
<tr role="row" v-if="shouldShow" class="pointer">
    <td class="align-top">
        <div class="row">
            <div class="col-md-6">
                <a :href="link" class="tr-anchor" :title="name">
                    <i class="fas small-icon" :class="iconClass"></i><b class="companyweb-lightblue" :inner-html.prop="highlightedName|truncate(38)"></b> <b class="companyweb-lightblue">({{vorm}})</b>
                </a> 
                <a :href="link" target="_blank" class="btn btn-transparent btn-sm ml-1"  :title="translate('open_in_new_tab')">
                    <i class="fas fa-external-link-alt"></i>
                </a>
            </div>
            <div class="col-md-6">
                {{vat}}
            </div>
        </div>
      
        <div class="row">
            <div class="col-md-6">
                <b>{{matchAddressLabel}}</b> <span v-html="address"></span>
            </div>
            <div class="col-md-6" v-if="showMainSeatAddress">
                <b>{{mainSeatAddressLabel}}:</b> <span v-html="mainSeatAddress"></span>
            </div>
        </div>
    </td>
    <td class="align-top" width="100px">
        <span v-html="barometer"></span>
        <span class="small-text" v-if="hasCompanyEndYear">({{companyEndYear}})</span>
    </td>
</tr>
