﻿import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import {Severity} from '@sentry/browser';
import {Vue as VueIntegration} from '@sentry/integrations';

if (window.sentry) {
    const rejectErrorsWith = [
        'extractFilteredSchemaValuesFromMicroData'
    ];
    
    Sentry.init(
        {
            dsn: window.sentry.dsn,
            environment: window.sentry.environment,
            release: window.sentry.release,
            integrations: [
                new VueIntegration({ Vue, attachProps: true, logErrors: true })
            ],
            denyUrls: [
                // External resources
                /www\.google-analytics\.com/i,
                /www\.googletagmanager\.com/i,
                /api\.leadinfo\.com/i,
                /collector\.leadinfo\.net/i,
                // Chrome extensions
                /^chrome:\/\//i,
                /^chrome-extension:\/\//i,
                // Local urls
                /^file:\/\//i,
                // webkit masked url
                /^\/\/hidden\//i
            ],
            beforeSend: function (event, hint) {
                const message = (hint?.originalException['message']) || '';
                const stack = (hint?.originalException['stack']) || '';
                const isFromExternalScript = !stack;
                
                if (isFromExternalScript && message.match(/undefined is not an object \(evaluating '\w\.\w'\)/)) {
                    event.fingerprint = ['undefined-property-in-external-script'];
                }
                
                for (var i=0; i<rejectErrorsWith.length; i++) {
                    if (message.includes(rejectErrorsWith[i]) || stack.includes(rejectErrorsWith[i])) {
                        return null;
                    }
                }
                
                return event;
            }
        }
    );

    Sentry.configureScope(scope => scope.setLevel(Severity.Error));
    Sentry.setTag('language', window.sentry.language);
    Sentry.setUser(window.sentry.user);
    
    Sentry.addBreadcrumb({
        category : 'page',
        message : 'Page loaded',
        level : Severity.Info
    });
}
