<template>
    <tr role="row">
        <td class="p-0 border-0">
            <div  class="timetable-row address-row">
                <table class="table table-small">
                    <tr>
                        <td colspan="2">
                            <div class="address">
                                <a :href="address.SearchAddressLink" class="addressname">
                                    <b>{{address.Lijn1}}, {{address.Lijn2}}</b>
                                </a>
                                <a :href="address.SearchAddressLink" class="btn btn-transparent btn-sm d-inline-block ml-1" target="_blank">
                                    <i class="fas fa-external-link-alt ext-link-icon"></i>
                                </a>
                            </div>
                        </td>
                    </tr>
                    <event-row v-for="(event, index) in events" :key="address.Id+'-event-'+index" v-bind="event"></event-row>
                </table>
            </div>
        </td>
    </tr>
</template>

<script>
    import EventRow from './event-row.vue';
    import Truncate from 'Mixins/truncate';
    import _ from 'lodash';

    export default {
        name: 'address-event',
        props: {
            events: Array,
            id: Number,
            currentList: Object
        },
        mixins: [Truncate],
        components: {
            EventRow
        },
        computed: {
            address() {
                return _.find(this.currentList.Addresses, address => address.Id == this.id);
            }
        }
    }
</script>

<style scoped>
    .addressname {
        font-size: 15px;
    }
    .ext-link-icon {
        font-size: 10px;
        vertical-align: top;
    }
</style>