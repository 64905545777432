﻿<template>
    <form method="get"
          v-on:submit.prevent="validateSubmit"
          action="/search/address"
          :data-gtm-cat="gtmCategory"
          :data-gtm-action="gtmAction">

        <div class="search-input-wrapper row" v-if="canSearchOnAddress">
            <div class="input-wrapper" :class="{'col-md-12':showAnnouncement, 'col-md-4': !showAnnouncement}">
                <autocomplete-input fetch-url="/ajax/autocomplete/citysuggestions"
                                    id="location"
                                    input-property="location"
                                    :value="location"
                                    next-tab-input="street"
                                    :placeholder="translate('placeholder_gemeente')"></autocomplete-input>
                <input type="hidden" name="PostalCode" :value="postalCode" />
                <input type="hidden" name="City" :value="city" />
            </div>
            <div class="input-wrapper" :class="{'col-8':showAnnouncement, 'col-md-4 col-8': !showAnnouncement}">
                <autocomplete-input fetch-url="/ajax/autocomplete/streetsuggestions"
                                    name="Street"
                                    id="street"
                                    input-ref="street"
                                    input-property="street"
                                    next-tab-input="number"
                                    :disabled="!streetInputEnabled"
                                    :value="street"
                                    :extra-params="{'postcode': postalCode}"
                                    :placeholder="translate('placeholder_straat')"></autocomplete-input>
            </div>
            <div class="input-wrapper" :class="{'col-4':showAnnouncement, 'col-md-3 col-4': !showAnnouncement}">
                <input class="form-control "
                       type="text"
                       name="Number"
                       id="houseNumber"
                       ref="number"
                       v-model="houseNumber"
                       :placeholder="translate('placeholder_huisnummer')"
                       :disabled="!streetInputEnabled" />
            </div>
            <div :class="{'col-md-12':showAnnouncement, 'col-md-1': !showAnnouncement}">
                <button type="submit" class="btn btn-info h-100 w-100">
                    <template v-if="showAnnouncement">
                        {{translate('zoeken')}}
                    </template>
                    <template v-else>
                        <i class="fas fa-search"></i>
                    </template>
                </button>
            </div>

            <div class="col-md-12">
                <div class="card-box warning small m-t-10" v-if="errorMessage !== ''" v-cloak>
                    {{errorMessage}}
                </div>
            </div>
        </div>
        <div v-else>
            {{translate('searchbox_upgrade_address_text')}}
            <div class="mt-2" v-if="isBasicUser || isStandardUser">
                <a href="/campaign/search-on-address" class="btn btn-info">
                    <span v-if="isBasicUser">{{translate('upgrade_basic_to_premium')}}</span>
                    <span v-if="isStandardUser">{{translate('upgrade_standard_to_premium')}}</span>
                </a>
            </div>
        </div>
        <input type="hidden" name="addressQuery" :value="location+';'+street+';'+houseNumber" />
    </form>
</template>
<script>
    import Translate from 'Mixins/translate';
    import AutocompleteInput from '../../shared/autocomplete-input.vue';
    import QueryString from "Lib/QueryString";

    export default {
        name: 'adres',
        components: {
            'autocomplete-input': AutocompleteInput
        },
        inject: ['showAnnouncement', 'canSearchOnAddress', 'isStandardUser', 'isBasicUser', 'isTrialUser'],
        mixins: [Translate],
        props: {
            gtmCategory: String,
            gtmAction: String
        },
        data: function () {
            return {
                street: '',
                houseNumber: '',
                location: '',
                minimumSearchLength: 2,
                errorMessage: '',
                errorField: '',
                initialAddress: {
                    street: '',
                    houseNumber: '',
                    postalCode: '',
                    city: ''
                }
            }
        },
        computed: {
            postalCode: function () {
                return this.location ? this.location.replace(/\D+/g, '') : '';
            },
            city: function () {
                return this.location ? this.location.replace(/^\d+\s*/g, '') : '';
            },
            streetInputEnabled: function () {
                return this.location !== '' && /^\d{4}\s{1}\w+/.test(this.location);
            }
        },
        watch: {
            streetInputEnabled: function (isEnabled) {
                if (isEnabled)
                    return;

                this.street = '';
                this.houseNumber = '';
            }
        },
        mounted: function () {
            const uri = window.location.href;
            
            this.initialAddress.postalCode = QueryString.getParameter(uri, "PostalCode") ?? "";
            this.initialAddress.city = QueryString.getParameter(uri, "City") ?? "";
            this.initialAddress.street = QueryString.getParameter(uri, "Street") ?? "";
            this.initialAddress.houseNumber = QueryString.getParameter(uri, "Number") ?? "";

            this.location = this.initialAddress.postalCode
                ? this.initialAddress.postalCode + " " + this.initialAddress.city
                : "";
            this.street = this.initialAddress.street;
            this.houseNumber = this.initialAddress.houseNumber;
        },
        methods: {
            showError: function (field, translationKey) {
                this.errorMessage = translationKey && this.translate(translationKey);
                this.errorField = field;
                document.getElementById(this.errorField).focus();
            },
            hideError: function () {
                this.errorField = '';
                this.errorMessage = '';
            },
            validateSubmit: function (event) {
                let message = '';
                let field = '';

                if (this.location === '') {
                    message = 'no_search_result_reason_location_missing';
                    field = 'location';
                } else if (this.location.length < this.minimumSearchLength) {
                    message = 'no_search_result_reason_location_too_short';
                    field = 'location';
                } else if (this.street === '') {
                    message = 'no_search_result_reason_street_missing';
                    field = 'street';
                } else if (this.houseNumber === '') {
                    message = 'no_search_result_reason_house_number_missing';
                    field = 'houseNumber';
                }

                if (message === '')
                    this.hideError();
                else
                    return this.showError(field, message);

                event.target.submit();
            }
        }
    };
</script>