﻿import Page from '../lib/Page';
import Swal, { SweetAlertOptions } from 'sweetalert2';

interface Options extends SweetAlertOptions{
    body: string;
}

export default (function () {
    Page.registerBehavior("[trigger-loading-dialog]", function (element) {
        const title = element.getAttribute("trigger-loading-dialog")! as string;
        
        element.addEventListener('click', () => {
            Swal.fire({
                title: title,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                confirmButtonColor: '#1ab9d6',
                onOpen: ()=>{
                    Swal.showLoading();
                }
            });
        });
    });
}());
