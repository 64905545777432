﻿import Vue from 'vue';
import Page from '../../lib/Page';
import KycReport from './report.vue';

export default (function () {
    Page.registerVue('#kycReport', function (rootSelector) {
        new Vue({
            el: rootSelector,
            name: 'kyc_report_wrapper',
            components: {
                KycReport
            },
            data: {}
        });
    });
}());
