﻿import Ajax from '../lib/Ajax';
import Page from '../lib/Page';

export default (function () {

    Page.registerBehavior('#visuallyImpairedStylesheetToggle', function (element) {
        const className = "visually-impaired";
        const label = element.parentNode!.querySelector('label')!;

        label.addEventListener('click', function (ev: Event) {
            ev.stopPropagation();
        });

        (element as HTMLInputElement).addEventListener('click', function (ev: Event) {
            ev.stopPropagation();
            if (this.checked) {
                document.getElementsByTagName('html')[0].classList.add(className);
                Ajax.get('/visually-impaired',
                    {},
                    function () { },
                    function () { }
                );
            } else {
                document.getElementsByTagName('html')[0].classList.remove(className);
                document.cookie = "visually-impaired=;path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
        })

    });
}());
