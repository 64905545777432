
<div>
    <!-- 1. loading -->
    <div v-if="isLoading" class="my-5 py-5 text-center">
        <i class="fas fa-2x fa-spinner text-lightblue spinning"></i>
    </div>

    <!-- 2. started -->
    <template v-else-if="currentRequest.status === RequestStatus.STARTED">
        <result-bar v-for="(bar) in resultBars" :key="bar.label" :amount="bar.count" :label="translate(bar.label)" :totalAmount="summary.countTotal"></result-bar>

        <form class="mt-3 mt-lg-5" @submit.prevent="saveRequest" v-if="!isLocked">
            <div class="form-group">
                <div class="row">
                    <div class="col-3">
                        <label for="language">{{translate("language")}}</label>
                        <select id="language" v-model="selectedLanguage" class="form-control mr-1">
                            <option v-for="lang in Language" :value="lang" :key="lang">{{lang.toUpperCase()}}</option>
                        </select>
                    </div>
                    <div class="col-9">
                        <label for="reference">{{translate("reference_label")}}</label>
                        <input :readonly="hasReference" id="reference" type="text" class="form-control" v-model="$v.newReference.$model" />
                    </div>
                </div>

                <template v-if="$v.newReference.$invalid && $v.newReference.$dirty">
                    <div class="card-box small my-2 warning" v-if="!$v.newReference.required">
                        {{translate('validation_error_required', translate("dm_reference"))}}
                    </div>
                    <div class="card-box small my-2 warning" v-else-if="!$v.newReference.maxLength">
                        {{translate('validation_error_max_length', translate("dm_reference"), 50)}}
                    </div>
                    <div class="card-box small my-2 warning" v-else-if="!$v.newReference.minLength">
                        {{translate('validation_error_min_length', translate("dm_reference"), 3)}}
                    </div>
                    <div class="card-box small my-2 warning" v-else-if="!$v.newReference.alphaNumeric">
                        {{translate('validation_error_alphanumeric', translate("dm_reference"), 3)}}
                    </div>
                </template>
            </div>

            <div class="form-group">
                <input id="terms" type="checkbox" class="form-check-input" v-model="$v.acceptTerms.$model" />
                <label for="terms">
                    <translation label="ik_ga_akkoord_met">
                        <a :href="termsOfUseLink" class="link">{{translate('de_gebruiksvoorwaarden').toLowerCase()}}</a>
                    </translation>
                </label>
            </div>
            <template v-if="$v.acceptTerms.$invalid && $v.acceptTerms.$dirty">
                <div class="card-box small my-2 warning">
                    <translation label="validation_error_required"><span>{{translate('gebruiksvoorwaarden')}}</span></translation>
                </div>
            </template>

            <div class="tip warning mt-4" v-if="!hasFullAccess">
                {{translate('datamarketing_no_user_rights')}}
            </div>
            
            <div class="form-group mt-4">
                <button class="btn btn-info btn-200" type="submit" :disabled="!canFinishRequest">{{translate('btn_accept_request')}} <i class="fas ml-1 fan-angle-right"></i></button>
            </div>
        </form>
        <div v-if="isDeveloperOrManager">
            <hr>
            <br>
            <h2><b>Radar</b></h2>
            <p>
                Maak hier een Radar dataset aan de hand van uw criteria.
            </p>
            <div>
                <input type="search" placeholder="Filter op bedrijfsnaam" class="form-control" v-model="filterCompanyName">
            </div>
            <div class="overflow-auto my-3 pr-2" style="max-height: 450px;">
                <table>
                    <thead>
                    <tr>
                        <th>Bedrijfsnaam</th>
                        <th width="75px">BOID</th>
                        <th>#sets </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="customer in filteredRadarCustomers" :key="customer.customerId">
                        <td>
                            <input type="radio" class=" mr-2" name="ts" :id="'customer_'+radarCustomerId" v-model="radarCustomerId" :value="customer.customerId">
                            <label :for="'customer_'+radarCustomerId" @click.prevent.stop="radarCustomerId = customer.customerId">
                                {{customer.companyName}}
                            </label>
                        </td>
                        <td>
                            <a class="link" :href="'https://backoffice.companyweb.be/main.asp?ret=55&hbcid=' + customer.customerId" target="_blank">{{customer.customerId}}</a>
                        </td>
                        <td>
                            {{customer.numberOfActiveDatasets}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
           <div class="d-flex" style="gap: 25px;" v-if="!sentToRadar"> 
               <input type="text" class="form-control" v-model="datasetName" placeholder="Naam dataset" />
               <button class="btn" :disabled="!radarCustomerId" @click="sendToRadar">Create</button>
           </div>
            <p v-else>
                Verzonden.
            </p>
        </div>
    </template>

    <!-- 3. finished OR pending -->
    <div v-else>
        <div class="row text-center mt-3 mt-lg-5">
            <div class="col-md-8 offset-md-2">
                <img src="/images/illustrations/dm-success.svg" class="img-fluid mb-4 success-image"/>
                <h2><b>{{translate('results_success_title')}}!</b></h2>
                <p> 
                    <translation label="download_text">
                        <a href="/datamarketing" class="link">{{translate('historiek').toLowerCase()}}</a>
                        <span>{{availableUntil.format()}}</span>
                    </translation>
                </p>

                <template v-if="!!currentRequest.fileGenerationDate">
                    <a 
                    v-if="canExport"
                    class="btn btn-success btn-xl mt-4"   
                    :href="currentRequest.exportFileLink">
                        {{translate('btn_download')}}<i class="fas ml-2 fa-download"></i>
                    </a>
                    
                    <a 
                    v-else
                    class="btn btn-success btn-xl mt-4"  
                    :disabled="!selectionFitsInPlan" 
                    @click.prevent="tryToComplete()">
                        {{translate('btn_download')}}<i class="fas ml-2 fa-download"></i>
                    </a>
                </template>

                <div class="font-weight-bold mt-2">{{currentRequest.reference}}</div>
                <div v-if="!!currentRequest.fileGenerationDate" class="text-grey font-small mt-1" v-html="translate('download_btn_desc', currentRequest.amountOfCompanies, requestDate.format())"></div>
                
                <div class="tip warning mt-4" v-if="isTrial">
                    <p class="mb-0">
                        {{translate('trial_warning')}}
                    </p>
                </div>
                <div class="tip warning mt-4" v-else-if="currentRequest.status !== RequestStatus.FINISHED && selectionNeedsManualAction">
                    <p class="mb-0">
                        {{translate('warning_selection_bigger_than_limit', selectionUpperLimit)}}
                    </p>
                </div>
                <div class="tip warning mt-4 text-left" v-else-if="currentRequest.status !== RequestStatus.FINISHED && !selectionFitsInPlan">
                    <p class="mb-0">
                        {{translate('warning_selection_bigger_than_plan')}}
                    </p>

                    <form :action="'/order/microtransaction/data-marketing/' + upsellAmount" method="POST" ref="mtForm" class="mt-3" @submit.prevent="startMicroTransaction">
                        <div class="form-group">
                            <input id="terms" type="checkbox" class="form-check-input" v-model="$v.acceptMollieTerms.$model" />
                            <label for="terms">
                                <translation label="ik_ga_akkoord_met">
                                    <a :href="termsAndConditionsLink" class="link" target="_blank">{{translate('algemene_voorwaarden').toLowerCase()}}</a>
                                </translation>
                            </label>
                        </div>
                        <template v-if="$v.acceptMollieTerms.$invalid && $v.acceptMollieTerms.$dirty">
                            <div class="card-box small my-2 warning">
                                <translation label="validation_error_required"><span>{{translate('algemene_voorwaarden')}}</span></translation>
                            </div>
                        </template>

                        <button type="submit" class="btn btn-success">{{translate('buy_credits_btn')}}</button>
                    </form>
                </div>
            </div>
        </div>
        
        
        <!-- SUPERADMIN BOX -->
        <div v-if="isLoggedInAsCustomer || isSuperAdmin" class="row mt-5 pt-4">
            <div class="col-md-12">
                <div class="box">
                    <span class="box-title"><i class="mr-2 fas fa-gem"></i>Super admin</span>
                    <div class="row">
                        <div class="col-md-4">
                            <b>Bestand</b>
                            <a v-if="!!currentRequest.fileGenerationDate" class="btn btn-default mt-3 d-block" :href="currentRequest.exportFileLink">
                                Download <i class="fas ml-2 fa-download"></i>
                            </a>
                            <div v-else-if="isGenerating">
                                Generating file...
                            </div>
                            <a v-else class="btn btn-default mt-3 d-block" href="#" @click.prevent="generateFileAsSuperadmin">
                                Genereer <i class="fas ml-2 fa-sync"></i>
                            </a>
                        </div>
                        <div class="col-md-4">
                            <div>
                                <btw-file-input @input="onNewFileInput"></btw-file-input>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="!isPaid">
                            <b>Markeren als betaald</b>
                            <button class="btn btn-default btn-block mt-3 "  @click.prevent="setIsPaid()">
                                Betaald <i class="fas ml-2 fa-euro-sign"></i>
                            </button>
                        </div>
                    </div>
                    
                    <div class="row mt-4">
                        <div class="col-md-4">
                            <div class="font-weight-bold">Validatie datum</div>
                            {{currentRequest.validationDate}}
                        </div>
                        <div class="col-md-4">
                            <div class="font-weight-bold">Is betaald?</div>
                            {{currentRequest.isPaid}}
                        </div>
                        <div class="col-md-4">
                            <div class="font-weight-bold">Aanmaakdatum bestand</div>
                            {{currentRequest.fileGenerationDate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
