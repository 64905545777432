<template>
  <div class="dm-historiek">
    <a 
        v-for="request in finishedRequests"
        :key="request.id" 
        class="hover-toggle box p-3 rounded-sm mb-3 d-flex justify-content-between align-items-center" 
        :href="request.detailLink">
            <div class="d-flex align-items-center w-full">
       
                <div class="w-full">
                    <h3 class="mb-1"><b>{{request.reference}}</b></h3>
                    <div class="font-small mb-1">
                        <template v-if="request.type === DataMarketingRequestType.PROSPECTIEDATA">{{translate("type_prospection")}}</template>
                        <template v-else>{{translate("type_enrichment")}}</template> <span class="mx-2 text-grey">|</span> <span>{{request.amountOfCompanies.formatThousandSeparator()}} {{translate("companies")}}</span> 
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="badge badge-inverted badge-success badge-small" v-if="request.isPaid">{{translate("label_done")}}</div>
                        <div class="badge badge-inverted badge-warning badge-small" v-else>{{translate("label_ongoing")}}</div>

                        <div class="text-grey font-small text-right">{{request.userName}}<br>{{Date.fromHansDate(request.requestDate).format()}}</div>
                    </div>
                </div>
            </div>
        </a>
        <p v-if="finishedRequests.length === 0">
            {{translate("no_requests")}}
        </p>
    </div>
</template>

<script lang="ts">
    import { DataMarketingRequestType } from '../../../types/dto/DataMarketingRequestType';
    import { PrepaidDataMarketingRequest } from '../../../types/dto/PrepaidDataMarketing';
    import Translate from '../../mixins/translate';
    import mixins from 'vue-typed-mixins';
    import {DataMarketingAccessLevel} from "../../../types/dto/DataMarketingAccessLevel";    
    

    export default mixins(Translate).extend({
        data(){
            return {
                requests: window.cw.requests as PrepaidDataMarketingRequest[],
                accessLevel: window.cw.dmAccessLevel as DataMarketingAccessLevel,
                DataMarketingRequestType,
                DataMarketingAccessLevel
            }
        },
        computed: {
            finishedRequests():PrepaidDataMarketingRequest[] {
                return this.requests.filter(r => !!r.validationDate);
            }
        }
    });
</script>

<style scoped lang="scss">
    .box {
        text-decoration: none;
    }

    .dm-historiek {
        max-height: 730px;
        overflow-y: auto;
        padding-right: 10px;
    }
</style>