<template>
    <div class="tip warning py-2 mb-3 space-y-3" v-if="hasAnyWarning">
        <div v-if="hasActiviteitBeroepWarning" v-html="translate('warning_activiteit')">
        </div>
        <div v-if="hasOmzetWarning" v-html="translate('warning_omzet')">
        </div>
        <div v-if="hasRecentCompaniesWithFinancialDataWarning" v-html="translate('warning_financieel')">
        </div>
    </div>
</template>

<script lang="ts">
    import { ProspectieDataSelectionCriterium } from '../../../../types/dto/ProspectieDataSelectionCriterium';
    import { ProspectieDataSelectionCriteriumType } from '../../../../types/dto/ProspectieDataSelectionCriteriumType';
    import DataMarketingService from '../../dataMarketingService';
    import Translate from '../../../mixins/translate';
    import mixins from 'vue-typed-mixins';    

    export default mixins(Translate).extend({
        name:'dm_warning_assistent',
        computed: {
            selectedCriteria(): ProspectieDataSelectionCriterium[] {
                return DataMarketingService.getInstance().currentRequest.selectedCriteria!;
            },
            hasActiviteitBeroepWarning(): boolean {
                return this.selectedCriteria.some(criterium => criterium.type === ProspectieDataSelectionCriteriumType.BEROEP) && this.selectedCriteria.some(criterium => criterium.type === ProspectieDataSelectionCriteriumType.ACTIVITEIT) ;
            },
            hasOmzetWarning(): boolean {
                return this.selectedCriteria.some(criterium => criterium.type === ProspectieDataSelectionCriteriumType.OMZET);
            },
            hasRecentCompaniesWithFinancialDataWarning(): boolean {
                const hasFinancialDataSelected = this.selectedCriteria.some(criterium =>  [
                    ProspectieDataSelectionCriteriumType.OMZET,
                    ProspectieDataSelectionCriteriumType.BALANSTOTAAL,
                    ProspectieDataSelectionCriteriumType.EIGENVERMOGEN,
                    ProspectieDataSelectionCriteriumType.RISICOPROFIEL,
                    ProspectieDataSelectionCriteriumType.WINSTVERLIES,
                    ProspectieDataSelectionCriteriumType.BRUTOMARGE
                ].includes(criterium.type)); 

                const twoYearsAgo = new Date().addMonths(-24).toHansDate();
                const hasRecentCompaniesSelected = this.selectedCriteria.some(criterium => (criterium?.range?.lowerBound ?? 0) > twoYearsAgo);

                return hasRecentCompaniesSelected && hasFinancialDataSelected;
            },

            hasAnyWarning(): boolean {
                return this.hasActiviteitBeroepWarning || this.hasOmzetWarning || this.hasRecentCompaniesWithFinancialDataWarning;
            }
        }
    });
</script>

<style scoped lang="scss">
    .space-y-3 > * + *{
        margin-top: 1rem;
        margin-bottom: 0;
    }
</style>