<template>
    <div>
        <h2>{{translate("start_with")}}</h2>
        
        <div class="space-y-3">
            <div>
                <input type="radio" id="1" :value="StartingSelection.NEW_SELECTION" v-model="startingSelection" @change="useNoPreset()"> 
                <label for="1">{{translate(StartingSelection.NEW_SELECTION)}}</label>
            </div>

            <div>
                <input type="radio" id="2" :value="StartingSelection.FORMER_SELECTION" v-model="startingSelection"> 
                <label for="2">{{translate(StartingSelection.FORMER_SELECTION)}}</label>
            </div>

            <div class="mb-4">
                <input type="radio" id="3" :value="StartingSelection.PREMADE_SELECTION" v-model="startingSelection"> 
                <label for="3">{{translate(StartingSelection.PREMADE_SELECTION)}}</label>
            </div>
        </div>
        <div v-if="startingSelection === StartingSelection.PREMADE_SELECTION">
            <div class="tiles">
                <a href="" v-for="preset in presets" :key="preset.name" class="mb-2 tile blue" @click.prevent="usePreset(preset)">
                    <i class="fad" :class="preset.icon"></i>
                    <h3>{{translate(preset.translationKey)}}</h3>
                </a>
            </div>
        </div>
        <div v-else-if="startingSelection === StartingSelection.FORMER_SELECTION">
             <div class="tiles" v-if="formerRequests">
                <a href="" v-for="request in formerRequests" :key="request.id" class="mb-2 tile blue" @click.prevent="useFormerRequest(request)">
                    <h3>{{request.reference}}</h3>
                    <span class="text-grey font-small">{{Date.fromHansDate(request.requestDate).format()}}</span>
                </a>
            </div>
            <p v-else>
                {{translate("no_former_selections")}}
            </p>
        </div>
    </div>
</template>

<script lang="ts">
    import Translate from '../../../mixins/translate';
    import mixins from 'vue-typed-mixins';    
    import SelectionPreset from '../../selectionPreset';
    import DataMarketingService, {Request} from '../../dataMarketingService';
    import Progress from '../../progress';
    import Toast from '../../../../lib/Toast';
    import { PrepaidDataMarketingRequest } from '../../../../types/dto/PrepaidDataMarketing'; 
    import { DataMarketingRequestType } from '../../../../types/dto/DataMarketingRequestType';

    enum StartingSelection {
       NEW_SELECTION = "label_new_selection",
       FORMER_SELECTION = "label_former_selection",
       PREMADE_SELECTION = "label_premade_selection"
    }

    export default mixins(Translate).extend({
        name: 'dm-doelgroep-preset',
        props: {
            progress: Object as () => Progress
        },
        data() {
            return {
                startingSelection: StartingSelection.NEW_SELECTION,
                StartingSelection
            }
        },
        methods: {
            async usePreset(preset: SelectionPreset): Promise<void> {
                await this.currentRequest.usePreset(preset);
                Toast.success(this.translate("criteria_copied"))
            },
            async useFormerRequest(request: PrepaidDataMarketingRequest): Promise<void> {
                await this.currentRequest.useFormerRequest(request);
                Toast.success(this.translate("criteria_copied"))
            },
            async useNoPreset(): Promise<void> {
                await this.currentRequest.useNoPreset();
            }
        },
        computed: {
            currentRequest(): Request{
                return DataMarketingService.getInstance().currentRequest;
            },
            presets(): SelectionPreset[] {
                return this.currentRequest.presets;
            },
            hasSelections(): boolean {
                return this.currentRequest.hasSelections;
            },
            formerRequests(): PrepaidDataMarketingRequest[] {
                return this.currentRequest.formerRequests.filter(r => r.type === DataMarketingRequestType.PROSPECTIEDATA).sort((a,b) => b.requestDate - a.requestDate);
            }
        }
    })
</script>

<style scoped lang="scss">
    @import "../../../../../sass/variables";

    .hoverable {
        position: relative;

        &:hover {
            .show-on-hover {
                display: inline-block;
            }            
        }
    }
    .show-on-hover {
        display: none;
        color: grey;
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;

        i {
            font-size: 14px !important;
        }   
        &:hover{
            color: black;
        }
    }

    .tiles {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;

        @media #{$phone} {
            grid-template-columns: 1fr 1fr;
        }

        .tile {
            border-radius: 3px;
            border: 1px dashed grey;
            padding: 10px;
            display: inline-flex;
            min-width: 170px;
            min-height: 170px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-decoration: none;

            @media #{$phone} {
                min-width: auto;
                min-height: 130px;
            }

            &:hover {
                text-decoration: none;
                border-style: solid;
            }
            i {
                font-size: 28px;
                margin-bottom: 14px;
            }

            h3 {
                color: inherit;
            }

            &.white {
                color: grey;
                border-color: grey;
                background-color: fade-out($color: grey, $amount: 0.95);
            }
            &.blue {
                color: $lightblue;
                border-color: $lightblue;
                background-color: fade-out($color: $lightblue, $amount: 0.95);
            }
        }
    }
</style>