
<div>
    <div class="row">
        <div class="col-md-12">
            <h3><b>{{translate('screening_remove_vats_title')}}</b></h3>
            <p>
                {{translate('screening_remove_vats_intro')}}
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="tabs mb-4">
                <a class="tab" 
                   href="" 
                   v-for="(status, index) in statusses" 
                   :key="index"
                   :class="{'active': selectedStatus === status}"
                   @click.prevent="selectStatus(status)">
                    <div class="status" :class="status">{{translate(status)}}</div>
                </a>
            </div>
        </div>
        <div class="col-md-12 mb-2 font-small">
            {{translate('shown')}}: {{startIndex + 1}} - {{Math.min(endIndex + 1, statusCompanies.length)}} / {{statusCompanies.length}}
        </div>
        <div class="col-md-12 vat-list mb-4" ref="companylist">
            <div class="row" >
                <div class="col-md-6" v-for="company in listedCompanies" :key="company.Vat">
                    <div class="company-row">
                        <div class="row">
                            <div class="col-12">
                                <input type="checkbox" :id="company.Vat" v-model="company.isSelected" class="form-check-input" />
                                <label class="m-0" :for="company.Vat">{{company.Naam|truncate(50)}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2 my-auto">
            <a href="" class="btn btn-default btn-block" @click.prevent="back()"><i class="fas fa-angle-left mr-2"></i> {{translate('btn_back')}}</a>
        </div>
        <div class="col-md-7">
            <alerts-usage :used="totalUsed" :selected="totalSelectedCompanies" :max="maxCompanies"></alerts-usage>
        </div>
        <div class="col-md-3 my-auto">
            <a href="" class="btn btn-blue btn-block" @click.prevent="proceed()" :class="{'disabled':isLimitExceeded}" :disabled="isLimitExceeded">{{translate('btn_complete')}} <i class="fas fa-angle-right ml-2"></i></a>
        </div>
    </div>
</div>
