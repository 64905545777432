import Vue from 'vue';
import Page from '../../lib/Page';
import LeadCandidateConversion from './components/lead-candidate-conversion.vue';

export default (function () {
    Page.registerVue('#leadCandidateConversion', function (rootSelector) {
        new Vue({
            el: rootSelector,
            name: 'lead-candidate-conversion-wrapper',
            components: {
                LeadCandidateConversion,
            }
        });
    });
}());
