﻿<template>
    <div class="cw-dropdown" id="type-dropdown">
        <div class="cw-dropdown__label" @click="toggleShowList()">{{translate('event_type_' + selectedTypeValue)}}</div>
        <ul class="cw-dropdown__list" :class="{'active': showList}">
            <li v-for="type in types" @click="selectValue(type.value)" :key="type.value">
                <i class="text-blue fas" :class="'fa-'+type.icon"></i> 
                {{translate('event_type_' + type.value)}} 
                <span class="badge badge-pill badge-secondary float-right badge-fixed" :class="{'badge-blue': type.numberOfEvents > 0}">{{type.numberOfEvents}}</span>
            </li>
        </ul>
    </div>
</template>

<script>
    import _ from 'lodash';
    import Translate from 'Mixins/translate';
    import Vue from 'vue';

    export default Vue.extend({
        name: 'type-dropdown',
        props: {
            value: String,
            types: Array
        },
        mixins:[Translate],
        data() {
            return {
                selectedTypeValue: this.value,
                showList: false
            }
        },
        methods: {
            toggleShowList() {
                this.showList = !this.showList;
            },
            selectValue(value) {
                this.selectedTypeValue = value;
                this.showList = false;
                this.emitValue();
            },
            emitValue() { 
                this.$emit('input', this.selectedTypeValue);
            }
        },
        mounted() {
            function isDescendant(parent, child) {
                var node = child.parentNode;
                while (node != null) {
                    if (node == parent) {
                        return true;
                    }
                    node = node.parentNode;
                }
                return false;
            }

            const containerEl = document.getElementById('type-dropdown');

            const outsideClickListener = (event) => {
                if (!isDescendant(containerEl, event.target))
                    this.showList = false;
            }

            document.addEventListener('click', outsideClickListener)
        },
        destroy() {
            document.removeEventListener('click', outsideClickListener)
        }
    });
</script>

<style scoped>
    .badge-fixed {
        width: 45px;
    }
</style>