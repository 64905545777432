﻿<template>
    <div class="select-menu-wrapper">
        <a class="label-wrapper" href="" @click.prevent="toggle">
            <span class="text">
                <div class="text-small text-grey">{{label}}</div>
                 <span class="font-500">{{ activeOption.title }}</span>
            </span>
            <span class="chevron">
                <i class="far fa-chevron-down"></i>
            </span>
        </a>
        <template v-if="!linkToUrl">
            <ul v-if="isOpen" class="select-menu">
                <li v-for="option in options" :class="{'active': option === activeOption}" @click="select(option)">
                    <div>
                        <div class="select-title">
                            <span>{{ option.title }}</span>
                            <i v-if="option === activeOption" class="far fa-check"></i>
                        </div>
                        <div class="select-desc">{{ option.description }}</div>
                    </div>
                </li>
            </ul>
        </template>
        <template v-else>
            <div v-if="isOpen" class="select-menu">
                <a :href="option.url" v-for="option in options" :class="{'active': option === activeOption}">
                    <div class="select-title">
                        <span>{{ option.title }}</span>
                        <i v-if="option.id == activeOption.id" class="far fa-check"></i>
                    </div>
                </a>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    export interface SelectMenuItem {
        id: string;
        title: string;
        url: string | null;
        description: string;
    }

    export default Vue.extend({
        name: 'advanced-select',
        props: {
            label: {
                type: String,
                required: true
            },
            options: {
                type:Array as () => SelectMenuItem[],
                required: true
            },
            value: {
                type:Object as () => SelectMenuItem,
                required: false
            },
            linkToUrl: Boolean
        },
        data() {
            return {
                activeOption: this.value || this.options[0] as SelectMenuItem,
                isOpen: false
            };
        },
        methods: {
            toggle(): void {
                this.isOpen = !this.isOpen;
            },
            select(option: SelectMenuItem): void {
                this.activeOption = option;
                this.$emit('input', option);
                this.isOpen = false;
            }
        },
        mounted(): void {
            function isDescendant(parent: HTMLElement, child: HTMLElement) {
                let node = child.parentNode;
                
                while (node !== null) {
                    if (node == parent) {
                        return true;
                    }
                    node = node.parentNode;
                }
                return false;
            }
            
            const outsideClickListener = (event: Event) => {
                if (!isDescendant(this.$el as HTMLElement, event.target as HTMLElement))
                    this.isOpen = false;
            }

            document.addEventListener('click', outsideClickListener);
        }
    });
</script>

<style lang="scss" scoped>
    $activeColor: #0368FF;
    .select-menu-wrapper {
      position: relative;
    }
    .label-wrapper {
      color: #0a2439;
        border: 1px solid #cdcdcd;
        border-radius: 6px;
        display: flex;
        right: 0;
        position: relative;
        font-size: 14px;

        .text-small {
            font-size: 11px;
            line-height: 11px;
        }
        &:focus {
          color: #0a2439;
        }

        &:hover {
            text-decoration: none;
        }

        > * {
            padding: 3px 13px;
            display: inline-block;
        }

        > .text {
            flex-grow: 1;
            text-align: left;

            i {
                margin-right: 8px;
            }
        }
        .chevron {
            display: flex;
            align-items: center;
        }
    }

    .select-menu {
        position: absolute;
        z-index: 500;
        background-color: white;
        left: 0;
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
        border: 1px solid #cdcdcd;
        border-radius: 6px;
        margin-top: 10px;
        overflow: hidden;
        width: 310px;

        > * {
            padding: 12px 20px;
            border-bottom: 1px solid #cdcdcd;
            display: block;
            color: #0a2439;

            &:last-of-type {
                border-bottom: none;
            }

            .select-title {
                font-size: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 4px;

                i {
                    color: $activeColor;
                }
            }

            .select-desc {
                font-size: 13px;
                opacity: 0.8;
            }

            &:hover {
                cursor: pointer;
                text-decoration: none;
                background-color: $activeColor;
                color: white;

                i {

                    color: white;
                }
            }

            &.active {
                .select-title {
                    font-weight: 500;
                }
            }
        }
    }
</style>