
<th :class="{'open': isFilterBoxShown, 'vertical narrow': column.isVertical, 'filtered': column.filterCriteria.hasValue}"
    @click.stop="toggle" :style="'min-width:' + (column.minWidth ? (column.minWidth + 'px') : '25px')">
    <div class="filter-title-row">
        <div class="d-flex" :class="{'align-items-center': column.isVertical}" style="gap:8px">
            <div class="text">
                {{ translate(column.title) }}
            </div>
            <div class="d-flex flex-column sort-wrapper">
                <a class="d-inline-block sort-btn" :class="{'active': isSorted && !isDescending}" href="#"
                   @click.prevent.stop="setSort(false)" :title="translate('sort_asc')">
                    <i class="fa-caret-up"
                       :class="{'fas': isSorted && !isDescending, 'far': !isSorted || isDescending}"></i>
                </a>
                <a class="d-inline-block  sort-btn" :class="{'active': isSorted && isDescending}" href="#"
                   @click.prevent.stop="setSort(true)" :title="translate('sort_desc')">
                    <i class="fa-caret-down"
                       :class="{'fas': isSorted && isDescending, 'far': !isSorted || !isDescending}"></i>
                </a>
            </div>
        </div>
    </div>
    <transition name="dropdown">
        <div v-if="isFilterBoxShown" v-click-outside="close">
            <div id="filter-box" class="filter-box" @click.stop>
                <div class="row mb-2">
                    <h3 class="col-md-6">
                        {{ translate(column.title) }}
                    </h3>
                    <div class="col-md-6 text-right" v-if="column.filterCriteria.hasValue">
                        <a href="" class="text-grey link" @click.prevent="column.filterCriteria.reset()"><i
                            class="fas fa-times"></i> {{ translate('delete_filter') }}</a>
                    </div>
                </div>
                <div v-if="column.showDescription" class="mb-3">
                    <em>{{ translate(column.title + '_desc') }}</em>
                </div>

                <div v-if="column.filterCriteria._type === FilterType.FREETEXT">
                    <free-text :criteria="column.filterCriteria"></free-text>
                </div>
                <div v-if="column.filterCriteria._type === FilterType.BOOLEAN">
                    <yes-no :criteria="column.filterCriteria"></yes-no>
                </div>
                <div v-else-if="column.filterCriteria._type === FilterType.SELECTIONLIST">
                    <selection-list :column="column" limited-height></selection-list>
                </div>
                <div v-else-if="column.filterCriteria._type === FilterType.SELECTIONTREE">
                    <selection-tree
                        limited-height
                        :column="column"
                        :criteria="column.filterCriteria"
                        :treeData="getSelectionTree"></selection-tree>
                </div>
                <div v-else-if="column.filterCriteria._type === FilterType.DATERANGE" class="mt-3">
                    <date-range :criteria="column.filterCriteria"></date-range>
                </div>
                <div v-else-if="column.filterCriteria._type === FilterType.NUMERICRANGE">
                    <div class="px-2 py-3">
                        <range-slider 
                            :is-linear="isLinear"
                            :lower-bound="lowerBound" 
                            :upper-bound="upperBound" 
                            v-model="column.filterCriteria._value"></range-slider>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</th>
