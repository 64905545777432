
<aside>
    <h1 class="mb-4">{{title}}</h1>

    <a href="" class="d-block d-lg-none position-absolute right-0 top-0 p-3 text-grey" @click.prevent="closeMenu">
        <i class="fas fa-times fa-lg"></i>
    </a>
    <ul>
        <li v-for="(step, index) in progress.steps" :key="index">
            <a href="" class="sidenav-link" v-on:click.prevent="activateStep(step)" :class="{'active': step === activeStep, 'not-activatable': !step.isClickable || step.isLocked}">
                {{index+1}}. {{translate('sidebar_'+step.label)}}
            </a>

            <ul v-if="step.children" class="pl-2">
                <li v-for="(substep, idx) in step.children" :key="'sub_'+idx">
                    <a class="sidenav-link" :class="{'active': substep === activeStep, 'not-activatable': !substep.isClickable || substep.isLocked}" href="" v-on:click.prevent="activateStep(substep)">
                        <i class="fad"  :class="substep.icon"></i> {{translate('sidebar_'+substep.label)}}
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</aside>
