<template>
    <div>
        <div v-if="isLoading">
            <div class="py-5 text-center">
                <i class="fas fa-spinner spinning text-blue fa-2x"></i>
            </div>
        </div>
        <div v-else>
            <table class="data-table table table-striped mt-5">
                <thead>
                    <tr>
                        <th colspan="3">

                        </th>
                        <th>
                            <input type="search" class="form-control" placeholder="filter op naam" v-model="filter.name"/>
                        </th>
                        <th>
                            <input type="search" class="form-control" placeholder="filter op tussenpersoon" v-model="filter.tussenPersoon"/>
                        </th>
                        <th>

                        </th>
                        <th>
                        <select v-model="filter.type" class="form-control mt-auto">
                            <option value="">Alle</option>
                            <option value="generic">Generiek</option>
                            <option value="custom">Op maat</option>
                        </select>
                        </th>
                        <th>
                        <select v-model="filter.customerType" class="form-control mt-auto">
                            <option value="">Alle</option>
                            <option value="prospect">Prospect</option>
                            <option value="customer">Klant</option>
                        </select>
                        </th>
                    </tr>
                    <tr>
                        <th width="30"><i class="fa fa-globe" title="Published?"></i></th>
                        <th width="30"><i class="fa fa-archive" title="Legacy?"></i></th>
                        <th width="140">Logo</th>
                        <th width="265">Software</th>
                        <th>Softwarehuis/Integrator</th>
                        <th>Gebruikers</th>
                        <th wdith="120">Type pakket</th>
                        <th width="120">Type integrator</th>
                        <th width="120">&nbsp;</th>
                    </tr>
                </thead>
                <tbody :list="softwares" group="softwares" @start="drag=true" @end="onDragged" tag="tbody" is="draggable">
                    <tr v-for="software in filteredSoftwares" :key="software.sortIndex" class="move" :class="{'yellow-row': software.label === SoftwareLabel.COMINGSOON, 'green-row': software.status === SoftwareStatus.PUBLISHED}">
                        <td>
                            <i 
                            class="fas fa-globe" 
                            :class="{'text-teal': software.status === SoftwareStatus.PUBLISHED, 'text-yellow': software.label === SoftwareLabel.COMINGSOON}" 
                            v-if="software.status !== SoftwareStatus.DRAFT"></i>
                        </td>
                         <td>
                            <i class="fas fa-archive text-grey" v-if="software.isLegacy" title="Legacy software"></i>
                        </td>
                        <td>
                            <div class="d-flex align-items-center justify-content-center" style="width: 100px;">
                                <img :src="software.logo" v-if="software.logo" class="img-fluid" style="max-height: 45px;"/> 
                            </div>
                        </td>
                        <td>
                            <b>{{software.name}}</b> <span class="text-grey" v-if="software.distributor"> by {{software.distributor}}</span>
                        </td>
                        <td>
                            <span v-if="software.tussenPersoon">
                                {{software.tussenPersoon.companyName}}
                            </span>
                        </td>
                        <td>
                            {{software.amountOfUsers}}
                        </td>
                        <td>
                            <span class="badge badge-warning badge-inverted" v-if="software.isCustom">
                                <i class="fas fa-tools mr-1"></i> op maat
                            </span>
                            <span class="badge badge-blue badge-inverted" v-else>
                                <i class="fas fa-box mr-1"></i> generiek
                            </span>
                        </td>
                        <td>
                            <span class="badge badge-warning badge-inverted" v-if="software.isProspect">
                                <i class="fas fa-search mr-1"></i> prospect
                            </span>
                            <span class="badge badge-blue badge-inverted" v-else>
                                <i class="fas fa-user mr-1"></i> klant
                            </span>
                        </td>
                        <td class="text-right" slot="footer">
                            <button @click.stop="editSoftware(software)" class="btn btn-info btn-sm"><i class="fas fa-pencil-alt"></i> Edit</button>
                            <button @click.stop="tryDelete(software)" class="btn btn-default btn-sm"><i class="fas fa-trash-alt"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import Ajax from '../../../lib/Ajax';
    import Toast from '../../../lib/Toast';
    import Confirmation from '../../../lib/Confirmation';
    import _ from 'lodash';
    import draggable from 'vuedraggable'
    import { SoftwarePartner, SoftwareStatus, SoftwareLabel } from '../../../types/dto/SoftwarePartner';

    export default Vue.extend({
        name: "list_software",
        components: {
            draggable,
        },
        data() {
            return {
                SoftwareStatus,
                SoftwareLabel,
                softwares: [] as SoftwarePartner[],
                isLoading: false,
                filter: {
                    name: '',
                    tussenPersoon: '',
                    type: '',
                    customerType: ''
                }
            };
        },
        computed: {
            hasFiltered(): boolean {
                return this.softwares.length !== this.filteredSoftwares.length;
            },
            filteredSoftwares(): SoftwarePartner[] {
                const filtered = this.softwares
                    .filter(software => {
                        const matchesName = 
                            software.name.cw_contains(this.filter.name);

                        const matchesTussenPersoon = 
                            software.tussenPersoon?.companyName.cw_contains(this.filter.tussenPersoon) ?? !this.filter.tussenPersoon;

                        const matchesType = 
                            (this.filter.type === '') || 
                            (this.filter.type === 'generic' && !software.isCustom) || 
                            (this.filter.type === 'custom' && software.isCustom);

                        const matchesCustomerType = 
                            (this.filter.customerType === '') || 
                            (this.filter.customerType === 'customer' && !software.isProspect) || 
                            (this.filter.customerType === 'prospect' && software.isProspect);

                        return matchesName && matchesType && matchesTussenPersoon && matchesCustomerType;
                    });

                return filtered;
            }
        },
        methods: {
            onDragged(event: any) {
                const newIndex: number = event.newIndex;
                const thisSoft: SoftwarePartner = this.softwares[newIndex];
                const prevSoft: SoftwarePartner | undefined = this.softwares[newIndex - 1];

                  Ajax.post(
                    "/ajax/cms/software-integrations/update-order",
                    {
                        softwareId: thisSoft.id,
                        previousSoftwareId: prevSoft?.id ?? null
                    },
                    (response) => {
                        Toast.success('Volgorde opgeslagen.');
                    },
                    (error) => {
                        console.error(error);
                    }
                )
            },
            getAllSoftwares(): Promise<SoftwarePartner[]> {
                return new Promise((resolve, reject) => {
                    Ajax.get(
                        '/ajax/cms/software-integrations/all',
                        {},
                        response => {
                            resolve(response.data);
                        },
                        error => {
                            reject(error);
                        }
                    );
                });
            },
            async editSoftware(software: SoftwarePartner): Promise<void> {
                let filterQueryString: string = _.reduce(this.filter, (acc: string, curr: string, prop: string) => {
                    return curr 
                        ? acc + prop + "=" + curr  + "&"
                        : acc;
                }, "?");
             
                filterQueryString = filterQueryString.slice(0, -1); 
                
                window.history.pushState(
                {},
                'Cms software integrations',
                '/cms/software-integrations/' + filterQueryString);

                await this.$router.push({ 
                    name: 'edit', 
                    params: { softwareId: software.id+"" } 
                });
            },
            tryDelete(software: SoftwarePartner): void {
                Confirmation(
                    () => this.deleteSoftware(software), 
                    {
                        title: software.name + ' verwijderen?',
                        body: 'Deze actie kan niet ongedaan worden gemaakt.'
                    }
                )
            },
            deleteSoftware(software: SoftwarePartner): void {
                const name = software.name;
                Ajax.post(
                    '/ajax/cms/software-integrations/delete',
                    {
                        softwareId: software.id
                    },
                    response => {
                        Toast.success(name + ' verwijderd!');
                        this.softwares = this.softwares.filter(s => s.id !== software.id);
                    },
                    error => {
                        Toast.error(error);
                    }
                )
            },
            getQSParameterByName(name: string): string {
                name = name.replace(/[\[\]]/g, '\\$&');
                var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(window.location.href);
                if (!results) return '';
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            }
        },
        async mounted() {
            this.isLoading = true;
            this.softwares = await this.getAllSoftwares();
            this.isLoading = false;

            this.filter.name = this.getQSParameterByName('name');
            this.filter.tussenPersoon = this.getQSParameterByName('tussenPersoon');
            this.filter.customerType = this.getQSParameterByName('customerType');
            this.filter.type = this.getQSParameterByName('type');
        }
    });
</script>

<style scoped>
    .green-row {
        background-color: #20c99714 !important;
    }

    .yellow-row {
        background-color: #c9aa2014 !important;
    }

    .move {
        cursor: move;
    }
</style>