﻿<template>
 <tr>
     <td>
         <i v-if="isSubmandate" class="fas fa-level-up-alt fa-rotate-90 mr-2"></i>
         <i class="fas fa-book mr-3" :class="mandate.status"></i>
         <a :href="mandate.detailsUrl" target="_blank" :disabled="!mandate.detailsUrl">
            {{mandate.mandateHolderName}}
         </a>
     </td>
     <td v-if="!isMobile"><span v-if="!mandate.isHuidig">{{mandate.einde|showYear}}</span></td>
     <td v-if="!isMobile">{{mandate.companyEndDate|showYear}}</td>
     <td><barometer-icon :className="mandate.companyBarometer" :score="mandate.companyBarometerScore" v-if="canSeeScores && !!mandate.companyBarometer"></barometer-icon></td>
 </tr>
</template>

<script lang="ts">
    import Vue from 'vue';
    import {Mandate, MandateStatus} from './types';
    import BarometerIcon from '../shared/barometer-icon.vue';

    export default Vue.extend({
        name: "mandate-row.vue",
        components: {
            BarometerIcon
        },
        props: {
            mandate: Object as () => Mandate,
            isSubmandate: Boolean,
            isMobile: Boolean,
            canSeeScores: Boolean,
            shouldSeeUpsell: Boolean
        },
        data() {
            return {
                
            }
        },
        filters: {
            showYear: function(num: number|string|null): string | null {
                if(!num)
                    return null;

                const str = num.toString();
                const matches = /^(\d{4})\d{4}$/.exec(str);

                //@ts-ignore
                return (matches?.length ?? 0) >= 2 ? matches[1] : str;
            }
        }
    });
</script>

<style scoped>

</style>