
<div class="upsell-banner" :class="{'bg-white': white, 'border': border}">
    <div class="image">
        <img src="/images/upsell.svg" :alt="title"/>
    </div>
    <div class="content">
        <h3>{{title}}</h3>
        <div class="text">
          <p v-html="text"></p>
          <a :href="href" class="btn btn-success">{{button}}</a>
        </div>
    </div>
</div>
