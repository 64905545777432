
<div class="credits">
    <div class="number">{{amount.formatThousandSeparator()}}</div>
    <div class="label">
        {{label}}
        <span v-if="!!labelInfo" :data-content="labelInfo">
            <i class="text-white fas fa-info-circle ml-1"></i>
        </span>
    </div>
</div>
