﻿<template>
    <div style="min-width: 360px;" class="radar-cell">
        <div style="min-width:33px;">
            <barometer-icon-square :class-name="barometer" v-if="barometer"></barometer-icon-square>
        </div>
       <div class="w-full">
           <div class="d-flex justify-content-between">
            <span>
                <a :disabled="confidential" class="font-weight-bold underline" :href="'/company/'+maybeConfidentialVat" target="_blank" :title="companyName">
                    <span v-confidential="confidential">{{companyName.truncate(29)}} </span>
                </a><span class="font-small ml-1">({{vorm}})</span>
            </span>
               <span class="flex space-x-1">
                <a :title="favouritesLabel" @click.prevent="addToOrRemoveFromFavourites" href="" class="icon-btn"><i class="far fa-star" :class="{'fas text-yellow': isInFavourites}"></i></a>
                <a :title="translate('add_to_alerts')" @click.prevent="addToAlerts" href="" class="icon-btn"><i class="far fa-bell"  :class="{'fas text-yellow': isInAlerts || store.vatsInAlerts.includes(vat)}"></i></a>
            </span>
           </div>
           <div class="font-small">
               <span v-confidential="confidential">{{vat.formatVat()}}</span>
           </div>
           <div class="text-grey font-small">
               <ul>
                   <li v-if="address">
                       <i class="fas fa-chair-office mr-1" :title="translate('maatschappelijke_zetel')"></i>
                       <a :title="translate('search_on_address')" :disabled="confidential" target="_blank" :href="getSearchOnAddressLink(address)" v-confidential="confidential">{{address.straatNaam}} {{address.huisNummer}}{{address.busNummer}}, {{address.postcode}} {{address.gemeente}}</a>
                   </li>
                   <li v-for="adres in extraAddresses">
                       <i class="fas fa-store mr-1" :title="translate('vestiging')"></i>
                       <a :title="translate('search_on_address')" :disabled="confidential" target="_blank" :href="getSearchOnAddressLink(adres)" v-confidential="confidential">{{adres.straatNaam}} {{adres.huisNummer}}{{adres.busNummer}}, {{adres.postcode}} {{adres.gemeente}}</a>
                   </li>
               </ul>
           </div>
       </div>
    </div>
</template>

<script lang="ts">
    import {Adres} from '../../types/dto/Adres';
    import BarometerIconSquare from './barometer-icon-square.vue';
    import Confidential from '../directives/confidential';
    import Ajax from '../../lib/Ajax';
    import Toast from '../../lib/Toast';
    import Modal from '../shared/modal.vue';
    import Translate from '../mixins/translate';
    import {useStore} from "../radar/store";

    export default  Translate.extend({
        name: "company-list-item.vue",
        components: {BarometerIconSquare, Modal},
        directives: {Confidential},
        props: {
            companyName: {
                type: String,
                required: true
            },
            vat: {
                type: Number  
            },
            vorm: {
                type: String,
                required: true 
            },
            barometer: {
                type: String 
            },
            address: {
                type: Object as () => Adres
            },
            extraAddresses: {
                type: Array as () => Adres[]
            },
            confidential: Boolean,
            isInAlerts: Boolean
        },
        data() {
            return {
                maybeConfidentialVat: this.confidential 
                    ? 111111111 
                    : this.vat,
                store: useStore()
            }
        },
        computed: {
            favouritesLabel(): string {
                return this.isInFavourites
                    ? this.translate('remove_from_favourites')
                    : this.translate('add_to_favourites');
            },
            isInFavourites(): boolean {
                return this.store.vatsInFavourites.includes(this.vat);
            } 
            
        },
        methods: {
            getSearchOnAddressLink(address: Adres): string {
                return `/search/address?PostalCode=${address.postcode}&City=${address.gemeente}&Street=${address.straatNaam}&Number=${address.huisNummer}`
            },
            async addToAlerts() {
                if (this.isInAlerts || this.store.vatsInAlerts.includes(this.vat)) {
                    Toast.warning(this.translate("is_already_followed", this.companyName));
                    return;
                }

                if (this.store.alertsLists.length === 1) {
                    await this.store.addToAlertsList(this.vat, this.companyName, this.store.alertsLists[0].listId);
                } else if (this.store.alertsLists.length > 1) {

                    this.store.addToAlertsVat = this.vat;
                    this.store.addToAlertsName = this.companyName;
                    this.$root.$emit('show-modal', "add-to-alerts");
                } else {
                    Toast.warning(this.translate('no_alerts_lists'));
                }
            },
            async addToOrRemoveFromFavourites() {
                const vat = this.vat;
                
                const message = this.isInFavourites
                    ? await this.removeFromFavourites(vat)
                    : await this.addToFavourites(vat);
                
                Toast.success(message)
            },
            async addToFavourites(vat: number): Promise<string> {
                await Ajax.postAsync("/ajax/company/add-to-favourites", {vat});
                this.store.vatsInFavourites.push(vat);
                
                return this.translate('added_to_favourites');
            },
            async removeFromFavourites(vat: number): Promise<string> {
                await Ajax.postAsync("/ajax/company/remove-from-favourites", {vat});
                this.store.vatsInFavourites.remove(vat);
                
                return this.translate('removed_from_favourites');
            }
        }
    });
</script>

<style scoped lang="scss">
.radar-cell {
    gap: 12px;
    display: flex;
    align-items: self-start;
}
    ul > li > i {
        width: 18px;
        font-size: 11px;
        text-align: center;
    }
    
    .icon-btn {
        padding: 0 2px;
        color: lightgrey;
        border-radius: 3px;
        display: inline-block;
    }

    .icon-btn:hover i {
      
      color: grey !important;
    }
    
    .underline {
        text-decoration: underline;
    }
</style>