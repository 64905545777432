<template>
    <div>
        <h2 class="mb-4"><b>{{translate("sidebar_barometer")}}</b></h2>

        <div class="row mb-4">
            <div class="col-md-8 my-auto">
                <p>
                    {{translate("barometer_description")}}
                </p>
            </div> 
            <div class="col-md-4 text-center">
                <img-barometer :is-demo="false" class-name="pos-26" :score="2.6" no-animate></img-barometer>
            </div>
        </div>

        <div class="px-5 px-lg-0">
             <div class="mb-3">
                <input type="radio" id="all" :checked="selectedValue === null" @change="selectNewRange(false)"> 
                <label for="all">{{translate("barometer_all_companies")}}</label>
            </div>

            <div class="mb-2">
                <input type="radio" id="nl-only" :checked="selectedValue !== null" @change="selectNewRange(true)"> 
                <label for="nl-only">{{translate("barometer_only_with_score")}}</label>
            </div>
        
            <range-slider 
                :disabled="selectedValue === null"
                is-linear
                :min="min" 
                :max="max" 
                :formatLabel="formatLabel"
                :lowerBound="lowerBound" 
                :upperBound="upperBound" 
                @update:min="updateMin"
                @update:max="updateMax"></range-slider> 
        </div>
    </div>
</template>

<script lang="ts">
    import { FormatLabel } from '../../types';
    import RangeSlider from '../shared/range-slider/index.vue';
    import ImgBarometer from '../../../barometer/barometer.vue';
    import CriteriumRange from '../../criteriumRange';
    import DataMarketingService, { Request } from '../../dataMarketingService';
    import { ProspectieDataSelectionCriteriumType } from '../../../../types/dto/ProspectieDataSelectionCriteriumType';
    import { ProspectieDataRange, ProspectieDataRangeOperator } from '../../../../types/dto/ProspectieDataRange';
    import Translate from '../../../mixins/translate';
    import mixins from 'vue-typed-mixins';  


    const formatLabel: FormatLabel = ((count: number) => (count / 10).toString().replace(".",","));

    export default mixins(Translate).extend({
        name: 'dm-barometer',
        components: {RangeSlider, ImgBarometer},
        data() {
            return {
                prospectieCriteria: DataMarketingService.getInstance().treeData!,
                min: -50,
                max: 50,
                lowerBound: -50,
                upperBound: 50,
                formatLabel: formatLabel
            }
        },
        computed:{
            currentRequest(): Request{
                return DataMarketingService.getInstance().currentRequest;
            },
            selectedValue(): ProspectieDataRange | null {
                const selectedCriteria = DataMarketingService.getInstance().currentRequest!.selectedCriteria!.filter(c => c.type === ProspectieDataSelectionCriteriumType.RISICOPROFIEL);

                return selectedCriteria.firstElement().map(v => v.range).valueOrNull();
            }
        },
        methods: {
            async selectNewRange(v: boolean) {
                if(!v) {
                     await this.currentRequest.removeCriteriumByType(ProspectieDataSelectionCriteriumType.RISICOPROFIEL);
                     this.reset();
                } else {
                    await this.currentRequest.addRange(new CriteriumRange(
                        ProspectieDataRangeOperator.BETWEEN, 
                        ProspectieDataSelectionCriteriumType.RISICOPROFIEL, 
                        this.lowerBound, 
                        this.upperBound));
                }
                
                await this.currentRequest.updateSummary();
            },
            async updateMin(v: number) {
                this.min = v;
                await this.currentRequest.removeCriteriumByType(ProspectieDataSelectionCriteriumType.RISICOPROFIEL);

                if (v !== this.lowerBound || this.max !== this.upperBound)
                    await this.currentRequest.addRange(new CriteriumRange(ProspectieDataRangeOperator.BETWEEN, ProspectieDataSelectionCriteriumType.RISICOPROFIEL, v, this.max));

                await this.currentRequest.updateSummary();
            },
            async updateMax(v: number) {
                this.max = v;
                await this.currentRequest.removeCriteriumByType(ProspectieDataSelectionCriteriumType.RISICOPROFIEL);

                if (v !== this.upperBound || this.min !== this.lowerBound)
                    await this.currentRequest.addRange(new CriteriumRange(ProspectieDataRangeOperator.BETWEEN, ProspectieDataSelectionCriteriumType.RISICOPROFIEL, this.min, v));

                await this.currentRequest.updateSummary();
            },
            reset() {
                this.min = this.lowerBound;
                this.max = this.upperBound;
            }
        },
        created() {
            this.min = this.currentRequest.selectedCriteria?.filter(c => c.type === ProspectieDataSelectionCriteriumType.RISICOPROFIEL).firstElement().map(v => v?.range?.lowerBound).valueOr(this.lowerBound) ?? this.lowerBound;
            this.max = this.currentRequest.selectedCriteria?.filter(c => c.type === ProspectieDataSelectionCriteriumType.RISICOPROFIEL).firstElement().map(v => v?.range?.upperBound).valueOr(this.upperBound) ?? this.upperBound;
        },
        mounted() {
            this.$root.$on('delete-filter', (type: ProspectieDataSelectionCriteriumType) => {
                if(type === ProspectieDataSelectionCriteriumType.RISICOPROFIEL) {
                    this.reset();
                }
            });
        }
    })
</script>

<style scoped>

</style>