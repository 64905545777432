﻿<template>
    <tr role="row" class="event-row">
        <td width="40" class="event-cell">
            <i class="fas type-icon" :class="[color,typeToIcon(type)]"></i>
        </td>
        <td class="event-cell" v-html="htmlDescription"></td>
    </tr>
</template>

<script lang="ts">
    import Vue from 'vue';
    import {AlertsEventType, EventColor} from './types';

    export default Vue.extend({
        name: 'event',
        props: {
            changeDirection: String,
            date: Number,
            htmlDescription: String,
            vat: Number,
            type: String as () => AlertsEventType,
            color: String as () => EventColor
        },
        methods: {
            typeToIcon(type: AlertsEventType): string {
                switch (type) {
                    case AlertsEventType.KREDIETLIMIET:
                        return "fa-euro-sign";
                    case AlertsEventType.BAROMETER:
                        return "fa-tachometer-alt";
                    case AlertsEventType.ADRES:
                        return "fa-truck-moving";
                    case AlertsEventType.NAAM:
                        return "fa-pencil-alt";
                    case AlertsEventType.STATUS:
                        return "fa-heartbeat";
                    case AlertsEventType.INTERNATIONALEJURIDISCHEVORM:
                        return "fa-building";
                    case AlertsEventType.JURIDISCHEVORM:
                        return "fa-building";
                    case AlertsEventType.DOORHALING:
                        return "fa-exclamation-triangle";
                    case AlertsEventType.GERECHTELIJKAKKOORD:
                        return "fa-exclamation-triangle";
                    case AlertsEventType.RSZDAGVAARDING:
                        return "fa-exclamation-triangle";
                    case AlertsEventType.CURRATORAANGESTELD:
                        return "fa-gavel";
                    case AlertsEventType.FAILLISSEMENT:
                        return "fa-gavel";
                    case AlertsEventType.PUBLICATIE:
                        return "fa-file-alt";
                    case AlertsEventType.JAARREKENING:
                        return "fa-file-alt";
                    default:
                        return "fa-check";
                }
            },
        }
    });
</script>