﻿<template>
    <div>
        <form v-if="!isSent" :action="action" method="POST" @submit.prevent="onSubmit">
            <button type="submit" :class="{'btn-success': successButton,'btn-info': !successButton, 'btn': !isTextLink, 'link': isTextLink}">{{buttonText}}</button>
        </form>
        <template v-else>
            <template v-if="isSuccess">
                {{onSuccess}}
            </template>
            <template v-else>
                {{onFailure}}
            </template>
        </template>
    </div>
</template>

<script type="text/javascript" lang="ts">
    import Vue from 'vue';
    import axios from 'axios';

    export default Vue.extend({
        name: 'ajax-button',
        props: {
            action: String,
            buttonText: String,
            onSuccess: String,
            onFailure: String,
            successButton: Boolean,
            isTextLink: Boolean
        },
        data() {
            return {
                isSent: false,
                isSuccess: false
            }
        },
        computed: {
          
        },
        methods: {
            async onSubmit() {
                const {data: response} = await axios.post<AjaxResponse<string>>(this.action, null);
                this.isSuccess = !!response.data;
                this.isSent = true;
            }
        },
        mounted() {
        
        }
    });
</script>