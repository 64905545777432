
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>{{translate('international_search')}}</h1>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12">
                <international-search :countries="countries"></international-search>
            </div>
<!--            <div class="col-md-5" v-if="hasFollowup">-->
<!--                <followup-box :hasFollowup="hasFollowup"></followup-box>-->
<!--            </div>-->
        </div>

        <div class="row mt-5" v-if="companies.length > 0">
            <div class="col-12">
                <companies :countries="countries" :companies="companies" :hasFollowup="hasFollowup"></companies>
            </div>
        </div>
    </div>
