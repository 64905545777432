﻿import Ajax from '../lib/Ajax';
import Confirm from '../lib/Confirmation';
import Translate from '../lib/Translate';
import Page from '../lib/Page';

export default (function () {

    Page.registerBehavior('#logoutLink', function (element) {
        var link = element;

        function logout(isConfirmed: boolean, dontShowDialogAgain: boolean) {
            Ajax.post(
                '/ajax/account/logout',
                {
                    isConfirmed: isConfirmed,
                    dontShowDialogAgain: dontShowDialogAgain
                },
                function success(result) {
                    if (!result.Data.isConfirmed) {
                        Confirm(function (dontShowAgain: any) {
                            logout(true, !!dontShowAgain);
                        },
                        {
                            title: Translate('signout_confirm_title'),
                            body: Translate('signout_confirm_body'),
                            type: 'warning',
                            inputPlaceholder: Translate('signout_confirm_checkbox_label'),
                            confirmButtonText: Translate('sign_out')
                        });
                    } else if (result.Data.isConfirmed) {
                        window.location.href = "/";
                    } else {
                        console.error(result);
                    }
                },
                function error(message) {
                    console.error(message);
                }
            );
        }

        link.addEventListener('click', function (e) {
            e.preventDefault();

            logout(false, false); 
        });
    });  

}());
