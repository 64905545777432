
<div class="row">
    <div class="col-md-12">
        <div class="row mb-2">
            <div class="col-md-3 col-6">
                <label>{{translate('address')}}</label>
                <div class="filter-input">
                    <i class="fas fa-filter"></i>
                    <input type="text" :placeholder="translate('filter_on_address')" v-model="filter.location" />
                </div>
            </div>
        </div>
        <template v-if="Addresses.length">
            <table class="table table-striped table-small data-table">
                <thead>
                    <tr>
                        <th class="d-none d-lg-table-cell">{{translate('city')}}</th>
                        <th class="d-none d-lg-table-cell">{{translate('street')}}</th>
                        <th width="110px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr is="address-row" v-for="address in Addresses" :key="address.Id" v-bind="address" :cant-remove="currentList.IsReadOnly"></tr>
                </tbody>
            </table>
            <div class="text-grey font-small py-5 text-center text-uppercase" v-if="!hasShownAllAddresses"><i class="fas fa-arrow-down mr-2"></i> {{translate('scroll_to_show_more')}}</div>
        </template>
        <template v-else>
            <p class="mt-5">
                {{translate('no_results')}}
            </p>
        </template>
    </div>
</div>
