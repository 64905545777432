﻿<template>
    <form class="wrapper" @submit.prevent="onSubmit">
        <div class="top-view">
            <h2 class="font-weight-bold">{{translate('save_filter')}}</h2>
            <p>{{translate('save_filter_desc')}}</p>
        </div>
        <div class="main-view">
            <div>
                <label for="nameFilter">{{translate('filter_name')}}</label>
                <input type="text" class="form-control" id="nameFilter" v-model="title" />
            </div>
            <template v-if="$v.title.$invalid && $v.title.$dirty">
                <div class="card-box small my-2 warning" v-if="!$v.title.required">
                    {{translate('validation_error_required', translate("title"))}}
                </div>
                <div class="card-box small my-2 warning" v-else-if="!$v.title.maxLength">
                    {{translate('validation_error_max_length', translate("title"), 50)}}
                </div>
                <div class="card-box small my-2 warning" v-else-if="!$v.title.minLength">
                    {{translate('validation_error_min_length', translate("title"), 3)}}
                </div>
            </template>
        </div>
        <div class="bottom-view">
            <a href="" class="btn btn-white" @click.prevent="close">{{translate('cancel')}}</a>
            <button class="btn btn-primary" type="submit">{{translate('save_filter')}}</button>
        </div>
    </form>
</template>

<script lang="ts">
import {useStore} from "../../store";
import Translate from "../../../mixins/translate";
import Ajax from "../../../../lib/Ajax";
//@ts-ignore
import {maxLength, minLength, required} from 'vuelidate/dist/validators.min';

export default Translate.extend({
    name: "filter-save",
    data() {
        return {
            store: useStore(),
            title: ''
        }
    },
    validations: {
        title: {
            required: required,
            minLength: minLength(3),
            maxLength: maxLength(50)
        }
    },
    methods: {
        close() {
            this.store.closeDrawer();
        },
        async onSubmit(): Promise<void> {
            this.$v.$touch();
            if(this.$v.$anyError)
                return;


            await Ajax.postAsJsonAsync("/ajax/radar/save-filter", {
                title: this.title,
                datasetId: this.store.currentDataset.id,
                criteria: this.store.filters
            });

            this.$v.$reset();
            window.location.reload();
        },
    }
});
</script>

<style scoped lang="scss">
.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    .top-view {
        padding: 30px;

    }
    .main-view{
        padding: 0 30px;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 1%; /* zorgt voor de scrollbar */
    }

    .bottom-view {
        padding: 30px;
        border-top: 1px solid lightgrey;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .button-group {
            display: flex;
            gap: 5px;
        }
    }
}
</style>