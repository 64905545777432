
<g>
    <path fill="transparent"
          class="kyc-edge"
          :stroke-width="strokeWidth"
          stroke-opacity="1"
          :stroke="strokeColor"
          :d="drawline"
          :stroke-dasharray="dashArray" style="pointer-events: none">
    </path>
    <g :transform="sharesCircleTransform" v-if="info.linkType === 'shareholder' || info.linkType === 'mandateholder-shareholder'">
        <circle class="kyc-link-type" :r="circleRadius" cx="0" cy="0" :stroke="sharesStrokeColor" fill="white"></circle>

        <text :transform="'translate(5, '+mainSharesYOffset+')'" fill="#7F90A0" font-size="12" text-anchor="end" font-weight="bold">
            <tspan v-if="shares.isKnown">
                {{shares.whole}}
            </tspan>
            <tspan v-else>
                ?
            </tspan>
        </text>
        <text v-if="shares.decimals" :transform="'translate(5, '+decimalSharesYOffset+')'" fill="#7F90A0" font-size="9" text-anchor="end">,{{shares.decimals}}</text>
        <text v-if="shares.isKnown" :transform="'translate(6, '+mainSharesYOffset+')'" fill="#7F90A0" font-size="10" text-anchor="start" font-weight="bold">%</text>
    </g>
</g>
