﻿<template>
    <g v-if="show">
        <rect rx="3" :x="posX" :y="y-30" :width="infoWidth" height="20" :fill="bgColor|toHexCode"></rect>
        <text :x="posX + 5" :y="y-16" font-family="Verdana" font-size="13" fill="white">{{value|formatEuro}}</text>
    </g>
</template>

<script>
    export default {
        name: 'node-info',
        data() {
            return {
                bgColor: 'transparent',
                value: 0,
                x: 0,
                y: 0,
                show: false
            }
        },
        computed: {
            infoWidth() {
                return (this.amountOfExtraCharacters + this.valueLength) * 8;
            },
            valueLength() {
                return this.value.toString().length;
            },
            amountOfExtraCharacters() {
                return Math.floor(Math.log10(Math.abs(this.value)) / 3) + 3;
            },
            offsetX() {
                return (this.infoWidth / 2) * -1;
            },
            posX() {
                return this.x + this.offsetX;
            }
        },
        mounted() {
            this.$root.$on('start-hover', nodeInfo => {
                this.show = true;
                this.value = nodeInfo.value;
                this.x = nodeInfo.x;
                this.y = nodeInfo.y;
                this.bgColor = nodeInfo.bgColor; 
            });

            this.$root.$on('end-hover', () => {
                this.show = false;
            });
        }
    }
</script>