﻿<template>
    <div class="row">
        <div class="col-md-4">
            <img src="/images/aanbod/alerts-plus.png" width="350" />
        </div>
        <div class="col-md-8">
            <p>
                Alerts+, speciaal ontworpen voor het opvolgen van een groot aantal bedrijven.
                De Alert Service van Companyweb kent u al. Met deze dienst kan u specifieke klanten nauwlettend in het oog houden.
                U kan een lijst aanmaken van de klanten die u wil opvolgen en Companyweb informeert u
                automatisch zodra er een belangrijke wijziging gebeurt bij de bedrijven opgenomen in uw klantenlijst.
            </p>

            <p>
                Alerts+ gaat een stap verder en geeft u meer mogelijkheden:
            </p>
            <ul class="check-list">
                <li>Ontworpen om een groot aantal bedrijven op te volgen (meer dan 1000 bedrijven)</li>
                <li>Een overzichtelijk dashboard die alle belangrijke wijzigingen weergeeft</li>
                <li>Mogelijkheid om deze informatie naar Excel te exporteren</li>
                <li>Overzicht van de historiek voor de opgevolgde bedrijven</li>
            </ul>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'feature-alerts-plus'
    }
</script>