
<div>
    <transition name="fade">
        <div class="drawer-backdrop" @click="closeDrawer" v-show="isOpen"></div>
    </transition>
    <transition name="slide">
        <aside class="drawer" v-show="isOpen">
            <component :is="activeDrawerView"></component>
        </aside>
    </transition>
</div>
