﻿import _ from 'lodash';
import Vue from 'vue';
import Page from '../../lib/Page';
import AccountUsers from './account-users.vue';
import { User, EditablePermissions, Company } from './types';


export default (function () {
    Page.registerVue('#user-management', function (rootSelector) {
 
        new Vue({
            el: rootSelector,
            name: 'account-users-wrapper',
            components: {
                AccountUsers
            },
            data(){
                return {
                    users: window.cw.users as User[],
                    currentUserEmail: window.cw.currentUserEmail as string,
                    isLoggedInAsAdmin: window.cw.isLoggedInAsAdmin as boolean,
                    userLimitReached: window.cw.userLimitReached as boolean,
                    userLimit: window.cw.userLimit as number,
                    editablePermissions: {
                        vervaldagboek: window.cw.editablePermissions.vervaldagboek,
                        alertsPlus: window.cw.editablePermissions.alertsPlus,
                        internationaal: window.cw.editablePermissions.internationaal,
                        addUsers: window.cw.editablePermissions.addUsers,
                        dataMarketing: window.cw.editablePermissions.dataMarketing,
                        radar: window.cw.editablePermissions.radar,
                        seeShareLink: window.cw.editablePermissions.seeShareLink
                    } as EditablePermissions,
                    apAccesslevels: window.cw.apAccesslevels,
                    irAccesslevels: window.cw.irAccesslevels,
                    dmAccesslevels: window.cw.dmAccessLevels,
                    company: {
                        name: window.cw.companyName,
                        vat: window.cw.vat
                    } as Company,
                    hasJoinLink: window.cw.hasJoinLink as string,
                    joinLink: window.cw.joinLink as string,
                    upgradeUsersLink: window.cw.upgradeUsersLink as string,
                    avLink: window.cw.AVLink as string
                }
            },
            mounted() {
                Page.applyBehaviors(rootSelector);

                this.$root.$on('userPromoted', (userId: number) => {
                    const promotedUser = _.find(this.users, (user:User) => user.userId === userId);

                    if (promotedUser)
                        promotedUser.isAdmin = true;
                });

                this.$root.$on('userDemoted', (userId: number) => {
                    const demotedUser = _.find(this.users, (user:User) => user.userId === userId);

                    if (demotedUser)
                        demotedUser.isAdmin = false;
                });
            }
        });
    });
}());
