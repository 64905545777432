import Page from '../lib/Page';
import _ from 'lodash';

export default (function () {
    type FormFieldElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

    Page.registerBehavior('form', function (el: Element) {
        const form = <HTMLFormElement>el;
        const fields: NodeListOf<FormFieldElement> = form.querySelectorAll('[data-val]');
        
        if (form.getAttribute('no-validate') || form.getAttribute('data-confirmation'))
            return;
 

        form.addEventListener('submit', (ev: Event) => {
            ev.preventDefault();
            _.forEach(fields, (field) => {
                validateField(field);
            });

            const invalidFields: NodeListOf<FormFieldElement> = form.querySelectorAll('.input-validation-error');
        
            if(invalidFields.length === 0)
                form.submit();
        });
        
        _.forEach(fields, (field) => {
            field.addEventListener('input', (ev: Event) => {
                validateField(field);
            });
        });


        function validateField(field: FormFieldElement){
            checkForRequired(field);
            checkForRegex(field);
            checkForMinLength(field);
            checkForMaxLength(field);
            checkForRequireChecked(field);
        }

        function checkForRequired(field: FormFieldElement){
            const isRequiredMessage = field.getAttribute('data-val-required');
            if(!isRequiredMessage)
                return;
            
            let isValid = false;

            if(field.type === 'radio') {
                const name = field.name;
                isValid = _.some(document.querySelectorAll('[name='+name+']'), radio => {
                    return (radio as HTMLInputElement).checked === true;
                });
                console.log(isValid);
            }
            else
                isValid = field.value !== '';

            showMessageIfInvalid(field, isValid, isRequiredMessage);
        }

        function checkForRegex(field: FormFieldElement) {
            const message = field.getAttribute('data-val-regex');
            const regexPattern: string | null = field.getAttribute('data-val-regex-pattern');

            if(!regexPattern || !message || !field.value)
                return;

            const regex: RegExp = new RegExp(regexPattern);
            const isValid = regex.test(field.value);
            
            showMessageIfInvalid(field, isValid, message);
        }

        function checkForMinLength(field: FormFieldElement) {
            const message = field.getAttribute('data-val-minlength');
            const minLength: string | null = field.getAttribute('data-val-minlength-min');

            if(!message || !minLength || !field.value)
                return;

            const isValid = field.value.length >= parseInt(minLength);

            showMessageIfInvalid(field, isValid, message);
        }

        function checkForMaxLength(field: FormFieldElement) {
            const message = field.getAttribute('data-val-maxlength');
            const maxLength: string | null = field.getAttribute('data-val-maxlength-max');

            if(!message || !maxLength || !field.value)
                return;

            const isValid = field.value.length <= parseInt(maxLength);

            showMessageIfInvalid(field, isValid, message);
        }

        function checkForRequireChecked(field: FormFieldElement) {
            const message = field.getAttribute('data-val-required-checked');
            const isCheckbox = field.type === 'checkbox';

            if(!message || !isCheckbox)
                return;

            const isValid = (field as HTMLInputElement).checked;
            showMessageIfInvalid(field, isValid, message);
        }

        function showMessageIfInvalid(field: FormFieldElement, isValid: boolean, message: string): void {
            const name = field.name.replace(".", "\\.");
            const validationSpan = document.querySelector('[data-valmsg-for='+name+']');
    
            if(!validationSpan) 
                return;
            
            if(isValid) {
                validationSpan!.classList.add('field-validation-valid');
                validationSpan!.classList.remove('field-validation-error');
                field.classList.remove('input-validation-error');
                
                (validationSpan as HTMLSpanElement)!.innerHTML = '';   
            } else {
                validationSpan!.classList.add('field-validation-error');
                validationSpan!.classList.remove('field-validation-valid');
                field.classList.add('input-validation-error');
                
                (validationSpan as HTMLSpanElement)!.innerHTML = message;
            }
        }
    });  
}());