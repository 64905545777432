﻿import Page from '../lib/Page';
import _ from 'lodash'

export default (function () {

    Page.register('featuretoggle-edit', function () {
        const form = document.getElementById('featureform')! as HTMLFormElement;
        const flagsField = form.querySelector('input[name=Flags]')! as HTMLInputElement;

        form.addEventListener('submit', (ev: Event) => {
            ev.preventDefault();
            const flags = form.querySelectorAll('input[type=checkbox]:checked, input[type=hidden]') as NodeListOf<HTMLInputElement>;
            const names: string[] = [];
            _.forEach(flags, el => {
                names.push(el.value);
            });

            flagsField.value = names.join(';');
       
            form.submit();
        });

        _.forEach(form.querySelectorAll('tr'), tr => {
            tr.addEventListener('click', (e: MouseEvent) => {
                const checkBoxWasClicked = (<HTMLElement>e.target!).tagName.toLowerCase() === 'input';

                if (!checkBoxWasClicked) {
                    const checkbox = (<HTMLElement>e.currentTarget).querySelector('input[type=checkbox]:not([disabled])')! as HTMLInputElement;
                    checkbox.checked = !checkbox.checked;
                }
            });
        });
    });

}());
