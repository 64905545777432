﻿export default function (key: string, ...args: any[]): string {
    window.cw = window.cw || {};
    window.cw.translations = window.cw.translations || [];

    const params: any[] = args;
    let errorMessage = '';

    key = key.toLowerCase().replace(/-/g, '_');
    
    
    if (window.cw.translations[key] === '')
        errorMessage = '"' + key + '" has an empty translation provided.';
    else if (!window.cw.translations[key])
        errorMessage = 'Could not translate "' + key + '" because it has not been provided via @Html.Raw(Translate.Js(...)).';

    if (errorMessage !== '') {
        console.error(errorMessage);
        return '[[' + key + ']]';
    } else {
        return window.cw.translations[key].replace(
            /\{(\d+)\}/g,
            (substring: string , index: number) => {
                if (params[index] === undefined) {
                    errorMessage = 'Could not translate "' + key + '" because there is no parameter for {' + index + '} in "' + window.cw.translations[key] + '".';
                    console.error(errorMessage);
                }
                return params[index];
            }
        );
    }
};
