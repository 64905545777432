﻿<template>
    <form class="wrapper" @submit.prevent="onSubmit">
        <div class="top-view">
            <div class="mb-3">
                <a href="" class="btn btn-sm btn-default" @click.prevent="backToList">
                    <i class="fas fa-arrow-left mr-"></i> {{translate('all_filters')}}
                </a>
            </div>
            <div class="row">
                <div class="col-md-12"><h1 class="font-weight-bold">{{translate('add_column')}}</h1></div>
            </div>

            <div class="mr-5 pr-5 mt-4">
                <div class="d-flex space-x-6 mb-4">
                    <div>
                        <input class="azure" id="boolean" type="radio" v-model="type" value="string">
                        <label for="boolean">{{ translate('text') }}</label>
                    </div>
                    <div>
                        <input  class="azure" id="string" type="radio" v-model="type" value="boolean">
                        <label for="string">{{ translate('checkbox') }}</label>
                    </div>
                </div>

                <div class="mb-4">
                    <h4 class="mb-2">{{ translate('title') }}</h4>
                    <input id="title" type="text" v-model="title" class="form-control">
                    <template v-if="$v.title.$invalid && $v.title.$dirty">
                        <div class="card-box small my-2 warning" v-if="!$v.title.required">
                            {{ translate('validation_error_required', translate("title")) }}
                        </div>
                        <div class="card-box small my-2 warning" v-else-if="!$v.title.maxLength">
                            {{ translate('validation_error_max_length', translate("title"), 20) }}
                        </div>
                        <div class="card-box small my-2 warning" v-else-if="!$v.title.minLength">
                            {{ translate('validation_error_min_length', translate("title"), 3) }}
                        </div>
                    </template>
                </div>
                <div class="mb-4">
                    <h4 class="mb-2">{{ translate('description') }}</h4>
                    <textarea v-model="description" class="form-control" rows="4"></textarea>
                </div>
                <div class="mb-4">
                    <h4 class="mb-2">{{ translate('width') }}</h4>
                    <input v-model="minWidth" class="form-control" type="number" min="50" max="250"/>
                    <div class="mt-2 text-grey small">min 50px - max 250px</div>
                </div>
            </div>
        </div>
        <div class="bottom-view">
            <a href="" class="btn btn-white" @click.prevent="close">{{translate('cancel')}}</a>
            <button class="btn btn-primary" type="submit">{{translate('add_column')}}</button>
        </div>
    </form>
</template>

<script lang="ts">
import {useStore} from "../../store";
import Translate from "../../../mixins/translate";
import {DrawerView} from "../../types";
//@ts-ignore
import {maxLength, minLength, required} from 'vuelidate/dist/validators.min';
import Modal from "../../../shared/modal.vue";
import Ajax from "../../../../lib/Ajax";

export default Translate.extend({
    name: "column-add",
    components: {Modal},
    data() {
        return {
            store: useStore(),
            type: 'string',
            title: '',
            description: '',
            minWidth: 50
        }
    },
    validations: {
        title: {
            required: required,
            minLength: minLength(3),
            maxLength: maxLength(20)
        },
        description: {
            minLength: minLength(3),
            maxLength: maxLength(250)
        }
    },
    methods: {
        close() {
            this.store.closeDrawer();
        },
        async onSubmit(): Promise<void> {
            console.log("et");
            this.$v.$touch();
            if(this.$v.$anyError)
                return;

            await Ajax.postAsync("/ajax/radar/add-column", {
                datasetId: this.store.currentDataset.id,
                type: this.type,
                title: this.title,
                description: this.description,
                minWidth: this.minWidth < 50 ? 50 : this.minWidth
            });

            window.location.reload();

            this.$v.$reset();
        },
        backToList() {
            this.store.openDrawer(DrawerView.COLUMN_EDIT);
        }
    },
    mounted() {
    }
});
</script>

<style scoped lang="scss">
.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;

    .top-view {
        padding: 30px;
        overflow-y: auto;
        overflow-x: hidden;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 1%; /* zorgt voor de scrollbar */

        label {
            font-weight: normal;
            font-size: 1rem;
        }
    }

    .bottom-view {
        padding: 30px;
        border-top: 1px solid lightgrey;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .button-group {
            display: flex;
            gap: 5px;
        }
    }
}
</style>