
<form @submit.prevent="onSubmit" class="w-full">
    <div class="row">
        <div class="col-8">
            <ul class="nav nav-tabs border-bottom mb-4">
                 <li class="nav-item" >
                    <a href="" class="nav-link" :class="{'active': currentTab === Tab.NEDERLANDS}" @click.prevent="changeTab(Tab.NEDERLANDS)">
                        <img src="/images/flags/nl.svg" class="mr-1 height-auto" width="13" height="13"> Nederlands</a>
                </li>
                <li class="nav-item" >
                    <a href="" class="nav-link" :class="{'active': currentTab === Tab.FRANS}" @click.prevent="changeTab(Tab.FRANS)">
                        <img src="/images/flags/fr.svg" class="mr-1 height-auto" width="13" height="13"> Francais</a>
                </li>
                <li class="nav-item" >
                    <a href="" class="nav-link" :class="{'active': currentTab === Tab.ENGELS}" @click.prevent="changeTab(Tab.ENGELS)">
                        <img src="/images/flags/gb.svg" class="mr-1 height-auto" width="13" height="13"> English</a>
                </li>
            </ul>
        </div>
        <div class="col-4 text-right">
            <button v-if="!isLoading" type="submit" class="btn btn-blue btn-200"><i class="fas fa-check mr-1"></i> Publiceren</button>
            <p v-else>Laden...</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12 mb-4" v-for="article in articles.filter(a => a.language == currentTab).sort((a,b) => a.articleNumber - b.articleNumber)">
            <label class="mb-1">Artikel {{article.articleNumber}} <a href="" @click.prevent="deleteArticle(article.articleNumber)" class="btn btn-danger btn-sm">DEL</a></label>
            <vue-editor v-model="article.body" :editorToolbar="customToolbarExcerpt"></vue-editor>
        </div>
        <div class="col-12 mb-4" v-for="article in newArticles.filter(a => a.language == currentTab).sort((a,b) => a.articleNumber - b.articleNumber)">
            <label class="mb-1">Nieuw artikel <a href="" @click.prevent="deleteNewArticle(article.articleNumber)" class="btn btn-danger btn-sm">DEL</a></label>
            <vue-editor v-model="article.body" :editorToolbar="customToolbarExcerpt"></vue-editor>
        </div>
    </div>

    <div class="col-12">
        <button class="btn btn-default" @click.prevent="addEmptyArticles"><i class="fas fa-plus mr-1"></i> Artikel toevoegen</button>
    </div>
</form>
