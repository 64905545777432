<template>
    <div>
        <div v-if="isLoading">
            <div class="py-5 text-center">
                <i class="fas fa-spinner spinning text-blue fa-2x"></i>
            </div>
        </div>
        <div v-else>
            <table class="data-table table table-striped mt-5">
                <thead>
                    <tr>
                        <th colspan="3">

                        </th>
                        <th>
                            <input type="search" class="form-control" placeholder="filter op NL titel" v-model="filter.title"/>
                        </th>
                    </tr>
                    <tr>
                        <th width="30"><i class="fa fa-globe" title="Published?"></i></th>
                        <th width="30"><i class="fa fa-thumbtack" title="Pinned?"></i></th>
                        <th width="120"></th>
                        <th><i class="fa fa-label" title="Titel"></i></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="article in filteredArticles" :key="article.id" @click="editArticle(article)" class="pointer blog-row">
                        <td width="30">
                          <i class="fas fa-globe text-teal" v-if="article.isPublished"></i>
                        </td>
                        <td width="30">
                          <i class="fas fa-thumbtack text-lightblue" v-if="article.isPinned"></i>
                        </td>
                        <td class="text-center">
                            <img :alt="article.titleNl" v-if="article.banner" :src="article.banner" />
                        </td>
                        <td>
                            <b>{{article.titleNl}}</b>
                            <div v-if="article.excerptNl" v-html="truncate(article.excerptNl, 100)"></div>
                            <div><span v-for="tag in article.relatedTags" :key="tag" class="badge badge-info badge-pill badge-sm mr-1">{{tag}}</span></div>
                        </td>
                        <td class="text-right" slot="footer">
                            <button @click.stop="tryDelete(article)" class="btn btn-default btn-sm"><i class="fas fa-trash-alt"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
    import Ajax from '../../../lib/Ajax';
    import Toast from '../../../lib/Toast';
    import Confirmation from '../../../lib/Confirmation';
    import { BlogArticle } from './types';
    import Page from '../../../lib/Page';
    import Truncate from '../../mixins/truncate';
    import mixins from 'vue-typed-mixins';
    

    export default mixins(Truncate).extend({
        name: "list_software",
        data() {
            return {
                articles: [] as BlogArticle[],
                isLoading: false,
                filter: {
                    title: ''
                }
            };
        },
        computed: {
            hasFiltered(): boolean {
                return this.articles.length !== this.filteredArticles.length;
            },
            filteredArticles(): BlogArticle[] {
                const filtered = this.articles
                    .filter(article => {
                        const matchesTitle = article.titleNl?.cw_contains(this.filter.title) ?? false;

                        return matchesTitle;
                    });

                return filtered.sort((a,b) => a.isPinned ? -1 : 1);
            }
        },
        methods: {
            async editArticle(article: BlogArticle): Promise<void> {
                   window.history.pushState(
                {},
                'Cms blog',
                '/cms/blog/');

                await this.$router.push({ 
                    name: 'blog-edit', 
                    params: { articleId: article.id+"" } 
                });
            },
            getAllArticles(): Promise<BlogArticle[]> {
                return new Promise((resolve, reject) => {
                    Ajax.get(
                        '/ajax/cms/blog/all',
                        {},
                        response => {
                            resolve(response.data);
                        },
                        error => {
                            reject(error);
                        }
                    );
                });
            },
            tryDelete(article: BlogArticle): void {
                Confirmation(
                    () => this.deleteArticle(article),
                    {
                        title: article.titleNl + ' verwijderen?',
                        body: 'Deze actie kan niet ongedaan worden gemaakt.'
                    }
                )
            },
            deleteArticle(article: BlogArticle): void {
                const title = article.titleNl;
                Ajax.post(
                    '/ajax/cms/blog/delete',
                    {
                        articleId: article.id
                    },
                    response => {
                        Toast.success(title + ' verwijderd!');
                        this.articles = this.articles.filter(s => s.id !== article.id);
                    },
                    error => {
                        Toast.error(error);
                    }
                )
            },
            getQSParameterByName(name: string): string {
                name = name.replace(/[\[\]]/g, '\\$&');
                var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(window.location.href);
                if (!results) return '';
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            }
        },
        async mounted() {
            Page.applyBehaviors('#cmsWrapper');
            this.isLoading = true;
            this.articles = await this.getAllArticles();
            this.isLoading = false;

            this.filter.title = this.getQSParameterByName('title');

        }
    });
</script>

<style scoped lang="scss">
    .blog-row {
        height: 90px;
        
        img {
            max-width: 100px;
            height: auto;
        }
    }
</style>