﻿import Page from '../lib/Page';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';

export default (function () {
    Page.registerBehavior('[data-content]', function (element: Element) {
        /* @ts-ignore*/
        const isIE = window.document.documentMode;
        // this node module breaks on IE, we will disable this behaviour on IE until support fully deprecates.

        if(isIE)
            return;

        tippy(
            '[data-content]',
            {
                allowHTML: true,
                interactive: true,
                interactiveDebounce: 25,
                theme: 'light-border',
                placement: 'right',
                content(reference) {
                    const content = reference.getAttribute('data-content')?.replace(/\n/g, "<br>");
                    return content ?? '';
                },
            }
        );
    });
}());