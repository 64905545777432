<template>
    <div class="row mt-5">
        <div class="col-12">
            <div class="row">
                <div class="col-md-4">
                    <input type="search" v-model="filterTerm" class="form-control" :placeholder="translate('filter_by_name').toLowerCase()"/>
                </div>
            </div>

            <div class="integration-grid mt-4">
                <div v-for="(integration, index) in filteredIntegrations" :key="index">
                    <a :href="integration.link"
                       class="border rounded d-block integration">
                        <div class="img-wrapper">
                            <img v-if="integration.logo" :src="integration.logo" class="img-fluid"/>
                            <img v-else src="/images/integratie/no-integration-logo.png" class="img-fluid"/>
                        </div>

                        <div class="distributor text-grey" v-if="integration.distributor !== ''">{{ integration.distributor }}</div>
                        <span v-if="integration.isActivated" class="badge badge-success">{{ translate('active') }}</span>
                        <span v-else-if="integration.wasActivated" class="badge badge-danger">{{ translate('expired') }}</span>

                        <span v-else-if="integration.label" class="badge" :class="{
                            'badge-warning':integration.label === SoftwareLabel.COMINGSOON,
                            'badge-purple': integration.label === SoftwareLabel.UPDATED,
                            'badge-info': integration.label === SoftwareLabel.RECENT
                            }">{{ translate(integration.label) }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Translate from '../../mixins/translate';
    import mixins from 'vue-typed-mixins';
    import {Integration} from '../types';
    import {SoftwareLabel} from '../../../types/dto/SoftwarePartner';

    export default mixins(Translate).extend({
        mixins: [Translate],
        data() {
            return {
                integrations: window.cw.integrations as Integration[],
                filterTerm: '',
                SoftwareLabel
            };
        },
        computed: {
            filteredIntegrations(): Integration[] {
                const filtered = this.integrations.filter(i => {
                    const matchesName = i.name.cw_contains(this.filterTerm);
                    const matchesAnyKeyWord = i.keyWords.some(keyWord => keyWord.cw_contains(this.filterTerm));

                    return matchesName || matchesAnyKeyWord;
                });

                return filtered.sort((a, b) => a.isActivated ? -1 : 1);
            }
        }
    });
</script>

<style scoped lang="scss">
    @import "../../../../sass/variables";

    .integration-grid {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 15px;

        @media #{$macbook} {
            grid-template-columns: repeat(4, 1fr);
        }

        @media #{$tablet} {
            grid-template-columns: repeat(3, 1fr);
        }

        @media #{$phone} {
            grid-template-columns: repeat(2, 1fr);
        }

        .integration {
            padding: 20px;
            text-align: center;
            position: relative;

            &:after {
                content: '';
                display: block;
                padding-bottom: 100%;
            }

            @media #{$tablet} {
                padding: 15px;
            }

            .img-wrapper {
                max-height: 90px;
                max-width: 90px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: auto;

                img {
                    flex-basis: 100%;
                    max-height: 100%;
                    max-width: 100%;
                }
            }

            &:hover {
                text-decoration: none;
                border-color: $azure !important;
                background-color: fade-out($azure, 0.95);
            }

            .badge {
                position: absolute;
                left: 50%;
                top: 10px;
                transform: translateX(-50%);
              
              &.badge-purple {
                background-color: #4f0cde;
              }
            }

            .distributor {
                position: absolute;
                bottom: -2px;
                left: 50%;
                transform: translate(-50%, -50%);
                line-height: 1.2em;
                width: 100%;
            }
        }
    }
</style>