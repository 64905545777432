import Vue from 'vue';
import Page from '../../lib/Page';
import MandateCard from './mandate-card.vue';

export default (function () {
    Page.registerVue('[vue-mandates]', function (rootSelector) {
        new Vue({
            el: rootSelector,
            name: 'mandates',
            components: {
                MandateCard,
            }
        });
    });
}());
