﻿import _ from 'lodash';
import Vue from 'vue';
import Ajax from '../lib/Ajax';
import Page from '../lib/Page';

export default (function () {

    Page.registerVue('#verifyForm', function (rootSelector) {

        new Vue({
            el: rootSelector,
            data: {
                email: window.cw.email || '',
                showSuccessMessage: false
            },
            methods: {
                newVerificationMail: function () {
                    var instance = this;

                    Ajax.post(
                        '/ajax/account/login',
                        {
                            email: instance.email,
                            isRetry: true,
                            isVerification: true
                        },
                        function () {
                            instance.showSuccessMessage = true;
                        },
                        function (error) {
                            console.error(error);
                        }
                    );
                }
            }
        });
    });

}());
