
<tr role="row" class="pointer">
    <td :class="{'faded': hasBeenRemoved}">
        <div class="flex-v-center">
            {{Lijn2}}
        </div>
    </td>
    <td :class="{'faded': hasBeenRemoved}">
        <div class="flex-v-center">
            <a :href="SearchAddressLink" class="tr-anchor">
                {{Lijn1}}
            </a>
            <a :href="SearchAddressLink" target="_blank" class="btn btn-transparent btn-sm d-inline ml-1" :title="translate('open_in_new_tab')">
                <i class="fas fa-external-link-alt"></i>
            </a>
        </div>
    </td>
    <template v-if="!cantRemove">
        <td class="remove-address-button excluded-from-click text-center"
            :title="translate('remove_address')"
            v-on:click="removeAddress()"
            width="50"
            v-if="!hasBeenRemoved">
            <i class="fas fa-trash-alt excluded-from-click"></i>
        </td>
        <td class="excluded-from-click text-center"
            :title="translate('restore_address')"
            v-on:click="addAddress()"
            width="50"
            v-else>
            <i class="fas fa-undo excluded-from-click"></i>
        </td>
    </template>
</tr>
