<template>
    <div>
        <h2 class="mb-4"><b>{{translate("sidebar_company_size")}}</b></h2>
        <form class="row" @submit.prevent="addRange()">
            <div class="col-5 col-md-4">
                <input type="number" class="form-control"  v-model="lowerBound" :min="0"/>
            </div>
            <div class="col-2 col-md-1 flex-h-center my-auto font-weight-bold">
                {{translate("tot_en_met")}}
            </div>
            <div class="col-5 col-md-4">
                <input type="number" class="form-control" v-model="upperBound" :min="0"/>
            </div>
            <div class="col-md-3 mt-3 mt-md-0">
                <button class="btn btn-default btn-block" type="submit">{{translate("btn_add")}}</button>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
    import CriteriumRange from '../../criteriumRange';
    import DataMarketingService, { Request } from '../../dataMarketingService';
    import { ProspectieDataSelectionCriteriumType } from '../../../../types/dto/ProspectieDataSelectionCriteriumType';
    import { ProspectieDataRangeOperator } from '../../../../types/dto/ProspectieDataRange';
    import Translate from '../../../mixins/translate';
    import mixins from 'vue-typed-mixins';  

    export default mixins(Translate).extend({
        name: 'dm-company-size',
        data() {
            return {
                lowerBound: undefined,
                upperBound: undefined
            }
        },
        computed: {
            currentRequest(): Request{
                return DataMarketingService.getInstance().currentRequest;
            }
        },
        methods: {
            async addRange() {
                const range = new CriteriumRange(
                    ProspectieDataRangeOperator.BETWEEN, 
                    ProspectieDataSelectionCriteriumType.PERSONEEL,
                    this.lowerBound ?? 0,
                    this.upperBound
                );

                await this.currentRequest.addRange(range);
                await this.currentRequest.updateSummary();

                this.lowerBound = undefined;
                this.upperBound = undefined;
            }
        },
    })
</script>

<style scoped>

</style>