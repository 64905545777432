
<div class="buffer-scores" v-if="score !== 0">
    <div class="buffer-label">{{label}}<i class="fas fa-info-circle ml-1" v-if="tooltip" :data-content="tooltip"></i></div>

    <div class="buffer-score" :class="{'active very-negative': (score == 1)}"><i class="fas fa-minus icoon-score"></i><i class="fas fa-minus icoon-score"></i></div>
    <div class="buffer-score" :class="{'active negative': (score == 2)}"><i class="fas fa-minus icoon-score"></i></div>
    <div class="buffer-score" :class="{'active neutral': (score == 3)}">0</div>
    <div class="buffer-score" :class="{'active positive': (score == 4)}"><i class="fas fa-plus icoon-score"></i></div>
    <div class="buffer-score" :class="{'active very-positive': (score == 5)}"><i class="fas fa-plus icoon-score"></i><i class="fas fa-plus icoon-score"></i></div>
</div>
