﻿import Vue from 'vue';
import Page from '../lib/Page';
import CwModal from './shared/modal.vue';
import Ajax from '../lib/Ajax';
import Toast from '../lib/Toast';
import Translate from './mixins/translate';

export default (function () {
    Page.registerVue('#betalingservaringModalWrapper', function (rootSelector) {
        new Vue({
            el: rootSelector,
            components: {
                CwModal
            },
            data() {
                return {
                    sent: false      
                }
            },
            mixins: [Translate],
            methods: {
                openModal() {
                    this.$root.$emit('show-modal', 'betalingservaring');
                },
                async contactMe() {
                    await Ajax.postAsync("/ajax/contact/payment-experience");
                    //@ts-ignore
                    Toast.success(this.translate("success_message_contact"));
                    this.sent = true;
                }
            },
            mounted() {
                Page.applyBehaviors("#betalingservaringModalWrapper");
            }
        });
    });
}());