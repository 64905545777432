<template>
    <div class="row mt-5">
        <div class="col-12">
            <div v-if="integrations.length > 0">
                <table class="table data-table">
                    <tbody>
                        <tr v-for="(integration, index) in integrations" :key="index" class="border">
                            <td width="130">
                                <div class="img-wrapper">
                                    <img v-if="integration.logo" :src="integration.logo" />
                                    <img v-else src="/images/integratie/no-integration-logo.png" />
                                </div>
                            </td>
                            <td>
                                <h2 class="mb-0 ml-5 int-name"><b>{{integration.name}}</b></h2>
                            </td>
                            <td width="120">
                                <a :href="integration.link" class="btn btn-info btn-200">{{translate('manage')}}</a>
                            </td>  
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else class="text-center">
                <div class="row mb-4">
                    <div class="col-md-6 offset-md-3">
                        <img src="/images/integratie/software-integratie.svg" class="img-fluid"/>
                    </div>
                </div>
                <h2>{{translate('no_integrations')}}</h2>

                <a :href="link" class="btn btn-info mt-4">{{translate('discover_cta')}}</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Integration } from '../types';
    import Translate from '../../mixins/translate';
    import mixins from 'vue-typed-mixins';
    
    export default mixins(Translate).extend({
        name: 'integrations_manage',
        data() {
            return {
                integrations: window.cw.integrations as Integration[],
                link: window.cw.discoverLink as string
            };
        }
    });
</script>

<style scoped lang="scss">
    @import "../../../../sass/variables";


    .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        img {
            max-height: 45px;
        }
    }
    .int-name {
        font-size: 16px;
    }
</style>