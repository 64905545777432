﻿import Page from '../lib/Page';
import * as Sentry from '@sentry/browser';

export default (function () {
    type AdresType = 'HuidigAdres' | 'Vestiging' | 'MaatschappelijkeZetel';

    interface Address {
        adres_line1: string;
        adres_line2: string;
        straat: string;
        name: string;
        type: AdresType;
    }

    interface Icon {
        url: string;
        scaledSize: google.maps.Size;
        origin: google.maps.Point;
    }
    
   
    if (!document.getElementById('map_all_addresses')) {
        return;
    }

    if (!google || google === null || google === undefined) {
        var errorMessage = 'Cannot instantiate Google Maps API: quota probably reached.';
        Sentry.captureMessage(errorMessage);
        return;
    }

    const iconMap: Record<AdresType, Icon> = {
        Vestiging: {
            url: '/images/markers/red.png',
            scaledSize: new google.maps.Size(40, 40),
            origin: new google.maps.Point(0, 0)
        },
        MaatschappelijkeZetel: {
            url: '/images/markers/blue.png',
            scaledSize: new google.maps.Size(40, 40),
            origin: new google.maps.Point(0, 0)
        },
        HuidigAdres: {
            url: '/images/markers/green.png',
            scaledSize: new google.maps.Size(40, 40),
            origin: new google.maps.Point(0, 0)
        }
    };

    Page.register('company-locaties', function () {
        (function CreateMapWithAllAddresses() {
            var maatschappelijkeAdressen = window.cw.maatschappelijkeAdressen as Address[];;
            var vestigingen = window.cw.vestigingen as Address[];
            var huidigAdres = window.cw.huidigAdres as Address;
            var allAdresses = [huidigAdres].concat(vestigingen.concat(maatschappelijkeAdressen));
            var adresses = getUniqueAddresses(10, allAdresses);
            initializeMyMap(adresses);
        }());
    });

    function getUniqueAddresses(maxAmount: number, addressList: Address[])
    {
        var signatures = [];
        var result = [];

        for (var i = 0, l = addressList.length; i < l && result.length < maxAmount; i++) {
            var signature = addressList[i].adres_line2 + addressList[i].straat;
            if (signatures.indexOf(signature) === -1) {
                signatures.push(signature);
                result.push(addressList[i]);
            }
        }

        return result;
    }

    function initializeMyMap(adresses: Address[]) {
        var mapOptions = {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        },
        map = new google.maps.Map(document.getElementById("map_all_addresses")!, mapOptions),
        bounds = new google.maps.LatLngBounds(),
        geocoder = new google.maps.Geocoder();

        for (var i = 0, l = adresses.length; i < l; i++) {
            var addressToDecode = [adresses[i].adres_line1, adresses[i].adres_line2].join(' ').replace(/\(.*\)/g, '');
            geocoder.geocode({address: addressToDecode},
                (function () {
                    var address = adresses[i];
                    return function (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            var marker = new google.maps.Marker({
                                map: map,
                                position: results[0].geometry.location,
                                icon: iconMap[address.type],
                                title: address.name,
                                animation: google.maps.Animation.DROP
                            });
                            
                            var infowindow = new google.maps.InfoWindow({
                                content:
                                    '<div>' +
                                    '<p class="companyweb-blue-bold">' + address.name + '</p>' +
                                    address.adres_line1 + '<br> ' +
                                    address.adres_line2 +
                                    '</div>'
                            });

                            marker.addListener('click', function () {
                                infowindow.open(marker.get('map'), marker);
                            });

                            bounds.extend(marker.getPosition()!);
                        }

                        if (adresses.length !== 1) {
                            map.fitBounds(bounds);
                            map.panToBounds(bounds);
                        } else {
                            map.setZoom(11);
                        }
                        map.setCenter(bounds.getCenter());
                    };
                }())
            );
        }
    }
}());
