
<th :class="{'open': isFilterBoxShown, 'filtered': store.hasCompanyFilters}" @click.stop="toggle">
    <div class="filter-title-row">

        <div class="d-flex align-items-center" style="gap:8px">
            <span class="text">{{translate('company')}}</span>
            <div class="d-flex flex-column sort-wrapper" >
                <a class="d-inline-block sort-btn" :class="{'active': isSorted && !isDescending}" href="#" @click.prevent.stop="setSort(false)" :title="translate('sort_asc')">
                    <i class="fa-caret-up" :class="{'fas': isSorted && !isDescending, 'far': !isSorted || isDescending}"></i>
                </a>
                <a class="d-inline-block  sort-btn" :class="{'active': isSorted && isDescending}"  href="#" @click.prevent.stop="setSort(true)" :title="translate('sort_desc')">
                    <i class="fa-caret-down" :class="{'fas': isSorted && isDescending, 'far': !isSorted || !isDescending}"></i>
                </a>
            </div>
        </div>
    </div>
    <transition name="dropdown">
        <div v-if="isFilterBoxShown" v-click-outside="close">
            <div id="filter-box" class="filter-box" @click.stop>
                <ul class="nav nav-tabs border-bottom">
                    <li class="nav-item">
                        <a :class="{'active': currentTab === Tab.COMPANYNAME}" class="nav-link" href="" @click.prevent="changeTab(Tab.COMPANYNAME)">
                            {{ translate('companyname') }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a :class="{'active': currentTab === Tab.STREET}" class="nav-link" href="" @click.prevent="changeTab(Tab.STREET)">
                            {{ translate('address') }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a :class="{'active': currentTab === Tab.VORM}" class="nav-link" href="" @click.prevent="changeTab(Tab.VORM)">
                            {{ translate('form') }}
                        </a>
                    </li>
                </ul>

                <div class="mt-3">
                    <div v-if="currentTab === Tab.COMPANYNAME">
                        <input v-model="store.companyNameFilter" class="form-control" :placeholder="translate('filter_on_company')" type="search"/>
                    </div>

                    <div v-if="currentTab === Tab.STREET">
                        <div class="row">
                            <div class="col-md-8">
                                <input v-model="streetSearchTerm" class="form-control" :placeholder="translate('filter_on_street')" type="search"/>
                                <div class="filter-overflow-box mt-4">
                                    <ul>
                                        <li v-for="street in shownFilteredStreets" :key="street">
                                            <input :id="street"
                                                   :checked="store.streetFilters.includes(street)"
                                                   class="form-check-input azure"
                                                   type="checkbox"
                                                   @change="store.toggleFilterStreet(street)"/>
                                            <label :for="street" class="wrap-normal d-block"><span>{{ street }}</span></label>
                                        </li>
                                    </ul>
                                    <a class="link mt-2 d-inline-block" v-if="maxStreetShowCount <= shownFilteredStreets.length" href="" @click.prevent="maxStreetShowCount+=100">Meer laden</a>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input class="form-control" type="search" :placeholder="translate('house_number')" v-model="store.houseNumberFilter">
                            </div>
                        </div>
                    </div>

                    <div v-if="currentTab === Tab.VORM">
                        <div class="filter-overflow-box mt-4">
                            <ul>
                                <li v-for="form in uniqueForms" :key="form">
                                    <input :id="form"
                                           :checked="store.formFilters.includes(form)"
                                           class="form-check-input azure"
                                           type="checkbox"
                                           @change="store.toggleFilterForm(form)"/>
                                    <label :for="form" class="wrap-normal d-block"><span>{{ form }}</span></label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</th>
