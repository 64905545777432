<template>
    <div>
        <h2 class="mb-4"><b>{{translate("sidebar_establishment")}}</b></h2>
        <form v-on:submit.prevent="addYearRange()">
            <div class="row form-group">
                <div class="col-sm-4">
                    <datepicker v-model="lowerBound" 
                                :language="locales[userLang]" 
                                :bootstrap-styling="true"  
                                :monday-first="true"
                                initial-view="year"
                                format="dd/MM/yyyy"
                                calendar-button
                                calendar-button-icon="fa fa-calendar"
                                :disabled-dates="{from: new Date()}"
                                :full-month-name="true">
                    </datepicker>
                </div>
                <label class="col-sm-1 flex-h-center my-auto">{{translate("tot_en_met")}}</label>
                <div class="col-sm-4">
                    <datepicker v-model="upperBound"
                                :language="locales[userLang]"
                                :bootstrap-styling="true"
                                :disabled-dates="{from: new Date()}"
                                :monday-first="true"
                                initial-view="year"
                                format="dd/MM/yyyy"
                                calendar-button
                                calendar-button-icon="fa fa-calendar"
                                :full-month-name="true">
                    </datepicker>
                </div>
                <div class="col-sm-3">
                    <button type="submit" class="btn btn-default btn-block">{{translate("btn_add")}}</button>
                </div>
            </div>
            <div class="card-box small warning mt-4" v-if="($v.lowerBound.$invalid && $v.lowerBound.$dirty) || ($v.upperBound.$invalid && $v.upperBound.$dirty)">
                {{translate("validation_error_date_collision")}}
            </div>
        </form>
    </div>
</template>

<script lang="ts">
    import CriteriumRange from '../../criteriumRange';
    import DataMarketingService, { Request } from '../../dataMarketingService';
    import { ProspectieDataSelectionCriteriumType } from '../../../../types/dto/ProspectieDataSelectionCriteriumType';
    import { ProspectieDataRangeOperator } from '../../../../types/dto/ProspectieDataRange';
    import Translate from '../../../mixins/translate';
    import mixins from 'vue-typed-mixins';  
    //@ts-ignore
    import { en, nl, fr } from 'vuejs-datepicker/dist/locale';
    //@ts-ignore
    import Datepicker from "vuejs-datepicker";

    export default mixins(Translate).extend({
        name: 'dm-establishment',
        components: {Datepicker},
        data() {
            return {
                locales: {
                    'en': en,
                    'fr': fr,
                    'nl': nl,
                } as Record<string, string>,
                userLang: window.cw.userLang,
                lowerBound: undefined as Date | undefined,
                upperBound: undefined as Date | undefined,
            }
        },
        validations: {
            lowerBound: {
                maxValue(val, {upperBound}){
                    return (!upperBound && !!val) || (!!upperBound && !val) || upperBound > val
                }
            },
            upperBound: {
                minValue(val, {lowerBound}) {
                    return (!lowerBound && !!val) || (!!lowerBound && !val) || lowerBound < val
                }
            }
        },
        computed: {
            currentRequest(): Request{
                return DataMarketingService.getInstance().currentRequest;
            },
            startDate(): number | undefined {
                return this.lowerBound?.toHansDate() ?? undefined
            },
            endDate(): number | undefined {
                return this.upperBound?.toHansDate() ?? undefined
            }
        },
        methods: {
            async addYearRange() {
                this.$v.$touch();
                if (this.$v.$invalid)
                    return;
                
                const range = new CriteriumRange(
                    ProspectieDataRangeOperator.BETWEEN, 
                    ProspectieDataSelectionCriteriumType.OPRICHTINGSDATUM,
                    this.startDate,
                    this.endDate
                );

                await this.currentRequest.addRange(range);
                await this.currentRequest.updateSummary();

                this.$v.$reset();
                this.lowerBound = undefined;
                this.upperBound = undefined;
            }
        },
    })
</script>

<style lang="scss">
    .vdp-datepicker {
        .form-control:disabled, .form-control[readonly] {
            background-color: white !important;
        }
    }
</style>