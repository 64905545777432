
<div class="timetable-group">
    <div class="date">
        <div class="font-weight-bold">{{date|toLongDate}}</div>
        <div class="font-small text-lightgrey font-italic">
            {{date|toTimeAgo}}
        </div>
    </div>
    <div class="content w-100">
        <table class="w-100">
            <alert-company v-for="c in companies" :events="c.events" :key="'company_' + c.companyId"></alert-company>
        </table>
    </div>
</div>
