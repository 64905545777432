﻿import Swal, { SweetAlertOptions } from 'sweetalert2';
import Translate from './Translate';

interface Options extends SweetAlertOptions{
    body: string;
}

export default function (options: Options) {
    const type = options && options.type || "warning";

    const dialog = Swal.mixin({
        title: options.title,
        text: options.body,
        type: type,
        showCloseButton: false,
        showCancelButton: false,
        buttonsStyling: false,
        customClass: {
            confirmButton: type === 'error' ? 'btn btn-danger btn-large' : 'btn btn-info btn-large'
        },
        confirmButtonText: Translate("verdergaan")
    });

    dialog.fire();
}

