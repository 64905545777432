
<nav class="row mobile-dm-menu">
    <div class="col-3">
        <button class="btn btn-default btn-block" @click.prevent="toggleMenu">
            <i class="fas fa-bars"></i>
        </button>
    </div>
    <div class="col-6">
        <a href="" @click.prevent="toggleCriteria">{{displayValue|formatNumber}} / {{total}}</a>
    </div>
    <div class="col-3" v-if="!isLastStep">
        <button class="btn btn-info btn-block" @click.prevent="nextStep()" :disabled="isNextStepDisabled">
            <i class="fas fa-angle-right"></i>
        </button>
    </div>
</nav>
