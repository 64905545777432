﻿<template>
    <div class="col-md-6">
        <div class="needs-review">
            <b>{{user.email}}</b>
            <template v-if="user.hasSuspiciousDomain">
                <p>
                    {{translate('needs_your_approval')}}
                </p>

                <a href="" class="btn btn-warning"  v-on:click.prevent="startReview">{{translate('opties')}}</a>
            </template>
            <template v-else>
                <p>
                    {{translate('non_active_because_limit_exceeded')}} 
                </p>

                <a href="" class="btn btn-info mr-2" v-on:click.prevent="acceptLimitedUser()">{{translate('reactivate_user')}}</a>
                <a href="" class="link text-grey" v-on:click.prevent="rejectLimitedUser()">{{translate('deactivate_user')}}</a>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
    import Ajax from '../../lib/Ajax';
    import Translate from '../mixins/translate';
    import { User } from './types';
    import mixins from 'vue-typed-mixins';
    import Toast from '../../lib/Toast';
   
    export default mixins(Translate).extend({
        name: 'needs-review-user',
        props: {
            user: Object as () => User
        },
        methods: {
            startReview() {
                this.$root.$emit('startReview', this.user);
            },
            async acceptLimitedUser(): Promise<void> {
                try {
                    await Ajax.postAsync('/ajax/account/activate-user', { userId: this.user.userId });
                    this.$root.$emit('userReactivated', this.user.email);
                } catch(e: unknown) {
                    Toast.error(e as string);
                }

            },
            async rejectLimitedUser(): Promise<void> {
                try {
                    await Ajax.postAsync('/ajax/account/deactivate-user', { userId: this.user.userId });
                    this.$root.$emit('userDeactivated', this.user.email);
                } catch(e: unknown) {
                    Toast.error(e as string);
                }
            }
        }
    });
</script>