﻿<template>
    <div class="mandate-card">
        <div class="box" :class="{'sharp-bottom-corners': allMandatesShown}">
            <div class="inner-card">
                <div>
                    <div class="mb-2 flex-v-center">
                        <a :href="detailsUrl" target="_blank">
                            <h3 class="font-weight-bold mr-3 mb-0">{{ name }}</h3>
                        </a>
                        <barometer-icon v-if="scoreClass && canSeeScores" :className="scoreClass" :score="score"></barometer-icon>
                    </div>
                    <div class="space-y-3">
                        <div v-if="vat">
                            <i class="faded fas fa-building mr-2"></i> {{ vat }}
                        </div>
                        <div v-if="isCompany">
                            <i class="faded fas fa-user-tie mr-2"></i> {{translate('vertegenwoordigd_door')}}: <b>{{ personName }}</b>
                        </div>
                        <div v-if="address">
                            <i class="faded fas fa-map-marker-alt mr-2"></i> {{ address }}
                        </div>
                    </div>
                </div>
                <div>
                    <h4>{{ title }}</h4>
    
                    <div v-if="isMobile" class="mandate-label mt-4"><i class="fas fa-book mr-2"></i> <span v-html="mandateString"></span></div>
                    <div :class="{'company-started-at-timeline-start': companyStartedAtTimelineStart}" class="timeline__company">
                        <div :class="mandateStatus" :style="'left:' + mandateOffset + '%; width:' + mandateWidth + '%;'" class="timeline__mandate">
                            <div v-if="!isMobile" class="mandate-label" :class="{'left': tooFewSpaceRight}"><i class="fas fa-book mx-2"></i> <span v-html="mandateString"></span></div>
                        </div>
                    </div>
                    
                    <div class="d-flex justify-content-between align-items-center">
                        <h4 class="mb-0">{{translate('overige_mandaten')}}</h4>
                        <template v-if="canSeeOtherMandates">
                            <a href="" class="link font-small no-wrap d-print-none" @click.prevent="showOtherMandates" v-if="!allMandatesShown && hasOtherMandates">{{translate('bekijk_alle_mandaten')}} <i class="fas fa-angle-down ml-2"></i></a>
                            <a href="" class="link font-small no-wrap d-print-none" @click.prevent="hideOtherMandates" v-if="allMandatesShown">{{translate('verberg_alle_mandaten')}} <i class="fas fa-angle-up ml-2"></i></a>
                        </template>
                    </div>
                    <div class="space-x-9">
                        <span :title="translate('mandaat_status_' + MandateStatus.MandateIsActive)" :class="{'very-faded': summary.green === 0}">
                            <i class="fas fa-book mr-2" :class="MandateStatus.MandateIsActive"></i>{{summary.green}}
                        </span>
                        <span :title="translate('mandaat_status_' + MandateStatus.MandateEnded)" :class="{'very-faded': summary.blue === 0}">
                            <i class="fas fa-book mr-2" :class="MandateStatus.MandateEnded"></i>{{summary.blue}}
                        </span>
                        <span :title="translate('mandaat_status_' + MandateStatus.CompanyEnded)" :class="{'very-faded': summary.yellow === 0}">
                            <i class="fas fa-book mr-2" :class="MandateStatus.CompanyEnded"></i>{{summary.yellow}}
                        </span>
                        <span :title="translate('mandaat_status_' + MandateStatus.MandateEndedLongBeforeCompanyFailure)" :class="{'very-faded': summary.purple === 0}">
                            <i class="fas fa-book mr-2" :class="MandateStatus.MandateEndedLongBeforeCompanyFailure"></i>{{summary.purple}}
                        </span>
                        <span :title="translate('mandaat_status_' + MandateStatus.MandateEndedShortlyBeforeCompanyFailure)" :class="{'very-faded': summary.red === 0}">
                            <i class="fas fa-book mr-2" :class="MandateStatus.MandateEndedShortlyBeforeCompanyFailure"></i>{{summary.red}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="all-mandates" v-if="loadingMandates || allMandatesShown">
            <div v-if="loadingMandates" class="text-center">
                <i class="fas fa-spinner spinning text-lightblue fa-lg"></i>
            </div>
            <table class="table data-table mb-0" v-if="allMandatesShown">
                <thead>
                    <tr>
                        <th>{{translate('mandate')}}</th>
                        <th v-if="!isMobile" width="150px">{{translate('mandate_end')}}</th>
                        <th v-if="!isMobile" width="150px">{{translate('company_end')}}</th>
                        <th width="60px">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <template  v-for="(mandate, index) in otherMandates">
                        <mandate-row :mandate="mandate" :can-see-scores="canSeeScores" :should-see-upsell="shouldSeeUpsell" :is-mobile="isMobile"></mandate-row>
                        <template v-if="mandate.subMandaten.length > 0">
                            <mandate-row  v-for="(submandate, idx) in mandate.subMandaten" :mandate="submandate" :key="'mandate_'+index + '_' + idx"  is-submandate :can-see-scores="canSeeScores" :should-see-upsell="shouldSeeUpsell" :is-mobile="isMobile"></mandate-row>
                        </template>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
    import BarometerIcon from '../shared/barometer-icon.vue';
    import Ajax from '../../lib/Ajax';
    import { Mandate, MandateStatus } from './types';
    import MandateRow from './mandate-row.vue';
    import Translate from '../mixins/translate';
    
    export default Translate.extend({
        components: {
            BarometerIcon,
            MandateRow
        },
        name: 'mandate-card',
        props: {
            id: String,
            token: String,
            name: String,
            vat: String as () => string | undefined,
            score: String as () => string | undefined,
            scoreClass: String as () => string | undefined,
            address: String,
            title: String,
            personName: String as () => string | undefined,
            mandateStatus: String as () => MandateStatus,
            mandateStartDate: String,
            mandateEndDate: String as () => string | undefined,
            mandateEndString: String as () => string | undefined,
            companyStartDate: String as () => string,
            companyEndDate: String as () => string | undefined,
            companyVat: String as () => string,
            mandateSummaryGreen: Number,
            mandateSummaryBlue: Number,
            mandateSummaryYellow: Number,
            mandateSummaryPurple: Number,
            mandateSummaryRed: Number,
            detailsUrl: String,
            canSeeScores: Boolean,
            canSeeOtherMandates: Boolean,
            shouldSeeUpsell: Boolean
        },
        data() {
            return {
                allMandatesShown: false,
                MandateStatus: MandateStatus,
                otherMandates: [] as Mandate[],
                loadingMandates: false,
                summary: {
                    green: this.mandateSummaryGreen - (this.mandateStatus === MandateStatus.MandateIsActive ? 1 : 0),
                    blue: this.mandateSummaryBlue - (this.mandateStatus === MandateStatus.MandateEnded ? 1 : 0),
                    yellow: this.mandateSummaryYellow - (this.mandateStatus === MandateStatus.CompanyEnded ? 1 : 0),
                    purple: this.mandateSummaryPurple - (this.mandateStatus === MandateStatus.MandateEndedLongBeforeCompanyFailure ? 1 : 0),
                    red: this.mandateSummaryRed - (this.mandateStatus === MandateStatus.MandateEndedShortlyBeforeCompanyFailure ? 1 : 0),
                }
            };
        },
        computed: {
            isMobile(): boolean {
                return window.outerWidth <= 770;
            },
            hasOtherMandates(): boolean {
                return this.summary.green + this.summary.blue + this.summary.yellow + this.summary.purple + this.summary.red > 0;  
            },
            shownMandateEndString(): string {
                return this.mandateEndString ? this.mandateEndString : this.timelineMandateEndDate.format()
            },
            tooFewSpaceRight(): boolean {
                return this.mandateOffset > 60;
            },
            isCompany(): boolean {
                return !!this.vat;
            },
            timelineStartDate(): Date {
                return Date.fromString(this.companyStartDate) > new Date().addYears(-20)
                    ? Date.fromString(this.companyStartDate)
                    : new Date().addYears(-20);
            },
            timelineEndDate(): Date {
                return this.companyEndDate ? Date.fromString(this.companyEndDate) : new Date();
            },
            timelineMandateStartDate(): Date {
                const mandateStartDate = Date.fromString(this.mandateStartDate);

                return mandateStartDate < this.timelineStartDate
                    ? this.timelineStartDate
                    : mandateStartDate;
            },
            timelineMandateEndDate(): Date {
                const mandateEndDate = this.mandateEndDate
                    ? Date.fromString(this.mandateEndDate)
                    : new Date();

                return mandateEndDate > this.timelineEndDate
                    ? this.timelineEndDate
                    : mandateEndDate;
            },
            mandateWidth(): number {
                return (+this.timelineMandateEndDate - +this.timelineMandateStartDate) / (+this.timelineEndDate - +this.timelineStartDate) * 100;
            },
            mandateOffset(): number {
                return (+this.timelineMandateStartDate - +this.timelineStartDate) / (+this.timelineEndDate - +this.timelineStartDate) * 100;
            },
            mandateString(): string {
                return `${this.translate("sinds")} <b>${this.mandateStartDate}</b> ${this.translate("tot").toLowerCase()} <b>${this.shownMandateEndString}</b>`;
            },
            companyStartedAtTimelineStart(): boolean {
                return Date.fromString(this.companyStartDate).toHansDate() === this.timelineStartDate.toHansDate();
            }
        },
        methods: {
            showOtherMandates(){
                if(this.otherMandates.length > 0)
                    this.allMandatesShown = true;
                else 
                    this.fetchOtherMandates();
            },
            hideOtherMandates() {
                this.allMandatesShown = false;
            },
            async fetchOtherMandates(): Promise<void> {
                this.loadingMandates = true;
                this.otherMandates = await Ajax.getAsync<Mandate[]>("/ajax/mandates", {
                    mandateHolderId: this.id,
                    vatOfCompanyToExclude: this.companyVat,
                    token: this.token
                });
                this.loadingMandates = false;
                this.allMandatesShown = true;
            }
        },
        mounted() {
            //@ts-ignore
            window.IsPageLoaded = true;
        }
    });
</script>

<style lang="scss" scoped>
    @import "../../../sass/variables";
    .box {
      @media #{$phone} {
        padding: 20px;
      }
    }
    .box.sharp-bottom-corners {
      border-radius: 3px 3px 0 0;
    }
    
    .all-mandates {
      border: 1px solid #dee0e0;
      border-top: none;
      border-radius: 0 0 3px 3px;
      padding: 30px;
      overflow-x: auto;
      
      @media #{$phone} {
        padding: 20px;
      }
    }
    
    .inner-card {
        color: $greyblue;
        display: grid;
        grid-template-columns: 4fr 6fr;
      
        @media #{$phone} {
            grid-template-columns: 1fr;
        }

        h3 {
            font-size: 1rem;
            &:hover{
                color: $lightblue;
            }
        }

        h4 {
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 1px;
            color: $greyblue;
            text-transform: uppercase;
        }
      
      .gray {
        color: gray !important;
      }

        .timeline__company {
            background-color: #d1d1d1;
            position: relative;
            height: 12px;
            border-radius: 2px;
            width: 100%;
            margin-top: 35px;
            margin-bottom: 35px;
          
          @media #{$phone}{
            margin-top: 10px;
          }

            &.company-started-at-timeline-start {
                &:before {
                    content: '\f4d8';
                    font-family: "Font Awesome 5 Pro";
                    font-weight: bold;
                    border-left: 2px solid;
                    color: #d1d1d1;
                    border-color: #d1d1d1;
                    left: 0;
                    top: calc(100% - 1px);
                    position: absolute;
                    padding: 3px 8px;
                    font-size: 12px;
                }
            }

            .timeline__mandate {
                position: absolute;
                height: 100%;

                &.mandate-is-active {
                    background-color: $status-9-color;
                }

                &.mandate-ended {
                    background-color: $lightblue;
                }

                &.company-ended {
                    background-color: $status-5-color;
                }

                &.mandate-ended-shortly-before-company-failure {
                    background-color: $status-0-color;
                }

                &.mandate-ended-long-before-company-failure {
                    background-color: $purple;
                }
              
              .mandate-label {
                position: absolute;
                bottom: 100%;
                border-left: 2px solid;
                padding: 4px;
                font-size: 13px;
                width: 250px;
                display: flex;
                align-items: center;
                
                @media print{
                  font-size: 12px;
                }
                
                &.left {
                  left: -248px;
                  text-align: right;
                  border-right: 2px solid;
                  border-left: none !important;
                  flex-direction: row-reverse;
                }

                span {
                  color: $greyblue;
                }
              }
            }
        }
    }
</style>