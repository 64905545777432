
<modal title="Toevoegen aan Alerts" :id="modalId">
    <form @submit.prevent="addToAlerts">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="listId" class="mb-1">Kies lijst</label>
                    <div v-for="(list, idx) in store.alertsLists" :value="list.listId" class="mb-2">
                        <input :id="list.listId" type="radio" class="azure" v-model="chosenAlertsListId" :value="list.listId" />
                        <label :for="list.listId">{{list.naam}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button type="submit" class="btn btn-primary">Toevoegen</button>
            </div>
        </div>
    </form>
</modal>
