﻿import Vue from 'vue';
import Page from '../lib/Page';
import FileInput from './shared/file-input.vue';

export default (function () {
    'use strict';

    Page.registerVue('#betaFeaturesFeedback', function (rootSelector) {
    
        new Vue({
            el: rootSelector,
            data() {
                return {
                    isError: false
                };
            },
            components: {
                FileInput
            },
            mounted() {
                Page.applyBehaviors(rootSelector);

                this.$root.$on('status', (isError: boolean)=> {
                    this.isError = isError;
                });
            }
        });
    });
}());
