<template>
    <div class="mb-2 mb-lg-3">
        <label>{{label}}</label>
        <div class="result-wrapper">
            <div class="result-bar">
                <div class="inner" :style="{width: width + '%'}"></div> 
                <span class="count">{{amount.formatThousandSeparator()}}</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue'

    export default Vue.extend({
        name: 'dm_result_bar',
        props: {
            amount: Number,
            totalAmount: Number,
            label: String
        },
        computed: {
            width(): number {
                return (this.amount / this.totalAmount) * 100
            }
        }
    })
</script>

<style lang="scss" scoped>
    @import "../../../../../sass/variables";

    .result-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;
        position: relative;

        .result-bar {
            width: 86%;
            display: flex;
            align-items: center;
            
            .inner {
                height: 8px;
                border-radius: 3px;
                background-color: #e6e6e6;
            }

            .count {
                font-weight: bold;
                position: absolute;
                right: 0;
            }
        }
    }
</style>