﻿import Vue from 'vue';

export default Vue.extend({
    methods: {
        truncate: function (name: string, length: number): string {
            if (name.length > length + 3)
                return name.substring(0, length) + '...';
            else
                return name;
        }
    },
    filters: {
        truncate: function (name: string, length: number): string {
            if (name.length > length + 3)
                return name.substring(0, length) + '...';
            else
                return name;
        }
    }
});
