
<div class="barometer-wrap" :class="className">
    <template v-if="!noAnimate">
        <img class="d-print-none" src="/images/barometer-grijs.svg" v-if="isFailliet" />
        <img class="d-print-none" src="/images/barometer-zonder-pijl.svg" v-else/>
        <img src="/images/barometer-pijl.svg" class="arrow d-print-none" :class="{'keep-rotating': isDemo}" id="barometer-arrow" :style="transformArrow" v-if="this.hasScore || this.isDemo"/>
        <div class="count d-print-none" id="counter" v-html="label" :style="bgColor"></div>
    </template>
    <img v-if="!isDemo" :src="'/images/barometer/big/' + imageName" class="d-print-block" :class="{'d-none': !noAnimate}"/>
</div>
