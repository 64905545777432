﻿<template>
    <g :transform="transform" @mouseover="onHover" @mouseleave="onHoverLeave">
        <g :transform="'translate('+offsetX+','+ offsetY+')'">
            <rect class="kyc-node" 
                  :width="$parent.nodeWidth" 
                  :height="$parent.nodeHeight" 
                  x="0" 
                  y="0" 
                  :fill="fill" 
                  rx="4" 
                  stoke-width="1" 
                  :stroke="stroke"></rect>
            

            <barometer-icon v-if="company.barometerClassName" svg :score="company.barometerScore" :className="company.barometerClassName"></barometer-icon>
      
            
            <g :transform="'translate(' + (company.barometerClassName ? 48 : 15) + ', 22)'" id="nodeTextContent">
                <a :xlink:href="link" target="_blank">
                    <text transform="translate(0, 0)" fill="#0A2439" font-size="12" font-weight="bold" class="hover-underline cursor-help">
                        <title>{{company.name}} {{company.form}}</title>
                        {{company.name|ellipsis(small ? 18 : 22)}}
                        <tspan font-weight="normal" fill="grey" font-size="9">{{company.form}}</tspan>
                    </text>
                </a>

                <text transform="translate(0, 15)" fill="#979797" font-size="11">{{company.vat|formatVat}}</text>
            </g>
            <g transform="translate(15, 50)" id="nodeLabel">
                <svg aria-hidden="true" width="10px" height="10px" viewBox="0 0 512 512" role="img" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#7F90A0" d="M0 252.118V48C0 21.49 21.49 0 48 0h204.118a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882L293.823 497.941c-18.745 18.745-49.137 18.745-67.882 0L14.059 286.059A48 48 0 0 1 0 252.118zM112 64c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z"></path>
                </svg>
    
                <svg-text :max-lines="2" :font-size="11" :characters-per-line="small ? 26 : 30" fill="#7F90A0">{{company.activity}}</svg-text>
            </g>

            <polygon class="kyc-node-arrow" id="arrow" :points="arrowVectors" :stroke="stroke" :fill="fill" :transform="'translate(' + arrowOffsetX + ', ' + arrowOffsetY + ')'" />
            <polygon id="arrowWhite" :points="arrowVectors2" :fill="fill" :transform="'translate(' + arrowOffsetX + ', ' + arrowOffsetY + ')'" />
        </g>
    </g>
</template>

<script>
    import Translate from 'Mixins/translate';
    import BarometerIcon from '../shared/barometer-icon.vue';
    import SvgText from '../shared/svg-text.vue';

    export default {
        name: 'ubo_node',
        mixins: [Translate],
        components: { BarometerIcon, SvgText },
        props: {
            small: Boolean,
            column: Number,
            row: Number,
            parents: Array,
            children: Array,
            company: {
                name: String,
                form: String,
                barometerScore: String,
                barometerClassName: String,
                vat: Number,
                link: String
            },
            hoveredVat: Number
        },
        data() {
            return {
                hovered: false,
                arrow: {
                    height: this.$parent.arrowHeight,
                    width: this.$parent.arrowWidth
                }
            }
        },
        computed: {
            shouldGlow() {
                return this.parents.includes(this.hoveredVat);  
            },
            fill() {
                return this.hovered || this.shouldGlow ? 'aliceblue' : 'white';
            },
            stroke() {
                return this.hovered || this.shouldGlow ? '#03a9f4' : '#f1f1f1';
            },
            link() {
                return this.company.link;
            },
            arrowVectors() {
                return `0,${this.arrow.height} ${this.arrow.width / 2},0 ${this.arrow.width},${this.arrow.height}`;
            },
            arrowVectors2() {
                return `0,${this.arrow.height + 1} ${this.arrow.width / 2},0 ${this.arrow.width},${this.arrow.height + 1}`;
            },
            arrowOffsetY() {
                return -1 * this.arrow.height;
            },
            arrowOffsetX() {
                return this.$parent.nodeWidth / 2 - this.arrow.width / 2;
            },
            transform() {
                return 'translate(' + ((this.column - 1) * this.$parent.colWidth) + ',' + (this.row * this.$parent.rowHeight - (this.$parent.nodeHeight + this.$parent.arrowHeight)) + ')';
            },
            offsetY() {
                return this.$parent.arrowHeight;
            },
            offsetX() {
                return (this.$parent.colWidth / 2) - (this.$parent.nodeWidth / 2);
            },
            drawStartLine() {
                const fromX = 0;
                const y = this.offsetY + (this.$parent.nodeHeight / 2);
                const toX = this.offsetX;

                return `M ${fromX} ${y} ${toX} ${y}`;
            }
        },
        methods:{
            onHover() {
                this.hovered = true;
                this.$emit('onHover', this.company.vat);
            },
            onHoverLeave() {
                this.hovered = false;
                this.$emit('onHoverLeave');
            }
        },
        filters: {
            formatVat(vat) {
                return vat.toString().replace(/^(\d{3})(\d{3})(\d{3})$/, 'BE 0$1.$2.$3');
            },
            ellipsis(name, max) {
                if (name.length > max)
                    return name.substring(0, (max - 3)) + '...';
                else
                    return name;
            }
        }
    }
</script>

<style scoped>
    .hover-underline:hover {
        text-decoration: underline;
    }
</style>