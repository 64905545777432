﻿<template>
    <div class="timetable-group">
        <div class="date">
            <div class="font-weight-bold">{{date|toLongDate}}</div>
            <div class="font-small text-lightgrey font-italic">
                {{date|toTimeAgo}}
            </div>
        </div>
        <div class="content w-100">
            <table class="w-100">
                <alert-company v-for="c in companies" :events="c.events" :key="'company_' + c.companyId"></alert-company>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
    import DateHelpers from '../../mixins/date';
    import {ExtendedAlertsEvent} from './types';
    import Vue from 'vue';
    import AlertCompany from "./alert-company.vue"
    
    export default Vue.extend({
        name: 'alert-day',
        props: {
            events: {
                type: Array as () => ExtendedAlertsEvent[],
                required:true
            },
            date: String
        },
        components: {AlertCompany},
        mixins: [DateHelpers],
        computed: {
            companies() {
                const arr = [] as {companyId: string, events: ExtendedAlertsEvent[]}[];
                
                this.events.groupBy(e => e.id).forEach((list, key) => {
                    arr.push({
                        companyId: key.toString(),
                        events: list
                    });
                });
                
                return arr;
            }
        }
    });
</script>
