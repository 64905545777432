﻿<template>
    <div>
        <canvas ref="canvas" :height="height+'px'" :width="width + 'px'"></canvas>
    </div>
</template>

<script lang="ts">
    import Translate from '../../vue/mixins/translate';
    import mixins from 'vue-typed-mixins';
    import { ChartType } from '../../behaviors/Chart';
    import Chart from 'chart.js';
    import { Color, YearData } from './types';
    
    interface LiquiditeitData {
        eng: YearData,
        ruim: YearData
    }
    
    export default mixins(Translate).extend({
        name: 'liquiditeit',
        data() {
            return {
                chartData: window.cw.liquiditeitData as LiquiditeitData
            };
        },
        mounted() {
            this.drawChart();  
        },
        computed: {
            isMobile(): boolean {
                return window.outerWidth <= 500;
            },
            height(): number {
                return this.isMobile ? 320 : 450
            },
            width(): number {
                return this.isMobile ? 320 : 450
            }
        },
        methods: {
            drawChart() {
                const canvas = this.$refs.canvas as HTMLCanvasElement;
                const rawData = this.chartData;
                
                const data = {
                     labels: Object.keys(rawData.eng),
                     datasets: [
                         {
                             label: this.translate('liquiditeit_in_enge_zin'),
                             data: Object.values(rawData.eng),
                             backgroundColor: Color.YELLOW,
                             borderColor: Color.YELLOW,
                             fill: false,
                             lineTension: 0
                         },
                         {
                             label: this.translate('liquiditeit_in_ruime_zin'),
                             data: Object.values(rawData.ruim).map(v => v ?? 0),
                             backgroundColor: Color.INDIGO,
                             borderColor: Color.INDIGO,
                             fill: false,
                             lineTension: 0
                         }
                     ]
                };
                 
                
                new Chart(canvas, {
                    type: ChartType.LINE,
                    data: data,
                    options: {
                        title: {
                            display: true,
                            text: this.translate('liquiditeit'),
                            fontSize: 15
                        },
                        animation: {
                            duration: 0
                        },
                        maintainAspectRatio: true,
                        responsive: false,
                        scales: {
                            yAxes: [
                                {
                                    id: 'L',
                                    ticks: {
                                        beginAtZero: true,
                                    }
                                },
                            ]
                        },
                        legend: {
                            labels: {
                                fontSize: 15
                            },
                            position: 'bottom'
                        },
                    }
                })
            },
            
        },
    });
</script>

<style lang="scss" scoped>
   
</style>