
<div class="col-md-6">
    <div class="needs-review">
        <b>{{user.email}}</b>
        <template v-if="user.hasSuspiciousDomain">
            <p>
                {{translate('needs_your_approval')}}
            </p>

            <a href="" class="btn btn-warning"  v-on:click.prevent="startReview">{{translate('opties')}}</a>
        </template>
        <template v-else>
            <p>
                {{translate('non_active_because_limit_exceeded')}} 
            </p>

            <a href="" class="btn btn-info mr-2" v-on:click.prevent="acceptLimitedUser()">{{translate('reactivate_user')}}</a>
            <a href="" class="link text-grey" v-on:click.prevent="rejectLimitedUser()">{{translate('deactivate_user')}}</a>
        </template>
    </div>
</div>
