﻿import Page from '../lib/Page';

export default (function () {
    Page.registerBehavior('input[type="number"]', function (input: Element) {        
        (input as HTMLInputElement).addEventListener('input', function() {
            this.value = this.value.replace(/\s/g, '')
        });
    });  

}());
