<template>
    <div>
        <h2>{{translate('integrate_title')}}</h2>
         <p>
            {{translate('institute_text', integration.name)}}
        </p>
        <a :href="'/integrations/contact/' + integration.id" class="btn btn-200 btn-info">{{translate('btn_contact')}}</a>
    </div>
</template>

<script lang="ts">
    import mixins from 'vue-typed-mixins';
    import Translate from '../../mixins/translate';
    import { SoftwarePartner } from '../../../types/dto/SoftwarePartner';

    export default mixins(Translate).extend({
        name: 'integration_institute',
        props: {
            integration: Object as () => SoftwarePartner
        }
    })
</script>

<style scoped>

</style>