﻿<template>
    <div class="neo-box no-hover no-border h-100">
        <div class="inner-container text-left m-b-0 ">
            <div class="switches">
                <a v-for="st in searchTypes" 
                   href="#" 
                   class="switch-label" 
                   v-on:click.stop.prevent="switchSearchType(st.name)" 
                   v-bind:class="{active: currentSearchType===st.name}">
                    <i class="fas m-r-10" :class="st.icon"></i> <span class="d-none d-md-inline-block">{{st.label}}</span>
                </a>
            </div>
            <div class="search-bar">
                <component :is="currentSearchType" :gtm-action="gtmAction" :gtm-category="gtmCategory"></component>
            </div>
        </div>
    </div>
</template>
<script>
    import Translate from 'Mixins/translate';
    import Company from './company.vue';
    import Manager from './manager.vue';
    import Address from './address.vue';

    export default {
        name: 'search-box',
        mixins: [Translate],
        components: {
            'company': Company,
            'person': Manager,
            'adres': Address
        },
        provide: function () {
            return {
                showAnnouncement: this.showAnnouncement,
                isInstituutUser: this.isInstituutUser,
                canSearchOnManager: this.canSearchOnManager,
                canSearchOnAddress: this.canSearchOnAddress,
                isStandardUser: this.isStandardUser,
                isBasicUser: this.isBasicUser,
                isTrialUser: this.isTrialUser
            }
        },
        props: {
            showAnnouncement: Boolean,
            isInstituutUser: Boolean,
            canSearchOnManager: Boolean,
            canSearchOnAddress: Boolean,
            isStandardUser: Boolean,
            isBasicUser: Boolean,
            isTrialUser: Boolean,
            gtm: Object
        },
        data: function () {
            return {
                minimumSearchLength: 2,
                currentSearchType: 'company',
                searchTypes: [
                    {
                        name: 'company',
                        label: this.translate('bedrijf'),
                        icon: 'fa-building'
                    },
                    {
                        name: 'person',
                        label: this.translate('bedrijfsleider'),
                        icon: 'fa-user-tie'
                    }
                ]
            }
        },
        computed: {
            gtmAction() {
                switch (this.currentSearchType) {
                    case "company":
                        return this.gtm.company.action;
                    case "person":
                        return this.gtm.person.action;
                    case "adres":
                        return this.gtm.address.action;
                }
            },
            gtmCategory() {
                switch (this.currentSearchType) {
                    case "company":
                        return this.gtm.company.category;
                    case "person":
                        return this.gtm.person.category;
                    case "adres":
                        return this.gtm.address.category;
                }
            }
        },
        methods: {
            switchSearchType: function (searchType) {
                this.currentSearchType = searchType;
            }
        },
        created() {
            const shouldSeeAddressSearchUpsell = !this.isInstituutUser;

            if (this.canSearchOnAddress || shouldSeeAddressSearchUpsell)
                this.searchTypes.push({
                    name: 'adres',
                    label: this.translate('address'),
                    icon: 'fa-map-marker-alt'
                });
        },
        mounted() {
            var isOnPersonPage = document.location.pathname.indexOf('/search/persons') === 0
                || document.location.pathname.indexOf('/person') === 0;
            var isOnAddressPage = document.location.pathname.indexOf('/search/address') === 0;

            if (isOnPersonPage)
                this.currentSearchType = 'person';

            if (isOnAddressPage)
                this.currentSearchType = 'adres';

            this.$on('validate', (errorMessage) => {
                this.errorMessage = errorMessage;
            });
        }
    };
</script>