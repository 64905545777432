import Option from "./option";

export default function Enumeration<T>() {
    abstract class Enumeration {
        protected constructor(public readonly name: string) {};

        /**
        * Create an Option<T> enum from string
        */
        public static fromString(string: string): Option<T> {
            return Option.someNotNull((this as any)[string]);
        }

        /**
        * Loop over all instances of the enumeration
        */
        public static get enumerators(): T[] {
            return Object.keys(this).map(key => (this as any)[key]);
        }   

        /**
        * Loop over all instances of the enumeration
        */
        public static get values(): T[] {
            return this.enumerators;
        }
    }

    return Enumeration;
}