
<div class="wrapper">
    <div class="top-view">
        <div class="row ">
            <div class="col-md-6"><h1 class="font-weight-bold">{{translate('columns')}}</h1></div>
            <div class="col-md-6 text-right">
                <button class="btn btn-ghost" @click.prevent="store.openColumnAddDrawer();">
                    <i class="fas fa-plus mr-1"></i> {{translate('add_column')}}
                </button>
            </div>
        </div>

        <div class="mt-4">
            <h2 class="font-weight-bold">{{translate('used_columns')}}</h2>
            <div v-for="column in store.chosenColumns" class="d-flex mb-2" style="gap:10px;" :key="column.title">
                <input type="checkbox" :id="column.title" checked @change="addOrDelete(column.title, $event)" >
                <label :for="column.title">{{translate(column.title)}}</label>
            </div>
        </div>
        <div class="mt-4" v-if="store.hiddenSelectableColumns.length > 0">
            <h2 class="font-weight-bold">{{translate('unused_columns')}}</h2>
            <div v-for="column in store.hiddenSelectableColumns" class="d-flex mb-2" style="gap:10px;" :key="column.title">
                <input type="checkbox" :id="column.title" @change="addOrDelete(column.title, $event)">
                <label :for="column.title">{{translate(column.title)}}</label>
            </div>
        </div>
    </div>
    <div class="bottom-view">
        <a href="" class="btn btn-white" @click.prevent="close">{{translate('cancel')}}</a>
        <button class="btn btn-primary" @click.prevent="saveColumns">{{translate('save_changes')}}</button>
    </div>
</div>
