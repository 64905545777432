
<modal :title="translate('edit_column')" id="editCustomColumnModal" size="md">
    <form @submit.prevent="onSubmit">
        <div class="mb-4">
            <h4 class="mb-2">{{translate('type')}}</h4>
            <div class="d-flex space-x-6">
                <div>
                    <input id="boolean" type="radio" class="azure" v-model="type" value="string">
                    <label for="boolean">{{translate('text')}}</label>
                </div>
                <div>
                    <input id="string" type="radio" class="azure" v-model="type" value="boolean">
                    <label for="string">{{translate('checkbox')}}</label>
                </div>
            </div>
        </div>
        <div class="mb-4">
            <h4 class="mb-2">{{translate('title')}}</h4>
            <input id="title" type="text" v-model="title" class="form-control">
            <template v-if="$v.title.$invalid && $v.title.$dirty">
                <div class="card-box small my-2 warning" v-if="!$v.title.required">
                    {{translate('validation_error_required', translate("title"))}}
                </div>
                <div class="card-box small my-2 warning" v-else-if="!$v.title.maxLength">
                    {{translate('validation_error_max_length', translate("title"), 20)}}
                </div>
                <div class="card-box small my-2 warning" v-else-if="!$v.title.minLength">
                    {{translate('validation_error_min_length', translate("title"), 3)}}
                </div>
            </template>
        </div>
        <div class="mb-4">
            <h4 class="mb-2">{{translate('description')}}</h4>
            <textarea v-model="description" class="form-control" rows="4"></textarea>
        </div>
        <div class="mb-4">
            <h4 class="mb-2">{{translate('width')}}</h4>
            <input v-model="minWidth" class="form-control" type="number" min="50" max="350" />
        </div>
        <div class="row">
            <div class="col-md-7">
                <button type="submit" class="btn btn-primary btn-200">
                    <i class="fas fa-save mr-1"></i>
                    {{translate('btn_save')}}
                </button>
            </div>
            <div class="col-md-5 mt-auto text-right">
                <a href="" class="text-grey link" @click.stop.prevent="tryDeleteColumn">{{translate('delete_column')}}</a>
            </div>
        </div>
    </form>
</modal>
