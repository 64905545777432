
<div class="row mt-5">
    <div class="col-12">
        <div class="row">
            <div class="col-md-4">
                <input type="search" v-model="filterTerm" class="form-control" :placeholder="translate('filter_by_name').toLowerCase()"/>
            </div>
        </div>

        <div class="integration-grid mt-4">
            <div v-for="(integration, index) in filteredIntegrations" :key="index">
                <a :href="integration.link"
                   class="border rounded d-block integration">
                    <div class="img-wrapper">
                        <img v-if="integration.logo" :src="integration.logo" class="img-fluid"/>
                        <img v-else src="/images/integratie/no-integration-logo.png" class="img-fluid"/>
                    </div>

                    <div class="distributor text-grey" v-if="integration.distributor !== ''">{{ integration.distributor }}</div>
                    <span v-if="integration.isActivated" class="badge badge-success">{{ translate('active') }}</span>
                    <span v-else-if="integration.wasActivated" class="badge badge-danger">{{ translate('expired') }}</span>

                    <span v-else-if="integration.label" class="badge" :class="{
                        'badge-warning':integration.label === SoftwareLabel.COMINGSOON,
                        'badge-purple': integration.label === SoftwareLabel.UPDATED,
                        'badge-info': integration.label === SoftwareLabel.RECENT
                        }">{{ translate(integration.label) }}</span>
                </a>
            </div>
        </div>
    </div>
</div>
