﻿import Swal, { SweetAlertOptions } from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css'

import Translate from './Translate';

interface Options extends SweetAlertOptions{
    body: string;
}

export default function (successHandler: Function, options: Options) {
    var needsConfirmation =
        options !== undefined &&
        options.title !== undefined &&
        options.title !== '';

    var type = options && options.type || "error";
    var showCheckbox = options && options.inputPlaceholder;

    if (!needsConfirmation) {
        successHandler();
        return;
    }

    var dialog = Swal.mixin({
        title: options.title,
        text: options.body,
        type: type,
        showCloseButton: true,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
            confirmButton: type === 'error' ? 'btn btn-danger btn-large' : 'btn btn-info btn-large',
            cancelButton: 'btn btn-default btn-large ml-2',
            input: 'form-check-input-wrapper',
        },
        cancelButtonText: options.cancelButtonText || Translate("annuleren"),
        confirmButtonText: options.confirmButtonText || Translate("verdergaan")
    });


    if (showCheckbox) {
        dialog
            .fire({
                input: 'checkbox',
                inputValue: '',
                inputPlaceholder: options.inputPlaceholder
            })
            .then(function (data) {
                if (data.value !== undefined)
                    successHandler(data.value);
            });
    } else {
        dialog
            .fire()
            .then(function (confirmed) {
                if (confirmed.value) successHandler();
            });
    }
}

