
<div class="row mt-4">
    <div class="col-md-12">
        <div class="box">
            <div class="box-title">{{translate('settings')}}</div>
            <form @submit.prevent="updateList()">
                <div class="row">
                    <div class="col-md-12 col-lg-4">
                        <label>{{translate('naam')}}</label>
                        <input type="text" class="form-control" v-model="form.listName" />

                        <template v-if="$v.form.listName.$invalid && $v.form.listName.$dirty">
                            <div class="card-box small my-2 warning" v-if="!$v.form.listName.required">
                                {{translate('validation_error_required', translate('naam'))}}
                            </div>
                            <div class="card-box small my-2 warning" v-else-if="!$v.form.listName.maxLength">
                                {{translate('validation_error_max_length', 50)}}
                            </div>
                        </template>

                    </div>
                    <div class="col-md-12 col-lg-4  my-2 my-lg-0" v-if="!form.isPrivate">
                        <label>{{translate('followers_label')}}</label>
                        <users-dropdown v-model="form.followers" :is-private="form.isPrivate"></users-dropdown>

                        <div class="card-box small my-2 warning" v-if="$v.form.followers.$invalid && $v.form.followers.$dirty">
                            {{translate('validation_error_followers_required')}}
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4" v-if="canHaveSharedLists">
                        <label>{{translate('private_list_label')}}</label>
                        <div>
                            <input id="private" type="radio" class="form-check-input" v-model="form.isPrivate" :value="true" :disabled="hasOtherFollowers" />
                            <label for="private">{{translate('private_list_label_me')}}</label>
                        </div>
                        <div>
                            <input id="shared" type="radio" class="form-check-input" v-model="form.isPrivate" :value="false" />
                            <label for="shared">{{translate('private_list_label_all')}}</label>
                        </div>
                    </div>
                    <div class="col-md-6 mt-2">
                        <button type="submit" class="btn btn-default btn-200">
                            {{translate('btn_save')}}
                        </button>
                    </div>
                    <div class="col-md-6 text-right mt-auto" v-if="!currentList.IsReadOnly">
                        <a v-confirm="deleteConfirmConfig" class="link text-grey ml-2 pointer"><i class="fas fa-trash-alt mr-2"></i>{{translate('confirm_delete_list_confirm_button')}}</a>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="col-md-12 mt-3" v-if="currentList.IsReadOnly">
        <div class="info-box">{{translate('info_readonly_list')}}</div>
    </div>
 
    <div class="col-md-12 mt-5">
        <div class=" menu-items-wrapper align-left mb-4" v-if="showPersonTab || showAddressTab">
            <div class="menu-items">
                <a href="" class="menu-item" :class="{'active': currentTab === 'company-list'}" @click.prevent="currentTab = 'company-list'">
                    <i class="fas fa-building mr-2"></i> {{translate('tab_companies')}}
                    <span class="badge badge-pill badge-secondary float-right badge-fixed ml-2" :class="{'badge-blue': currentTab === 'company-list'}">{{currentList.NumberOfCompanies}}</span>
                </a>
                <a v-if="showPersonTab" href="" class="menu-item" :class="{'active': currentTab === 'person-list'}" @click.prevent="currentTab = 'person-list'">
                    <i class="fas fa-user-tie mr-2"></i> {{translate('tab_persons')}}
                    <span class="badge badge-pill badge-secondary float-right badge-fixed ml-2" :class="{'badge-blue': currentTab === 'person-list'}">{{currentList.NumberOfPersons}}</span>
                </a>
                <a v-if="showAddressTab" href="" class="menu-item" :class="{'active': currentTab === 'address-list'}" @click.prevent="currentTab = 'address-list'">
                    <i class="fas fa-map-marker-alt mr-2"></i> {{translate('tab_addresses')}}
                    <span class="badge badge-pill badge-secondary float-right badge-fixed ml-2" :class="{'badge-blue': currentTab === 'address-list'}">{{currentList.NumberOfAddresses}}</span>
                </a>
            </div>
        </div>

        <component :is="currentTab" :current-list="currentList"></component>
    </div>
</div>
