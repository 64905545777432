﻿<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <h3><b>{{translate('screening_remove_vats_title')}}</b></h3>
                <p>
                    {{translate('screening_remove_vats_intro')}}
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="tabs mb-4">
                    <a class="tab" 
                       href="" 
                       v-for="(status, index) in statusses" 
                       :key="index"
                       :class="{'active': selectedStatus === status}"
                       @click.prevent="selectStatus(status)">
                        <div class="status" :class="status">{{translate(status)}}</div>
                    </a>
                </div>
            </div>
            <div class="col-md-12 mb-2 font-small">
                {{translate('shown')}}: {{startIndex + 1}} - {{Math.min(endIndex + 1, statusCompanies.length)}} / {{statusCompanies.length}}
            </div>
            <div class="col-md-12 vat-list mb-4" ref="companylist">
                <div class="row" >
                    <div class="col-md-6" v-for="company in listedCompanies" :key="company.Vat">
                        <div class="company-row">
                            <div class="row">
                                <div class="col-12">
                                    <input type="checkbox" :id="company.Vat" v-model="company.isSelected" class="form-check-input" />
                                    <label class="m-0" :for="company.Vat">{{company.Naam|truncate(50)}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2 my-auto">
                <a href="" class="btn btn-default btn-block" @click.prevent="back()"><i class="fas fa-angle-left mr-2"></i> {{translate('btn_back')}}</a>
            </div>
            <div class="col-md-7">
                <alerts-usage :used="totalUsed" :selected="totalSelectedCompanies" :max="maxCompanies"></alerts-usage>
            </div>
            <div class="col-md-3 my-auto">
                <a href="" class="btn btn-blue btn-block" @click.prevent="proceed()" :class="{'disabled':isLimitExceeded}" :disabled="isLimitExceeded">{{translate('btn_complete')}} <i class="fas fa-angle-right ml-2"></i></a>
            </div>
        </div>
    </div>
</template>

<script>
    import AlertsUsage from './alerts-usage.vue';
    import _ from 'lodash';
    import Translate from 'Mixins/translate';
    import Truncate from 'Mixins/truncate';
    import Ajax from 'Lib/Ajax';
    import Toast from 'Lib/Toast';
    import Vue from 'vue';

    export default Vue.extend({
        name: 'remove-vats-from-screening',
        components: { AlertsUsage },
        props: {
            maxCompanies: Number,
            totalUsed: Number,
            selectedCompanies: Array,
            currentList: Object,
            barometerstatusses: Object
        },
        mixins: [Translate, Truncate],
        data() {
            return {
                jumpAmount: 20,
                selectedStatus: this.selectedCompanies[0].BarometerStatus,
                companies: _.map(this.selectedCompanies, company => {
                    return {
                        ...company,
                        isSelected: true
                    };
                }),
                startIndex: 0,
                endIndex: 40
            }
        },
        computed: {
            extraPaddingBottom() {
                return this.listedCompanies.length < 40 ? '50px' : 0;
            },
            statusses() {
                return _.uniq(_.map(this.companies, 'BarometerStatus'));
            },
            statusCompanies() {
                return _.filter(this.companies, company => company.BarometerStatus === this.selectedStatus);
            },
            listedCompanies() {
                return _.slice(this.statusCompanies, this.startIndex, this.endIndex);
            },
            totalSelectedCompanies() {
                return _.filter(this.companies, 'isSelected').length;
            },
            isLimitExceeded() {
                return this.totalUsed + this.totalSelectedCompanies > this.maxCompanies;
            },
            selectedVats() {
                return _.map(_.filter(this.companies, 'isSelected'), 'Vat');
            }
        },
        methods: {
            selectStatus(status) {
                this.selectedStatus = status;
                this.startIndex = 0;
                this.endIndex = 40;
                const scrollList = this.$refs['companylist'];
                scrollList.scrollTop = 0;
            },
            updateList() {
                return new Promise((resolve, reject) => {
                    Ajax.get(
                        '/ajax/followup',
                        {
                            listId: this.currentList.ListId
                        },
                        (response) => {
                            let companies = response.Data.Companies;
                            let events = response.Data.FollowUpEvents;
                            this.$root.$emit('updateList', {
                                listId: this.currentList.ListId,
                                companies,
                                events
                            });
                            resolve();
                        },
                        () => {
                            reject();
                        }
                    );
                });
            },
            back() {
                this.$root.$emit('change-view', {
                    viewName: 'screening',
                    viewData: {
                        barometerstatusses: this.barometerstatusses
                    },
                    currentList: this.currentList,
                    modalSize: 'xl'
                });
            },
            proceed() {
                this.$root.$emit('setLoading', true);
                Ajax.post(
                    '/ajax/followup/add-companies',
                    {
                        listId: this.currentList.ListId,
                        vatList: this.selectedVats
                    },
                    () => {
                        this.updateList()
                            .then(() => {
                                this.$root.$emit('setLoading', false);
                                this.$root.$emit('change-used-companies');
                                this.$root.$emit('hide-modal', 'addCompanies');
                                this.$root.$emit('hide-modal', 'addList');

                                Toast.success(this.translate('list_updated', this.currentList.Naam));
                            });
                    },
                    err => {
                        Toast.error(err);
                        this.$root.$emit('hide-modal', 'addCompanies');
                        this.$root.$emit('hide-modal', 'addList');
                    }
                )
            },
            sliceCompaniesOnScroll() {
                const scrollList = this.$refs['companylist'];
                scrollList.onscroll = () => {
                    const offset = scrollList.offsetHeight;
                    const scrolled = scrollList.scrollTop;
                    const scrollHeight = scrollList.scrollHeight; 

                    const scrolledToBottomOfModal = offset + scrolled >= scrollHeight;
                    const scrolledToTopOfModal = scrolled === 0;

                    if (scrolledToBottomOfModal && (this.statusCompanies.length - 1) > this.endIndex) {
                        scrollList.scrollTop = 20;

                        this.endIndex += this.jumpAmount;

                        if (this.endIndex >= this.statusCompanies.length) {
                            this.endIndex = this.statusCompanies.length - 1;
                        }

                        this.startIndex = this.endIndex - 40;

                        if (this.startIndex < 0)
                            this.startIndex = 0;

                    } else if (scrolledToTopOfModal && this.startIndex > 0) {
                        scrollList.scrollTop = 20;

                        this.startIndex -= this.jumpAmount;

                        if (this.startIndex < 0)
                            this.startIndex = 0;

                        this.endIndex = Math.min(this.startIndex + 40, (this.statusCompanies.length - 1));
                    }
                }
            }
        },
        mounted() {
            this.sliceCompaniesOnScroll();
        }
    });
</script>

<style scoped>
    .vat-list {
        overflow-y: scroll;
        max-height: 440px;
    }
    
    .company-row {
        padding: 5px;
        background: #f8f9f9;
        margin: 2px;
        border-radius: 3px;
        user-select: none;
    }
</style>