<template>
    <div class="row mt-5">
        <div class="col-lg-11 col-md-12">
            <div class="box" v-if="isActivated && integration.ctaType !== CtaType.NOCTA">
                <div class="box-title">{{translate('aan_de_slag')}}</div>
                <div class="row mb-3">
                    <div class="col-md-12">
                        <div v-if="intro" v-html="intro"></div>
                    </div>
                </div>
                <div v-if="hasInstallStep" class="mb-5 step-wrapper">
                    <div class="step">
                        <div class="step-number">1</div>
                        <h2><b>{{translate('download_title')}}</b></h2>
                    </div>
                    <div v-html="externalLinkText" class="cms-content"></div>
                    <div v-if="externalLinkText && appText" class="my-2 ml-4 font-italic text-grey">
                        {{translate('or')}}
                    </div>
                    <div v-if="appText" v-html="appText" class="cms-content"></div>
                </div>

                <div v-if="apiCredentials" class="step-wrapper mb-5">
                    <div class="step">
                        <div class="step-number">{{apiStepNumber}}</div>
                        <h2><b>{{translate('credentials')}}</b></h2>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-2">
                                {{translate('credentials_text')}}
                            </div>

                            <b>{{translate('username')}}:</b> <span class="user-select-all" v-if="canSeeCredentials">{{apiCredentials.login}}</span><span v-else class="confidential">xxxxxxxxx</span><br>
                            <b>{{translate('password')}}:</b> <span class="user-select-all" v-if="canSeeCredentials">{{apiCredentials.password}}</span><span v-else class="confidential">xxxxxxxxxxx</span>
                        </div>
                    </div>
                    <div class="row" v-if="!canSeeCredentials">
                        <div class="col-12">
                            <div class="tip py-2 mt-3 d-inline-block">
                                {{translate('contact_admin_credentials')}}
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="apiCredentials.endDate">
                        <div class="col-12">
                            <div class="tip py-2 mt-3 d-inline-block">
                                <template v-if="!Date.fromHansDate(apiCredentials.endDate).isInThePast()">
                                    {{translate('api_enddate', Date.fromHansDate(apiCredentials.endDate).format())}}.
                                </template>
                                <template v-else>
                                    {{translate('api_key_ended', Date.fromHansDate(apiCredentials.endDate).format())}}.<br>
                                    <a :href="'/integrations/contact/' + integration.id" class="link">{{translate('contact_us')}}</a> {{translate('to_regain_access')}}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="step-wrapper" v-if="downloads.length > 0 || (partnerText && partnerText !== '')">
                    <div class="step">
                        <div class="step-number">{{downloadsStepNumber}}</div>
                        <h2><b>{{translate('help_needed')}}</b></h2>
                    </div>
                    <div class="row mt-1">
                        <div class="col-12 cms-content" v-html="partnerText"></div>
                    </div>
                    <div class="row mt-4" v-for="download in downloads" :key="download.id">
                        <div class="col-6">
                            <template v-if="language === 'nl'">
                                {{download.nameNl}}
                            </template>
                            <template v-else-if="language === 'fr'">
                                {{download.nameFr}}
                            </template>
                            <template v-else>
                                {{download.nameEn}}
                            </template>
                        </div>
                        <div class="col-2">
                            <a :href="download.pathNl" v-if="download.hasFileNl" :download="integration.name+'_'+download.nameNl+'.pdf'" class="btn btn-info btn-sm"><i class="fas fa-download mr-2"></i>NL</a>
                        </div>
                        <div class="col-2">
                            <a :href="download.pathFr" v-if="download.hasFileFr" :download="integration.name+'_'+download.nameFr+'.pdf'" class="btn btn-info btn-sm"><i class="fas fa-download mr-2"></i>FR</a>
                        </div>
                        <div class="col-2">
                            <a :href="download.pathEn" v-if="download.hasFileEn" :download="integration.name+'_'+download.nameEn+'.pdf'" class="btn btn-info btn-sm"><i class="fas fa-download mr-2"></i>EN</a>
                        </div>
                    </div>
                </div>
            </div>
            <coming-soon v-else-if="integration.label === SoftwareLabel.COMINGSOON" :integration="integration" :isLoggedInWithEmail="isLoggedIn && hasEmail" :language="language"></coming-soon>
            <no-account v-else-if="!isLoggedIn" :integratorId="integration.integratorCredentials.softwareId"></no-account>
            <institute v-else-if="isInstitute" :integration="integration"></institute>
            <not-activatable v-else-if="integration.ctaType === CtaType.CONTACTUS && !isActivated" :integration="integration"></not-activatable>
            <div class="box" v-else-if="integration.ctaType === CtaType.ACTIVATABLE">
               <div class="box-title">{{translate('aan_de_slag')}}</div>
                
                <div class="row mb-3">
                    <div class="col-md-12">
                        <div v-if="intro" v-html="intro"></div> 
                    </div>
                </div>

                <template v-if="isLoading">
                    <div class="text-center py-5">
                        <i class="fas fa-spinner spinning text-blue fa-2x"></i>
                    </div>
                </template>
                <template v-else>
                    <p>
                        {{translate('activation_details')}}
                    </p>

                    <div class="form-group mt-5">
                        <input id="IkGaAkkoord" type="checkbox" class="form-check-input" v-model="hasAcceptedTerms"/>
                        <label for="IkGaAkkoord" class="d-block">
                            {{translate('ik_ga_akkoord')}} 
                            <a :href="termsLink" target="_blank" class="link">
                                {{translate('gebruiksvoorwaarden').toLowerCase()}}
                            </a>
                    
                        </label>
                    </div>
                    <div>
                        <button class="btn btn-info btn-200" @click.prevent="activate()" :disabled="!hasAcceptedTerms">{{translate('btn_activate')}}</button>
                    </div>
                </template>
           </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Language } from '../../../types/dto/Language';
    import Ajax from '../../../lib/Ajax';
    import NoAccount from '../components/no-account.vue';
    import Institute from '../components/institute.vue';
    import DateHelpers from '../../mixins/date';
    import Translate from '../../mixins/translate';
    import NotActivatable from '../components/not-activatable.vue';
    import ComingSoon from '../components/coming-soon.vue';
    import mixins from 'vue-typed-mixins';
    import {SoftwarePartner, SoftwarePartnerManual, SoftwareLabel, CtaType} from '../../../types/dto/SoftwarePartner';
    import { ApiUser } from '../../../types/dto/ApiUser';
    
    type FakeApiUser = Omit<ApiUser, "software"|"customerId"|"id"|"customerName">
    
    export default mixins(DateHelpers, Translate).extend({
        name: 'integrations_detail_page',
        components: { NoAccount, Institute, NotActivatable, ComingSoon },
        data() {
            return {
                integration: window.cw.integration as SoftwarePartner,
                language: window.cw.language as Language,
                hasAcceptedTerms: false as boolean,
                isActivated: false as Boolean,
                apiCredentials: null as ApiUser | FakeApiUser | null,
                isLoading: false,
                downloads: [] as SoftwarePartnerManual[],
                isLoggedIn: window.cw.isLoggedIn,
                hasEmail: window.cw.hasEmail,
                isAdmin: window.cw.isAdmin,
                isInstitute: window.cw.isInstitute,
                isTrial: window.cw.isTrial,
                termsLink: window.cw.termsLink,
                isSalesUser: window.cw.isSalesUser,
                isTempAccount: window.cw.isTempAccount,
                SoftwareLabel,
                CtaType
            };
        },
        computed: {
            canSeeCredentials(): boolean {
                const isForeverActive = !this.apiCredentials?.endDate; 

                return !isForeverActive || this.isAdmin;
            },
            apiStepNumber(): number {
                return this.hasInstallStep ? 2 : 1;
            },
            downloadsStepNumber(): number {
                return this.hasInstallStep ? 3 : 2;
            },
            hasInstallStep(): boolean {
                return !!this.externalLinkText || !!this.appText;
            },
            intro(): string | null {
                switch(this.language){
                    case Language.NL:
                        return this.integration.introNl;
                    case Language.FR:
                        return this.integration.introFr;
                    case Language.EN:
                        return this.integration.introEn;
                    default:
                        return this.integration.introNl;
                }
            },
            externalLinkText(): string | null {
                 switch(this.language){
                    case Language.NL:
                        return this.integration.externalLinkTextNl;
                    case Language.FR:
                        return this.integration.externalLinkTextFr;
                    case Language.EN:
                        return this.integration.externalLinkTextEn;
                    default:
                        return this.integration.externalLinkTextNl;
                }
            },
            partnerText(): string | null {
                 switch(this.language){
                    case Language.NL:
                        return this.integration.partnerTextNl;
                    case Language.FR:
                        return this.integration.partnerTextFr;
                    case Language.EN:
                        return this.integration.partnerTextEn;
                    default:
                        return this.integration.partnerTextNl;
                }
            },
            appText(): string | null {
                switch(this.language){
                    case Language.NL:
                        return this.integration.appTextNl;
                    case Language.FR:
                        return this.integration.appTextFr;
                    case Language.EN:
                        return this.integration.appTextEn;
                    default:
                        return this.integration.appTextNl;
                }
            }
        },
        methods: {
            async ajaxActivate(): Promise<ApiUser | FakeApiUser> {
                return new Promise((resolve, reject) => {
                    if(this.isSalesUser || this.isTempAccount ) {
                        const fakeCredentials: FakeApiUser = {
                            login: "ABCDEF-XXX-OOO",
                            password: "D2F2G8",
                            creationDate: new Date().toHansDate(),
                            endDate: new Date(Date.now() + 12096e5).toHansDate()                            
                        };

                        this.isActivated = true;
                        resolve(fakeCredentials);
                    } else {
                        this.isLoading = true;
                        Ajax.post(
                            "/ajax/integrations/activate",
                        {
                            softwareId: this.integration.id
                        },
                        (response: AjaxResponse<ApiUser>) => {
                            this.isLoading = false;
                            this.isActivated = true;
                            resolve(response.data);
                        },
                        error => {
                            this.isLoading = false;
                            reject(error);
                        });
                    }
                });
            },
            async activate(): Promise<void> {
                this.apiCredentials = await this.ajaxActivate();
                this.getDownloads();
            },
            checkIfActivated(): void {
                this.isLoading = true;
                  Ajax.get(
                    "/ajax/integrations/has-integration",
                    {
                        softwareId: this.integration.id
                    },
                    (response: AjaxResponse<boolean>) => {
                        this.isLoading = false;
                        this.isActivated = response.data;

                        if (this.isActivated) {
                            this.getApiUser();
                            this.getDownloads();
                        }
                    },
                    error => {
                        this.isLoading = false;
                        console.error(error);
                    }
                )
            },
            getApiUser(): void {
                this.isLoading = true;
                  Ajax.get(
                    "/ajax/integrations/api-user",
                    {
                        softwareId: this.integration.id
                    },
                    (response: AjaxResponse<ApiUser>) => {
                        this.isLoading = false;
                        this.apiCredentials = response.data;
                    },
                    error => {
                        this.isLoading = false;
                        console.error(error);
                    }
                )
            },
            getDownloads(): void {
                  this.isLoading = true;
                  Ajax.get(
                    "/ajax/integrations/manuals",
                    {
                        softwareId: this.integration.id
                    },
                    (response: AjaxResponse<SoftwarePartnerManual[]>) => {
                        this.isLoading = false;
                        this.downloads = response.data;
                    },
                    error => {
                        this.isLoading = false;
                        console.error(error);
                    }
                )
            }
        },
        created() {
            if(this.isLoggedIn && !this.isInstitute)
                this.checkIfActivated();
        }
    });
</script>

<style scoped lang="scss">
    @import "../../../../sass/variables";

    .step-wrapper {
        margin-left: 3em;
        .step {
            margin-left: -3em;
            display: flex;
            margin-bottom: 10px;

            .step-number {
                background-color: fade-out($color: $azure, $amount: 0.95);
                color: $azure;
                width: 28px;
                height: 28px;
                border-radius: 50px;
                font-weight: bold;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                margin-right: 15px;
            }

            h2 {
                margin-bottom: 0 !important;
                font-size: 16px;
                display: flex;
                align-items: center;
            }
        }
    }
</style>
