
<g :transform="transform" @mouseover="onHover" @mouseleave="onHoverLeave">
    <g :transform="'translate('+offsetX+','+ offsetY+')'">
        <rect class="kyc-node" 
              :width="$parent.nodeWidth" 
              :height="$parent.nodeHeight" 
              x="0" 
              y="0" 
              :fill="fill" 
              rx="4" 
              stoke-width="1" 
              :stroke="stroke"></rect>
        

        <barometer-icon v-if="company.barometerClassName" svg :score="company.barometerScore" :className="company.barometerClassName"></barometer-icon>
  
        
        <g :transform="'translate(' + (company.barometerClassName ? 48 : 15) + ', 22)'" id="nodeTextContent">
            <a :xlink:href="link" target="_blank">
                <text transform="translate(0, 0)" fill="#0A2439" font-size="12" font-weight="bold" class="hover-underline cursor-help">
                    <title>{{company.name}} {{company.form}}</title>
                    {{company.name|ellipsis(small ? 18 : 22)}}
                    <tspan font-weight="normal" fill="grey" font-size="9">{{company.form}}</tspan>
                </text>
            </a>

            <text transform="translate(0, 15)" fill="#979797" font-size="11">{{company.vat|formatVat}}</text>
        </g>
        <g transform="translate(15, 50)" id="nodeLabel">
            <svg aria-hidden="true" width="10px" height="10px" viewBox="0 0 512 512" role="img" xmlns="http://www.w3.org/2000/svg">
                <path fill="#7F90A0" d="M0 252.118V48C0 21.49 21.49 0 48 0h204.118a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882L293.823 497.941c-18.745 18.745-49.137 18.745-67.882 0L14.059 286.059A48 48 0 0 1 0 252.118zM112 64c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z"></path>
            </svg>

            <svg-text :max-lines="2" :font-size="11" :characters-per-line="small ? 26 : 30" fill="#7F90A0">{{company.activity}}</svg-text>
        </g>

        <polygon class="kyc-node-arrow" id="arrow" :points="arrowVectors" :stroke="stroke" :fill="fill" :transform="'translate(' + arrowOffsetX + ', ' + arrowOffsetY + ')'" />
        <polygon id="arrowWhite" :points="arrowVectors2" :fill="fill" :transform="'translate(' + arrowOffsetX + ', ' + arrowOffsetY + ')'" />
    </g>
</g>
