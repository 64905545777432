
<div ref="country-dropdown-wrapper">
    <div @click="showDropdown = !showDropdown" class="country-dropdown">
        <div v-if="currentCountry">
            <img :alt="currentCountry.name" :src="'/images/flags/' + currentCountry.code + '.svg'" width="18" height="18" class="mr-1">
            {{currentCountry.name}}
        </div>
        <i class="fas fa-angle-down ml-3 text-grey"></i>
    </div>
    <div v-if="showDropdown">
        <transition name="slide-fade">
            <div id="autocompleteWrapper" class="autocomplete-wrapper country-variant">
                <input
                        ref="searchTerm"
                        type="search"
                        class="form-control"
                        v-model="searchTerm"
                        :placeholder="translate('type_to_search')"
                        v-on:search="makeSuggestions"
                        v-on:keyup="makeSuggestions"
                        v-on:keyup.down="selectNext"
                        v-on:keyup.up="selectPrev"
                        v-on:keypress.enter.stop="acceptSelected"
                        v-on:keydown.tab.stop="acceptSelected"/>
                <div class="countries">
                    <div  v-for="(country, index) in suggestions" 
                          :key="index" 
                          class="suggestion-selector" 
                          :class="{'selected': selectedIndex === index}" 
                          v-on:click="onCountryClick(index)">
                        <img :alt="country.name" :src="'/images/flags/' + country.code + '.svg'" width="18" height="18" class="mr-1"> {{country.name}}
                    </div>
                </div>
            </div>
        </transition>
    </div>
</div>
