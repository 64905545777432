﻿import Vue from 'vue';
import Page from '../../lib/Page';
import BalansTotaal from './balans-totaal.vue';
import Resultatenrekening from './resultatenrekening.vue';
import Liquiditeit from './liquiditeit.vue';
import Rendabiliteit from './rendabiliteit.vue';

export default (function () {
    Page.registerVue('#report-graphs', function (rootSelector) {
        new Vue({
            el: rootSelector,
            name: 'report_graphs',
            components: {
                BalansTotaal,
                Resultatenrekening,
                Liquiditeit,
                Rendabiliteit
            }
        });
    });
}());
