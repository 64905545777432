﻿import Vue from 'vue';
import Ajax from '../lib/Ajax';
import Page from '../lib/Page';
import Translate from './mixins/translate';
import T from '../lib/Translate';
import CwModal from './shared/modal.vue';

export default (function () {
    
    Page.registerVue('#meeting-room-wrapper', function (rootSelector) {
        new Vue({
            el: rootSelector,
            components: {
                CwModal
            },
            data: {
                errorRoom: "",
                googleMeetRooms: []
            },
            mixins: [Translate],
            methods: {
                getGoogleMeetRooms() {
                    Ajax.get(
                        '/ajax/contact/google-meet-rooms',
                        {},
                        (response: AjaxResponse<any>) => {
                            this.googleMeetRooms = response.Data.GoogleMeetRooms;
                        },
                        (err: any) => {
                            this.errorRoom = T("meeting_room_error_rooms");
                        }
                    );
                },
                openModal() {
                    this.getGoogleMeetRooms();
                    this.$root.$emit('show-modal', 'meeting-room');
                }
            },
            mounted() {
                this.getGoogleMeetRooms();
            }
        });
    });
}());