﻿<template>
    <div>
        <transition name="fade">
            <div class="drawer-backdrop" @click="closeDrawer" v-show="isOpen"></div>
        </transition>
        <transition name="slide">
            <aside class="drawer" v-show="isOpen">
                <component :is="activeDrawerView"></component>
            </aside>
        </transition>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {useStore} from "../store";
import {DrawerView} from "../types";
import FilterSave from './drawers/filter-save.vue';
import FilterList from './drawers/filter-list.vue';
import FilterDetail from './drawers/filter-detail.vue';
import ColumnEdit from './drawers/column-edit.vue';
import ColumnAdd from './drawers/column-add.vue';

export default Vue.extend({
    name: "drawer",
    components: {FilterList, FilterDetail, FilterSave, ColumnEdit, ColumnAdd},
    data() {
        return {
            store: useStore()
        }
    },
    watch: {
        isOpen(isOpen: boolean) {
            if(isOpen) {
                document.body.style.maxHeight = '100vh';
                document.body.style.overflow = 'hidden';
            } else{
                document.body.style.maxHeight = 'initial';
                document.body.style.overflow = 'initial';
            }
        }
    },
    computed: {
        isOpen(): boolean {
            return this.store.isDrawerOpen;
        },
        activeDrawerView(): DrawerView | null {
            return this.store.drawerView;
        }
    },
    methods: {
        closeDrawer(): void {
            this.store.closeDrawer();
        }
    }
});
</script>

<style scoped lang="scss">

.drawer-backdrop {
    position: fixed;
    inset: 0;
    background: #00000040;
    z-index: 50;
}

.drawer {
    background: white;
    width: 35vw;
    z-index: 60;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    box-shadow: -15px 0 17px #00000021;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.slide-enter-active, .slide-leave-active {
    transition: transform .25s;
}

.slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */
{
    transform: translateX(100%);
}
</style>