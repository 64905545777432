﻿import _ from 'lodash';
import Vue from 'vue';
import Translate from '../lib/Translate';
import Page from '../lib/Page';

export default (function () {

    Page.registerVue('#alertsPlusPreviewTable', function (rootSelector) {
        Vue.component('columnpicker', {
            template: '#column-picker-template',
            props: {
                pickerTitle: String,
                pickerInputName: String,
                pickerIsRequired: Boolean,
                pickerIndex: Number,
                activePickerIndex: Number,
                pickerTotalCount: Number
            },
            data: function () {
                return {
                    title: this.pickerTitle,
                    inputName: this.pickerInputName,
                    isRequired: this.pickerIsRequired,
                    totalCount: this.pickerTotalCount,
                    selectedColumnIndex: 0
                };
            },
            computed: {
                hasColumnSelected: function () {
                    return this.selectedColumnIndex !== 0;
                },
                isActive: function () {
                    return this.pickerIndex === this.activePickerIndex;
                }
            },
            watch: {
                selectedColumnIndex: function (index) {
                    var validity = (this.isRequired && index > 0) || !this.isRequired;
                    this.$root.$emit('toggleNextButtonState', validity);
                }
            },
            methods: {
                selectColumn: function (index) {
                    if (index === this.selectedColumnIndex) {
                        this.selectedColumnIndex = 0;
                    } else {
                        this.selectedColumnIndex = index;
                    }
                }
            }
        });

        new Vue({
            el: rootSelector,
            data: {
                activePickerIndex: 1,
                pickerIsValid: false,
                columnpickers: [
                    {
                        pickerTitle: Translate("vat_column_title"),
                        pickerInputName: 'VatColumnIndex',
                        pickerIsRequired: true
                    },
                    {
                        pickerTitle: Translate("reference_column_title"),
                        pickerInputName: 'ReferentieColumnIndex',
                        pickerIsRequired: false
                    }
                ]
            },
            mounted: function () {
                this.$root.$on('toggleNextButtonState', function (validity) {
                    return this.pickerIsValid = validity;
                });
            },
            computed: {
                isFirstPicker: function () {
                    return this.activePickerIndex === 1;
                },
                isLastPicker: function () {
                    return this.activePickerIndex === 2;
                }
            },
            methods: {
                nextPicker: function () {
                    this.activePickerIndex++;
                },
                prevPicker: function () {
                    this.activePickerIndex--;
                }
            }
        });
    });
}());
