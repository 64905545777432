
<div class="container">
    <template v-if="isLoading">
        <div class="text-center py-5">
            <i class="fas fa-3x fa-spinner spinning text-blue"></i>
        </div>
    </template>
    <template v-else>
        <div class="row">
            <div class="col-6">
                <h1 class="mb-0">{{translate('kyc_report')}}</h1>
            </div>
            <div class="col-6 text-right text-greyblue mt-auto">
                {{translate('requested_by')}} <b>{{user}}</b><br />
                {{translate('requested_date_prefix')}} <b>{{request_date}}</b>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12 d-flex align-items-center mb-2">
                <h2 class="mb-0 text-xl"><b>{{company.name}} <span class="text-grey font-400">({{company.form.toLowerCase()}})</span></b></h2>
                <div class="ml-3">
                    <barometer-icon :class-name="company.barometerClassName"
                                    :score="company.barometerScore"></barometer-icon>
                </div>
            </div>
        </div>

        <div class="row pb-3">
            <div class="col-sm-6">
                <div class="row my-2">
                    <div class="col-6">
                        <span class="text-greyblue text-uppercase font-500">{{translate('status')}}</span>
                    </div>
                    <div class="col-6">
                        {{translate(company.status)}}
                    </div>
                </div>
                <div class="row my-2">
                    <div class="col-6">
                        <span class="text-greyblue text-uppercase font-500">{{translate('vat')}}</span>
                    </div>
                    <div class="col-6">
                        {{company.vat|formatVat}}
                    </div>
                </div>
                <div class="row my-2">
                    <div class="col-6">
                        <span class="text-greyblue text-uppercase font-500">{{translate('establish_date')}}</span>
                    </div>
                    <div class="col-6">
                        {{company.startDate}}
                    </div>
                </div>
                <div class="row my-2">
                    <div class="col-6">
                        <span class="text-greyblue text-uppercase font-500">{{translate('address')}}</span>
                    </div>
                    <div class="col-6">
                        {{company.address}}
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row my-2">
                    <div class="col-6">
                        <span class="text-greyblue text-uppercase font-500">{{translate('main_activity')}}</span>
                    </div>
                    <div class="col-6">
                        {{company.activity}}
                    </div>
                </div>
                <div class="row my-2">
                    <div class="col-6">
                        <span class="text-greyblue text-uppercase font-500">{{translate('company_size')}}</span>
                    </div>
                    <div class="col-6">
                        {{company.size}} ({{company.amountOfEmployees}})
                    </div>
                </div>
                <div class="row my-2">
                    <div class="col-6">
                        <span class="text-greyblue text-uppercase font-500">{{translate('locations')}}</span>
                    </div>
                    <div class="col-6">
                        {{company.locations}}
                    </div>
                </div>
                <div class="row my-2">
                    <div class="col-6">
                        <span class="text-greyblue text-uppercase font-500">{{translate('warnings')}}</span>
                    </div>
                    <div class="col-6">
                        {{company.amountOfWarnings}}
                    </div>
                </div>

            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12">
                <h2 class="text-xl"><b>{{translate('persons_linked_to', company.name)}}</b></h2>
                <p>
                    {{translate('kyc_report_ubo_description')}}
                </p>
            </div>
        </div>

        <div class="my-4">
            <p>
                <template v-if="allPersons.length === 1">
                    {{translate('amount_of_persons_linked_to_singular', allPersons.length, company.name)}}
                </template>
                <template v-else>
                    {{translate('amount_of_persons_linked_to_plural', allPersons.length, company.name)}}
                </template>
            </p>
        </div>

        <div class="row my-5">
            <div class="col-12">
                <div class="tip">
                    <div class="tip__title">
                        {{translate('legend')}}
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <div class="my-3 row">
                                <div class="col-2 text-center">
                                    <svg width="50" height="15"><path stroke-dasharray="2 5" stroke="black" d="M 0,6 50,6" stroke-width="1" fill="transparent"></path></svg>
                                </div>
                                <div class="col-10">
                                    <span class="text-greyblue font-500">{{translate('mandateholder')}}</span>
                                </div>
                            </div>

                            <div class="my-3 row">
                                <div class="col-2 text-center">
                                    <svg width="50" height="15"><path stroke-dasharray="7 6" stroke="black" d="M 0,6 50,6" stroke-width="1" fill="transparent"></path></svg>
                                </div>
                                <div class="col-10">
                                    <span class="text-greyblue font-500">{{translate('participation')}}</span>
                                </div>
                            </div>

                            <div class="my-3 row">
                                <div class="col-2 text-center">
                                    <svg width="50" height="15"><path stroke-dasharray="250 0" stroke="black" d="M 0,6 50,6" stroke-width="1" fill="transparent"></path></svg>
                                </div>
                                <div class="col-10">
                                    <span class="text-greyblue font-500">{{translate('participation')}} & {{translate('mandateholder')}}</span>
                                </div>
                            </div>
                            <div class="my-3 row" v-if="hasCircularConstructions">
                                <div class="col-2 text-center flex-v-center justify-content-center">
                                    <svg width="18" height="15"><path stroke-dasharray="6 6" stroke="red" d="M 0,6 35,6" stroke-width="1" fill="transparent"></path></svg>
                                    /
                                    <svg width="13" height="15"><path stroke-dasharray="250 0" stroke="red" d="M 0,6 35,6" stroke-width="1" fill="transparent"></path></svg>
                                    /
                                    <svg width="13" height="15"><path stroke-dasharray="1 4" stroke="red" d="M 0,6 35,6" stroke-width="1" fill="transparent"></path></svg>
                                </div>
                                <div class="col-10">
                                    <span class="text-greyblue font-500">{{translate('circular_construction')}}</span>
                                </div>
                            </div>
                        </div>
                  
                        <div class="col-6">
                            <div class="my-3 row">
                                <div class="col-2 text-center">
                                    <svg aria-hidden="true" width="12px" height="12px" viewBox="0 0 512 512" role="img" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="black" d="M0 252.118V48C0 21.49 21.49 0 48 0h204.118a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882L293.823 497.941c-18.745 18.745-49.137 18.745-67.882 0L14.059 286.059A48 48 0 0 1 0 252.118zM112 64c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z"></path>
                                    </svg>
                                </div>
                                <div class="col-10">
                                    <span class="text-greyblue font-500">{{translate('main_activity')}}</span>
                                </div>
                            </div>

                            <div class="my-3 row d-none d-print-flex">
                                <div class="col-2 text-center">
                                    <i class="fas fa-building"></i>
                                </div>
                                <div class="col-10">
                                    <span class="text-greyblue font-500">{{translate('active_mandates')}}</span>
                                </div>
                            </div>

                            <div class="my-3 row d-none d-print-flex">
                                <div class="col-2 text-center">
                                    <i class="fas fa-gavel"></i>
                                </div>
                                <div class="col-10">
                                    <span class="text-greyblue font-500">{{translate('fails')}}</span>
                                </div>
                            </div>

                            <div class="my-2 row">
                                <div class="col-2 text-center">
                                    <svg aria-hidden="true" viewBox="-50 -50 100 100" width="30" height="30" role="img" xmlns="http://www.w3.org/2000/svg">
                                        <circle r="50" cx="0" cy="0" stroke="#F1F1F1" fill="white"></circle>
                                        <text transform="translate(4, 0)" fill="#7F90A0" font-size="36" text-anchor="end" font-weight="bold" alignment-baseline="middle">33</text>
                                        <text transform="translate(5, 0)" fill="#7F90A0" font-size="32" text-anchor="start" font-weight="bold" alignment-baseline="middle">%</text>
                                    </svg>
                                </div>
                                <div class="col-10 my-auto">
                                    <span class="text-greyblue font-500">{{translate('shares_in_daughter')}}</span>
                                </div>
                            </div>
                            <div class="my-2 row">
                                <div class="col-2 text-center">
                                    <svg aria-hidden="true" viewBox="-50 -50 100 100" width="30" height="30" role="img" xmlns="http://www.w3.org/2000/svg">
                                        <circle r="50" cx="0" cy="0" stroke="#F1F1F1" fill="white"></circle>
                                        <text fill="#7F90A0" font-size="40" text-anchor="middle" font-weight="bold" alignment-baseline="middle">?</text>
                                    </svg>
                                </div>
                                <div class="col-10 my-auto">
                                    <span class="text-greyblue font-500">{{translate('shares_in_daughter_unknown')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pagebreak"> </div>

        <div class="row">
            <div class="col-12">
                <kyc-ubo 
                v-for="(ubo, index) in allPersons" 
                v-bind="ubo" 
                :key="ubo.id" 
                :companies="companies"
                @add-to-polling-list="addToPollingList" 
                :index="index + 1"></kyc-ubo>
            </div>
        </div>
    </template>

    <footer class="kyc-report-footer">
        <div class="row">
            <div class="col-4">
                {{translate('kyc_footer_title', company.name + ' ' + company.form.toLowerCase())}}
            </div>
            <div class="col-4 text-center">
                {{request_date}}
            </div>
            <div class="col-4 text-right">
                {{translate('requested_by')}} {{user}}
            </div>
        </div>
    </footer>
</div>
