﻿import _ from 'lodash';
import Vue from 'vue';
import Page from 'Lib/Page';
import AddressSearchResults from './search-results.vue';
import CwModal from '../../../shared/modal.vue'
import Ajax from "../../../../lib/Ajax";
import Toast from "../../../../lib/Toast";
import T from "../../../../lib/Translate";
import QueryString from "../../../../lib/QueryString";

export default (function () {
    Page.registerVue('#addressSearchResults', function (rootSelector) {
        new Vue({
            el: rootSelector,
            components: {
                'address-search-results': AddressSearchResults,
                CwModal
            },
            data: {
                searchResults: window.cw.searchResults,
                addressId: window.cw.searchAddressId,
                origins: window.cw.origins,
                lists: window.cw.lists,
                listId: 0,
                isAddedToAlerts: window.cw.isAddedToAlerts,
                street: '',
                houseNumber: '',
                postalCode: '',
                city: ''
            },
            methods: {
                onAddToAlerts() {
                    Ajax.post(
                        '/ajax/followup/add-address',
                        {
                            listId: this.listId,
                            addressId: this.addressId,
                            zipCode: this.postalCode,
                            city: this.city,
                            street: this.street,
                            houseNumber: this.houseNumber
                        },
                        () => {
                            this.$root.$emit('hide-modal', 'add-to-alerts');
                            Toast.success(T('added_to_alerts'));
                            this.isAddedToAlerts = true;
                        },
                        err => {
                            Toast.error(err);
                        }
                    );
                }
            },
            mounted() {
                Page.applyBehaviors(rootSelector);

                if (this.lists && this.lists.length > 0)
                    this.listId = this.lists[0].id;

                const uri = window.location.href;
                this.postalCode = QueryString.getParameter(uri, "PostalCode") ?? "";
                this.city = QueryString.getParameter(uri, "City") ?? "";
                this.street = QueryString.getParameter(uri, "Street") ?? "";
                this.houseNumber = QueryString.getParameter(uri, "Number") ?? "";
            }
        });
    });
}());
