
<div class="neo-box no-hover no-border h-100">
    <div class="inner-container text-left m-b-0 ">
        <div class="switches">
            <a v-for="st in searchTypes" 
               href="#" 
               class="switch-label" 
               v-on:click.stop.prevent="switchSearchType(st.name)" 
               v-bind:class="{active: currentSearchType===st.name}">
                <i class="fas m-r-10" :class="st.icon"></i> <span class="d-none d-md-inline-block">{{st.label}}</span>
            </a>
        </div>
        <div class="search-bar">
            <component :is="currentSearchType" :gtm-action="gtmAction" :gtm-category="gtmCategory"></component>
        </div>
    </div>
</div>
