
<div class="grey-box mb-5 page-break-avoid">
    <a :href="link" target="_blank" class="text-body text-decoration-none">
        <div class="row pb-3 border-bottom">
            <div class="col-6">
                <h3 class="text-body text-xl"><b>{{name}}</b></h3>
                <div class="text-grey">
                    <i class="fas fa-home mr-1"></i> {{location}}
                </div>
            </div>
            <div class="col-6 text-right text-body">
                <div class="mb-2" :title="translate('active_mandates')">
                    {{numberOfActiveMandates}}
                    <i class="fas fa-building ml-2"></i>
                </div>
                <div :title="translate('fails')">
                    {{numberOfFails}}
                    <i class="fas fa-gavel ml-2"></i>
                </div>
            </div>
        </div>
    </a>
    <div class="row mt-4 pb-3 border-bottom">
        <div class="col-12" ref="svg">
            <h3 class="text-uppercase font-weight-bold">{{translate('links')}}</h3>
            <!--IE: have to render svg with specific height or it doens't scale. Height shouldn't be bound for other cases to retain print-specific lay-outs. -->
            <svg v-if="isIE" xmlns="http://www.w3.org/2000/svg" style="overflow: visible;" :viewBox="'0 0 ' + width + ' ' + height" :height="height">
               <ubo-edge 
                v-for="(edge, index) in edgesWithMetaInfo" 
                v-bind="edge" 
                :node-height="nodeHeight" 
                :col-width="colWidth" 
                :row-height="rowHeight" 
                :circle-radius="circleRadius" 
                :arrow-height="arrowHeight"
                :hovered-vat="hoveredVat"
                :key="'edge_'+index"></ubo-edge>
                <ubo-node v-for="(node, index) in nodes" v-bind="node" :key="'node_'+index" :small="hasSmallNodes"></ubo-node>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" style="overflow: visible;" :viewBox="'0 0 ' + width + ' ' + height" >
                <ubo-node v-for="(node, index) in nodes" v-bind="node" :key="'node_'+index" :small="hasSmallNodes" @onHover="onHover" @onHoverLeave="onHoverLeave" :hovered-vat="hoveredVat"></ubo-node>
                <ubo-edge 
                v-for="(edge, index) in edgesWithMetaInfo" 
                v-bind="edge" 
                :node-height="nodeHeight" 
                :col-width="colWidth" 
                :row-height="rowHeight" 
                :circle-radius="circleRadius" 
                :arrow-height="arrowHeight" 
                :hovered-vat="hoveredVat"
                :key="'edge_'+index"></ubo-edge>
            </svg>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12">
            <div class="d-flex align-items-center mb-2">
                <h3 class="text-uppercase font-weight-bold my-auto">{{translate('pep_title')}}</h3> 
                <span class="ml-1"> {{translate('for_the_name')}} <b>"{{lastName}} {{firstName}}"</b></span>
                <i class="fas fa-info-circle text-yellow ml-1 mr-5" :title="translate('pep_explanation')"></i>
                <button class="btn btn-blue btn-sm d-print-none" type="button" @click="psCheck()" :disabled="isLoading">
                    <i class="fas fa-shield-check mr-1"></i>
                    <span v-if="!lastCheckDate">{{translate('btn_pep')}}</span>
                    <span v-else>{{translate('btn_pep_again')}}</span>
                </button>
            </div>
            <div class="card-box warning mb-3" v-if="errorMessage">{{errorMessage}}</div>

            <div class="my-4" v-if="showUpsell">
                <upsell-banner white
                               border
                               href="/upgrade/kyc"
                               :btn="translate('increase_pep_btn')"
                               :title="translate('increase_pep_title')"
                               :text="translate('increase_pep_text')"></upsell-banner>
            </div>

            <div v-if="isLoading">
                <i class="fas fa-spinner spinning text-blue mr-2"></i> {{translate('fetching_data')}}
            </div>
            <p class="font-italic" v-else-if="!lastCheckDate || lastCheckStatus !== 'success'">
                {{translate('never_pepped')}}
            </p>
            <template v-else>
                <table class="table table-sm mt-3" v-if="hits.length > 0" :style="'max-width:' + width + 'px !important;'">
                    <thead>
                        <tr>
                            <th width="85">{{translate('pep_header_type')}}</th>
                            <th width="65">match</th>
                            <th width="200">{{translate('pep_header_name')}}</th>
                            <th width="100">{{translate('pep_header_nationality')}}</th> 
                            <th width="100">{{translate('pep_header_birthdate')}}</th>
                            <th width="100">{{translate('pep_header_listname')}}</th>
                            <th>{{translate('pep_header_reason')}}</th>
                        </tr>
                    </thead>
                    <tbody style="background-color: white">
                        <tr v-for="(hit, index) in hits" :key="index">
                            <td>
                                <span class="badge badge-inverted badge-sm"
                                      :class="{'badge-blue': hit.hitType === 'Peps', 'badge-danger': hit.hitType === 'Sanction'}">
                                    <i class="fas mr-1" :class="{'fa-landmark-alt': hit.hitType === 'Peps', 'fa-user-secret': hit.hitType === 'Sanction'}"></i>
                                    {{hit.hitType}}
                                </span>
                            </td>
                            <td>{{hit.score}}%</td>
                            <td><b>{{hit.name.lastname}} {{hit.name.firstname}}</b></td>
                            <td>{{hit.nationality}}</td>
                            <td>{{hit.birthDate}}</td>
                            <td>{{hit.listName}}</td>
                            <td>{{translateInstituteGroup(hit.reason)}}</td>
                        </tr>
                    </tbody>
                </table>
                <p v-else>
                    <i class="mr-2 fas fa-check-circle text-teal"></i>{{translate('no_results')}}
                </p>

                <p class="text-grey font-italic mt-4 mb-0">
                    {{translate('last_pep_date')}} {{lastCheckDate|toLongDate}}
                </p>
            </template>

            <div class="position-absolute bottom-0 right-0 mr-3">
                {{translate('person').toLowerCase()}} {{index}} / {{$parent.allPersons.length}}
            </div>
        </div>
    </div>
</div>
