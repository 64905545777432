<template>
    <form @submit.prevent="onSubmit">
        <div class="row mb-4 mt-5">
            <div class="col-8">
                <ul class="nav nav-tabs border-bottom">
                    <li class="nav-item" >
                        <a href="" class="nav-link" :class="{'active': currentTab === Tab.ALGEMEEN}" @click.prevent="changeTab(Tab.ALGEMEEN)">
                            <i class="fas fa-info-circle mr-1 text-lightgrey"></i> Algemeen</a>
                    </li>
                     <li class="nav-item" >
                        <a href="" class="nav-link" :class="{'active': currentTab === Tab.NEDERLANDS}" @click.prevent="changeTab(Tab.NEDERLANDS)">
                            <img src="/images/flags/nl.svg" class="mr-1 height-auto" width="13" height="13"> Nederlands</a>
                    </li>
                    <li class="nav-item" >
                        <a href="" class="nav-link" :class="{'active': currentTab === Tab.FRANS}" @click.prevent="changeTab(Tab.FRANS)">
                            <img src="/images/flags/fr.svg" class="mr-1 height-auto" width="13" height="13"> Francais</a>
                    </li>
                    <li class="nav-item" >
                        <a href="" class="nav-link" :class="{'active': currentTab === Tab.ENGELS}" @click.prevent="changeTab(Tab.ENGELS)">
                            <img src="/images/flags/en.svg" class="mr-1 height-auto" width="13" height="13"> English</a>
                    </li>
                </ul>
            </div>
            <div class="col-4 text-right">
                <button type="submit" class="btn btn-blue btn-200"><i class="fas fa-save mr-1"></i> Opslaan</button>
            </div>
        </div>

        <!-- TAB ALGEMEEN -->
        <div class="row"  v-if="currentTab === Tab.ALGEMEEN">
            <div class="col-6">
                <div class="form-group">
                    <div class="row">
                        <div class="col-7">
                        <file-input 
                            v-on:input="onImageUpload" 
                            :multiple="false" 
                            :required="false" 
                            name="banner"
                            placeholder=".jpg" 
                            title="Upload image"
                            accept=".jpg" ></file-input>
                        </div>
                        <div class="col-5 mt-auto">
                            <img :src="image.path" v-if="image" class="img-fluid"/>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <input id="isPinned" type="checkbox" v-model="isPinned" class="form-check-input">
                    <label for="isPinned">In de kijker?</label>
                </div>

                 <div class="form-group">
                    <input id="isPublished" type="checkbox" v-model="isPublished" class="form-check-input">
                    <label for="isPublished">Published?</label>
                </div>

                <hr> 
                <h2>Tags</h2>
                <ul class="mt-3">
                    <li v-for="tag in Tags" :key="tag">
                        <input class="form-check-input" :value="tag" :id="tag" type="checkbox" v-model="related_tags">
                        <label :for="tag">{{tag}}</label>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row" v-else> 
            <div class="col-12">
                 <div class="form-group">
                    <label>Titel {{currentTab.toUpperCase()}}</label>
                    <input type="text" class="form-control" v-model="title[currentTab]">
                </div> 

                <div class="form-group">
                    <label>Excerpt {{currentTab.toUpperCase()}}</label>
                    <vue-editor v-model="excerpt[currentTab]" :editorToolbar="customToolbarExcerpt"></vue-editor>
                </div> 

                <div class="form-group big-editor">
                    <label>Tekst {{currentTab.toUpperCase()}}</label>
                    <vue-editor v-model="text[currentTab]" :editorToolbar="customToolbar"></vue-editor>
                </div> 
            </div>
        </div>


    </form>
</template>

<script lang="ts">
    import Vue from 'vue';
    import Option from '../../../types/global/option';
    import FileInput from '../../shared/file-input.vue';
    import Ajax from '../../../lib/Ajax';
    import Toast from '../../../lib/Toast';
    import { BlogArticle } from './types';
    import { Language } from '../../../types/dto/Language';
    import { VueEditor } from "vue2-editor";
    import { Tags } from './types';

    enum Tab {
        ALGEMEEN = "algemeen",
        NEDERLANDS = "nl",
        FRANS = "fr",
        ENGELS = "en"
    }
    
    export default Vue.extend({
        name: 'upsert_form',
        components: {
            FileInput,
            VueEditor,
        },
        props: {
            populateWith: Object as () => Option<BlogArticle>
        },
        data() {
            return {
                Tags,
                Tab,
                customToolbar: [
                    ["bold", "italic", "underline"],  
                    ["link", 'image'], 
                    [{ list: "bullet" }], 
                    [{ 'size': ['small', false, 'large', 'huge'] }],
                    [{ 'header': [false, 2, 3, 4] }],
                ],
                customToolbarExcerpt: [  
                    ["bold", "italic", "underline"],  
                ],
                currentTab: Tab.ALGEMEEN as Tab,
                id: this.populateWith.map(v => v.id),
                isPublished: this.populateWith.map((v: BlogArticle) => v.isPublished).valueOr(false),
                isPinned: this.populateWith.map((v: BlogArticle) => v.isPinned).valueOr(false),
                image: {
                    file: null as File | null,
                    path: this.populateWith.map(v => v.banner).valueOr('')
                },
                text: {
                    [Tab.NEDERLANDS]: this.populateWith.map(v => v.articleTranslations.find(at => at.language === Language.NL)?.text).valueOr(''),
                    [Tab.FRANS]: this.populateWith.map(v => v.articleTranslations.find(at => at.language === Language.FR)?.text).valueOr(''),
                    [Tab.ENGELS]: this.populateWith.map(v => v.articleTranslations.find(at => at.language === Language.EN)?.text).valueOr('')
                },
                excerpt: {
                    [Tab.NEDERLANDS]: this.populateWith.map(v => v.articleTranslations.find(at => at.language === Language.NL)?.excerpt).valueOr(''),
                    [Tab.FRANS]: this.populateWith.map(v => v.articleTranslations.find(at => at.language === Language.FR)?.excerpt).valueOr(''),
                    [Tab.ENGELS]: this.populateWith.map(v => v.articleTranslations.find(at => at.language === Language.EN)?.excerpt).valueOr('')
                },
                title: {
                    [Tab.NEDERLANDS]: this.populateWith.map(v => v.articleTranslations.find(at => at.language === Language.NL)?.title).valueOr(''),
                    [Tab.FRANS]: this.populateWith.map(v => v.articleTranslations.find(at => at.language === Language.FR)?.title).valueOr(''),
                    [Tab.ENGELS]: this.populateWith.map(v => v.articleTranslations.find(at => at.language === Language.EN)?.title).valueOr('')
                },
                related_tags: this.populateWith.map(v => v.relatedTags).valueOr([])
            }
        },
        computed: {
            isEdit(): boolean {
                return this.populateWith.hasValue();
            }
        },
        methods: {
            changeTab(tab: Tab): void {
                this.currentTab = tab;
            },
            onImageUpload(uploadedFiles: File[]) {
                if(uploadedFiles.length === 0)
                    return;

                const file = uploadedFiles[0];
                this.image.path = URL.createObjectURL(file); //sets preview image
                this.image.file = file;
            },
            async onSubmit() {
                const formData = new FormData();
                
                formData.append('is_published', this.isPublished.toString());
                formData.append('is_pinned', this.isPinned.toString());
                formData.append('title_nl', this.title[Tab.NEDERLANDS]);
                formData.append('title_fr', this.title[Tab.FRANS]);
                formData.append('title_en', this.title[Tab.ENGELS]);
                formData.append('excerpt_nl', this.excerpt[Tab.NEDERLANDS]);
                formData.append('excerpt_fr', this.excerpt[Tab.FRANS]);
                formData.append('excerpt_en', this.excerpt[Tab.ENGELS]);
                formData.append('text_nl', this.text[Tab.NEDERLANDS]);
                formData.append('text_fr', this.text[Tab.FRANS]);
                formData.append('text_en', this.text[Tab.ENGELS]);
                formData.append('related_tags', this.related_tags.join(";"));

                if (this.image.file)
                    formData.append('image', this.image.file);

                this.id.matchSome(id_ => {
                    formData.append('id', id_.toString());
                });
                    
                const url:string = `/ajax/cms/blog/${this.isEdit ? 'update' : 'add'}`;
                
                Ajax.postWithBinary(
                    url,
                    formData,
                    async (response: AjaxResponse<BlogArticle>) => {
                        const article = response.data;

                        if(!this.isEdit) {
                            await this.$router.push({ 
                                name: 'blog-edit', 
                                params: { articleId: article.id+"" }
                            });
                        }

                        Toast.success('Opslaan gelukt.')
                    },
                    (err) => {
                        Toast.error(err);
                    }
                );
            },
            sanitizeHtml(html: string): string {
                const regex = /style="[^"]+"/ig;
                return html.replace(regex, "");
            }
        },
    });
</script>

<style lang="scss">
    .big-editor .ql-editor {
        min-height: 600px;
    }
    .ql-snow .ql-picker.ql-expanded .ql-picker-options {
        z-index: 550 !important;
    }
</style>