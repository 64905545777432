import { defineStore } from 'pinia';
import {
    Column,
    CustomField,
    ColumnForStorage,
    Filter,
    RadarProfile,
    DrawerView,
    RadarCompany,
    Subset,
    Dataset,
    RadarCompanyDto,
    CustomValue,
    FilterCriteria,
    FilterType,
    SelectionListFilterCriteria,
    NumericRangeFilterCriteria,
    DateRangeFilterCriteria,
    FreeTextFilterCriteria,
    SavedFilter, GeneralFile, SelectionTreeNode, TranslationWithParent, SelectionTreeFilterCriteria, IndexFile
} from './types';
import getColumns from './columnConfig';
import Option from '../../types/global/option';
import AlertsList from "../../types/dto/AlertsList";
import Ajax from "../../lib/Ajax";
import Toast from "../../lib/Toast";
import _ from "lodash";
import {Language} from "../../types/dto/Language";
import {SelectMenuItem} from "./components/select-menu.vue";
import pLimit from 'p-limit';

enum SavedConfigType {
    HIDDEN_COLUMNS = "radarHiddenColumns",
    HIDDEN_COLUMNSV2 = "radarHiddenColumns_v2",
    FILTERS = "filters_v1"
}

function parseSavedCriteriaAsClasses(json: string): Filter {
    const parsedJson = JSON.parse(json) as Filter;
    const genericFilters = parsedJson.genericFilters as ColumnForStorage[];

    genericFilters.forEach(f => {
        let criteriaAsClass = null as null | FilterCriteria;

        if(f.filterCriteria._type === FilterType.SELECTIONLIST)
            criteriaAsClass = Object.assign(new SelectionListFilterCriteria(), f.filterCriteria);
        else if(f.filterCriteria._type === FilterType.NUMERICRANGE)
            criteriaAsClass = Object.assign(new NumericRangeFilterCriteria(), f.filterCriteria);
        else if(f.filterCriteria._type === FilterType.DATERANGE)
            criteriaAsClass = Object.assign(new DateRangeFilterCriteria(), f.filterCriteria);
        else if(f.filterCriteria._type === FilterType.FREETEXT)
            criteriaAsClass = Object.assign(new FreeTextFilterCriteria(), f.filterCriteria);
        else if(f.filterCriteria._type === FilterType.SELECTIONTREE)
            criteriaAsClass = Object.assign(new SelectionTreeFilterCriteria(), f.filterCriteria);

        f.filterCriteria = criteriaAsClass!;
    });

    return parsedJson;
}

const persistToStorage = (dataset: Dataset, type: SavedConfigType, obj: any) => localStorage.setItem(`${type}_set${dataset.id}`, JSON.stringify(obj));
const getFromStorage = <T>(dataset: Dataset, type: SavedConfigType): Option<T> => Option.someNotNull(localStorage.getItem(`${type}_set${dataset.id}`)).map(json => JSON.parse(json))
const mapNode = function(poco: TranslationWithParent<string>, groups: Map<string, TranslationWithParent<string>[]>, language: Language, valueInName: boolean = false): SelectionTreeNode {
    const children = groups.has(poco.value) 
        ? groups.get(poco.value)!.map(p => mapNode(p, groups, language, valueInName)) 
        : [];

    return {
        value: poco.value,
        name: valueInName 
            ? poco.value + " - " + poco[language] 
            : poco[language],
        children: children
    };
}
//changed store so it has filters that know their keys when object is created
//see: https://stackoverflow.com/questions/42678983/vue-js-computed-property-not-updating
export const useStore = defineStore('radar', {
    state: () => ({
        companies: [] as readonly RadarCompany[],
        sorting: {
            property: "total_score",
            descending: true
        },
        openedFilterBox: null as Column | CustomField  | "company" | null,
        companyNameFilter: "",
        formFilters: [] as string[],
        streetFilters: [] as string[],
        houseNumberFilter: "",
        currentDataset: window.cw.currentDataset as Dataset,
        datasets: window.cw.datasets as Dataset[],
        columns: getColumns() as Column[],
        indexFile: window.cw.indexFile as IndexFile,
        generalFile: null as null | GeneralFile,
        profile: window.cw.profile as RadarProfile,
        allowFullExport: window.cw.allowFullExport as boolean,
        customFields: (window.cw.customFields ?? []) as CustomField[],
        customFilters: (window.cw.customFields ?? [] as CustomField[]).reduce(
            (
                acc: Record<number, string | boolean | null>,
                field: CustomField
            ) => ({...acc, [field.id]: field.type === "string" ? "" : null }), {}) as Record<number, string | boolean | null>,
        addToAlertsVat: null as null | number,
        addToAlertsName: null as null | string,
        alertsLists: window.cw.alertsLists as AlertsList[],
        vatsInAlerts: window.cw.vatsInAlerts as number[],
        drawerView: null as null | DrawerView,
        currentDrawerFilter: '' as string | CustomField,
        language: window.cw.language as Language,
        customValueLookup: window.cw.customValues as CustomValue[][],
        fetchCompanyUrls: window.cw.fetchCompanyUrls as string[],
        vatsInFavourites: window.cw.vatsInFavourites as number[],
        subsetSelectMenuItem: null as SelectMenuItem | null,
        savedFilters: (window.cw.savedFilters as {id: number, version: number, title: string, criteria: string}[]).map(v => ({
            ...v,
            criteria: parseSavedCriteriaAsClasses(v.criteria)
        })) as SavedFilter[],
        urlsFetched: 0 as number,
        naceTree: [] as SelectionTreeNode[]
    }),
    getters: {
        beroepenIndexTree(state): SelectionTreeNode[] {
            if(!this.generalFile)
                return[];
            
            const pocos = this.generalFile.beroepenIndex as TranslationWithParent<string>[];  
            const grouped = pocos.groupBy(p => p.parentValue);
            const rootNodes = grouped.get("")!;
            
            return rootNodes.map(rootNode => mapNode(rootNode, grouped, state.language));
        },
        customCategoriesTree(state): SelectionTreeNode[] {
            if(!this.generalFile)
                return[];

            const pocos = this.generalFile.customCategory as TranslationWithParent<string>[];  
            const grouped = pocos.groupBy(p => p.parentValue);
            const rootNodes = grouped.get("")!;
            
            return rootNodes.map(rootNode => mapNode(rootNode, grouped, state.language));
        },
        totalUrls(): number {
            return this.fetchCompanyUrls.length;  
        },
        loadingPercentage(): number {
            return Math.ceil(this.urlsFetched / this.totalUrls * 100) 
        },
        subsetFilter(): Subset {
            return this.subsetSelectMenuItem?.id! as Subset;
        },
        startedThisMonth(): boolean {
            return Date.fromHansDate(this.indexFile.radarStartDate).isSameMonthOrMoreRecent(new Date);
        },
        currentPeriodStart(): Date {
            return Date.fromHansDate(this.indexFile.currentPeriodStartDate || 20240301);
        },
        onlyShowUpdates(): boolean {
            return this.subsetFilter === Subset.DELETED || this.subsetFilter === Subset.UPDATED || this.subsetFilter === Subset.NEW;
        },
        isDrawerOpen: (state): boolean => state.drawerView !== null,
        chosenColumns: (state): Column[] => state.columns.filter(c => !c.hidden && !c.isFilterOnly),
        hiddenSelectableColumns: (state): Column[] => state.columns.filter(c => c.hidden && !c.isFilterOnly),
        hiddenColumns: (state): Column[] => state.columns.filter(c => c.hidden),
        genericFilters: (state): ColumnForStorage[] => state.columns.map(c => ({
            filterCriteria: c.filterCriteria,
            title: c.title
        })),
        hasCompanyFilters: (state): boolean => {
            return state.companyNameFilter !== '' || state.streetFilters.length > 0 || state.houseNumberFilter !== '' || state.formFilters.length > 0;
        },
        hasAnyFilter(state): boolean {
            return state.columns.some(c => c.filterCriteria.hasValue) || this.hasCompanyFilters || this.customFields.some((field: CustomField) => this.customFilters[field.id] !== null && this.customFilters[field.id] !== "");
        },
        filters(state): Filter {
            return {
                customFilters: state.customFilters as Record<number, string | boolean | null>,
                companyNameFilter: state.companyNameFilter,
                formFilters: state.formFilters,
                streetFilters: state.streetFilters,
                genericFilters: this.genericFilters
            };
        },
        normalizedCompanyNameFilter(): string {
            return (this.companyNameFilter ?? '')
                .replace(/[\s\D]+/g, "")
                .replace(/\./g,"")
                .replace(/^0/, "")
        },
        allFilteredCompanies(): RadarCompany[] {
            return this.filteredCompanies(null);  
        },
        filteredCompanies() {
            return (excludeColumn: Column | "street" | "form"  | null): RadarCompany[] => {
                const customFilters = this.customFilters;
                const streetFilters = this.streetFilters;
                const companyNameFilter = this.companyNameFilter;
                const houseNumberFilter = this.houseNumberFilter;
                const columns = this.columns.filter(col => col.filterCriteria.hasValue);
                const customFields  = this.customFields;
                const formFilters  = this.formFilters;
    
                let filteredBySubset = this.companies;
    
                if(this.subsetFilter === Subset.UPDATED) {
                    filteredBySubset = this.companies
                        .filter(comp => Date.fromHansDate(comp.lastChangeDate).isSameMonthOrMoreRecent(this.currentPeriodStart))
                        .filter(comp => (!!comp.enterListDate && !Date.fromHansDate(comp.enterListDate).isSameMonthOrMoreRecent(this.currentPeriodStart)) || !comp.enterListDate)
                        .filter(comp => (!!comp.leaveListDate && !Date.fromHansDate(comp.leaveListDate).isSameMonthOrMoreRecent(this.currentPeriodStart)) || !comp.leaveListDate);
                } else if(this.subsetFilter === Subset.NEW) {
                    if(this.startedThisMonth)
                        filteredBySubset = [];
                    else
                        filteredBySubset = this.companies.filter(comp => !!comp.enterListDate && Date.fromHansDate(comp.enterListDate).isSameMonthOrMoreRecent(this.currentPeriodStart));
                } else if(this.subsetFilter === Subset.DELETED) {
                    filteredBySubset = this.companies.filter(comp => !!comp.leaveListDate && Date.fromHansDate(comp.leaveListDate).isSameMonthOrMoreRecent(this.currentPeriodStart));
                } else if(this.subsetFilter === Subset.ALL_ACTIVE) {
                    filteredBySubset = this.companies.filter(c => c.isActive)
                }

                return filteredBySubset.filter(company => {
                    return columns.filter(c => !excludeColumn || excludeColumn !== c).every(col => col.matchesCriteria(company, col.filterCriteria));
                }).filter(company => {
                    return companyNameFilter === '' || company.companyName.cw_contains(companyNameFilter) || (this.normalizedCompanyNameFilter !== '' && company.vat.toString().cw_contains(this.normalizedCompanyNameFilter));
                }).filter(company => {
                    return excludeColumn === "street" || streetFilters.length === 0 ||
                        (company.vestigingsAdressen
                            .concat(company.zetelAddress)
                            .some(a => streetFilters.includes(a.straatNaam) && (houseNumberFilter === '' || a.huisNummer === houseNumberFilter)));
                }).filter(company => {
                    return excludeColumn === "form" || formFilters.length === 0 || formFilters.includes(company.juridischeVormTranslated);
                }).filter(company => {
                    return _.every(customFilters, (value, fieldId) => {
                        const fieldType = customFields.find((c: CustomField) => c.id === +fieldId)!.type;
    
                        // if no filter
                        if ((fieldType === "string" && value === '') || (fieldType === "boolean" && value === null))
                            return true;
    
                        // by default the custom values array is empty and should match the empty-case
                        if(value === false && company.customValues.length === 0)
                            return true;
    
                        const matchEmptyStrings: boolean = company.customValues.filter(cv => cv.fieldId === +fieldId).length === 0 && value === null;
    
                        return company.customValues.filter(cv => {
                                if(cv.fieldId !== +fieldId)
                                    return false;
    
                                return fieldType === 'string'
                                    ? (value !== null && cv.stringValue?.cw_contains(value as string ?? "") || (value === null && cv.stringValue === ""))
                                    : cv.boolValue === value;
                            }
    
                        ).length > 0 || matchEmptyStrings;
                    });
                });
            }
        },
        shownCompanies(): RadarCompany[] {
            const sortColumn = Option.someNotNull(this.columns.find(c => c.title == this.sorting.property));
            const customField = this.customFields.find((c: CustomField) => c.name === this.sorting.property);
            const isDescending = this.sorting.descending;

            return this.allFilteredCompanies.sort((a, b) => {
                let valA = sortColumn.map(c => c.sortMapper(a)).valueOr(a.companyName);
                let valB = sortColumn.map(c => c.sortMapper(b)).valueOr(b.companyName);

                if (!!customField) {
                    if (customField.type === 'string') {
                        valA = a.customValues.find(cv => cv.fieldId === customField.id)?.stringValue ?? '';
                        valB = b.customValues.find(cv => cv.fieldId === customField.id)?.stringValue ?? '';
                    } else {
                        valA = a.customValues.find(cv => cv.fieldId === customField.id)?.boolValue ? 1 : 0;
                        valB = b.customValues.find(cv => cv.fieldId === customField.id)?.boolValue ? 1 : 0;
                    }
                }

                if (isDescending) {
                    if (typeof valA === 'string' && typeof valB === 'string') {
                        if (valA > valB) {
                            return -1;
                        }
                        if (valB > valA) {
                            return 1;
                        }
                        return 0;
                    } else if (typeof valA === 'number' && typeof valB === 'number') {
                        return valB - valA;
                    }
                } else {
                    if (typeof valA === 'string' && typeof valB === 'string') {
                        if (valA < valB) {
                            return -1;
                        }
                        if (valB > valA) {
                            return 1;
                        }
                        return 0;
                    } else if (typeof valA === 'number' && typeof valB === 'number') {
                        return valA - valB;
                    }
                }

                return -1;
            })
        }
    },
    actions: {
        createNaceTree() {
            const pocos = this.generalFile!.nace as TranslationWithParent<string>[];
            const grouped = pocos.groupBy(p => p.parentValue);
            const rootNodes = grouped.get("")!;

            this.naceTree = rootNodes.map(rootNode => mapNode(rootNode, grouped, this.language, true));  
        },
        async getGeneralFile(): Promise<void> {
            this.generalFile = await Ajax.getJsonFileAsync<GeneralFile>("/ajax/radar/general-file");
        },
        async getCompaniesAndFields(): Promise<void> {
            let companies: RadarCompany[] = [];
            const limit = pLimit(5);

            const naceMap = new Map(this.generalFile!.nace.map((obj) => [obj.value, obj]));
            const beroepenMap = new Map(this.generalFile!.beroepenIndex.map((obj) => [obj.value, obj]));
            const jurSituMap = new Map(this.generalFile!.juridischeSituatie.map((obj) => [obj.value, obj]));
            const jurVormMap = new Map(this.generalFile!.juridischeVorm.map((obj) => [obj.value, obj]));
            const fetchData = async (url: string) => {
                const chunkedCompanies = await Ajax.getJsonFileAsync<RadarCompanyDto[]>(url);
                const mappedCompanies = chunkedCompanies.map(c => ({
                    ...c,
                    customValues: [],
                    beroepenIndexDescription: (!!c.beroepenIndex && beroepenMap.has(c.beroepenIndex)) ? beroepenMap.get(c.beroepenIndex)![this.language] : "",
                    hoofdNace: (!!c.hoofdNaceCode.value && naceMap.has(c.hoofdNaceCode.value)) ? naceMap.get(c.hoofdNaceCode.value)![this.language] : "",
                    juridischeSituatieTranslated: jurSituMap.get(c.juridischeSituatie)![this.language],
                    juridischeVormTranslated: jurVormMap.get(c.juridischeVorm)![this.language]
                }) as RadarCompany)
                companies = companies.concat(mappedCompanies);
                this.urlsFetched++;
            }
            
            const promises = this.fetchCompanyUrls.map(async url => limit(() => fetchData(url)));
            await Promise.all(promises);

            // enrich custom values from lookup
            this.customValueLookup.forEach(customValues => {
                const company = companies.find(c => c.vat === customValues[0].vat);
                
                if(company)
                    company.customValues = customValues;
            });
            companies.filter(c => this.vatsInAlerts.includes(c.vat)).forEach(company => {
                company.isInAlerts = true;
            });
            companies.filter(c => this.vatsInFavourites.includes(c.vat)).forEach(company => {
                company.isInFavourites = true;
            });

            this.companies = Object.freeze(companies);
        },
        async addToAlertsList(vat: number, companyName: string, listId: number) {
            await Ajax.postAsync(
                '/ajax/followup/add-companies',
                {
                    listId: listId,
                    vats: [vat]
                });
            this.vatsInAlerts.push(vat);
            Toast.success(companyName + " werd toegevoegd aan uw Alerts!");
        },
        updateChosenColumns(shownColumnTitles: string[]) {
            this.columns.forEach(column => {
                column.hidden = !shownColumnTitles.includes(column.title);
            });
            this.saveConfig();
        },
        openDrawer(drawerView: DrawerView) {
            this.drawerView = drawerView;  
        },
        openFilterListDrawer() {
            this.openDrawer(DrawerView.FILTER_LIST);  
        },
        openFilterDetailDrawer(currentDrawerFilter: string | CustomField) {
            this.openDrawer(DrawerView.FILTER_DETAIL);
            this.currentDrawerFilter = currentDrawerFilter;
        },
        openColumnAddDrawer() {
            this.openDrawer(DrawerView.COLUMN_ADD);
        },
        openColumnEditDrawer() {
            this.openDrawer(DrawerView.COLUMN_EDIT);
        },
        closeDrawer() {
            this.drawerView = null;
            this.saveConfig();
        },
        setSorting(property: string, descending: boolean): void {
            this.sorting = {
                property: property,
                descending: descending
            }
        },
        
        toggleFilterBox(column: Column | CustomField | null | "company"): void {
            if(this.openedFilterBox === column)
                this.openedFilterBox = null;
            else
                this.openedFilterBox = column;
            
            this.saveConfig();
        },
        toggleFilterStreet(street: string): void {
            if(this.streetFilters.includes(street))
                this.streetFilters = this.streetFilters.filter(s => s !== street);
            else
                this.streetFilters.push(street);
        },
        toggleFilterForm(form: string): void {
            if(this.formFilters.includes(form))
                this.formFilters = this.formFilters.filter(s => s !== form);
            else
                this.formFilters.push(form);
        },
        removeFiltersWithProp(title: string): void {
            this.columns.find(c => c.title == title)?.filterCriteria.reset();
        },
        removeCustomFilter(field: CustomField): void {
            if(field.type === "boolean")
                this.customFilters[field.id] = null;
            else
                this.customFilters[field.id] = "";
            
            this.saveConfig();
        },
        removeStreetFilters(): void {
            this.streetFilters = [];
            this.houseNumberFilter = "";
            this.saveConfig();
        },
        removeFormFilters(): void {
            this.formFilters = [];
            this.saveConfig();
        },
        removeCompanyNameFilters(): void {
            this.companyNameFilter = "";
            this.saveConfig();
        },
        removeCompanyFilters(): void {
            this.companyNameFilter = "";
            this.houseNumberFilter = "";
            this.streetFilters = [];
            this.formFilters = [];
        },
        removeGenericFilters(): void {
            this.columns.forEach(col => {
                col.filterCriteria.reset();
            });
        },
        removeAllCustomFilters(): void {
            this.customFields.forEach(field => {
                this.removeCustomFilter(field);
            });
        },
        removeAllFilters() {
            this.removeGenericFilters();
            this.removeCompanyFilters();
            this.removeAllCustomFilters();
            this.saveConfig();
        },
        
        saveConfig(): void {
            persistToStorage(this.currentDataset, SavedConfigType.HIDDEN_COLUMNSV2, this.hiddenColumns.map(c => c.title));
            persistToStorage(this.currentDataset, SavedConfigType.FILTERS, this.filters);
        },
        applyFilter(filter: Filter): void {
            this.streetFilters = filter.streetFilters;
            this.companyNameFilter = filter.companyNameFilter;
            this.customFilters = this.removeUnreachableFilters(filter.customFilters); //remove filters whose columns are deleted or have other owners (ingelogd als klant)

            filter.genericFilters.forEach(storedFilter => {
                const column = this.columns.find(c => c.title === storedFilter.title);

                if(column)
                    column.filterCriteria.setValue(storedFilter.filterCriteria._value); //access value, we dont want to overwrite everything since json does not have methods.
                
            });
        },
        useSavedConfig(): void {
            const filters = getFromStorage<Filter>(this.currentDataset, SavedConfigType.FILTERS);
            
            filters.matchSome(filters_ => {
                this.applyFilter(filters_);
            });
            
            getFromStorage<string[]>(this.currentDataset, SavedConfigType.HIDDEN_COLUMNSV2).match(
                hiddenCols => {
                    this.columns.forEach(col => {
                        col.hidden = hiddenCols.includes(col.title);
                    })
                },
                () => {
                    getFromStorage<string[]>(this.currentDataset, SavedConfigType.HIDDEN_COLUMNS).matchSome(
                        hiddenCols => {
                        this.columns.forEach(col => {
                            col.hidden = col.hidden || hiddenCols.includes(col.title);
                        });
                        persistToStorage(this.currentDataset, SavedConfigType.HIDDEN_COLUMNSV2, this.hiddenColumns.map(c => c.title));
                    })
                }
            );
        },
        removeUnreachableFilters(filters: Record<number, string | boolean | null>) {
            const currentCustomFieldIds = this.customFields.map(f => f.id);
            
            return Object.keys(filters)
                .filter((key) => currentCustomFieldIds.includes(+key))
                .reduce((obj, key) => {
                    return Object.assign(obj, {
                        [key]: filters[+key]
                    });
                }, {}) as Record<number, string | boolean | null>;
        }
    }
});
