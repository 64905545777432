﻿﻿import Vue from 'vue';
import Page from '../../lib/Page';
import CrisisWeerbaarheidMatrix from './crisis-weerbaarheid-matrix.vue';

export default (function () {
    Page.registerVue('#crisisWeerbaarheid', function (rootSelector) {
        new Vue({
            el: rootSelector,
            components: {
                CrisisWeerbaarheidMatrix
            },
            data: {
                bufferScores: window.cw.coronaBufferScores,
            },
            mounted() {
                Page.applyBehaviors(rootSelector);
            }
        });
    });
}());