
<div v-cloak>
    <input class="form-control search-input"
           :class="{'is-invalid': hasError, ' form-control-sm': small}"
           type="text"
           autocomplete="off"
           :placeholder="placeholder"
           :disabled="disabled"
           :name="name"
           :id="id"
           :ref="inputRef"
           v-bind:value="searchTerm"
           v-on:input="searchTerm= $event.target.value"
           v-on:keyup="makeSuggestions"
           v-on:keyup.down="selectNext"
           v-on:keyup.up="selectPrev"
           v-on:keypress.enter="acceptSelected"
           v-on:keydown.tab="acceptSelected" />
    <transition name="slide-fade">
        <div id="autocompleteWrapper" class="autocomplete-wrapper" v-if="showSuggestions">
            <suggestion v-for="(suggestion, index) in suggestions"
                        v-bind:name="suggestion"
                        v-bind:search-value="searchTerm"
                        v-bind:selected-index="selectedIndex"
                        v-bind:index="index"
                        :key="index">
            </suggestion>
        </div>
    </transition>
</div>
