﻿<template>
    <div class="row">
        <div class="col-md-12">
            <p>
                {{translate('please_select_vats')}}
            </p>
        </div>
        <template v-if="!error">
            <div class="d-flex col-md-12 may-overflow-x my-3">
                <div v-for="column in columns"
                    :key="column.Index"
                     class="column pointer"
                     :class="{'selected': column.Index === selectedColumnIndex}"
                     @click="selectColumn(column)">
                    <span class="font-weight-bold">{{column.Name}}</span>
                    <div v-for="(row, index) in column.Rows" class="row" :key="'row'+index">
                        <div v-html="row" class="col-md-12"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="info-box warning mb-2" v-if="!hasSelectedIndex">{{translate('select_column_error')}}</div>
            </div>
            <div class="col-md-12">
                <button class="btn btn-blue btn-200" @click.prevent="ajaxSelectColumns()" :disabled="!hasSelectedIndex">{{translate('next')}}</button>
            </div>
        </template>
        <template v-else>
            <div class="col-md-12">
                <div class="card-box small warning">{{error}}</div>
            </div>
            <div class="col-md-12 mt-4">
                <button class="btn btn-default btn-200" @click.prevent="backToUpload()">{{translate('btn_back')}}</button>
            </div>
        </template>
    </div>
</template>

<script>
    import Translate from 'Mixins/translate';
    import Vue from 'vue';

    export default Vue.extend({
        name: 'choose-column',
        mixins: [Translate],
        props: {
            columns: Array,
            error: String,
            currentList: Object
        },
        data() {
            return {
                selectedColumnIndex: this.columns && this.columns.find(col => col.HasVats) ? this.columns.find(col => col.HasVats).Index : -1
            }
        },
        computed: {
            hasSelectedIndex() {
                return this.selectedColumnIndex !== -1;
            }
        },
        methods: {
            selectColumn(column) {
                if (column.HasVats)
                    this.selectedColumnIndex = column.Index
            },
            ajaxSelectColumns() {
                this.$root.$emit('change-view', {
                    viewName: 'choose-selection-method',
                    viewData: {
                        selectedColumnIndex: this.selectedColumnIndex
                    },
                    currentList: this.currentList
                });
            },
            backToUpload() {
                this.$root.$emit('change-view', {
                    viewName: 'upload-form',
                    viewData: {},
                    currentList: this.currentList
                });
            }
        }
    });
</script>

<style scoped>
    .column {
        border: 2px solid transparent;
        padding: 15px;
        border-radius: 3px;
        flex: 1 0 220px;
        margin-bottom: 15px;
    }
    .column > .row:nth-child(even) {
        background: #f8f8f8;
    }
    .column:hover {
        border: 2px solid gray;
    }
    .column.selected {
        border: 2px solid var(--primary);
    }
</style>