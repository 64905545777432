﻿<template>
    <tr role="row" class="pointer">
        <td :class="{'faded': hasBeenRemoved}">
            <div class="flex-v-center">
                {{Lijn2}}
            </div>
        </td>
        <td :class="{'faded': hasBeenRemoved}">
            <div class="flex-v-center">
                <a :href="SearchAddressLink" class="tr-anchor">
                    {{Lijn1}}
                </a>
                <a :href="SearchAddressLink" target="_blank" class="btn btn-transparent btn-sm d-inline ml-1" :title="translate('open_in_new_tab')">
                    <i class="fas fa-external-link-alt"></i>
                </a>
            </div>
        </td>
        <template v-if="!cantRemove">
            <td class="remove-address-button excluded-from-click text-center"
                :title="translate('remove_address')"
                v-on:click="removeAddress()"
                width="50"
                v-if="!hasBeenRemoved">
                <i class="fas fa-trash-alt excluded-from-click"></i>
            </td>
            <td class="excluded-from-click text-center"
                :title="translate('restore_address')"
                v-on:click="addAddress()"
                width="50"
                v-else>
                <i class="fas fa-undo excluded-from-click"></i>
            </td>
        </template>
    </tr>
</template>

<script>
    import Translate from 'Mixins/translate';
    import Truncate from 'Mixins/truncate';
    import Ajax from 'Lib/Ajax';
    import Toast from 'Lib/Toast';

    export default {
        name: 'address-row',
        props: {
            Id: Number,
            StraatNaam: String,
            HuisNummer: String,
            Postcode: String,
            Gemeente: String,
            Lijn1: String,
            Lijn2: String,
            SearchAddressLink: String,
            isRemoved: Boolean,
            cantRemove: Boolean
        },
        mixins: [Truncate, Translate],
        data() {
            return {
                hasBeenRemoved: this.isRemoved
            }
        },
        methods: {
            removeAddress() {
                Ajax.post(
                    '/ajax/followup/remove-address',
                    {
                        listId: this.$parent.currentList.ListId,
                        AddressId: this.Id
                    },
                    () => {
                        Toast.success(this.translate('address_removed'));
                        this.$root.$emit('change-used-addresses');
                        this.hasBeenRemoved = true;
                        this.$root.$emit('removeAddress', { id: this.Id, isReAdded: false } );
                    },
                    (err) => {
                        console.error(err);
                    }
                )
            },
            addAddress() {
                Ajax.post(
                    '/ajax/followup/add-address',
                    {
                        listId: this.$parent.currentList.ListId,
                        AddressId: this.Id,
                        zipCode: this.Postcode,
                        city: this.Gemeente,
                        street: this.StraatNaam,
                        houseNumber: this.HuisNummer
                    },
                    () => {
                        this.$root.$emit('change-used-addresses');
                        Toast.success(this.translate('address_restored'));
                        this.hasBeenRemoved = false;
                        this.$root.$emit('removeAddress', { id: this.Id, isReAdded: true } );
                    },
                    (err) => {
                        console.error(err);
                    }
                );
            }
        }
    }
</script>

<style>
    td.faded {
        opacity: 0.2;
    }

    .remove-address-button:hover {
        background: #F44336;
        color: white !important;
    }
</style>