
<div>
    <b v-if="title !== ''" class="label-like">{{title}}</b>
    <input :multiple="multiple" :required="required" :id="name" :name="name" type="file" :ref="name" @change="previewFile($event)" class="cw-file-input" :class="{'valid': error == '' && hasUploaded, dragged}" :accept="accept"/>
    <label @dragover="dragover" @dragleave="dragleave" @drop="drop" :for="name">
        <span class="info text-grey font-small font-italic" v-if="!small">
            <template v-if="hasUploaded" class="">
                {{filesizeLabel}}
            </template>
            <template v-else>
                {{instructionsLabel}}
            </template>
        </span>
        <span class="inner-label">{{label|truncate(25)}}</span>
    </label>

    <div v-if="error !== ''" class="card-box warning mt-2 small">{{error}}</div>
</div>
