<template>
    <div>
        <h2>{{translate('sidebar_language')}}</h2>

        <div class="mb-2">
            <input type="radio" id="all" @change="select(null)" :checked="selectedValue == null"> 
            <label for="all">{{translate('language_all')}}</label>
        </div>

        <div class="mb-2">
            <input type="radio" id="nl-only" @change="select(2)" :checked="selectedValue === 2"> 
            <label for="nl-only">{{translate('language_nl')}}</label>
        </div>

        <div class="mb-2">
            <input type="radio" id="fr-only" @change="select(1)" :checked="selectedValue === 1"> 
            <label for="fr-only">{{translate('language_fr')}}</label>
        </div>
        
        <div class="mb-2">
            <input type="radio" id="du-only" @change="select(3)" :checked="selectedValue === 3">
            <label for="du-only">{{translate('language_du')}}</label>
        </div>
    </div>
</template>

<script lang="ts">
    import _ from 'lodash';
    import DataMarketingService, { Request } from '../../dataMarketingService';
    import { ProspectieDataSelectionCriteriumType } from '../../../../types/dto/ProspectieDataSelectionCriteriumType';
    import Translate from '../../../mixins/translate';
    import mixins from 'vue-typed-mixins';    

    export default mixins(Translate).extend({
        name: 'dm-language',
        computed:{
            currentRequest(): Request{
                return DataMarketingService.getInstance().currentRequest;
            },
            selectedValue(): number | null {
                const selectedCriteria = DataMarketingService.getInstance().currentRequest!.selectedCriteria!.filter(c => c.type === ProspectieDataSelectionCriteriumType.TAALVEREISTE);

                return selectedCriteria.firstElement().map(v => v.id).valueOrNull();
            }
        },
        methods:  {
            async select(value: number | null) {
                await this.currentRequest.removeCriteriumByType(ProspectieDataSelectionCriteriumType.TAALVEREISTE);

                if(value)
                    await this.currentRequest!.select(value, ProspectieDataSelectionCriteriumType.TAALVEREISTE, true);
                
                await this.currentRequest.updateSummary();
            }
        }
    })
</script>

<style scoped>

</style>