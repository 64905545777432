﻿import { ProspectieDataSelectionCriteriumType } from './ProspectieDataSelectionCriteriumType';

export interface ProspectieDataRange { 
    operator: ProspectieDataRangeOperator;
    type: ProspectieDataSelectionCriteriumType;
    lowerBound: number;
    upperBound: number;
    translationKey: string;
}

export enum ProspectieDataRangeOperator { 
    LESSTHAN = "less-or-equal-than",
    EQUALTO = "equal-to",
    GREATEROREQUALTHAN = "greater-or-equal-than",
    BETWEEN = "between",
}
