﻿
interface MandateFunction {
    title: string,
    id: number,
    isBedrijfsLeider: boolean
}

interface Mandate {
    mandateHolderName: string;
    companyEndDate: number | null;
    functie: MandateFunction;
    begin: number;
    einde: number;
    subMandaten: Mandate[];
    status: MandateStatus;
    isActueel: boolean;
    isHuidig: boolean;
    companyBarometer: string;
    companyBarometerScore: string;
    detailsUrl: string | null;
}

enum MandateStatus {
    MandateIsActive = 'mandate-is-active',
    MandateEndedShortlyBeforeCompanyFailure = 'mandate-ended-shortly-before-company-failure',
    MandateEndedLongBeforeCompanyFailure = 'mandate-ended-long-before-company-failure',
    CompanyEnded = 'company-ended',
    MandateEnded = 'mandate-ended'
}

export {
    Mandate,
    MandateStatus
}