
<div>
    <h2>{{translate("start_with")}}</h2>
    
    <div class="space-y-3">
        <div>
            <input type="radio" id="1" :value="StartingSelection.NEW_SELECTION" v-model="startingSelection" @change="useNoPreset()"> 
            <label for="1">{{translate(StartingSelection.NEW_SELECTION)}}</label>
        </div>

        <div>
            <input type="radio" id="2" :value="StartingSelection.FORMER_SELECTION" v-model="startingSelection"> 
            <label for="2">{{translate(StartingSelection.FORMER_SELECTION)}}</label>
        </div>

        <div class="mb-4">
            <input type="radio" id="3" :value="StartingSelection.PREMADE_SELECTION" v-model="startingSelection"> 
            <label for="3">{{translate(StartingSelection.PREMADE_SELECTION)}}</label>
        </div>
    </div>
    <div v-if="startingSelection === StartingSelection.PREMADE_SELECTION">
        <div class="tiles">
            <a href="" v-for="preset in presets" :key="preset.name" class="mb-2 tile blue" @click.prevent="usePreset(preset)">
                <i class="fad" :class="preset.icon"></i>
                <h3>{{translate(preset.translationKey)}}</h3>
            </a>
        </div>
    </div>
    <div v-else-if="startingSelection === StartingSelection.FORMER_SELECTION">
         <div class="tiles" v-if="formerRequests">
            <a href="" v-for="request in formerRequests" :key="request.id" class="mb-2 tile blue" @click.prevent="useFormerRequest(request)">
                <h3>{{request.reference}}</h3>
                <span class="text-grey font-small">{{Date.fromHansDate(request.requestDate).format()}}</span>
            </a>
        </div>
        <p v-else>
            {{translate("no_former_selections")}}
        </p>
    </div>
</div>
