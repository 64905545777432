﻿<template>
    <div class="cw-dropdown"  v-click-outside="close">
        <div @click.stop.prevent="toggle()" class="cw-dropdown__label"><slot name="toggler"></slot></div>
        <transition name="dropdown">
            <ul class="cw-dropdown__list" :class="{'active': isOpen}"
                 @click="close()">
                <slot name="content">Please provide a slot with name 'content'</slot>
            </ul>
        </transition>
    </div>
</template>

<script>
    import ClickOutside from 'vue-click-outside';


    export default {
        directives: { ClickOutside },
        data() {
            return {
                isOpen: false,
                guid: Math.floor(Math.random() * 15000)
            };
        },
        methods: {
            toggle() {
                this.isOpen = !this.isOpen;

                this.$root.$emit('open-dropdown', this.guid);
            },
            close() {
                this.isOpen = false;
            }
        },
        mounted() {
            this.$root.$on('open-dropdown', guid => {
                if (guid !== this.guid)
                    this.close();
            });
        }
    }
</script>

<style>
    
</style>