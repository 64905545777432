﻿<template>
    <div class="switch">
        <input type="checkbox" :class="{'azure': azure}" v-model="checked" :id="name" :name="name" @change="change()"/>
        <label class="slider" :for="name"></label>
    </div>
</template>

<script>
    export default {
        name: 'slide-toggle',
        props: {
            value: Boolean,
            name: String,
            azure: Boolean
        },
        data() {
            return {
                checked: this.value
            };
        },
        methods: {
            change() {
                this.$emit('input', this.checked);
            }
        }
    }
</script>

<style>

</style>
