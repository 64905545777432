﻿<template>
    <div class="event"
         :title="type"
         v-bind:class="[eventHappenedBeforeTimelineStart ? 'd-none' : '', type]"
         :style="{left: left + '%'}">
    </div>
</template>

<script>
    import _ from 'lodash';
    export default {
        name: 'event',
        props: {
            date: Number,
            type: String,
            info: String,
            timelineStart: Number,
            timelineEnd: Number
        },
        inject: ['hansDateToReadableDate', 'hansDateToTimestamp'],
        computed: {
            eventHappenedBeforeTimelineStart: function () {
                return this.date < parseInt(this.timelineStart);
            },
            left: function () {
                return (this.hansDateToTimestamp(this.date) - this.hansDateToTimestamp(this.timelineStart)) /
                    (this.hansDateToTimestamp(this.timelineEnd) - this.hansDateToTimestamp(this.timelineStart)) * 100;
            },
            infoContent: function () {
                return '<strong>' + this.info + '</strong>: ' + this.hansDateToReadableDate(this.date);
            }
        },
        methods: {
            detectTracker: function (e) {
                var trackerXPosition = e.pageX - this.$parent.$parent.offsetLeft;
                var leftBoundary = this.$el.offsetLeft - 12;
                var rightBoundary = leftBoundary + this.$el.clientWidth;

                if (this.info !== undefined && this.info !== '' && trackerXPosition >= leftBoundary && trackerXPosition <= rightBoundary) {
                    this.$parent.activeElement.content = this.infoContent;
                } else if (this.$parent.activeElement.content === this.infoContent) {
                    this.$parent.activeElement.content = '';
                }
            }
        },
        mounted: function () {
            window.addEventListener('mousemove', _.throttle(this.detectTracker, 100));
        },
        beforeDestroy() {
            window.removeEventListener('mousemove');
        }
    };
</script>