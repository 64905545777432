﻿<template>
    <div class="pagination-wrapper">
        <div>
            <a class="pagination-link" :disabled="isFirstPage" href="" @click.prevent="setPage(currentPage-1)"><i class="fas fa-chevron-left"></i> </a>
        </div>
   
        <div class="text-grey">
            Pagina <input class="form-control form-control-sm" min="1" type="number" :value="currentPage" ref="input" @click="selectAll" @input="setPage(+$event.target.value)"> van {{numberOfPages}}
        </div>
        
        <div>
            <a :disabled="isLastPage" class="pagination-link" href="" @click.prevent="setPage(currentPage+1)"><i class="fas fa-chevron-right"></i> </a>
        </div>
    </div>
</template>

<script lang="ts">

    import Vue from 'vue';
    import _ from 'lodash';

    export default Vue.extend({
        name: 'pagination',
        props: {
            value: {
                type: Number,
                required: true
            },
            itemsPerPage: Number,
            amountOfItems: Number
        },
        data() {
            return {
                currentPage: this.value
            };
        },
        watch: {
            value(val: number) {
                this.currentPage = val;
            }  
        },
        computed: {
            numberOfPages(): number {
                return Math.ceil(this.amountOfItems / this.itemsPerPage);
            },
            isFirstPage(): boolean {
                return this.currentPage === 1;
            },
            isLastPage(): boolean {
                return this.currentPage === this.numberOfPages;
            }
        },
        methods: {
            setPage(page: number) {
                if(page < 1)
                    this.currentPage = 1;
                else if(page > this.numberOfPages)
                    this.currentPage = this.numberOfPages;
                else
                  this.currentPage = page;
                
                this.$emit('input', this.currentPage);
            },
            selectAll() {
                (this.$refs.input as HTMLInputElement).select();
            }
        }
    });
</script>

<style lang="scss" scoped>
  $primColor: #0368FF;

  .pagination-wrapper {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    
    input {
      border: 1px solid #cdcdcd;
      display: inline-block;
      width: auto;
      margin: 0 8px;
      max-width: 50px;
    }
    
    .pagination-link {
        padding: 5px 10px;
        display: inline-block;
        color: #0a2439;
        border-radius: 6px;
      
        &[disabled] {
          color: lightgrey;
        }

        &:hover{
          text-decoration: none;
          background-color: #f6f6f6;
          color: $primColor;
        }
      
    }
  }
</style>