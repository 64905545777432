import _ from 'lodash';
import Enumeration from '../../types/global/enumeration';
import Option from '../../types/global/option';

export class Step extends Enumeration<Step>() {
    public isLocked: boolean = false;

    private constructor(
        public label: string,
        public children?: Step[],
        public icon?: string
    ) 
    {
        super(label);
    }

    public get isClickable(): boolean {
        return !this.children || this.children.length === 0;
    }

    public static Language = new Step('language', [], 'fa-globe');
    public static Location = new Step('location', [], 'fa-map-marker-alt');
    public static Activity = new Step('activity', [], 'fa-industry');
    public static CompanySize = new Step('company-size', [], 'fa-users');
    public static Establishment = new Step('establishment', [], 'fa-seedling');
    public static LegalForm = new Step('legal-form', [], 'fa-building');
    public static Barometer = new Step('barometer', [], 'fa-tachometer');
    public static Financial = new Step('financial', [], 'fa-euro-sign');

    public static Start = new Step('start');
    public static Refine = new Step('refine', [
        Step.Language,
        Step.Location,
        Step.Activity,
        Step.CompanySize,
        Step.Establishment,
        Step.LegalForm,
        Step.Barometer,
        Step.Financial
    ]);
    public static Exclude = new Step('exclude');
    public static Results = new Step('results');
    public static UploadVats = new Step('upload-vats');
}

export default class Progress {
    public steps: Step[];
    public activeStep: Step;

    private constructor(steps: Step[]) {
        this.steps = steps;
        this.activeStep = this.steps[0];
    }

    public get allSteps(): Step[] {
        return _.flatMap(this.steps, step => {
            return _.flatten([
                step,
                step.children ? step.children : []
            ])
        });
    }

    public get lastStep(): Step {
        return this.allSteps[this.allSteps.length - 1];
    }

    public get isNextStepDisabled(): boolean {
        return this.nextStep
            .map(s => s.isLocked)
            .valueOr(false)
    }
    
    public get nextStep(): Option<Step> {
        const currentIndex = this.allSteps.findIndex(s => s === this.activeStep);
        
        return this.allSteps.firstOrNone((step, index) => step.isClickable && index > currentIndex);
    }

    public setActiveStep(activatedStep: Step): void {
        this.activeStep = activatedStep;
    }

    public activateNextStep(): Step | undefined {
        const next = this.nextStep.valueOrUndefined();
        this.nextStep.matchSome(step => this.setActiveStep(step));
        
        return next;
    }
    
    public activateLastStep(): void {
        this.setActiveStep(this.lastStep);
    }

    public lockPreviousSteps(): void {
        this.allSteps.forEach(step => {
            step.isLocked = true;
        });
        this.activateLastStep();
    }

    public lockOtherSteps(): void {
        this.allSteps.filter(step => step !== this.activeStep).forEach(step => {
            step.isLocked = true;
        });
    }

    public unlockOtherSteps(): void {
        this.allSteps.forEach(step => {
            step.isLocked = false;
        });
    }
    
    public findStepInUrl(url: string): void {
        const matches = /\/datamarketing\/(?:prospecting|enrichment)\/\d+(\/.+)?/.exec(url);
        
        if(!matches)
            return;
        
        const subPage = (matches[1] ?? '').trim_left("/");
        
        if(subPage != "") {
            this.allSteps
                .firstOrNone(step => step.label === subPage)
                .matchSome(step => this.setActiveStep(step));
        }
    }

    public static readonly ProspectingProgress: Progress = new Progress(
        [
            Step.Start,
            Step.Refine,
            Step.Exclude,
            Step.Results
        ]
    );

    public static readonly EnrichmentProgress: Progress = new Progress(
        [
            Step.UploadVats,
            Step.Results
        ]
    );
}