﻿<template>
    <div class="credits">
        <div class="number">{{amount.formatThousandSeparator()}}</div>
        <div class="label">
            {{label}}
            <span v-if="!!labelInfo" :data-content="labelInfo">
                <i class="text-white fas fa-info-circle ml-1"></i>
            </span>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default  Vue.extend({
        name: 'credits-counter',
        props: {
            label: String as () => string,
            labelInfo: String as () => string,
            amount: Number as () => number
        }
    });
</script>

<style scoped lang="scss">
    .credits {
        padding: 12px;
        text-align: center;
        color: #20c997;
        background: #20c9971f;
        border-radius: 4px;
        position: relative;
        font-size: 12px;
        border: 1px dashed
    }
    .credits .number {
        font-size: 26px;
        font-weight: 800;
    }
    .credits .label {
        letter-spacing: 0.15em;
        text-transform: uppercase;
    }
</style>