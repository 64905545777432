<template>
    <div class="btw-file-input-wrapper">
        <div class="text-center m-4" v-if="isLoading">
            <i class="fas fa-spinner spinning text-lightblue fa-lg"></i>
        </div>

        <file-input 
            v-show="!isLoading"
            :multiple="false" 
            :required="required" 
            @input="onFileUpload"
            :modelClosed="modelClosed"
            accept=".xlsx,.csv"  
            :title="translate('upload_vats')" 
            placeholder="xlsx"></file-input>


        <cw-modal :noOverflow="false" :title="title" :id="nameOfModal" size="xl" @onclose="triggerModalClosed()">
            <transition name="slide-fade">
                <div class="row" v-if="show">
                    <div class="col-md-12 may-overflow-x">
                        <div class="flexbox-table">
                            <div 
                                class="flexbox-column" 
                                v-for="column in columnPreviews" 
                                :key="column.index" 
                                :class="{'active': column.index === activeColumnIndex}" 
                                @click="activeColumnIndex = column.index">

                                <div class="flexbox-cell">
                                    <span>{{column.name|truncate(22)}}</span>
                                </div>
                                <template v-if="column.rows.length > 0">
                                    <div class="flexbox-cell" v-for="(row, index) in column.rows" :key="'row-' + column.index + '-' + index">
                                        <span>{{row|truncate(22)}}</span>
                                    </div>
                                </template>
                                <div class="flexbox-cell" v-else>
                                    {{translate('no_data')}}
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </transition>

            <button class="mt-4 btn btn-info btn-200" @click.prevent="next">{{translate('verdergaan')}}</button>
        </cw-modal>
    </div>
</template>

<script lang="ts">
    import CwModal from './modal.vue';
    import FileInput from './file-input.vue';
    import Ajax from '../../lib/Ajax';
    import { ExcelColumnPreview } from '../../types/dto/ExcelColumnPreview';
    import { VatInputResponse } from '../../types/dto/VatInputResponse';
    import Truncate from '../mixins/truncate';
    import Translate from '../mixins/translate';
    import mixins from 'vue-typed-mixins';
    import Toast from '../../lib/Toast';

    enum Columns {
        Vat,
        Reference
    }
    
    export default mixins(Truncate, Translate).extend({
        name: 'btw-file-input',
        components: {
            CwModal,
            FileInput
        },
        props: {
            required: Boolean,
            askForReferences: Boolean,
            maxNumberOfVats: Number
        },
        data() {
            return {
                modelClosed: false,
                columnPreviews: [] as ExcelColumnPreview[],
                nameOfModal: "vatsModal",
                activeColumnIndex: 1,
                currentColumn: Columns.Vat,
                Columns,
                vatColumnIndex: 0,
                referenceColumnIndex: 0,
                fileIdentifier: '',
                show: false,
                isLoading: false,
                hardLimit: 100000
            }
        },
        computed: {
            title(): string {
                if(this.currentColumn === Columns.Vat)
                    return "1. " + this.translate('vat_column_title');
                else
                    return "2. "+ this.translate('reference_column_title');
            }
        },

        methods: {
            reset() {
                this.currentColumn = Columns.Vat;
                this.vatColumnIndex = 0;
                this.referenceColumnIndex = 0;
                this.fileIdentifier = '';
            },
            triggerModalClosed() {
                this.modelClosed = true;
                this.reset();
                setTimeout(() => {
                    this.modelClosed = false;
                }, 500);
            },
            async next() {
                if(!this.askForReferences) {
                    this.vatColumnIndex = this.activeColumnIndex;
                    this.referenceColumnIndex = this.activeColumnIndex;
                    await this.emitData();
                } else {
                    if(this.currentColumn === Columns.Vat){
                        this.show = false;
                        this.vatColumnIndex = this.activeColumnIndex;
                        this.currentColumn = Columns.Reference;
                        setTimeout(() => {
                            this.show = true;
                        }, 260);
                    } else {
                        this.referenceColumnIndex = this.activeColumnIndex;
                        await this.emitData();
                        this.currentColumn = Columns.Reference;
                    }
                }

                this.activeColumnIndex = 1;
            },
            async onFileUpload(uploadedFiles: File[]): Promise<void> {
                  if(uploadedFiles.length === 0)
                    return;

                const file: File = uploadedFiles[0];
                let formData = new FormData();
                formData.append("file", file);
                formData.append("hardLimit", (this.maxNumberOfVats ?? this.hardLimit).toString());
                this.isLoading = true;

                try {
                    const response = await Ajax.postWithBinaryAsync<VatInputResponse>(
                        "/ajax/vat-input/column-previews",
                        formData
                    );

                    this.columnPreviews = response.columnPreviews;
                    this.fileIdentifier = response.fileIdentifier;

                    this.$root.$emit('show-modal', this.nameOfModal);
                } catch (e: unknown) {
                    Toast.error(e as string);
                } finally {
                    this.isLoading = false;
                }
            },
            async emitData(): Promise<void> {
                const columnIndex = this.activeColumnIndex;
                if(columnIndex < 0 || columnIndex > this.columnPreviews.length)
                    return;
                
               
                this.$emit("input", {
                    vatColumnIndex: this.vatColumnIndex,
                    referenceColumnIndex: this.referenceColumnIndex,
                    fileIdentifier: this.fileIdentifier
                } as VatInputEmit);

                this.$root.$emit("hide-modal", this.nameOfModal); 
            }
        },
        mounted() {
            this.show = true;
        }
    });
</script>

<style scoped lang="scss">
    @import '../../../sass/_variables.scss';

    .flexbox-table {
        display: flex;
        align-items: stretch;

        .flexbox-column {
            flex-grow: 1;
            min-width: 200px;
            border: 1px solid transparent;
            margin-left: -1px;

            &:hover {
                cursor: pointer;
                .flexbox-cell {
                    @include ease(0.2s);
                    background-color: #e9ecef;
                }
            }

            &.active {
                border-color: $lightblue !important;
                .flexbox-cell:first-of-type {
                    background-color: $lightblue !important;
                    color: white;
                    cursor: pointer;
                }
            }

            .flexbox-cell {
                border: 1px solid #eee;
                padding: 0.5rem;
                word-break: break-word;
                white-space: nowrap;
                min-height: 40px;
                @include ease(0.2s);

                &:first-child {
                    background-color: #e9ecef;
                    font-weight: bold;
                }
            }
        }
    }
</style>