
<div>
    <h2>{{translate('history')}}</h2>
        <div  class="may-overflow-x">
        <table class="table data-table table-striped">
            <thead>
                <tr>
                    <th style="min-width:130px" class="position-relative">
                        <select v-model="filter.countryCode" class="form-control">
                            <option :value="''">{{translate('filter_on_country')}}</option>
                            <option v-for="country in historyCountries" v-if="country" :value="country.code">{{country.name}}</option>
                        </select>
                    </th>
                    <th style="min-width:180px">
                        <input type="search" v-model="filter.companyName" class="form-control" :placeholder="translate('filter_on_companyname')"/>
                    </th>
                    <th></th>
                    <th style="min-width:150px"></th>
                </tr>
                <tr>
                    <th>{{translate('country')}}</th>
                    <th>{{translate('company_name')}}</th>
                    <th>{{translate('registration_number')}}</th>
                    <th>{{translate('last_report')}}</th>
                </tr>
            </thead>
            <tbody>
                <tr 
                    is="company-row" 
                    role="row" 
                    v-for="company in filteredCompanies" 
                    :hasFollowup="hasFollowup"
                    :company="company" 
                    :key="company.companyId"></tr>
            </tbody>
        </table>
    </div>  
</div>
