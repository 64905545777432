
<tr role="row" class="pointer">
    <td :class="{'faded': hasBeenRemoved}">
        <div class="flex-v-center">
            <a :href="Link" class="tr-anchor">
                {{Vat}}
            </a>
            <a :href="Link" target="_blank" class="btn btn-transparent btn-sm d-inline ml-1" :title="translate('open_in_new_tab')">
                <i class="fas fa-external-link-alt"></i>
            </a>
        </div>
    </td>
    <td :class="{'faded': hasBeenRemoved}" width="30%">
        {{Naam|truncate(40)}} ({{Vorm}})
    </td>
    <td :class="{'faded': hasBeenRemoved}" class="d-none d-lg-table-cell">
        {{Adres.Lijn1}}, {{Adres.Lijn2}}
    </td>
    <td>
        <div class="status col-md-12 d-none d-lg-table-cell" :class="BarometerStatus">
            {{translate(BarometerStatus)}}
        </div>
    </td>
    <template v-if="!cantRemove">
        <td class="remove-company-button excluded-from-click text-center"
            :title="translate('remove_company')"
            v-on:click="removeCompany()"
            width="50"
            v-if="!hasBeenRemoved">
            <i class="fas fa-trash-alt excluded-from-click"></i>
        </td>
        <td class="excluded-from-click text-center"
            :title="translate('restore_company')"
            v-on:click="addCompany()"
            width="50"
            v-else>
            <i class="fas fa-undo excluded-from-click"></i>
        </td>
    </template>
</tr>
