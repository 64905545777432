﻿import Vue from 'vue';
import Page from '../../lib/Page';
import PricingTable from './components/pricing-table.vue';
import VModal from "../shared/modal.vue";
import ZImage from "../shared/zoomable-image.vue";

import { Package } from './types';

export default (function () {
    Page.registerVue('#pricing-table', function (rootSelector) {

        new Vue({
            el: rootSelector,
            components:{
                PricingTable,
                VModal,
                "zoomable-image": ZImage
            },
            data() {
                return {
                    selectedPackType: Package.Premium as Package,
                    packages: Package.enumerators
                }
            },
            methods: {
                selectPack(pack: Package): void {
                    this.selectedPackType = pack;
                },
                openOtherEmailModal(): void {
                    this.$root.$emit('show-modal', 'other-email-modal');
                }
            }
        });
    });
}());
