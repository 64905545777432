﻿import {Adres} from '../../../types/dto/Adres';

type InternationalAlertsCountry = "fr" | "nl";
type ChangeDirection = "increase" | "decrease" | "none";
type EventColor = "orange" | "red" | "blue" | "green" | "yellow";

enum AlertsEventType {
    STATUS = "status",
    BAROMETER = "barometer",
    KREDIETLIMIET = "kredietlimiet",
    NAAM = "naam",
    JURIDISCHEVORM = "juridische-vorm",
    INTERNATIONALEJURIDISCHEVORM = "internationale-juridische-vorm",
    ADRES = "adres",
    DOORHALING = "doorhaling",
    RSZDAGVAARDING = "rsz-dagvaarding",
    GERECHTELIJKAKKOORD = "gerechtelijk-akkoord",
    CURRATORAANGESTELD = "currator-aangesteld",
    FAILLISSEMENT = "faillissement",
    PUBLICATIE = "publicatie",
    JAARREKENING = "jaarrekening"
}

interface AlertsCompany {
    address: Adres,
    companyName: string,
    country: InternationalAlertsCountry,
    registrationNumber: string,
    id: number,
    url: string
} 

interface AlertsEvent {
    changeDirection: ChangeDirection,
    color: EventColor,
    date: number, 
    htmlDescription: string,
    severity: number,
    type: AlertsEventType
}

interface ExtendedAlertsEvent extends AlertsEvent {
    id: number,
    company: AlertsCompany
}

export {
    AlertsCompany,
    AlertsEvent,
    AlertsEventType,
    ExtendedAlertsEvent,
    EventColor
}