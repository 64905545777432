<template>
    <div class="range-slider mr-3 mr-md-0" :class="{'disabled': disabled}">
        <div class="slider-line">
            <div class="inner" :class="{'barometer-colors': barometerColors}"></div>
        </div>
        <knob :formatLabel="formatLabel" :isLinear="isLinear" :sliderWidth="sliderWidth" @value="updateMinValue"  :min="min" :max="max" :otherX="xRight" :initX="xLeft" :lowerBound="lowerBound" :upperBound="upperBound" is-left ></knob>
        <knob :formatLabel="formatLabel" :isLinear="isLinear" :sliderWidth="sliderWidth" @value="updateMaxValue" :min="min" :max="max" :otherX="xLeft" :initX="xRight" :lowerBound="lowerBound" :upperBound="upperBound"></knob>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import Knob from './knob.vue';

    export default Vue.extend({
        name: 'range-slider',
        components: {Knob},
        props: {
            lowerBound: {
                type: Number,
                default: 0
            },
            upperBound: {
                type: Number,
                default: 100
            },
            min: {
                type: Number,
                default: 0
            },
            max: {
                type: Number,
                default: 100
            },
            isLinear:{
                type: Boolean,
                default: true
            },
            formatLabel: {
                type: Function,
                default: function (count: number) {
                    return count.toString()
                },
            },
            barometerColors: {
                type: Boolean,
                default: false
            },
            disabled: Boolean
        },
        data() {
            return {
                sliderWidth: 100,
            }
        },
        computed: {
            isLogarithmic(): boolean {
                return !this.isLinear;
            },
            xLeft(): number {
                return this.valueToPosition(this.min);
            },
            xRight(): number {
                return this.valueToPosition(this.max);
            }
        },
        methods: {
            updateMinValue(v: number) {
                this.$emit('update:min', v)
            },
            updateMaxValue(v: number) {
                this.$emit('update:max', v)
            },
            valueToPosition(input: number): number {
                const width = this.sliderWidth - 14;
                if(input === 0 && this.isLogarithmic)
                    return 0

                if(this.isLogarithmic) {
                    let lowerBound = Math.log(this.lowerBound || 0);

                    if (lowerBound === -Infinity)
                        lowerBound = 0;
                        
                    return (Math.log(input) - lowerBound) / (Math.log(this.upperBound) - lowerBound) * width;
                } else {
                    return (input - this.lowerBound) / (this.upperBound - this.lowerBound) * width;
                }
            }
        },
        mounted() {
            this.sliderWidth = (this.$el as HTMLElement).offsetWidth;
        }
    })
</script>

<style scoped lang="scss">
    .range-slider {
        display: block;
        height: 14px;
        position: relative;
        min-width: 250px;
        &.disabled {
            opacity: 0.4;
        }

        .slider-line {
            position: absolute;
            display: flex;
            height: 100%;
            width: calc(100% - 16px);
            left: 8px;
            align-items: center;
            justify-content: center;

            .inner {
                height: 4px;
                background: #1ab9d6;
                width: 100%;
                &.barometer-colors {
                    background: linear-gradient(90deg, #f44336, #fca112, #f1d01c,#94b633,#4caf50);
                }
            }
        }
    }
</style>