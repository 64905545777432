﻿<template>
    <div class="row">
        <div class="col-md-12">
            <div class="row mb-2">
                <div class="col-md-3 col-6">
                    <label>{{translate('company')}}</label>
                    <div class="filter-input">
                        <i class="fas fa-filter"></i>
                        <input type="text" :placeholder="translate('filter_on_name')" v-model="filter.name" />
                    </div>
                </div>

                <div class="col-md-3 col-6">
                    <label>{{translate('status')}}</label>
                    <select class="form-control" v-model="filter.status">
                        <option value="all">{{translate('all')}}</option>
                        <option value="zeer-negatief">{{translate('zeer_negatief')}}</option>
                        <option value="negatief">{{translate('negatief')}}</option>
                        <option value="matig">{{translate('matig')}}</option>
                        <option value="positief">{{translate('positief')}}</option>
                        <option value="zeer-positief">{{translate('zeer_positief')}}</option>
                        <option value="geen-score">{{translate('geen_score')}}</option>
                        <option value="niet-actief">{{translate('niet_actief')}}</option>
                    </select>
                </div>

                <div v-if="!currentList.IsReadOnly" class="col-md-6 text-right mt-md-auto mt-2">
                    <a href="" class="btn btn-blue" @click.prevent="openAddCompaniesModal()">
                        <i class="fas fa-plus mr-0 mr-md-2"></i> <span class="d-md-inline-block">{{translate('add_companies')}}</span>
                    </a>
                </div>
            </div>
            <template v-if="companies.length">
                <table class="table table-striped table-small data-table">
                    <thead>
                        <tr>
                            <th width="18%">{{translate('vat')}}</th>
                            <th width="41%">{{translate('company')}}</th>
                            <th class="d-none d-lg-table-cell">{{translate('address')}}</th>
                            <th width="110px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr is="company-row" v-for="company in companies" :key="company.Vat" v-bind="company" :cant-remove="currentList.IsReadOnly"></tr>
                    </tbody>
                </table>
                <div class="text-grey font-small py-5 text-center text-uppercase" v-if="!hasShownAllCompanies"><i class="fas fa-arrow-down mr-2"></i> {{translate('scroll_to_show_more')}}</div>
            </template>
            <template v-else>
                <p class="mt-5">
                    {{translate('no_results')}}
                </p>
            </template>
        </div>
    </div>
</template>

<script>
    import Page from 'Lib/Page';
    import CompanyRow from './company-row.vue';
    import _ from 'lodash';
    import Translate from 'Mixins/translate';
    import Vue from 'vue';

    export default Vue.extend({
        name: 'list-management-company',
        components: {
            CompanyRow
        },
        mixins: [Translate],
        props: {
            currentList: Object
        },
        data() {
            return {
                filter: {
                    name: '',
                    status: 'all'
                },
                displayAmount: 50
            };
        },
        computed: {
            filteredCompanies() {
                return _.filter(this.currentList.Companies, (company) => {
                    let passesThroughNameFilter = company.Naam.toLowerCase().indexOf(this.filter.name.toLowerCase()) !== -1;
                    let passesThroughStatusFilter = this.filter.status == "all"
                        ? true
                        : company.BarometerStatus == this.filter.status;

                    return passesThroughNameFilter && passesThroughStatusFilter;
                });
            },
            companies() {
                return _.take(this.filteredCompanies, this.displayAmount);
            },
            hasShownAllCompanies() {
                return this.displayAmount >= this.filteredCompanies.length
            }
        },
        methods: {
            openAddCompaniesModal() {
                this.$root.$emit('show-modal', 'addCompanies');
            },
            infiniteScroll() {
                window.onscroll = () => {
                    const fullDocumentHeight = document.documentElement.scrollHeight;
                    const scrolledHeight = document.documentElement.scrollTop;
                    const screenHeight = window.innerHeight;

                    if (scrolledHeight + screenHeight + 200 >= fullDocumentHeight && !this.hasShownAllCompanies)
                        this.displayAmount += 50;
                }
            }
        },
        mounted() {
            Page.applyBehaviors('#opvolging');
            this.infiniteScroll();
        }
    });
</script>