<template>
    <div>
        <h2>{{translate('history')}}</h2>
            <div  class="may-overflow-x">
            <table class="table data-table table-striped">
                <thead>
                    <tr>
                        <th style="min-width:130px" class="position-relative">
                            <select v-model="filter.countryCode" class="form-control">
                                <option :value="''">{{translate('filter_on_country')}}</option>
                                <option v-for="country in historyCountries" v-if="country" :value="country.code">{{country.name}}</option>
                            </select>
                        </th>
                        <th style="min-width:180px">
                            <input type="search" v-model="filter.companyName" class="form-control" :placeholder="translate('filter_on_companyname')"/>
                        </th>
                        <th></th>
                        <th style="min-width:150px"></th>
                    </tr>
                    <tr>
                        <th>{{translate('country')}}</th>
                        <th>{{translate('company_name')}}</th>
                        <th>{{translate('registration_number')}}</th>
                        <th>{{translate('last_report')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr 
                        is="company-row" 
                        role="row" 
                        v-for="company in filteredCompanies" 
                        :hasFollowup="hasFollowup"
                        :company="company" 
                        :key="company.companyId"></tr>
                </tbody>
            </table>
        </div>  
    </div>
</template>

<script lang="ts">
    import CompanyRow from './company-row.vue';
    import { Company, Country } from '../types';
    import Translate from '../../mixins/translate';
    import mixins from 'vue-typed-mixins';
    import CountryInput from './country-input.vue';
    import _ from 'lodash';
    import QueryString from '../../../lib/QueryString';
    
    const QS_COUNTRY: string = "country"

    export default mixins(Translate).extend({
        name: 'companies',
        components: {CountryInput, CompanyRow },
        props: {
            countries: Array as () => Country[],
            companies: Array as () => Company[],
            hasFollowup: Boolean
        },
        data() {
            return {
                filter: {
                    companyName: '',
                    countryCode: '',
                    following: false
                },
            }
        },
        watch: {
          'filter.countryCode': (val) => {
              let newUrl = '';
              
              if(val)
                  newUrl = QueryString.updateParameter(window.location.href, QS_COUNTRY, val);
              else
                  newUrl = QueryString.deleteParameter(window.location.href, QS_COUNTRY);
              
              history.replaceState({}, document.title, newUrl);
          }  
        },
        computed: {
            filteredCompanies(): Company[] {
                return this.companies.filter(company => {
                    const matchesCountry = 
                        this.filter.countryCode === '' || this.filter.countryCode === company.countryCode;
                    const matchesName = 
                        this.filter.companyName === '' || company.companyName.cw_contains(this.filter.companyName);
                    const matchesFollowing =
                        company.followupEndDate !== null || !this.filter.following;

                    return matchesCountry && matchesName && matchesFollowing;
                });
            },
            historyCountries(): Country[] {
                return _.uniq(
                    this.companies.map(company => this.countries?.firstOrNone(c => c.code === company.countryCode))
                .presentValues());
            }
        },
        mounted(): void {
            this.filter.countryCode = QueryString.getParameter(window.location.href, QS_COUNTRY) ?? '';
        }
    });
</script>

<style scoped>
</style>