﻿<template>
   <component 
              :is="svg ? 'svg-baro' : 'html-baro'" 
              :score="score" 
              :scoreFormatted="scoreFormatted" 
              :className="className"></component>
</template>

<script>
    import SvgBaro from './svg-barometer-icon.vue';
    import HtmlBaro from './html-barometer-icon.vue';

    export default {
        name: 'barometer-icon',
        components: {
            SvgBaro,
            HtmlBaro
        },
        props: {
            className: String,
            svg: Boolean,
            score: String
        },
        computed: {
            scoreFormatted() {
                return this.score ?
                    this.score.toString().replace('.', ',')
                    : 0
            }
        }
    };
</script>