
<tr>
    <td class="no-wrap">
        <img :src="'/images/flags/' + company.countryCode + '.svg'" width="18" height="18" class="mr-1" :alt="company.country">
        {{company.country}}
    </td>
    <td>
        <b>
            <a :href="link" class="tr-anchor">{{company.companyName}}</a>
        </b>
    </td>
    <td>{{company.registrationNumber}}</td>
    <td>
        {{company.mostRecentReportDate|toLongDate}}
    </td>
    
</tr>
