
<div>
    <div class="cookie-list">
        <cookie-type v-for="(type, idx) in cookieTypes" :key="idx" v-bind="type"></cookie-type>
    </div>
    <button type="button" class="btn btn-info btn-large btn-lg" id="cookieAgreeBtn" v-on:click.prevent="accept()">{{btnLabel}}</button>
    <div class="mt-3">
        <a class="body-color" href="" v-on:click.prevent="acceptMinimal()">{{linkLabel}}</a>
    </div>
</div>
