﻿import Vue from 'vue';
import Ajax from 'Lib/Ajax';
import Page from 'Lib/Page';
import Toast from 'Lib/Toast';
import Translate from 'Mixins/translate';
import FeatureExplanation from './feature-explanation.vue';

var UpgradeLevel = {
    newPakket: 'new_package',
    newFeature: 'new_feature',
    upgradedFeature: 'upgraded_feature'
};

export default (function () {
    Page.registerVue('#upgradePackageForm', function (rootSelector) {
        new Vue({
            el: rootSelector,
            components: {
                FeatureExplanation
            },
            mixins: [Translate],
            data() {
                return {
                    features: window.cw.features,
                    initialFeature: window.cw.initialFeature,
                    selectedFeature: window.cw.features[0],
                    isFeatureInNewPackage: window.cw.isFeatureInNewPackage,
                    showPrice: window.cw.showPrice,
                    upgradePrice: window.cw.upgradePrice,
                    minimumAbonnementType: window.cw.minimumAbonnementType,
                    suggestedAmount: window.cw.suggestedAmount,
                    hasAccess: window.cw.hasAccessToFeature
                }
            },
            computed: {
                upgradeLevel() {
                    return this.isFeatureInNewPackage
                        ? UpgradeLevel.newPakket
                        : !this.hasAccess
                            ? UpgradeLevel.newFeature
                            : UpgradeLevel.upgradedFeature;
                },
                title() {
                    return this.upgradeLevel == UpgradeLevel.newPakket
                        ? this.translate(this.upgradeLevel, this.minimumAbonnementType)
                        : this.upgradeLevel == UpgradeLevel.newFeature
                            ? this.translate(this.upgradeLevel, this.translate("feature_" + this.initialFeature))
                            : this.translate(this.upgradeLevel, this.translate("feature_" + this.initialFeature), this.suggestedAmount);
                }
            },
            methods: {
                selectFeature(feature) {
                    this.selectedFeature = feature;
                },
                requestContact() {
                    Ajax.post(
                        '/ajax/upgrade/request-upgrade',
                        {
                            feature: this.initialFeature
                        },
                        () => {
                            Toast.success('Bedankt, wij nemen zo snel mogelijk contact met u op in verband met deze upgrade.')
                        },
                        err => Toast.error(err)
                    )
                },
                orderUpgrade() {
                    Ajax.post(
                        '/ajax/upgrade/order-upgrade',
                        {
                            feature: this.initialFeature
                        },
                        () => {
                            Toast.success('Bedankt, wij maken alles zo snel mogelijk in orde.')
                        },
                        err => Toast.error(err)
                    )
                }
            }
        });
    });
}());
