﻿import Vue from 'vue';
import Page from '../lib/Page';
import CwModal from './shared/modal.vue';

export default (function () {
    Page.registerVue('#alertsPlusDemoMail', function (rootSelector) {
        new Vue({
            el: rootSelector,
            components: {
                CwModal
            },
            methods: {
                openModal() {
                    this.$root.$emit('show-modal', 'alerts-plus-demo-mail');
                }
            },
        });
    });
}());