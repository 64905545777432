﻿<template>
    <div>
        <canvas id="balansTotaalCanvas" ref="balansTotaalCanvas" :height="height+'px'" :width="width + 'px'"></canvas>
    </div>
</template>

<script lang="ts">
    import Translate from '../../vue/mixins/translate';
    import mixins from 'vue-typed-mixins';
    import { ChartType } from '../../behaviors/Chart';
    import Chart from 'chart.js';
    import { Color, YearData } from './types';
    
    interface BalansTotaalData {
        eigen_vermogen: YearData,
        overlopende_rek: YearData,
        schulden_kort: YearData,
        schulden_lang: YearData
    }
    
    export default mixins(Translate).extend({
        name: 'balans_totaal',
        data() {
            return {
                chartData: window.cw.balansTotaalData as BalansTotaalData
            };
        },
        computed: {
            isMobile(): boolean {
                return window.outerWidth <= 500;
            },
            height(): number {
                return this.isMobile ? 320 : 450
            },
            width(): number {
                return this.isMobile ? 320 : 450
            }
        },
        mounted() {
            this.drawChart();  
        },
        methods: {
            formatNumber(input: string | number | undefined): string {
                return '€ ' + (input ?? '').toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            drawChart() {
                const canvas = this.$refs.balansTotaalCanvas as HTMLCanvasElement;
                const rawData = this.chartData;
                
                const schuldenKort = Object.values(rawData.schulden_kort).reduce((acc, current, i) => {
                    acc.push(current ?? 0 + Object.values(rawData.overlopende_rek)[i] ?? 0); 
                    return acc;
                }, [])
                
                 const data = {
                     labels: Object.keys(rawData.eigen_vermogen),
                     datasets: [
                         {
                             label: this.translate('eigen_vermogen'),
                             data: Object.values(rawData.eigen_vermogen),
                             backgroundColor: Color.YELLOW,
                             maxBarThickness: 30,
                             stack: '1'
                         },
                         {
                             label: this.translate('langlopende_schulden'),
                             data: Object.values(rawData.schulden_lang).map(v => v ?? 0),
                             backgroundColor: Color.INDIGO,
                             maxBarThickness: 30,
                             stack: '1'
                         },
                         {
                             label: this.translate('kortlopende_schulden'),
                             data: schuldenKort,
                             backgroundColor: Color.BLUE,
                             maxBarThickness: 30,
                             stack: '1'
                         }
                     ]
                 };
                 
                const instance =  this;
                new Chart(canvas, {
                    type: ChartType.BAR,
                    data: data,
                    options: {
                        title: {
                            display: true,
                            text: this.translate('balanstotaal'),
                            fontSize: 15
                        },
                        animation: {
                            duration: 0
                        },
                        maintainAspectRatio: true,
                        responsive: false,
                        tooltips: {
                            callbacks: {
                                label(tooltipItem, data) {
                                    return instance.formatNumber(tooltipItem?.value);
                                }
                            }
                        },
                        scales: {
                            yAxes: [
                                {
                                    id: 'L',
                                    ticks: {
                                        beginAtZero: true,
                                        callback: (value: number) => {
                                            return instance.formatNumber(value);
                                        }
                                    },
                                    stacked: true
                                },
                            ]
                        },
                        legend: {
                            labels: {
                                fontSize: 15
                            },
                            position: 'bottom'
                        }
                    }
                })
            },
            
        },
    });
</script>

<style lang="scss" scoped>
   
</style>