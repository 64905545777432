
<form method="get"
      v-on:submit.prevent="validateSubmit"
      action="/search/address"
      :data-gtm-cat="gtmCategory"
      :data-gtm-action="gtmAction">

    <div class="search-input-wrapper row" v-if="canSearchOnAddress">
        <div class="input-wrapper" :class="{'col-md-12':showAnnouncement, 'col-md-4': !showAnnouncement}">
            <autocomplete-input fetch-url="/ajax/autocomplete/citysuggestions"
                                id="location"
                                input-property="location"
                                :value="location"
                                next-tab-input="street"
                                :placeholder="translate('placeholder_gemeente')"></autocomplete-input>
            <input type="hidden" name="PostalCode" :value="postalCode" />
            <input type="hidden" name="City" :value="city" />
        </div>
        <div class="input-wrapper" :class="{'col-8':showAnnouncement, 'col-md-4 col-8': !showAnnouncement}">
            <autocomplete-input fetch-url="/ajax/autocomplete/streetsuggestions"
                                name="Street"
                                id="street"
                                input-ref="street"
                                input-property="street"
                                next-tab-input="number"
                                :disabled="!streetInputEnabled"
                                :value="street"
                                :extra-params="{'postcode': postalCode}"
                                :placeholder="translate('placeholder_straat')"></autocomplete-input>
        </div>
        <div class="input-wrapper" :class="{'col-4':showAnnouncement, 'col-md-3 col-4': !showAnnouncement}">
            <input class="form-control "
                   type="text"
                   name="Number"
                   id="houseNumber"
                   ref="number"
                   v-model="houseNumber"
                   :placeholder="translate('placeholder_huisnummer')"
                   :disabled="!streetInputEnabled" />
        </div>
        <div :class="{'col-md-12':showAnnouncement, 'col-md-1': !showAnnouncement}">
            <button type="submit" class="btn btn-info h-100 w-100">
                <template v-if="showAnnouncement">
                    {{translate('zoeken')}}
                </template>
                <template v-else>
                    <i class="fas fa-search"></i>
                </template>
            </button>
        </div>

        <div class="col-md-12">
            <div class="card-box warning small m-t-10" v-if="errorMessage !== ''" v-cloak>
                {{errorMessage}}
            </div>
        </div>
    </div>
    <div v-else>
        {{translate('searchbox_upgrade_address_text')}}
        <div class="mt-2" v-if="isBasicUser || isStandardUser">
            <a href="/campaign/search-on-address" class="btn btn-info">
                <span v-if="isBasicUser">{{translate('upgrade_basic_to_premium')}}</span>
                <span v-if="isStandardUser">{{translate('upgrade_standard_to_premium')}}</span>
            </a>
        </div>
    </div>
    <input type="hidden" name="addressQuery" :value="location+';'+street+';'+houseNumber" />
</form>
