﻿<template>
    <div class="radar-score-wrapper">
        <div class="score-label" :style="'left:' + width">{{score}} </div>
        <div class="score-bar" :style="'clip-path:' + polygon"></div>
        <div class="bg-bar"></div>
        <div class="arrow" :style="'left:' + width"></div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: "score-graph",
    props: {
        score: Number
    },
    computed: {
        width(): string {
            return Math.min(this.score * 5, 100) + "%";
        },
        polygon(): string{
            return `polygon(0 0, 0 100%, ${this.width} 100%, ${this.width} 0)`;
        }
    }
});
</script>

<style lang="scss" scoped>
.radar-score-wrapper {
    position: relative;
    padding: 15px 0;
}
.score-label {
    position: absolute;
    display: flex;
    gap: 6px;
    top: -12px;
    font-size: 17px;
    font-weight: 600;
    align-items: center;
    transform: translateX(-50%);
    color: #505050;
}
.arrow {
    border: 6px solid #505050;
    border-left-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    transform: translateX(-50%);
    position: absolute;
    bottom: -8px;
}
.bg-bar {
    background: #dadada;
    height: 6px;
    width: 100%;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    margin-top: 1px;
}
.score-bar {
    background: linear-gradient(to right, #f1d01c, #F9821E, #F44336);
    height: 8px;
    width: 100%;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    z-index: 5;
}
</style>