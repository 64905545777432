import Vue from 'vue';
import Page from '../../lib/Page';
import Prospecting from './pages/prospecting.vue';
import Enrichment from './pages/enrichment.vue';
import DmHistoriek from './pages/historiek.vue';
import CreditsCounter from '../shared/credits-counter.vue';

export default (function () {
    Page.registerVue('#prepaidDataMarketing', function (rootSelector) {
        new Vue({
            el: rootSelector,
            name: 'prepaid_datamarketing',
            components: {
                Prospecting,
                Enrichment,
                DmHistoriek,
                CreditsCounter
            }
        });
    });
}());
