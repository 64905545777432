
<div>
    <form v-if="!isSent" :action="action" method="POST" @submit.prevent="onSubmit">
        <button type="submit" :class="{'btn-success': successButton,'btn-info': !successButton, 'btn': !isTextLink, 'link': isTextLink}">{{buttonText}}</button>
    </form>
    <template v-else>
        <template v-if="isSuccess">
            {{onSuccess}}
        </template>
        <template v-else>
            {{onFailure}}
        </template>
    </template>
</div>
