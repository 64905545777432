﻿<template>
    <transition name="slide-fade">
        <tr role="row" @click.stop="editUser" class="pointer">
            <td :data-header="translate('beheerder')">
                <i v-if="user.isAdmin" class="fas fa-check text-lightblue"></i>
                <i v-else class="fas fa-times text-lightgrey"></i>
            </td>
            <td :data-header="translate('naam')">
                <b>{{user.name}}</b>
            </td>
            <td :data-header="translate('status')">
                <span class="badge" :class="badgeClass">{{translate('tab_label_'+user.type)}}</span>
            </td>
            <td :data-header="translate('email')">
                {{user.email}}
            </td>
            <td :data-header="translate('laatst_actief')">
                <template v-if="user.lastActive !== 0">
                    {{user.lastActive|toTimeAgo}}
                </template>
                <template v-else>-</template>
            </td>
            <td :title="translate('aantal_mobile')" v-if="isLoggedInAsAdmin" class="mobile-hide">
                {{user.mobileInstalls.length}}
            </td>
            <td :title="translate('aantal_persoonlijke_alerts_gebruikt')" class="mobile-hide">
                {{user.alertsUsage}}
            </td>
            
            <td class="mobile-hide">
                <template v-if="!isMailSent">
                    <button class="btn btn-secondary btn-sm show-on-hover"
                            v-on:click.stop="sendInviteAgain"
                            v-if="isLoggedInAsAdmin && user.type === 'pending'" :title="translate('send_invite_again')">
                        <i class="fas fa-envelope mr-2"></i>Mail
                    </button>
                </template>
            </td>
            <td  class="mobile-hide">
                <button class="btn btn-sm btn-default show-on-hover" v-if="isLoggedInAsAdmin">{{translate('btn_edit')}}</button>
            </td>
            <td class="mobile-hide">
                <template v-if="isLoggedInAsAdmin">
                    <button class="btn btn-sm btn-danger remove show-on-hover"
                            v-if="user.type === 'pending'"
                            v-on:click.prevent.stop="removePendingUser"
                            :title="translate('remove_user')">
                        <i class="fas fa-trash-alt"></i>
                    </button>

                    <button class="btn btn-sm btn-danger remove show-on-hover"
                            v-if="user.type === 'active'"
                            v-on:click.prevent.stop="deactivateUser"
                            :title="translate('deactivate_user')">
                        <i class="fas fa-user-lock"></i>
                    </button>

                    <button class="btn btn-sm btn-success show-on-hover"
                            v-if="user.type === 'deactivated'"
                            v-on:click.prevent.stop="reactivateUser"
                            :title="translate('reactivate_user')">
                        <i class="fas fa-user-check"></i>
                    </button>
                </template>
            </td>
        </tr>
    </transition>
</template>

<script lang="ts">
    import Confirm from '../../lib/Confirmation';
    import Ajax from '../../lib/Ajax';
    import Toast from '../../lib/Toast';
    import DateHelpers from '../mixins/date';
    import Translate from '../mixins/translate';
    import mixins from 'vue-typed-mixins';
    import { User, EditablePermissions } from './types';
    import { AlertsPlusAccessLevel } from '../../types/dto/AlertsPlusAccessLevel';
    import { InternationaalAccessLevel } from '../../types/dto/InternationaalAccessLevel';
    import { DataMarketingAccessLevel } from '../../types/dto/DataMarketingAccessLevel';
    import _ from 'lodash';
    

    export default mixins(Translate, DateHelpers).extend({
        name: 'user-row',
        props: {
            user: Object as () => User
        },
        data() {
            return {
                userPermissions: this.user.permissions,
                userIsAdmin: this.user.isAdmin,
                isMailSent: false,
                editablePermissions: window.cw.editablePermissions as EditablePermissions,
                isLoggedInAsAdmin: window.cw.isLoggedInAsAdmin as boolean,
            };
        },
        computed: {
            badgeClass(): string {
                let badgeClass = "";

                if (this.user.type === 'active')
                    badgeClass = "badge-success";
                else if (this.user.type === 'pending')
                    badgeClass = "badge-warning";
                else
                    badgeClass = "badge-danger";

                return badgeClass + " badge-inverted";
            },
            nameOrEmail(): string {
                return this.user.name.trim() !== '' ? this.user.name : this.user.email;
            }
        },
        methods: {
            editUser(): void {
                this.$emit('edit', this.user);
            },
            async deactivateUser(): Promise<void> {
                var successHandler = async () => {
                    await Ajax.postAsync('/ajax/account/deactivate-user', {userId: this.user.userId});

                    this.$root.$emit('userDeactivated', this.user.email);
                };

                Confirm(
                    successHandler,
                    {
                        title: this.translate('confirmation_title'),
                        body: this.translate('confirmation_body'),
                        confirmButtonText: this.translate('confirmation_ok'),
                        cancelButtonText: this.translate('confirmation_cancel')
                    }
                );
            },
            async reactivateUser(): Promise<void> {
                if (this.user.hasSuspiciousDomain) {
                    this.$root.$emit('startReview', this.user);
                } else {
                    Confirm(
                        async () => {
                            await Ajax.postAsync('/ajax/account/activate-user', { userId: this.user.userId });

                            this.$root.$emit('userReactivated', this.user.email);
                        },
                        {
                            title: this.translate('reactivate_user_confirmation_title'),
                            body: this.translate('reactivate_user_confirmation_body'),
                            confirmButtonText: this.translate('reactivate_user_confirmation_ok'),
                            cancelButtonText: this.translate('confirmation_cancel')
                        }
                    );
                }
            },
            async sendInviteAgain(): Promise<void> {
                await Ajax.postAsync(
                    '/ajax/account/send-invite-again',
                    {
                        email: this.user.email
                    }
                );

                this.isMailSent = true;
                Toast.success(this.translate('mail_sent'));
            },
            async removePendingUser(): Promise<void> {
                Confirm(
                    async () => {
                        await Ajax.postAsync('/ajax/account/remove-pending-user', { userId: this.user.userId });

                        Toast.success(this.translate('user_removed', this.nameOrEmail));
                        this.$root.$emit('pendingUserRemoved', this.user.userId);
                    },
                    {
                        title: this.translate("remove_user_confirmation_title"),
                        body: this.translate("remove_user_confirmation_body"),
                        confirmButtonText: this.translate("remove_user_confirmation_ok"),
                        cancelButtonText: this.translate("confirmation_cancel"),
                    }
                );
            }
        }
    });
</script>