<template>
    <div class="pricing-cell" :class="{'d-none d-sm-inline-flex': !isSelected, 'selected': isSelected}">
        <div v-if="type === PricingRowType.TEXT">{{translate(value)}}</div>
        <div v-else-if="type === PricingRowType.CHECKMARK"><i v-if="value" class="fas fa-check text-teal"></i></div>
        <div v-else-if="type === PricingRowType.NUMBER">{{value}}</div>
        <div v-else-if="type === PricingRowType.DROPDOWN">
            <span v-if="value.length === 1">{{value[0].toString()}}</span>
            <select v-else v-model="selectedValue" class="form-control" style="width: 110px;">
                <option v-for="v in value" :key="v" :value="v">{{v}}</option>
            </select>
        </div>
        <div v-else-if="type === PricingRowType.CHECKBOX">
            <input :id="'integratie'+currentPackage.propertyName" type="checkbox" v-if="value" v-model="hasIntegratie" class="form-check-input"/>
            <label :for="'integratie'+currentPackage.propertyName">&nbsp;</label>
        </div>
    </div>
</template>

<script lang="ts">
    import {Package, PricingRow, PricingRowType, ValueType} from '../types';
    import Translate from '../../mixins/translate';
    import mixins from 'vue-typed-mixins';


    export default mixins(Translate).extend({
        name: 'pricing_cell',
        props: {
            rowData: Object as () => PricingRow<PricingRowType>,
            selectedPackage: Object as () => Package,
            currentPackage: Object as () => Package
        },
        data() {
            return {
                selectedValue: 250,
                PricingRowType,
                hasIntegratie: false
            }
        },
        watch: {
            selectedValue(alerts: number) {
                if (alerts === 500) {
                    this.$root.$emit('choose-package', Package.Premium500);
                } else {
                    this.$root.$emit('choose-package', Package.Premium);
                }
            },
            hasIntegratie(isSelected: boolean): void {
                this.currentPackage.integrationPrice = isSelected ? 470 : 0;
            }
        },
        computed: {
            isSelected(): boolean {
                return this.selectedPackage === this.currentPackage;
            },
            value(): ValueType {
                return this.rowData.getColumnValue(this.currentPackage);
            },
            type(): PricingRowType{
                return this.rowData.type;
            }
        },
        updated() {
            this.selectedValue = this.selectedPackage === Package.Premium500 ? 500 : 250;
        }
    })
</script>

<style scoped>
</style>