﻿import _ from 'lodash';
import Vue from 'vue';
import Page from '../lib/Page';

export default (function () {

    Page.registerVue('#onboardingRoleChoice', function (rootSelector) {

        new Vue({
            el: rootSelector,
            data: {
                choices: window.cw.choices,
                activeChoiceType: ''
            },
            computed: {
                link: function () {
                    return this.activeChoiceType === 'admin-self' || this.activeChoiceType === 'only-user'
                        ? '/onboarding/create-admin'
                        : '/onboarding/create-user';
                }
            }
        });
    });
}());
