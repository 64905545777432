﻿<template>
    <div class="row">
        <div class="col-lg-3 col-md-12 mb-4">
            <div class="pl-4">
                <h3><b>{{translate('searchterm')}}</b></h3>
                <div class="row">
                    <div class="col-md-8">
                        <p v-html="searchedAddress"></p>
                    </div>
                    <div class="col-md-4 text-right">
                        <a href="" class="btn btn-default" v-on:click.prevent="openAlertsModal" :title="translate(isAddedToAlerts ? 'added_to_alerts' : 'add_to_alerts')" v-if="showAddToAlertsButton && addressId !== null">
                             <span v-if="isAddedToAlerts" class="fa-stack added-to-alerts-icon">
                                  <i class="fas fa-bell fa-lg fa-stack-1x"></i>
                                  <i class="fas fa-check-circle fa-stack-1x"></i>
                            </span>
                            
                            <i v-else class="fas fa-bell fa-lg text-yellow"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="search-result-summary grey-box d-md-block d-none">
                <div class="row">
                  
                    <div class="col-lg-12 col-md-4">
                        <h3><b>Type</b></h3>
                        <div class="row mt-1">
                            <div class="col-md-12">
                                <input type="checkbox" class="form-check-input" id="activeCompanies" v-model="filter.type.activeCompanies" value="1" />
                                <label for="activeCompanies">{{translate('active_companies')}}<b class="ml-1">({{amountOfActiveCompanies}})</b></label>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-md-12">
                                <input type="checkbox" class="form-check-input" id="inactiveCompanies" v-model="filter.type.inactiveCompanies" value="1" />
                                <label for="inactiveCompanies">{{translate('inactive_companies')}}<b class="ml-1">({{amountOfInactiveCompanies}})</b></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-4 mt-lg-3 mt-0">
                        <h3><b>{{translate('search_by')}}</b></h3>

                        <div class="row mt-2" v-for="(origin, idx) in originsSorted" :key="idx">
                            <div class="col-md-12">
                                <input type="checkbox" class="form-check-input" :id="origin" v-model="filter.origin[origin]" value="1" />
                                <label :for="origin">{{translate(origin)}}<b class="ml-1">({{getAmountOfResultsWithOrigin(origin)}})</b></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <b>{{amountOfFilteredResults}} {{translate('results')}}</b>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-9 col-md-12">
            <div class="row">
                <div class="col-md-12 vert-offset-bottom-1">
                    <div class="filter-input">
                        <i class="fas fa-filter"></i>
                        <input type="text" :placeholder="translate('filter_by_name')" v-model="filterTerm" />
                    </div>
                </div>
                <result-list v-for="(group, key) in groupedResults" :results="group" :name="key" :key="key"></result-list>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import _, {add, Dictionary} from 'lodash';
    import List from './list.vue';
    import Translate from '../../../mixins/translate';
    import QueryString from '../../../../lib/QueryString';
    import Ajax from "../../../../lib/Ajax";
    import Toast from "../../../../lib/Toast";
    import T from "../../../../lib/Translate";

    interface SearchResult {
        addressStreet: string;
        addressNumber: string;
        addressBox: string;
        origin: string;
        barometer: string;
        companyEndYear: string;
        addressEndYear: string;
        name: string;
        isActive: boolean;
        vat: number;
        link: string;
        vorm: string;
        type: string;
        huidigeMaatschappelijkeZetelStraat: string;
        huidigeMaatschappelijkeZetelGemeente: string;
    }
    
    export default Translate.extend({
        name: 'address-search-results',
        props: {
            results: Array as () => SearchResult[],
            origins: Array as () => string[],
            addressId: Number,
            listId: Number,
            isAddedToAlerts: Boolean
        },
        data() {
            return {
                filterTerm: '',
                filter: {
                    type: {
                        activeCompanies: true,
                        inactiveCompanies: true
                    },
                    origin: _.mapValues(
                        _.keyBy(this.origins,
                            (o) => o),
                        () => true)
                },
                street: '' as string,
                houseNumber: '' as string,
                postalCode: '' as string,
                city: '' as string,
                showAddToAlertsButton: window.cw.isAddressFollowupEnabled
            }
        },
        components: {
            'result-list': List
        },
        computed: {
            originsSorted(): string[] {
                return _.sortBy(this.origins);
            },
            searchedAddress(): string {
                return this.street + " " + this.houseNumber + ", <br/>" + this.postalCode + " " + this.city;
            },
            amountOfActiveCompanies(): number {
                return _.filter(this.results, (r) => {
                    return r.isActive && r.type === 'company';
                }).length;
            },
            amountOfInactiveCompanies(): number {
                return _.filter(this.results, (r) => {
                    return !r.isActive && r.type === 'company';
                }).length;
            },
            filteredResults(): SearchResult[] {
                return this.filterResults(this.results);
            },
            groupedResults(): Dictionary<[SearchResult, ...SearchResult[]]>{
                return _.fromPairs(_.sortBy(_.toPairs(_.groupBy(this.filteredResults, 'origin')), 0));
            },
            amountOfFilteredResults(): number {
                return this.filteredResults.length;
            }
        },
        methods: {
            add,
            getAmountOfResultsWithOrigin(origin: string): number {
                return _.filter(this.results, (result) => {
                    return result.origin === origin;
                }).length;
            },
            filterResults(results: SearchResult[]): SearchResult[] {
                let activeCompanies: SearchResult[] = [];
                let inactiveCompanies: SearchResult[] = [];
                
                if (this.filter.type.activeCompanies) {
                    activeCompanies = _.filter(results, (result) => {
                        return result.type === 'company' && result.isActive;
                    });
                }
                if (this.filter.type.inactiveCompanies) {
                    inactiveCompanies = _.filter(results, (result) => {
                        return result.type === 'company' && !result.isActive;
                    });
                }

                let typeFilteredResults = _.concat(activeCompanies, inactiveCompanies);

                _.forEach(this.filter.origin, (value, key) => {
                    if (!value) {
                        typeFilteredResults = _.filter(typeFilteredResults, (result) => {
                            return result.origin !== key;
                        })
                    }
                });

                return _.orderBy(typeFilteredResults, 'name', 'asc');
            },
            openAlertsModal(){
                this.$root.$emit("show-modal", "add-to-alerts");
            }
        },
        mounted() {
            const uri = window.location.href;
            this.postalCode = QueryString.getParameter(uri, "PostalCode") ?? "";
            this.city = QueryString.getParameter(uri, "City") ?? "";
            this.street = QueryString.getParameter(uri, "Street") ?? "";
            this.houseNumber = QueryString.getParameter(uri, "Number") ?? "";
        }
    });
</script>