﻿import Vue from 'vue';
import Page from 'Lib/Page';
import OpvolgingWrapper from './wrapper.vue';
import Ajax from 'Lib/Ajax';
import Toast from 'Lib/Toast';
import Translate from 'Mixins/translate';
import Vuelidate from 'vuelidate'
import _ from 'lodash';
import QueryString from "../../lib/QueryString";

Vue.use(Vuelidate)
export default (function () {
    Vue.filter('typeToIcon', (type) => {
        switch (type) {
            case "kredietlimiet":
                return "fa-euro-sign";
            case "barometer":
                return "fa-tachometer-alt";
            case "adres":
                return "fa-truck-moving";
            case "naam":
                return "fa-pencil-alt";
            case "status":
                return "fa-heartbeat";
            case "juridische-vorm":
                return "fa-building";
            case "doorhaling":
                return "fa-exclamation-triangle";
            case "gerechtelijk-akkoord":
                return "fa-exclamation-triangle";
            case "rsz-dagvaarding":
                return "fa-exclamation-triangle";
            case "currator-aangesteld":
                return "fa-gavel";
            case "faillissement":
                return "fa-gavel";
            case "publicatie":
                return "fa-file-alt";
            case "jaarrekening":
                return "fa-file-alt";
            case "mandaat":
                return "fa-user-tie";
            case "op-adres":
                return "fa-truck-moving";
            default:
                return "fa-check";
        }
    });

    Page.registerVue('#opvolging', function (rootSelector) {
        new Vue({
            el: rootSelector,
            components: {
                OpvolgingWrapper
            },
            mixins: [Translate],
            data() {
                return {
                    initialListId: window.cw.initialListId,
                    listId: window.cw.initialListId,
                    lists: window.cw.lists,
                    amountOfFollowupsUsed: window.cw.amountOfFollowupsUsed,
                    amountOfFollowupsAvailable: window.cw.amountOfFollowupsAvailable,
                    amountOfFollowupsSuggested: window.cw.amountOfFollowupsSuggested,
                    isLimitReached: window.cw.isLimitReached,
                    isLimitAlmostReached: window.cw.isLimitAlmostReached,
                    userId: window.cw.userId,
                    isAdmin: window.cw.isAdmin,
                    showSampleMail: window.cw.showSampleMail,
                    language: window.cw.language,
                    canAccessScreening: window.cw.canAccessScreening,
                    canHaveSharedLists: window.cw.canHaveSharedLists,
                    users: window.cw.users,
                    isSidebarOpen: false,
                    currentList: {},
                    isLoading: false,
                    isFetching: false
                }
            },
            methods: {
                updateUsage() {
                    Ajax.get(
                        '/ajax/followup/get-usage',
                        {},
                        response => {
                            this.amountOfFollowupsUsed = response.Data.AmountUsed;
                            this.amountOfFollowupsAvailable = response.Data.AmountAvailable;
                            this.isLimitReached = response.Data.IsLimitReached;
                            this.isLimitAlmostReached = response.Data.IsLimitAlmostReached;
                        },
                        () => {

                        }
                    );
                },
                changeListId(listId) {
                    this.listId = listId;
                    this.fetchListWithEvents(listId);
                },
                fetchListWithEvents(listId) {
                    if (!listId)
                        return;
                    this.isFetching = true;
                    this.isLoading = true;
                    let listIds = _.map(this.lists, list => list.listId);

                    if (!_.includes(listIds, listId)) {
                        window.location.href = "/followup";
                    }

                    Ajax.get(
                        '/ajax/followup',
                        {
                            listId
                        },
                        (response) => {
                            this.currentList = response.Data;
                            this.isFetching = false;
                            this.isLoading = false;
                        },
                        (err) => {
                            let listName = _.find(this.lists, list => {
                                return list.listId === listId;
                            }).name;
                            Toast.error(this.translate("cannot_find_events_for_list", listName));
                            this.isFetching = false;
                            this.isLoading = false;
                        }
                    );
                },
            },
            created() {

                this.fetchListWithEvents(this.listId);

                if (this.listId) {
                    const modal = QueryString.getUrlParameter("modal");
                    const querystring = modal ? "?modal=" + modal : "";
                    const tabName = location.pathname.indexOf('/list-management') === -1 ? 'events' : 'list-management';
                    
                    window.history.replaceState(
                        {
                            listId: this.listId,
                            tabName: tabName
                        },
                        'Alerts list ' + this.listId,
                        '/followup/' + this.listId + '/' + tabName + querystring
                    );
                }


                this.$root.$on('change-state', payload => {
                    const tabName = payload.tabName || 'events';
                    const listId = payload.listId || this.lists[0].listId;

                    if (this.listId !== listId)
                        this.changeListId(listId);

                    this.$root.$emit('change-tab', tabName);
                    window.history.pushState(
                        {
                            listId,
                            tabName
                        },
                        'Alerts list ' + listId,
                        '/followup/' + listId + '/' + tabName);
                });

                window.onpopstate = (event) => {
                    const listId = event.state.listId;
                    const tabName = event.state.tabName;

                    if (this.listId !== listId)
                        this.changeListId(listId);

                    this.$root.$emit('change-tab', tabName);
                };

                this.$root.$on('toggle-sidebar', () => {
                    this.isSidebarOpen = !this.isSidebarOpen;
                });

                this.$root.$on('updateList', payload => {
                    if (payload.companies)
                        this.currentList.Companies = payload.companies;

                    if (payload.events)
                        this.currentList.FollowUpEvents = payload.events;

                    if (payload.name)
                        this.currentList.Naam = payload.name;

                    let listInSidebar = this.lists.find(list => list.listId === payload.listId);

                    if (payload.companies)
                        listInSidebar.numberOfCompanies = payload.companies.length;

                    if (payload.name)
                        listInSidebar.name = payload.name;
                });

                this.$root.$on('removeCompany', ({vat, isReAdded}) => {
                    let company = this.currentList.Companies.find(c => c.Vat === vat);
                    let list = this.lists.find(list => list.listId === this.currentList.ListId);
                    list.numberOfCompanies+= isReAdded ? 1 : -1;
                    this.currentList.NumberOfCompanies+= isReAdded ? 1 : -1;
                    
                    if (company)
                        company.isRemoved = !isReAdded;
                });

                this.$root.$on('removePerson', ({id, isReAdded}) => {
                    let person = this.currentList.Persons.find(c => c.Id === id);
                    let list = this.lists.find(list => list.listId === this.currentList.ListId);
                    list.numberOfPersons+= isReAdded ? 1 : -1;
                    this.currentList.NumberOfPersons+= isReAdded ? 1 : -1;

                    if (person)
                        person.isRemoved = !isReAdded;
                });

                this.$root.$on('removeAddress', ({id, isReAdded}) => {
                    let address = this.currentList.Addresses.find(c => c.Id === id);
                    let list = this.lists.find(list => list.listId === this.currentList.ListId);
                    list.numberOfAddresses+= isReAdded ? 1 : -1;
                    this.currentList.NumberOfAddresses+= isReAdded ? 1 : -1;

                    if (address)
                        address.isRemoved = !isReAdded;
                });

                this.$root.$on('setLoading', isLoading => {
                    this.isLoading = isLoading;
                });
                
                this.$root.$on('change-used-companies', () => {
                    this.updateUsage();
                });
                
                this.$root.$on('change-used-persons', () => {
                    this.updateUsage();
                });
                
                this.$root.$on('change-used-addresses', () => {
                    this.updateUsage();
                });
            }
        });
    });
}());
