﻿<template>
    <th :class="{'open': isFilterBoxShown, 'filtered': store.hasCompanyFilters}" @click.stop="toggle">
        <div class="filter-title-row">

            <div class="d-flex align-items-center" style="gap:8px">
                <span class="text">{{translate('company')}}</span>
                <div class="d-flex flex-column sort-wrapper" >
                    <a class="d-inline-block sort-btn" :class="{'active': isSorted && !isDescending}" href="#" @click.prevent.stop="setSort(false)" :title="translate('sort_asc')">
                        <i class="fa-caret-up" :class="{'fas': isSorted && !isDescending, 'far': !isSorted || isDescending}"></i>
                    </a>
                    <a class="d-inline-block  sort-btn" :class="{'active': isSorted && isDescending}"  href="#" @click.prevent.stop="setSort(true)" :title="translate('sort_desc')">
                        <i class="fa-caret-down" :class="{'fas': isSorted && isDescending, 'far': !isSorted || !isDescending}"></i>
                    </a>
                </div>
            </div>
        </div>
        <transition name="dropdown">
            <div v-if="isFilterBoxShown" v-click-outside="close">
                <div id="filter-box" class="filter-box" @click.stop>
                    <ul class="nav nav-tabs border-bottom">
                        <li class="nav-item">
                            <a :class="{'active': currentTab === Tab.COMPANYNAME}" class="nav-link" href="" @click.prevent="changeTab(Tab.COMPANYNAME)">
                                {{ translate('companyname') }}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a :class="{'active': currentTab === Tab.STREET}" class="nav-link" href="" @click.prevent="changeTab(Tab.STREET)">
                                {{ translate('address') }}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a :class="{'active': currentTab === Tab.VORM}" class="nav-link" href="" @click.prevent="changeTab(Tab.VORM)">
                                {{ translate('form') }}
                            </a>
                        </li>
                    </ul>

                    <div class="mt-3">
                        <div v-if="currentTab === Tab.COMPANYNAME">
                            <input v-model="store.companyNameFilter" class="form-control" :placeholder="translate('filter_on_company')" type="search"/>
                        </div>

                        <div v-if="currentTab === Tab.STREET">
                            <div class="row">
                                <div class="col-md-8">
                                    <input v-model="streetSearchTerm" class="form-control" :placeholder="translate('filter_on_street')" type="search"/>
                                    <div class="filter-overflow-box mt-4">
                                        <ul>
                                            <li v-for="street in shownFilteredStreets" :key="street">
                                                <input :id="street"
                                                       :checked="store.streetFilters.includes(street)"
                                                       class="form-check-input azure"
                                                       type="checkbox"
                                                       @change="store.toggleFilterStreet(street)"/>
                                                <label :for="street" class="wrap-normal d-block"><span>{{ street }}</span></label>
                                            </li>
                                        </ul>
                                        <a class="link mt-2 d-inline-block" v-if="maxStreetShowCount <= shownFilteredStreets.length" href="" @click.prevent="maxStreetShowCount+=100">Meer laden</a>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <input class="form-control" type="search" :placeholder="translate('house_number')" v-model="store.houseNumberFilter">
                                </div>
                            </div>
                        </div>

                        <div v-if="currentTab === Tab.VORM">
                            <div class="filter-overflow-box mt-4">
                                <ul>
                                    <li v-for="form in uniqueForms" :key="form">
                                        <input :id="form"
                                               :checked="store.formFilters.includes(form)"
                                               class="form-check-input azure"
                                               type="checkbox"
                                               @change="store.toggleFilterForm(form)"/>
                                        <label :for="form" class="wrap-normal d-block"><span>{{ form }}</span></label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </th>
</template>

<script lang="ts">
    import {useStore} from '../../store';
    import Translate from '../../../mixins/translate';
    import _ from 'lodash';
    //@ts-ignore
    import ClickOutside from "vue-click-outside";

    enum Tab {
        COMPANYNAME,
        STREET,
        VORM
    }

    export default Translate.extend({
        name: 'company-filter-th',
        directives: {ClickOutside},
        data() {
            return {
                streetSearchTerm: '',
                Tab,
                currentTab: Tab.COMPANYNAME,
                store: useStore(),
                maxStreetShowCount: 100
            };
        },
        methods: {
            changeTab(tab: Tab): void {
                this.currentTab = tab;
            },
            toggle(): void {
                this.store.toggleFilterBox('company');
            },
            close(): void {
                this.store.toggleFilterBox(null);
            },
            setSort(desc: boolean): void {
                this.store.setSorting('companyName', desc);
                this.close();
            },
            removeFilters() {
                this.store.removeCompanyFilters();
            }

        },
        watch: {
            isFilterBoxShown() {
                this.streetSearchTerm = '';
            }
        },
        computed: {
            uniqueStreets(): string[] {
                return _.uniq(this.store.filteredCompanies("street").flatMap(c => {
                    const zetelStraat: string = c.zetelAddress.straatNaam;
                    const straten: string[] = c.vestigingsAdressen.flatMap(v => v.straatNaam);
                    straten.push(zetelStraat);

                    return straten;
                }));
            },
            uniqueForms(): string[] {
                return _.uniq(this.store.filteredCompanies("form").map(c => c.juridischeVormTranslated)).sortAlpha();
            },
            isFilterBoxShown(): boolean {
                return this.store.openedFilterBox === 'company';
            },
            shownFilteredStreets(): string[] {
                const streetFilters = this.store.streetFilters;
                return this.uniqueStreets.filter(v => (v ?? "").cw_contains(this.streetSearchTerm))
                .sortAlpha()
                .sort((a,b) => streetFilters.includes(a) ? -1 : 1)
                .splice(0,this.maxStreetShowCount);
            },
            isSorted(): boolean {
                return this.store.sorting.property === 'companyName';
            },
            isDescending(): boolean {
                return this.store.sorting.descending;
            }
        }
    });
</script>

<style lang="scss" scoped>
    $primColor: #0368FF;
    h3 {
        text-transform: none;
    }

    .link {
        text-transform: none;
        color: $primColor;
        text-decoration: none !important;

        &:hover {
            text-decoration: underline !important;
        }
    }

    th {
        position: relative;
        cursor: pointer;
        user-select: none;
        color: #595959;
        font-weight: 500;
        &.filtered {
            color: black;
            font-weight: 600;
        }

        &:hover, &.open {
            background-color: white;
        }

        .filter-box {
            position: absolute;
            background: white;
            padding: 25px;
            min-width: 520px;
            width: 100%;
            left: 0;
            top: 100%;
            box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
            border-radius: 0 0 4px 4px;
            z-index: 20;
            border-top: 0;
        }
    }

    .wrap-normal {
        white-space: normal;
    }

    .filter-overflow-box {
        max-height: 300px;
        overflow-y: auto;
    }

    .filter-title-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        z-index: 1;
        position: relative;
        gap: 10px;

        .has-filtered {
            padding: 5px 9px;
            border-radius: 3px;
            display: inline-block;
            background-color: #d7d7d7;

            &:hover {
                background-color: #c4c4c4;
                color: black;
            }
        }
    }

    .sort-wrapper {
        writing-mode: initial;
        justify-content: flex-end;
        .sort-btn {
            line-height: 4px;
            padding: 2px;
            color: #c0c0c0;
            :hover{
                color: grey;
            }
            &.active {
                color: $primColor;
            }
            i {
                line-height: 0.4;
            }
        }
    }
</style>