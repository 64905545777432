﻿<template>
    <div class="row">
        <div class="col-md-12">
          
        </div>
    </div>
</template>

<script>
    export default {
        name: 'feature-search-on-manager'
    }
</script>