export default abstract class Option<T> {
    static some<T>(value: T): Option<T> {
        if (value !== null && value !== undefined)
            return new PresentOption<T>(value);
        else throw new TypeError('The passed value was null or undefined.');
    }

    static someNotNull<T>(nullable: T | null | undefined): Option<T> {
        if (nullable !== null && nullable !== undefined)
            return new PresentOption<T>(nullable);
        else return new EmptyOption<T>();
    }

    static none<T>(): Option<T> {
        return new EmptyOption();
    }

    abstract contains(value: T): boolean;

    /**
     * Returns whether this is present or not.
     *
     * If a payload is present, be `true` , otherwise be `false`.
     */
    abstract hasValue(): boolean;

    /**
     * If a payload is present, executes the given `consumer`, otherwise does nothing.
     *
     * @param consumer a consumer of the payload
     */
    abstract matchSome(consumer: (value: T) => void): void;

    abstract matchSomeAsync(consumer: (value: T) => Promise<void>): Promise<void>;

    /**
     * If a payload is present, executes the given `consumer`,
     * otherwise executes `emptyAction` instead.
     *
     * @param some a consumer of the payload, if present
     * @param none an action, if empty
     */
    abstract match(
        some: (value: T) => void,
        none: () => void
    ): void;

    /**
     * Filters a payload with an additional `predicate`.
     *
     * If a payload is present and the payload matches the given `predicate`, returns `this`,
     * otherwise returns an empty `Optional` even if this is present.
     *
     * @param predicate a predicate to test the payload, if present
     */
    abstract filter(predicate: (value: T) => boolean): Option<T>;

    /**
     * Maps a payload with a mapper.
     *
     * If a payload is present, returns an `Optional` as if applying `Optional.ofNullable` to the result of
     * applying the given `mapper` to the payload,
     * otherwise returns an empty `Optional`.
     *
     * @param mapper a mapper to apply the payload, if present
     */
    abstract map<U>(mapper: (value: T) => U): Option<NonNullable<U>>;

    /**
     * Maps a payload with a mapper which returns Optional as a result.
     *
     * If a payload is present, returns the result of applying the given `mapper` to the payload,
     * otherwise returns an empty `Optional`.
     *
     * @param mapper a mapper to apply the payload, if present
     */
    abstract flatMap<U>(mapper: (value: T) => Option<U>): Option<U>;

    /**
     * If a payload is present, returns `this`,
     * otherwise returns an `Optional` provided by the given `supplier`.
     *
     * @param supplier a supplier
     */
    abstract or(supplier: () => Option<T>): Option<T>;

    /**
     * If a payload is present, returns the payload, otherwise returns `another`.
     *
     * @param another an another value
     */
    abstract valueOr(another: T): T;

    /**
     * If a payload is present, returns the payload,
     * otherwise returns the result provided by the given `supplier`.
     *
     * @param supplier a supplier of another value
     */
    abstract valueOrSupplier(supplier: () => T): T;

    /**
     * If a payload is present, returns the payload,
     * otherwise throws an error provided by the given `errorSupplier`.
     *
     * @param errorSupplier a supplier of an error
     * @throws {T} when `this` is empty.
     */
    abstract valueOrThrow<U>(errorSupplier: () => U): T;

    /**
     * If a payload is present, returns the payload,
     * otherwise returns `null`.
     */
    abstract valueOrNull(): T | null;

    /**
     * If a payload is present, returns the payload,
     * otherwise returns `undefined`.
     */
    abstract valueOrUndefined(): T | undefined;
}

class PresentOption<T> extends Option<T> {
    private readonly payload: T;

    constructor(value: T) {
        super();
        this.payload = value;
    }

    public toString(): T {
        return this.payload;
    }

    contains(value: T): boolean {
        return value === this.payload;
    }

    hasValue = (): boolean => {
        return true;
    };

    matchSome(consumer: (value: T) => void): void {
        consumer(this.payload);
    }

    async matchSomeAsync(consumer: (value: T) => Promise<void>): Promise<void> {
        await consumer(this.payload);
    }

    match(some: (value: T) => void, none: () => void): void {
        some(this.payload);
    }

    filter(predicate: (value: T) => boolean): Option<T> {
        return predicate(this.payload) ? this : Option.none();
    }

    map<U>(mapper: (value: T) => U): Option<NonNullable<U>> {
        const result: U = mapper(this.payload);
        return Option.someNotNull(result!);
    }

    flatMap<U>(mapper: (value: T) => Option<U>): Option<U> {
        return mapper(this.payload);
    }

    or(supplier: () => Option<T>): Option<T> {
        return this;
    }

    valueOr(another: T): T {
        return this.payload;
    }

    valueOrSupplier(another: () => T): T {
        return this.payload;
    }

    valueOrThrow<U>(exception: () => U): T {
        return this.payload;
    }

    valueOrNull(): T {
        return this.payload;
    }

    valueOrUndefined(): T {
        return this.payload;
    }
}

class EmptyOption<T> extends Option<T> {
    constructor() {
        super();
    }

    contains(value: T): boolean {
        return false;
    }

    hasValue(): boolean {
        return false;
    }

    matchSome(consumer: (value: T) => void): void {
    }

    async matchSomeAsync(consumer: (value: T) => Promise<void>): Promise<void> {
    }

    match(some: (value: T) => void, none: () => void): void {
        none();
    }

    filter(predicate: (value: T) => boolean): Option<T> {
        return this;
    }

    map<U>(mapper: (value: T) => U): Option<NonNullable<U>> {
        return Option.none();
    }

    flatMap<U>(mapper: (value: T) => Option<U>): Option<U> {
        return Option.none();
    }

    or(supplier: () => Option<T>): Option<T> {
        return supplier();
    }

    valueOr(another: T): T {
        return another;
    }

    valueOrSupplier(another: () => T): T {
        return this.valueOr(another());
    }

    valueOrThrow<U>(exception: () => U): T {
        throw exception();
    }

    valueOrNull(): null {
        return null;
    }

    valueOrUndefined(): undefined {
        return undefined;
    }
}
