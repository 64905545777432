<template>
    <form @submit.prevent="onSubmit" class="w-full">
        <div class="row">
            <div class="col-8">
                <ul class="nav nav-tabs border-bottom mb-4">
                     <li class="nav-item" >
                        <a href="" class="nav-link" :class="{'active': currentTab === Tab.NEDERLANDS}" @click.prevent="changeTab(Tab.NEDERLANDS)">
                            <img src="/images/flags/nl.svg" class="mr-1 height-auto" width="13" height="13"> Nederlands</a>
                    </li>
                    <li class="nav-item" >
                        <a href="" class="nav-link" :class="{'active': currentTab === Tab.FRANS}" @click.prevent="changeTab(Tab.FRANS)">
                            <img src="/images/flags/fr.svg" class="mr-1 height-auto" width="13" height="13"> Francais</a>
                    </li>
                    <li class="nav-item" >
                        <a href="" class="nav-link" :class="{'active': currentTab === Tab.ENGELS}" @click.prevent="changeTab(Tab.ENGELS)">
                            <img src="/images/flags/gb.svg" class="mr-1 height-auto" width="13" height="13"> English</a>
                    </li>
                </ul>
            </div>
            <div class="col-4 text-right">
                <button v-if="!isLoading" type="submit" class="btn btn-blue btn-200"><i class="fas fa-check mr-1"></i> Publiceren</button>
                <p v-else>Laden...</p>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mb-4" v-for="article in articles.filter(a => a.language == currentTab).sort((a,b) => a.articleNumber - b.articleNumber)">
                <label class="mb-1">Artikel {{article.articleNumber}} <a href="" @click.prevent="deleteArticle(article.articleNumber)" class="btn btn-danger btn-sm">DEL</a></label>
                <vue-editor v-model="article.body" :editorToolbar="customToolbarExcerpt"></vue-editor>
            </div>
            <div class="col-12 mb-4" v-for="article in newArticles.filter(a => a.language == currentTab).sort((a,b) => a.articleNumber - b.articleNumber)">
                <label class="mb-1">Nieuw artikel <a href="" @click.prevent="deleteNewArticle(article.articleNumber)" class="btn btn-danger btn-sm">DEL</a></label>
                <vue-editor v-model="article.body" :editorToolbar="customToolbarExcerpt"></vue-editor>
            </div>
        </div>

        <div class="col-12">
            <button class="btn btn-default" @click.prevent="addEmptyArticles"><i class="fas fa-plus mr-1"></i> Artikel toevoegen</button>
        </div>
    </form>
</template>

<script lang="ts">
import Vue from 'vue';
import FileInput from '../../shared/file-input.vue';
import Ajax from '../../../lib/Ajax';
import {VueEditor} from "vue2-editor";
import {Language} from "../../../types/dto/Language";

enum Tab {
        NEDERLANDS = "nl",
        FRANS = "fr",
        ENGELS = "en"
    }
    
    interface AvArticle {
        articleNumber: number;
        body: string;
        language: Language;
    }
         
    export default Vue.extend({
        name: 'av-articles-new',
        components: {
            FileInput,
            VueEditor,
        },
        data() {
            return {
                Tab,
                customToolbar: [
                    ["bold", "italic", "underline"],  
                    ["link", 'image'], 
                    [{ list: "bullet" }], 
                    [{ 'size': ['small', false, 'large', 'huge'] }],
                    [{ 'header': [false, 2, 3, 4] }],
                ],
                customToolbarExcerpt: [  
                    ["bold", "italic", "underline"],  
                ],
                currentTab: Tab.NEDERLANDS,
                articles: window.cw.articles as AvArticle[],
                isLoading: false,
                newArticles: [] as AvArticle[]
            }
        },
        computed: {
            maxArticleNumber(): number {
                return Math.max(...this.articles.map(a => a.articleNumber)); 
            },
            allArticles(): AvArticle[] {
                return this.articles.concat(this.newArticles);
            }
        },
        methods: {
            changeTab(tab: Tab): void {
                this.currentTab = tab;
            },
            async onSubmit() {
                this.isLoading = true;
                
                await Ajax.postAsJsonAsync(
                    "/cms/terms-and-conditions/new",
                    {
                        articles: this.allArticles
                    }
                );
                this.isLoading = false;
                
                window.location.href = "/cms/terms-and-conditions";
            },
            sanitizeHtml(html: string): string {
                const regex = /style="[^"]+"/ig;
                return html.replace(regex, "");
            },
            addEmptyArticles() {
                const pseudoRandomId = this.maxArticleNumber + 1;
                this.newArticles.push({
                   language: Language.NL,
                   articleNumber: pseudoRandomId,
                   body: "" 
                });
                this.newArticles.push({
                    language: Language.FR,
                    articleNumber: pseudoRandomId,
                    body: ""
                });
                this.newArticles.push({
                    language: Language.EN,
                    articleNumber: pseudoRandomId,
                    body: ""
                });
            },
            deleteNewArticle(num: number): void {
                this.newArticles = this.newArticles.filter(a => a.articleNumber !== num);
            },
            deleteArticle(num: number): void {
                this.articles = this.articles.filter(a => a.articleNumber !== num);
                
                this.articles.filter(a => a.articleNumber > num).forEach(a => {
                    a.articleNumber = a.articleNumber -1; 
                });
            }
        },
    });
</script>

<style lang="scss">
    .ql-editor {
      min-height: unset !important;
    }
    .ql-snow .ql-picker.ql-expanded .ql-picker-options {
        z-index: 550 !important;
    }
</style>