<template>
    <div>
        <h2 class="mb-4"><b>{{translate("sidebar_legal_form")}}</b></h2>

        <div class="row">
             <div class="col-md-6">
                <select-tree 
                    :isParentSelected="false"
                    :criteria="treeData.juridischeVorm" 
                    :type="ProspectieDataSelectionCriteriumType.JURIDISCHEVORM"></select-tree>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import DataMarketingService from '../../dataMarketingService';
    import SelectTree from '../shared/select-tree.vue';
    import { ProspectieDataSelectionCriteriumType } from '../../../../types/dto/ProspectieDataSelectionCriteriumType';
    import Translate from '../../../mixins/translate';
    import mixins from 'vue-typed-mixins';
    
    

    export default mixins(Translate).extend({
        name: 'dm-legal-form',
        components: {SelectTree},
        data() {
            return {
                ProspectieDataSelectionCriteriumType,
                treeData: DataMarketingService.getInstance().treeData!
            }
        }
    })
</script>

<style scoped>

</style>