﻿<template>
    <div class="px-5 my-5">
        <loading-radar v-if="isLoading"></loading-radar>
        <template v-else>
            <edit-custom-column-modal :field="editField"></edit-custom-column-modal>
            <drawer></drawer>
            <add-to-alerts></add-to-alerts>

            <div class="row mb-2">
                <div class="col-md-6 d-flex">
                    <h1 class="font-weight-bold mr-5 mb-0">Radar</h1>
                </div>
                <div class="col-md-6 text-right">
                    <form method="POST" enctype="application/x-www-form-urlencoded"
                          id="exportForm" ref="exportForm" class="d-inline-flex align-items-center"
                            @submit.prevent="exportData">
                        <span v-if="!canExport" class="text-grey font-small">({{translate('max_export_companies', exportCompanyLimit.toString())}})</span>
                        <button type="submit" class="btn btn-default btn-sm ml-3" :disabled="!canExport || isExporting">
                            <i v-if="!isExporting" class="fas fa-cloud-download mx-1 py-1"></i>
                            <i v-else class="fas fa-spinner spinning mx-1 py-1"></i>
                            export
                        </button>
                    </form>

                    <div class="font-italic mt-1">
                        {{ filteredCompanies.length.formatThousandSeparator() }} {{ translate('x_selected_companies') }}
                    </div>
                </div>
            </div>

            <div class="row my-4">
                <div class="col-lg-8 col-xl-9 d-flex flex-wrap align-items-center" style="gap: 10px;">
                    <select-menu link-to-url label="Dataset" v-model="store.currentDataset" :options="datasetOptions"></select-menu>
                    <i class="far fa-angle-double-right text-grey"></i>
                    <select-menu label="Subset" v-model="store.subsetSelectMenuItem" :options="subsetOptions"></select-menu>
                    <i class="far fa-angle-double-right text-grey"></i>
                    <button type="button" class="btn btn-primary" @click.prevent="store.openFilterListDrawer"><i class="far fa-filter mr-2"></i> {{translate('filters')}}</button>
                </div>
                <div class="col-lg-4 col-xl-3 text-right my-auto">
                    <div class="view-layout">
                        <a href="" :class="{'active': currentView === View.TABLE}"
                           @click.prevent="currentView = View.TABLE">
                            <i class="fas fa-table mr-2"></i>{{ translate('tab_table') }}
                        </a>
                        <a href="" :class="{'active': currentView === View.MAP}" @click.prevent="changeToMapView">
                            <i class="fas fa-map mr-2"></i>{{ translate('tab_map') }}
                        </a>
                    </div>
                </div>
            </div>
            
            <div class="d-flex my-4" style="gap:10px;"  v-if="store.hasAnyFilter">
                <a href="" @click.prevent="store.openFilterDetailDrawer('companyname')" class="applied-filter"
                   v-if="store.companyNameFilter !== ''">
                    {{ translate('companyname') }}: <b class="text-dark">{{ store.companyNameFilter }}</b>
                    <span class="py-1 px-2 " @click.prevent.stop="store.removeCompanyNameFilters()">
                            <i class="far fa-times" :title="translate('clear_filter')"></i>
                        </span>
                </a>

                <a href="" @click.prevent="store.openFilterDetailDrawer('street')" class="applied-filter"
                   v-if="store.streetFilters.length > 0">
                    {{ translate('street') }}: <span
                    class="badge badge-pill ml-1">{{ store.streetFilters.length > 1 ? store.streetFilters.length : store.streetFilters[0] }}</span>
                    <span class="py-1 px-2 " @click.prevent.stop="store.removeStreetFilters()">
                            <i class="far fa-times" :title="translate('clear_filter')"></i>
                        </span>
                </a>

                <a href="" @click.prevent="store.openFilterDetailDrawer('form')" class="applied-filter"
                   v-if="store.formFilters.length > 0">
                    {{ translate('form') }}: <span
                    class="badge badge-pill ml-1">{{ store.formFilters.length > 1 ? store.formFilters.length : store.formFilters[0] }}</span>
                    <span class="py-1 px-2 " @click.prevent.stop="store.removeFormFilters()">
                            <i class="far fa-times" :title="translate('clear_filter')"></i>
                        </span>
                </a>

                <a href="" @click.prevent="store.openFilterDetailDrawer(filter.title)" class="applied-filter"
                   v-for="filter in store.genericFilters.filter(fil => fil.filterCriteria.hasValue)">
                    {{ translate(filter.title) }}: <span class="badge badge-pill ml-1"><span
                    v-html="filter.filterCriteria.toHtml()"></span></span>
                    <span class="py-1 px-2 hover:bg-grey-500 inline-block"
                          @click.prevent.stop="() => {filter.filterCriteria.reset(); store.saveConfig();}">
                            <i class="far fa-times" :title="translate('clear_filter')"></i>
                        </span>
                </a>

                <a href="" 
                   @click.prevent="store.openFilterDetailDrawer(store.customFields.find(f => f.id === +label))"
                   class="applied-filter" v-for="(filter, label) in store.customFilters"
                   v-if="(store.customFields.find(f => f.id === +label).type === 'boolean' && filter != null) || (store.customFields.find(f => f.id === +label).type === 'string' && (filter !== ''))">
                    {{ store.customFields.find(f => f.id === +label).name }}: <span class="badge badge-pill ml-1">
                            <span v-if="typeof filter === 'boolean'">
                                <template v-if="filter">{{ translate('checkbox_checked') }}</template>
                                <template v-else>{{ translate('checkbox_unchecked') }}</template>
                            </span>
                        <span v-else>{{ filter }}</span>
                        </span>
                    <span class="py-1 px-2"
                          @click.prevent="store.removeCustomFilter(store.customFields.find(f => f.id === +label))">
                            <i class="far fa-times" title="remove filter?"></i>
                        </span>
                </a>

                <a href="" @click.prevent="store.removeAllFilters" v-if="store.hasAnyFilter"
                   class="text-dark d-flex align-items-center">
                    <i class="far fa-times pr-2"></i> {{ translate('delete_all_filters') }}
                </a>
            </div>

            <div v-show="currentView === View.TABLE">
                <div
                    v-if="filteredCompanies.length === 0 && store.startedThisMonth && (store.subsetSelectMenuItem?.id !== Subset.ALL && store.subsetSelectMenuItem?.id !== Subset.ALL_ACTIVE)"
                    class="no-data-placeholder">
                    {{ translate('no_companies_first_month') }}
                </div>
                <div v-else class="radar-table-wrapper">
                    <div class="p-3 d-flex justify-content-between">
                        <pagination v-model="currentPage" :amount-of-items="filteredCompanies.length"
                                    :items-per-page="itemsPerPage"></pagination>
                        <button class="btn btn-ghost" @click.prevent="store.openColumnEditDrawer"><i
                            class="fas fa-columns mr-2"></i>{{translate('columns')}} 
                            <span v-if="store.hiddenSelectableColumns.length > 0" class="text-small font-weight-normal text-grey text-lowercase">({{store.hiddenSelectableColumns.length}} verborgen )</span>
                        </button>
                    </div>
                    <div class="radar-container" :class="{'scrolled': isScrolled}" ref="slider">
                        <table class="table mb-0 w-auto radar-slider" id="radar-slider">
                            <thead>
                            <tr class="gray position-relative">
                                <th is="company-filter-th" style="min-width: 360px" class="sticky"></th>
                                <th is="filter-th" v-for="column in store.chosenColumns" :key="'col_' + column.title"
                                    :column="column"></th>
                                <th is="custom-filter-th" v-for="customField in store.customFields"
                                    :key="'custom_' + customField.id" :field="customField"
                                    v-on:editCustomColumn="showEditCustomColumn"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-if="filteredCompanies.length > 0">
                                <tr is="company-row" v-for="(company,idx) in shownCompanies" :key="idx + company.vat"
                                    :company="company"></tr>
                            </template>
                            <tr v-else>
                                <td colspan="100%" class="position-fixed">
                                    <p>
                                        {{ translate('no_companies') }}
                                    </p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="p-3 d-flex justify-content-between">
                        <pagination v-model="currentPage" :amount-of-items="filteredCompanies.length"
                                    :items-per-page="itemsPerPage"></pagination>
                        <div class="d-flex items-center" style="gap:10px">
                            <label for="" class="inline-label">{{translate('rows_per_page')}}</label>
                            <select class="form-control form-control-sm" v-model="itemsPerPage">
                                <option :value="10">10</option>
                                <option :value="25">25</option>
                                <option :value="50">50</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="currentView === View.MAP">
                <cw-map></cw-map>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import {
    CustomField,
    CustomValue,
    RadarCompany, Subset,
} from '../types';
import CompanyRow from './row.vue';
import CheckboxDropdown from '../../shared/checkbox-dropdown.vue';
import Pagination from '../../shared/pagination.vue';
import FilterTh from './filter-boxes/generic.vue';
import CompanyFilterTh from './filter-boxes/company.vue';
import CustomFilterTh from './filter-boxes/custom.vue';
import Drawer from './drawer.vue';
import EditCustomColumnModal from './modals/edit-custom-column.vue';
import {useStore} from '../store';
import LoadingRadar from './loading-radar.vue';
import Translate from '../../mixins/translate';
import QueryString from '../../../lib/QueryString';
import SelectMenu, {SelectMenuItem} from './select-menu.vue';
import Dropdown from '../../shared/dropdown.vue';
import Modal from "../../shared/modal.vue";
import AddToAlerts from "./modals/add-to-alerts.vue";
import CwMap from './map.vue';
import axios from "axios";
import Toast from "../../../lib/Toast";

enum View {
    TABLE,
    MAP
}

export default Translate.extend({
    name: 'overview',
    components: {
        AddToAlerts,
        CwMap,
        Modal,
        Drawer,
        Dropdown, 
        EditCustomColumnModal,
        CheckboxDropdown, 
        CompanyRow,
        Pagination, 
        FilterTh,
        CompanyFilterTh, 
        LoadingRadar,
        CustomFilterTh, 
        SelectMenu
    },
    data() {
        return {
            isLoading: false as boolean,
            isExporting: false as boolean,
            isError: false as boolean,
            currentPage: 1,
            itemsPerPage: 10,
            store: useStore(),
            currentView: View.TABLE,
            editField: null as null | CustomField,
            View,
            Subset,
            subsetOptions: Object.values(Subset).map(v => (
                {
                    id: v,
                    title: this.translate(v),
                    description: this.translate(v + '_description')
                } as SelectMenuItem)),
            isScrolled: false,
            exportCompanyLimit: 2000
        };
    },
    methods: {
        changeToMapView(): void {
            this.currentView = View.MAP;
        },
        showEditCustomColumn(field: CustomField) {
            this.editField = field;
            this.$root.$emit('show-modal', 'editCustomColumnModal');
        },
        exportData(){
            const formData =  new FormData();
            formData.append("vats", this.filteredCompanies.map(fc => fc.vat).join(','));
            formData.append("datasetId", this.store.currentDataset.id.toString());
            this.isExporting = true;
            axios({
                url: '/radar/export',
                method: 'POST',
                data: formData,
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Radar-${this.store.currentDataset.title}-${new Date().toHansDate()}.xlsx`);
                link.click();
                window.URL.revokeObjectURL(url);
                this.isExporting = false;
            }).catch((ex) => { 
                this.isExporting = false;
                Toast.error(ex);
            });
        }
    },
    watch: {
        filteredCompanies(comps): void {
            this.currentPage = 1;
        }
    },
    computed: {
        canExport(): boolean {
            return this.store.allowFullExport || this.store.allFilteredCompanies.length <= this.exportCompanyLimit;  
        },
        datasetOptions(): SelectMenuItem[] {
            return this.store.datasets.map(v => (
                {
                    id: v.id.toString(),
                    title: v.title,
                    description: "",
                    url: "/radar/" + v.id
                } as SelectMenuItem)).sort((a, b) => a.title < b.title ? -1 : 1)
        },
        filteredCompanies(): RadarCompany[] {
            return this.store.shownCompanies;
        },
        normalizedCompanyNameFilter(): string {
            return (this.store.companyNameFilter ?? '')
                .replace(/[\s\D]+/g, "")
                .replace(/\./g, "")
                .replace(/^0/, "");
        },
        shownCompanies(): RadarCompany[] {
            const start: number = this.currentPage * this.itemsPerPage - this.itemsPerPage;
            const end: number = start + this.itemsPerPage;

            return this.filteredCompanies.slice(start, end);
        },
    },
    async mounted() {
        this.isLoading = true;
        try {
            await this.store.getGeneralFile();
            this.store.createNaceTree();
            await this.store.getCompaniesAndFields();
            this.store.useSavedConfig();
        } catch (e: unknown) {
            this.isError = true;
            console.error(e);
        }
        this.isLoading = false;

        const statusFromQueryString = QueryString.getUrlParameter("status") as Subset | null;

        if (statusFromQueryString && Object.values(Subset).includes(statusFromQueryString)) {
            this.store.subsetSelectMenuItem = this.subsetOptions.find(o => o.id === statusFromQueryString)!;
            QueryString.deleteUrlParameter("status");
        } else {
            this.store.subsetSelectMenuItem = this.subsetOptions[0];
        }

        function hasClass(child: HTMLElement, className: string) {
            if (child.classList.contains(className))
                return true;

            let node = child.parentElement;
            while (node !== null) {
                if (node.classList.contains(className))
                    return true;

                node = node.parentElement;
            }
            return false;
        }

        this.$nextTick(() => {
            const slider = this.$refs.slider! as HTMLDivElement;
            let isDown = false;
            let startX: number = 0;
            let scrollLeft: number = 0;

            slider.addEventListener('mousedown', (e) => {
                if (hasClass(e.target as HTMLElement, "sticky")) return;

                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if (hasClass(e.target as HTMLElement, "sticky")) return;

                if (!isDown) return;
                e.preventDefault();
                const x = (e as MouseEvent).pageX - slider.offsetLeft;
                const walk = (x - startX);
                slider.scrollLeft = scrollLeft - walk;
                this.isScrolled = slider.scrollLeft > 0;
            });
        });
    },
});

interface IEditedFieldData {
    companyId: number,
    field: CustomValue
}


</script>

<style lang="scss" scoped>
$primColor: #0368FF;
$hoverColor: #0050C8;

.view-layout {
    display: inline-flex;
    border-radius: 6px;
    border: 1px solid #cdcdcd;
    overflow: hidden;

    > a {
        padding: 8px 18px;
        display: block;
        color: #9b9b9b;
        text-decoration: none;
        font-size: 13px;

        &:not(:first-child) {
            border-left: 1px solid #cdcdcd;
        }


        &.active, &:hover {
            background-color: fade-out($primColor, 0.92);
            color: #0a2439;

            i {
                color: $primColor;
            }

            font-weight: 500;
        }
    }
}

.btn {
    border-radius: 6px !important;
}

table {
    border: 1px solid #efefef;
    border-radius: 4px;
    border-collapse: collapse;

    thead {
        position: sticky;
        top: 0;
        z-index: 2;

        tr {
            th {
                word-break: break-all;
            }

            &.gray {
                background: #f3f3f3;
            }
        }
    }
}

.radar-table-wrapper {
    border: 1px solid #dedede;
    border-radius: 25px;
}

.radar-container {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: initial;
    min-height: 900px;
    cursor: grab;
    padding-bottom: 40px;

    &.active {
        cursor: grabbing;
    }
}

.radar-slider {
    position: relative;
    width: 100%;
    white-space: nowrap;
    transition: all 0.2s;
    user-select: none;
}

.no-data-placeholder {
    max-width: 50%;
    text-align: center;
    margin: 120px auto;
    font-weight: 500;
}

.inline-label {
    display: inline-flex;
    flex-basis: 100%;
    align-items: center;
    font-weight: normal;
    white-space: pre;
    color: grey;
}

.applied-filter {
    border-radius: 6px;
    background: fade-out($primColor, 0.9);
    color: grey;
    padding: 4px 12px;
    display: flex;
    align-items: center;

    &:hover {
        background: fade-out($primColor, 0.85);
        text-decoration: none;
    }

    span {
        &:hover {
            color: black;
        }
    }
}
</style>