﻿import _ from 'lodash';
import Translate from '../lib/Translate';
import Shepherd from 'shepherd.js';
import {Placement} from 'popper.js';
import Page from '../lib/Page';

export default (function () {
    Page.registerBehavior('body', function (element) {

        const steps: HTMLElement[] = Array.from(document.getElementsByClassName('tour-step')) as HTMLElement[];
     
        const pageHasATour = steps.length > 0;
        const pageTooSmall = screen.width < 800;

        function shouldScroll(el: HTMLElement): boolean {
            var position = window.getComputedStyle(el).getPropertyValue('position');
            return position === 'fixed' || position === 'absolute' ? false : true;
        }

        if (!pageHasATour || pageTooSmall)
            return;

        const  tour = new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
                classes: 'shepherd-theme-custom'
            }
        });

        _.forEach(steps, (step: HTMLElement, index: number) => {
            const stepCount: string = (index + 1) + " / " + steps.length;
            const tip: string | null = step.getAttribute('tour-step-tip');
            const hasTip: boolean = tip !== "" && tip !== null;

            const tipHtml: string = hasTip
                ? '<span class="tip d-block mt-3"><span class="d-block font-weight-bold">' + Translate('tip') + '<i class="fas fa-magic companyweb-lightblue ml-2"></i></span>' + tip + '</span>'
                : '';

            tour.addStep( {
                title: step.getAttribute('tour-step-title') ?? '',
                text: step.getAttribute('tour-step-text') + tipHtml + '<span class="step-count">' + stepCount + '</span>',
                attachTo: {
                    element: step,
                    //@ts-ignore
                    on: step.getAttribute('tour-step-position') as Placement ?? 'bottom'
                },
                scrollTo: shouldScroll(step) && !step.getAttribute('tour-step-dontscroll'),
                classes: 'tour-guide',
                buttons: [
                    {
                        text: index === steps.length - 1 ? Translate('sluiten') : Translate('volgende'),
                        action: tour.next
                    }
                ]
            });
        });
            
        tour.start();
    });

}());
