﻿<template>
    <div>
        <alert-day v-for="(group) in eventsPerDate" :date="group.date" :events="group.events" :key="'day_' + group.date">
            
        </alert-day>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import {AlertsEvent, AlertsCompany, ExtendedAlertsEvent} from './types';
    import AlertDay from './alert-day.vue';
    
    export default Vue.extend({
        name: "alert-list",
        components: {AlertDay},
        data() {
            return {
                events: window.cw.events as {[key: number]: AlertsEvent[]},
                companies: window.cw.companies as AlertsCompany[],
            }
        },
        computed: {
            eventsPerDate(): {date: string, events: ExtendedAlertsEvent[]}[] {
                const arr = [] as {date: string, events: ExtendedAlertsEvent[]}[];
                
                Object
                .keys(this.events)
                .map(
                    (id) => this.events[+id].map(e => (
                        {...e, id: +id, company: this.companies.find(c => c.id == +id)} as ExtendedAlertsEvent
                    )))
                .flat()
                .groupBy(e => e.date).forEach((list, key) => {
                    arr.push({
                        date: key.toString(),
                        events: list
                    });
                });
                
                return arr.sort((a,b) => +b.date - +a.date);
            }
        }
    });
</script>