
<div class="btw-file-input-wrapper">
    <div class="text-center m-4" v-if="isLoading">
        <i class="fas fa-spinner spinning text-lightblue fa-lg"></i>
    </div>

    <file-input 
        v-show="!isLoading"
        :multiple="false" 
        :required="required" 
        @input="onFileUpload"
        :modelClosed="modelClosed"
        accept=".xlsx,.csv"  
        :title="translate('upload_vats')" 
        placeholder="xlsx"></file-input>


    <cw-modal :noOverflow="false" :title="title" :id="nameOfModal" size="xl" @onclose="triggerModalClosed()">
        <transition name="slide-fade">
            <div class="row" v-if="show">
                <div class="col-md-12 may-overflow-x">
                    <div class="flexbox-table">
                        <div 
                            class="flexbox-column" 
                            v-for="column in columnPreviews" 
                            :key="column.index" 
                            :class="{'active': column.index === activeColumnIndex}" 
                            @click="activeColumnIndex = column.index">

                            <div class="flexbox-cell">
                                <span>{{column.name|truncate(22)}}</span>
                            </div>
                            <template v-if="column.rows.length > 0">
                                <div class="flexbox-cell" v-for="(row, index) in column.rows" :key="'row-' + column.index + '-' + index">
                                    <span>{{row|truncate(22)}}</span>
                                </div>
                            </template>
                            <div class="flexbox-cell" v-else>
                                {{translate('no_data')}}
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </transition>

        <button class="mt-4 btn btn-info btn-200" @click.prevent="next">{{translate('verdergaan')}}</button>
    </cw-modal>
</div>
