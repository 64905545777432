
<div class="mb-2 mb-lg-3">
    <label>{{label}}</label>
    <div class="result-wrapper">
        <div class="result-bar">
            <div class="inner" :style="{width: width + '%'}"></div> 
            <span class="count">{{amount.formatThousandSeparator()}}</span>
        </div>
    </div>
</div>
