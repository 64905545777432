<template>
    <tr>
        <td class="no-wrap">
            <img :src="'/images/flags/' + company.countryCode + '.svg'" width="18" height="18" class="mr-1" :alt="company.country">
            {{company.country}}
        </td>
        <td>
            <b>
                <a :href="link" class="tr-anchor">{{company.companyName}}</a>
            </b>
        </td>
        <td>{{company.registrationNumber}}</td>
        <td>
            {{company.mostRecentReportDate|toLongDate}}
        </td>
        
    </tr>
</template>

<script lang="ts">
    import Vue from 'vue'
    import { Company, Country } from '../types';
    import DateHelpers from '../../mixins/date';
    import Page from '../../../lib/Page';
    import Ajax from '../../../lib/Ajax';

    export default Vue.extend({
        name: 'company-row',
        props: {
            company: Object as () => Company,
            hasFollowup: Boolean
        },
        mixins: [DateHelpers],
        data() {
            return {
                followupUrl: window.cw.followupUrl as string,
                followupEndDate: this.company.followupEndDate
            }
        }, 
        computed: {
            link(): string {
                return '/international/' + this.company.countryCode  + '/' + this.company.companyKey
            }
        },
        methods: {
            addCompanyToFollowUp(): void {
                Ajax.post(
                    this.followupUrl,
                    {
                        country: this.company?.countryCode,
                        companyId: this.company.companyId,
                        registratieNr: this.company.registrationNumber
                    },
                    (response: AjaxResponse<Company>) => {
                        this.followupEndDate = response.data.followupEndDate;
                    },
                    error => {
                        console.error(error)
                    }
                );
            }
        },
        mounted() {
            Page.applyBehaviors('#international')
        }
    })
</script>

<style scoped>
</style>