﻿import _ from 'lodash';
import Confirm from '../lib/Confirmation';
import Page from '../lib/Page';
import {SweetAlertType } from 'sweetalert2';

export default (function () {
    Page.registerBehavior('[data-confirmation]', function (form: Element) {
        (form as HTMLFormElement).addEventListener('submit', function (e: Event) {
            e.preventDefault();

            const isValid = _.every(Array.from(this.querySelectorAll('[required]')), input => !!(input as HTMLInputElement).value);
           
            if(!isValid)
                return;

            Confirm(
                () => {
                    this.submit();
                },
                {
                    title: this.getAttribute("data-confirmation")!, 
                    body: this.getAttribute("data-confirmation-body")!,
                    type: this.getAttribute('data-confirmation-type') as SweetAlertType,
                    cancelButtonText: this.getAttribute('data-confirmation-btn-cancel') ?? undefined,
                    confirmButtonText: this.getAttribute('data-confirmation-btn-confirm') ?? undefined
                }
            );
        });
    });
}());
