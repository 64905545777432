﻿export default {
    getParameter(uri: string, key: string): string | null {
        key = key.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + key + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(uri);

        if (!results) return null;
        if (!results[2]) return '';

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    updateParameter(uri: string, key: string, value: string): string {
        const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");

        const separator = uri.indexOf('?') !== -1 ? "&" : "?"
        if (uri.match(re))
            return uri.replace(re, '$1' + key + "=" + value + '$2');

        return uri + separator + key + "=" + value;

    },
    deleteParameter(uri: string, key: string): string {
        const urlParts = uri.split('?');
        if (urlParts.length >= 2) {

            const prefix = encodeURIComponent(key) + '=';
            const pars = urlParts[1].split(/[&;]/g);

            for (let i = pars.length; i-- > 0;) {
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            return urlParts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        
        return uri;
    },
    deleteUrlParameter(key: string) {
        const newUrl = this.deleteParameter(window.location.href, key);
        window.history.pushState('data', newUrl, newUrl);
    },
    getUrlParameter(key: string): string | null {
        return this.getParameter(window.location.href, key);
    },
    updateUrlParameter(key: string, value: string) {
        const newUrl = this.updateParameter(window.location.href, key, value);
        window.history.pushState('data', newUrl, newUrl);
    }
}