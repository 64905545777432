﻿<template>
    <div class="position-relative" style="z-index: 0">
        <p class="tip warning" v-if="tooManyMarkers">{{translate('radar_map_selection_too_large')}}</p>
        <div id="map" style="height: 70vh;"></div>


        <link rel="stylesheet" href="https://unpkg.com/leaflet.markercluster@1.3.0/dist/MarkerCluster.css">
        <link rel="stylesheet" href="https://unpkg.com/leaflet.markercluster@1.3.0/dist/MarkerCluster.Default.css">
    </div>
</template>

<script lang="ts">
import _ from "lodash";
import {Adres} from "../../../types/dto/Adres";
import {useStore} from "../store";
import * as L from 'leaflet';
import {RadarCompany} from "../types";
import 'leaflet/dist/leaflet.css';
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.fullscreen/Control.FullScreen.js";
import "leaflet.fullscreen/Control.FullScreen.css";
//@ts-ignore
import { MarkerCluster } from 'leaflet.markercluster';
import Translate from '../../mixins/translate';

enum AddressType {
    ZETEL = "maatschappelijke_zetel",
    VESTIGING = "vestiging"
}
interface MarkerAddress {
    adres: AddressWithType;
    vat: number;
    companyName: string;
    vorm: string;
    score: number;
}

interface AddressWithType extends Adres {
    type: AddressType
}

export default Translate.extend({
    name: "radar_map",
    data() {
        return {
            store: useStore(),
            map: {} as L.Map
        }
    },
    watch: {
        filteredCompanies(val){
            this.addMarkers();
        }  
    },
    computed: {
        onlyShowMarkersInRegion(): boolean {
            return this.store.indexFile.hasOnlyLocationCriteria;  
        },
        filteredCompanies(): RadarCompany[] {
            return this.store.allFilteredCompanies;  
        },
        postcodes(): string[] {
            return _.uniq(this.store.companies.map(c => c.postCode.value.toString()));
        },
        markerAddresses(): MarkerAddress[] {
            const zipCodes = this.store.indexFile.zipCodesFromCriteria ?? [];
            
            return this.filteredCompanies.flatMap(comp => {
                const establishmentAddresses = this.onlyShowMarkersInRegion ? comp.vestigingsAdressen.map(c => ({...c, type: AddressType.VESTIGING})) : [];
                
                const addresses = establishmentAddresses
                    .concat({...comp.zetelAddress, type: AddressType.ZETEL})
                    .filter(adres => !!adres.coordinates && !!adres.coordinates.latitude)
                    .filter(adres => !this.onlyShowMarkersInRegion || zipCodes.includes(+adres.postcode));
                
                return addresses.map(a => ({
                    companyName: comp.companyName,
                    adres: a,
                    vat: comp.vat,
                    vorm: comp.juridischeVormTranslated,
                    score: comp.totalFraudScore.value
                } as MarkerAddress))
            });
        },
        bounds(): L.LatLngBounds {
            const latLongArray = this.markerAddresses.map(a => [a.adres.coordinates?.latitude!, a.adres.coordinates?.longitude!] as L.LatLngTuple);
            return new L.LatLngBounds(latLongArray);
        },
        tooManyMarkers(): boolean {
            return this.filteredCompanies.length > 50_000;
        }
    },
    methods: {
        scoreToClassName(score: number): string {
            if(score == 0)
                return "green";
            if(score <= 4)
                return "lime"
            if(score <= 9)
                return "yellow"
            if(score <= 14)
                return "orange"
            
            return "red";
        },
        addMarkers(): void {
            this.map.eachLayer(l => l.removeFrom(this.map));
            if(this.tooManyMarkers)
                return;
            
            const markers = L.markerClusterGroup({
                iconCreateFunction: (cluster: MarkerCluster) => {
                    const childMarkers = cluster.getAllChildMarkers();
                    const max = _.max(childMarkers.map(m => +m.options.icon!.options.className!));
                 
                    return L.divIcon({ 
                        html: '<div>' + cluster.getChildCount() + '</div>', 
                        className: "cluster " + this.scoreToClassName(+max)
                    });
                }
            });
            this.markerAddresses.forEach(markerAddress => {
                const markerColor = this.scoreToClassName(markerAddress.score);
                
                const addressTypeToIcon = (type: AddressType): string => {
                    if(type === AddressType.VESTIGING)
                        return "store";
                    else
                        return "chair";
                };
                
                const marker = L
                    .marker([markerAddress.adres.coordinates?.latitude!, markerAddress.adres.coordinates?.longitude!], {
                        icon:L.icon({
                            iconAnchor: [10, 41],
                            popupAnchor: [2, -40],
                            iconUrl: '/images/markers/radar/'+markerColor + '-' + addressTypeToIcon(markerAddress.adres.type) +'.png', 
                            className: markerAddress.score.toString(), //used to calc cluster score
                        }),
                        
                    });
                marker.bindPopup(
                    `<div class="row"><div class="col-md-6 font-weight-bold">${this.translate('companyname')}</div><div class="col-md-6"><a class="link" target="_blank" href="/company/${markerAddress.vat}">${markerAddress.companyName + " (" + markerAddress.vorm + ")"}</a></div></div>  
                        <div class="row mt-1"><div class="col-md-6 font-weight-bold">${this.translate('vat')}</div><div class="col-md-6">${markerAddress.vat.formatVat()}</div></div>
                        <div class="row mt-1"><div class="col-md-6 font-weight-bold">${this.translate('address')}</div><div class="col-md-6">${markerAddress.adres.straatNaam} ${markerAddress.adres.huisNummer}, ${markerAddress.adres.postcode} ${markerAddress.adres.gemeente}</div></div>  
                        <div class="row mt-1"><div class="col-md-6 font-weight-bold">${this.translate('type_location')}</div><div class="col-md-6">${this.translate(markerAddress.adres.type)}</div></div>
                        <div class="row mt-1"><div class="col-md-6 font-weight-bold">Score</div><div class="col-md-6">${markerAddress.score}</div></div>
                    `, {
                        minWidth: 300
                    });

                markers.addLayer(marker);
            });

            
            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            }).addTo(this.map);
            this.map.addLayer(markers);

            this.map.fitBounds(this.bounds);
        }
    },
    mounted() {
        this.$nextTick(() => {
            const map = L.map('map', {
                fullscreenControl: true,
                fullscreenControlOptions: {
                    position: 'topright',
                    forceSeparateButton:true
                }
            }).setZoom(13);
            this.map = map;
            this.addMarkers();
        });
    }
});
</script>

<style lang="scss">
.cluster {
    width: 40px !important;
    height: 40px !important;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -20px !important;
    margin-top: -20px !important;
    &.green {
        background-color: fade-out(#4CAF50, 0.7);
        div {
            background-color: #4CAF50;
        }
    }
    &.lime {
        background-color: fade-out(#94B633, 0.7);
        div {
            background-color:  #94B633;
        }
    }
    &.yellow {
        background-color: fade-out(#f1d01c, 0.7);
        div {
            background-color:  #f1d01c;
        }
    }
    &.orange {
        background-color:  fade-out(#F9821E, 0.7);
        div {
            background-color:  #F9821E;
        }
    }
    &.red {
        background-color:  fade-out(#F44336, 0.7);;
        div {
            background-color:  #F44336;
        }
    }
}
.cluster div {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    text-align: center;
    font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
}

.fullscreen-icon {
    background-image: url("/images/icon-fullscreen.svg");
}
</style>