<template>
    <div>
        <h2 class="mb-4"><b>{{translate("sidebar_location")}}</b></h2>

        <div class="row mb-5">
            <div class="col-md-12">
                <h3>{{translate("postcodes")}}</h3>
                <form class="row" @submit.prevent="addPostcodeRange()">
                    <div class="col-5 col-md-4">
                        <input type="number" min="1000" max="9999" class="form-control" v-model.number="lowerbound" />
                    </div>
                    <div class="col-2 col-md-1 flex-h-center my-auto font-weight-bold">
                        {{translate("tot_en_met")}}
                    </div>
                    <div class="col-5 col-md-4">
                        <input type="number" min="1000" max="9999" class="form-control" v-model.number="upperbound" />
                    </div>
                    <div class="col-md-3 mt-3 mt-md-0">
                        <button class="btn btn-default btn-block" type="submit">{{translate("btn_add")}}</button>
                    </div>
                </form>
                <div class="card-box small warning mt-4" v-if="($v.lowerbound.$invalid && $v.lowerbound.$dirty) || ($v.upperbound.$invalid && $v.upperbound.$dirty)">
                    {{translate("validation_error_postal_codes_format")}}
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 mb-3 mb-md-0">
                <h3>{{translate("gewesten_en_provincies")}}</h3>
                <select-tree always-open
                            :isParentSelected="false"
                             :criteria="treeData.gewest"
                             :currentRequest="currentRequest"
                             :subType="ProspectieDataSelectionCriteriumType.PROVINCIE"
                             :type="ProspectieDataSelectionCriteriumType.GEWEST"></select-tree>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { ProspectieDataRangeOperator } from '../../../../types/dto/ProspectieDataRange';
    import { ProspectieDataSelectionCriteriumType } from '../../../../types/dto/ProspectieDataSelectionCriteriumType';
    import CriteriumRange from '../../criteriumRange';
    import DataMarketingService, { Request } from '../../dataMarketingService';
    import SelectTree from '../shared/select-tree.vue';
    import Translate from '../../../mixins/translate';
    import mixins from 'vue-typed-mixins';

    export default mixins(Translate).extend({
        name: 'dm-location',
        components: {
             SelectTree
        },
        data() {
            return {
                ProspectieDataSelectionCriteriumType,
                treeData: DataMarketingService.getInstance().treeData!,
                lowerbound: undefined as number | undefined,
                upperbound: undefined as number | undefined
            }
        },
        validations: {
            lowerbound: {
                lowerbound(val, {upperbound}){
                    return (!upperbound && !!val) || (!!upperbound && !val) || upperbound > val
                }
            },
            upperbound: {
                upperbound(val, {lowerbound}) {
                    return (!lowerbound && !!val) || (!!lowerbound && !val) || lowerbound < val
                }
            }
        },
        computed: {
            currentRequest(): Request{
                return DataMarketingService.getInstance().currentRequest;
            }
        },
        methods: {
            async addPostcodeRange() {
                this.$v.$touch();
                if (this.$v.$invalid)
                    return;
                // makes sure both lower and upperbound are the same when undefined.
                // this way we make sure we get the EQUAL TO operator in the constructor.
                const range = new CriteriumRange(
                    ProspectieDataRangeOperator.BETWEEN,
                    ProspectieDataSelectionCriteriumType.POSTCODE,
                    this.lowerbound || this.upperbound,
                    this.upperbound || this.lowerbound
                );

                await this.currentRequest.addRange(range);
                await this.currentRequest.updateSummary();
                this.$v.$reset();

                this.lowerbound = undefined;
                this.upperbound = undefined;
            }
        }
    })
</script>

<style scoped>
</style>