﻿<template>
    <div class="container">
        <div class="row">
            <div class="col-6 col-lg-8">
                <h1>
                    {{currentList.Naam}}
                </h1>
                <button type="button"
                        class="btn btn-blue btn-sm"
                        v-if="showSampleMail"
                        v-on:click.prevent="openModal">
                    <i class="fas fa-envelope"></i>&nbsp; Demo Mail
                </button>
                <modal id="alerts-demo-mail" title="Alerts demo mail" v-cloak size="lg">
                    <div class="row">
                        <div class="col-md-12">
                            <img :src="'/images/demo/alerts-example-mail-' + language + '.jpg'" alt="Alerts" class="img-fluid"/>
                        </div>
                    </div>
                </modal>
            </div>
            <div class="col-6 col-lg-4 text-right">
                <div class=" menu-items-wrapper">
                    <div class="title">
                        Alerts
                    </div>
                    <div class="menu-items">
                         <a href="" class="menu-item" :class="{'active': activeSubPage === 'events'}" @click.prevent="activateTab('events')">
                            <i class="fas fa-exchange-alt mr-2"></i> {{translate('changes')}}
                        </a>
                       <a href="" class="menu-item" :class="{'active': activeSubPage === 'list-management'}" @click.prevent="activateTab('list-management')">
                            <i class="fas fa-tasks mr-2"></i> {{translate('list_management')}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <component :is="activeSubPage"
                   :current-list="currentList"
                   :users="users"></component>

        <modal :title="translate('add_companies')" id="addCompanies" size="lg">
            <add-companies :current-list="currentList"></add-companies>
        </modal>
    </div>
</template>
<script>
    import Modal from '../shared/modal.vue';
    import Events from './events.vue';
    import ListManagement from './list-management.vue';
    import AddCompanies from './add-companies.vue';
    import Ajax from 'Lib/Ajax';
    import Translate from 'Mixins/translate';
    import _ from 'lodash';
    import QueryString from "../../lib/QueryString";

    export default {
        name: 'opvolging-wrapper',
        components: {
            'modal': Modal,
            'events': Events,
            'list-management': ListManagement,
            'add-companies': AddCompanies
        },
        mixins: [Translate],
        props: {
            listId: Number,
            currentList: Object,
            users: Array
        },
        data() {
            return {
                activeSubPage: '',
                showSampleMail: window.cw.showSampleMail,
                language: window.cw.language
            }
        },
        watch: {
            listId(val) {
                this.activeSubPage = 'events';
            }
        },
        methods: {
            activateTab(tabName) {
                this.activeSubPage = tabName;
                this.$root.$emit('change-state', {
                    listId: this.listId,
                    tabName
                });
            },
            updateList() {
                return new Promise((resolve, reject) => {
                    Ajax.get(
                        '/ajax/followup',
                        {
                            listId: this.currentList.ListId
                        },
                        (response) => {
                            let companies = response.Data.Companies;
                            let persons = response.Data.Persons;
                            let addresses = response.Data.Addresses;
                            let events = response.Data.FollowUpEvents;
                            this.$root.$emit('updateList', {
                                listId: this.currentList.ListId,
                                companies,
                                persons,
                                addresses,
                                events
                            });
                            resolve();
                        },
                        () => {
                            reject();
                        }
                    );
                });
            },
            openModal() {
                this.$root.$emit('show-modal', 'alerts-demo-mail');
            }
        },
        created() {
            if (location.pathname.indexOf('list-management') !== -1)
                this.activeSubPage = 'list-management';
            else
                this.activeSubPage = 'events';
            
            this.$root.$on('change-tab', tabName => {
                this.activeSubPage = tabName;
            });

            const modal = QueryString.getUrlParameter("modal");
           
            this.$nextTick(() => {
                if(modal === "addCompanies") {
                    this.$root.$emit("show-modal", "addCompanies");
                    QueryString.deleteUrlParameter("modal");
                }
            });
        }
    }
</script>
