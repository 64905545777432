﻿<template>
    <div class="buffer-scores" v-if="score !== 0">
        <div class="buffer-label">{{label}}<i class="fas fa-info-circle ml-1" v-if="tooltip" :data-content="tooltip"></i></div>

        <div class="buffer-score" :class="{'active very-negative': (score == 1)}"><i class="fas fa-minus icoon-score"></i><i class="fas fa-minus icoon-score"></i></div>
        <div class="buffer-score" :class="{'active negative': (score == 2)}"><i class="fas fa-minus icoon-score"></i></div>
        <div class="buffer-score" :class="{'active neutral': (score == 3)}">0</div>
        <div class="buffer-score" :class="{'active positive': (score == 4)}"><i class="fas fa-plus icoon-score"></i></div>
        <div class="buffer-score" :class="{'active very-positive': (score == 5)}"><i class="fas fa-plus icoon-score"></i><i class="fas fa-plus icoon-score"></i></div>
    </div>
</template>

<script>
    export default {
        name: 'buffer-score',
        props: {
            score: Number,
            label: String,
            tooltip: String
        }
    }
</script>

<style scoped>
    .icoon-score {
        margin: 0 2px;
        font-size: 9px;
    }
    .buffer-scores {
        display: flex;
        border-bottom: 1px solid #dee2e6;
    }
    .buffer-scores:last-of-type{
        border-bottom: 0;
    }
    .buffer-label {
        font-weight: bold;
        width: 180px;
        line-height: 2.2em;
    }
    .buffer-score {
        width: 50px;
        padding: 2px;
        text-align: center;
        margin: 3px;
        border-radius: 3px;
        color: #757575;
    }
    @media only screen and (max-width : 576px) {
        .buffer-score {
            width: 28px;
            padding: 1px;
            margin: 2px;
            line-height: 1.6em;
        }
    }
    .buffer-score.active{font-weight: bold; color: white;}
    .buffer-score.very-negative{background-color: #F44336;}
    .buffer-score.negative{background-color: #F9821E;}
    .buffer-score.neutral{background-color: #f1d01c;}
    .buffer-score.positive{background-color: #94B633;}
    .buffer-score.very-positive{background-color: #4CAF50;}
</style>