import Vue from 'vue';
import Page from '../../lib/Page';
import IntegrationsDiscover from './pages/discover.vue';
import IntegrationsDetail from './pages/detail.vue';
import IntegrationsManage from './pages/manage.vue';
import Vuelidate from 'vuelidate';
import { SoftwarePartner } from '../../types/dto/SoftwarePartner';
import Gallery from '../shared/gallery.vue';

Vue.use(Vuelidate)

export default (function () {
    Page.registerVue('#integrations', function (rootSelector) {
        new Vue({
            el: rootSelector,
            name: 'integrations_wrapper',
            components: {
                IntegrationsDiscover,
                IntegrationsDetail,
                IntegrationsManage,
                Gallery
            },
            data() {
                return {
                    images: (window.cw.integration as SoftwarePartner)?.screenshots ?? [] as string[],
                    index: null
                }
            }
        });
    });
}());
