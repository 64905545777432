﻿<template>
    <div class="row">
        <div class="col-md-12">
            <p>
                Heeft u buitenlandse klanten of prospecten? Vermijd risico’s en wanbetalers nu ook bij internationale klanten.
            </p>
            <p>
                U kan vanaf nu ook over de landsgrenzen heen genieten van de voordelen van Companyweb.
                Vind snel informatie en data over de financiële gezondheid van bedrijven in Nederland, Frankrijk, Luxemburg, Verenigd Koninkrijk, Spanje en Italië.<br />
                We blijven deze lijst verder uitbreiden.
            </p>

            <h4><b>Onze voordelen</b></h4>
            <ul class="check-list">
                <li>U kent de kwaliteit van Companyweb</li>
                <li>Onmiddellijk beschikbaar</li>
                <li>Financiële voorkennis is geen vereiste</li>
                <li>Licht uw buitenlandse klanten door</li>
                <li>Bovendien kan je makkelijk bedrijven uit Nederland en Frankrijk opvolgen. Zo blijf je automatisch op de hoogte van de meest recente informatie</li>
            </ul>
        </div>
        <div class="col-md-12">
            <img src="/images/aanbod/internationaal-rapport.png" width="300" class="img-fluid" />
            <img src="/images/aanbod/international-reports2.png" width="300" class="img-fluid" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'feature-international-reports'
    }
</script>