
<div>
    <div class="box mb-5">
        <h2 class="box-title">Conversie criteria</h2>

        <form id="form" action="/lead-candidates/summary" method="POST" @submit.prevent="convertVisitsToLeads">
            <h2>Algemeen</h2>
            <div class="form-group">
                <label for="coolDownInDays">Lead cooldown (in dagen)</label>
                <input id="coolDownInDays" v-model="form.coolDownInDays" class="form-control" type="number"/>
            </div>
            <div class="form-group">
                <label for="maxAgeInDays">Moet minstens ... dagen geleden nog zijn langsgeweest</label>
                <input id="maxAgeInDays" v-model="form.maxAgeInDays" class="form-control" type="number"/>
            </div>
            <div class="form-group">
                <label for="minVisitedCompanyPages">Mininum aantal bezochte bedrijfspagina's</label>
                <input id="minVisitedCompanyPages" v-model="form.minVisitedCompanyPages" class="form-control" type="number"/>
            </div>
            <div class="form-group">
                <label for="minDurationOnCompanyPages">Mininum bezoekduur op bedrijfspagina's</label>
                <input id="minDurationOnCompanyPages" v-model="form.minDurationOnCompanyPages" class="form-control" type="number"/>
            </div>

            <div class="row my-5">
                <div class="col-4">
                    <h2>Nederlandse leads</h2>
                    <div class="form-group">
                        <input id="nl_hasBoid" v-model="form.nlCriteria.hasBoid" class="form-check-input" type="checkbox"/>
                        <label for="nl_hasBoid">Moet matchen aan bestaande BOID?</label>
                    </div>
                    <div class="form-group">
                        <input id="nl_hasNoBoid" v-model="form.nlCriteria.hasNoBoid" class="form-check-input" type="checkbox"/>
                        <label for="nl_hasNoBoid">Mag niet matchen aan bestaande BOID?</label>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-6">
                                <label for="nl_minFte">Mininum FTE's</label>
                                <input id="nl_minFte" v-model="form.nlCriteria.minFte" class="form-control" min="0" type="number"/>
                            </div>
                            <div class="col-6">
                                <label for="nl_maxFte">Maximum FTE's</label>
                                <input id="nl_maxFte" v-model="form.nlCriteria.maxFte" class="form-control" min="0" type="number"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="nl_score">Mininum Score</label>
                        <input id="nl_score" v-model="form.nlCriteria.minScore" class="form-control" max="50" min="-50" step="1" type="number"/>
                    </div>
                    <div class="form-group">
                        <label for="nl_minTradeReceivables">Mininum Handelsvorderingen</label>
                        <input id="nl_minTradeReceivables" v-model="form.nlCriteria.minTradeReceivables" class="form-control" min="0" step="1" type="number"/>
                    </div>
                </div>
                <div class="col-4">
                    <h2>Franse leads</h2>
                    <div class="form-group">
                        <input id="fr_hasBoid" v-model="form.frCriteria.hasBoid" class="form-check-input" type="checkbox"/>
                        <label for="fr_hasBoid">Moet matchen aan bestaande BOID?</label>
                    </div>
                    <div class="form-group">
                        <input id="fr_hasNoBoid" v-model="form.frCriteria.hasNoBoid" class="form-check-input" type="checkbox"/>
                        <label for="fr_hasNoBoid">Mag niet matchen aan bestaande BOID?</label>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-6">
                                <label for="fr_minFte">Mininum FTE's</label>
                                <input id="fr_minFte" v-model="form.frCriteria.minFte" class="form-control" min="0" type="number"/>
                            </div>
                            <div class="col-6">
                                <label for="fr_maxFte">Maximum FTE's</label>
                                <input id="fr_maxFte" v-model="form.frCriteria.maxFte" class="form-control" min="0" type="number"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="fr_score">Mininum Score</label>
                        <input id="fr_score" v-model="form.frCriteria.minScore" class="form-control" max="50" min="-50" step="1" type="number"/>
                    </div>
                    <div class="form-group">
                        <label for="fr_minTradeReceivables">Mininum Handelsvorderingen</label>
                        <input id="fr_minTradeReceivables" v-model="form.frCriteria.minTradeReceivables" class="form-control" min="0" step="1" type="number"/>
                    </div>
                </div>
                <div class="col-4">
                    <h2>Duitse leads</h2>
                    <div class="form-group">
                        <input id="de_hasBoid" v-model="form.deCriteria.hasBoid" class="form-check-input" type="checkbox"/>
                        <label for="de_hasBoid">Moet matchen aan bestaande BOID?</label>
                    </div>
                    <div class="form-group">
                        <input id="de_hasNoBoid" v-model="form.deCriteria.hasNoBoid" class="form-check-input" type="checkbox"/>
                        <label for="de_hasNoBoid">Mag niet matchen aan bestaande BOID?</label>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-6">
                                <label for="fr_minFte">Mininum FTE's</label>
                                <input id="de_minFte" v-model="form.deCriteria.minFte" class="form-control" min="0" type="number"/>
                            </div>
                            <div class="col-6">
                                <label for="de_maxFte">Maximum FTE's</label>
                                <input id="de_maxFte" v-model="form.deCriteria.maxFte" class="form-control" min="0" type="number"/>
                            </div>
                        </div>   
                    </div>
                    <div class="form-group">
                        <label for="fr_score">Mininum Score</label>
                        <input id="de_score" v-model="form.deCriteria.minScore" class="form-control" max="50" min="-50" step="1" type="number"/>
                    </div>
                    <div class="form-group">
                        <label for="de_minTradeReceivables">Mininum Handelsvorderingen</label>
                        <input id="de_minTradeReceivables" v-model="form.deCriteria.minTradeReceivables" class="form-control" min="0" step="1" type="number"/>
                    </div>
                </div>
            </div>

            <div class="text-center" v-if="isLoading">
                LOADING...
            </div>
            <div v-else class="d-flex align-items-center justify-content-between">
                <button class="btn btn-info mt-4" type="submit"><i class="fas fa-sync mr-2"></i> Bekijk conversie</button>
                <div class="text-center">
                    <ul>
                        <li><b>{{amountOfLeadsNl}}</b> NL leads</li>
                        <li><b>{{amountOfLeadsFr}}</b> FR leads</li>
                        <li><b>{{amountOfLeadsDe}}</b> EN leads</li>
                    </ul>
                </div>
                <a :disabled="!amountOfLeads" class="btn btn-success" href="" @click.prevent.stop="insertLeads">Leads converteren</a>
            </div>
        </form>
    </div>

    <table v-if="amountOfLeads > 0" class="mt-4 table data-table table-striped">
        <thead>
        <tr>
            <th>vat</th>
            <th>companyName</th>
            <th>language</th>
            <th>visits</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="lead in leads">
            <td>{{ lead.vat }}</td>
            <td>{{ lead.companyName }}</td>
            <td>{{ lead.language }}</td>
            <td>{{ lead.visits }}</td>
        </tr>
        </tbody>
    </table>
</div>
