﻿<template>
    <tr role="row">
        <td class="p-0 border-0">
            <div  class="timetable-row company-row">
                <table class="table table-small">
                    <tr>
                        <td colspan="2">
                            <div class="company">
                                <a :href="company.Link" class="companyname">
                                    <b>{{company.Naam|truncate(40)}} ({{company.Vorm}})</b>
                                </a>
                                <div class="vat text-lightgrey">
                                    {{vat|formatVat}}
                                    <a :href="company.Link" class="btn btn-transparent btn-sm d-inline-block ml-1" target="_blank">
                                        <i class="fas fa-external-link-alt ext-link-icon"></i>
                                    </a>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <event-row v-for="(event, index) in events" :key="company.Vat+'-event-'+index" v-bind="event"></event-row>
                </table>
            </div>
        </td>
    </tr>
</template>

<script>
    import EventRow from './event-row.vue';
    import Truncate from 'Mixins/truncate';
    import _ from 'lodash';

    export default {
        name: 'company-event',
        props: {
            events: Array,
            vat: Number,
            currentList: Object
        },
        mixins: [Truncate],
        components: {
            EventRow
        },
        computed: {
            company() {
                return _.find(this.currentList.Companies, company => company.Vat == this.vat);
            }
        },
        filters: {
            formatVat(vat) {
                return vat.toString().replace(/^(\d{3})(\d{3})(\d{3})$/, 'BE 0$1.$2.$3');
            }
        }
    }
</script>

<style scoped>
    .companyname {
        font-size: 15px;
    }
    .vat {
        font-size: 13px;
    }
    .ext-link-icon {
        font-size: 10px;
        vertical-align: top;
    }
</style>