﻿<template>
    <g :transform="transform" v-if="show">
        <circle x="-2" y="-2" r="2" :fill="color|toHexCode"></circle>
        <circle x="-6" y="-6" r="6" :fill="hoverColor|toHexCode" @mouseout="hideInfo()"  @mouseover="showInfo()" style="cursor: pointer;"></circle>
    </g>
</template>

<script>
    import gsap from 'gsap';

    export default {
        name: 'chart-node',
        inject: ['color', 'valueToYPos'],
        props: {
            value: Number,
            year: String || Number,
            index: Number
        },
        data() {
            return {
                tween: {
                    x: 0,
                    y: 0
                },
                hoverColor: 'transparent'
            };
        },
        watch: {
            x: function (val) {
                gsap.to(this.tween, 0.6, { x: val });
            },
            y: function (val) {
                gsap.to(this.tween, 0.6, { y: val });
            }
        },
        computed: {
            x() { return this.index * this.$root.yearWidth },
            y() {
                return this.valueToYPos(this.value);
            },
            show() {
                return !isNaN(this.value);
            },
            transform() {
                return 'translate(' + this.tween.x + ', ' + this.tween.y + ')';
            }
        },
        methods: {
            showInfo() {
                this.hoverColor = this.color;
                this.$root.$emit('start-hover', {
                    x: this.x,
                    y: this.y,
                    value: this.value,
                    bgColor: this.color
                });
            },
            hideInfo() {
                this.hoverColor = 'transparent';
                this.$root.$emit('end-hover');
            }
        },
        created() {
            this.tween.x = this.x;
            this.tween.y = this.y;
        }
    }
</script>