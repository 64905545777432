
<div>
    <input v-model="filterTerm" class="form-control" :placeholder="translate('zoekterm')" type="search"/>
    <div class="filter-overflow-box mt-4" :class="{'limited-height':limitedHeight}">
        <ul>
            <li v-for="filter in shownFilterValues" :key="filter">
                <input :id="filter"
                       :checked="column.filterCriteria._value.includes(filter)"
                       class="form-check-input azure"
                       type="checkbox"
                       @change="toggleFilter(column, filter)"/>
                <label :for="filter" class="wrap-normal d-block"><span>{{ filter }}</span></label>
            </li>
        </ul>
        <a class="link mt-2 d-inline-block" v-if="maxShowCount <= shownFilterValues.length" href="" @click.prevent="maxShowCount+=100">Meer laden</a>
    </div>
</div>
