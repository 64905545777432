﻿import _ from 'lodash';
import Vue from 'vue';
import Translate from 'Lib/Translate';
import Page from 'Lib/Page';
import ChartCategory from './chart-category.vue';
import ChartYear from './chart-year.vue';
import ChartYAxisSeparator from './chart-y-axis-separator.vue';
import NodeInfo from './node-info.vue';

export default (function () {
    Page.registerVue('#kerncijfers', function (rootSelector) {
        Vue.filter('toHexCode',
            function (colorString) {
                var colorMap = {
                    'yellow': '#FFC107',
                    'red': '#e91e63',
                    'blue': '#007bff',
                    'teal': '#20c997',
                    'orange': '#ff5722',
                    'transparent': 'transparent'
                };

                var mappedColor = _.find(colorMap, function (val, key) {
                    return colorString.indexOf(key) === 0;
                });

                return mappedColor || '#cccccc';
            }
        );

        Vue.filter('formatEuro',
            function (value, hasMillions) {
                var formattedValue = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

                if (hasMillions) {
                    return formattedValue
                        .replace(/(.+)? 000 000$/, '$1 M')
                        .replace(/^(-)?(\d)00 000$/, '$10,$2 M')
                        .replace(/^(-)?(\d+)(?:\s+)?(\d)00 000$/, '$1$2,$3 M');
                }

                return '€ ' + formattedValue;
            }
        );

        Math.log10 = function (x) {
            if (!(x > 0 && x < 1 / 0)) {
              return Math.log(x) * Math.LOG10E;
            }
            var n = Math.round(Math.log(Math.min(Math.max(x, 10 / Number.MAX_VALUE), Number.MAX_VALUE / 10)) * Math.LOG10E);
            return n + Math.log(x / Math.pow(10, n)) * Math.LOG10E;
          }
        
        new Vue({
            el: rootSelector,
            components: {
                'chart-category': ChartCategory,
                'chart-year': ChartYear,
                'chart-y-axis-separator': ChartYAxisSeparator,
                NodeInfo
            },
            provide() {
                return {
                    containerHeight: this.containerHeight,
                    valueToYPos: this.valueToYPos
                };
            },
            data: {
                kerncijfers: window.cw.kernCijfers,
                containerHeight: 400,
                containerWidth: 0, //gets defined in mounted hook based on available space.
                margin: 25,
                yAxisWidth: 120,
                chartData: {},
                currentView: 'table'
            },
            computed: {
                hasMillions() { return _.some(this.activeChartValues, function (v) { return v > 1000000 || v < -1000000; }); },
                chartHeight() { return this.containerHeight - (this.margin * 2); },
                chartWidth() { return this.containerWidth - this.yAxisWidth - 30; },
                yearWidth() { return Math.abs(this.chartWidth / (this.years.length - 1)); },
                maxYears() { return this.chartWidth <= 400 ? 4 : this.chartWidth <= 800 ? 6 : 12;},
                years() { return _.takeRight(_.keys(this.kerncijfers), this.maxYears); },
                winstValuesPerYear() {
                    return _.pick(
                        _.mapValues(this.kerncijfers, year => parseInt(year.winst.replace(/[^0-9-]+/g, ''))),
                        this.years
                    );
                },
                omzetValuesPerYear() {
                    return _.pick(
                        _.mapValues(this.kerncijfers, year => parseInt(year.omzet.replace(/[^0-9-]+/g, ''))),
                        this.years
                    );
                },
                brutoMargeValuesPerYear() {
                    return _.pick(
                        _.mapValues(this.kerncijfers, year => parseInt(year.bruto_marge.replace(/[^0-9-]+/g, ''))),
                        this.years
                    );
                },
                eigenVermogenValuesPerYear() {
                    return _.pick(
                        _.mapValues(this.kerncijfers, year => parseInt(year.eigen_vermogen.replace(/[^0-9-]+/g, ''))),
                        this.years
                    );
                },
                activeChartValues() {
                    return _.flatMap(
                        _.filter(
                            this.chartData, kc => {
                                return kc.isActive && !kc.hasNoValues;
                            }
                        ),
                        kc => {
                            return _.map(kc.valuesPerYear);
                        }
                    );
                },
                lowerBound() {
                    return _.min(this.activeChartValues);
                },
                upperBound() {
                    return _.max(this.activeChartValues);
                },
                valueRange() {
                    return this.upperBound - Math.min(this.lowerBound, 0);
                },
                precision() {
                    if (!this.valueRange)
                        return;

                    var amountOfZeros = Math.floor(Math.log10(Math.abs(this.valueRange)));
                    var precisionInPowerOfTen = Math.pow(10, amountOfZeros);
                    var unit = Math.ceil(this.valueRange / precisionInPowerOfTen);

                    return unit * (precisionInPowerOfTen / 10);
                },
                upperAxisLimit() {
                    return this.roundUp(this.upperBound, this.precision);
                },
                lowerAxisimit() {
                    return this.roundDown(Math.min(this.lowerBound, 0), this.precision);
                },
                yAxisValues() {
                    if (this.upperAxisLimit === null || this.upperAxisLimit === undefined)
                        return;

                    return _.range(
                        this.lowerAxisimit,
                        this.upperAxisLimit + this.precision,
                        this.precision
                    );
                },
                range() {
                    return this.upperAxisLimit - this.lowerAxisimit;
                }
            },
            methods: {
                toggleView() {
                    this.currentView = this.currentView === 'table'
                        ? 'chart'
                        : 'table';
                },
                roundUp(num, precision) {
                    return Math.ceil(num / precision) * precision;
                },
                roundDown(num, precision) {
                    return Math.floor(num / precision) * precision;
                },
                valueToYPos(val) {
                    var heightPercentage = (val + Math.abs(_.min(this.yAxisValues))) / this.range;

                    return this.chartHeight - heightPercentage * this.chartHeight;
                },
                createChartData() {
                    this.chartData = {
                        winst: {
                            valuesPerYear: this.winstValuesPerYear,
                            isActive: true,
                            label: Translate('label_kerncijfers_winst'),
                            color: 'red',
                            hasNoValues: _.every(this.kerncijfers, year => year.winst === "")
                        },
                        omzet: {
                            valuesPerYear: this.omzetValuesPerYear,
                            isActive: true,
                            label: Translate('label_kerncijfers_omzet'),
                            color: 'yellow',
                            hasNoValues: _.every(this.kerncijfers, year => year.omzet === "")
                        },
                        brutoMarge: {
                            valuesPerYear: this.brutoMargeValuesPerYear,
                            isActive: true,
                            label: Translate('label_kerncijfers_brutomarge'),
                            color: 'blue',
                            hasNoValues: _.every(this.kerncijfers, year => year.brutoMarge === "")
                        },
                        eigenVermogen: {
                            valuesPerYear: this.eigenVermogenValuesPerYear,
                            isActive: true,
                            label: Translate('label_kerncijfers_eigen_vermogen'),
                            color: 'teal',
                            hasNoValues: _.every(this.kerncijfers, year => year.eigenVermogen === "")
                        }
                    };
                }
            },
            mounted() {
                Page.applyBehaviors(rootSelector);

                var chartContainer = this.$refs.chartcontainer;
                var padding = 30;
                this.containerWidth = chartContainer.clientWidth - padding;

                this.createChartData();
            }
        });

    });
}());
