
<div class="col-md-12 vert-offset-top-1">
    <table class="table data-table">
        <thead>
            <tr>
                <th colspan="4">{{translate(name)}}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(company, index) in orderedResults"
                v-bind="company"
                is="result-row"
                :key="index"></tr>
        </tbody>
    </table>
</div>
