
<div class="row">
    <div class="col-md-10 offset-md-2 mt-3 my-4 my-lg-5 d-none d-md-block">
        <div class="row filter-row">
            <div class="col-md-5 mt-2 mt-md-0">
                <label for="type">{{translate('event_type')}}</label>
                <type-dropdown v-model="filter.type" :types="filterEventTypes"></type-dropdown>
            </div>
        </div>
    </div>
    <div v-if="filteredEvents.length == 0" class="col-md-10 offset-md-2 mt-3 ">
        <p>
            {{translate('no_results')}}
        </p>
    </div>
    <div class="col-md-12" v-else>
        <day v-for="day in days"
                   :key="day.date"
                   :current-list="currentList"
                   :date="day.date"
                   :companies="day.companies"
                   :persons="day.persons"
                   :addresses="day.addresses">
        </day>
        <div class="text-grey font-small py-5 text-center text-uppercase" v-if="!hasShownAllEvents">
            <i class="fas fa-arrow-down mr-2"></i> {{translate('scroll_to_show_more')}}
        </div>
    </div>
</div>
