<template>
    <ul class="pl-3">
        <li v-for="node in nodes" :key="node.id">
            <span v-if="node.children && node.children.length > 0 && !alwaysOpen" @click="onExpandClick(node)" class="expander">
                <i aria-hidden="true" class="fas fa-lg fa-angle-down" v-if="isExpanded(node)"></i>
                <i aria-hidden="true" class="fas fa-lg fa-angle-right text-lightgrey" v-else></i> 
            </span>

            <input type="checkbox" class="form-check-input" :id="type + node.id" :checked="isSelected(node, type)" @change="select(node, $event, type, subType)" :disabled="isSelectedAndDisabled"/>
            <label :for="type + node.id">{{node.name}}</label>

            <select-tree v-if="node.children && isExpanded(node)" :isParentSelected="isSelected(node, type)" :criteria="node.children" :type="subType || type" :filterTerm="filterTerm"></select-tree>
        </li>
    </ul>
</template>

<script lang="ts">
    import _ from 'lodash';
    import Vue from 'vue';
    import { ProspectieDataSelectionCriteriumType } from '../../../../types/dto/ProspectieDataSelectionCriteriumType';
    import DataMarketingService from '../../dataMarketingService';
    import { IProspectieDataTreeNode } from '../../../../types/dto/IProspectieDataTreeNode';
  
    export default Vue.extend({
        name: 'select-tree',
        props: {
            type: String as () => ProspectieDataSelectionCriteriumType,
            subType: {
                type:String as () => ProspectieDataSelectionCriteriumType,
                default: undefined
            },
            criteria: Array as () => IProspectieDataTreeNode[],
            isParentSelected: Boolean,
            filterTerm: {
                type: String,
                default: ""
            },
            alwaysOpen: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                expandedNodes:[] as  IProspectieDataTreeNode[]
            }
        },
        computed:{
            nodes(): IProspectieDataTreeNode[] {
                return this.criteria.filter(node => {
                    return node.name.cw_contains(this.filterTerm) || this.hasMatchingDescendants(node);
                }); 
            },
            isSelectedAndDisabled(): boolean {
                return this.isParentSelected;
            }
        },
        methods: {
            hasMatchingDescendants(node: IProspectieDataTreeNode): boolean {
                if(!this.filterTerm)
                    return false;

                return _.some(node.children, (child: IProspectieDataTreeNode) => {
                    return child.name.cw_contains(this.filterTerm) || this.hasMatchingDescendants(child);
                });
            },
            onExpandClick(node: IProspectieDataTreeNode): void {
                this.expandedNodes.includes(node) 
                    ? this.expandedNodes.remove(node) 
                    : this.expandedNodes.push(node);
            },
            isExpanded(node: IProspectieDataTreeNode): boolean {
                return this.expandedNodes.includes(node) || this.hasSelectedChildren(node) || this.alwaysOpen;
            },
            async select(node: IProspectieDataTreeNode, event: Event, type: ProspectieDataSelectionCriteriumType, subType: ProspectieDataSelectionCriteriumType): Promise<void> {
                const selectedChildren = [] as IProspectieDataTreeNode[];
                
                _.forEach(node.children, child => {
                    this.foreachInTree(
                        child,
                        (n: IProspectieDataTreeNode) => {
                            if(this.isSelected(n, subType ?? type))
                                selectedChildren.push(n)
                        });
                });
                
                await Promise.all(selectedChildren.map(async childNode => await DataMarketingService.getInstance().currentRequest!.select(childNode.id, subType ?? type, false)))
                await DataMarketingService.getInstance().currentRequest!.select(node.id, type, (event.target as HTMLInputElement).checked);
                await DataMarketingService.getInstance().currentRequest!.updateSummary();
            },
            hasSelectedChildren(node: IProspectieDataTreeNode): boolean {
                let anySelected = false;

                _.forEach(node.children, child => {
                    this.foreachInTree(
                        child,
                        (n: IProspectieDataTreeNode) => {
                            anySelected = anySelected || this.isSelected(n, this.type)|| this.isSelected(n, this.subType);
                        });
                });

                return anySelected
            },
            foreachInTree (node: IProspectieDataTreeNode, visitor: (n: IProspectieDataTreeNode) => any) {
                visitor(node);
                if (node.children) {
                    for (var i = 0, l = node.children.length; i < l; i++) {
                        this.foreachInTree(node.children[i], visitor);
                    }
                }
            },
            isSelected(node: IProspectieDataTreeNode, type: ProspectieDataSelectionCriteriumType): boolean {
                const selectedCriteria = DataMarketingService.getInstance().currentRequest!.selectedCriteria!.filter(c => c.type === type);
                return selectedCriteria.map(s => s.id).includes(node.id) || this.isSelectedAndDisabled;
            }
        }
    });
</script>

<style scoped lang="scss">
    li {
        position: relative;
        > ul {   
            padding-left: 15px;
        }
    }

    .expander {
        display: block;
        position: absolute;
        margin-top: -4px;
        margin-left: -20px;
        padding: 5px;
        cursor: pointer;
    }
</style>