
<div class="track">
    <transition name="fade">
        <div class="active-element"
             v-bind:class="{'reflected': isReflected}"
             v-bind:style="labelPosition"
             v-if="shouldShowInfo"
             v-html="activeElement.content"></div>
    </transition>
    <div class="track-label">
        <template v-if="link">
            <a v-if="icon" :href="link" target="_blank" class="text-blue">
                {{label}}
            </a>
        </template>
        <template v-else>
            {{label}}
        </template>
        <i v-if="icon" class="fas" v-bind:class="'fa-' + icon"></i>
    </div>
    <div class="track-content">
        <cw-period v-for="(period, index) in periods"
                   v-bind="period"
                   :key="index"
                   :index="index"
                   :timeline-start="timelineStart"
                   :timeline-end="timelineEnd">
        </cw-period>
        <cw-event v-for="(event, index) in events"
                  v-bind="event"
                  :key="index"
                  :index="index"
                  :timeline-start="timelineStart"
                  :timeline-end="timelineEnd">
        </cw-event>
    </div>
</div>
