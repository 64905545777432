﻿<template>
    <div class="corona-matrix">
        <buffer-score v-for="(bufferCat,key) in bufferScores" :key="key" :score="bufferCat.score" :label="key" :tooltip="bufferCat.tooltip || ''"></buffer-score>
    </div>
</template>

<script>
    import BufferScore from './buffer-score.vue';
    export default {
        name: 'crisis-weerbaarheid-matrix',
        props: {
            bufferScores: Object
        },
        components: {
            'buffer-score': BufferScore
        }
    }
</script>

<style scoped>
    .corona-matrix {
        display: inline-block;
        margin-top: 12px;
        background-color: #f8f9f9;
    }
</style>