﻿<template>
    <div class="row">
        <div class="col-md-12">
            <p>Zoek op adres en krijg bijkomende inzichten zoals:</p>
            <ul class="check-list">
                <li>Welke andere bedrijven zijn op een adres gevestigd?</li>
                <li>Hebben bedrijven op basis van adres iets gemeen?</li>
                <li>Wat is de gezondheid van bedrijven die op een bepaalde locatie gevestigd zijn?</li>
                <li>Zijn er veel failliete bedrijven op eenzelfde locatie?</li>
            </ul>

            <img src="/images/campaign/search-on-address/search.png" class="img-fluid" width="350">
        </div>
    </div>
</template>



<script>
    export default {
        name: 'feature-search-on-address'
    }
</script>