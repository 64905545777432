﻿<template>
    <div>
        <div>
            <input type="search" v-model="filterTerm" class="form-control mb-3" :placeholder="placeholder">
        </div>
        <div :class="{'limited-height':limitedHeight}">
            <selection-branch :column="column" :is-parent-selected="false" :is-forced-expanded="isForcedExpanded" :tree-data="treeData" :criteria="criteria" :filterTerm="filterTerm"></selection-branch>
        </div>
    </div>
</template>

<script lang="ts">
import Translate from '../../../../mixins/translate';
import SelectionBranch from "./selection-branch.vue";
import {Column, SelectionTreeFilterCriteria, SelectionTreeNode} from "../../../types";

export default Translate.extend({
    name: "selection-tree",
    components: {
        SelectionBranch
    },
    props: {
        limitedHeight: Boolean,
        criteria: Object as () => SelectionTreeFilterCriteria,
        treeData: Array as () => SelectionTreeNode[],
        isParentSelected: Boolean,
        isForcedExpanded: Boolean,
        column: {
            type:Object as () => Column,
            required: true
        }
    },
    data() {
        return {
            filterTerm: ''
        }
    },
    computed: {
        placeholder(): string {
            return this.translate("filter_on") + " " + this.translate(this.column.title).toLowerCase();
        }
    }
});
</script>

<style scoped>
.limited-height {
    max-height: 350px;
    overflow-y: auto;
}
</style>