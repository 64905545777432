﻿<template>
    <div class="row">
        <div class="col-md-10 offset-md-2 mt-3 my-4 my-lg-5 d-none d-md-block">
            <div class="row filter-row">
                <div class="col-md-5 mt-2 mt-md-0">
                    <label for="type">{{translate('event_type')}}</label>
                    <type-dropdown v-model="filter.type" :types="filterEventTypes"></type-dropdown>
                </div>
            </div>
        </div>
        <div v-if="filteredEvents.length == 0" class="col-md-10 offset-md-2 mt-3 ">
            <p>
                {{translate('no_results')}}
            </p>
        </div>
        <div class="col-md-12" v-else>
            <day v-for="day in days"
                       :key="day.date"
                       :current-list="currentList"
                       :date="day.date"
                       :companies="day.companies"
                       :persons="day.persons"
                       :addresses="day.addresses">
            </day>
            <div class="text-grey font-small py-5 text-center text-uppercase" v-if="!hasShownAllEvents">
                <i class="fas fa-arrow-down mr-2"></i> {{translate('scroll_to_show_more')}}
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import Day from './events/day.vue';
    import TypeDropdown from './events/type-dropdown.vue';
    import Translate from 'Mixins/translate';
    import DateHelpers from 'Mixins/date';

    export default {
        name: 'events',
        mixins: [Translate, DateHelpers],
        components: {
            Day,
            TypeDropdown
        },
        props: {
            currentList: Object
        },
        data() {
            return {
                eventTypes: window.cw.eventTypes,
                filter: {
                    type: 'all',
                    since: 'past-30-days'
                },
                displayAmount: 50,
                isPersonFollowupEnabled: window.cw.isPersonFollowupEnabled,
                isAddressFollowupEnabled: window.cw.isAddressFollowupEnabled
            };
        },
        computed: {
            allEvents(){
                return Object.keys(this.currentList.FollowUpEvents).flatMap((vat) => {
                    return this.currentList.FollowUpEvents[vat].map(events => ({...events, Vat:+vat}))
                });
            },
            availableEvents() {
                const removedCompanyVats = _.map(_.filter(this.currentList.Companies, company => {
                    return company.isRemoved;
                }), 'Vat');
                return _.filter(this.allEvents, event =>  {
                    return !_.includes(removedCompanyVats, event.Vat) &&
                        ((this.isPersonFollowupEnabled && event.Group === 'person') || (this.isAddressFollowupEnabled && event.Group === 'address') || event.Group === 'company')
                });
            },
            filterEventTypes() {
                let eventTypes = [
                    {
                        value: 'all',
                        eventTypes: ['barometer', 'kredietlimiet', 'status', 'naam', 'juridische-vorm', 'adres', 'doorhaling', 'rsz-dagvaarding', 'gerechtelijk-akkoord',
                            'currator-aangesteld', 'faillissement', 'publicatie', 'jaarrekening', 'mandaat', 'op-adres'],
                        icon: 'check',
                        numberOfEvents: this.allEvents.length
                    }];


                if (this.isPersonFollowupEnabled)
                    eventTypes.push(
                        {
                            value: 'mandaat',
                            eventTypes: ['mandaat'],
                            icon: 'user-tie',
                            numberOfEvents: this.getEventsByType('mandaat').length
                        }
                    )
                
                if (this.isAddressFollowupEnabled)
                    eventTypes.push(
                        {
                            value: 'op-adres',
                            eventTypes: ['op-adres'],
                            icon: 'truck-moving',
                            numberOfEvents: this.getEventsByType('op-adres').length
                        }
                    )
                
                eventTypes.push(
                    {
                        value: 'barometer-en-kredietlimiet',
                        eventTypes: ['barometer', 'kredietlimiet'],
                        icon: 'tachometer-alt',
                        numberOfEvents: _.union(this.getEventsByType('barometer'), this.getEventsByType('kredietlimiet')).length
                    },
                    {
                        value: 'faillissement',
                        eventTypes: ['faillissement'],
                        icon: 'gavel',
                        numberOfEvents: this.getEventsByType('faillissement').length
                    },
                    {
                        value: 'currator-aangesteld',
                        eventTypes: ['currator-aangesteld'],
                        icon: 'gavel',
                        numberOfEvents: this.getEventsByType('currator-aangesteld').length
                    },
                    {
                        value: 'status',
                        eventTypes: ['status'],
                        icon: 'heartbeat',
                        numberOfEvents: this.getEventsByType('status').length
                    },
                    {
                        value: 'naam',
                        eventTypes: ['naam'],
                        icon: 'pencil-alt',
                        numberOfEvents: this.getEventsByType('naam').length
                    },
                    {
                        value: 'adres',
                        eventTypes: ['adres'],
                        icon: 'truck-moving',
                        numberOfEvents: this.getEventsByType('adres').length
                    },
                    {
                        value: 'juridische-vorm',
                        eventTypes: ['juridische-vorm'],
                        icon: 'building',
                        numberOfEvents: this.getEventsByType('juridische-vorm').length
                    },
                    {
                        value: 'doorhaling',
                        eventTypes: ['doorhaling'],
                        icon: 'exclamation-triangle',
                        numberOfEvents: this.getEventsByType('doorhaling').length
                    },
                    {
                        value: 'rsz-dagvaarding',
                        eventTypes: ['rsz-dagvaarding'],
                        icon: 'exclamation-triangle',
                        numberOfEvents: this.getEventsByType('rsz-dagvaarding').length
                    },
                    {
                        value: 'gerechtelijk-akkoord',
                        eventTypes: ['gerechtelijk-akkoord'],
                        icon: 'exclamation-triangle',
                        numberOfEvents: this.getEventsByType('gerechtelijk-akkoord').length
                    },
                    {
                        value: 'publicatie',
                        eventTypes: ['publicatie'],
                        icon: 'file-alt',
                        numberOfEvents: this.getEventsByType('publicatie').length
                    },
                    {
                        value: 'jaarrekening',
                        eventTypes: ['jaarrekening'],
                        icon: 'file-alt',
                        numberOfEvents: this.getEventsByType('jaarrekening').length
                    }
                );
                
                return eventTypes;
            },
            eventTypesToFilterOn() {
                return _.find(this.filterEventTypes, type => {
                    return type.value === this.filter.type
                }).eventTypes;
            },
            filteredEvents() {
                return _.orderBy(
                        _.filter(
                        this.availableEvents,
                            event => { return this.eventTypesToFilterOn.indexOf(event.Type) !== -1; }
                        ), 'Date', 'desc');
            },
            days() {
                const subSelection = _.take(this.filteredEvents, this.displayAmount);

                return _.chain(subSelection)
                        .groupBy('Date')
                        .map((events, date) => ({
                            date: date,
                            companies: _.chain(events)
                                .filter(f => f.Group === 'company')
                                .groupBy('Vat')
                                .map((eventsForVat, vat) => (
                                    {
                                        vat: parseInt(vat),
                                        events: _.orderBy(eventsForVat, 'Severity', 'desc'),
                                        mostSevereEvent: _.max(_.map(eventsForVat, 'Severity')),
                                        companyName: _.head(eventsForVat).CompanyName
                                    }
                                ))
                                .orderBy(['mostSevereEvent', 'companyName'], ['desc', 'asc'])
                                .value(),
                            persons: _.chain(events)
                                .filter(f => f.Group === 'person')
                                .groupBy('Person.PersonId')
                                .map((eventsForPerson, id) => (
                                    {
                                        id: parseInt(id),
                                        events: _.orderBy(eventsForPerson, 'CompanyName', 'desc'),
                                        mostSevereEvent: _.max(_.map(eventsForPerson, 'Severity'))
                                    }
                                ))
                                .orderBy(['mostSevereEvent', 'id'], ['desc', 'asc'])
                                .value(),
                            addresses: _.chain(events)
                                .filter(f => f.Group === 'address')
                                .groupBy('Adres.Id')
                                .map((eventsForAddress, id) => (
                                    {
                                        id: parseInt(id),
                                        events: _.orderBy(eventsForAddress, 'CompanyName', 'asc'),
                                        mostSevereEvent: _.max(_.map(eventsForAddress, 'Severity')),
                                        postCode: _.head(eventsForAddress).Adres.Postcode
                                    }
                                ))
                                .orderBy(['mostSevereEvent', 'postCode'], ['desc', 'asc'])
                                .value()
                        }))
                        .orderBy('date', 'desc')
                        .value();
                
            },
            hasShownAllEvents() {
                return this.displayAmount >= this.filteredEvents.length
            }
        },
        methods: {
            getEventsByType(type) {
                return _.filter(this.availableEvents, event => {
                    return event.Type === type;
                });
            },
            infiniteScroll() {
                window.onscroll = () => {
                    const fullDocumentHeight = document.documentElement.scrollHeight;
                    const scrolledHeight = document.documentElement.scrollTop || document.body.scrollTop;
                    const screenHeight = window.innerHeight;

                    if (scrolledHeight + screenHeight + 200 >= fullDocumentHeight)
                        this.displayAmount += 50;
                }
            }
        },
        mounted() {
            this.infiniteScroll();
        }
    }
</script>

<style scoped>
    .filter-row:before {
        content: '\f0b0';
        position: absolute;
        font-weight: bold;
        font-family: 'Font Awesome 5 Pro';
        color: #acacac;
        left: -20px;
        bottom: 7px;
    }
</style>