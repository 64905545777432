
<div>
    <h2><b>{{translate('upload_vats_title')}}</b></h2>
    <p class="mb-4">
        {{translate('upload_vats_desc')}} 
    </p>

    <div class="tip warning py-2">
        {{translate('max_lines_message')}} <a href="/contact/message" class="link">{{translate('contact_us')}}</a>
    </div>

    <div class="row mt-5">
        <div v-if="isLoading" class="col-md-12 text-center">
            <i class="fas fa-spinner spinning text-lightblue fa-lg my-4"></i>
        </div>
        <div v-else class="col-md-8">
            <btw-file-input required ask-for-references @input="onUpload"></btw-file-input>
        </div>
    </div>
</div>
