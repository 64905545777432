<template>
    <div class="company-information">
        <div class="feature-tabs">
            <a 
                class="tab" 
                href="" 
                :class="{'active': activeTab === feature}" 
                v-for="feature in CompanyInformationFeature"
                :key="feature" 
                @click.prevent="activateTab(feature)">
                {{feature}}
            </a>
        </div>
        <div class="feature-panel">
            <div class="feature-content">
                <transition-group name="list" tag="div">
                    <span v-for="item in CompanyInformationFeature" v-bind:key="item" class="list-item" v-show="activeTab === item">
                        <h2>{{item|capitalize}}</h2>
                        <p>
                            {{translate('desc_' + item)}}
                        </p>
                    </span>
                </transition-group>               
            </div>
            <div class="feature-image">
                <img :src="'/images/possibilities/company-information/'+ screenshots[activeTab]" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import mixins from 'vue-typed-mixins';
    import Translate from '../../mixins/translate';
    import _ from 'lodash';
    
    enum CompanyInformationFeature {
        BASISGEGEVENS = "basisgegevens",
        WARNINGS = "warnings",
        KREDIETLIMIET = "kredietlimiet",
        BAROMETER = "barometer",
        BETALINGSGEDRAG = "betalingsgedrag",
        PUBLICATIES = "publicaties",
        BEDRIJFSSTRUCTUUR = "bedrijfsstructuur",
        CONCURRENTIEVERGELIJKING = "concurrentievergelijking",
        KERNCIJFERS = "kerncijfers",
        LOCATIES = "locaties"
    }

    export default mixins(Translate).extend({
        name: 'test',
        data() {
            return {
                CompanyInformationFeature,
                activeTab: CompanyInformationFeature.BASISGEGEVENS,
                screenshots: {
                    [CompanyInformationFeature.BASISGEGEVENS]: 'basisinformatie.png',
                    [CompanyInformationFeature.WARNINGS]: 'waarschuwingen.png',
                    [CompanyInformationFeature.KREDIETLIMIET]: 'kredietlimiet.png',
                    [CompanyInformationFeature.BAROMETER]: 'barometer.png',
                    [CompanyInformationFeature.BETALINGSGEDRAG]: 'betalingsgedrag.png',
                    [CompanyInformationFeature.PUBLICATIES]: 'publicaties.png',
                    [CompanyInformationFeature.BEDRIJFSSTRUCTUUR]: 'bedrijfsstructuur.png',
                    [CompanyInformationFeature.CONCURRENTIEVERGELIJKING]: 'concurrentievergelijking.png',
                    [CompanyInformationFeature.LOCATIES]: 'locaties.png',
                    [CompanyInformationFeature.KERNCIJFERS]: 'kerncijfers.png',
                } as Record<CompanyInformationFeature, string>,
                interval: null as NodeJS.Timer | null
            }
        },
        filters:{
            capitalize(str: string) {
                return str.slice(0, 1).toUpperCase() + str.slice(1, str.length);
            }
        },
        methods: {
            activateTab(feature: CompanyInformationFeature) {
                clearInterval(this.interval!);
                this.activeTab = feature;
            }
        },
        mounted() {
            let index = 0;
            this.interval = setInterval(() => {
                if(index >= Object.values(CompanyInformationFeature).length -1) {
                    index = 0;
                } else {
                    index++;
                }
                this.activeTab = Object.values(CompanyInformationFeature)[index];
            }, 3500);
        }
    });
</script>

<style scoped lang="scss">
    @import '../../../../sass/_variables.scss';
    
    .company-information {
        padding: 40px;
        box-shadow: 0 0 55px -15px #26262630;
        border-radius: 10px;
    }

    .feature-tabs {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 11px;
        
        .tab {
            background-color: $bg-lightgrey;
            text-align: center;
            padding: 6px;
            border-radius: 90px;
            font-size: 13px;   
            color: grey;
            box-shadow: 2px 2px 2px -1px #0000001f;
            text-transform: capitalize;
            user-select: none;
            border: 1px solid transparent;
            @include ease(0.1s);

            &:hover {
                text-decoration: none;
                background-color: darken($bg-lightgrey, 6%);
                color: black;
                @include ease(0.1s);
            }
            
            &.active {
                background-color: fade-out($lightblue, 0.95);
                color: $lightblue;
                border-color: $lightblue;
                font-weight: bold;
            }
        }
    }
    .feature-panel {
        padding-top: 40px;
        display: flex;
        .feature-content {
            margin-right: 35px;
            margin-top: 10px;
            flex-grow: 1;
            position: relative;
            flex-basis: 50%;
        }
        .feature-image {
            flex-grow: 1;
            flex-basis: 50%;

            img {
                max-width: 100%;
                border-radius: 4px;
                border: 1px solid #e3e3e3;
            }
        }
    }
</style>