﻿<template>
    <div>
        <input type="search" v-model="criteria._value[0]" :placeholder="translate('filterwaarde_1')" class="form-control mb-3"/>
        <input v-if="showSecondField" type="search" v-model="criteria._value[1]" :placeholder="translate('filterwaarde_2')" class="form-control"/>
        <a href="" v-else @click.prevent="showSecondField = true" class="btn btn-sm">
            <i class="far fa-plus mr-1"></i> {{translate('add_filter_value')}}
        </a>
    </div>
</template>

<script lang="ts">
    import { FreeTextFilterCriteria } from "../../../types";
    import Translate from '../../../../mixins/translate';

    export default Translate.extend({
        name: "free-text",
        props: {
          criteria: {
              type: Object as () => FreeTextFilterCriteria,
              required: true
          }
        },
        data() {
            return {
                showSecondField: this.criteria._value[1] !== ""
            }
        }
    });
</script>

<style scoped>

</style>