﻿<template>
    <div class="wrapper">
        <div class="top-view">
            <div class="mb-3">
                <a href="" class="btn btn-sm btn-default" @click.prevent="backToList">
                    <i class="fas fa-arrow-left mr-"></i> {{translate('all_filters')}}
                </a>
            </div>
            <h2 class="font-weight-bold">{{label}}</h2>
            <p v-if="column && column.showDescription" v-html="translate(column.title+'_desc')"></p>
        </div>
        <div class="main-view">
            <template v-if="column">
                <div v-if="column.filterCriteria._type === FilterType.FREETEXT">
                    <free-text :criteria="column.filterCriteria"></free-text>
                </div>
                <div v-if="column.filterCriteria._type === FilterType.BOOLEAN">
                    <yes-no :criteria="column.filterCriteria"></yes-no>
                </div>
                <div v-else-if="column.filterCriteria._type === FilterType.SELECTIONLIST">
                    <selection-list :column="column"></selection-list>
                </div>
                <div v-else-if="column.filterCriteria._type === FilterType.DATERANGE" class="mt-3">
                    <date-range :criteria="column.filterCriteria"></date-range>
                </div>
                <div v-else-if="column.filterCriteria._type === FilterType.SELECTIONTREE">
                    <selection-tree
                        :column="column"
                        :criteria="column.filterCriteria"
                        :treeData="getSelectionTree"
                        :is-forced-expanded="column.forceExpandTree"></selection-tree>
                </div>
                <div v-else-if="column.filterCriteria._type === FilterType.NUMERICRANGE">
                    <div class="px-2 py-3">
                        <range-slider
                            :is-linear="isLinear"
                            :lower-bound="lowerBound"
                            :upper-bound="upperBound"
                            v-model="column.filterCriteria._value"></range-slider>
                    </div>
                </div>
            </template>
            <template v-else-if="currentDrawerFilter === 'companyname'">
                <input v-model="store.companyNameFilter" class="form-control" :placeholder="translate('filter_on_company')" type="search"/>
            </template>
            <template v-else-if="currentDrawerFilter === 'street'">
                <div class="row">
                    <div class="col-md-8">
                        <input v-model="streetSearchTerm" class="form-control" :placeholder="translate('filter_on_street')" type="search"/>
                        <div class="filter-overflow-box mt-4">
                            <ul>
                                <li v-for="street in shownFilteredStreets" :key="street">
                                    <input :id="street"
                                           :checked="store.streetFilters.includes(street)"
                                           class="form-check-input azure"
                                           type="checkbox"
                                           @change="store.toggleFilterStreet(street)"/>
                                    <label :for="street" class="wrap-normal d-block"><span>{{ street }}</span></label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control" type="search" :placeholder="translate('house_number')" v-model="store.houseNumberFilter">
                    </div>
                </div>
            </template>
            <template v-else-if="currentDrawerFilter === 'form'">
                <div class="filter-overflow-box">
                    <ul>
                        <li v-for="form in uniqueForms" :key="form">
                            <input :id="form"
                                   :checked="store.formFilters.includes(form)"
                                   class="form-check-input azure"
                                   type="checkbox"
                                   @change="store.toggleFilterForm(form)"/>
                            <label :for="form" class="wrap-normal d-block"><span>{{ form }}</span></label>
                        </li>
                    </ul>
                </div>
            </template>
            <template v-else>
                <div>
                    <div v-if="field.type === 'boolean'">
                        <select v-model="store.customFilters[field.id]" class="form-control">
                            <option :value="null">{{translate('checkbox_all')}}</option>
                            <option :value="true">{{translate('checkbox_checked')}}</option>
                            <option :value="false">{{translate('checkbox_unchecked')}}</option>
                        </select>
                    </div>
                    <div v-else>
                        <div class="d-flex align-items-center">
                            <input v-model="store.customFilters[field.id]"
                                   :placeholder="translate('filter_on') + ' ' + field.name" class="form-control" type="search">
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="bottom-view">
            <a href="" class="btn btn-white" @click.prevent="close">{{translate('cancel')}}</a>
            <div class="font-italic text-grey text-center"><div class="font-weight-bold text-body">{{store.allFilteredCompanies.length}}</div> {{translate('x_selected_companies')}}</div>
            <button class="btn btn-default" @click.prevent="removeFilter">{{translate('clear_filter')}}</button>
        </div>
    </div>
</template>

<script lang="ts">
import {useStore} from "../../store";
import Translate from "../../../mixins/translate";
import {Column, CustomField, DrawerView, FilterType, NumericRangeFilterCriteria, SelectionTreeNode} from "../../types";
import FreeText from "../filter-boxes/filter-types/free-text.vue";
import SelectionList from "../filter-boxes/filter-types/selection-list.vue";
import DateRange from "../filter-boxes/filter-types/date-range.vue";
import RangeSlider from "../../../shared/range-slider/index.vue";
import _ from "lodash";
import SelectionTree from "../filter-boxes/filter-types/selection-tree.vue";
import YesNo from "../filter-boxes/filter-types/yes-no.vue";

export default Translate.extend({
    name: "filter-detail",
    components: {YesNo, SelectionTree, RangeSlider, DateRange, SelectionList, FreeText},
    data() {
        return {
            store: useStore(),
            streetSearchTerm: ''
        }
    },
    computed: {
        FilterType() {
            return FilterType
        },
        field(): CustomField {
            return this.currentDrawerFilter as CustomField;
        },
        currentDrawerFilter(): string | CustomField {
            return this.store.currentDrawerFilter;
        },
        label(): string | CustomField {
            return typeof this.currentDrawerFilter === 'string' ? this.translate(this.currentDrawerFilter) : (this.currentDrawerFilter as CustomField).name
        },
        column(): Column | undefined {
            return this.store.columns.find(c => c.title === this.currentDrawerFilter);
        },
        uniqueForms(): string[] {
            return _.uniq(this.store.companies.map(c => c.juridischeVormTranslated)).sortAlpha();
        },
        uniqueStreets(): string[] {
            return _.uniq(this.store.companies.flatMap(c => {
                const zetelStraat: string = c.zetelAddress.straatNaam;
                const straten: string[] = c.vestigingsAdressen.flatMap(v => v.straatNaam);
                straten.push(zetelStraat);

                return straten;
            }));
        },
        shownFilteredStreets(): string[] {
            const streetFilters = this.store.streetFilters;
            return this.uniqueStreets.filter(v => (v ?? "").cw_contains(this.streetSearchTerm))
                .sortAlpha()
                .sort((a,b) => streetFilters.includes(a) ? -1 : 1)
                .splice(0,25);
        },
        lowerBound(): number | undefined {
            return (this.column?.filterCriteria as NumericRangeFilterCriteria)._min;
        },
        upperBound(): number | undefined {
            return (this.column?.filterCriteria as NumericRangeFilterCriteria)._max;
        },
        isLinear(): boolean | undefined {
            return (this.column?.filterCriteria as NumericRangeFilterCriteria)._isLinear;
        },
        getSelectionTree(): SelectionTreeNode[] {
            switch (this.column?.title) {
                case 'nace':
                    return this.store.naceTree;
                case 'beroepenindex':
                    return this.store.beroepenIndexTree;
                case 'custom_categories' :
                    return this.store.customCategoriesTree;
                default:
                    return [];
            }
        },
    },
    methods: {
        close() {
            this.store.closeDrawer();
        },
        backToList() {
            this.store.openDrawer(DrawerView.FILTER_LIST);
        },
        removeFilter() {
            this.column?.filterCriteria.reset();
            
            if(this.currentDrawerFilter == 'street')
                this.store.removeStreetFilters();
            if(this.currentDrawerFilter == 'companyname')
                this.store.removeCompanyNameFilters();
            if(this.currentDrawerFilter == 'form')
                this.store.removeFormFilters();
        }
    }
});
</script>

<style scoped lang="scss">
.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    .top-view {
        padding: 30px;

    }
    .main-view{
        padding: 0 30px;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 1%; /* zorgt voor de scrollbar */
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            border-radius: 10px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar
        {
            width: 8px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb
        {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
        }
    }

    .bottom-view {
        padding: 30px;
        border-top: 1px solid lightgrey;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .button-group {
            display: flex;
            gap: 5px;
        }
    }
}
</style>