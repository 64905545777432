﻿import _ from 'lodash';
import Vue from 'vue';
import Ajax from '../lib/Ajax';
import Translate from '../lib/Translate';
import Page from '../lib/Page';

export default (function () {

    Page.registerVue('#competitorComparisonWrapper', function (rootSelector) {

        enum State {
            initial = 'initial',
            pending = 'pending',
            done = 'done'
        };

        enum ResultStatus {
            success = 'success',
            error = 'error'
        };

        Vue.filter('formatDate', function (value: string | number) {
            var date = Date.fromHansDate(+value);
            return date.getFullYear() + '(' + (date.getMonth() + 1) + ')';
        });

        Vue.component('competitor', {
            props: {
                startingState: Object as () => State,
                startingVat: String,
                count: Number
            },
            data() {
                return {
                    vat: this.startingVat,
                    state: this.startingState,
                    result: {
                        status: '',
                        availableYears: [],
                        company: '',
                        vat: '',
                        message: ''
                    }
                };
            },
            template: '#competitor-template',
            computed: {
                strippedVat: function (): string {
                    return this.vat.toString().replace(/\D/g, '').replace(/^0+/, '');
                }
            },
            beforeMount(): void {
                this.checkVat();
            },
            methods: {
                checkVat(): void {
                    if (this.strippedVat.length === 9) {
                        Ajax.get(
                            '/ajax/competitorcomparison/' + this.strippedVat + '/availableyears',
                            {
                                vat: this.strippedVat
                            },
                            (result: AjaxResponse<any>) => {
                                this.state = State.done;

                                this.result = {
                                    availableYears: result.Data.years,
                                    company: result.Data.company.name,
                                    vat: result.Data.company.vat,
                                    status: ResultStatus.success,
                                    message: ''
                                };
                            },
                            (error: string) => {
                                this.state = State.pending;
                                this.result.message = error !== '' ? Translate(error) : '';
                                this.result.status = ResultStatus.error;
                            }
                        );
                    } else if (this.strippedVat.length > 9) {
                        this.state = State.pending;
                        this.result.status = ResultStatus.error;
                    } else {
                        this.result.status = '';
                    }
                },
                removePlaceholder(): void {
                    if (this.state === State.initial) {
                        this.state = State.pending;
                        this.$nextTick(function () {
                            (this.$refs.input as HTMLInputElement).focus();
                        });
                    }
                },
                resetCompetitor(): void {
                    this.state = State.initial;
                    this.vat = '';
                }
            }
        });

        new Vue({
            el: rootSelector,
            data: {
                competitors: [
                    {
                        startingState: State.pending,
                        startingVat: ''
                    },
                    {
                        startingState: State.initial,
                        startingVat: ''
                    },
                    {
                        startingState: State.initial,
                        startingVat: ''
                    }
                ]
            }
        });

    });
}());
