<template>
    <div class="slider-knob" @touchmove="onTouch" @touchend="emitValue"  @mousedown.stop="onStart" :style="{'left': x + 'px'}">
        <div class="grey-bar" :class="{'left': isLeft}" :style="{'width': (isLeft ? x : sliderWidth - x - 14) + 'px'}"></div>
        <div class="count">{{formattedValue}}</div>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        name: 'knob',
        props: {
            isLeft: {
                type: Boolean,
                default: false
            },
            min: Number,
            max: Number,
            lowerBound: Number,
            upperBound: Number,
            otherX: Number,
            initX: Number,
            sliderWidth: Number,
            isLinear: Boolean,
            formatLabel: Function
        },
        data() {
            return {
                x: this.initX,
                startX: 0,
                elemWidth: 14
            }
        },
        watch: {
            initX(v: number) {
                this.x = v;
            }
        },
        computed: {
            isLogarithmic(): boolean {
                return !this.isLinear;
            },
            maxPosition(): number {
                return this.sliderWidth - this.elemWidth;
            },
            currentValue(): number {
                const valueRange = this.upperBound - this.lowerBound;
                const percentOnTrail = this.x / this.maxPosition;
               
                if(this.isLogarithmic) 
                    return this.lowerBound + (percentOnTrail !== 0 
                        ? parseInt(Math.pow(valueRange, percentOnTrail).toFixed(0)) 
                        : 0);
                else
                    return this.lowerBound + parseInt((valueRange * percentOnTrail).toFixed(0))
            },
            formattedValue(): string {
                return this.formatLabel(this.currentValue)
            },
        },
        methods: {
            onTouch(evt: TouchEvent) {
                const touches = evt.changedTouches;
                const maxX = this.sliderWidth - this.elemWidth;
                const touch = touches[0];

                this.x = touch.pageX - 21;
                  // prevent overlaps and out of bounds
                if (this.isLeft) {
                    if (this.x < 0) 
                        this.x = 0;
                    if (this.x > this.otherX - this.elemWidth)
                        this.x = this.otherX - this.elemWidth
                } else {
                    if (this.x > maxX) 
                        this.x = maxX;
                    if (this.x < this.otherX + this.elemWidth)
                        this.x = this.otherX + this.elemWidth;
                }
            },
            onStart(event: MouseEvent) {
                this.x = (this.$el as HTMLElement).offsetLeft;
                this.startX = event.pageX - this.x;
                document.addEventListener('mousemove', this.onMove);
                document.addEventListener('mouseup', this.onStop);
            },
            onStop() {
                document.removeEventListener('mousemove', this.onMove);
                document.removeEventListener('mouseup', this.onStop);
                this.emitValue();
            },
            onMove(event: MouseEvent) {
                const maxX = this.sliderWidth - this.elemWidth;
                this.x = event.pageX - this.startX;

                // prevent overlaps and out of bounds
                if (this.isLeft) {
                    if (this.x < 0) 
                        this.x = 0;
                    if (this.x > this.otherX - this.elemWidth)
                        this.x = this.otherX - this.elemWidth
                } else {
                    if (this.x > maxX) 
                        this.x = maxX;
                    if (this.x < this.otherX + this.elemWidth)
                        this.x = this.otherX + this.elemWidth;
                }
            },
            emitValue() {
                this.$emit('value', this.currentValue);
            }
        }
    })
</script>

<style scoped lang="scss">
    .slider-knob {
        width: 14px;
        height: 14px;
        background-color: white;
        border: 4px solid #1ab9d6;
        border-radius: 36px;
        position: absolute;
		z-index: 2;
        cursor: pointer;

        .grey-bar {
            height: 4px;
            margin-top: 1px;
            content: '';
            background-color: #f8f9f9;
            left: 0;
            transform: translateX(10px);

            &.left {
                transform: translateX(-100%);
                margin-left: -4px;
            }
        }

        .count{
            position: absolute;
            bottom: -32px;
            color: white;
            text-align: center;
            transform: translateX(-50%);
            margin-left: 4px;
            user-select: none;
            background: #7f90a0;
            min-width: 25px;
            padding: 0px 5px;
            border-radius: 3px;
            &:before {
                content: '';
                position: absolute;
                bottom: 100%;
                border: 4px solid transparent;
                border-bottom-color: #7f90a0;
                transform: translateX(-50%);
                left: 50%;
            }
        }
    }
</style>