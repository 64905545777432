
<form @submit.prevent="onSubmit">
    <div class="row mb-4 mt-5">
        <div class="col-8">
            <ul class="nav nav-tabs border-bottom">
                <li class="nav-item" >
                    <a href="" class="nav-link" :class="{'active': currentTab === Tab.ALGEMEEN}" @click.prevent="changeTab(Tab.ALGEMEEN)"><i class="fas fa-info-circle mr-1 text-lightgrey"></i> Algemeen</a>
                </li>
                 <li class="nav-item">
                    <a href="" class="nav-link" :class="{'active': currentTab === Tab.APP}" @click.prevent="changeTab(Tab.APP)"><i class="fas fa-code mr-1 text-lightgrey"></i>Applicatie</a>
                </li>
                 <li class="nav-item">
                    <a href="" class="nav-link" :class="{'active': currentTab === Tab.DOWNLOADS}" @click.prevent="changeTab(Tab.DOWNLOADS)"><i class="fas fa-file-pdf mr-1 text-lightgrey"></i>Downloads</a>
                </li>
                 <li class="nav-item">
                    <a href="" class="nav-link" :class="{'active': currentTab === Tab.SCREENSHOTS}" @click.prevent="changeTab(Tab.SCREENSHOTS)"><i class="fas fa-image mr-1 text-lightgrey"></i>Screenshots</a>
                </li>
            </ul>
        </div>
        <div class="col-4 text-right">
            <button type="submit" class="btn btn-blue btn-200"><i class="fas fa-save mr-1"></i> Opslaan</button>
        </div>
    </div>

    <!-- TAB ALGEMEEN -->
    <div class="row"  v-if="currentTab === Tab.ALGEMEEN">
        <div class="col-6">
            <div class="row">
                <div class="col-8">
                    <div class="form-group">
                        <label for="name">Naam</label>
                        <input id="name" type="text" v-model="name" class="form-control" required/>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="name">Price</label>
                        <input id="name" type="number" v-model="price" class="form-control" required/>
                    </div>
                </div>
            </div>
            

           <div class="form-group">
                <label for="distributor">Verdeler</label>
                <input id="distributor" type="text" v-model="distributor" class="form-control" placeholder="Enkel indien meerdere verdelers"/>
            </div>

            <div class="form-group">
                <label for="distributorRedesign">Verdeler Redesign</label>
                <input id="distributorRedesign" type="text" v-model="distributorRedesign" class="form-control" placeholder="Enkel indien meerdere verdelers"/>
            </div>

            <div class="form-group">
                <label for="name">Zoekwoorden</label>
                <vue-tags-input
                    v-model="tag"
                    :tags="tags"
                    placeholder="Zoekwoord toevoegen"
                    @tags-changed="newTags => tags = newTags"
                    />
            </div>

            <div class="form-group">
                <label>Omschrijving NL</label>
                <vue-editor v-model="descriptionNL" :editorToolbar="customToolbar"></vue-editor>
            </div>     
            <div class="form-group">
                <label>Omschrijving FR</label>
                <vue-editor v-model="descriptionFR" :editorToolbar="customToolbar"></vue-editor>
            </div> 
            <div class="form-group">
                <label>Omschrijving EN</label>
                <vue-editor v-model="descriptionEN" :editorToolbar="customToolbar"></vue-editor>
            </div>  
        </div>
        <div class="col-6">
            <div class="form-group">
                <div class="row">
                    <div class="col-7">
                    <file-input 
                        v-on:input="onLogoUpload" 
                        :multiple="false" 
                        :required="false" 
                        name="logo"
                        placeholder=".png" 
                        title="Upload logo"
                        accept=".png" ></file-input>
                    </div>
                    <div class="col-5 mt-auto">
                        <img :src="logo.path" v-if="logo" class="img-fluid"/>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-7">
                        <file-input
                            v-on:input="onLogoRedesignUpload"
                            :multiple="false"
                            :required="false"
                            name="logoRedesign"
                            placeholder=".png"
                            title="Upload logoRedesign"
                            accept=".png" ></file-input>
                    </div>
                    <div class="col-5 mt-auto">
                        <img :src="logoRedesign.path" v-if="logoRedesign" class="img-fluid"/>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="intro_nl">Intro NL</label>
                <vue-editor id="intro_nl" v-model="introNL" :editorToolbar="customToolbar"/>
            </div> 
            <div class="form-group">
                <label for="intro_fr">Intro FR</label>
                <vue-editor id="intro_fr" v-model="introFR" :editorToolbar="customToolbar"/>
            </div> 
            <div class="form-group">
                <label for="intro_en">Intro EN</label>
                <vue-editor id="intro_en" v-model="introEN" :editorToolbar="customToolbar"/>
            </div>

            <div class="form-group">
                <b class="label-like">CTA knop</b>
                <div>
                    <input id="c" type="radio" v-model="ctaType" :value="CtaType.NOCTA">
                    <label for="c">Geen CTA</label>
                </div>
                <div class="mt-1">
                    <input id="b" type="radio" v-model="ctaType" :value="CtaType.CONTACTUS">
                    <label for="b">Contacteer ons</label>
                </div>
                <div class="mt-1">
                    <input id="a" type="radio" v-model="ctaType" :value="CtaType.ACTIVATABLE">
                    <label for="a">Activeerbaar</label>
                </div>
            </div>

            <div class="form-group my-4">
                <b class="label-like">Type software</b>
                <div>
                    <input id="e" type="radio" v-model="isCustom" :value="false">
                    <label for="e">Generiek</label>
                </div>
                <div class="mt-1">
                    <input id="d" type="radio" v-model="isCustom" :value="true">
                    <label for="d">Op maat</label>
                </div>
            </div>

            <div class="form-group">
                <input id="betalingservaring" type="checkbox" v-model="hasBetalingservaring" class="form-check-input">
                <label for="betalingservaring">Heeft Betalingservaring?</label>
            </div>

            <div class="form-group">
                <input id="hasFollowUp" type="checkbox" v-model="hasFollowUp" class="form-check-input">
                <label for="hasFollowUp">Heeft follow-up?</label>
            </div>

            <div class="form-group">
                <input id="prospect" type="checkbox" v-model="isProspect" class="form-check-input" :disabled="status === SoftwareStatus.PUBLISHED && !isProspect">
                <label for="prospect">Prospect?</label>
            </div>

            <div class="form-group">
                <input id="legacy" type="checkbox" v-model="isLegacy" class="form-check-input">
                <label for="legacy">Legacy?</label> <i class="fas fa-info-circle" title="Legacy software wordt enkel op beheer-pagina getoond, maar niet op de ontdek-pagina."></i>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label for="label">Label</label>
                        <select v-model="label" class="form-control" id="label">
                            <option :value="null">Geen</option>
                            <option v-for="label in SoftwareLabel" :key="label" :value="label">{{label}}</option>
                        </select>
                    </div>
                    <div class="col-6">
                        <label for="status">Status <span class="status-circle" :class="{'available': status === SoftwareStatus.PUBLISHED}"></span></label>
                        <select v-model="status" class="form-control" id="status">
                            <option :disabled="(isProspect || hasEmptyCredentials) && status === SoftwareStatus.PUBLISHED" v-for="status in SoftwareStatus" :key="status" :value="status.valueOf()">{{status}}</option>
                        </select>
                    </div>
                </div>
            </div> 
        </div>
    </div>

    <!-- TAB APP -->
    <div class="row" v-if="currentTab === Tab.APP">
        <div class="col-6">
            <div class="form-group mt-4">
                <div class="box">
                    <div class="box-title">
                        Integrator Credentials
                    </div>
                    <div class="form-group">
                        <label for="creds_id">ID</label>
                        <input id="creds_id" type="text" v-model="integratorCredentials.softwareId" :readonly="isEdit" class="form-control"/>
                    </div>
                    <div class="form-group" v-if="isEdit">
                        <label for="creds_secret">Secret</label>
                        <input id="creds_secret" type="text" v-model="integratorCredentials.secret" readonly class="form-control user-select-all"/>
                    </div>
                    <div class="form-group" v-if="isEdit && hasEmptyCredentials">
                        <button class="btn btn-info" @click.prevent="generateCredentials()"><i class="fas fa-sync mr-1"></i> Generate credentials</button>
                    </div>
                </div>
           </div>

            <div class="form-group mt-5">
                <div class="box">
                    <div class="box-title">
                        On-Premise App
                    </div>

                    <div v-if="!isEdit" class="tip">
                        On-Premise app toevoegen kan enkel na het aanmaken van de software.
                    </div>
                   
                    <file-input 
                        v-else
                        v-on:input="onPremiseAppUpload" 
                        :multiple="false" 
                        :required="false" 
                        name="app"
                        placeholder=".zip" 
                        title="Upload app"
                        accept=".zip"></file-input>


                    <template v-if="onPremiseApp.path !== ''">
                        <div class="my-3 "><b>Upload link:</b> <span class="font-italic" style="user-select:all">{{onPremiseApp.path}}</span></div>
                        <div class="form-group small-editor">
                            <label>On-Premise tekst NL</label>
                            <vue-editor v-model="onPremiseApp.textNL" :editorToolbar="customToolbar"></vue-editor>
                        </div>   
                        <div class="form-group small-editor">
                            <label>On-Premise tekst FR</label>
                            <vue-editor v-model="onPremiseApp.textFR" :editorToolbar="customToolbar"></vue-editor>
                        </div>   
                        <div class="form-group small-editor">
                            <label>On-Premise tekst EN</label>
                            <vue-editor v-model="onPremiseApp.textEN" :editorToolbar="customToolbar"></vue-editor>
                        </div> 

                        <a href="" @click.prevent="deleteApp()" class="text-danger"><i class="fas fa-times mr-2"></i>Verwijder app</a>
                    </template>    
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <div class="box">
                    <div class="box-title">
                        Externe link
                    </div>
                    <div class="form-group small-editor">
                        <label>Tekst NL</label>
                        <vue-editor v-model="externalLinkNL" :editorToolbar="customToolbar"></vue-editor>
                    </div>   
                    <div class="form-group small-editor">
                        <label>Tekst FR</label>
                        <vue-editor v-model="externalLinkFR" :editorToolbar="customToolbar"></vue-editor>
                    </div>   
                    <div class="form-group small-editor">
                        <label>Tekst EN</label>
                        <vue-editor v-model="externalLinkEN" :editorToolbar="customToolbar"></vue-editor>
                    </div> 
                </div>
           </div>
        </div>
    </div>

    <!-- TAB DOWNLOADS -->
    <div class="row" v-if="currentTab === Tab.DOWNLOADS">
        <div class="col-6">
            <div class="form-group mt-3">
                <div class="box">
                    <div class="box-title">
                        Contacteer Partner
                    </div>
                    <div class="form-group small-editor">
                        <label>Tekst over partner NL</label>
                        <vue-editor v-model="partnerTextNL" :editorToolbar="customToolbar"></vue-editor>
                    </div>   
                    <div class="form-group small-editor">
                        <label>Tekst over partner FR</label>
                        <vue-editor v-model="partnerTextFR" :editorToolbar="customToolbar"></vue-editor>
                    </div>   
                    <div class="form-group small-editor">
                        <label>Tekst over partner EN</label>
                        <vue-editor v-model="partnerTextEN" :editorToolbar="customToolbar"></vue-editor>
                    </div> 
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-6">
                    <h2>Downloads</h2>
                </div>
                <div class="col-6 text-right">
                    <button v-if="isEdit" class="btn btn-ghost btn-sm" @click.prevent="createEmptyDownload()">
                        <i class="fas fa-file-plus mr-2"></i> Download toevoegen
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div v-if="!isEdit" class="tip">
                        Downloads toevoegen kan enkel na het aanmaken van de software.
                    </div>
                
                    <software-manual-component v-for="(download, index) in downloads" :manual.sync="downloads[index]" :key="index" :download="download" :index="index" @delete-download="deleteDownload"></software-manual-component>
                </div>
            </div>
        </div>
    </div>


    <!-- TAB SCREENSHOTS -->
    <div class="row" v-if="currentTab === Tab.SCREENSHOTS">
         <div class="col-12">
             <div class="row">
                 <div class="col-6">
                    <file-input 
                        v-on:input="onScreenshotUpload" 
                        :multiple="true" 
                        :required="false" 
                        name="logo"
                        placeholder=".png" 
                        title="Upload screenshots"
                        accept=".png,.jpg,.jpeg" ></file-input>
                 </div>

                 <div class="col-12 mt-4">
                     <div class="row">
                        <div class="col-2 my-auto relative" v-for="(screenshot, index) in allScreenshots" :key="'screen_' + index">
                            <button class="btn btn-danger delete-btn" @click.prevent="deleteScreenshot(screenshot)"><i class="fas fa-times"></i></button>
                            <img :src="screenshot.path" class="img-fluid"/>
                        </div>
                     </div>
                 </div>
             </div>
        </div>
    </div>

    <hr>

    <!-- TUSSENPERSOON -->
    <div class="box mt-5" v-if="tussenPersoon">
        <div class="box-title">Tussenpersoon</div>
        <div class="row">
           <div class="col-md-2">
                <b>Bedrijf</b>
           </div>
           <div class="col-md-4">
                {{tussenPersoon.companyName}} <span v-if="tussenPersoon.companyVat">({{tussenPersoon.companyVat}})</span>
           </div>

            <div class="col-md-2">
                <b>Contactpersoon</b>
           </div>
           <div class="col-md-4">
                {{tussenPersoon.contactName}}
           </div>
        </div>

        <div class="row mt-3">
           <div class="col-md-2">
                <b>Telefoon</b>
           </div>
           <div class="col-md-4">
                {{tussenPersoon.phone}}
           </div>

           <div class="col-md-2">
                <b>Gsm</b>
           </div>
           <div class="col-md-4">
                {{tussenPersoon.mobile}}
           </div>
        </div>

        <div class="row mt-3">
           <div class="col-md-2">
                <b>Adres</b>
           </div>
           <div class="col-md-4">
                {{tussenPersoon.address.lijn1}}<br>
                {{tussenPersoon.address.lijn2}}

           </div>

           <div class="col-md-2">
                <b>E-mailadres</b>
           </div>
           <div class="col-md-4">
                {{tussenPersoon.email}}
           </div>
        </div>

        
        <div class="row mt-3">
           <div class="col-md-2">
                <b>Website</b>
           </div>
           <div class="col-md-4">
                {{tussenPersoon.website}}
           </div>

           <div class="col-md-2">
                <b>Commissie</b>
           </div>
           <div class="col-md-4">
                {{(tussenPersoon.commission || 0) * 100}} %
           </div>
        </div>
    </div>
</form>
