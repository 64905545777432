<template>
    <text :fill="fill" :font-size="fontSize" :class="{'cursor-help': hasOverflow}" dominant-baseline="hanging">
        <title v-if="hasOverflow">{{content}}</title>
        <tspan v-for="(line, idx) in showedLines" :key="idx" :dy="15 * idx" x="15">
            {{line}}
            <tspan v-if="hasOverflow && idx === showedLines.length - 1">...</tspan>
        </tspan>
    </text>
</template>

<script>
    export default {
        name: 'svg-text',
        props: {
            fill: String,
            fontSize: Number,
            maxLines: Number,
            charactersPerLine: Number
        },
        data() {
            return {
                content: this.$slots.default[0].text || ""
            }
        },
        computed: {
            charlength() {
                return this.content.length;
            },
            hasOverflow() {
                return this.charlength > this.maxLines * this.charactersPerLine;
            },
            lines() {
                const amountOfLines = Math.ceil(this.charlength / this.charactersPerLine);
                const lines = [];
                const words = this.content.split(' ');

                for (let i = 0;i<amountOfLines;i++) {
                    const line =  []; 
                    let charactersLeft = this.charactersPerLine;

                    while (words.length) {
                        const word = words[0];
                        let wordLength = word.length;

                        if (wordLength <= charactersLeft || wordLength >= this.charactersPerLine) {
                            line.push(word);
                            charactersLeft = charactersLeft - wordLength - 1; //spatie;   
                            words.splice(0, 1);
                        } else {
                            break;
                        }
                    }
                    lines.push(line.join(" "));
                }

                return lines;
            },
            showedLines() {
                return this.lines.filter((_,index) => index < this.maxLines);
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>