﻿<template>
    <div>
        <template v-if="screeningLimitReached">
            <div class="info-box warning">{{translate('screening_limit')}}</div>
        </template>
        <template v-else>
            <div class="row">
                <div class="col-md-12">
                    <h3><b>Screening</b></h3>
                    <p>
                        {{translate('screening_select_statussses_intro')}}
                    </p>
                </div>
            </div>
            <div class="row my-2 my-md-5">
                <div class="col-md-4 my-2 my-md-auto">
                    <vue-pie-chart :labels="pieLabels" :colors="pieColors" :numbers="pieNumbers"></vue-pie-chart>
                </div>
                <div class="col-md-7 offset-md-1">
                    <div class="row mb-2">
                        <div class="col-4">
                            <b>{{translate('status')}}</b>
                        </div>
                        <div class="col-3">
                            <b>{{translate('amount')}}</b>
                        </div>
                        <div class="col-5">
                            <b>{{translate('add_to_selection')}}</b>
                        </div>
                    </div>
                    <div class="row mb-2" v-for="(status, key) in statusses" :key="key">
                        <div class="col-4">
                            <span class="status" :class="status.name">{{translate(status.name)}}</span>
                        </div>
                        <div class="col-3">
                            {{status.companies.length}}
                        </div>
                        <div class="col-5">
                            <slide-toggle azure v-model="status.isSelected" :name="status.name"></slide-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-9 mb-2 mb-md-0">
                    <alerts-usage :used="totalUsed" :selected="totalSelected" :max="maxCompanies"></alerts-usage>
                </div>
                <div class="col-md-3 my-auto">
                    <a href="" class="btn btn-blue btn-block" @click.prevent="proceed()">{{proceedBtnLabel}} <i class="fas fa-angle-right ml-2"></i></a>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import AlertsUsage from './alerts-usage.vue';
    import VuePieChart from '../../../shared/vue-pie-chart.vue';
    import SlideToggle from '../../../shared/slide-toggle.vue';
    import Translate from 'Mixins/translate';
    import _ from 'lodash';
    import Ajax from 'Lib/Ajax';
    import Toast from 'Lib/Toast';
    import Vue from 'vue';

    export default Vue.extend({
        name: 'screening-select-statusses',
        components: { VuePieChart, SlideToggle, AlertsUsage },
        mixins: [Translate],
        props: {
            barometerstatusses: Object,
            currentList: Object
        },
        data() {
            return {
                statusses: [
                    {
                        name: 'zeer-negatief',
                        isSelected: false,
                        companies: this.barometerstatusses['zeer-negatief'] || [],
                        color: '#F44336'
                    },
                    {
                        name: 'negatief',
                        isSelected: false,
                        companies: this.barometerstatusses['negatief'] || [],
                        color: '#F9821E'
                    },
                    {
                        name: 'matig',
                        isSelected: false,
                        companies: this.barometerstatusses['matig'] || [],
                        color: '#f1d01c'
                    },
                    {
                        name: 'positief',
                        isSelected: false,
                        companies: this.barometerstatusses['positief'] || [],
                        color: '#94B633'
                    },
                    {
                        name: 'zeer-positief',
                        isSelected: false,
                        companies: this.barometerstatusses['zeer-positief'] || [],
                        color: '#4CAF50'
                    },
                    {
                        name: 'geen-score',
                        isSelected: false,
                        companies: this.barometerstatusses['geen-score'] || [],
                        color: '#a2a2a2'
                    },
                    {
                        name: 'niet-actief',
                        isSelected: false,
                        companies: this.barometerstatusses['niet-actief'] || [],
                        color: '#707384'
                    }
                ]
            };
        },
        computed: {
            proceedBtnLabel() {
                return this.isLimitExceeded
                    ? this.translate('screening_remove_vats_title')
                    : this.translate('btn_complete');
            },
            totalUploaded() {
                let total = 0;

                for (var key in this.barometerstatusses) {
                    total += this.barometerstatusses[key].length;
                }

                return total;
            },
            screeningLimitReached() {
                return this.totalUploaded > 5000;
            },
            totalAvailable() {
                return this.$root.amountOfFollowupsAvailable;
            },
            totalUsed() {
                return this.$root.amountOfFollowupsUsed;
            },
            maxCompanies() {
                return this.totalUsed + this.totalAvailable;
            },
            selectedCompanies() {
                return _.flatMap(_.filter(this.statusses, 'isSelected'), 'companies');
            },
            selectedVats() {
                return _.map(this.selectedCompanies, 'Vat');
            },
            totalSelected() {
                return _.flatMap(_.filter(this.statusses, 'isSelected'), 'companies').length;
            },
            isLimitExceeded() {
                return this.totalSelected > this.totalAvailable;
            },
            pieLabels() {
                return _.map(this.statusses, status => this.translate(status.name));
            },
            pieNumbers() {
                return _.map(this.statusses, 'companies.length');
            },
            pieColors() {
                return _.map(this.statusses, 'color');
            }
        },
        methods: {
            updateList() {
                return new Promise((resolve, reject) => {
                    Ajax.get(
                        '/ajax/followup',
                        {
                            listId: this.currentList.ListId
                        },
                        (response) => {
                            let companies = response.Data.Companies;
                            let events = response.Data.FollowUpEvents;
                            this.$root.$emit('updateList', {
                                listId: this.currentList.ListId,
                                companies,
                                events
                            });
                            resolve();
                        },
                        () => {
                            reject();
                        }
                    );
                });
            },
            proceed() {
                if (this.isLimitExceeded) {
                    this.$root.$emit('change-view', {
                        viewName: 'remove-vats-from-screening',
                        viewData: {
                            totalUsed: this.totalUsed,
                            maxCompanies: this.maxCompanies,
                            selectedCompanies: this.selectedCompanies,
                            barometerstatusses: this.barometerstatusses
                        },
                        currentList: this.currentList
                    });
                } else {
                    this.$root.$emit('setLoading', true);
                    Ajax.post(
                        '/ajax/followup/add-companies',
                        {
                            listId: this.currentList.ListId,
                            vatList: this.selectedVats
                        },
                        response => {
                            this.updateList()
                                .then(() => {

                                    this.$root.$emit('setLoading', false);
                                    this.$root.$emit('change-used-companies');
                                    this.$root.$emit('hide-modal', 'addCompanies');
                                    this.$root.$emit('hide-modal', 'addList');

                                    Toast.success(this.translate('list_updated', this.currentList.Naam));
                                });
                        },
                        err => {
                            Toast.error(err);
                            this.$root.$emit('hide-modal', 'addCompanies');
                            this.$root.$emit('hide-modal', 'addList');
                            this.isLoading = false;
                        }
                    )
                }
            }
        },
        created() {
            let modalId = this.$parent.$parent.id;

            this.$root.$emit('resize-modal', {
                modalId: modalId,
                size: 'xl'
            });
        }
    });
</script>