﻿import {
    BooleanFilterCriteria,
    ChangeableProperty,
    Column,
    DateRangeFilterCriteria,
    FreeTextFilterCriteria,
    NumericRangeFilterCriteria,
    RadarCompany,
    SelectionListFilterCriteria,
    SelectionTreeFilterCriteria
} from './types';
import translate from "../../lib/Translate";

const fraudScoreColumnGenerator = (title: string, key: keyof RadarCompany): Column => {
    return {
        title: title,
        hidden: false,
        isVertical: true,
        showDescription: true,
        isBold: false,
        toClass: (v) => `risk-score risk-${(v[key] as ChangeableProperty<number>).value}`,
        toHtml: (v) => `${(v[key] as ChangeableProperty<number>).value}`,
        toString: (v) => `${(v[key] as ChangeableProperty<number>).value}`,
        sortMapper: (v) => (v[key] as ChangeableProperty<number>).value,
        lastUpdated: (v) => (v[key] as ChangeableProperty<number>).updateDate,
        filterCriteria: new SelectionListFilterCriteria(),
        matchesCriteria: (v: RadarCompany, criteria: SelectionListFilterCriteria) => criteria.matchesCriteria((v[key] as ChangeableProperty<number>).value.toString())
    } as Column;
}

const getColumns = () => {
    return [
        {
            title: "bedrijfsleiding",
            hidden: false,
            isVertical: false,
            showDescription: false,
            toClass: (v) => "",
            toHtml: (v) => {
                let t = "<div class='font-small'>"

                for (let i = 0; i < Math.min(v.mandateHolders.length, 5); i++) {
                    t += v.mandateHolders[i].link
                        ? "<a target='_blank' class='d-block' href='" + v.mandateHolders[i].link + "'>" + v.mandateHolders[i].name + "</a>"
                        : "<div>" + v.mandateHolders[i].name + "</div>"
                }

                if (v.mandateHolders.length > 5) {
                    const link = `/company/${v.vat}/directors`;
                    t += "<a target='_blank' class='d-block link text-lightblue' href='" + link + "'>Alle bestuurders</a>";
                }

                t += "</div>"

                return t;
            },
            toString: (v) => v.mandateHolders.join(", "),
            sortMapper: (v) => v.mandateHolders.join(", "),
            lastUpdated: (v) => undefined,
            matchesCriteria: (v: RadarCompany, criteria: FreeTextFilterCriteria) => criteria.matchesCriteria(v.mandateHolders.map(mh => mh.name)),
            filterCriteria: new FreeTextFilterCriteria(),
            minWidth: 175
        },
        {
            title: "last_bestuurswissel",
            hidden: false,
            isVertical: false,
            showDescription: false,
            toClass: (v) => "",
            toHtml: (v) => !!v.lastBestuursWissel ? Date.fromHansDate(v.lastBestuursWissel).format() : "/",
            toString: (v) => !!v.lastBestuursWissel ? Date.fromHansDate(v.lastBestuursWissel).format() : "/",
            sortMapper: (v) => v.lastBestuursWissel ? v.lastBestuursWissel : 0,
            lastUpdated: (v) => undefined,
            matchesCriteria: (v: RadarCompany, criteria: DateRangeFilterCriteria) => criteria.matchesCriteria(v.lastBestuursWissel ? Date.fromHansDate(v.lastBestuursWissel) : undefined),
            filterCriteria: new DateRangeFilterCriteria(),
            minWidth: 110
        },
        {
            title: "begindatum",
            hidden: false,
            isVertical: false,
            showDescription: false,
            toClass: (v) => "",
            toHtml: (v) => Date.fromHansDate(v.beginDatum).format(),
            toString: (v) => Date.fromHansDate(v.beginDatum).format(),
            sortMapper: (v) => v.beginDatum,
            lastUpdated: (v) => undefined,
            matchesCriteria: (v: RadarCompany, criteria: DateRangeFilterCriteria) => criteria.matchesCriteria(v.beginDatum ? Date.fromHansDate(v.beginDatum) : undefined),
            filterCriteria: new DateRangeFilterCriteria(),
            minWidth: 110
        },
        {
            title: "einddatum",
            hidden: false,
            isVertical: false,
            showDescription: false,
            toClass: (v) => "",
            toHtml: (v) => !!v.eindDatum ? Date.fromHansDate(v.eindDatum).format() : "-",
            toString: (v) => !!v.eindDatum ? Date.fromHansDate(v.eindDatum).format() : "-",
            sortMapper: (v) => v.eindDatum ? v.eindDatum : 0,
            lastUpdated: (v) => undefined,
            matchesCriteria: (v: RadarCompany, criteria: DateRangeFilterCriteria) => criteria.matchesCriteria(v.eindDatum ? Date.fromHansDate(v.eindDatum) : undefined),
            filterCriteria: new DateRangeFilterCriteria(),
            minWidth: 110
        },
        {
            title: "nace",
            hidden: false,
            isVertical: false,
            showDescription: false,
            toClass: (v) => "",
            toHtml: (v) => `<div class="font-small">${v.hoofdNaceCode.value} - ${v.hoofdNace !== '' ? v.hoofdNace : ''}</div>`,
            toString: (v) => `${v.hoofdNace}`,
            sortMapper: (v) => v.hoofdNace,
            lastUpdated: (v) => v.hoofdNaceCode.updateDate,
            filterCriteria: new SelectionTreeFilterCriteria(),
            matchesCriteria: (v: RadarCompany, criteria: SelectionTreeFilterCriteria) => criteria.matchesCriteria(v.hoofdNaceCode.value),
            minWidth: 250
        },
        {
            title: "beroepenindex",
            hidden: false,
            isVertical: false,
            showDescription: false,
            toClass: (v) => "",
            toHtml: (v) => `<div class="font-small">${!!v.beroepenIndexDescription ? v.beroepenIndexDescription : '-'}</div>`,
            toString: (v) => `${v.beroepenIndexDescription}`,
            sortMapper: (v) => v.beroepenIndexDescription?.toString() ?? '',
            lastUpdated: (v) => undefined,
            filterCriteria: new SelectionTreeFilterCriteria(),
            matchesCriteria: (v: RadarCompany, criteria: SelectionTreeFilterCriteria) => criteria.matchesCriteria(v.beroepenIndex ?? ""),
            minWidth: 110
        },
        {
            title: "employees",
            hidden: false,
            isVertical: false,
            showDescription: false,
            toClass: (v) => "",
            toHtml: (v) => `${v.numberOfEmployees.formatThousandSeparator()}`,
            toString: (v) => `${v.numberOfEmployees}`,
            sortMapper: (v) => v.numberOfEmployees,
            lastUpdated: (v) => undefined,
            filterCriteria: new NumericRangeFilterCriteria({
                lowerBound: undefined,
                upperBound: undefined
            }, 0, window.cw.indexFile.maxEmployees, false),
            matchesCriteria: (v: RadarCompany, criteria: NumericRangeFilterCriteria) => criteria.matchesCriteria(v.numberOfEmployees),
            isBold: false,
            minWidth: 88
        },
        {
            title: "btwplichtig",
            hidden: true,
            isVertical: false,
            showDescription: false,
            toClass: (v) => "",
            toHtml: (v) => {
                let t = "<input type='checkbox'"
                if (v.btwPlichtig){
                    t += " checked='checked'"
                }
                return t + " disabled>";
            },
            toString: (v) => v.btwPlichtig ? translate('filter_yes') : translate('filter_no'),
            sortMapper: (v) => v.btwPlichtig ? 1 : 0,
            lastUpdated: (v) => undefined,
            matchesCriteria: (v: RadarCompany, criteria: BooleanFilterCriteria) => criteria.matchesCriteria(v.btwPlichtig),
            filterCriteria: new BooleanFilterCriteria(),
            minWidth: 110
        },
        {
            title: "postcode",
            hidden: false,
            isVertical: false,
            showDescription: true,
            toClass: (v) => "",
            toHtml: (v) => `${v.postCode.value}`,
            toString: (v) => `${v.postCode.value}`,
            sortMapper: (v) => v.postCode.value,
            lastUpdated: (v) => v.postCode.updateDate,
            filterCriteria: new SelectionListFilterCriteria(),
            matchesCriteria: (v: RadarCompany, criteria: SelectionListFilterCriteria) => criteria.matchesCriteria(v.postCode.value.toString()),
            isBold: false,
            minWidth: 88
        },
        {
            title: "total_score",
            hidden: false,
            isVertical: true,
            showDescription: true,
            toClass: (v) => "",
            toHtml: (v) => `${v.totalFraudScore.value}`,
            toString: (v) => `${v.totalFraudScore.value}`,
            sortMapper: (v) => v.totalFraudScore.value,
            lastUpdated: (v) => v.totalFraudScore.updateDate,
            filterCriteria: new NumericRangeFilterCriteria({
                lowerBound: undefined,
                upperBound: undefined
            }, 0, window.cw.indexFile.maxTotalScore),
            matchesCriteria: (v: RadarCompany, criteria: NumericRangeFilterCriteria) => criteria.matchesCriteria(v.totalFraudScore.value),
            isBold: true
        },
        fraudScoreColumnGenerator("late_fin_state", "lateFinancialstatements"),
        fraudScoreColumnGenerator("fake_fin_state", "fakeFinancialstatements"),
        fraudScoreColumnGenerator("neg_equity", "negativeEquity"),
        fraudScoreColumnGenerator("kl_zero", "creditLimitZero"),
        fraudScoreColumnGenerator("warning_liq", "warningLiquidity"),
        fraudScoreColumnGenerator("vat_deleted", "vatDeleted"),
        fraudScoreColumnGenerator("address_deleted", "addressCrossedOut"),
        fraudScoreColumnGenerator("adm_canceled", "administrativelyCanceled"),
        fraudScoreColumnGenerator("danger_pers", "hasDangerousPerson"),
        fraudScoreColumnGenerator("danger_adr", "hasDangerousAddress"),
        fraudScoreColumnGenerator("busy_adr", "hasBusyAddress"),
        fraudScoreColumnGenerator("business_center", "movedToBusinessCenter"),
        fraudScoreColumnGenerator("multi_move", "multiMove"),
        fraudScoreColumnGenerator("rsz", "rszDagvaardingCount"),
        fraudScoreColumnGenerator("inhouding", "hasInhoudingsplicht"),
        fraudScoreColumnGenerator("many_nace", "hasManyNaceCodes"),
        fraudScoreColumnGenerator("crime", "hasCrimeLink"),
        fraudScoreColumnGenerator("fail_en_new", "hasMandateHolderWithFailedAndNewCompanies"),
        fraudScoreColumnGenerator("new_mgmt", "hasNewManagement"),
        fraudScoreColumnGenerator("mgmt_change_cnt", "managementChangeCount"),
        fraudScoreColumnGenerator("foodweb", "foodwebScore"),
        fraudScoreColumnGenerator("zombie", "zombie"),
        fraudScoreColumnGenerator("ghost", "ghost"),
        {
            title: "custom_categories",
            isFilterOnly: true,
            forceExpandTree: true,
            isVertical: false,
            showDescription: false,
            toClass: (v) => "",
            toHtml: (v) => "html",
            toString: (v) => "string",
            sortMapper: (v) => v.customCategories.toString(),
            lastUpdated: (v) => undefined,
            filterCriteria: new SelectionTreeFilterCriteria(),
            matchesCriteria: (v: RadarCompany, criteria: SelectionTreeFilterCriteria) => criteria.matchesMultiCriteria(v.customCategories)
        },
    ] as Column[];
}

export default getColumns;