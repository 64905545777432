
<div class="usage-bar-wrapper" :style="'width:' + width + 'px;'">
    <div class="usage-bar">
        <div class="used" :style="'width:' + usedWidth + 'px;'">
            <span class="number">{{translate('used')}}: {{used}}</span>
        </div>
        <div class="selected" :class="{'red':isLimitExceeded}" :style="'left:' + usedWidth + 'px; width:' + selectedWidth + 'px;'">
            <span class="number">{{translate('used_plus_selection')}}: {{selected + used}}</span>
        </div>
    </div>
    <div class="maximum">{{max}} ({{translate('limit')}})</div>
</div>
