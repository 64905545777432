﻿import Vue from 'vue';
import Page from '../lib/Page';
import BtwFileInput from './shared/btw-file-input.vue';

export default (function () {
    'use strict';

    Page.registerVue('#vatFileInput', function (rootSelector) {
    
        new Vue({
            el: rootSelector,
            data() {
                return {
                    fileIdentifier: "",
                    vatColumnIndex: 0,
                    refColumnIndex: 0
                };
            },
            components: {
                BtwFileInput
            },
            methods: {
                async onUpload(vatUploadResponse: VatInputEmit) {
                    this.fileIdentifier = vatUploadResponse.fileIdentifier;
                    this.vatColumnIndex = vatUploadResponse.vatColumnIndex;
                    this.refColumnIndex = vatUploadResponse.referenceColumnIndex;
                }
            }
        });
    });
}());
