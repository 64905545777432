﻿import Confirm from '../lib/Confirmation';
import Page from '../lib/Page';

export default (function () {

    // Usage:
    // Links with the html attribute method="post" 
    // will be submitted via POST instead of GET when clicked.
    // Since we don't want these links to function via GET, 
    // the attribute for the url is "action", not "href".
    // <a action="/account/logout" method="post">logout</a>
    Page.registerBehavior('a[method=post]', function (element) {
        element
            .addEventListener('mousedown', function (ev: Event) {
                ev.stopPropagation();
                ev.preventDefault();
            
                /* @ts-ignore*/
                const middleMouseClick = ev.type === 'mousedown' && ev.which === 2;
                const href = element.getAttribute('action') || element.getAttribute('href');
                const target = element.getAttribute('target');

                if (middleMouseClick || target === "_blank")
                    post(element, href);
            });

        element.addEventListener('click', function (ev: Event) {
            ev.stopPropagation();
            ev.preventDefault();

            const href = element.getAttribute('action') || element.getAttribute('href');
           
            post(element, href);
        });
    });

    function post($element: Element, href: string | null) {
        Confirm(
            function () {
                document.body.insertAdjacentHTML("beforeend",'<form id="injectedForm" method="post" action="' + href + '"' + '></form>');
                
                const form = document.getElementById('injectedForm') as HTMLFormElement;

                for (let i = 1, data; data = $element.getAttribute('post-data-' + i); i++) {
                    const match = /^([^:]+):(.*)$/.exec(data);

                    if (match) {
                        form.insertAdjacentHTML("beforeend",'<input type="hidden" name="' + match[1] + '" value="' + match[2] + '"/>');
                    }
                }

                form.submit();
            },
            {
                title: $element.getAttribute("data-confirmation") ?? "",
                body: $element.getAttribute("data-confirmation-body") ?? "",
                cancelButtonText: $element.getAttribute('data-confirmation-btn-cancel') ?? "",
                confirmButtonText: $element.getAttribute('data-confirmation-btn-confirm') ?? ""
            }
        );
    }
}());
