
<div class="cw-dropdown"  v-click-outside="close">
    <div @click.stop.prevent="toggle()" class="cw-dropdown__label"><slot name="toggler"></slot></div>
    <transition name="dropdown">
        <ul class="cw-dropdown__list" :class="{'active': isOpen}"
             @click="close()">
            <slot name="content">Please provide a slot with name 'content'</slot>
        </ul>
    </transition>
</div>
