﻿import Page from '../lib/Page';
import _ from 'lodash';

export default (function () {

    Page.registerBehavior('.nav-tabs [role=tab]', function (tab) {
        if(tab.parentElement?.parentElement?.classList.contains('no-tab-behaviour'))
            return;

        const href = tab.getAttribute('href');
        if (!href || href === "#")
            return;

        tab.addEventListener('click', function (ev: Event) {
            ev.preventDefault();
            _.forEach(document.querySelectorAll('.nav-link'), tabPane => tabPane.classList.remove('active'));
            _.forEach(document.querySelectorAll('.tab-pane'), tabPane => tabPane.classList.remove('active'));

            const targetId = tab.getAttribute('data-target')!;
            document.querySelector(targetId)?.classList.add('active');
            tab.classList.add('active');
            history.replaceState({}, document.title, href);
        });
    });
}());
