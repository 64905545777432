
<div class="row">
    <div class="col-md-12">
        <p>
            {{translate('please_select_vats')}}
        </p>
    </div>
    <template v-if="!error">
        <div class="d-flex col-md-12 may-overflow-x my-3">
            <div v-for="column in columns"
                :key="column.Index"
                 class="column pointer"
                 :class="{'selected': column.Index === selectedColumnIndex}"
                 @click="selectColumn(column)">
                <span class="font-weight-bold">{{column.Name}}</span>
                <div v-for="(row, index) in column.Rows" class="row" :key="'row'+index">
                    <div v-html="row" class="col-md-12"></div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="info-box warning mb-2" v-if="!hasSelectedIndex">{{translate('select_column_error')}}</div>
        </div>
        <div class="col-md-12">
            <button class="btn btn-blue btn-200" @click.prevent="ajaxSelectColumns()" :disabled="!hasSelectedIndex">{{translate('next')}}</button>
        </div>
    </template>
    <template v-else>
        <div class="col-md-12">
            <div class="card-box small warning">{{error}}</div>
        </div>
        <div class="col-md-12 mt-4">
            <button class="btn btn-default btn-200" @click.prevent="backToUpload()">{{translate('btn_back')}}</button>
        </div>
    </template>
</div>
