﻿<template>
    <div class="col-md-12 vert-offset-top-1">
        <table class="table data-table">
            <thead>
                <tr>
                    <th colspan="4">{{translate(name)}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(company, index) in orderedResults"
                    v-bind="company"
                    is="result-row"
                    :key="index"></tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    import _ from 'lodash';
    import Row from './row.vue';
    import Translate from 'Mixins/translate';

    export default {
        name: 'result-list',
        components: {
            'result-row': Row
        },
        mixins: [Translate],
        props: {
            results: Array,
            name: String
        },
        data() {
            return {
               
            }
        },
        computed: {
            orderedResults() {
                return _.orderBy(
                    this.results,
                    [
                        (result) => result.isActive,
                        (result) => result.barometer && result.barometer.indexOf('failliet') !== -1,
                        (result) => result.companyEndYear
                    ],
                    [
                        'desc',
                        'desc',
                        'desc'
                    ]
                );
            }
        }
    }
</script>