
    export interface Coordinate {
        x: number;
        y: number;
    };

    export interface Position {
        row: number;
        column: number;
    };

    export interface Shares {
        isKnown: boolean;
        whole: number;
        decimals: number;
    };

    export interface Info {
        fromVat: number;
        linkType: string;
        shares: string;
    }

    export interface Company {
        vat: number;
        name: string;
        form: string;
        barometerScore: string;
        barometerClassName: string;
        activity: string;
    }

    export interface RootCompany extends Company {
        address: string;
        status: string;
        startDate: string;
        locations: number;
        size: string;
        amountOfEmployees: number;
        amountOfWarnings: number;
    }

    export interface PersonName {
        firstname: string;
        lastname: string;
    } 

    export interface Hit {
        id: number;
        nameId: number;
        checkId: number;
        name: PersonName;
        birthDate: string | null;
        nationality: string | null;
        listNumber: string | null;
        listName: string | null;
        hitType: string | null;
        reason: string | null;
        score: number;
        isPerson: boolean;
        isActive: boolean;
        hash: string;
        lastUpdate: Date;
    }

    export enum LinkType {
        MandateHolder = "mandateholder",
        ShareHolder = "shareholder",
        MandateHolderShareHolder = "mandateholder-shareholder"
    }

    export enum CheckStatus {
        Pending = "pending",
        Processing = "processing",
        Success = "success",
        Failure = "failure",
        Timeout = "timeout"
    }

    export interface ChainLink {
        vat: number;
        linkType: LinkType;
        shares: string;
    }

    export interface Person {
        id: number;
        firstName: string;
        lastName: string;
        numberOfActiveMandates: number;
        numberOfFails: number;
        location: string;
        chains: Array<ChainLink[]>;
        lastCheckStatus_: CheckStatus;
        lastCheckDate_: string;
        hits: Hit[]
    }

    export interface KycPerson {
        hits: Hit[];
        lastCheck: {
            status: CheckStatus;
            updated: string;
        };
        lastSuccessfulCheck: string;
        name: PersonName;
    }

    export interface PollingEmitResult {
        person: KycPerson;
        personId: number;
    }
    
    export interface TreeNode {
        vat: number;
        children: number[];
    }
    
    export interface NestedTreeNode {
        vat: number;
        children: NestedTreeNode[];
    }
