﻿<template>
    <div>
        <input v-model="filterTerm" class="form-control" :placeholder="translate('zoekterm')" type="search"/>
        <div class="filter-overflow-box mt-4" :class="{'limited-height':limitedHeight}">
            <ul>
                <li v-for="filter in shownFilterValues" :key="filter">
                    <input :id="filter"
                           :checked="column.filterCriteria._value.includes(filter)"
                           class="form-check-input azure"
                           type="checkbox"
                           @change="toggleFilter(column, filter)"/>
                    <label :for="filter" class="wrap-normal d-block"><span>{{ filter }}</span></label>
                </li>
            </ul>
            <a class="link mt-2 d-inline-block" v-if="maxShowCount <= shownFilterValues.length" href="" @click.prevent="maxShowCount+=100">Meer laden</a>
        </div>
    </div>
</template>

<script lang="ts">
    import Translate from '../../../../mixins/translate';
    import {Column} from '../../../types';
    import _ from 'lodash';
    import {useStore} from "../../../store";
    import Option from '../../../../../types/global/option'
    
    export default Translate.extend({
        name: "selection-list",
        props: {
            limitedHeight: Boolean,
            column: {
              type:Object as () => Column,
              required: true  
            }
        },
        data: () => {
            return {
                filterTerm: "",
                store: useStore(),
                maxShowCount: 100
            }
        },
        methods: {
            toggleFilter(col: Column, filter: string) {
                let current = (col.filterCriteria._value as string[]);

                if(current.includes(filter))
                    current.remove(filter);
                else
                    current.push(filter);
            }
        },
        computed: {
            possibleFilterValues(): string[] {
                return _.uniq(this.store.filteredCompanies(this.column).map(c => this.column.toString(c)));
            },
            shownFilterValues(): string[] {
                return this.possibleFilterValues
                .filter(v => v.cw_contains(this.filterTerm))
                .filter(v => v !== 'null')
                .sortAlpha()
                .splice(0,this.maxShowCount);
            },
        }
    });
</script>

<style scoped lang="scss">
$primColor: #0368FF;
.limited-height {
    max-height: 350px;
    overflow-y: auto;
}
.link {
    text-transform: none;
    color: $primColor;
    text-decoration: none !important;

    &:hover {
        text-decoration: underline !important;
    }
}
</style>