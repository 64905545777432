﻿<template>
    <g v-if="show">
        <polygon :points="polygonPoints" :fill="color|toHexCode" fill-opacity="0.07"></polygon>
        <line :x1="x1" :y1="tween.y1" :x2="x2" :y2="tween.y2" :stroke="color|toHexCode" stroke-width="1"></line>
    </g>
</template>

<script>
    import gsap from 'gsap';

    export default {
        name: 'chart-segment',
        props: {
            valStart: Number,
            valEnd: Number,
            year: String || Number,
            index: Number
        },
        data() {
            return {
                tween: {
                    y1: 0,
                    y2: 0
                }
            };
        },
        inject: ['valueToYPos', 'color'],
        watch: {
            y1: function (newValue) {
                gsap.to(this.tween, 0.6, { y1: newValue });
            },
            y2: function (newValue) {
                gsap.to(this.tween, 0.6, { y2: newValue });
            }
        },
        computed: {
            show() {
                return !isNaN(this.valStart) && !isNaN(this.valEnd);
            },
            x1() { return this.index * this.$root.yearWidth },
            x2() { return this.x1 + this.$root.yearWidth },
            x3() { return this.x2 },
            x4() { return this.x1 },
            y1() { return this.valueToYPos(this.valStart) },
            y2() { return this.valueToYPos(this.valEnd) },
            y3() { return this.valueToYPos(0) },
            y4() { return this.y3 },
            polygonPoints() {
                return `${this.x1},${this.tween.y1} ${this.x2},${this.tween.y2} ${this.x3},${this.y3} ${this.x4},${this.y4}`
            }
        },
        mounted() {
            this.tween.y1 = this.y1;
            this.tween.y2 = this.y2;
        }
    }
</script>