﻿import _ from 'lodash';
import Vue from 'vue';
import Page from 'Lib/Page';
import Timeline from './timeline.vue';
import * as Sentry from '@sentry/browser';

export default (function () {

    Page.registerVue('#timeline', function (rootSelector) {
        
        new Vue({
            el: rootSelector,
            components: {
                'timeline': Timeline
            },
            data: {
                startDate: window.cw.timeline.startDate,
                endDate: window.cw.timeline.endDate,
                mijlpalen: window.cw.mijlpalen,
                names: window.cw.names,
                addresses: window.cw.addresses,
                financialYears: window.cw.financialYears,
                mandateHolders: window.cw.mandateHolders,
                dagvaardingen: window.cw.dagvaardingen,
                inhoudingsplicht: window.cw.inhoudingsplicht,
                gerechtelijkakkoord: window.cw.gerechtelijkakkoord
            }
        });

    });
}());
