﻿<template>
    <div ref="country-dropdown-wrapper">
        <div @click="showDropdown = !showDropdown" class="country-dropdown">
            <div v-if="currentCountry">
                <img :alt="currentCountry.name" :src="'/images/flags/' + currentCountry.code + '.svg'" width="18" height="18" class="mr-1">
                {{currentCountry.name}}
            </div>
            <i class="fas fa-angle-down ml-3 text-grey"></i>
        </div>
        <div v-if="showDropdown">
            <transition name="slide-fade">
                <div id="autocompleteWrapper" class="autocomplete-wrapper country-variant">
                    <input
                            ref="searchTerm"
                            type="search"
                            class="form-control"
                            v-model="searchTerm"
                            :placeholder="translate('type_to_search')"
                            v-on:search="makeSuggestions"
                            v-on:keyup="makeSuggestions"
                            v-on:keyup.down="selectNext"
                            v-on:keyup.up="selectPrev"
                            v-on:keypress.enter.stop="acceptSelected"
                            v-on:keydown.tab.stop="acceptSelected"/>
                    <div class="countries">
                        <div  v-for="(country, index) in suggestions" 
                              :key="index" 
                              class="suggestion-selector" 
                              :class="{'selected': selectedIndex === index}" 
                              v-on:click="onCountryClick(index)">
                            <img :alt="country.name" :src="'/images/flags/' + country.code + '.svg'" width="18" height="18" class="mr-1"> {{country.name}}
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script lang="ts">
    import {Country} from '../types';
    import Translate from '../../mixins/translate';
    
    const KeyCodes = {
        Tab: 9,
        Enter: 13,
        Shift: 16,
        ArrowLeft: 37,
        ArrowUp: 38,
        ArrowRight: 39,
        ArrowDown: 40
    };
    
    export default Translate.extend({
        name: "country-input",
        props: {
            countries: Array as () => Country[],
            value: String
        },
        data() {
            return {
                searchTerm: '',
                suggestions: this.countries as Country[],
                selectedIndex: 0,
                showDropdown: false
            }
        },
        watch: {
          showDropdown(val: boolean) {
              if(val) {
                  this.$nextTick(() => {
                      (this.$refs.searchTerm as HTMLInputElement).focus();
                  })
              }
                  
          }  
        },
        computed: {
            currentCountry(): Country | undefined {
                return this.countries.find(c => c.code === this.value)  
            }
        },
        methods: {  
            makeSuggestions(event: KeyboardEvent) {
                const noInputKeyPressed =
                    event.keyCode === KeyCodes.Tab ||
                    event.keyCode === KeyCodes.Shift ||
                    event.keyCode === KeyCodes.Enter ||
                    event.keyCode === KeyCodes.ArrowLeft ||
                    event.keyCode === KeyCodes.ArrowUp ||
                    event.keyCode === KeyCodes.ArrowRight ||
                    event.keyCode === KeyCodes.ArrowDown;

                if (noInputKeyPressed)
                    return;

                if (this.searchTerm.length == 0) {
                    this.suggestions = this.countries;
                    return;
                }

                this.suggestions = this.countries
                    .filter(country => country.name.cw_contains(this.searchTerm))
                    .sort((a,b) => {
                        if (a.name.toLowerCase().startsWith(this.searchTerm.toLowerCase()))
                                return -1;
                        else if (b.name.toLowerCase().startsWith(this.searchTerm.toLowerCase()))
                            return 1;
                        
                        return a.name < b.name ? -1 : 1;
                    });
            },
            selectPrev(): void {
                this.selectedIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : 0;
            },
            selectNext(): void {
                const limit = this.suggestions.length - 1;
                this.selectedIndex = this.selectedIndex < limit ? this.selectedIndex + 1 : limit;
            },
            onCountryClick(index: number): void {
                this.selectedIndex = index;
                this.acceptSelected();
            },
            acceptSelected() {
                const selected = this.suggestions[this.selectedIndex];
                if(!selected) {
                    this.$emit('input', '');
                    return;
                }
                    
                this.$emit('input', selected.code);
                this.selectedIndex = 0;
                this.showDropdown = false;
                this.setCountryCodeInUrl(selected.code);
            },
            setCountryCodeInUrl(code: string) {
                const pathWithoutCountryCode = (new RegExp("/international/?(?:search/)?\(?:d{2})?").exec(window.location.pathname)?.[0] ?? '').trim_right('/');
                history.replaceState({}, document.title, pathWithoutCountryCode + '/' + code);
            }
        },
        mounted() {
            function isDescendant(parent: HTMLElement, child: HTMLElement) {
                let node = child.parentNode;
                while (node != null) {
                    if (node == parent) {
                        return true;
                    }

                    node = node.parentNode;
                }
                return false;
            }

            document.addEventListener('click', (event: MouseEvent) => {
                const containerEl = (this.$refs['country-dropdown-wrapper'] as HTMLElement);

                if (!isDescendant(containerEl, (event.target as HTMLElement)))
                    this.showDropdown = false;
            });
        }
    });
</script>

<style scoped lang="scss">
    .country-dropdown {
        padding: 8px 13px;
        border: 1px solid #dee0e0;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    input {
      border: 0;
      border-bottom: 1px solid #dee0e0;
      border-radius: 0;
      padding-left: 40px;
    }
    .autocomplete-wrapper {
      padding: 0 0;
      top: 100%;
      box-shadow: 0 10px 11px -2px rgb(0 0 0 / 22%);
      border: 1px solid #dee0e0;
      
      &:before {
        content: "";
        font-family: "Font Awesome 5 Pro";
        font-weight: 600;
        vertical-align: middle;
        position: absolute;
        text-align: center;
        height: 37px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: lightgrey;
      }
      
      .countries {
        max-height: 320px;
        overflow-y: auto;
      }
    }
</style>