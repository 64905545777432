﻿<template>
    <div>
        <h3 v-if="showSubtitle">
            {{translate('feature_' + feature)}}
        </h3>
        <component :is="feature"></component>
    </div>
</template>

<script>
    import Translate from 'Mixins/translate';
    import InternationalReports from './features/international-reports.vue';
    import MobileApp from './features/mobile-app.vue';
    import AlertsScreening from './features/alerts-screening.vue';
    import Alerts from './features/alerts.vue';
    import AlertsPlus from './features/alerts-plus.vue';
    import Mandates from './features/mandates.vue';
    import SearchOnManager from './features/search-on-manager.vue';
    import SearchOnAddress from './features/search-on-address.vue';
    import LinkedBarometers from './features/linked-barometers.vue';

    export default {
        name: 'feature-explanation',
        mixins: [Translate],
        components: {
            InternationalReports,
            MobileApp,
            AlertsScreening,
            Mandates,
            SearchOnManager,
            SearchOnAddress,
            LinkedBarometers,
            AlertsPlus,
            Alerts
        },
        data() {
            return {

            }
        },
        props: {
            feature: String,
            showSubtitle: Boolean
        }
    }
</script>

<style scoped>
    h3 {
        font-size: 1.3rem !important;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05em;
    }
</style>