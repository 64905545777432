
<div class="pagination-wrapper">
    <div>
        <a class="pagination-link" :disabled="isFirstPage" href="" @click.prevent="setPage(currentPage-1)"><i class="fas fa-chevron-left"></i> </a>
    </div>

    <div class="text-grey">
        Pagina <input class="form-control form-control-sm" min="1" type="number" :value="currentPage" ref="input" @click="selectAll" @input="setPage(+$event.target.value)"> van {{numberOfPages}}
    </div>
    
    <div>
        <a :disabled="isLastPage" class="pagination-link" href="" @click.prevent="setPage(currentPage+1)"><i class="fas fa-chevron-right"></i> </a>
    </div>
</div>
