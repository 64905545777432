import _ from 'lodash';
import Page from '../lib/Page';

export default (function () {
    Page.registerBehavior('.dropdown-toggle', function (element: Element) {
        document.addEventListener('click', (ev: Event) => {
            const allOpenDropdowns = document.querySelectorAll('.dropdown-menu.show') as NodeListOf<HTMLElement>;
            _.forEach(allOpenDropdowns, element => {
                element.classList.remove('show');
            });   
        });

        (<HTMLElement>element).addEventListener('click', (ev: Event) => {
            ev.stopPropagation();
            ev.preventDefault();

            const allOpenDropdowns = document.querySelectorAll('.dropdown-menu.show') as NodeListOf<HTMLElement>;
            _.forEach(allOpenDropdowns, element => {
                element.classList.remove('show');
            });

            const dropdown = element.parentElement?.querySelector('.dropdown-menu')! as HTMLElement;
            
            if(dropdown.classList.contains('show'))
                dropdown.classList.remove('show');
            else
                dropdown.classList.add('show');
        });
    });
}());

