
<transition name="fade">
    <div
        v-if="typeof index === 'number'"
        class="light-gallery"
        @touchend="touchendHandler"
        @touchmove="touchmoveHandler"
        @touchstart="touchstartHandler"
    >
        <div
            :style="`background: ${background}`"
            class="light-gallery__modal"
            v-on:click="close"
        >
            <div
                :class="['light-gallery__spinner', !isImageLoaded || 'hide']"
            >
                <div
                    :style="`border-color: ${interfaceColor}`"
                    class="light-gallery__dot"
                />
                <div
                    :style="`border-color: ${interfaceColor}`"
                    class="light-gallery__dot"
                />
                <div
                    :style="`border-color: ${interfaceColor}`"
                    class="light-gallery__dot"
                />
            </div>
            <div class="light-gallery__container">
                <ul class="light-gallery__content">
                    <li
                        v-for="(image, imageIndex) in formattedImages"
                        :key="imageIndex"
                        :style="`transform: translate3d(${currentIndex * -100}%, 0px, 0px);`"
                        class="light-gallery__image-container"
                    >
                        <div class="light-gallery__image">
                            <div
                                v-show="image.title && isImageLoaded"
                                :style="`background: ${background}; color: ${interfaceColor}`"
                                class="light-gallery__text"
                            >
                                {{ image.title }}
                            </div>
                            <img
                                :ref="`lg-img-${imageIndex}`"
                                :src="shouldPreload(imageIndex) ? image.url : false"
                                @load="imageLoaded($event, imageIndex)"
                            >
                        </div>
                    </li>
                </ul>
            </div>
            <button
                v-if="currentIndex > 0"
                :style="`background: ${background}`"
                class="light-gallery__prev"
                @click.stop="prev()"
            >
                <svg
                    height="40"
                    viewBox="0 0 25 40"
                    width="25"
                >
                    <polyline
                        :stroke="interfaceColor"
                        fill="none"
                        points="19 5 5 20 19 35"
                        stroke-linecap="butt"
                        stroke-linejoin="round"
                        stroke-width="3"
                    />
                </svg>
            </button>
            <button
                v-if="currentIndex + 1 < images.length"
                :style="`background: ${background}`"
                class="light-gallery__next"
                @click.stop="next()"
            >
                <svg
                    height="40"
                    viewBox="0 0 25 40"
                    width="25"
                >
                    <polyline
                        :stroke="interfaceColor"
                        fill="none"
                        points="6 5 20 20 6 35"
                        stroke-linecap="butt"
                        stroke-linejoin="round"
                        stroke-width="3"
                    />
                </svg>
            </button>
            <button
                :style="`background: ${background}`"
                class="light-gallery__close"
                @click="close()"
            >
                <svg
                    height="30"
                    width="30"
                >
                    <g
                        :stroke="interfaceColor"
                        stroke-width="3"
                    >
                        <line
                            x1="5"
                            x2="25"
                            y1="5"
                            y2="25"
                        />
                        <line
                            x1="5"
                            x2="25"
                            y1="25"
                            y2="5"
                        />
                    </g>
                </svg>
            </button>
        </div>
    </div>
</transition>
