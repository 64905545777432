
<tr role="row">
    <td class="p-0 border-0">
        <table class="table table-small" >
            <tr>
                <td colspan="2">
                    <div class="company">
                        <a :href="company.url" class="companyname">
                            <b>{{company.companyName}}</b>
                        </a>
                        <div class="vat text-lightgrey">
                            {{company.registrationNumber}}
                            <a :href="company.url" class="btn btn-transparent btn-sm d-inline-block ml-1" target="_blank">
                                <i class="fas fa-external-link-alt ext-link-icon"></i>
                            </a>
                        </div>
                    </div>
                </td>
            </tr>
            <alert-event v-for="(event, index) in events" :key="company.Vat+'-event-'+index" v-bind="event"></alert-event>
        </table>
    </td>
</tr>
