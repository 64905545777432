
<div class="box my-4">
    <div class="box-title">Download {{index + 1}}</div>

    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label for="nl">Titel NL</label>
                <input id="nl" type="text" v-model="form.nameNl" class="form-control" @change="change"/>
            </div>
            <div class="form-group">
                <label for="fr">Titel FR</label>
                <input id="fr" type="text" v-model="form.nameFr" class="form-control" @change="change"/>
            </div>
            <div class="form-group">
                <label for="en">Titel EN</label>
                <input id="en" type="text" v-model="form.nameEn" class="form-control" @change="change"/>
            </div>

            <div class="mt-2">
                <a href="" class="text-danger" @click.prevent="tryDelete()"><i class="fas fa-times mr-2"></i> download verwijderen</a>
            </div>
        </div>

        <div class="col-6">
            <div class="form-group">
                 <file-input 
                        small
                        v-on:input="onManualNlUpload" 
                        :multiple="false" 
                        :required="false" 
                        :name="'pdf_nl_' + index"
                        placeholder=".pdf" 
                        title="Upload NL pdf"
                        accept=".pdf"></file-input>

                        <a v-if="manualNl.path !== ''" :href="manualNl.path" download class="text-blue mt-2 d-inline-block">
                            <i class="fas fa-download mr-2"></i> download PDF
                        </a>
            </div>
             <div class="form-group">
                 <file-input 
                        small
                        v-on:input="onManualFrUpload" 
                        :multiple="false" 
                        :required="false" 
                        :name="'pdf_fr_' + index"
                        placeholder=".pdf" 
                        title="Upload FR pdf"
                        accept=".pdf"></file-input>

                    <a v-if="manualFr.path !== ''" :href="manualFr.path" download class="text-blue mt-2 d-inline-block">
                        <i class="fas fa-download mr-2"></i> download PDF
                    </a>
            </div>
             <div class="form-group mb-0">
                 <file-input 
                        small
                        v-on:input="onManualEnUpload" 
                        :multiple="false" 
                        :required="false" 
                        :name="'pdf_en_' + index"
                        placeholder=".pdf" 
                        title="Upload EN pdf"
                        accept=".pdf"></file-input>

                    <a v-if="manualEn.path !== ''" :href="manualEn.path" download class="text-blue mt-2 d-inline-block">
                        <i class="fas fa-download mr-2"></i> download PDF
                    </a>
            </div>
        </div>
    </div>
</div>
