
<form method="get"
      action="/search/persons"
      v-on:submit.prevent="validateSubmit"
      v-cloak
      :data-gtm-cat="gtmCategory"
      :data-gtm-action="gtmAction">
    <div class="search-input-wrapper row" v-if="canSearchOnManager">
        <div class="input-wrapper" :class="{'col-md-12':showAnnouncement, 'col-md-6': !showAnnouncement}">
            <input name="LastName"
                   id="lastName"
                   class="form-control mr-1 search-input"
                   type="text"
                   :placeholder="translate('placeholder_familienaam')"
                   v-model="lastName"
                   v-bind:class="{error: errorField == 'lastName'}" />
        </div>
        <div class="input-wrapper" :class="{'col-md-12':showAnnouncement, 'col-md-5': !showAnnouncement}">
            <input name="FirstName"
                   id="firstName"
                   class="form-control mr-1 search-input"
                   type="text"
                   :placeholder="translate('placeholder_voornaam')"
                   v-model="firstName"
                   v-bind:class="{error: errorField == 'firstName'}" />
        </div>
        <div :class="{'col-md-12':showAnnouncement, 'col-md-1': !showAnnouncement}">
            <button type="submit" class="btn btn-info w-100 h-100">
                <template v-if="showAnnouncement">
                    {{translate('zoeken')}}
                </template>
                <template v-else>
                    <i class="fas fa-search"></i>
                </template>
            </button>
        </div>

        <div class="col-md-12">
            <div class="card-box warning small m-t-10" v-if="errorMessage !== ''" v-cloak>
                {{errorMessage}}
            </div>
        </div>
    </div>
    <div v-else>
        {{translate('searchbox_upgrade_text')}}
        <div class="mt-2">
            <a :href="$root.upgradeSearchOnManagerLink" class="btn btn-info">
                <template v-if="isInstituutUser">
                    {{translate('upgrade_standard_instituut')}}
                </template>
                <template v-else>
                    {{translate('upgrade_standard')}}
                </template>
            </a>
        </div>
    </div>
    <input type="hidden" name="personQuery" :value="firstName+';'+lastName" />
</form>
