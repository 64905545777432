﻿<template>
  <th class="vertical narrow" :class="{'open': isFilterBoxShown, 'filtered': hasFiltered}" @click.stop="toggle"
      :style="'min-width:' + (field.minWidth < 50 ? 50 : field.minWidth)  + 'px' ">
    <div class="filter-title-row">
        <div class="d-flex align-items-center" style="gap:8px">
        <div class="text">
          {{ field.name }}
        </div>
          <div class="d-flex flex-column sort-wrapper" >
              <a class="d-inline-block sort-btn" :class="{'active': isSorted && !isDescending}" href="#" @click.prevent.stop="setSort(false)" :title="translate('sort_asc')">
                  <i class="fa-caret-up" :class="{'fas': isSorted && !isDescending, 'far': !isSorted || isDescending}"></i>
              </a>
              <a class="d-inline-block  sort-btn" :class="{'active': isSorted && isDescending}"  href="#" @click.prevent.stop="setSort(true)" :title="translate('sort_desc')">
                  <i class="fa-caret-down" :class="{'fas': isSorted && isDescending, 'far': !isSorted || !isDescending}"></i>
              </a>
          </div>
      </div>
    </div>
    <transition name="dropdown">
      <div v-if="isFilterBoxShown" v-click-outside="close">
        <div></div>
        <div id="filter-box" class="filter-box" @click.stop>
          <div class="row mb-3">
            <div class="col-md-10">
              <em>{{ field.description }}</em>
            </div>
            <div class="col-md-2 text-right">
              <a class="btn btn-default btn-sm" href="" @click.stop.prevent="openEditColumnModal"><i
                  class="fas fa-cog"></i> </a>
            </div>
          </div>

            <div class="row mb-2">
                <h3 class="col-md-6">
                    {{translate('filteren')}}
                </h3>
                <div class="col-md-6 text-right" v-if="hasFiltered">
                    <a href="" class="text-grey link" @click.prevent="removeCustomFilter(field)"><i class="fas fa-times"></i> {{translate('delete_filter')}}</a>
                </div>
            </div>
          <div>
            <div v-if="field.type === 'boolean'">
              <select v-model="store.customFilters[field.id]" class="form-control">
                <option :value="null">{{translate('checkbox_all')}}</option>
                <option :value="true">{{translate('checkbox_checked')}}</option>
                <option :value="false">{{translate('checkbox_unchecked')}}</option>
              </select>
            </div>
            <div v-else>
              <div class="d-flex align-items-center">
                <input v-model="store.customFilters[field.id]"
                       :placeholder="translate('filter_on') + ' ' + field.name" class="form-control" type="search">
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </th>
</template>

<script lang="ts">
import {CustomField} from '../../types';
import {useStore} from '../../store';
import Translate from '../../../mixins/translate';
import {storeToRefs} from 'pinia';
//@ts-ignore
import ClickOutside from "vue-click-outside";

export default Translate.extend({
  name: 'custom-filter-th',
  directives: {ClickOutside},
  props: {
    field: {
      required: true,
      type: Object as () => CustomField
    }
  },
  data() {
    return {
      filterTerm: '',
      store: useStore(),
    };
  },
  watch: {
    isFilterBoxShown() {
      this.filterTerm = '';
    },
  },
  methods: {
    removeCustomFilter(field: CustomField) {
      this.store.removeCustomFilter(field);
    },
    toggle(): void {
      this.store.toggleFilterBox(this.field);
    },
    close(): void {
      this.store.toggleFilterBox(null);
    },
    setSort(desc: boolean): void {
      this.store.setSorting(this.field.name, desc);
      this.close();
    },
    openEditColumnModal(): void {
      this.$emit('editCustomColumn', this.field);
    }
  },
  computed: {
    isFilterBoxShown(): boolean {
      return this.store.openedFilterBox === this.field;
    },
    isSorted(): boolean {
      return this.store.sorting.property === this.field.name;
    },
    isDescending(): boolean {
      return this.store.sorting.descending;
    },
    hasFiltered(): boolean {
      const store = useStore();
      const {customFilters} = storeToRefs(store); //doesn't seem to be reactive if used with this.store.
      const thisFilter = customFilters.value[this.field.id];

      if (thisFilter === undefined)
        return false;

      return (this.field.type === "boolean" && thisFilter != null) || (this.field.type === "string" && (thisFilter !== ""))
    }
  }
});
</script>

<style lang="scss" scoped>
$primColor: #0368FF;
h3 {
  text-transform: none;
}

.link {
  text-transform: none;
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
  }
}

th {
  position: relative;
  cursor: pointer;
  user-select: none;
    color: #595959;
    font-weight: 500;
    &.filtered {
        color: black;
        font-weight: 600;
    }

  em {
    font-weight: normal;
    white-space: normal;
    word-break: normal;
  }

  &:hover, &.open {
    background-color: white;
  }
  
  &.vertical {
    transform-origin: bottom;
    transform: skewX(-10deg);

    .filter-box {
      transform: skewX(10deg);
    }
      .sort-wrapper {
          transform: skewX(10deg);
      }

    .filter-title-row {
      writing-mode: vertical-rl;
      vertical-align: bottom;

      .text {
        transform: rotateZ(-170deg) skewX(10deg);
      }

      .has-filtered {
        transform: skewX(10deg);
        margin-top: 10px;
      }
    }
  }

  .filter-box {
    position: absolute;
    background: white;
    padding: 25px;
    min-width: 420px;
    width: 100%;
    right: 0;
    top: 100%;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    border-radius: 0 0 4px 4px;
    z-index: 20;
    border-top: 0;
    cursor: auto;
    white-space: break-spaces;
  }
}

.sort-wrapper {
    writing-mode: initial;
    .sort-btn {
        line-height: 4px;
        padding: 2px;
        color: #c0c0c0;
        :hover{
            color: grey;
        }
        &.active {
            color: $primColor;
        }
        i {
            line-height: 0.4;
        }
    }
}

.filter-title-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 1;
    position: relative;

  .has-filtered {
      padding: 5px 9px;
      border-radius: 3px;
      display: inline-block;
      background-color: #d7d7d7;
      writing-mode: lr;
      margin-top: 10px;
    &:hover {
      background-color: #c4c4c4;
      color: black;
    }
  }
}
</style>