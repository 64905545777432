﻿<template>
    <form method="get"
          action="/search/persons"
          v-on:submit.prevent="validateSubmit"
          v-cloak
          :data-gtm-cat="gtmCategory"
          :data-gtm-action="gtmAction">
        <div class="search-input-wrapper row" v-if="canSearchOnManager">
            <div class="input-wrapper" :class="{'col-md-12':showAnnouncement, 'col-md-6': !showAnnouncement}">
                <input name="LastName"
                       id="lastName"
                       class="form-control mr-1 search-input"
                       type="text"
                       :placeholder="translate('placeholder_familienaam')"
                       v-model="lastName"
                       v-bind:class="{error: errorField == 'lastName'}" />
            </div>
            <div class="input-wrapper" :class="{'col-md-12':showAnnouncement, 'col-md-5': !showAnnouncement}">
                <input name="FirstName"
                       id="firstName"
                       class="form-control mr-1 search-input"
                       type="text"
                       :placeholder="translate('placeholder_voornaam')"
                       v-model="firstName"
                       v-bind:class="{error: errorField == 'firstName'}" />
            </div>
            <div :class="{'col-md-12':showAnnouncement, 'col-md-1': !showAnnouncement}">
                <button type="submit" class="btn btn-info w-100 h-100">
                    <template v-if="showAnnouncement">
                        {{translate('zoeken')}}
                    </template>
                    <template v-else>
                        <i class="fas fa-search"></i>
                    </template>
                </button>
            </div>

            <div class="col-md-12">
                <div class="card-box warning small m-t-10" v-if="errorMessage !== ''" v-cloak>
                    {{errorMessage}}
                </div>
            </div>
        </div>
        <div v-else>
            {{translate('searchbox_upgrade_text')}}
            <div class="mt-2">
                <a :href="$root.upgradeSearchOnManagerLink" class="btn btn-info">
                    <template v-if="isInstituutUser">
                        {{translate('upgrade_standard_instituut')}}
                    </template>
                    <template v-else>
                        {{translate('upgrade_standard')}}
                    </template>
                </a>
            </div>
        </div>
        <input type="hidden" name="personQuery" :value="firstName+';'+lastName" />
    </form>
</template>
<script lang="ts">
    import Translate from '../../mixins/translate';
    import QueryString from "../../../lib/QueryString";

    export default Translate.extend({
        name: 'manager',
        inject: ['showAnnouncement', 'isInstituutUser', 'canSearchOnManager'],
        mixins: [Translate],
        props: {
            gtmCategory: String,
            gtmAction: String
        },
        data() {
            return {
                firstName: '' as string,
                lastName: '' as string,
                minimumSearchLength: 2 as number,
                errorMessage: '' as string,
                errorField: '' as string
            }
        },
        methods: {
            showError(field: string, translationKey: string): void {
                this.errorMessage = translationKey && this.translate(translationKey);
                this.errorField = field;
                document.getElementById(this.errorField)!.focus();
            },
            hideError(): void {
                this.errorField = '';
                this.errorMessage = '';
            },
            validateSubmit(event: Event): void {
                if (this.lastName === '') {
                    return this.showError('lastName', 'no_search_result_reason_last_name_missing');
                } else if (this.lastName.length < this.minimumSearchLength) {
                    return this.showError('lastName', 'no_search_result_reason_last_name_too_short');
                } else if (this.firstName === '') {
                    return this.showError('firstName', 'no_search_result_reason_first_name_missing');
                } else if (this.firstName.length < this.minimumSearchLength) {
                    return this.showError('firstName', 'no_search_result_reason_first_name_too_short');
                }
                
                (event.target as HTMLFormElement).submit();
            }
        },
        mounted() {
            const uri = window.location.href;
            this.firstName = QueryString.getParameter(uri, "FirstName") ?? "";
            this.lastName = QueryString.getParameter(uri, "LastName") ?? "";
        }
    });
</script>