<template>
    <div>
       <h2>{{translate('integrate_title')}}</h2>
       <p>
           {{translate('not_activatable')}}
       </p>
        <a :href="'/integrations/contact/' + integration.id" class="btn btn-200 btn-info">{{translate('btn_contact')}}</a>
    </div>
</template>

<script lang="ts">
    import mixins from 'vue-typed-mixins';
    import { SoftwarePartner } from '../../../types/dto/SoftwarePartner';
    import Translate from '../../mixins/translate';

    export default mixins(Translate).extend({
        name: 'integration_not_activatable',
        props: {
            integration: Object as () => SoftwarePartner
        }
    })
</script>

<style scoped>

</style>