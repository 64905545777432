
<div class="px-5 my-5">
    <loading-radar v-if="isLoading"></loading-radar>
    <template v-else>
        <edit-custom-column-modal :field="editField"></edit-custom-column-modal>
        <drawer></drawer>
        <add-to-alerts></add-to-alerts>

        <div class="row mb-2">
            <div class="col-md-6 d-flex">
                <h1 class="font-weight-bold mr-5 mb-0">Radar</h1>
            </div>
            <div class="col-md-6 text-right">
                <form method="POST" enctype="application/x-www-form-urlencoded"
                      id="exportForm" ref="exportForm" class="d-inline-flex align-items-center"
                        @submit.prevent="exportData">
                    <span v-if="!canExport" class="text-grey font-small">({{translate('max_export_companies', exportCompanyLimit.toString())}})</span>
                    <button type="submit" class="btn btn-default btn-sm ml-3" :disabled="!canExport || isExporting">
                        <i v-if="!isExporting" class="fas fa-cloud-download mx-1 py-1"></i>
                        <i v-else class="fas fa-spinner spinning mx-1 py-1"></i>
                        export
                    </button>
                </form>

                <div class="font-italic mt-1">
                    {{ filteredCompanies.length.formatThousandSeparator() }} {{ translate('x_selected_companies') }}
                </div>
            </div>
        </div>

        <div class="row my-4">
            <div class="col-lg-8 col-xl-9 d-flex flex-wrap align-items-center" style="gap: 10px;">
                <select-menu link-to-url label="Dataset" v-model="store.currentDataset" :options="datasetOptions"></select-menu>
                <i class="far fa-angle-double-right text-grey"></i>
                <select-menu label="Subset" v-model="store.subsetSelectMenuItem" :options="subsetOptions"></select-menu>
                <i class="far fa-angle-double-right text-grey"></i>
                <button type="button" class="btn btn-primary" @click.prevent="store.openFilterListDrawer"><i class="far fa-filter mr-2"></i> {{translate('filters')}}</button>
            </div>
            <div class="col-lg-4 col-xl-3 text-right my-auto">
                <div class="view-layout">
                    <a href="" :class="{'active': currentView === View.TABLE}"
                       @click.prevent="currentView = View.TABLE">
                        <i class="fas fa-table mr-2"></i>{{ translate('tab_table') }}
                    </a>
                    <a href="" :class="{'active': currentView === View.MAP}" @click.prevent="changeToMapView">
                        <i class="fas fa-map mr-2"></i>{{ translate('tab_map') }}
                    </a>
                </div>
            </div>
        </div>
        
        <div class="d-flex my-4" style="gap:10px;"  v-if="store.hasAnyFilter">
            <a href="" @click.prevent="store.openFilterDetailDrawer('companyname')" class="applied-filter"
               v-if="store.companyNameFilter !== ''">
                {{ translate('companyname') }}: <b class="text-dark">{{ store.companyNameFilter }}</b>
                <span class="py-1 px-2 " @click.prevent.stop="store.removeCompanyNameFilters()">
                        <i class="far fa-times" :title="translate('clear_filter')"></i>
                    </span>
            </a>

            <a href="" @click.prevent="store.openFilterDetailDrawer('street')" class="applied-filter"
               v-if="store.streetFilters.length > 0">
                {{ translate('street') }}: <span
                class="badge badge-pill ml-1">{{ store.streetFilters.length > 1 ? store.streetFilters.length : store.streetFilters[0] }}</span>
                <span class="py-1 px-2 " @click.prevent.stop="store.removeStreetFilters()">
                        <i class="far fa-times" :title="translate('clear_filter')"></i>
                    </span>
            </a>

            <a href="" @click.prevent="store.openFilterDetailDrawer('form')" class="applied-filter"
               v-if="store.formFilters.length > 0">
                {{ translate('form') }}: <span
                class="badge badge-pill ml-1">{{ store.formFilters.length > 1 ? store.formFilters.length : store.formFilters[0] }}</span>
                <span class="py-1 px-2 " @click.prevent.stop="store.removeFormFilters()">
                        <i class="far fa-times" :title="translate('clear_filter')"></i>
                    </span>
            </a>

            <a href="" @click.prevent="store.openFilterDetailDrawer(filter.title)" class="applied-filter"
               v-for="filter in store.genericFilters.filter(fil => fil.filterCriteria.hasValue)">
                {{ translate(filter.title) }}: <span class="badge badge-pill ml-1"><span
                v-html="filter.filterCriteria.toHtml()"></span></span>
                <span class="py-1 px-2 hover:bg-grey-500 inline-block"
                      @click.prevent.stop="() => {filter.filterCriteria.reset(); store.saveConfig();}">
                        <i class="far fa-times" :title="translate('clear_filter')"></i>
                    </span>
            </a>

            <a href="" 
               @click.prevent="store.openFilterDetailDrawer(store.customFields.find(f => f.id === +label))"
               class="applied-filter" v-for="(filter, label) in store.customFilters"
               v-if="(store.customFields.find(f => f.id === +label).type === 'boolean' && filter != null) || (store.customFields.find(f => f.id === +label).type === 'string' && (filter !== ''))">
                {{ store.customFields.find(f => f.id === +label).name }}: <span class="badge badge-pill ml-1">
                        <span v-if="typeof filter === 'boolean'">
                            <template v-if="filter">{{ translate('checkbox_checked') }}</template>
                            <template v-else>{{ translate('checkbox_unchecked') }}</template>
                        </span>
                    <span v-else>{{ filter }}</span>
                    </span>
                <span class="py-1 px-2"
                      @click.prevent="store.removeCustomFilter(store.customFields.find(f => f.id === +label))">
                        <i class="far fa-times" title="remove filter?"></i>
                    </span>
            </a>

            <a href="" @click.prevent="store.removeAllFilters" v-if="store.hasAnyFilter"
               class="text-dark d-flex align-items-center">
                <i class="far fa-times pr-2"></i> {{ translate('delete_all_filters') }}
            </a>
        </div>

        <div v-show="currentView === View.TABLE">
            <div
                v-if="filteredCompanies.length === 0 && store.startedThisMonth && (store.subsetSelectMenuItem?.id !== Subset.ALL && store.subsetSelectMenuItem?.id !== Subset.ALL_ACTIVE)"
                class="no-data-placeholder">
                {{ translate('no_companies_first_month') }}
            </div>
            <div v-else class="radar-table-wrapper">
                <div class="p-3 d-flex justify-content-between">
                    <pagination v-model="currentPage" :amount-of-items="filteredCompanies.length"
                                :items-per-page="itemsPerPage"></pagination>
                    <button class="btn btn-ghost" @click.prevent="store.openColumnEditDrawer"><i
                        class="fas fa-columns mr-2"></i>{{translate('columns')}} 
                        <span v-if="store.hiddenSelectableColumns.length > 0" class="text-small font-weight-normal text-grey text-lowercase">({{store.hiddenSelectableColumns.length}} verborgen )</span>
                    </button>
                </div>
                <div class="radar-container" :class="{'scrolled': isScrolled}" ref="slider">
                    <table class="table mb-0 w-auto radar-slider" id="radar-slider">
                        <thead>
                        <tr class="gray position-relative">
                            <th is="company-filter-th" style="min-width: 360px" class="sticky"></th>
                            <th is="filter-th" v-for="column in store.chosenColumns" :key="'col_' + column.title"
                                :column="column"></th>
                            <th is="custom-filter-th" v-for="customField in store.customFields"
                                :key="'custom_' + customField.id" :field="customField"
                                v-on:editCustomColumn="showEditCustomColumn"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-if="filteredCompanies.length > 0">
                            <tr is="company-row" v-for="(company,idx) in shownCompanies" :key="idx + company.vat"
                                :company="company"></tr>
                        </template>
                        <tr v-else>
                            <td colspan="100%" class="position-fixed">
                                <p>
                                    {{ translate('no_companies') }}
                                </p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="p-3 d-flex justify-content-between">
                    <pagination v-model="currentPage" :amount-of-items="filteredCompanies.length"
                                :items-per-page="itemsPerPage"></pagination>
                    <div class="d-flex items-center" style="gap:10px">
                        <label for="" class="inline-label">{{translate('rows_per_page')}}</label>
                        <select class="form-control form-control-sm" v-model="itemsPerPage">
                            <option :value="10">10</option>
                            <option :value="25">25</option>
                            <option :value="50">50</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="currentView === View.MAP">
            <cw-map></cw-map>
        </div>
    </template>
</div>
