
<transition name="slide-fade">
    <tr role="row" @click.stop="editUser" class="pointer">
        <td :data-header="translate('beheerder')">
            <i v-if="user.isAdmin" class="fas fa-check text-lightblue"></i>
            <i v-else class="fas fa-times text-lightgrey"></i>
        </td>
        <td :data-header="translate('naam')">
            <b>{{user.name}}</b>
        </td>
        <td :data-header="translate('status')">
            <span class="badge" :class="badgeClass">{{translate('tab_label_'+user.type)}}</span>
        </td>
        <td :data-header="translate('email')">
            {{user.email}}
        </td>
        <td :data-header="translate('laatst_actief')">
            <template v-if="user.lastActive !== 0">
                {{user.lastActive|toTimeAgo}}
            </template>
            <template v-else>-</template>
        </td>
        <td :title="translate('aantal_mobile')" v-if="isLoggedInAsAdmin" class="mobile-hide">
            {{user.mobileInstalls.length}}
        </td>
        <td :title="translate('aantal_persoonlijke_alerts_gebruikt')" class="mobile-hide">
            {{user.alertsUsage}}
        </td>
        
        <td class="mobile-hide">
            <template v-if="!isMailSent">
                <button class="btn btn-secondary btn-sm show-on-hover"
                        v-on:click.stop="sendInviteAgain"
                        v-if="isLoggedInAsAdmin && user.type === 'pending'" :title="translate('send_invite_again')">
                    <i class="fas fa-envelope mr-2"></i>Mail
                </button>
            </template>
        </td>
        <td  class="mobile-hide">
            <button class="btn btn-sm btn-default show-on-hover" v-if="isLoggedInAsAdmin">{{translate('btn_edit')}}</button>
        </td>
        <td class="mobile-hide">
            <template v-if="isLoggedInAsAdmin">
                <button class="btn btn-sm btn-danger remove show-on-hover"
                        v-if="user.type === 'pending'"
                        v-on:click.prevent.stop="removePendingUser"
                        :title="translate('remove_user')">
                    <i class="fas fa-trash-alt"></i>
                </button>

                <button class="btn btn-sm btn-danger remove show-on-hover"
                        v-if="user.type === 'active'"
                        v-on:click.prevent.stop="deactivateUser"
                        :title="translate('deactivate_user')">
                    <i class="fas fa-user-lock"></i>
                </button>

                <button class="btn btn-sm btn-success show-on-hover"
                        v-if="user.type === 'deactivated'"
                        v-on:click.prevent.stop="reactivateUser"
                        :title="translate('reactivate_user')">
                    <i class="fas fa-user-check"></i>
                </button>
            </template>
        </td>
    </tr>
</transition>
