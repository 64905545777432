import { ProspectieDataRangeOperator } from '../../types/dto/ProspectieDataRange';
import { ProspectieDataSelectionCriterium } from '../../types/dto/ProspectieDataSelectionCriterium';
import { ProspectieDataSelectionCriteriumType } from '../../types/dto/ProspectieDataSelectionCriteriumType';
import Enumeration from '../../types/global/enumeration';
import CriteriumRange from './criteriumRange';

export default class SelectionPreset extends Enumeration<SelectionPreset>() {
    public name: string;
    public criteria: ProspectieDataSelectionCriterium[];
    public icon: string;

    public constructor(name: string, criteria: ProspectieDataSelectionCriterium[], icon: string) {
        super(name);
        this.name = name;
        this.criteria = criteria;
        this.icon = icon;
    }

    public get translationKey(): string {
        return 'preset_' + this.name
    }

    public static readonly Starters: SelectionPreset = new SelectionPreset(
        "recently_established",
        [
            {
                type: ProspectieDataSelectionCriteriumType.OPRICHTINGSDATUM,
                id: null,
                range: new CriteriumRange(
                    ProspectieDataRangeOperator.BETWEEN,
                    ProspectieDataSelectionCriteriumType.OPRICHTINGSDATUM,
                    new Date().addMonths(-3).toHansDate(),
                    new Date().toHansDate())
            }
        ],
        "fa-seedling"
    );

    public static readonly Healthy: SelectionPreset = new SelectionPreset(
        "healthy",
        [
            {
                type: ProspectieDataSelectionCriteriumType.RISICOPROFIEL,
                id: null,
                range: new CriteriumRange(
                    ProspectieDataRangeOperator.BETWEEN,
                    ProspectieDataSelectionCriteriumType.RISICOPROFIEL,
                    10,
                    50)
            }
        ],
        "fa-tachometer"
    );
}