﻿import Vue from 'vue';
import Ajax from '../lib/Ajax';
import Toast from '../lib/Toast';
import Translate from '../lib/Translate';
import Page from '../lib/Page';

export default (function () {
    Page.registerVue('#favourites', (rootSelector) => {
        new Vue({
            el: rootSelector,
            data: {
                isFavourite: window.cw.isFavourite,
                vatOrPersonId: window.cw.vatOrPersonId,
                type: window.cw.type
            },
            methods: {
                addToOrRemoveFromFavourites(): void {
                    if (this.isFavourite)
                        this.removeFromFavourites();
                    else
                        this.addToFavourites();
                },
                addToFavourites(): void {
                    Ajax.post(
                        '/ajax/' + this.type + '/add-to-favourites',
                        this.type === 'company'
                            ? { vat: this.vatOrPersonId }
                            : { personId: this.vatOrPersonId }
                        ,
                        () => {
                            this.isFavourite = true;
                            Toast.success(Translate('added_to_favourites'));
                        },
                        (error) => {
                            console.error(error);
                        }
                    );
                },
                removeFromFavourites(): void {
                    Ajax.post(
                        '/ajax/' + this.type + '/remove-from-favourites',
                        this.type === 'company'
                            ? { vat: this.vatOrPersonId }
                            : { personId: this.vatOrPersonId }
                        ,
                        () => {
                            this.isFavourite = false;
                            Toast.success(Translate('removed_from_favourites'));
                        },
                        (error) => {
                            console.error(error);
                        }
                    );
                }
            }
        });
    });
}());
