﻿import T from '../../lib/Translate';
import Vue from 'vue';

export default Vue.extend({
    methods: {
        translate: function (translateKey: string, ...args: any[]): string {
            return T(translateKey, ...args);
        }
    }
});
