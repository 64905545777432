<template>
    <div class="row">
        <div class="col-md-12">
            <input type="hidden" name="Package" :value="selectedPackage.name.toKebabCase()" />

            <select class="mobile-show form-control" v-model="selectedPackage">
                <option v-for="pack in packages" :key="pack.name" :value="pack">{{pack.name}}</option>
            </select>

            <div class="mobile-show text-center my-3 large">
                <strong>
                    € {{mobilePrice}}<small>&nbsp; / {{translate("jaar").toLowerCase()}}</small>
                </strong>
            </div>

            <div class="pricing-table animated fadeIn" v-bind:class="selectedPackage">
                <div class="pricing-table-row header-row">
                    <div class="pricing-cell"></div>

                    <div v-for="column in columns" :data-text="translate('most_chosen')" :key="column.name" class="pricing-cell" :class="{'d-none d-sm-inline-flex': selectedPackage !== column.selectedPackage, 'selected': selectedPackage === column.selectedPackage}">
                        <div class="full-width text-center">
                            <img :src="'/images/illustrations/'+column.name+'.svg'" :alt="column.name" />

                            <div v-if="column !== PricingColumn.One" class="full-width text-center">{{column.name}}</div>
                            <select v-else v-model="selectedThematicPackage" class="form-control">
                                <option v-for="pack in column.selectablePackages" :key="pack.name" :value="pack">{{pack.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <pricing-row v-for="(row, index) in filteredRows" :selectedPackage="selectedPackage" :rowData="row" :key="index"></pricing-row>

                <div class="pricing-table-row footer-row">
                    <div class="pricing-cell"></div>
                    <div v-for="column in columns" :key="'column_' + column.name" class="pricing-cell" :class="{'d-none d-sm-inline-flex': selectedPackage !== column.selectedPackage, 'selected': selectedPackage === column.selectedPackage}">
                        <small class="mr-2"> {{translate('vanaf')}}</small>
                        € {{column.selectedPackage.totalPrice}} <small>&nbsp; / {{translate("jaar").toLowerCase()}}</small>
                        <div class="full-width text-center m-t-10">
                            <button class="btn btn-block btn-info" v-on:click.prevent="choose(column.selectedPackage)" v-if="selectedPackage !== column.selectedPackage">
                                {{translate('choose_pack')}}
                            </button>
                            <div class="small" v-else>
                                <i class="fas fa-check-circle"></i> {{translate('selected')}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Translate from "../../mixins/translate";
    import mixins from "vue-typed-mixins";
    import Page from '../../../lib/Page';
    import PricingRowComponent from './pricing-row.vue';
    import {Package, PricingRow, PricingColumn, PricingRowType, ValueType} from '../types';

    export default mixins(Translate).extend({
        components: {
            'pricing-row': PricingRowComponent
        },
        name: 'pricing-table',
        props: {
            selectedPackType: Object as () => Package
        },
        data() {
            return {
                selectedPackage: Package.Premium,
                packages: Package.enumerators,
                rows: PricingRow.enumerators,
                Package,
                PricingColumn,
                isMultiUser: window.cw.isMultiUser,
                betaalmethodes: window.cw.betaalmethodes,
                selectedThematicPackage: Package.PremiumInternational,
                selectedPremiumPackage: Package.Premium,
                isSales: window.cw.isSales
            };
        },
        watch: {
            selectedPackage(v: Package) {
                this.$emit('package', v);
            },
            selectedPackType(v: Package) {
                this.choose(v);
            },
            selectedThematicPackage(v: Package) {
                this.choose(v);
            },
            selectedPremiumPackage(v: Package) {
                this.choose(v);
            }
        },
        computed: {
            mobilePrice(): number {
                return this.selectedPackage.price;
            },
            columns(): PricingColumn[] {
                return PricingColumn.enumerators;
            },
            filteredRows(): PricingRow<ValueType>[] {
                return this.rows.filter(row => row.isVisible(this.isSales))
            }
        },
        methods: {
            choose(pack: Package): void {
                const queryString = window.location.search;
                const matches = document.location.pathname.match(/\/([\w]{2})\/order/);
                const language = matches ? matches[1] : "nl";

                if(this.selectedPackage === pack)
                    return;
                
                this.selectedPackage = pack;

                if (pack === Package.PremiumCompliance || pack === Package.PremiumInternational || pack === Package.PremiumData) {
                    PricingColumn.One.selectedPackage = pack;
                    this.selectedThematicPackage = pack;
                }
                if (pack === Package.Premium || pack === Package.Premium500) {
                    PricingColumn.Two.selectedPackage = pack;
                    this.selectedPremiumPackage = pack;
                }

                window.history.replaceState(
                    "Historyobject",
                    "Order " + pack.name,
                    "/" + language + "/order/" + pack.urlName + queryString
                );
            }
        },
        mounted() {
            Page.applyBehaviors('#pricing-table');

            const urlSegements = window.location.pathname.split("/");
            const lastSegment = urlSegements[urlSegements.length - 1];

            this.selectedPackage = this.packages.find(pack => pack.urlName === lastSegment) ?? Package.Premium;

            if (this.selectedPackage === Package.PremiumCompliance || this.selectedPackage === Package.PremiumInternational || this.selectedPackage === Package.PremiumData)
                this.selectedThematicPackage = this.selectedPackage;

            if (this.selectedPackage === Package.Premium || this.selectedPackage === Package.Premium500)
                this.selectedPremiumPackage = this.selectedPackage;

            this.$root.$on('choose-package', (pack: Package) => {
                this.choose(pack);
            });
        }
    });
</script>

<style scoped>
</style>