﻿import _ from 'lodash';
import Vue from 'vue';
import Page from 'Lib/Page';
import CookieConsentModal from './cookie-consent-modal.vue';

export default (function () {
    Page.registerVue('#cookieConsent', function (rootSelector) {
        new Vue({
            el: rootSelector,
            components: {
                'cookie-consent-modal': CookieConsentModal,
            },
            data: {
                hasAcceptedFunctionalCookies: window.cw.hasAcceptedFunctionalCookies,
                isOnCookiePage: window.cw.isOnCookiePage
            },
            computed: {
                show() {
                    return !this.hasAcceptedFunctionalCookies && !this.isOnCookiePage;
                }
            }
        });
    });

    Page.registerVue('#cookieConsentOnCookiePage', function (rootSelector) {
        new Vue({
            el: rootSelector,
            components: {
                'cookie-consent-modal': CookieConsentModal,
            },
            data: {
                hasAcceptedFunctionalCookies: window.cw.hasAcceptedFunctionalCookies,
                triggerModalAgain: false
            },
            computed: {
                show() {
                    return this.triggerModalAgain;
                }
            },
            methods: {
                showModal() {
                    this.triggerModalAgain = true;
                },
                hideModal() {
                    this.triggerModalAgain = false;
                }
            },
            mounted() {
                this.$root.$on('submitted-form', () => {
                    this.hideModal();
                });
            }
        });
    });
}());
