﻿<template>
    <div class="wrapper">
        <div class="top-view">
            <div class="row title-row">
                <div class="col-lg-4"><h2 class="font-weight-bold mb-0">{{translate('filters')}}</h2></div>
                <div class="col-lg-8 my-auto">
                    <div class="inline-form">
                        <dropdown>
                            <template v-slot:toggler>{{store.savedFilters.length}} {{translate('saved_filter_s')}}</template>
                            <template v-slot:content>
                                <li @click="applyFilter(filter.criteria)" v-for="filter in store.savedFilters" class="d-flex justify-content-between">
                                    <span>{{filter.title}}</span>
                                    <a class="btn btn-sm btn-default ml-2" href="" @click.prevent.stop="deleteFilter(filter)" :title="translate('delete_filter')">
                                        <i class="fas fa-trash-alt"></i>
                                    </a>
                                </li>
                                <div v-if="store.savedFilters.length === 0"></div>
                            </template>
                        </dropdown>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-view">
            <div class="filter-list">
                <h3 style="margin-left: 30px;">{{translate('company_information')}}</h3>

                <a href="" @click.prevent="openFilterDetail('companyname')"  class="filter-list-item">
                    <div>
                        <h3 class="font-weight-normal mb-0">{{ translate('companyname') }}</h3>
                    </div>
                    <div class="d-flex align-items-center" style="gap: 20px">
                        <span v-if="store.companyNameFilter !== ''" class="badge badge-pill ml-1">{{store.companyNameFilter}}</span>
                        <i class="fas fa-chevron-right text-grey"></i>
                    </div>
                </a>

                <a href="" @click.prevent="openFilterDetail('street')"  class="filter-list-item">
                    <div>
                        <h3 class="font-weight-normal mb-0">{{ translate('street') }}</h3>
                    </div>
                    <div class="d-flex align-items-center" style="gap: 20px">
                        <span v-if="store.streetFilters.length > 0" class="badge badge-pill ml-1">{{store.streetFilters.length}}</span>
                        <i class="fas fa-chevron-right text-grey"></i>
                    </div>
                </a>

                <a href="" @click.prevent="openFilterDetail('form')"  class="filter-list-item">
                    <div>
                        <h3 class="font-weight-normal mb-0">{{ translate('form') }}</h3>
                    </div>
                    <div class="d-flex align-items-center" style="gap: 20px">
                        <span v-if="store.formFilters.length > 0" class="badge badge-pill ml-1">{{store.formFilters.length}}</span>
                        <i class="fas fa-chevron-right text-grey"></i>
                    </div>
                </a>
                
                <a href="" v-for="filter in companyFilters" class="filter-list-item" @click.prevent="openFilterDetail(filter.title)">
                    <div>
                        <h3 class="font-weight-normal mb-0">{{ translate(filter.title) }}</h3>
                    </div>
                    <div class="d-flex align-items-center" style="gap: 20px">
                        <span v-if="filter.filterCriteria.hasValue" class="badge badge-pill ml-1"><span v-html="filter.filterCriteria.toHtml()"></span></span>
                        <i class="fas fa-chevron-right text-grey"></i>
                    </div>
                </a>

                <h3 class="mt-5" style="margin-left: 30px;">{{translate('scores_and_indicators')}}</h3>
                <a href="" v-for="filter in scoreFilters" class="filter-list-item" @click.prevent="openFilterDetail(filter.title)">
                    <div>
                        <h3 class="font-weight-normal mb-0">{{ translate(filter.title) }}</h3>
                    </div>
                    <div class="d-flex align-items-center" style="gap: 20px">
                        <span v-if="filter.filterCriteria.hasValue" class="badge badge-pill ml-1"><span v-html="filter.filterCriteria.toHtml()"></span></span>
                        <i class="fas fa-chevron-right text-grey"></i>
                    </div>
                </a>

                <h3 class="mt-5" style="margin-left: 30px;" v-if="store.customFields.length > 0">{{translate('custom_fields')}}</h3>
                <a href="" v-for="customField in store.customFields" class="filter-list-item" @click.prevent="openFilterDetail(customField)">
                    <div>
                        <h4 class="font-weight-normal mb-0">{{ customField.name }}</h4>
                    </div>
                    <div class="d-flex align-items-center" style="gap: 20px">
                        <span v-if="hasSpecificCustomFilter(customField)" class="badge badge-pill ml-1"><span v-html="store.customFilters[customField.id]"></span></span>
                        <i class="fas fa-chevron-right text-grey"></i>
                    </div>
                </a>
            </div>
        </div>
        <div class="bottom-view">
            <a href="" class="btn btn-white" @click.prevent="close">{{translate('cancel')}}</a>
            <div class="font-italic text-grey text-center"><div class="font-weight-bold text-body">{{store.allFilteredCompanies.length}}</div> {{translate('x_selected_companies')}}</div>
            <button class="btn btn-default" :disabled="!store.hasAnyFilter" @click.prevent="store.openDrawer(DrawerView.FILTER_SAVE)">{{translate('save_filter')}}</button>
        </div>
    </div>
</template>

<script lang="ts">
import {useStore} from "../../store";
import Translate from "../../../mixins/translate";
import {CustomField, DrawerView, Filter, SavedFilter} from "../../types";
import Ajax from "../../../../lib/Ajax";
import Toast from "../../../../lib/Toast";
import Dropdown from '../../../shared/dropdown.vue';
import {storeToRefs} from "pinia";

export default Translate.extend({
    name: "filter-list",
    components: {Dropdown},
    data() {
        return {
            store: useStore(),
            DrawerView
        }
    },
    computed: {
        scoreFilters() {
            const columns = this.store.columns;
            
            return this.store.filters.genericFilters.filter(gf => columns.find(c => c.title === gf.title)!.isVertical);
        },
        companyFilters() {
            const columns = this.store.columns;

            return this.store.filters.genericFilters.filter(gf => !columns.find(c => c.title === gf.title)!.isVertical);
        }
    },
    methods: {
        hasSpecificCustomFilter(field: CustomField): boolean {
            const thisFilter = this.store.customFilters[field.id];

            if (thisFilter === undefined)
                return false;

            return (field.type === "boolean" && thisFilter != null) || (field.type === "string" && (thisFilter !== ""))
        },
        close() {
            this.store.closeDrawer();
        },
        openFilterDetail(filterTitle: string | CustomField) {
            this.store.openFilterDetailDrawer(filterTitle);
        },
        applyFilter(filter: Filter): void {
            const filteredTitles = filter.genericFilters
                .filter(f => f.filterCriteria.hasValue)
                .map(f => f.title);

            this.store.columns.filter(c => filteredTitles.includes(c.title)).forEach(column => {
                column.hidden = false;
            });

            this.store.applyFilter(filter);
            this.store.saveConfig();
            this.store.closeDrawer();
        },
        async deleteFilter(filter: SavedFilter): Promise<void> {
            await Ajax.postAsync("ajax/radar/delete-filter", {filterId: filter.id});

            this.store.savedFilters.removeBy(elem => elem.id === filter.id);
            this.store.saveConfig();
            Toast.success(this.translate('filter_deleted'));
        },
    }
});
</script>

<style scoped lang="scss">
.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;

    .top-view {
        padding: 30px 0;

        .title-row {
            padding: 0 30px;
            margin-bottom: 15px;
        }

    }
    
    .main-view {
        overflow-y: auto;
        overflow-x: hidden;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 1%; /* zorgt voor de scrollbar */

        &::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            border-radius: 10px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar
        {
            width: 8px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb
        {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
            background-color: #b0b0b0;
        }
        .filter-list {
            .filter-list-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 12px 30px;

                &:hover {
                    background-color: #e5e5e5;
                    text-decoration: none;
                }
            }
        }
    }

    .bottom-view {
        padding: 30px;
        border-top: 1px solid lightgrey;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .button-group {
            display: flex;
            gap: 5px;
        }
    }
}
</style>