import Page from '../lib/Page';
import Chart, { ChartData, ChartTooltipItem } from 'chart.js';

export interface CWChartData {
    percentages: number[];
    labels: string[];
    colors: string[];
    bckColors: string[];
}

export enum ChartType {
    PIE = 'pie',
    BAR = 'bar',
    LINE = 'line'
}

export default (function () {
    Page.registerBehavior('[pie-chart]', function (element) {
        const chartName = element.getAttribute('pie-chart')!;
        const hideTooltips = element.getAttribute('hide-tooltips') !== null;
        const showLegend = element.getAttribute('show-legend') !== null;
        const chartData =  getChartData(chartName);

        createChart(element as HTMLCanvasElement, ChartType.PIE, chartData, showLegend, hideTooltips);
    });  

    Page.registerBehavior('[bar-chart]', function (element) {
        const chartName = element.getAttribute('bar-chart')!;
        const hideTooltips = element.getAttribute('hide-tooltips') !== null;
        const showLegend = element.getAttribute('show-legend') !== null;
        const chartData =  getChartData(chartName);

        createChart(element as HTMLCanvasElement, ChartType.BAR, chartData, showLegend, hideTooltips);
    }); 

    function getChartData(chartName: string): CWChartData {
        const percentages = window.cw[chartName].percentages;
        const labels = window.cw[chartName].labels;
        const colors = window.cw[chartName].colors;
        const bckColors = window.cw[chartName].bckColors;
 
        if (!percentages) {
            console.error('missing percentages to create a pie chart.');
        }
        if (!labels) {
            console.error('missing labels to create a pie chart.');
        }
        if (!colors) {
            console.error('missing colors to create a pie chart.');
        }
        if (!bckColors) {
            console.error('missing bckColors to create a pie chart.');
        }

        return {
            percentages,
            labels,
            colors,
            bckColors
        }
    }
}());


export function createChart(element: HTMLCanvasElement, type: ChartType, chartData: CWChartData, hasLegend: boolean, hideToolips: boolean) {
    if (hideToolips === undefined || hideToolips === null)
        hideToolips = false;

    new Chart(element, {
        type: type,
        data: {
            labels: chartData.labels,
            datasets: [
                {
                    data: chartData.percentages,
                    backgroundColor: chartData.bckColors,
                    hoverBackgroundColor: chartData.colors,
                    borderColor: chartData.colors
                } 
            ]
        }, 
        options: {
            elements: {
                arc: {
                    borderWidth: 0
                }
            },
            legend: {
                display: hasLegend
            },
            tooltips: {
                enabled: !hideToolips,
                callbacks: {
                    label: function (tooltipItem: ChartTooltipItem, data: ChartData) {
                        const allData = data.datasets![tooltipItem.datasetIndex!].data as number[];
                        const tooltipLabel = data.labels![tooltipItem.index!] as string;
                        const tooltipData = allData![tooltipItem.index!] as number;
                        
                        const total = allData!.reduce((acc, curr) => {
                            return acc + curr;
                        }, 0);
                        
                        var tooltipPercentage = Math.round((tooltipData / total) * 100); 
                        return tooltipLabel + ': ' + tooltipData + ' (' + tooltipPercentage + '%)';
                    }
                }
            }
        }
    });
};