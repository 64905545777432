
<tr class="position-relative" :class="{'highlight': isNew && showNew, 'highlight-danger': isDeleted && showDeleted}">
    <td class="sticky">
        <company-list-item :address="company.zetelAddress"
                           :barometer="company.barometer"
                           :company-name="company.companyName"
                           :extra-addresses="company.vestigingsAdressen"
                           :is-in-alerts="company.isInAlerts"
                           :is-in-favourites="company.isInFavourites"
                           :vat="company.vat"
                           :vorm="company.juridischeVormTranslated"></company-list-item>
    </td>
    <td
        v-for="(column, index) in store.chosenColumns"
        :class="{
            
            'bold': column.isBold, 
            'narrow': column.isVertical, 
            'hover-highlight': !!hoveredColumn && column.title === hoveredColumn.title, 
            'highlight': !isDeleted && !isNew && showUpdated && isChanged(column)
        }"
        v-on:mouseenter="hoveredColumn = column"
        v-on:mouseleave="hoveredColumn = null"
        >
        <div class="hover-info" :class="{'hug-right': (index >= store.chosenColumns.length -2)}" v-if="hoveredColumn && column === hoveredColumn">{{translate(column.title)}}</div>
        <div v-html="column.toHtml(company)" :class="[{'d-flex justify-content-center': column.isVertical},column.toClass(company)]">
            
        </div>
    </td>

    <td is="custom-td" v-for="field in store.customFields" :company="company" :field="field" :key="'field_' + field.id"></td>
</tr>
