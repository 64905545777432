
<div class="pricing-table-row">
    <div class="pricing-cell">
        <a v-if="rowData.hasModal" href="" v-on:click.prevent="showModal(rowData.name)" class="link">
            {{translate(rowData.name)}}
        </a>
        <span v-else>
            {{translate(rowData.name)}}
        </span>
    </div>

    <pricing-cell v-for="column in columns" :key="column.name + '_row'" :currentPackage="column.selectedPackage" :selectedPackage="selectedPackage" :rowData="rowData"></pricing-cell>
</div>
