﻿import Vue from 'vue';
import Ajax from '../lib/Ajax';
import Page from '../lib/Page';

export default (function () {

    Page.registerVue('#userLogging', function (rootSelector) {
        new Vue({
            el: rootSelector,
            data: {
                loggingCookie: "",
                expiryDate: window.cw.expiryDate,
                counter: 0,
                forcedStop: false,
                present: new Date()
            },
            computed: {
                isLogging(): boolean {
                    return !this.forcedStop && !!this.loggingCookie && this.loggingCookie !== "";
                },
                expiryDateTime(): Date {
                    return new Date(this.expiryDate);
                },
                timeDiff(): number {
                    return Math.floor((this.expiryDateTime.getTime() - this.present.getTime()) / 1000);
                },
                hasCounter(): boolean {
                    return this.counter > 0;
                },
                deltaMinutes(): number {
                    return Math.floor(this.counter / 60) % 60;
                },
                deltaSeconds(): number {
                    return this.counter - (this.deltaMinutes * 60);
                },
                clock(): string {
                    return this.deltaMinutes + ':' + this.deltaSeconds;
                }
            },
            methods: {
                countDown(): void {
                    this.counter--;
                },
                stopLogging(): void {
                    Ajax.get(
                        '/ajax/help/stoplogging',
                        {},
                        () => {
                            this.forcedStop = true;
                        },
                        (err) => {
                            this.forcedStop = true;
                        }
                    );
                },
                getLoggingCookie(): string {
                    const name = "loggingkey=";
                    const decodedCookie = decodeURIComponent(document.cookie);
                    const ca = decodedCookie.split(';');
                    
                    for (let i = 0; i < ca.length; i++) {
                        let c = ca[i].trim();
                        
                        while (c.charAt(0) === ' ') {
                            c = c.substring(1);
                        }
                        
                        if (c.indexOf(name) === 0) 
                            return c.substring(name.length, c.length);
                    }
                    
                    return "";
                }
            },
            mounted() {
                this.loggingCookie = this.getLoggingCookie();

                this.counter = this.timeDiff;
                setInterval(this.countDown, 1000);
            }
        });
    });
}());
