﻿<template>
    <div class="container ">
        <div class="row vert-offset-bottom-2">
            <div class="col-md-6 vert-offset-bottom-1">
                <h1>{{translate('page_title')}}</h1>
                <p>
                    {{translate('page_intro')}}
                </p>
            </div>

            <div class="col-lg-5 offset-lg-1 col-md-6 mt-4" v-if="hasJoinLink && editablePermissions.seeShareLink">
                <b>{{translate('deelbare_link')}}</b>
                <p>
                    {{translate('join_link_intro')}}
                </p>
                <div class="copy-input-wrapper">
                    <input class="form-control"
                           :value="joinLink"
                           type="text"
                           ref="joinLink"
                           readonly v-on:click="selectJoinLink()" />
                    <a href="" v-on:click.prevent="copyJoinLink()" class="btn btn-default">
                        <i class="fas fa-clipboard mr-2"></i> {{translate('copy')}}
                    </a>
                </div>
            </div>
        </div>
        <div class="row vert-offset-bottom-2" v-if="userLimitReached">
            <div class="col-md-6">
                <div class="jumbotron jumbotron-cw">
                    <div class="content">
                        <h3>
                            {{translate('upgrade_users_title')}}
                        </h3>
                        <p v-if="showExtendedUpsellMessage">
                            {{translate('upgrade_users_body', userLimit)}}
                        </p>
                        <a :href="upgradeUsersLink" class="btn btn-info" :class="{'mt-3': !showExtendedUpsellMessage}">{{translate('upgrade_users_btn')}} <i class="fas fa-angle-right ml-2"></i></a>
                    </div>
                    <img src="/images/upsell.svg" :alt="translate('upgrade_users_btn')">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12 mb-3" v-if="needReviewUsers.length > 0 && isLoggedInAsAdmin">
                        <div class="row">
                            <needs-review-user v-for="(user, index) in needReviewUsers"
                                               :key="index"
                                               :index="index"
                                               :user="user"></needs-review-user>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row filter-row mb-3">
                            <div class="col-md-4">
                                <div class="filter-input">
                                    <i class="fas fa-filter"></i>
                                    <input type="text" :placeholder="translate('filter_placeholder')" v-model="filterTerm" />
                                </div>
                            </div>
                            <div class="col-md-4 my-auto text-center">
                                {{users.filter(u => u.type === "active").length}} / {{userLimit}} {{translate('users').toLowerCase()}}
                            </div>
                            <div class="col-md-4 text-right" v-if="isLoggedInAsAdmin && editablePermissions.addUsers">
                                <a href="" class="btn btn-info" v-on:click.prevent="goToCreateMode">
                                    <i class="fas fa-plus"></i> &nbsp;{{translate('gebruiker_toevoegen')}}
                                </a>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 users">
                                <table class="table phone-table data-table">
                                    <thead>
                                        <tr>
                                            <th width="80">{{translate('beheerder')}}?</th>
                                            <th width="180">{{translate('naam')}}</th>
                                            <th width="120">{{translate('status')}}</th>
                                            <th width="280">{{translate('email')}}</th>
                                            <th width="230">{{translate('laatst_actief')}}</th>
                                            <th v-if="isLoggedInAsAdmin"><i :title="translate('aantal_mobile')" class="fas fa-mobile-alt"></i></th>
                                            <th><i :title="translate('aantal_persoonlijke_alerts_gebruikt')" class="fas fa-bell"></i></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="user in filteredUsers"
                                            :user="user"
                                            is="user-row"
                                            @edit="editUser"
                                            :key="user.userId"></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal :title="translate('gebruiker_toevoegen')" id="add-user">
            <template v-slot>
                <form v-on:submit.prevent="inviteUser" 
                      class="full-width" 
                      ref="inviteForm">
                    <template v-if="newUser.isLoading">
                        <div class="text-center">
                            <i class="fas fa-spinner spinning fa-lg"></i>
                        </div>
                    </template>
                    <template v-else>
                        <div class="row mb-3">
                            <div class="col-8">
                                <label>{{translate('email')}}</label>
                                <input data-val-regex-pattern="^[-_.a-zA-Z0-9+]+@[-_.a-zA-Z0-9]+\.[a-zA-Z0-9]+$"
                                       class="form-control"
                                       type="text"
                                       v-model="newUser.email"
                                       :placeholder="translate('email_address')"
                                       ref="email"
                                       v-validate.required.email="translate('email')" />
                            </div>
                            <div class="col-4">
                                <label>{{translate('language')}}</label>
                                <select v-model="newUser.language" class="form-control" v-validate.required="translate('language')">
                                    <option v-for="lang in Language" :value="lang" :key="lang">{{lang|uppercase}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <div v-if="!!newUser.errorMessage" class="card-box warning small mb-0 mt-1">
                                    {{newUser.errorMessage}}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <button type="submit" class="btn btn-info btn-block">{{translate('uitnodigen')}}</button>
                            </div>
                        </div>
                    </template>
                </form>
            </template>
        </modal>

        <modal :title="reviewModal.title" id="review">
            <template v-slot>
                <div v-html="translate('suspicious_domain_user_activate_confirmation_article', avHtml)"></div>

                <div class="mt-3" v-html="reviewModal.body"></div>

                <div class="mt-3">
                    <a href="" class="btn btn-info" v-on:click.prevent="acceptUser">{{reviewModal.yesBtn}}</a>
                    <a class="btn btn-default" v-on:click.prevent="rejectUser" v-if="reviewModal.type !== 'deactivated'">{{reviewModal.noBtn}}</a>
                </div>
            </template>
        </modal>
        
        <modal :title="translate('edit_permissions')" id="edit-user" size="lg">
            <template v-slot>
                <form v-on:submit.prevent="savePermissions" v-if="editableUser">
                    <h2 class="mb-4">{{ editableUser.name }}</h2>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input :id="'userIsAdmin' + editableUser.userId"
                                       type="checkbox"
                                       class="form-check-input"
                                       :checked="editableUser.isAdmin"
                                       :disabled="!isLoggedInAsAdmin || cannotDemoteFromAdmin"
                                       @change.stop="editAdmin($event)"/>
                                <label :for="'userIsAdmin' + editableUser.userId"
                                       class="mb-0">{{ translate("beheerder") }}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" v-if="isLoggedInAsAdmin && editablePermissions.vervaldagboek">
                                <div>
                                    <input type="checkbox" class="form-check-input"
                                           :id="'vervaldagboek' + editableUser.userId"
                                           v-model="editableUser.permissions.hasVervaldagboek"/>
                                    <label :for="'vervaldagboek' + editableUser.userId"
                                           class="mb-0">{{ translate('vervaldagboek') }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" v-if="editablePermissions.alertsPlus">
                                <label>ALERTS+</label>
                                <select class="form-control" v-model="editableUser.permissions.alertsPlusAccessLevel">
                                    <option v-for="apAccesslevel in apAccesslevels" :value="apAccesslevel"
                                            :key="'al'+apAccesslevel">
                                        {{ translate('alertsplus_accesslevel_' + apAccesslevel) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" v-if="isLoggedInAsAdmin && editablePermissions.internationaal">
                                <label>{{ translate('internationaal_rapport') }}</label>
                                <select class="form-control"
                                        v-model="editableUser.permissions.internationaalAccessLevel">
                                    <option v-for="irAccesslevel in irAccesslevels" :value="irAccesslevel"
                                            :key="'ir'+irAccesslevel">
                                        {{ translate('internationaal_accesslevel_' + irAccesslevel) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" v-if="isLoggedInAsAdmin && editablePermissions.dataMarketing">
                                <label>{{ translate('datamarketing') }}</label>
                                <select class="form-control"
                                        v-model="editableUser.permissions.dataMarketingAccessLevel">
                                    <option v-for="dmAccesslevel in dmAccesslevels" :value="dmAccesslevel"
                                            :key="'dm'+dmAccesslevel">
                                        {{ translate('internationaal_accesslevel_' + dmAccesslevel) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group"
                                 v-if="isLoggedInAsAdmin && editablePermissions.radar">
                                <label>Radar</label>
                                <select class="form-control" v-model="editableUser.permissions.radarAccessLevel">
                                    <option v-for="radarAccessLevel in radarAccesslevels" :value="radarAccessLevel"
                                            :key="'radar'+radarAccessLevel">
                                        {{ translate('internationaal_accesslevel_' + radarAccessLevel) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" v-if="isLoggedInAsAdmin && hasMobileDevices">
                        <hr>
                        <label>{{ translate('mobile_devices') }}</label>
                        <div class="col-md-12">
                            <div class="row" v-for="(device, index) in editableUser.mobileInstalls.filter(x => x.isActive && !x.isOldMobileAccount)">
                                {{ mobileDeviceName(device) }}
                                <button class="btn btn-sm ml-2 remove show-on-hover"
                                        v-if="!device.isOldMobileAccount"
                                        v-on:click.prevent.stop="changeDeviceStatus(device.id, false)"
                                        :title="translate('deactivate_device')">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </div>
                            <div class="row grey" v-for="(device, index) in editableUser.mobileInstalls.filter(x => !x.isActive && !x.isOldMobileAccount)">
                                {{ mobileDeviceName(device) }}
                                <button class="btn btn-sm ml-2 show-on-hover"
                                        v-if="!device.isOldMobileAccount"
                                        v-on:click.prevent.stop="changeDeviceStatus(device.id, true)"
                                        :title="translate('activate_device')">
                                    <i class="fas fa-check-circle"></i>
                                </button>
                            </div>
                            <div class="row grey" v-for="(device, index) in editableUser.mobileInstalls.filter(x => x.isOldMobileAccount)">
                                {{ mobileDeviceName(device) }}
                            </div>
                        </div>
                    </div>

                    <button type="submit" class="btn btn-info mt-3">{{ translate("btn_save") }}</button>
                </form>
            </template>
        </modal>
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import Ajax from '../../lib/Ajax';
import Toast from '../../lib/Toast';
import Translate from '../mixins/translate';
import Validate from '../directives/validate';
import NeedsReviewUserComponent from './needs-review-user.vue';
import UserRow from './user-row.vue';
import Modal from '../shared/modal.vue';
import mixins from 'vue-typed-mixins';
import {Company, EditablePermissions, InviteUserResult, MobileInstalls, RadarAccessLevel, User} from './types';
import {Language} from '../../types/dto/Language';
import {AlertsPlusAccessLevel} from '../../types/dto/AlertsPlusAccessLevel';
import {InternationaalAccessLevel} from '../../types/dto/InternationaalAccessLevel';
import {DataMarketingAccessLevel} from '../../types/dto/DataMarketingAccessLevel';
import Confirm from '../../lib/Confirmation';


export default mixins(Translate).extend({
        name: 'account-users',
        directives: {
            Validate
        },
        components: {
            UserRow,
            'needs-review-user': NeedsReviewUserComponent,
            'modal': Modal
        },
        props: {
            users: Array as () => User[],
            currentUserEmail: String,
            isLoggedInAsAdmin: Boolean as () => boolean,
            userLimit: Number,
            editablePermissions: Object as () => EditablePermissions,
            company: Object as () => Company,
            hasJoinLink: Boolean,
            upgradeUsersLink: String,
            joinLink: String,
            avLink: String
        },
        filters: {
            uppercase(str: string): string {
                return str === null
                    ? ''
                    : str.toUpperCase();
            }
        },
        data() {
            return {
                Language,
                reviewModal: {
                    title: '',
                    body: '',
                    yesBtn: '',
                    noBtn: '',
                    userId: 0,
                    email: '',
                    type: ''
                },
                filterTerm: '',
                newUser: {
                    email: '',
                    emailExists: false,
                    errorMessage: '',
                    language: Language.NL as Language,
                    isLoading: false
                },
                editableUser: null as User | null,
                addedUsers: [] as User[],
                removedUsers: [] as User[],
                defaultUserLimit: 10,
                apAccesslevels: window.cw.apAccesslevels as AlertsPlusAccessLevel[],
                irAccesslevels: window.cw.irAccesslevels as InternationaalAccessLevel[],
                dmAccesslevels: window.cw.dmAccesslevels as DataMarketingAccessLevel[],
                radarAccesslevels: window.cw.radarAccesslevels as RadarAccessLevel[],
                RadarAccessLevel
            };
        },
        computed: {
            userLimitReached(): boolean {
                return this.activeUsers.length >= this.userLimit;
            },
            allUsers(): User[] {
                return _.difference(_.concat(this.users, this.addedUsers), this.removedUsers);
            },
            avHtml(): string {
                return "<a href='" + this.avLink + "' target='_blank'>" + this.translate('algemene_voorwaarden') + "</a>";
            },
            filteredUsers(): User[] {
                return _.orderBy(
                    _.filter(this.allUsers, (user) => {
                        return user.type !== "review-pending"
                            && (user.name.toLowerCase().indexOf(this.filterTerm.toLowerCase()) > -1
                                || user.email.toLowerCase().indexOf(this.filterTerm.toLowerCase())) > -1;
                    }),
                    [
                        (user) => {
                            return user.isAdmin;
                        },
                        (user) => {
                            return user.type === 'active';
                        },
                        (user) => {
                            return user.type === 'pending';
                        },
                        (user) => {
                            return user.name;
                        }
                    ],
                    ['desc', 'desc', 'desc', 'asc']
                );
            },
            activeUsers(): User[] {
                return _.filter(this.allUsers, (user) => {
                    return user.type === "active"
                        && (user.name.toLowerCase().indexOf(this.filterTerm.toLowerCase()) > -1
                            || user.email.toLowerCase().indexOf(this.filterTerm.toLowerCase())) > -1;
                });
            },
            pendingUsers(): User[] {
                return _.filter(this.allUsers, (user) => {
                    return user.type === "pending"
                        && (user.name.toLowerCase().indexOf(this.filterTerm.toLowerCase()) > -1
                            || user.email.toLowerCase().indexOf(this.filterTerm.toLowerCase())) > -1;
                });
            },
            showExtendedUpsellMessage(): boolean {
                return this.userLimit === this.defaultUserLimit && (this.activeUsers.length + this.pendingUsers.length === this.defaultUserLimit);
            },
            deactivatedUsers(): User[] {
                return _.filter(this.allUsers, (user) => {
                    return user.type === "deactivated"
                        && (user.name.toLowerCase().indexOf(this.filterTerm.toLowerCase()) > -1
                            || user.email.toLowerCase().indexOf(this.filterTerm.toLowerCase())) > -1;
                });
            },
            needReviewUsers(): User[] {
                return _.filter(this.allUsers, (user) => {
                    return user.type === "review-pending";
                });
            },
            isCurrentUser(): boolean {
                return this.editableUser?.email === window.cw.currentUserEmail;
            },
            cannotDemoteFromAdmin(): boolean {
                return this.totalAmountOfAdmins === 1 && (this.editableUser?.isAdmin ?? false)
            },
            totalAmountOfAdmins(): number {
                //@ts-ignore
                return _.filter(this.allUsers, user => user.isAdmin).length;
            },
            nameOrEmail(): string {
                return (this.editableUser?.name.trim() !== '' ? this.editableUser?.name : this.editableUser?.email) ?? "";
            },
            hasMobileDevices(): boolean {
                return this.editableUser!.mobileInstalls.length > 0;
            },
        },
        methods: {
            mobileDeviceName(device: MobileInstalls): string {
                return device.name.trim() !== '' ? device.name : "Unknown";
            },
            editAdmin(event: Event) {
                const isSetToAdmin = (event.target as HTMLInputElement).checked;
                if (!isSetToAdmin && this.isCurrentUser) {
                    (event.target as HTMLInputElement).checked = true;
                    Confirm(
                        this.demoteFromAdmin,
                        {
                            title: this.translate('confirmation_demote_yourself_title'),
                            body: this.translate('confirmation_demote_yourself_body'),
                            confirmButtonText: this.translate('confirmation_demote_yourself_ok'),
                            cancelButtonText: this.translate('confirmation_cancel')
                        }
                    );
                } else if (!isSetToAdmin) {
                    this.demoteFromAdmin();
                } else if (isSetToAdmin) {
                    this.promoteToAdmin();
                }

            },
            async promoteToAdmin(): Promise<void> {
                await Ajax.postAsync('/ajax/account/promote-to-admin', { userId: this.editableUser!.userId});

                this.editableUser!.isAdmin = true;
                this.$root.$emit('userPromoted', this.editableUser!.userId);
                Toast.success(this.translate('user_promoted'));
            },
            async demoteFromAdmin(): Promise<void> {
                await Ajax.postAsync('/ajax/account/demote-from-admin', { userId: this.editableUser!.userId});

                this.editableUser!.isAdmin = false;
                this.$root.$emit('userDemoted', this.editableUser!.userId);
                Toast.success(this.translate('user_demoted'));

                if (this.isCurrentUser)
                    window.location.reload();
            },
            editUser(user: User): void {
                this.editableUser = user;
                this.$root.$emit('show-modal', 'edit-user');
            },
            async savePermissions(): Promise<void> {
                const user = this.editableUser!;
                
                await Ajax.postAsync(
                    '/ajax/account/setpermissions',
                    {
                        userId: user.userId,
                        hasVervaldagboek: user.permissions.hasVervaldagboek,
                        alertsPlusAccessLevel: user.permissions.alertsPlusAccessLevel,
                        internationaalAccessLevel: user.permissions.internationaalAccessLevel,
                        dmAccesslevel: user.permissions.dataMarketingAccessLevel,
                        radarAccesslevel: user.permissions.radarAccessLevel,
                    }
                );

                Toast.success(this.translate('permissions_editted', user.name));
                this.$root.$emit('hide-modal', 'edit-user');
            },
            selectJoinLink(): void {
                var joinLinkInput = this.$refs.joinLink as HTMLInputElement;
                joinLinkInput.select();
            },
            copyJoinLink(): void {
                var joinLinkInput = this.$refs.joinLink as HTMLInputElement;
                joinLinkInput.select();
                joinLinkInput.setSelectionRange(0, 99999);//mobile devices
                document.execCommand('copy');
                joinLinkInput.blur();
                Toast.info(this.translate('copied_to_clipboard'));
            },
            goToCreateMode(): void {
                this.$root.$emit('show-modal', 'add-user');

                this.$nextTick(() => {
                    return (this.$refs.email as HTMLInputElement).focus();
                });

            },
            async inviteUser(): Promise<void> {
                if (this.newUser.isLoading)
                    return;

                this.newUser.isLoading = true;

                try {
                    const result = await Ajax.postAsync<InviteUserResult>(
                    '/ajax/account/send-invite',
                    {
                        email: this.newUser.email,
                        language: this.newUser.language
                    });

                     this.newUser.isLoading = false;

                        if (!result.userInvited)
                            this.newUser.errorMessage = result.notInvitedReason!;
                        else {
                            this.$root.$emit('userInvited', {
                                email: this.newUser.email,
                                userId: result.userId
                            });

                            this.$root.$emit('hide-modal', 'add-user');

                            this.newUser.errorMessage = '';
                            this.newUser.email = '';
                        }
                } catch(e: unknown) {
                    this.newUser.errorMessage = e as string;
                    this.newUser.isLoading = false;
                }
            },
            addUser(newUser: User): void {
                this.addedUsers.push({
                    name: '',
                    userId: newUser.userId,
                    email: newUser.email,
                    lastActive: 0,
                    mobileInstalls: [],
                    alertsUsage: 0,
                    type: 'pending',
                    hasSuspiciousDomain: false,
                    isAdmin: false,
                    permissions: {
                        hasVervaldagboek: false,
                        alertsPlusAccessLevel: AlertsPlusAccessLevel.NONE,
                        internationaalAccessLevel: InternationaalAccessLevel.READONLY,
                        dataMarketingAccessLevel: DataMarketingAccessLevel.FULL
                    }
                });
            },
            startReview(user: User): void {
                this.reviewModal.title = this.translate("suspicious_domain_user_activate_confirmation_title", user.email);
                this.reviewModal.body = this.translate("suspicious_domain_user_activate_confirmation_body",
                    user.email,
                    this.company.name,
                    this.company.vat !== 0 ? this.company.vat : "0");
                this.reviewModal.yesBtn = this.translate("suspicious_domain_user_activate_confirmation_ok");
                this.reviewModal.noBtn = this.translate("suspicious_domain_user_activate_confirmation_cancel");

                this.reviewModal.userId = user.userId;
                this.reviewModal.email = user.email;
                this.reviewModal.type = user.type;

                this.$root.$emit('show-modal', 'review');
            },
            acceptUser(): void {
                Ajax.post(
                    '/ajax/account/accept-user',
                    {
                        userId: this.reviewModal.userId
                    },
                    () => {
                        this.$root.$emit('hide-modal', 'review');
                        const user = _.find(this.allUsers, (u) => {
                            return u.email === this.reviewModal.email;
                        })!;

                        user.type = 'active';
                    },
                    (error) => {
                        Toast.error(error);
                        this.$root.$emit('hide-modal', 'review');
                    }
                );
            },
            rejectUser(): void {
                Ajax.post(
                    '/ajax/account/reject-user',
                    {
                        userId: this.reviewModal.userId
                    },
                    () => {
                        this.$root.$emit('hide-modal', 'review');
                        const user = _.find(this.allUsers, (u) => {
                            return u.email === this.reviewModal.email;
                        })!;

                        user.type = 'deactivated';
                    },
                    (error) => {
                        Toast.error(error);
                        this.$root.$emit('hide-modal', 'review');
                    }
                );
            },
            changeDeviceStatus(deviceId: string, active: boolean): void {
                Ajax.post(
                    '/ajax/account/change-device-status',
                    {
                        userId: this.editableUser!.userId,
                        installId: deviceId,
                        isActive: active
                    },
                    () => {
                        const device = this.editableUser!.mobileInstalls.find(x => x.id === deviceId);
                        if (device)
                            device.isActive = active;
                    },
                    (error) => {
                        Toast.error(error);
                    }
                );
            }
        },
        mounted() {
            this.$root.$on('userInvited', (user: User) => {
                this.addUser(user);
            });

            this.$root.$on('userDeactivated', (email: string) => {
                const deactivatedUser = _.find(this.allUsers, (u) => {
                    return u.email === email;
                })!;

                deactivatedUser.type = "deactivated";
            });

            this.$root.$on('userReactivated', (email: string) => {
                const reactivatedUser = _.find(this.allUsers, (u) => {
                    return u.email === email;
                })!;

                reactivatedUser.type = "active";
            });

            this.$root.$on('pendingUserRemoved', (userId: number) => {
                const removedUser = _.find(this.allUsers, (u) => {
                    return u.userId === userId;
                })!;
                this.removedUsers.push(removedUser)
            });

            this.$root.$on('startReview', (user: User) => {
                this.startReview(user);
            });
        }
    });
</script>