<template>
    <tr role="row">
        <td class="p-0 border-0">
            <div  class="timetable-row person-row">
                <table class="table table-small">
                    <tr>
                        <td colspan="2">
                            <div class="person">
                                <a :href="person.Link" class="personname">
                                    <b>{{person.VolledigeNaam.Lastname}} {{person.VolledigeNaam.Firstname}}</b>
                                </a>
                                <a :href="person.Link" class="btn btn-transparent btn-sm d-inline-block ml-1" target="_blank">
                                    <i class="fas fa-external-link-alt ext-link-icon"></i>
                                </a>
                                <div class="address text-lightgrey">
                                    {{person.Adres.Lijn1}}, {{person.Adres.Lijn2}}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <event-row v-for="(event, index) in events" :key="person.Id+'-event-'+index" v-bind="event"></event-row>
                </table>
            </div>
        </td>
    </tr>
</template>

<script>
    import EventRow from './event-row.vue';
    import Truncate from 'Mixins/truncate';
    import _ from 'lodash';

    export default {
        name: 'person-event',
        props: {
            events: Array,
            id: Number,
            currentList: Object
        },
        mixins: [Truncate],
        components: {
            EventRow
        },
        computed: {
            person() {
                return _.find(this.currentList.Persons, person => person.Id == this.id);
            }
        }
    }
</script>

<style scoped>
    .personname {
        font-size: 15px;
    }
    .address {
        font-size: 13px;
    }
    .ext-link-icon {
        font-size: 10px;
        vertical-align: top;
    }
</style>