
<ul class="pl-3" >
    <li v-for="node in nodes" :key="node.value">
        <span v-if="hasMatchingDescendants(node)" @click="onExpandClick(node)" class="expander">
            <i aria-hidden="true" class="fas fa-lg fa-angle-down" v-if="isExpanded(node)"></i>
            <i aria-hidden="true" class="fas fa-lg fa-angle-right text-lightgrey" v-else></i> 
        </span>

        <input type="checkbox" class="form-check-input azure" :id="node.value" :checked="isSelected(node)" @change="select(node)" :disabled="isSelectedAndDisabled"/>
        <label :for="node.value">{{node.name}}</label>

        <selection-branch v-if="node.children && isExpanded(node)" :isParentSelected="isSelected(node)" :criteria="criteria" :tree-data="node.children" :column="column" :filterTerm="filterTerm"></selection-branch>
    </li>
</ul>
