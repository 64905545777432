<template>
    <span v-html="translateString"></span>
</template>

<script lang="ts">
    import Translate from '../mixins/translate';
    import mixins from 'vue-typed-mixins';
    import { VNode } from 'vue';
    import _ from 'lodash';

    export default mixins(Translate).extend({
        name:"translation",
        props: {
            label: String
        },
        computed: {
            translateString(): string {
                const params = this.$slots.default?.filter((node: VNode) => !!node.tag) ?? [];
                const htmlParams = params.map(p => this.nodeToHtml(p));

                return this.translate(this.label, ...htmlParams)
            }
        },
        methods:{
            nodeToHtml(node: VNode): string {
                if(!node.tag)
                    return '';
                
                const tag: string = node.tag;
                const staticClass: string = node.data?.staticClass ?? '';
                const attributes: string = _.map(node.data?.attrs, (v, k) => {
                    return `${k}="${v}"`;
                }).join(" ");
                const content: string = node.text ?? (node.children?.length ?? 0) > 0 
                    ? (node.children![0].text ?? '')
                    : '';

                return `<${tag} class="${staticClass}" ${attributes}>${content}</${tag}>`;
            }
        }
    })
</script>

<style scoped>

</style>