﻿<template>
    <tr class="position-relative" :class="{'highlight': isNew && showNew, 'highlight-danger': isDeleted && showDeleted}">
        <td class="sticky">
            <company-list-item :address="company.zetelAddress"
                               :barometer="company.barometer"
                               :company-name="company.companyName"
                               :extra-addresses="company.vestigingsAdressen"
                               :is-in-alerts="company.isInAlerts"
                               :is-in-favourites="company.isInFavourites"
                               :vat="company.vat"
                               :vorm="company.juridischeVormTranslated"></company-list-item>
        </td>
        <td
            v-for="(column, index) in store.chosenColumns"
            :class="{
                
                'bold': column.isBold, 
                'narrow': column.isVertical, 
                'hover-highlight': !!hoveredColumn && column.title === hoveredColumn.title, 
                'highlight': !isDeleted && !isNew && showUpdated && isChanged(column)
            }"
            v-on:mouseenter="hoveredColumn = column"
            v-on:mouseleave="hoveredColumn = null"
            >
            <div class="hover-info" :class="{'hug-right': (index >= store.chosenColumns.length -2)}" v-if="hoveredColumn && column === hoveredColumn">{{translate(column.title)}}</div>
            <div v-html="column.toHtml(company)" :class="[{'d-flex justify-content-center': column.isVertical},column.toClass(company)]">
                
            </div>
        </td>

        <td is="custom-td" v-for="field in store.customFields" :company="company" :field="field" :key="'field_' + field.id"></td>
    </tr>
</template>

<script lang="ts">
    import {Column, Subset, RadarCompany} from '../types';
    import CompanyListItem from '../../shared/company-list-item.vue';
    import {useStore} from '../store';
    import CustomTd from './custom-td.vue';
    import Translate from '../../mixins/translate';

    export default Translate.extend({
        name: 'company-row',
        components: {CompanyListItem, CustomTd},
        props: {
            company: {
                type: Object as () => RadarCompany,
                required: true
            }
        },
        data() {
            return {
                store: useStore(),
                Subset,
                hoveredColumn: null as Column | null
            };
        },
        computed: {
            showNew(): boolean {
                return this.store.subsetFilter === Subset.NEW;
            },
            showDeleted(): boolean {
                return this.store.subsetFilter === Subset.DELETED;
            },
            showUpdated(): boolean {
                return this.store.subsetFilter === Subset.UPDATED;
            },
            isNew(): boolean {
                return !!this.company.enterListDate && Date.fromHansDate(this.company.enterListDate).isSameMonthOrMoreRecent(this.store.currentPeriodStart);
            },
            isDeleted(): boolean {
                return !!this.company.leaveListDate && Date.fromHansDate(this.company.leaveListDate).isSameMonthOrMoreRecent(this.store.currentPeriodStart);
            }
        },
        methods: {
            isChanged(column: Column): boolean {
                return !!column.lastUpdated(this.company) && Date.fromHansDate(column.lastUpdated(this.company)!).isSameMonthOrMoreRecent(this.store.currentPeriodStart);
            }
        }
    });
</script>

<style lang="scss">
  .hover-info {
    background: #e0e0e0;
    position: absolute;
    min-width: 150px;
    z-index: 20;
    padding: 3px 6px;
    border-radius: 4px;
    box-shadow: 2px 2px 2px #00000055;
    top: 105%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center; 
    
    &.hug-right {
      left: auto;
      right: 0;
      transform: initial;
    }
  }

    .radar-overview {
      td, th {
        background: inherit;
        padding: 0.2em 0.8em;

        &.narrow {
          padding: 0.2em;
        }
        &.sticky {
          position: sticky;
          left: 0;
          cursor: initial;
          z-index: 1;
          &:after {
            content: '';
            width: 20px;
            height: 100%;
            position: absolute;
            top: 0;
            right:0;
            box-shadow: 20px 0 18px rgba(0, 0, 0, 0.1);
            z-index: -1;
          }
        }
      }
      th.sticky {
        z-index: 2;
      }
        tr {
            &:hover {
                td {
                  background: whitesmoke;
                }
            }
          td {
            border-top: 1px solid #e5e5e5;
            vertical-align: middle;
            white-space: break-spaces;
            position: relative;
            background: white;
            
            &.highlight {
              background: #1ab9d60a;
              border: 1px solid #1ab9d6;
              border-radius: 3px;
            }
            &.hover-highlight {
              background: #e0e0e0 !important;
                color: #3d3232 !important;
            }

            span > i {
              color: #c1c1c1;
            }

              div.risk-score {
                  text-align: center;
                  border-radius: 3px;
                  $max-score: 5;
                  @for $i from 0 through $max-score {

                      &.risk-#{$i} {
                          background-color: rgba(215, 20, 20, ($i / $max-score) - ($i * 0.09));
                      }
                  }
              }
          }
        }
    }
</style>