﻿<template>
  <div>
      <label>{{translate("ondergrens")}}</label>
      <datepicker v-model="criteria._value.lowerBound"
                  :language="localeNl"
                  :bootstrap-styling="true"
                  :monday-first="true"
                  initial-view="year"
                  format="dd/MM/yyyy"
                  calendar-button
                  calendar-button-icon="fa fa-calendar"
                  :disabled-dates="{from: criteria._value.upperBound}"
                  :full-month-name="true"
                  @selected="onSelectLowerBound">
      </datepicker>

      <label class="mt-4">{{translate("bovengrens")}}</label>
      <datepicker v-model="criteria._value.upperBound"
                  :language="localeNl"
                  :bootstrap-styling="true"
                  :monday-first="true"
                  initial-view="year"
                  format="dd/MM/yyyy"
                  calendar-button
                  calendar-button-icon="fa fa-calendar"
                  :disabled-dates="{to: criteria._value.lowerBound}"
                  :full-month-name="true"
                  @selected="onSelectUpperBound">
      </datepicker>
  </div>
</template>

<script lang="ts">
    //@ts-ignore
    import { en, nl, fr } from 'vuejs-datepicker/dist/locale';
    //@ts-ignore
    import Datepicker from "vuejs-datepicker";
    import {DateRangeFilterCriteria} from "../../../types";
    import Translate from '../../../../mixins/translate';

    export default Translate.extend({
        name: "date-rage",
        components: {Datepicker},
        props: {
            criteria: {
                required: true,
                type:Object as () => DateRangeFilterCriteria
            }
        },
        data() {
            return {
                localeNl: nl,
            }
        },
        methods: {
            onSelectLowerBound(date: Date | null) {
                if(!date)
                    return;
                
                this.criteria._value.lowerBound = this.removeTime(date);
            },
            onSelectUpperBound(date: Date | null) {
                if(!date)
                    return;

                this.criteria._value.upperBound = this.removeTime(date);
            },
            removeTime(date: Date): Date {
                let dateWithoutTime = date;
                dateWithoutTime.setHours(0);
                dateWithoutTime.setMinutes(0);
                dateWithoutTime.setSeconds(0);
                dateWithoutTime.setMilliseconds(0);
                
                return dateWithoutTime;
            }
        }
    })
</script>

<style scoped lang="scss">
    .vdp-datepicker {
        .form-control:disabled, .form-control[readonly] {
            background-color: white !important;
        }
    }
</style>