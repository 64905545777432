
<div>
    <template v-if="screeningLimitReached">
        <div class="info-box warning">{{translate('screening_limit')}}</div>
    </template>
    <template v-else>
        <div class="row">
            <div class="col-md-12">
                <h3><b>Screening</b></h3>
                <p>
                    {{translate('screening_select_statussses_intro')}}
                </p>
            </div>
        </div>
        <div class="row my-2 my-md-5">
            <div class="col-md-4 my-2 my-md-auto">
                <vue-pie-chart :labels="pieLabels" :colors="pieColors" :numbers="pieNumbers"></vue-pie-chart>
            </div>
            <div class="col-md-7 offset-md-1">
                <div class="row mb-2">
                    <div class="col-4">
                        <b>{{translate('status')}}</b>
                    </div>
                    <div class="col-3">
                        <b>{{translate('amount')}}</b>
                    </div>
                    <div class="col-5">
                        <b>{{translate('add_to_selection')}}</b>
                    </div>
                </div>
                <div class="row mb-2" v-for="(status, key) in statusses" :key="key">
                    <div class="col-4">
                        <span class="status" :class="status.name">{{translate(status.name)}}</span>
                    </div>
                    <div class="col-3">
                        {{status.companies.length}}
                    </div>
                    <div class="col-5">
                        <slide-toggle azure v-model="status.isSelected" :name="status.name"></slide-toggle>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9 mb-2 mb-md-0">
                <alerts-usage :used="totalUsed" :selected="totalSelected" :max="maxCompanies"></alerts-usage>
            </div>
            <div class="col-md-3 my-auto">
                <a href="" class="btn btn-blue btn-block" @click.prevent="proceed()">{{proceedBtnLabel}} <i class="fas fa-angle-right ml-2"></i></a>
            </div>
        </div>
    </template>
</div>
