<template>
    <div>
        <h2 class="mb-4"><b>{{translate("sidebar_financial")}}</b></h2>

        <div class="row mt-4">
            <div class="col-md-12">
                <h3>{{translate(ProspectieDataSelectionCriteriumType.BRUTOMARGE)}}</h3>
                <form class="row" @submit.prevent="addRange(ProspectieDataSelectionCriteriumType.BRUTOMARGE)">
                    <div class="col-5 col-md-4">
                        <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.BRUTOMARGE].lowerbound"/>
                    </div>
                    <div class="col-2 col-md-1 flex-h-center my-auto font-weight-bold">
                        {{translate("tot_en_met")}}
                    </div>
                    <div class="col-5 col-md-4">
                        <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.BRUTOMARGE].upperbound"/>
                    </div>
                    <div class="col-md-3 mt-3 mt-md-0">
                        <button class="btn btn-default btn-block" type="submit">{{translate("btn_add")}}</button>
                    </div>
                </form>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-md-12">
                <h3>{{translate(ProspectieDataSelectionCriteriumType.OMZET)}}</h3>
                <form class="row" @submit.prevent="addRange(ProspectieDataSelectionCriteriumType.OMZET)">
                    <div class="col-5 col-md-4">
                        <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.OMZET].lowerbound"/>
                    </div>
                    <div class="col-2 col-md-1 flex-h-center my-auto font-weight-bold">
                        {{translate("tot_en_met")}}
                    </div>
                    <div class="col-5 col-md-4">
                        <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.OMZET].upperbound"/>
                    </div>
                    <div class="col-md-3 mt-3 mt-md-0">
                        <button class="btn btn-default btn-block" type="submit">{{translate("btn_add")}}</button>
                    </div>
                </form> 
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-md-12">
                <h3>{{translate(ProspectieDataSelectionCriteriumType.WINSTVERLIES)}}</h3>
                <form class="row" @submit.prevent="addRange(ProspectieDataSelectionCriteriumType.WINSTVERLIES)">
                    <div class="col-5 col-md-4">
                        <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.WINSTVERLIES].lowerbound"/>
                    </div>
                    <div class="col-2 col-md-1 flex-h-center my-auto font-weight-bold">
                        {{translate("tot_en_met")}}
                    </div>
                    <div class="col-5 col-md-4">
                        <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.WINSTVERLIES].upperbound"/>
                    </div>
                    <div class="col-md-3 mt-3 mt-md-0">
                        <button class="btn btn-default btn-block" type="submit">{{translate("btn_add")}}</button>
                    </div>
                </form> 
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-md-12">
                <h3>{{translate(ProspectieDataSelectionCriteriumType.BALANSTOTAAL)}}</h3>
                <form class="row" @submit.prevent="addRange(ProspectieDataSelectionCriteriumType.BALANSTOTAAL)">
                    <div class="col-5 col-md-4">
                        <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.BALANSTOTAAL].lowerbound"/>
                    </div>
                    <div class="col-2 col-md-1 flex-h-center my-auto font-weight-bold">
                        {{translate("tot_en_met")}}
                    </div>
                    <div class="col-5 col-md-4">
                        <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.BALANSTOTAAL].upperbound"/>
                    </div>
                    <div class="col-md-3 mt-3 mt-md-0">
                        <button class="btn btn-default btn-block" type="submit">{{translate("btn_add")}}</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12">
                <h3>{{translate(ProspectieDataSelectionCriteriumType.EIGENVERMOGEN)}}</h3>
                <form class="row" @submit.prevent="addRange(ProspectieDataSelectionCriteriumType.EIGENVERMOGEN)">
                    <div class="col-5 col-md-4">
                        <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.EIGENVERMOGEN].lowerbound"/>
                    </div>
                    <div class="col-2 col-md-1 flex-h-center my-auto font-weight-bold">
                        {{translate("tot_en_met")}}
                    </div>
                    <div class="col-5 col-md-4">
                        <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.EIGENVERMOGEN].upperbound"/>
                    </div>
                    <div class="col-md-3 mt-3 mt-md-0">
                        <button class="btn btn-default btn-block" type="submit">{{translate("btn_add")}}</button>
                    </div>
                </form>
            </div>
        </div>
        
        <div class="tip mt-4 py-2">
            <translation label="financial_tip">
                <a href="tel:022552625" class="link" >02/255.26.25</a>
                <a href="/contact/message" class="link">{{translate("contact_form").toLowerCase()}}</a>
            </translation>
        </div> 
    </div>
</template>

<script lang="ts">
    import CriteriumRange from '../../criteriumRange';
    import DataMarketingService, { Request } from '../../dataMarketingService';
    import { ProspectieDataSelectionCriteriumType } from '../../../../types/dto/ProspectieDataSelectionCriteriumType';
    import { ProspectieDataRangeOperator } from '../../../../types/dto/ProspectieDataRange';
    import Translation from '../../../shared/translation.vue';
    import Translate from '../../../mixins/translate';
    import mixins from 'vue-typed-mixins';    
   
   interface Boundaries {
       lowerbound?: number;
       upperbound?: number;
   }

    export default mixins(Translate).extend({
        name: 'dm-financial',
        components:{Translation},
        data() {
            return {
                ProspectieDataSelectionCriteriumType,
                prospectieCriteria: DataMarketingService.getInstance().treeData!,
                values: {
                    [ProspectieDataSelectionCriteriumType.BRUTOMARGE]: {
                        lowerbound: undefined,
                        upperbound: undefined
                    },
                    [ProspectieDataSelectionCriteriumType.OMZET]: {
                        lowerbound: undefined,
                        upperbound: undefined
                    },
                    [ProspectieDataSelectionCriteriumType.WINSTVERLIES]: {
                        lowerbound: undefined,
                        upperbound: undefined
                    },
                    [ProspectieDataSelectionCriteriumType.BALANSTOTAAL]: {
                        lowerbound: undefined,
                        upperbound: undefined
                    },
                    [ProspectieDataSelectionCriteriumType.EIGENVERMOGEN]: {
                        lowerbound: undefined,
                        upperbound: undefined
                    }
                    
                } as Record<ProspectieDataSelectionCriteriumType, Boundaries>
            }
        },
        computed: {
            currentRequest(): Request{
                return DataMarketingService.getInstance().currentRequest;
            }
        },
        methods: {
            async addRange(type: ProspectieDataSelectionCriteriumType) {
                const range = new CriteriumRange(
                    ProspectieDataRangeOperator.BETWEEN, 
                    type,
                    this.values[type].lowerbound,
                    this.values[type].upperbound,
                );

                await this.currentRequest.addRange(range);
                await this.currentRequest.updateSummary();

                this.values[type].lowerbound = undefined;
                this.values[type].upperbound = undefined;
            }
        }
    })
</script>

<style scoped>

</style>