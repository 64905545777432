﻿import {Language} from "../../../types/dto/Language";

enum Tags {
    CASE = "case",
    ARTICLE = "article"
}

interface BlogArticle {
    id: number;
    creationDate: number;
    isPinned: boolean;
    isPublished: boolean;
    articleTranslations: BlogArticleTranslation[];
    relatedTags: Tags[];
    banner: string | null;
    assetDirectory: string;
    titleNl: string | null;
    excerptNl: string | null;
    detailLink: string;
}

interface BlogArticleTranslation {
    id: number;
    articleId: number;
    language: Language;
    title: string;
    excerpt: string;
    text: string;
}

export {
    Tags,
    BlogArticle,
    BlogArticleTranslation
}