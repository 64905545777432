import { DataMarketingRequest } from '../../types/dto/DataMarketingRequest';
import { ProspectieDataSelectionCriteriumType } from '../../types/dto/ProspectieDataSelectionCriteriumType';
import { IProspectieDataTreeNode } from '../../types/dto/IProspectieDataTreeNode';

export type FormatLabel = (count: number) => string;


export type TreeData  = Record<ProspectieDataSelectionCriteriumType, IProspectieDataTreeNode[]>;

export interface DeduplicationMethods {    
    hasFile: boolean;
    requests: DataMarketingRequest[]
}

export enum RequestStatus {
    STARTED,
    PENDING,
    FINISHED
}

export enum RequestSelectionStatus {
    WITHIN_CREDIT_LIMIT,
    ABOVE_CREDIT_LIMIT,
    ABOVE_UPPER_LIMIT
}

export interface RadarCustomer {
    customerId: number;
    numberOfActiveDatasets: number;
    companyName: string;
}