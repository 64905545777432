﻿import { ProspectieDataSelectionCriteriumTypeCategory } from './ProspectieDataSelectionCriteriumTypeCategory';

export enum ProspectieDataSelectionCriteriumType { 
    POSTCODE = "postcode",
    PROVINCIE = "provincie",
    GEWEST = "gewest",
    ACTIVITEIT = "activiteit",
    JURIDISCHEVORM = "juridische-vorm",
    PERSONEEL = "personeel",
    OPRICHTINGSDATUM = "oprichtingsdatum",
    BEROEP = "beroep",
    RISICOPROFIEL = "risicoprofiel",
    BRUTOMARGE = "brutomarge",
    OMZET = "omzet",
    BALANSTOTAAL = "balanstotaal",
    EIGENVERMOGEN = "eigen-vermogen",
    NIS = "nis",
    TAALVEREISTE = "taal-vereiste",
    METTELEFOON = "met-telefoon",
    WINSTVERLIES = "winst-verlies",
    EXCLUSION = "exclusion",
}
