﻿export enum Color {
    GREEN = '#7de18c',
    BLUE = '#73a6d7',
    INDIGO = '#6c2f86',
    ORANGE = '#ff8b62',
    LIGHTGREY = '#cdcdcd',
    VIOLET = '#e39cd5',
    YELLOW = '#fbd870'
}

export type YearData = Map<string, string>