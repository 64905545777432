
<div>
    <h2 class="mb-4"><b>{{translate("sidebar_financial")}}</b></h2>

    <div class="row mt-4">
        <div class="col-md-12">
            <h3>{{translate(ProspectieDataSelectionCriteriumType.BRUTOMARGE)}}</h3>
            <form class="row" @submit.prevent="addRange(ProspectieDataSelectionCriteriumType.BRUTOMARGE)">
                <div class="col-5 col-md-4">
                    <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.BRUTOMARGE].lowerbound"/>
                </div>
                <div class="col-2 col-md-1 flex-h-center my-auto font-weight-bold">
                    {{translate("tot_en_met")}}
                </div>
                <div class="col-5 col-md-4">
                    <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.BRUTOMARGE].upperbound"/>
                </div>
                <div class="col-md-3 mt-3 mt-md-0">
                    <button class="btn btn-default btn-block" type="submit">{{translate("btn_add")}}</button>
                </div>
            </form>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <h3>{{translate(ProspectieDataSelectionCriteriumType.OMZET)}}</h3>
            <form class="row" @submit.prevent="addRange(ProspectieDataSelectionCriteriumType.OMZET)">
                <div class="col-5 col-md-4">
                    <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.OMZET].lowerbound"/>
                </div>
                <div class="col-2 col-md-1 flex-h-center my-auto font-weight-bold">
                    {{translate("tot_en_met")}}
                </div>
                <div class="col-5 col-md-4">
                    <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.OMZET].upperbound"/>
                </div>
                <div class="col-md-3 mt-3 mt-md-0">
                    <button class="btn btn-default btn-block" type="submit">{{translate("btn_add")}}</button>
                </div>
            </form> 
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <h3>{{translate(ProspectieDataSelectionCriteriumType.WINSTVERLIES)}}</h3>
            <form class="row" @submit.prevent="addRange(ProspectieDataSelectionCriteriumType.WINSTVERLIES)">
                <div class="col-5 col-md-4">
                    <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.WINSTVERLIES].lowerbound"/>
                </div>
                <div class="col-2 col-md-1 flex-h-center my-auto font-weight-bold">
                    {{translate("tot_en_met")}}
                </div>
                <div class="col-5 col-md-4">
                    <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.WINSTVERLIES].upperbound"/>
                </div>
                <div class="col-md-3 mt-3 mt-md-0">
                    <button class="btn btn-default btn-block" type="submit">{{translate("btn_add")}}</button>
                </div>
            </form> 
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <h3>{{translate(ProspectieDataSelectionCriteriumType.BALANSTOTAAL)}}</h3>
            <form class="row" @submit.prevent="addRange(ProspectieDataSelectionCriteriumType.BALANSTOTAAL)">
                <div class="col-5 col-md-4">
                    <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.BALANSTOTAAL].lowerbound"/>
                </div>
                <div class="col-2 col-md-1 flex-h-center my-auto font-weight-bold">
                    {{translate("tot_en_met")}}
                </div>
                <div class="col-5 col-md-4">
                    <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.BALANSTOTAAL].upperbound"/>
                </div>
                <div class="col-md-3 mt-3 mt-md-0">
                    <button class="btn btn-default btn-block" type="submit">{{translate("btn_add")}}</button>
                </div>
            </form>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-12">
            <h3>{{translate(ProspectieDataSelectionCriteriumType.EIGENVERMOGEN)}}</h3>
            <form class="row" @submit.prevent="addRange(ProspectieDataSelectionCriteriumType.EIGENVERMOGEN)">
                <div class="col-5 col-md-4">
                    <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.EIGENVERMOGEN].lowerbound"/>
                </div>
                <div class="col-2 col-md-1 flex-h-center my-auto font-weight-bold">
                    {{translate("tot_en_met")}}
                </div>
                <div class="col-5 col-md-4">
                    <input type="number" class="form-control" v-model.number="values[ProspectieDataSelectionCriteriumType.EIGENVERMOGEN].upperbound"/>
                </div>
                <div class="col-md-3 mt-3 mt-md-0">
                    <button class="btn btn-default btn-block" type="submit">{{translate("btn_add")}}</button>
                </div>
            </form>
        </div>
    </div>
    
    <div class="tip mt-4 py-2">
        <translation label="financial_tip">
            <a href="tel:022552625" class="link" >02/255.26.25</a>
            <a href="/contact/message" class="link">{{translate("contact_form").toLowerCase()}}</a>
        </translation>
    </div> 
</div>
