
<div id="cookies" class="cookie-consent-modal" role="dialog">
    <div class="row">
        <div class="col-md-12">
            <h2 data-nosnippet>{{translate('cookies_modal_title')}}</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <p data-nosnippet v-html="cookiesModalDesc"></p>
            <cookie-consent-list></cookie-consent-list>
        </div>
    </div>
</div>
