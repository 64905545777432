﻿<template>
    <canvas :id="id" width="180" height="115" ref="canvas"></canvas>
</template>

<script lang="ts">
    import Vue from 'vue';
    import { ChartType, createChart, CWChartData } from '../../behaviors/Chart';


    export default Vue.extend({
        name: 'Vue-pie-chart',
        props: {
            id: {
                type: String,
                default: 'pieChart'
            },
            labels: Array as () => string[],
            colors: Array as () => string[],
            numbers: Array as () => Array<number>,
            hideLabels: Boolean
        },
        watch: {
            numbers(newNumbers: number[]): void {
                this.makeChart(newNumbers);
            }
        },
        methods: {
            makeChart(percentages?: number[]): void {
                const canvas = this.$refs.canvas as HTMLCanvasElement;

                const chartData: CWChartData = {
                    colors: this.colors,
                    labels: this.labels,
                    bckColors: this.colors,
                    percentages: percentages ?? this.numbers
                };

                createChart(canvas, ChartType.PIE, chartData, false, this.hideLabels);
            }
        },
        mounted(): void {
           this.makeChart();
        }
    });
</script>
