import Vue from 'vue';
import Page from '../lib/Page';
import CwModal from './shared/modal.vue';
import Ajax from '../lib/Ajax';
import Toast from '../lib/Toast';
import T from '../lib/Translate';
import _, {ThrottleSettings} from 'lodash';

export default (function () {
    Page.registerVue('#side-nav', function (rootSelector) {
        new Vue({
            el: rootSelector,
            data() {
                return {
                    vat: window.cw.vat,
                    lists: window.cw.lists,
                    listId: 0,
                    clicked: false
                };
            },
            components:{CwModal},
            methods: {
                onSubmit() {
                    if(this.clicked)
                        return;

                    this.clicked = true;
                    Ajax.post(
                        '/ajax/followup/add-companies',
                        {
                            listId: this.listId,
                            vats: [this.vat]
                        },
                        () => {
                            this.$root.$emit('hide-modal', 'add-to-alerts');
                            Toast.success(T('added_to_alerts'));
                        },
                        err => {
                            Toast.error(err);
                        }
                    );
                },
                openMapsModal() {
                    this.$root.$emit('show-modal', 'maps');
                    this.$nextTick(() => {
                        Page.applyBehaviors(rootSelector);
                    });
                },

                openAlertsModal() {
                    this.$root.$emit('show-modal', 'add-to-alerts');
                }
            },
            mounted() {
                if (this.lists && this.lists.length > 0)
                    this.listId = this.lists[0].id;
            }
        });
    });
}());
