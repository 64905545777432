﻿<template>
    <div class="row">
        <div class="col-md-4">
            <img src="/images/aanbod/alerts.png" width="350" />
        </div>
        <div class="col-md-8 my-auto">
            <p>
                De Alert Service van Companyweb laat u toe om specifieke bedrijven op te volgen. 
                U kan een lijst aanmaken van die bedrijven om automatisch op de hoogte te worden gebracht van elke belangrijke wijziging.<br /> 
                Hierdoor hoeft u zelf niet continu een bedrijf te controleren op mogelijke negatieve signalen.
            </p>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'feature-alerts'
    }
</script>