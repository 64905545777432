﻿import Vue from 'vue';
import Page from '../lib/Page';
import FileInput from './shared/file-input.vue';

export default (function () {
    Page.registerVue('#file-input-wrapper', (rootSelector) => {
        new Vue({
            el: rootSelector,
            components: { FileInput }
        });
    });
}());
