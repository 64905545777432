
<div>
    <h2 class="mb-4"><b>{{translate("sidebar_exclude")}}</b></h2>
    <div class="row">
        <div class="col-md-12">
            <h3>{{translate("vats")}}</h3>
            <p>
                {{translate("exclude_desc")}}
            </p>

        </div>
        <div class="col-md-6 text-center">
            <template v-if="isLoading"><i class="fas fa-spinner spinning text-blue"></i></template>
            <btw-file-input v-else :required="false" :ask-for-references="false" :max-number-of-vats="100000" @input="onInput"></btw-file-input>
        </div>
        <div class="col-md-6 mt-4 mt-md-0">
            <div class="form-group">
                <label for="selecties">{{translate("former_requests")}}</label>
                <checkbox-dropdown v-model="selectedFormerRequests"></checkbox-dropdown>
            </div>
        </div>
    </div>

     <div class="row mt-5">
        <div class="col-md-6">
            <h3>{{translate("phone")}}</h3>
            <div class="form-group">
                <input type="checkbox" id="phone-only" class="form-check-input" @change="setOnlyTel" v-model="form.onlyTel">
                <label for="phone-only">{{translate("only_phone")}}</label>
            </div>
        </div>
     </div>
</div>
