<template>
    <div>
        <h2><b>{{translate('upload_vats_title')}}</b></h2>
        <p class="mb-4">
            {{translate('upload_vats_desc')}} 
        </p>

        <div class="tip warning py-2">
            {{translate('max_lines_message')}} <a href="/contact/message" class="link">{{translate('contact_us')}}</a>
        </div>

        <div class="row mt-5">
            <div v-if="isLoading" class="col-md-12 text-center">
                <i class="fas fa-spinner spinning text-lightblue fa-lg my-4"></i>
            </div>
            <div v-else class="col-md-8">
                <btw-file-input required ask-for-references @input="onUpload"></btw-file-input>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Translate from '../../../mixins/translate';
    import DataMarketingService, {Request} from '../../dataMarketingService';
    import mixins from 'vue-typed-mixins';    
    import Progress from '../../progress';
    import BtwFileInput from '../../../shared/btw-file-input.vue';
    import Option from '../../../../types/global/option';
    import Toast from '../../../../lib/Toast';
    import Translation from '../../../shared/translation.vue';

    export default mixins(Translate).extend({
        components: {Translation, BtwFileInput },
        name: 'dm-upload-vats',
        props: {
            progress: Object as () => Progress,
        },
        data() {
            return {
                DataMarketingService,
                isLoading: false
            }
        },
        methods: {
            async onUpload(emitResult: VatInputEmit) {
                this.isLoading = true;

                try {
                    const response = await DataMarketingService.uploadVats(emitResult);
                    const request = await Request.getInstance(response)
                    request.vatInputEmit = Option.some<VatInputEmit>(emitResult);
                    this.$emit('createRequest', request);
                    this.progress.unlockOtherSteps();
                } catch(e: unknown) {
                    Toast.error(e as  string);
                }

                this.isLoading = false;
            },
        }
    })
</script>
