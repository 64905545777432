﻿<template>
    <div class="cw-dropdown" id="cw-dropdown">
        <div class="cw-dropdown__label" @click="toggleShowList()">{{label}}</div>
        <ul class="cw-dropdown__list" :class="{'active': showList}">
            <li v-for="record in value" :key="record.id">
                <input class="form-check-input azure" :id="record.id" type="checkbox" v-model="record.isChecked" @change="emitData()"/> 
                <label :for="record.id"><span>{{record.label}}</span></label>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
    import Translate from '../mixins/translate';
    import mixins from 'vue-typed-mixins'; 

    export interface CheckboxRow {
        id: number | string,
        label: string,
        isChecked: boolean
    }

    export default mixins(Translate).extend({
        name: 'checkbox-dropdown',
        props: {
            value: Array as () => CheckboxRow[]
        },
        data() {
            return {
                showList: false
            }
        },
        computed: {
            label(): string {
                const selected = this.value.filter(v => v.isChecked);

                if (selected.length === 0)
                    return this.translate('select');
                else if (selected.length === 1)
                    return selected[0].label;
                else
                    return selected.length + " " + this.translate('selections').toLowerCase();
            }
        },
        methods: {
            toggleShowList(): void {
                this.showList = !this.showList;
            },
            emitData(): void {
                this.$emit('input', this.value);
            },
        },
        mounted(): void {
            function isDescendant(parent: HTMLElement, child: HTMLElement) {
                var node = child.parentNode;
                while (node != null) {
                    if (node == parent) {
                        return true;
                    }
                    node = node.parentNode;
                }
                return false;
            }

            const containerEl = document.getElementById('cw-dropdown') as HTMLElement;

            const outsideClickListener = (event: Event) => {
                if (!isDescendant(containerEl, event.target as HTMLElement))
                    this.showList = false;
            }

            document.addEventListener('click', outsideClickListener)
        }
    });
</script>
<style lang="scss" scoped>
  .cw-dropdown .cw-dropdown__list li {
    padding: 0;
  }
  .cw-dropdown .cw-dropdown__list li label {
    display: block;
    padding: 8px 35px;
  }

  .form-check-input + label:before, .form-check-input + label:after {
    margin: 9px;
  }
</style>