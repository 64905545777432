﻿import Page from '../lib/Page';
import {elementInViewport} from './OnScrollAnimations';

export default (function () {
    Page.registerBehavior('.streetview img', function (image: Element) {
        if(isVisible(image as HTMLElement))
            initializeStreetview(image);
        
        function initializeStreetview (image: Element): void {
            const src = image.getAttribute('data-src') ?? "";
    
            image.setAttribute('src', src);
        }

        function isVisible(el: Element) {
            return !isInModalTemplate(el) && elementInViewport(el as HTMLElement);
        }

        function isInModalTemplate(el: Element) {
            for (; el !== null; el = el.parentElement as Element) {
                if (el.nodeName.toLowerCase() === 'cw-modal')
                    return true;
            }
            return false;
        }
    });
}());