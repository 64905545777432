﻿<template>
    <div class="cw-dropdown" :class="{'disabled': isPrivate}" id="cw-dropdown">
        <div class="cw-dropdown__label" @click="toggleShowList()">{{label}}</div>
        <ul class="cw-dropdown__list" :class="{'active': showList}">
            <li v-for="user in value" v-bind:class="{'disabled':isDisabled(user.UserId)}" :key="user.UserId">
                <input class="form-check-input" :id="user.UserId" type="checkbox" v-model="user.IsFollower" @change="emitUsers()" v-bind:disabled="isDisabled(user.UserId)"/> 
                <label :for="user.UserId">{{user.Firstname}} {{user.Lastname}} &lt;{{user.Email}}&gt;</label>
            </li>
            <li v-if="isAdmin && !isPrivate" class="li-with-anchor">
                <a href="/account/users" class="text-grey font-small" target="_blank"><i class="fas fa-user-plus text-grey font-small ml-2 mr-1"></i> {{translate('add_user')}}</a>
            </li>
        </ul>
    </div>
</template>

<script>
    import _ from 'lodash';
    import Translate from 'Mixins/translate';
    import Vue from 'vue';

    export default Vue.extend({
        name: 'users-dropdown',
        props: {
            value: Array,
            isPrivate: Boolean
        },
        mixins: [Translate],
        data() {
            return {
                showList: false
            }
        },
        computed: {
            followers() {
                return _.filter(this.value, user => user.IsFollower);
            },
            label() {
                if (this.followers.length === 0)
                    return this.translate('select_users');
                else if (this.followers.length === 1)
                    return this.followers[0].Firstname + " " + this.followers[0].Lastname;
                else
                    return this.followers.length + " " + this.translate('users').toLowerCase();
            },
            isAdmin() {
                return this.$root.isAdmin;
            }
        },
        methods: {
            toggleShowList() {
                if(!this.isPrivate)
                    this.showList = !this.showList;
            },
            emitUsers() {
                this.$emit('input', this.value);
            },
            isDisabled(userId) {
                return this.$parent.form.isPrivate && parseInt(userId) !== this.$root.userId;
            }
        },
        mounted() {
            function isDescendant(parent, child) {
                var node = child.parentNode;
                while (node != null) {
                    if (node == parent) {
                        return true;
                    }
                    node = node.parentNode;
                }
                return false;
            }

            const containerEl = document.getElementById('cw-dropdown');

            const outsideClickListener = (event) => {
                if (!isDescendant(containerEl, event.target))
                    this.showList = false;
            }

            document.addEventListener('click', outsideClickListener)
        },
        destroy() {
            document.removeEventListener('click', outsideClickListener)
        }
    });
</script>