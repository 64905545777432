
<div class="row">
    <div class="col-md-12">
        <input type="hidden" name="Package" :value="selectedPackage.name.toKebabCase()" />

        <select class="mobile-show form-control" v-model="selectedPackage">
            <option v-for="pack in packages" :key="pack.name" :value="pack">{{pack.name}}</option>
        </select>

        <div class="mobile-show text-center my-3 large">
            <strong>
                € {{mobilePrice}}<small>&nbsp; / {{translate("jaar").toLowerCase()}}</small>
            </strong>
        </div>

        <div class="pricing-table animated fadeIn" v-bind:class="selectedPackage">
            <div class="pricing-table-row header-row">
                <div class="pricing-cell"></div>

                <div v-for="column in columns" :data-text="translate('most_chosen')" :key="column.name" class="pricing-cell" :class="{'d-none d-sm-inline-flex': selectedPackage !== column.selectedPackage, 'selected': selectedPackage === column.selectedPackage}">
                    <div class="full-width text-center">
                        <img :src="'/images/illustrations/'+column.name+'.svg'" :alt="column.name" />

                        <div v-if="column !== PricingColumn.One" class="full-width text-center">{{column.name}}</div>
                        <select v-else v-model="selectedThematicPackage" class="form-control">
                            <option v-for="pack in column.selectablePackages" :key="pack.name" :value="pack">{{pack.name}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <pricing-row v-for="(row, index) in filteredRows" :selectedPackage="selectedPackage" :rowData="row" :key="index"></pricing-row>

            <div class="pricing-table-row footer-row">
                <div class="pricing-cell"></div>
                <div v-for="column in columns" :key="'column_' + column.name" class="pricing-cell" :class="{'d-none d-sm-inline-flex': selectedPackage !== column.selectedPackage, 'selected': selectedPackage === column.selectedPackage}">
                    <small class="mr-2"> {{translate('vanaf')}}</small>
                    € {{column.selectedPackage.totalPrice}} <small>&nbsp; / {{translate("jaar").toLowerCase()}}</small>
                    <div class="full-width text-center m-t-10">
                        <button class="btn btn-block btn-info" v-on:click.prevent="choose(column.selectedPackage)" v-if="selectedPackage !== column.selectedPackage">
                            {{translate('choose_pack')}}
                        </button>
                        <div class="small" v-else>
                            <i class="fas fa-check-circle"></i> {{translate('selected')}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
