
<div class="container">
    <div class="row">
        <div class="col-12">
            <h1>{{translate('search_results')}}</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <international-search :countries="countries"></international-search>
        </div>
    </div>
     <div class="row mt-5">
        <div class="col-12">
            <table class="table data-table table-striped" v-if="searchResults.length">
                <thead>
                    <tr>
                        <th>{{translate('company_name')}}</th>
                        <th>{{translate('registration_number')}}</th>
                        <th>{{translate('address')}}</th>
                        <th>{{translate('location')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="result in searchResults" :key="result.companyId">
                        <td>
                            <a :href="result.link" class="tr-anchor"><b>{{result.naam}}</b></a>
                        </td>
                        <td>{{result.registratieNr}}</td>
                        <td>{{result.adres.lijn1}}</td>
                        <td>{{result.adres.lijn2}}</td>
                    </tr>
                </tbody>
            </table>

            <p v-else>
                {{translate('no_results')}}
            </p>
        </div>
    </div>
</div>
