
<div>
    <h2 class="mb-4"><b>{{translate("sidebar_barometer")}}</b></h2>

    <div class="row mb-4">
        <div class="col-md-8 my-auto">
            <p>
                {{translate("barometer_description")}}
            </p>
        </div> 
        <div class="col-md-4 text-center">
            <img-barometer :is-demo="false" class-name="pos-26" :score="2.6" no-animate></img-barometer>
        </div>
    </div>

    <div class="px-5 px-lg-0">
         <div class="mb-3">
            <input type="radio" id="all" :checked="selectedValue === null" @change="selectNewRange(false)"> 
            <label for="all">{{translate("barometer_all_companies")}}</label>
        </div>

        <div class="mb-2">
            <input type="radio" id="nl-only" :checked="selectedValue !== null" @change="selectNewRange(true)"> 
            <label for="nl-only">{{translate("barometer_only_with_score")}}</label>
        </div>
    
        <range-slider 
            :disabled="selectedValue === null"
            is-linear
            :min="min" 
            :max="max" 
            :formatLabel="formatLabel"
            :lowerBound="lowerBound" 
            :upperBound="upperBound" 
            @update:min="updateMin"
            @update:max="updateMax"></range-slider> 
    </div>
</div>
