﻿<template>
    <td>
        <input v-if="type === 'string'" v-model="newStringValue" class="form-control form-control-sm" type="text"/>
        <input v-else-if="type === 'boolean'" v-model="newBoolValue" type="checkbox"/>
    </td>
</template>

<script lang="ts">
    import Vue from 'vue';
    import {CustomField, CustomValue, RadarCompany} from '../types';
    import _ from 'lodash';
    import Ajax from '../../../lib/Ajax';
    import {useStore} from "../store";

    export default Vue.extend({
        name: 'custom-td',
        props: {
            company: {
                type: Object as () => RadarCompany,
                required: true
            },
            field: {
                type: Object as () => CustomField,
                required: true
            }
        },
        data() {
            return {
                newStringValue: this.company.customValues.find(c => c.fieldId === this.field.id)?.stringValue ?? '',
                newBoolValue: this.company.customValues.find(c => c.fieldId === this.field.id)?.boolValue ?? false,
                store:  useStore()
            };
        },
        watch: {
            newStringValue(val: string) {
                this.ajaxDebouncePersist('string', this.company.vat, this.field.id, val, null);
            },
            newBoolValue(val: boolean) {
                this.ajaxDebouncePersist('boolean', this.company.vat, this.field.id, null, val);
            }
        },
        computed: {
            type(): 'string' | 'boolean' {
                return this.field.type;
            }
        },
        methods: {
            ajaxDebouncePersist(type: 'string' | 'boolean', vat: number, fieldId: number, stringValue: string | null, boolValue: boolean | null) {
                _.debounce(() => this.ajaxPeristInput(type,vat, fieldId, stringValue, boolValue), 350)();
            },
            async ajaxPeristInput(type: 'string' | 'boolean', vat: number, fieldId: number, stringValue: string | null, boolValue: boolean | null) {
                await Ajax.postAsync('/ajax/radar/edit-field', {
                    type,
                    vat,
                    fieldId,
                    stringValue,
                    boolValue
                });
                
                const company = this.store.companies.find(c => c.vat === vat)!;
                const field = company.customValues.find(cv => cv.fieldId === fieldId)!;

                if (!field) {
                    company.customValues.push({
                        fieldType: type,
                        stringValue: stringValue,
                        boolValue: boolValue,
                        fieldId: fieldId,
                        vat: vat
                    } as CustomValue);
                } else {
                    field.fieldType = type;
                    field.stringValue = stringValue;
                    field.boolValue = boolValue;
                }
                
                this.$root.$emit('edit-custom-value', {
                    vat: vat,
                    field: {
                        fieldType: type,
                        fieldId,
                        stringValue,
                        boolValue
                    } as CustomValue
                });
            }
        }
    });
</script>

<style lang="scss" scoped>

</style>