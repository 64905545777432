﻿<template>
    <div class="radar-loader-wrapper">
        <div class="text-center" style="margin-top: -60px;font-weight: bold;font-size: 1.2em;">{{store.currentDataset.title}} </div>
        <div class="loading-bar-wrapper">
            <div class="loading-bar" :style="'width:' + store.loadingPercentage + '%'"></div>
            <span class="loading-percentage">{{store.loadingPercentage}}%</span>
        </div>
        <div class="radar-loader">
            <div class="scanner"></div>
            <div class="dot dot-1"></div>
            <div class="dot dot-2"></div>
            <div class="dot dot-3"></div>
            <div class="dot dot-4"></div>
        </div>
    </div>
</template>

<script lang="ts">
  import Translate from "../../mixins/translate";
  import {useStore} from '../store';
  export default Translate.extend({
      name: "loading-radar",
      data() {
          return {
              store: useStore()
          }
      }
  });
</script>

<style lang="scss" scoped>
    .radar-loader-wrapper{
        position: absolute;
        left: 50vw;
        top: 42vh;
        transform: translate(-50%, -50%);

        .loading-bar-wrapper {
            position: relative;
            border: 1px solid #cccccc;
            margin: 10px auto;
            border-radius: 4px;
            overflow: hidden;
            width: 200px;
            height: 8px;

            .loading-bar {
                background-color: #0368FF;
                height: 100%;
                transition: width 1.5s;
            }
        }
    .radar-loader {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        box-shadow: 0 0 31px -9px #54545447;
        position: relative;
        margin: 15px auto;

        .scanner {
            width: 90%;
            height: 90%;
            border-radius: 50%;
            position: absolute;
            top: 5%;
            left: 5%;
            background: conic-gradient(#6b85cf55, #0368FF);
            animation: scan 2s infinite linear;
            overflow: hidden;

            &:after, &:before {
                content: "";
                width: 10%;
                height: 10%;
                border-radius: 50%;
                position: absolute;
                border: 0.1px solid rgba(238, 238, 238, 0.31);
                animation: ripple 2s infinite linear;
                left: 45%;
                top: 45%;
            }
        }

        .dot {
            opacity: 0;
            border: 2px solid #ecfafd;
            border-radius: 100%;
            position: absolute;
            animation: blink 2s ease-out;
            animation-iteration-count: infinite;
            z-index: 25;

            &.dot-1 {
                left: 53%;
                top: 22%;
                animation-delay: 0.1s;
            }

            &.dot-2 {
                left: 61%;
                top: 46%;
                animation-delay: 0.45s;
            }

            &.dot-3 {
                left: 58%;
                top: 49%;
                animation-delay: 0.5s;
            }

            &.dot-4 {
                left: 35%;
                top: 75%;
                animation-delay: 1.22s;
            }
        }
    }


    @keyframes scan {
        to {
            transform: rotate(1turn);
        }
    }

    @keyframes ripple {
        to {
            transform: scale(35);
        }
    }

    @keyframes blink {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    }

</style>