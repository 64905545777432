interface Number {
    formatThousandSeparator(): string;
    formatVat(): string;
}

Number.prototype.formatThousandSeparator = function (): string {
    return this
        .toString()
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

Number.prototype.formatVat = function (): string {
    return this
        .toString()
        .padStart(10, "0")
        .replace(/^(\d{4})(\d{3})(\d{3})$/, 'BE $1.$2.$3');
}