export default (function() {
    /*
    * Takes fixed header height into account when scrolling towards anchors.
    */
    (function(document, history, location) {
        var HISTORY_SUPPORT = !!(history && history.pushState);  
        var anchorScrolls = {
          ANCHOR_REGEX: /^#[^ ]+$/,
          OFFSET_HEIGHT_PX: 80, 
      
     
          init: function() {
            this.scrollToCurrent();
            window.addEventListener('hashchange', this.scrollToCurrent.bind(this));
            document.body.addEventListener('click', this.delegateAnchors.bind(this));
          },
      
          getFixedOffset: function() {
            return this.OFFSET_HEIGHT_PX;
          },
      
          scrollIfAnchor: function(href: string | null, pushToHistory?: boolean) {
            var match, rect, anchorOffset;
            href = href ?? ""
            if(!this.ANCHOR_REGEX.test(href)) {
              return false;
            }
      
            match = document.getElementById(href.slice(1));
      
            if(match) {
              rect = match.getBoundingClientRect();
              anchorOffset = window.pageYOffset + rect.top - this.getFixedOffset();
              window.scrollTo(window.pageXOffset, anchorOffset);
      
              if(HISTORY_SUPPORT && pushToHistory) {
                history.pushState({}, document.title, location.pathname + href);
              }
            }
      
            return !!match;
          },
      
          scrollToCurrent: function() {
            this.scrollIfAnchor(window.location.hash);
          },
      
          delegateAnchors: function(e: Event) {
            var elem = e.target as HTMLAnchorElement;
      
            if(
              elem.nodeName === 'A' &&
              this.scrollIfAnchor(elem.getAttribute('href'), true)
            ) {
              e.preventDefault();
            }
          }
        };
      
        window.addEventListener(
          'DOMContentLoaded', anchorScrolls.init.bind(anchorScrolls)
        );
      })(window.document, window.history, window.location);
})()