<template>
    <div>
        <div v-if="isLoading" class="my-5 py-5 text-center">
            <i class="fas fa-2x fa-spinner text-lightblue spinning"></i>
        </div>
        <div v-else class="dm-wrapper"  :class="{'wide': waitingOrFinished}">
            <sidebar :progress="progress" :title="translate('enrichment_sidebar_title')" v-if="!waitingOrFinished" :class="{'d-none d-lg-block': !mobileOpen, 'd-block': mobileOpen}"></sidebar>
            <main>
                <upload-vats v-if="activeStep === Step.UploadVats" :progress="progress" @createRequest="createRequest"></upload-vats>
                <results v-else :progress="progress" :currentRequest="currentRequest"></results>     
            </main>
            <result-counter v-if="currentRequest" :progress="progress" :boxTitle="translate('uw_lijst')" class="d-none d-md-block"></result-counter>
        </div>
    </div>
</template>

<script lang="ts">
    import SidebarComponent from '../components/shared/sidebar.vue';
    import ResultCounter from '../components/shared/result-counter.vue';
    import Progress, { Step } from '../progress';
    import { PrepaidDataMarketingRequest } from '../../../types/dto/PrepaidDataMarketing';
    import DataMarketingService, {Request} from '../dataMarketingService';
    import UploadVats from '../components/enrichment/upload-vats.vue';
    import Results from '../components/shared/results.vue';
    import { RequestStatus } from '../types';
    import Translate from '../../mixins/translate';
    import mixins from 'vue-typed-mixins';

    export default mixins(Translate).extend({
        components: {
            'sidebar': SidebarComponent,
            ResultCounter,
            UploadVats,
            Results
        },
        data(){
            return {
                Step,
                progress: Progress.EnrichmentProgress,
                request: window.cw.request as null | PrepaidDataMarketingRequest,
                dataMarketingService: null as null | DataMarketingService ,
                currentRequest: null as null | Request,
                RequestStatus,
                mobileOpen: false,
                isLoading: false
            }
        },
        computed:{
            activeStep(): Step {
                return this.progress.activeStep;
            },
            waitingOrFinished(): boolean {
                return this.currentRequest ? (this.currentRequest?.status !== RequestStatus.STARTED) : false;
            },
            isFinished(): boolean {
                return this.currentRequest?.status === RequestStatus.FINISHED
            }
        },
        methods: {
            async createRequest(req: PrepaidDataMarketingRequest) {
                const {currentRequest} = await DataMarketingService.create(req);
                this.currentRequest = currentRequest;
                const step = this.progress.activateNextStep();
                
                if(step) {
                    window.history.pushState(
                        {
                            subPage: step.label
                        },
                        '',
                        window.location.href.replace(/(\/datamarketing\/enrichment)(\/.+)?/, '$1/' + currentRequest.id + '/' + step.label)
                    );
                }
            },
            listenForMobileMenu(): void {
                this.$root.$on('mobile-menu', () => {
                    this.mobileOpen = !this.mobileOpen
                });

                this.$root.$on('mobile-menu-close', () => {
                    this.mobileOpen = false
                });
            }
        },
        async created() {
            this.listenForMobileMenu();
            
            if(this.request){
                this.isLoading = true;
                const {currentRequest} =  await DataMarketingService.create(this.request);
                this.currentRequest = currentRequest;
                const step = this.progress.activateNextStep();
                this.isLoading = false;
                if(step) {
                    window.history.replaceState(
                        {
                            subPage: step.label
                        },
                        '',
                        window.location.href.replace(/(\/datamarketing\/enrichment)(?:\/\d+)(\/.+)?/, '$1/' + currentRequest.id + '/' + step.label)
                    );
                }
            }
            else {
                this.progress.lockOtherSteps();
            }
        },
        mounted(): void {
            this.progress.findStepInUrl(window.location.href);
        }
    })
</script>

<style scoped lang="scss">
     @import "../../../../sass/_variables.scss";

     .dm-wrapper {
       display: grid;
       grid-template-columns: 4fr 9fr 5fr;
       align-items: start;
       column-gap: 25px;

       &.wide {
         grid-template-columns: 11fr 4fr;

         @media #{$responsive-992} {
           grid-template-columns: 1fr;
         }
       }

       @media #{$responsive-992} {
         grid-template-columns: 1fr;
       }

       main {
         min-height: 50vh;
         max-height: 74vh;
         overflow-y: auto;
         overflow-x: hidden;
       }

       aside {
         @media #{$responsive-992} {
           position: fixed;
           background: #fff;
           border-right: 0;
           width: 80%;
           left: 0;
           padding: 25px;
           z-index: 55;
           top: 9vh;
           overflow-y: scroll;
           height: 82vh;
           box-shadow: 2px 2px 5px #0000002e;
         }
       }
     }
</style>