<template>
    <div>
        <h2 class="mb-4"><b>{{translate("sidebar_activity")}}</b></h2>

        <div class="row">
            <div class="col-12">
                <ul class="nav nav-tabs border-bottom mb-4">
                    <li class="nav-item" >
                        <a href="" class="nav-link" :class="{'active': currentTab === Tab.NACEBEL}" @click.prevent="changeTab(Tab.NACEBEL)">{{translate(Tab.NACEBEL)}}</a>
                    </li>
                     <li class="nav-item">
                        <a href="" class="nav-link" :class="{'active': currentTab === Tab.BEROEPEN}" @click.prevent="changeTab(Tab.BEROEPEN)">{{translate(Tab.BEROEPEN)}}</a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row mb-3" v-if="currentTab === Tab.BEROEPEN">
            <div class="col-12">
                <p>{{translate('beroepenindex_desc')}}</p>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-md-12">
                <input type="search" v-model="filterTerm" class="form-control" :placeholder="translate('filter_input_placeholder')" />
            </div>
        </div>

        <div class="row">
             <div class="col-md-12" v-if="currentTab === Tab.NACEBEL">
                <select-tree 
                    :is-parent-selected="false"
                    :filterTerm="filterTerm" 
                    :criteria="treeData.activiteit" 
                    :type="ProspectieDataSelectionCriteriumType.ACTIVITEIT"></select-tree>
            </div>
            <div class="col-md-12" v-else>
                <select-tree 
                    :is-parent-selected="false"
                    :filterTerm="filterTerm" 
                    :criteria="treeData.beroep" 
                    :type="ProspectieDataSelectionCriteriumType.BEROEP"></select-tree>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import DataMarketingService from '../../dataMarketingService';
    import SelectTree from '../shared/select-tree.vue';
    import { ProspectieDataSelectionCriteriumType } from '../../../../types/dto/ProspectieDataSelectionCriteriumType';
    import Translate from '../../../mixins/translate';
    import mixins from 'vue-typed-mixins';  

    enum Tab {
        NACEBEL = "nacebel",
        BEROEPEN = "beroepenindex"
    }

    export default mixins(Translate).extend({
        name: 'dm-activity',
        components: {SelectTree},
        data() {
            return {
                ProspectieDataSelectionCriteriumType,
                treeData: DataMarketingService.getInstance().treeData!,
                currentTab: Tab.NACEBEL,
                Tab,
                filterTerm: ''
            }
        },
        methods: {
            changeTab(tab: Tab) {
                this.currentTab = tab;
            }
        }
    })
</script>

<style scoped>

</style>