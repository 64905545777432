export {}

import T from '../lib/Translate';

function translate(translateKey: string, ...args: any) {
    return T(translateKey, ...args);
}
declare global {
    interface Date {
        /**
        * Returns given date in a dd-MM-YYYY format
        */
        format(): string;

        /**
         * Returns given date in a dd-MM- HH:II format
         */
        formatDateTime(): string;

        /**
        * Returns a translatable String defining how long the given Date lies in the past.
        * Returns empty string if the Date lies in the future.
        */
        toTimeAgo(includeTime: boolean): string;

        /**
        * Transforms and returns given date to a 8-digit hansdate (YYYYMMdd) format.
        */
        toHansDate(): number;

        /**
         * Transforms and returns given date to a string (YYYY-MM-dd) format.
         */
        toYYYY_MM_DD(): string;

        /**
         * Transforms and returns given date to a string (MM-YYYY) format.
         */
        toMM_YYYY(): string;

        /**
        * Defines whether or not the given Date lies in the past.
        */
        isInThePast(): boolean;

        /**
        * Add number of days to the given Date and returns the new Date.
        */
        addDays(days: number): Date;

        /**
        * Add number of months to the given Date and returns the new Date.
        */
        addMonths(months: number): Date;

        /**
        * Add number of years to the given Date and returns the new Date.
        */
        addYears(years: number): Date;

        /**
         * Determines whether gives date is after the date passed as an argument.
         */
        isAfter(otherDate: Date): boolean;

        /**
         * Determines whether gives date is after the date passed as an argument only based on month and year.
         */
        isSameMonthOrMoreRecent(otherDate: Date): boolean;
    }
    interface DateConstructor {
        /**
        * Creates a new Date based on a 8-digit Hansdate (YYYYMMdd).
        */
        fromHansDate(hd: number): Date; 
        /**
         * Creates a new Date based on a 8-digit Hansdate (YYYYMMdd).
         */
        fromString(str: string): Date;
        
        fromISOString(isoString: string): Date;
    }
}

Date.fromHansDate = function(hd: number): Date {
    const parts = hd.toString().match(/^(\d{4})(\d{2})(\d{2})$/);
    
    if(parts) 
        return new Date(+parts[1], +parts[2] - 1, +parts[3]) 
    else
        throw new Error(`Could not create date from hansdate: ${hd}`);
};

Date.fromString = function(str: string): Date {
    const parts = str.toString().match(/^(\d{2})[-/](\d{2})[-/](\d{4})$/);
   
    if(parts)
        return new Date(+parts[3], +parts[2] - 1, +parts[1])
    else
        throw new Error(`Could not create date from string: ${str}`);
};

Date.fromISOString = function (isoString: string): Date {
    return new Date(isoString.trim_right('Z'));
}

Date.prototype.isInThePast = function(): boolean {
    return this.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
}

Date.prototype.format = function(): string {
    const day = this.getDate().toString().padStart(2, '0');
    const month = (this.getMonth() + 1).toString().padStart(2, '0');
    const year = this.getFullYear();
    
    return day + "-" + month + "-" + year;
}

Date.prototype.toHansDate = function(): number {
    const day = this.getDate().toString().padStart(2, '0');
    const month = (this.getMonth() + 1).toString().padStart(2, '0');
    const year = this.getFullYear();
    
    return +(""+ year  + month + day);
}

Date.prototype.toYYYY_MM_DD = function(): string {
    const day = this.getDate().toString().padStart(2, '0');
    const month = (this.getMonth() + 1).toString().padStart(2, '0');
    const year = this.getFullYear();

    return (year + '-' + month + '-' + day);
}

Date.prototype.toMM_YYYY = function(): string {
    const month = (this.getMonth() + 1).toString().padStart(2, '0');
    const year = this.getFullYear();

    return (month + '-' + year);
}

Date.prototype.formatDateTime = function(): string {
    const day = this.getDate().toString().padStart(2, '0');
    const month = (this.getMonth() + 1).toString().padStart(2, '0');
    const year = this.getFullYear();
    const hours = this.getHours();
    const minutes = this.getMinutes().toString().padStart(2, '0');

    return `${day}-${month}-${year} om ${hours}u${minutes}`;
}

Date.prototype.addDays = function(days: number) {
    this.setDate(this.getDate() + days);

    return this;
}

Date.prototype.addMonths = function(months: number) {
    this.setMonth(this.getMonth() + months);

    return this;
}

Date.prototype.addYears = function(years: number) {
    this.setFullYear(this.getFullYear() + years);

    return this;
}

Date.prototype.toTimeAgo = function(includeTime: boolean): string {
    const seconds = Math.floor((new Date().getTime() - this.getTime()) / 1000);

    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
        return translate("years_ago", interval);
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
        return translate("months_ago", interval);
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
        return translate("days_ago", interval);
    } else if (interval === 1) {
        return translate('yesterday');
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return !includeTime ? translate('today') : translate('hours_ago', interval);
    }
    interval = Math.floor(seconds / 60);
    if (interval < 0) {
        return ''; //future
    }
    if (interval > 1) {
        return !includeTime ? translate('today') : translate('minutes_ago', interval);
    }
    return !includeTime ? translate('today') : translate('seconds_ago', Math.floor(seconds));
}

Date.prototype.isAfter = function (otherDate: Date) {
    return this > otherDate
}

Date.prototype.isSameMonthOrMoreRecent = function (otherDate: Date) {
    return (this.getFullYear() > otherDate.getFullYear()) || (this.getFullYear() === otherDate.getFullYear() && this.getMonth() >= otherDate.getMonth());
}