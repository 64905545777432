﻿import Vue from 'vue';
import Ajax from '../lib/Ajax';
import Toast from '../lib/Toast';
import Translate from '../lib/Translate';
import Page from '../lib/Page';
import CwModal from "./shared/modal.vue";
import T from "../lib/Translate";

export default (function () {
    Page.registerVue('#person-detail', (rootSelector) => {
        new Vue({
            el: rootSelector,
            data: {
                isFavourite: window.cw.isFavourite,
                personId: window.cw.vatOrPersonId,
                type: window.cw.type,
                lists: window.cw.lists,
                listId: 0,
            },
            components:{CwModal},
            methods: {
                onAddToAlerts() {
                    Ajax.post(
                        '/ajax/followup/add-person',
                        {
                            listId: this.listId,
                            personId: this.personId
                        },
                        () => {
                            this.$root.$emit('hide-modal', 'add-to-alerts');
                            Toast.success(T('added_to_alerts'));
                        },
                        err => {
                            Toast.error(err);
                        }
                    );
                },
                openAlertsModal(){
                    this.$root.$emit("show-modal", "add-to-alerts");
                },
                addToOrRemoveFromFavourites(): void {
                    if (this.isFavourite)
                        this.removeFromFavourites();
                    else
                        this.addToFavourites();
                },
                addToFavourites(): void {
                    Ajax.post(
                        '/ajax/' + this.type + '/add-to-favourites',
                        this.type === 'company'
                            ? { vat: this.personId }
                            : { personId: this.personId }
                        ,
                        () => {
                            this.isFavourite = true;
                            Toast.success(Translate('added_to_favourites'));
                        },
                        (error) => {
                            console.error(error);
                        }
                    );
                },
                removeFromFavourites(): void {
                    Ajax.post(
                        '/ajax/' + this.type + '/remove-from-favourites',
                        this.type === 'company'
                            ? { vat: this.personId }
                            : { personId: this.personId }
                        ,
                        () => {
                            this.isFavourite = false;
                            Toast.success(Translate('removed_from_favourites'));
                        },
                        (error) => {
                            console.error(error);
                        }
                    );
                }
            },
            mounted() {
                if (this.lists && this.lists.length > 0)
                    this.listId = this.lists[0].id;
            }
        });
    });
}());
