
<div>
    <h2>{{translate('sidebar_language')}}</h2>

    <div class="mb-2">
        <input type="radio" id="all" @change="select(null)" :checked="selectedValue == null"> 
        <label for="all">{{translate('language_all')}}</label>
    </div>

    <div class="mb-2">
        <input type="radio" id="nl-only" @change="select(2)" :checked="selectedValue === 2"> 
        <label for="nl-only">{{translate('language_nl')}}</label>
    </div>

    <div class="mb-2">
        <input type="radio" id="fr-only" @change="select(1)" :checked="selectedValue === 1"> 
        <label for="fr-only">{{translate('language_fr')}}</label>
    </div>
    
    <div class="mb-2">
        <input type="radio" id="du-only" @change="select(3)" :checked="selectedValue === 3">
        <label for="du-only">{{translate('language_du')}}</label>
    </div>
</div>
