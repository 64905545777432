
<section>
    <div class="row">
        <div class="col-9">
            <h1>{{pageTitle}}</h1>
        </div>
        <div class="col-3 text-right">
            <router-link class="btn btn-blue" :to="{name: 'create'}" v-if="$route.name === 'list'">
                <i class="fas fa-plus mr-2"></i> Software toevoegen
            </router-link>
             <a class="link text-grey pointer" @click="$router.go(-1)" v-else>
                <i class="fas fa-angle-left mr-1"></i> Terug
            </a>
        </div>
    </div>

    <router-view></router-view>
</section>
