﻿<template>
    <div v-cloak>
        <input class="form-control search-input"
               :class="{'is-invalid': hasError, ' form-control-sm': small}"
               type="text"
               autocomplete="off"
               :placeholder="placeholder"
               :disabled="disabled"
               :name="name"
               :id="id"
               :ref="inputRef"
               v-bind:value="searchTerm"
               v-on:input="searchTerm= $event.target.value"
               v-on:keyup="makeSuggestions"
               v-on:keyup.down="selectNext"
               v-on:keyup.up="selectPrev"
               v-on:keypress.enter="acceptSelected"
               v-on:keydown.tab="acceptSelected" />
        <transition name="slide-fade">
            <div id="autocompleteWrapper" class="autocomplete-wrapper" v-if="showSuggestions">
                <suggestion v-for="(suggestion, index) in suggestions"
                            v-bind:name="suggestion"
                            v-bind:search-value="searchTerm"
                            v-bind:selected-index="selectedIndex"
                            v-bind:index="index"
                            :key="index">
                </suggestion>
            </div>
        </transition>
    </div>
</template>

<script>

    const KeyCodes = {
        Tab: 9,
        Enter: 13,
        Shift: 16,
        ArrowLeft: 37,
        ArrowUp: 38,
        ArrowRight: 39,
        ArrowDown: 40
    };

    import Suggestion from './autocomplete-suggestion.vue';
    import Ajax from 'Lib/Ajax';
    import _ from 'lodash';

    export default {
        name: 'autocomplete-input',
        components: {
            'suggestion': Suggestion
        },
        props: {
            id: String,
            fetchUrl: String,
            placeholder: String,
            name: String,
            value: String,
            inputProperty: String,
            extraParams: {},
            nextTabInput: String,
            inputRef: String,
            disabled: Boolean,
            small: Boolean
        },
        data() {
            return {
                suggestions: [],
                selectedIndex: 0,
                searchTerm: this.value,
                isReady: false,
                shouldHaveSuggestions: false
            };
        },
        watch: {
            value: function (val) {
                this.searchTerm = val;
            },
            searchTerm: function (val) {
                this.$parent[this.inputProperty] = val;
            }
        },
        computed: {
            hasSuggestions: function () {
                return this.suggestions.length > 0;
            },
            showSuggestions: function () {
                return this.hasSuggestions;
            },
            hasError() {
                return !this.hasSuggestions && this.shouldHaveSuggestions && this.searchTerm !== '';
            }
        },
        methods: {
            makeSuggestions: function (event) {
                var inputTooShort = this.searchTerm.length < 2;

                var noInputKeyPressed =
                    event.keyCode === KeyCodes.Tab ||
                    event.keyCode === KeyCodes.Shift ||
                    event.keyCode === KeyCodes.Enter ||
                    event.keyCode === KeyCodes.ArrowLeft ||
                    event.keyCode === KeyCodes.ArrowUp ||
                    event.keyCode === KeyCodes.ArrowRight ||
                    event.keyCode === KeyCodes.ArrowDown;

                if (noInputKeyPressed)
                    return;

                if (inputTooShort) {
                    this.clearSuggestions();
                    return;
                }

                if (this.fetchUrl !== '' && this.fetchUrl !== undefined)
                    this.ajaxFetchSuggestions();
            },
            clearSuggestions: function () {
                this.suggestions = [];
            },
            selectPrev: function () {
                this.selectedIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : 0;
            },
            selectNext: function () {
                var limit = this.suggestions.length - 1;
                this.selectedIndex = this.selectedIndex < limit ? this.selectedIndex + 1 : limit;
            },
            acceptSelected: function (event, clickedIndex) {
                if (event) {
                    var selection = "";
                    if (!this.isReady && (event.keyCode === KeyCodes.Tab || event.keyCode === KeyCodes.Enter)) {
                        return;
                    }

                    if ((event.keyCode === KeyCodes.Enter || event.keyCode === KeyCodes.Tab) && this.suggestions.length === 0)
                        return;

                    if (event.keyCode === KeyCodes.Enter)
                        event.preventDefault();
                }

                if (clickedIndex)
                    selection = this.suggestions[clickedIndex];
                else
                    selection = this.suggestions[this.selectedIndex];

                this.clearSuggestions();
                this.shouldHaveSuggestions = false;
                this.searchTerm = selection;

                this.$emit('select', selection);

                if (event && event.keyCode === KeyCodes.Tab && !event.shiftKey && this.nextTabInput !== '') {
                    event.preventDefault();
                    this.$root.$emit('next-input-focus', this.nextTabInput);
                }
            },
            ajaxFetchSuggestions: _.debounce(function () {
                this.isReady = false;
                let params = _.merge(this.extraParams, { searchTerm: this.searchTerm });
                this.shouldHaveSuggestions = false;

                Ajax.get(
                    this.fetchUrl,
                    params,
                    (response) => {
                        this.isReady = true;
                        this.suggestions = response.Data;
                        this.shouldHaveSuggestions = true;
                    },
                    (e) => {
                        this.isReady = true;
                        this.shouldHaveSuggestions = false;
                    }
                );
            }, 100)
        },
        mounted() {
            this.$on('acceptSuggestion', (clickedIndex) => {
                this.acceptSelected(null, clickedIndex);
            });
            document.addEventListener('click', () => {
                this.clearSuggestions();
            });
            this.$root.$on('next-input-focus', (inputRef) => {
                let nextInputElement = this.$refs[inputRef] || this.$parent.$refs[inputRef];
                
                this.$nextTick(() => {
                    if (nextInputElement)
                        nextInputElement.focus();
                });
            });
        }
    };
</script>