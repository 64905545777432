
<th class="vertical narrow" :class="{'open': isFilterBoxShown, 'filtered': hasFiltered}" @click.stop="toggle"
    :style="'min-width:' + (field.minWidth < 50 ? 50 : field.minWidth)  + 'px' ">
  <div class="filter-title-row">
      <div class="d-flex align-items-center" style="gap:8px">
      <div class="text">
        {{ field.name }}
      </div>
        <div class="d-flex flex-column sort-wrapper" >
            <a class="d-inline-block sort-btn" :class="{'active': isSorted && !isDescending}" href="#" @click.prevent.stop="setSort(false)" :title="translate('sort_asc')">
                <i class="fa-caret-up" :class="{'fas': isSorted && !isDescending, 'far': !isSorted || isDescending}"></i>
            </a>
            <a class="d-inline-block  sort-btn" :class="{'active': isSorted && isDescending}"  href="#" @click.prevent.stop="setSort(true)" :title="translate('sort_desc')">
                <i class="fa-caret-down" :class="{'fas': isSorted && isDescending, 'far': !isSorted || !isDescending}"></i>
            </a>
        </div>
    </div>
  </div>
  <transition name="dropdown">
    <div v-if="isFilterBoxShown" v-click-outside="close">
      <div></div>
      <div id="filter-box" class="filter-box" @click.stop>
        <div class="row mb-3">
          <div class="col-md-10">
            <em>{{ field.description }}</em>
          </div>
          <div class="col-md-2 text-right">
            <a class="btn btn-default btn-sm" href="" @click.stop.prevent="openEditColumnModal"><i
                class="fas fa-cog"></i> </a>
          </div>
        </div>

          <div class="row mb-2">
              <h3 class="col-md-6">
                  {{translate('filteren')}}
              </h3>
              <div class="col-md-6 text-right" v-if="hasFiltered">
                  <a href="" class="text-grey link" @click.prevent="removeCustomFilter(field)"><i class="fas fa-times"></i> {{translate('delete_filter')}}</a>
              </div>
          </div>
        <div>
          <div v-if="field.type === 'boolean'">
            <select v-model="store.customFilters[field.id]" class="form-control">
              <option :value="null">{{translate('checkbox_all')}}</option>
              <option :value="true">{{translate('checkbox_checked')}}</option>
              <option :value="false">{{translate('checkbox_unchecked')}}</option>
            </select>
          </div>
          <div v-else>
            <div class="d-flex align-items-center">
              <input v-model="store.customFilters[field.id]"
                     :placeholder="translate('filter_on') + ' ' + field.name" class="form-control" type="search">
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</th>
