
<div class="company-information">
    <div class="feature-tabs">
        <a 
            class="tab" 
            href="" 
            :class="{'active': activeTab === feature}" 
            v-for="feature in CompanyInformationFeature"
            :key="feature" 
            @click.prevent="activateTab(feature)">
            {{feature}}
        </a>
    </div>
    <div class="feature-panel">
        <div class="feature-content">
            <transition-group name="list" tag="div">
                <span v-for="item in CompanyInformationFeature" v-bind:key="item" class="list-item" v-show="activeTab === item">
                    <h2>{{item|capitalize}}</h2>
                    <p>
                        {{translate('desc_' + item)}}
                    </p>
                </span>
            </transition-group>               
        </div>
        <div class="feature-image">
            <img :src="'/images/possibilities/company-information/'+ screenshots[activeTab]" />
        </div>
    </div>
</div>
