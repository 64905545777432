﻿<template>
    <div>
        <div class="cookie-list">
            <cookie-type v-for="(type, idx) in cookieTypes" :key="idx" v-bind="type"></cookie-type>
        </div>
        <button type="button" class="btn btn-info btn-large btn-lg" id="cookieAgreeBtn" v-on:click.prevent="accept()">{{btnLabel}}</button>
        <div class="mt-3">
            <a class="body-color" href="" v-on:click.prevent="acceptMinimal()">{{linkLabel}}</a>
        </div>
    </div>
</template>

<script>
    import Translate from 'Mixins/translate';
    import CookieType from './cookie-type.vue';
    import Ajax from 'Lib/Ajax';
    import _ from 'lodash';

    export default {
        name: 'cookie-consent-list',
        mixins: [Translate],
        components: {
            'cookie-type': CookieType
        },
        data() {
            return {
                cookieTypes: window.cw.cookieTypes,
                linkLabel: this.translate('proceed_with_functional_cookies')//Ga door met enkel functionele cookies
            }
        },
        computed: {
            btnLabel() {
                return this.isAnyCookieChecked ? this.translate('save_choice') : this.translate('accept_all_cookies');
            },
            acceptedCookieTypes() {
                return _.filter(this.cookieTypes, (ct) => {
                    return ct.checked;
                })
            },
            acceptedCookieTypeNames() {
                return _.map(this.acceptedCookieTypes, 'name');
            },
            isAnyCookieChecked() {
                return this.acceptedCookieTypes.length > 1;
            },
            requiredCookieTypes() {
                return _.filter(this.cookieTypes, (ct) => {
                    return ct.required;
                })
            },
            requiredCookieTypeNames() {
                return _.map(this.requiredCookieTypes, 'name');
            },
            allCookieTypeNames() {
                return _.map(this.cookieTypes, 'name');
            }
        },
        methods: {
            accept() {
                if (this.isAnyCookieChecked)
                    this.ajaxAcceptCookies(this.acceptedCookieTypeNames);
                else
                    this.ajaxAcceptCookies(this.allCookieTypeNames);
            },
            acceptMinimal() {
                this.ajaxAcceptCookies(this.requiredCookieTypeNames);
            },
            ajaxAcceptCookies(acceptedCookieTypes) {
                Ajax.post(
                    '/ajax/accept-cookies',
                    {
                        acceptedCookieTypes: acceptedCookieTypes
                    },
                    () => {
                        this.$root.hasAcceptedFunctionalCookies = true;
                        this.$root.$emit('submitted-form');

                        gtag('consent', 'update', {
                            'ad_storage': acceptedCookieTypes.includes("marketing-cookies") ? "granted" : "denied",
                            'ad_user_data': acceptedCookieTypes.includes("marketing-cookies") ? "granted" : "denied",
                            'ad_personalization': acceptedCookieTypes.includes("marketing-cookies") ? "granted" : "denied",
                            'analytics_storage': acceptedCookieTypes.includes("analytical-cookies") ? "granted" : "denied",
                            'personalization_storage': acceptedCookieTypes.includes("preference-cookies") ? "granted" : "denied",
                            'functionality_storage': 'granted',
                            'security_storage': 'granted'
                        });
                    },
                    (error) => {
                        console.error(error);
                    }
                );
            }
        },
        created() {
            let instance = this;
            this.$root.$on('checked', function (cookieName, val) {
                let currentCookieType = _.find(instance.cookieTypes, (ct) => {
                    return ct.name === cookieName;
                });
                currentCookieType.checked = val;
            });
        }
    }
</script>