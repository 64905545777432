
<div class="cw-dropdown" :class="{'disabled': isPrivate}" id="cw-dropdown">
    <div class="cw-dropdown__label" @click="toggleShowList()">{{label}}</div>
    <ul class="cw-dropdown__list" :class="{'active': showList}">
        <li v-for="user in value" v-bind:class="{'disabled':isDisabled(user.UserId)}" :key="user.UserId">
            <input class="form-check-input" :id="user.UserId" type="checkbox" v-model="user.IsFollower" @change="emitUsers()" v-bind:disabled="isDisabled(user.UserId)"/> 
            <label :for="user.UserId">{{user.Firstname}} {{user.Lastname}} &lt;{{user.Email}}&gt;</label>
        </li>
        <li v-if="isAdmin && !isPrivate" class="li-with-anchor">
            <a href="/account/users" class="text-grey font-small" target="_blank"><i class="fas fa-user-plus text-grey font-small ml-2 mr-1"></i> {{translate('add_user')}}</a>
        </li>
    </ul>
</div>
