﻿<template>
    <tr role="row" class="event-row">
        <td width="40" class="event-cell">
            <i class="fas type-icon" :class="[Color,eventIcon(Type)]"></i> 
        </td>
        <td class="event-cell" v-html="HtmlDescription"></td>
    </tr>
</template>

<script>
    import Translate from 'Mixins/translate';
    import _ from 'lodash';
    
    export default {
        name: 'event',
        props: {
            ChangeDirection: String,
            Date: Number,
            HtmlDescription: String,
            Type: String,
            Color: String,
            Icon: String | undefined
        },
        mixins: [Translate],
        methods: {
            eventIcon(type) {
                if (this.Icon !== undefined)
                    return this.Icon;
                return this.$options.filters.typeToIcon(type);
            }
        }
    }
</script>