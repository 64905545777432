
<div>
    <div v-if="isLoading" class="my-5 py-5 text-center">
        <i class="fas fa-2x fa-spinner text-lightblue spinning"></i>
    </div>
    <div v-else class="dm-wrapper"  :class="{'wide': waitingOrFinished}">
        <sidebar :progress="progress" :title="translate('enrichment_sidebar_title')" v-if="!waitingOrFinished" :class="{'d-none d-lg-block': !mobileOpen, 'd-block': mobileOpen}"></sidebar>
        <main>
            <upload-vats v-if="activeStep === Step.UploadVats" :progress="progress" @createRequest="createRequest"></upload-vats>
            <results v-else :progress="progress" :currentRequest="currentRequest"></results>     
        </main>
        <result-counter v-if="currentRequest" :progress="progress" :boxTitle="translate('uw_lijst')" class="d-none d-md-block"></result-counter>
    </div>
</div>
