
<div class="container">
    <div class="row">
        <div class="col-6 col-lg-8">
            <h1>
                {{currentList.Naam}}
            </h1>
            <button type="button"
                    class="btn btn-blue btn-sm"
                    v-if="showSampleMail"
                    v-on:click.prevent="openModal">
                <i class="fas fa-envelope"></i>&nbsp; Demo Mail
            </button>
            <modal id="alerts-demo-mail" title="Alerts demo mail" v-cloak size="lg">
                <div class="row">
                    <div class="col-md-12">
                        <img :src="'/images/demo/alerts-example-mail-' + language + '.jpg'" alt="Alerts" class="img-fluid"/>
                    </div>
                </div>
            </modal>
        </div>
        <div class="col-6 col-lg-4 text-right">
            <div class=" menu-items-wrapper">
                <div class="title">
                    Alerts
                </div>
                <div class="menu-items">
                     <a href="" class="menu-item" :class="{'active': activeSubPage === 'events'}" @click.prevent="activateTab('events')">
                        <i class="fas fa-exchange-alt mr-2"></i> {{translate('changes')}}
                    </a>
                   <a href="" class="menu-item" :class="{'active': activeSubPage === 'list-management'}" @click.prevent="activateTab('list-management')">
                        <i class="fas fa-tasks mr-2"></i> {{translate('list_management')}}
                    </a>
                </div>
            </div>
        </div>
    </div>
    <component :is="activeSubPage"
               :current-list="currentList"
               :users="users"></component>

    <modal :title="translate('add_companies')" id="addCompanies" size="lg">
        <add-companies :current-list="currentList"></add-companies>
    </modal>
</div>
