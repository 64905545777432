﻿<template>
    <modal title="Toevoegen aan Alerts" :id="modalId">
        <form @submit.prevent="addToAlerts">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="listId" class="mb-1">Kies lijst</label>
                        <div v-for="(list, idx) in store.alertsLists" :value="list.listId" class="mb-2">
                            <input :id="list.listId" type="radio" class="azure" v-model="chosenAlertsListId" :value="list.listId" />
                            <label :for="list.listId">{{list.naam}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-primary">Toevoegen</button>
                </div>
            </div>
        </form>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue'
import Modal from "../../../shared/modal.vue";
import {useStore} from "../../store";
import Toast from "../../../../lib/Toast";

export default Vue.extend({
  name: "add-to-alerts",
    components: {Modal},
  data() {
    return {
        modalId: "add-to-alerts",
        chosenAlertsListId: null as number | null,
        store: useStore()
    }
  },
  methods: {
    async addToAlerts() {
        if(!this.chosenAlertsListId)
            return;
        try{
            await this.store.addToAlertsList(this.store.addToAlertsVat!,this.store.addToAlertsName!, this.chosenAlertsListId!);
        } catch (e: unknown) {
            Toast.error(e as string);
        }
        this.$root.$emit('hide-modal', this.modalId);
        this.store.addToAlertsVat = null;
        this.store.addToAlertsName = null;
    }
  }
})
</script>