
<div class="cw-dropdown" id="type-dropdown">
    <div class="cw-dropdown__label" @click="toggleShowList()">{{translate('event_type_' + selectedTypeValue)}}</div>
    <ul class="cw-dropdown__list" :class="{'active': showList}">
        <li v-for="type in types" @click="selectValue(type.value)" :key="type.value">
            <i class="text-blue fas" :class="'fa-'+type.icon"></i> 
            {{translate('event_type_' + type.value)}} 
            <span class="badge badge-pill badge-secondary float-right badge-fixed" :class="{'badge-blue': type.numberOfEvents > 0}">{{type.numberOfEvents}}</span>
        </li>
    </ul>
</div>
