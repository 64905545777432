﻿<template>
    <form method="get"
          v-on:submit.prevent="validateSubmit"
          action="/search/companies"
          :data-gtm-cat="gtmCategory"
          :data-gtm-action="gtmAction">

        <div class="search-input-wrapper row">
            <div class="input-wrapper" :class="{'col-md-12':showAnnouncement, 'col-md-6': !showAnnouncement}">
                <input name="NameOrVat"
                       autofocus
                       id="nameOrVat"
                       class="form-control mr-1 search-input"
                       type="text"
                       :placeholder="translate('placeholder_bedrijf')"
                       v-model="nameOrVat"
                       v-bind:class="{error: errorField == 'nameOrVat'}" />
            </div>
            <div class="input-wrapper" :class="{'col-md-12':showAnnouncement, 'col-md-5': !showAnnouncement}">
                <autocomplete-input fetch-url="/ajax/autocomplete/citysuggestions"
                                    name="Location"
                                    :value="location"
                                    :placeholder="translate('placeholder_gemeente')"></autocomplete-input>
            </div>
            <div :class="{'col-md-12':showAnnouncement, 'col-md-1': !showAnnouncement}">
                <button type="submit" class="btn btn-info h-100 w-100">
                    <template v-if="showAnnouncement">
                        {{translate('zoeken')}}
                    </template>
                    <template v-else>
                        <i class="fas fa-search"></i>
                    </template>
                </button>
            </div>

            <div class="col-md-12">
                <div class="card-box warning small m-t-10" v-if="errorMessage !== ''" v-cloak>
                    {{errorMessage}}
                </div>
            </div>
        </div>
        <input type="hidden" name="companyQuery" :value="nameOrVat+';'+location" />
    </form>
</template>
<script>
    import Translate from 'Mixins/translate';
    import AutocompleteInput from '../../shared/autocomplete-input.vue';
    import QueryString from "Lib/QueryString";

    export default {
        name: 'company',
        components: {
            'autocomplete-input': AutocompleteInput
        },
        inject: ['showAnnouncement'],
        props: {
            gtmCategory: String,
            gtmAction: String
        },
        mixins: [Translate],
        data: function () {
            return {
                nameOrVat: '',
                location: '',
                minimumSearchLength: 2,
                errorMessage: '',
                errorField: ''
            }
        },
        mounted: function () {
            const uri = window.location.href;
            this.nameOrVat = QueryString.getParameter(uri, "NameOrVat") ?? "";
            this.location = QueryString.getParameter(uri, "Location") ?? "";
        },
        methods: {
            showError: function (field, translationKey) {
                this.errorMessage = translationKey && this.translate(translationKey);
                this.errorField = field;
                document.getElementById(this.errorField).focus();
            },
            hideError: function () {
                this.errorField = '';
                this.errorMessage = '';
            },
            validateSubmit: function (event) {
                let message = '';
                if (this.nameOrVat === '') {
                    message = 'no_search_result_reason_company_name_missing';
                } else if (this.nameOrVat.length < this.minimumSearchLength) {
                    message = 'no_search_result_reason_company_name_too_short';
                }

                if (message === '')
                    this.hideError();
                else
                    return this.showError('nameOrVat', message);

                event.target.submit();
            }
        }
    };
</script>