﻿<template>
    <div>
        <a v-if="!isLeadCreated"
           href=""
           class="mt-3 btn" :class="btnClass"
           v-on:click.prevent="sendRequest(package, type)"
           :data-gtm-cat="dataGtmCat"
           :data-gtm-action="dataGtmAction">
            <slot></slot>
        </a>
        <div v-else class="alert alert-success">
            <b>{{message}}</b>
        </div>
    </div>
</template>

<script>
    import Ajax from 'Lib/Ajax';
    import Translate from 'Mixins/translate';

    export default {
        name: 'create-lead-btn',
        props: {
            hid: String,
            feature: String,
            campaign: String,
            package: String,
            btnClass: String,
            type: String,
            message: String,
            dataGtmCat: String,
            dataGtmAction: String
        },
        mixins: [Translate],
        data() {
            return {
                isLeadCreated: false
            }
        },
        methods: {
            sendRequest(abonnementType, requestedAction) {
                Ajax.post(
                    '/ajax/campaign/' + requestedAction,
                    {
                        hid: this.hid,
                        feature: this.feature,
                        requestedType: abonnementType,
                        campaignCode: this.campaign
                    },
                    () => {
                        this.isLeadCreated = true;
                    },
                    (error) => {
                        console.error(error);
                    }
                );
            }
        }
    }
</script>