﻿<template>
    <div id="cookies" class="cookie-consent-modal" role="dialog">
        <div class="row">
            <div class="col-md-12">
                <h2 data-nosnippet>{{translate('cookies_modal_title')}}</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <p data-nosnippet v-html="cookiesModalDesc"></p>
                <cookie-consent-list></cookie-consent-list>
            </div>
        </div>
    </div>
</template>

<script>
    import CookieConsentList from './cookie-consent-list.vue';
    import Translate from 'Mixins/translate';

    export default {
        name: 'cookie-consent-modal',
        mixins: [Translate],
        components: {
            'cookie-consent-list': CookieConsentList,
        },
        data() {
            return {
                cookiesLink: window.cw.cookiesLink
            }
        },
        computed: {
            cookiesLinkHtml: function () {
                return '<a href="' + this.cookiesLink + '">' + this.translate('cookie_management') + '</a>';
            },
            cookiesModalDesc: function () {
                return this.translate('cookies_modal_desc', this.cookiesLinkHtml);
            }
        }
    }
</script>