﻿<template>
    <div class="row">
        <div class="col-md-12">
            <p>
                Wil u weten waar deze bedrijfsleider nog mandaten heeft of heeft gehad.
                Bestel vandaag nog deze uitbreiding en u krijgt onmiddellijk zicht op het volledig netwerk van deze bestuurder.
            </p>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'feature-mandates'
    }
</script>