﻿<template>
    <component :is="viewName" v-bind="viewData" :current-list="currentList"></component>
</template>

<script>
    import Form from './add-companies/upload-form.vue';
    import ChooseColumn from './add-companies/choose-column.vue';
    import ChooseSelectionMethod from './add-companies/choose-selection-method.vue';
    import Screening from './add-companies/screening/select-statusses.vue';
    import RemoveVatsFromScreening from './add-companies/screening/remove-vats-from-screening.vue';

    export default {
        name: 'add-companies',
        components: {
            'upload-form': Form,
            'choose-column': ChooseColumn,
            'choose-selection-method': ChooseSelectionMethod,
            'screening': Screening,
            RemoveVatsFromScreening
        },
        props: {
            currentList: Object
        },
        data() {
            return {
                viewName: 'upload-form',
                viewData: null
            }
        },
        methods: {
            closeModal() {
                this.$root.$emit('hide-modal', 'addCompanies');
            }
        },
        mounted() {
            this.$root.$on('change-view', (payload) => {
                this.viewName = payload.viewName;
                this.viewData = payload.viewData;

                if (payload.modalSize) {
                    this.$root.$emit('resize-modal', {
                        modalId: 'addCompanies',
                        size: payload.modalSize
                    });
                }
            });
        }
    }
</script>

<style>

</style>