﻿<template>
    <div class="timetable-group">
        <div class="date">
            <div class="font-weight-bold">{{date|toLongDate}}</div>
            <div class="font-small text-lightgrey font-italic">
                {{date|toTimeAgo}}
            </div>
        </div>
        <div class="content w-100">
            <table class="w-100">
                <company-event v-for="(company, vat, idx) in companies" 
                                   :key="company.idx" 
                                   :current-list="currentList"
                                   :events="company.events" 
                                   :vat="company.vat"></company-event>
                <person-event v-for="(person, id, idx) in persons" 
                                   :key="person.idx" 
                                   :current-list="currentList"
                                   :events="person.events" 
                                   :id="person.id"></person-event>
                <address-event v-for="(address, id, idx) in addresses" 
                                   :key="address.idx" 
                                   :current-list="currentList"
                                   :events="address.events" 
                                   :id="address.id"></address-event>
            </table>
        </div>
    </div>
</template>

<script>
    import CompanyEvent from './company-event.vue';
    import PersonEvent from './person-event.vue';
    import AddressEvent from './address-event.vue';
    import DateHelpers from 'Mixins/date';

    export default {
        name: 'day',
        props: {
            companies: Array,
            persons: Array,
            addresses: Array,
            date: String,
            currentList: Object
        },
        mixins: [DateHelpers],
        components: {
            CompanyEvent,
            PersonEvent,
            AddressEvent
        }
    }
</script>
