﻿import Toast, {MessageType}  from '../lib/Toast';
import Page from '../lib/Page';

export default (function () {
    Page.registerBehavior('[data-toast]', function (element) {
        const type = element.getAttribute('data-toast-type') as MessageType;
        const message = element.innerHTML;
    
        Toast.message(message, type);
    });  
}());
