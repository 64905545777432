
<form method="get"
      v-on:submit.prevent="validateSubmit"
      action="/search/companies"
      :data-gtm-cat="gtmCategory"
      :data-gtm-action="gtmAction">

    <div class="search-input-wrapper row">
        <div class="input-wrapper" :class="{'col-md-12':showAnnouncement, 'col-md-6': !showAnnouncement}">
            <input name="NameOrVat"
                   autofocus
                   id="nameOrVat"
                   class="form-control mr-1 search-input"
                   type="text"
                   :placeholder="translate('placeholder_bedrijf')"
                   v-model="nameOrVat"
                   v-bind:class="{error: errorField == 'nameOrVat'}" />
        </div>
        <div class="input-wrapper" :class="{'col-md-12':showAnnouncement, 'col-md-5': !showAnnouncement}">
            <autocomplete-input fetch-url="/ajax/autocomplete/citysuggestions"
                                name="Location"
                                :value="location"
                                :placeholder="translate('placeholder_gemeente')"></autocomplete-input>
        </div>
        <div :class="{'col-md-12':showAnnouncement, 'col-md-1': !showAnnouncement}">
            <button type="submit" class="btn btn-info h-100 w-100">
                <template v-if="showAnnouncement">
                    {{translate('zoeken')}}
                </template>
                <template v-else>
                    <i class="fas fa-search"></i>
                </template>
            </button>
        </div>

        <div class="col-md-12">
            <div class="card-box warning small m-t-10" v-if="errorMessage !== ''" v-cloak>
                {{errorMessage}}
            </div>
        </div>
    </div>
    <input type="hidden" name="companyQuery" :value="nameOrVat+';'+location" />
</form>
