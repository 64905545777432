﻿<template>
    <transition name="modal">
        <div class="modal-wrapper" v-if="isShown">
            <div class="modal-dialog" :class="sizeClass">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="modal-title">
                            {{title}}
                        </div>
                        <button type="button" @click.prevent="close()" data-dismiss="modal" aria-label="Close" class="close"><span aria-hidden="true">×</span></button>
                    </div>

                    <div class="modal-body" :class="{'no-overflow': noOverflow}" ref="modalBody">
                        <slot>
                            Provided html will be injected into this part.
                        </slot>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'modal',
        props: {
            title: String,
            id: String,
            size: {
                type: String,
                default: 'md'
            },
            noOverflow: Boolean
        },
        data() {
            return {
                isShown: false,
                sz: this.size
            }
        },
        computed: {
            sizeClass() {
                return 'modal-' + this.sz;
            }
        },
        methods: {
            open() {
                this.isShown = true;
                const scroll = window.scrollY || window.pageYOffset;
                document.body.style.position = 'fixed';
                document.body.style.width = '100%';//IE FIX
                document.body.style.top = `-${scroll}px`;

                if (document.getElementById("site-header"))
                    document.getElementById("site-header").style.zIndex = 0;

                this.focusFirstInputElement();
            },
            close() {
                this.isShown = false;
                const scrollY = document.body.style.top;
                document.body.style.position = '';
                document.body.style.top = '';

                if (document.getElementById("site-header"))
                    document.getElementById("site-header").style.zIndex = 35;

                window.scrollTo(0, parseInt(scrollY || '0') * -1);
                this.$emit('onclose');
            },
            listenForShow() {
                this.$root.$on('show-modal', (modalId) => {
                    if (this.id === modalId) {
                        this.sz = this.sz || 'md';
                        this.open();
                    }
                });
            },
            listenForHide() {
                document.addEventListener('keydown', (event) => {
                    if (event.keyCode === 27)
                        this.close();
                });
                this.$root.$on('hide-modal', (modalId) => {
                    if (this.id === modalId)
                        this.close();
                });
            },
            listenForResize() {
                this.$root.$on('resize-modal', payload => {
                    if (this.id === payload.modalId)
                        this.sz = payload.size;
                });
            },
            focusFirstInputElement() {
                this.$nextTick(function () {
                    if(!this.$refs.modalBody)
                        return;
                    
                    const inputsInsideForm = this.$refs.modalBody.querySelectorAll('input,textarea');
            
                    if (inputsInsideForm.length)
                        inputsInsideForm[0].focus();
                });
            }
        },
        mounted() {
            this.listenForShow();
            this.listenForHide();
            this.listenForResize();
        }
        
    }
</script>
