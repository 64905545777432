
<form :action="'/international/search/'+ form.countryCode" method="GET" @submit="isSearching = true">
    <input type="hidden" name="searchType" :value="usedSearchField.map(field => field.name)" />
    <input type="hidden" name="searchTerm" :value="usedSearchField.map(field => field.value)" />
    
    <div class="row">
        <div class="col-md-4">
            <h2>1. {{translate('select_a_country')}}</h2>
            <country-input :countries="countries" id="country" v-model="form.countryCode"></country-input>
        </div>
        <div class="col-md-7 offset-md-1 mt-2 mt-md-0" v-if="limitReached">
            <upsell-banner 
                    :title="translate('cta_upgrade_international_title')"
                    :text="translate('cta_upgrade_international_text')"
                    :btn="translate('cta_upgrade_international_btn')"
                    href="/upgrade/international-reports"
            ></upsell-banner>
        </div>
    </div>

    <h2 class="mt-5">2. {{translate('search_a_company')}}</h2>
    <div class="box">
        <div class="row searchfields">
            <div v-for="searchField in searchFields"
                    :data-or="translate('or').toLowerCase()"
                    class="searchfield col-md-4 mb-2 mb-md-0"
                    :key="searchField.label">
                <label :for="searchField.name">{{searchField.label}}</label>
                <input type="text"
                        :name="searchField.name"
                        :id="searchField.name"
                        v-model="searchField.value"
                        :placeholder="searchField.placeholder"
                        :disabled="usedSearchField.hasValue() && !usedSearchField.contains(searchField)"
                        class="form-control search-input" />
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12">
                <button type="submit" class="btn btn-blue btn-200" :disabled="!usedSearchField.hasValue() || isSearching">
                    <template v-if="!isSearching"><i class="fas fa-search mr-2"></i>{{translate('button_search')}}</template>
                    <i v-else class="fas fa-spinner spinning"></i>
                </button>
            </div>
        </div>
    </div>

</form>
