﻿<template>
    <div>
        <select v-model="criteria._value" class="form-control">
            <option :value="null">{{translate('checkbox_all')}}</option>
            <option :value="true">{{translate('filter_yes')}}</option>
            <option :value="false">{{translate('filter_no')}}</option>
        </select>
    </div>
</template>

<script lang="ts">
    import {BooleanFilterCriteria} from "../../../types";
    import Translate from '../../../../mixins/translate';

    export default Translate.extend({
        name: "yes-no",
        props: {
          criteria: {
              type: Object as () => BooleanFilterCriteria,
              required: true
          }
        }
    });
</script>

<style scoped>

</style>