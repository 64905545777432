<template>
    <div>
        <p class="border-top pt-5"> 
            <template v-if="language === Language.NL">
                Klik op onderstaande knop en wij informeren u 
                wanneer deze integratie beschikbaar is. Zo kan u ze gratis als één van de eersten uitproberen.
            </template>    
             <template v-else-if="language === Language.FR">
                Cliquez sur le bouton ci-dessous et nous vous informerons lorsque cette intégration sera disponible. 
                De cette façon, vous pouvez être l'un des premiers à essayer cette intégration gratuitement.
            </template> 
             <template v-else-if="language === Language.EN"> 
                Click the button below and we will inform you when this integration is available. 
                This way you can be one of the first to try this integration out for free.
            </template> 
        </p>

        <form @submit.prevent="keepMeInformed()" v-if="!isSent">
            <div class="row mt-4">
                <div class="col-md-4 form-group" v-if="!isLoggedInWithEmail">
                    <label for="email">{{translate('email')}}*</label>
                    <input id="email" class="form-control" type="email" v-model="email" />
                </div>
                <div class="col-md-4 form-group" v-if="!isLoggedInWithEmail">
                    <label for="phone">{{translate('phone')}}</label>
                    <input id="phone" class="form-control" type="phone" v-model="phone" />
                </div>
                <div class="col-md-4 form-group mt-auto">
                    <button type="submit" class="mt-auto btn btn-info btn-block"><i class="fas fa-bell mr-1"></i> {{translate('keep_me_informed_btn')}}</button>
                </div>
            </div>
             <div class="card-box small my-2 warning" v-if="$v.email.$invalid && $v.email.$dirty">
                {{translate('validation_error_required', translate('email'))}}
            </div>
            <input type="text" class="honeypot" v-model="honey" />
        </form>

        <div v-else>
            <p>
                <b>{{translate('thank_you')}}</b> 
            </p>
        </div>
    </div>
</template>

<script lang="ts">
    import mixins from 'vue-typed-mixins';
    import Translate from '../../mixins/translate';
    import { SoftwarePartner } from '../../../types/dto/SoftwarePartner';
    import Ajax from '../../../lib/Ajax';
    //@ts-ignore
    import { required } from 'vuelidate/dist/validators.min';
    import { Language } from '../../../types/dto/Language';
    
    export default mixins(Translate).extend({
        name: 'integration_coming_soon',
        props: {
            isLoggedInWithEmail: Boolean,
            integration: Object as  () => SoftwarePartner,
            language: String as () => Language
        },
        data() {
            return {
                email: '',
                phone: '',
                isSent: false,
                Language,
                honey: ''
            }
        },
        validations: {
            email: {required}
        },
        methods: {
            async keepMeInformed(): Promise<void> {
                if (!!this.honey)
                    return;

                if(this.isLoggedInWithEmail) {
                    await Ajax.postAsync("/ajax/integrations/keep-me-informed/user", {
                        softwareId: this.integration.id
                    });

                    this.isSent = true;
                } else {
                    this.$v.$touch();
                    if (this.$v.$invalid)
                        return;

                    await Ajax.postAsync("/ajax/integrations/keep-me-informed/visitor", {
                        softwareId: this.integration.id,
                        email: this.email,
                        phone: this.phone
                    });

                    this.isSent = true;
                }
            }
        }
    })
</script>

<style scoped>
    .honeypot {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        z-index: -1;
    }
</style>