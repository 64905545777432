﻿import Vue from 'vue';
import Page from '../../lib/Page';

import InternationalDashboard from './pages/dashboard.vue';
import InternationalSearchResults from './pages/search-results.vue';

export default (function () {
    Page.registerVue('#international', (rootSelector) => {
        new Vue({
            el: rootSelector,
            components: {
                InternationalDashboard,
                InternationalSearchResults
            }
        });
    });
}());
