﻿import Vue from 'vue';
import Page from '../lib/Page';

export default (function () {
    Page.registerVue('#alerts-sidebar', (rootSelector) => {
        new Vue({
            el: rootSelector,
            data() {
                return {
                    isOpen: false
                }
            },
            methods: {
                toggleSidebar() {
                    this.isOpen = !this.isOpen;
                }
            }
        });
    });
}());
