﻿import Vue from 'vue';
import Page from 'Lib/Page';
import CreateLeadBtn from './create-lead-btn.vue';

export default (function () {
    Page.registerVue('#featureLandingPage', function (rootSelector) {
        new Vue({
            el: rootSelector,
            components: {
                'create-lead-btn': CreateLeadBtn 
            },
            data() {
                return {
                    currentFeature: window.cw.currentFeature,
                    currentCampaign: window.cw.currentCampaign
                }
            },
            mounted() {
                Page.applyBehaviors(rootSelector);
            }
        });
    });
}());
