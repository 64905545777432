﻿import Vue from 'vue';
import Page from '../../lib/Page';
import Wrapper from './wrapper.vue';

export default (function () {
    Page.registerVue('#sales-kpi', function (rootSelector) {
        new Vue({
            el: rootSelector,
            name: 'sales_kpi',
            components: {
                Wrapper
            }
        });
    });
}());