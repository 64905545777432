
<transition name="modal">
    <div class="modal-wrapper" v-if="isShown">
        <div class="modal-dialog" :class="sizeClass">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">
                        {{title}}
                    </div>
                    <button type="button" @click.prevent="close()" data-dismiss="modal" aria-label="Close" class="close"><span aria-hidden="true">×</span></button>
                </div>

                <div class="modal-body" :class="{'no-overflow': noOverflow}" ref="modalBody">
                    <slot>
                        Provided html will be injected into this part.
                    </slot>
                </div>
            </div>
        </div>
        <div class="modal-backdrop fade show"></div>
    </div>
</transition>
