﻿import Page from '../lib/Page';

export default (function () {
    Page.registerBehavior('.animated', function (element: Element) {
        const animation = (element as HTMLElement).dataset.animation!;
        const delay = (element as HTMLElement).dataset.delay;

        checkScrollPos(element);
        window.addEventListener('scroll', (ev: Event) => {
            checkScrollPos(element);
        });

        function checkScrollPos(element: Element): void {
            if(elementInViewport(element as HTMLElement, 20)) {
                setTimeout(() => {
                    element.classList.remove('initially-hidden');
                    element.classList.add('started-animation');
                    element.classList.add(animation);
                },  delay ? parseInt(delay) : 0);
            }
        }
    });
}());

export function elementInViewport(el: HTMLElement, offsetPercentage?: number) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while(el.offsetParent) {
      el = el.offsetParent as HTMLElement;
      top += el.offsetTop;
      left += el.offsetLeft;
    }
  
    return (
      left < (window.pageXOffset + window.innerWidth) &&
      (top + height) > window.pageYOffset * (100 + (offsetPercentage ?? 0)) / 100 &&
      (left + width) > window.pageXOffset
    );
}

