﻿import Page from '../lib/Page';
import { tns } from "tiny-slider/src/tiny-slider";

export default (function () {
    const selector: string = '.slider';
    Page.registerBehavior(selector, function (elem) {
        tns({
            container: selector,
            slideBy: 'page',
            autoplay: true,
            controls: false,
            nav: false,
            autoplayButtonOutput: false,
            mouseDrag: true,
            gutter: 15,
            fixedWidth: 130,
            responsive: {
                '900': {
                    items: 7
                },
                '700': {
                    items: 5
                },
                '500': {
                    items: 4
                },
                '400': {
                    items: 3
                }
            }
        });
    });

    Page.registerBehavior('.announcement-card-wrapper', function (elem) {
        tns({
            container: '.announcement-card-wrapper',
            items: 1,
            slideBy: 1,
            autoplay: true,
            controls: false,
            nav: true,
            navPosition: 'bottom',
            autoplayButtonOutput: false,
            mouseDrag: true,
            autoplayTimeout: 8000,
            //autoplayHoverPause: true // pause on hover, bugs out in combo with mouseDrag: true
        });
    });
}());
