<template>
    <aside>
        <h1 class="mb-4">{{title}}</h1>

        <a href="" class="d-block d-lg-none position-absolute right-0 top-0 p-3 text-grey" @click.prevent="closeMenu">
            <i class="fas fa-times fa-lg"></i>
        </a>
        <ul>
            <li v-for="(step, index) in progress.steps" :key="index">
                <a href="" class="sidenav-link" v-on:click.prevent="activateStep(step)" :class="{'active': step === activeStep, 'not-activatable': !step.isClickable || step.isLocked}">
                    {{index+1}}. {{translate('sidebar_'+step.label)}}
                </a>

                <ul v-if="step.children" class="pl-2">
                    <li v-for="(substep, idx) in step.children" :key="'sub_'+idx">
                        <a class="sidenav-link" :class="{'active': substep === activeStep, 'not-activatable': !substep.isClickable || substep.isLocked}" href="" v-on:click.prevent="activateStep(substep)">
                            <i class="fad"  :class="substep.icon"></i> {{translate('sidebar_'+substep.label)}}
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </aside>
</template>

<script lang="ts">
    import mixins from 'vue-typed-mixins';
    import Translate from '../../../mixins/translate';
    import Progress from '../../progress';
    import { Step } from '../../progress';
    import prospecting from '../../pages/prospecting.vue';

    export default mixins(Translate).extend({
        name: 'dm-sidebar',
        props: {
            progress: Object as () => Progress,
            title: String
        },
        methods:{
            activateStep(step: Step) {
                if(step.isLocked)
                    return;

                this.progress.setActiveStep(step);
                window.history.pushState(
                    {
                        subPage: step.label
                    },
                    '',
                    window.location.href.replace(/(\/datamarketing\/(?:prospecting|enrichment)\/\d+)(\/.+)?/, '$1/' + step.label)
                );
                this.closeMenu();
            },
            closeMenu(): void{
                this.$root.$emit('mobile-menu-close');
            }
        },
        computed: {
            activeStep(): Step {
                return this.progress.activeStep;
            }
        }
    })
</script>

<style scoped lang="scss">
    @import "../../../../../sass/variables";
    
    h1 {
      font-size: 1.9rem;
    }
    
    aside {
        border-right: 1px solid #dee0e0;
        padding-right: 25px;
    }
     
    .sidenav-link {
        padding: 10px 16px;
        display: flex;
        align-items: center;
        color: $body;
        border-radius: 5px;
        border: 1px solid transparent;
        &:hover {
            background-color: $bg-lightgrey;
            text-decoration: none;
        }
        &.active {
            color: $lightblue !important;
            border-color: $lightblue;
            background-color: fade-out($lightblue, 0.95);
        }
        &.not-activatable {
            cursor: default;
            pointer-events: none;
        }
    }

    ul {
        > li {
            font-weight: bold;
            font-size: 15px;  
            > ul {
                margin-bottom: 15px;
                margin-left: 10px;
                > li {
                    font-size: 14px;  
                    a {
                        padding: 7px;
                        color: $greyblue !important;
                        font-weight: normal;

                        i {
                            width: 25px;
                            text-align: center;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
</style>