﻿<template>
    <div class="period"
         v-bind:class="{'d-none': periodEndedBeforeTimelineStart, 'started-before-timeline': periodStartedBeforeTimelineStart}"
         :style="{left: left + '%', width:width + '%', background: background}"
         :title="value">
        <span class="value">{{value}}</span>
    </div>
</template>

<script>
    import _ from 'lodash';
    export default {
        name: 'period',
        props: {
            startDate: Number,
            endDate: Number,
            value: String,
            index: Number,
            timelineStart: Number,
            timelineEnd: Number
        },
        inject: ['hansDateToReadableDate', 'hansDateToTimestamp'],
        computed: {
            periodEndedBeforeTimelineStart: function () {
                return this.endDateConstrained < parseInt(this.timelineStart);
            },
            periodStartedBeforeTimelineStart: function () {
                return this.startDate < parseInt(this.timelineStart);
            },
            startDateConstrained: function () {
                return this.startDate < this.timelineStart ? this.timelineStart : this.startDate;
            },
            endDateConstrained: function () {
                return this.endDate === 0 || this.endDate > this.$root.endDate ? this.$root.endDate : this.endDate;
            },
            left: function () {
                return (this.hansDateToTimestamp(this.startDateConstrained) - this.hansDateToTimestamp(this.timelineStart)) /
                    (this.hansDateToTimestamp(this.timelineEnd) - this.hansDateToTimestamp(this.timelineStart)) * 100;
            },
            width: function () {
                return (this.hansDateToTimestamp(this.endDateConstrained) - this.hansDateToTimestamp(parseInt(this.startDateConstrained))) /
                    (this.hansDateToTimestamp(this.timelineEnd) - this.hansDateToTimestamp(this.timelineStart)) * 100;
            },
            background: function () {
                var colors = [
                    '#1a88d6',
                    '#60209b',
                    '#2adab1'
                ];

                return this.$parent.forcedColor || colors[this.index % colors.length];
            }
        },
        methods: {
            detectTracker: function (e) {
                var trackerXPosition = e.pageX - this.$parent.$parent.offsetLeft;
                var leftBoundary = this.$el.offsetLeft;
                var rightBoundary = leftBoundary + this.$el.clientWidth;
                var label = this.$parent.label;
                var activePeriodContent = '<strong>' + label + '</strong>: ' + this.value;
                var thisElementWasActive = this.$parent.activeElement.content === activePeriodContent && this.$parent.activeElement.index === this.index;

                if (this.value !== undefined && trackerXPosition >= leftBoundary && trackerXPosition <= rightBoundary) {
                    this.$parent.activeElement = {
                        index: this.index,
                        content: activePeriodContent
                    };
                } else if (thisElementWasActive) {
                    this.$parent.activeElement = {
                        index: -1,
                        content: ''
                    };
                }
            }
        },
        mounted: function () {
            window.addEventListener('mousemove', _.throttle(this.detectTracker, 100));
        },
        beforeDestroy() {
            window.removeEventListener('mousemove');
        }
    };
</script>