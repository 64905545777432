﻿
import { VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';
import Translate from '../../lib/Translate';

export default {
    inserted: function (el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
        var message = "";
        var modifiers = binding.modifiers;
        var inputName = binding.value;
        var msg = document.createElement('SPAN');
     
        if (!inputName) {
            console.error("V-validate verwacht een value.");
            return;
        }
        
        if (modifiers.required) {
            message = Translate("validation_error_required", inputName);
            el.setAttribute('data-val-required', message);
        }

        if (modifiers.email) {
            message = Translate("validation_error_email");
            el.setAttribute('data-val-regex', message);
        }

        el.setAttribute('name', inputName);
        el.setAttribute('data-val', 'true');

        if(el.parentNode)
            el.parentNode.appendChild(msg);
       
        msg.setAttribute('class', 'field-validation-valid');
        msg.setAttribute('data-valmsg-replace', 'true');
        msg.setAttribute('data-valmsg-for', inputName);
    }
};

