﻿import _ from 'lodash';
import Vue from 'vue';
import Page from 'Lib/Page';
import Ajax from 'Lib/Ajax';
import UserDropdown from './user-dropdown.vue';
import Toast from 'Lib/Toast';

export default (function () {
    Page.registerVue('#alertsPlusListManagement', function (rootSelector) {
      
        new Vue({
            el: rootSelector,
            components: {
                UserDropdown
            },
            data: {
                users: window.cw.users,
                followerIds: window.cw.followerIds,
                form: {
                    followers: []
                }
            },
            methods: {
                onSubmit() {
                    const followerIds = _.map(_.filter(this.form.followers, 'isFollower'), 'id');
                    
                    Ajax.post(
                        '/ajax/alertsplus/update-followers',
                        {
                            followerIds: followerIds
                        },
                        response => {
                            Toast.success(response.Data);
                        },
                        error => {
                            console.error(error)
                        }
                    )
                }
            },
            mounted() {
                this.form.followers =_.map(this.users, user => {
                    return {
                        ...user,
                        isFollower: _.includes(this.followerIds, user.id)
                    };
                })
            }
        });
    });
}());
