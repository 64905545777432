﻿<template>
    <div class="grey-box mb-5 page-break-avoid">
        <a :href="link" target="_blank" class="text-body text-decoration-none">
            <div class="row pb-3 border-bottom">
                <div class="col-6">
                    <h3 class="text-body text-xl"><b>{{name}}</b></h3>
                    <div class="text-grey">
                        <i class="fas fa-home mr-1"></i> {{location}}
                    </div>
                </div>
                <div class="col-6 text-right text-body">
                    <div class="mb-2" :title="translate('active_mandates')">
                        {{numberOfActiveMandates}}
                        <i class="fas fa-building ml-2"></i>
                    </div>
                    <div :title="translate('fails')">
                        {{numberOfFails}}
                        <i class="fas fa-gavel ml-2"></i>
                    </div>
                </div>
            </div>
        </a>
        <div class="row mt-4 pb-3 border-bottom">
            <div class="col-12" ref="svg">
                <h3 class="text-uppercase font-weight-bold">{{translate('links')}}</h3>
                <!--IE: have to render svg with specific height or it doens't scale. Height shouldn't be bound for other cases to retain print-specific lay-outs. -->
                <svg v-if="isIE" xmlns="http://www.w3.org/2000/svg" style="overflow: visible;" :viewBox="'0 0 ' + width + ' ' + height" :height="height">
                   <ubo-edge 
                    v-for="(edge, index) in edgesWithMetaInfo" 
                    v-bind="edge" 
                    :node-height="nodeHeight" 
                    :col-width="colWidth" 
                    :row-height="rowHeight" 
                    :circle-radius="circleRadius" 
                    :arrow-height="arrowHeight"
                    :hovered-vat="hoveredVat"
                    :key="'edge_'+index"></ubo-edge>
                    <ubo-node v-for="(node, index) in nodes" v-bind="node" :key="'node_'+index" :small="hasSmallNodes"></ubo-node>
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" style="overflow: visible;" :viewBox="'0 0 ' + width + ' ' + height" >
                    <ubo-node v-for="(node, index) in nodes" v-bind="node" :key="'node_'+index" :small="hasSmallNodes" @onHover="onHover" @onHoverLeave="onHoverLeave" :hovered-vat="hoveredVat"></ubo-node>
                    <ubo-edge 
                    v-for="(edge, index) in edgesWithMetaInfo" 
                    v-bind="edge" 
                    :node-height="nodeHeight" 
                    :col-width="colWidth" 
                    :row-height="rowHeight" 
                    :circle-radius="circleRadius" 
                    :arrow-height="arrowHeight" 
                    :hovered-vat="hoveredVat"
                    :key="'edge_'+index"></ubo-edge>
                </svg>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <div class="d-flex align-items-center mb-2">
                    <h3 class="text-uppercase font-weight-bold my-auto">{{translate('pep_title')}}</h3> 
                    <span class="ml-1"> {{translate('for_the_name')}} <b>"{{lastName}} {{firstName}}"</b></span>
                    <i class="fas fa-info-circle text-yellow ml-1 mr-5" :title="translate('pep_explanation')"></i>
                    <button class="btn btn-blue btn-sm d-print-none" type="button" @click="psCheck()" :disabled="isLoading">
                        <i class="fas fa-shield-check mr-1"></i>
                        <span v-if="!lastCheckDate">{{translate('btn_pep')}}</span>
                        <span v-else>{{translate('btn_pep_again')}}</span>
                    </button>
                </div>
                <div class="card-box warning mb-3" v-if="errorMessage">{{errorMessage}}</div>

                <div class="my-4" v-if="showUpsell">
                    <upsell-banner white
                                   border
                                   href="/upgrade/kyc"
                                   :btn="translate('increase_pep_btn')"
                                   :title="translate('increase_pep_title')"
                                   :text="translate('increase_pep_text')"></upsell-banner>
                </div>

                <div v-if="isLoading">
                    <i class="fas fa-spinner spinning text-blue mr-2"></i> {{translate('fetching_data')}}
                </div>
                <p class="font-italic" v-else-if="!lastCheckDate || lastCheckStatus !== 'success'">
                    {{translate('never_pepped')}}
                </p>
                <template v-else>
                    <table class="table table-sm mt-3" v-if="hits.length > 0" :style="'max-width:' + width + 'px !important;'">
                        <thead>
                            <tr>
                                <th width="85">{{translate('pep_header_type')}}</th>
                                <th width="65">match</th>
                                <th width="200">{{translate('pep_header_name')}}</th>
                                <th width="100">{{translate('pep_header_nationality')}}</th> 
                                <th width="100">{{translate('pep_header_birthdate')}}</th>
                                <th width="100">{{translate('pep_header_listname')}}</th>
                                <th>{{translate('pep_header_reason')}}</th>
                            </tr>
                        </thead>
                        <tbody style="background-color: white">
                            <tr v-for="(hit, index) in hits" :key="index">
                                <td>
                                    <span class="badge badge-inverted badge-sm"
                                          :class="{'badge-blue': hit.hitType === 'Peps', 'badge-danger': hit.hitType === 'Sanction'}">
                                        <i class="fas mr-1" :class="{'fa-landmark-alt': hit.hitType === 'Peps', 'fa-user-secret': hit.hitType === 'Sanction'}"></i>
                                        {{hit.hitType}}
                                    </span>
                                </td>
                                <td>{{hit.score}}%</td>
                                <td><b>{{hit.name.lastname}} {{hit.name.firstname}}</b></td>
                                <td>{{hit.nationality}}</td>
                                <td>{{hit.birthDate}}</td>
                                <td>{{hit.listName}}</td>
                                <td>{{translateInstituteGroup(hit.reason)}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p v-else>
                        <i class="mr-2 fas fa-check-circle text-teal"></i>{{translate('no_results')}}
                    </p>

                    <p class="text-grey font-italic mt-4 mb-0">
                        {{translate('last_pep_date')}} {{lastCheckDate|toLongDate}}
                    </p>
                </template>

                <div class="position-absolute bottom-0 right-0 mr-3">
                    {{translate('person').toLowerCase()}} {{index}} / {{$parent.allPersons.length}}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import _ from 'lodash';
    import Ajax from '../../lib/Ajax';
    import UboNode from './node.vue';
    import UboEdge from './edge.vue';
    import DateHelpers from '../mixins/date';
    import Translate from '../mixins/translate';
    import Toast from '../../lib/Toast';
    import UpsellBanner from '../shared/upsell-banner.vue';
    import {CheckStatus, PollingEmitResult, Hit, LinkType, ChainLink, Position, Info, Company, TreeNode, NestedTreeNode} from './types';
    import mixins from 'vue-typed-mixins'; 
    interface Edge {
        from: Position,
        to: Position,
        info: Info,
        hash: string
    }
    interface EnrichedEdge extends Edge {
        current: number;
        max: number;
    }

    interface NodeWithoutColumn {
        company: Company,
        row: number,
        parents: number[],
        children: number[]
    }

    interface Node extends NodeWithoutColumn {
        column: number,
    }
    
    export default mixins(DateHelpers, Translate).extend({
        name: 'report_kyc_ubo',
        components: {
            UboNode,
            UboEdge,
            UpsellBanner
        },
        props: {
            chains: Array as () => ChainLink[][],
            id: Number,
            linkType: String as () => LinkType,
            firstName: String,
            lastName: String,
            location: String,
            numberOfActiveMandates: Number,
            numberOfFails: Number,
            numberOfShares: Number,
            index: Number,
            hits_: Array as () => Hit[],
            lastCheckDate_: String,
            lastCheckStatus_: String as () => CheckStatus,
            companies: Array as () => Company[]
        },
        data() {
            return {
                width: 1190,
                rowHeight: 165,
                nodeHeight: 95,
                arrowHeight: 10,
                arrowWidth: 10,
                circleRadius: 17,
                name: this.lastName + " " + this.firstName,

                isLoading: false,
                hits: this.hits_,
                lastCheckDate: this.lastCheckDate_
                    ? new Date(this.lastCheckDate_)
                    : null,
                lastCheckStatus: this.lastCheckStatus_,
                showUpsell: false,
                errorMessage: '',
                hoveredVat: null as number | null
            }
        },
        computed: {
            link(): string {
                return '/person/' + this.id;
            },
            isIE(): boolean {
                /* @ts-ignore */
                return !!window.document.documentMode;
            },
            hasSmallNodes(): boolean {
                return this.numberOfCols >= 6;
            },
            nodeWidth(): number {
                return this.hasSmallNodes ? 190: 220;
            },
            numberOfRows(): number {
                return _.maxBy(this.nodes, 'row')?.row ?? 0;
            },
            numberOfCols(): number {
                return this.nodes.length ? (_.maxBy(this.nodes, 'column')?.column) ?? 0 : 0;
            },
            colWidth(): number {
                return this.width / this.numberOfCols;
            },
            height(): number {
                return this.rowHeight * this.numberOfRows; //root has no nodeHeight
            },
            uniqueVats(): number[] {
                return _.uniq(_.flatten(this.chains).map(c => c.vat));   
            },
            nestedTree(): NestedTreeNode[] {
                const sortedChainsWithVat = this.chains.map(chain => chain.map(c => c.vat));
                let nodeTreeList: TreeNode[] = [];
                
                this.uniqueVats.forEach((vat: number) => {
                    sortedChainsWithVat.forEach((chain:number[]) => {
                        const index = chain.findIndex(c => c === vat);
                        
                        if(index != -1) {
                          if(!nodeTreeList.map(node => node.vat).includes(vat)) {
                              nodeTreeList.push({
                                  vat:vat,
                                  children: []
                              }) 
                          }
                            if(!!chain[index + 1] && !nodeTreeList.find(node => node.vat === vat)?.children.includes(chain[index + 1])) {
                                nodeTreeList.find(node => node.vat === vat)?.children.push(chain[index + 1])  
                            }
                        }
                    })
                });
                
                const startVats = this.uniqueVats.filter(vat => !nodeTreeList.flatMap(n => n.children).find(c => c === vat));

                function findNodelist(vat: number): NestedTreeNode {
                    const foundNode = nodeTreeList.find(node => node.vat === vat)!;
                   
                    const children = foundNode.children.map((child: number) => findNodelist(child))
                    
                    return {
                        vat: foundNode.vat,
                        children: children
                    } as NestedTreeNode
                    
                }
                
                return startVats.map(startVat => {
                    return findNodelist(startVat);
                });
            },
            nodes(): Node[] {
                const nodesWithRows: NodeWithoutColumn[] = this.uniqueVats.map(vat => {
                    const parents: number[] = [];
                    const children: number[] = [];

                    _.forEach(this.chains, (chainLinks) => {
                        const vats = chainLinks.map(comp => comp.vat);
                        const index = vats.indexOf(vat);
                        const parentIndex = index - 1;
                        const childIndex = index + 1;

                        if (parentIndex >= 0) {
                            const parent = vats[parentIndex];

                            if (!_.includes(parents, parent))
                                parents.push(parent);
                        }
                        if (childIndex > 0 && childIndex < vats.length) {
                            const child = vats[childIndex];

                            if (!_.includes(children, child))
                                children.push(child);
                        }
                    });


                    return {
                        company: this.companies.find(company => company.vat === vat)!,
                        row: this.findDepth(vat, this.nestedTree) + 1,
                        parents,
                        children
                    }
                });
               
                const rootVats: number[] = _.uniq(this.chains.map(companies => _.head(companies)?.vat ?? 0));
                const otherVats: number[] = _.difference(this.uniqueVats, rootVats);

                const rootNodes: NodeWithoutColumn[] = _.orderBy(
                    nodesWithRows.filter(node => _.includes(rootVats, node.company.vat)),
                    [(n) => n.children.length, 'row'],
                    ['desc', 'asc']
                );
                const otherNodes: NodeWithoutColumn[] = nodesWithRows.filter(node => _.includes(otherVats, node.company.vat));

                const rootColumns: number[] = [];
                for (let i = 1; i <= rootNodes.length; i++) {
                    rootColumns.push(i);
                }

                //choose column
                const rootNodesWithCols: Node[] = rootNodes.map(node => {
                    const middleColumnIndex = Math.floor(rootColumns.length / 2);
                    const mostCentralColumn = rootColumns[middleColumnIndex];
                    rootColumns.splice(middleColumnIndex, 1);

                    return {
                        ...node,
                        column: mostCentralColumn
                    };
                });
                const maxRow: number = _.maxBy(nodesWithRows, n => n.row)!.row;

                let allnodesWithCols = rootNodesWithCols;
                for (let i = 2; i <= maxRow; i++) {
                    const nodesInThisRow = otherNodes.filter(node => node.row === i);
                    const chosenColumns = rootNodesWithCols.filter(n => n.row === i).map(n => n.column);
                    
                    const nodesInThisRowWithColumn: Node[] = nodesInThisRow.map(node => {
                        const parentColumns = allnodesWithCols.filter(n => _.includes(node.parents, n.company.vat)).map(n => n.column);
                    
                        // child should not be in same column as two parents, this would disrupt visibility. 
                        const parentNodesPerColumn = _.countBy(parentColumns, n => n);
                        const choosableColumns = _.keys(
                            _.omitBy(
                                parentNodesPerColumn, 
                                (val, key) => val >= 2)
                            )
                            .map(v => parseInt(v))
                            .filter(v => !chosenColumns.includes(v)
                        );
                        
                        let column: number = 1;
                        if (choosableColumns.length > 0) {
                            const middleColumnIndex = Math.floor(choosableColumns.length / 2);
                            column = choosableColumns[middleColumnIndex];
                        } else if (chosenColumns.length > 0) {
                            column = _.max(chosenColumns)! + 1; // one to the right of sibling
                        } else if (parentColumns.length > 1) {
                            column = parentColumns[0] + 1 // one to the right of parents
                        } else {
                            column = parentColumns[0] // same as parent
                        }
                        
                        chosenColumns.push(column);

                        return {
                            ...node,
                            column
                        }
                    });
                    allnodesWithCols = _.concat(allnodesWithCols, nodesInThisRowWithColumn);
                }

                // recalculate rows for when data is incorrect and rows are missing.
                const groups = _.groupBy(allnodesWithCols, 'row'); 
                let newNodes: Node[] = [];
                let newRow: number = 1;
                _.forEach(groups, (group, row) => {
                    _.forEach(group, node => {
                        newNodes.push({...node, row: newRow});
                    });
                    newRow++;
                });
              
                return newNodes;
            },
            edges(): Edge[] {
                let allEdges: Edge[] = [];
                this.chains.forEach(chain => {
                    let parentVat: number | null = null;
                    let newEdges = chain.map(link => {
                        const vat = link.vat;
                        const node = this.nodes.find(node => node.company.vat === vat)!;
                        const parentNode = this.nodes.find(node => node.company.vat === parentVat);
                        const result: Edge = {
                            from: {
                                column: parentNode?.column ?? node.column,
                                row: parentNode
                                    ? parentNode.row
                                    : 0
                            },
                            to: {
                                column: node.column,
                                row: node.row
                            },
                            info: {
                                linkType: link.linkType,
                                shares: link.shares,
                                fromVat: parentVat ? parentVat : 0
                            },
                            hash: (parentVat ? parentVat : "0") + "->" + vat
                        }
                        parentVat = vat;
            
                        return result;
                    });
            
                    allEdges = allEdges.concat(newEdges);
                });
            
                return _.uniqBy(allEdges, 'hash');
            },
            edgesWithMetaInfo(): EnrichedEdge[] {
                const groups = _.groupBy(this.edges, edge => {
                    return edge.from.column + '-' + edge.from.row;
                });
            
                return _.flatMap(groups, (edges: Edge[]) => {
                    const sortedEges: Edge[] = edges.sort((a, b) => {
                        return (a.to.column - b.to.column) * 10 - (a.to.row - b.to.row);
                    });
            
                    return sortedEges.map((edge: Edge, index: number) => ({
                        ...edge,
                        current: index + 1,
                        max: edges.length
                    }))
                });
            }
        },
        methods: {
            onHover(vat: number) {
              this.hoveredVat = vat;
            },
            onHoverLeave() {
                this.hoveredVat = null;
            },
            findDepth(vat: number, children: NestedTreeNode[] = [], depth = 0): number {
                let maxDepth = 0;

                children.forEach(child => {
                    if(child.children.length > 0) {
                        const d = this.findDepth(vat, child.children, depth + 1);
                        if(d > maxDepth)
                            maxDepth = d;
                    }
                    
                    if(child.vat === vat && depth > maxDepth) maxDepth = depth;

                });

                return maxDepth;
            },
            psCheck(): void {
                this.isLoading = true;
                const url = '/ajax/kyc/queue-pep-and-sanctions-check';
                const formData = {
                    firstName: this.firstName,
                    lastName: this.lastName
                };

                Ajax.post(
                    url,
                    formData,
                    ({ data }) => {
                        if (data === "OK") {
                            this.addToPollingList();
                        }
                        else if (data === "NO_CREDITS") {
                            this.isLoading = false;
                            this.showUpsell = true;
                        }
                    },
                    error => {
                        Toast.error(this.translate('error_message_ajax_something_went_wrong'));
                    }
                )
            },
            addToPollingList(): void {
                this.$emit("add-to-polling-list", this.id);
            },
            setError(date?: Date): void {
                const lastCheckDate = this.formatDate(date ? date : (this.lastCheckDate ?? new Date())); 

                this.errorMessage = this.translate('pep_error_date', lastCheckDate);
            },
            translateInstituteGroup(text: string): string {
                return text.replace('[Gemeente]', this.translate('pep_institutegroup_gemeente'))
                    .replace('[OCMW]', this.translate('pep_institutegroup_ocmw'))
                    .replace('[District]', this.translate('pep_institutegroup_district'))
                    .replace('[Provincie]', this.translate('pep_institutegroup_provincie'));
            }
        },
        mounted(): void {
            if (this.lastCheckStatus === CheckStatus.Pending) {
                this.isLoading = true;
                this.addToPollingList();
            } else if (this.lastCheckStatus === CheckStatus.Failure) {
                this.setError();
            }

            this.$root.$on('polling-result', (data: PollingEmitResult) => {
               
                if (this.id === data.personId) {
                    this.isLoading = false;

                    if (data.person.lastCheck.status === CheckStatus.Success) {
                        this.hits = data.person.hits;
                        this.lastCheckDate = new Date(data.person.lastCheck.updated);
                        this.lastCheckStatus = CheckStatus.Success;
                        this.errorMessage = '';
                    } else { //failure
                        this.setError(new Date(data.person.lastCheck.updated));
                    }
                }
            });
            
        }
    });
</script>