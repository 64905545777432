
<tr>
    <td>
        <i v-if="isSubmandate" class="fas fa-level-up-alt fa-rotate-90 mr-2"></i>
        <i class="fas fa-book mr-3" :class="mandate.status"></i>
        <a :href="mandate.detailsUrl" target="_blank" :disabled="!mandate.detailsUrl">
           {{mandate.mandateHolderName}}
        </a>
    </td>
    <td v-if="!isMobile"><span v-if="!mandate.isHuidig">{{mandate.einde|showYear}}</span></td>
    <td v-if="!isMobile">{{mandate.companyEndDate|showYear}}</td>
    <td><barometer-icon :className="mandate.companyBarometer" :score="mandate.companyBarometerScore" v-if="canSeeScores && !!mandate.companyBarometer"></barometer-icon></td>
</tr>
