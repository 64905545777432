<template>
    <div>
        <div class="dm-wrapper" :class="{'wide': waitingOrFinished}">
            <sidebar :progress="progress" :title="translate('dm_option_prospecting_title')" v-if="!waitingOrFinished" :class="{'d-none d-lg-block': !mobileOpen, 'd-block': mobileOpen}"></sidebar>
            <main v-if="currentRequest" class="pb-5 pr-3 pb-dm-0">
                <warning-assistent></warning-assistent>
                <keep-alive>
                    <component :is="activeStep.label"  :progress="progress"></component>
                </keep-alive>
            </main>
            <main v-else>
                <div class="text-center py-5">
                    <i class="fas fa-spinner spinning text-blue fa-2x"></i>
                </div>
            </main>
            <result-counter 
                v-if="currentRequest" 
                :progress="progress" 
                :boxTitle="translate('uw_selectie')" 
                class="d-none d-lg-block"
                :class="{'d-none d-lg-block': !mobileCriteriaShown, 'd-block': mobileCriteriaShown}"></result-counter>
            <mobile-menu v-if="currentRequest && !waitingOrFinished"  :progress="progress"></mobile-menu>
        </div>

    </div>
</template>

<script lang="ts">
    import SidebarComponent from '../components/shared/sidebar.vue';
    import ResultCounter from '../components/shared/result-counter.vue';
    import Language from '../components/prospecting/language.vue';
    import Start from '../components/prospecting/start.vue';
    import Location from '../components/prospecting/location.vue';
    import Activity from '../components/prospecting/activity.vue';
    import CompanySize from '../components/prospecting/company-size.vue';
    import Establishment from '../components/prospecting/establishment.vue';
    import LegalForm from '../components/prospecting/legal-form.vue';
    import Barometer from '../components/prospecting/barometer.vue';
    import Financial from '../components/prospecting/financial.vue';
    import Exclude from '../components/prospecting/exclude.vue';
    import Results from '../components/shared/results.vue';
    import Translate from '../../mixins/translate';
    import mixins from 'vue-typed-mixins';    
    import { RequestStatus } from '../types';
    import DataMarketingService, {Request} from '../dataMarketingService';
    import Progress, { Step } from '../progress';
    import WarningAssistent from '../components/prospecting/warning-assistent.vue';
    import { PrepaidDataMarketingRequest } from '../../../types/dto/PrepaidDataMarketing';
    import MobileMenu from '../components/shared/mobile-menu.vue';
    

    export default mixins(Translate).extend({
        components: {
            'sidebar': SidebarComponent,
            ResultCounter,
            Language,
            Start,
            Location,
            Activity,
            CompanySize,
            Establishment,
            LegalForm,
            Barometer,
            Financial,
            Exclude,
            Results,
            WarningAssistent,
            MobileMenu
        },
        data(){
            return {
                progress: Progress.ProspectingProgress,
                request: window.cw.request as PrepaidDataMarketingRequest,
                currentRequest: null as null | Request,
                RequestStatus,
                mobileOpen: false,
                mobileCriteriaShown: false
            }
        },
        computed:{
            activeStep(): Step {
                return this.progress.activeStep;
            },
            waitingOrFinished(): boolean {
                return this.currentRequest?.status !== RequestStatus.STARTED
            }
        },
        methods: {
            listenForMobileMenu(): void {
                this.$root.$on('mobile-menu', () => {
                    this.mobileOpen = !this.mobileOpen
                });

                this.$root.$on('mobile-menu-close', () => {
                    this.mobileOpen = false
                });
            },
            listenForMobileCriteria(): void {
                this.$root.$on('mobile-criteria', () => {
                    this.mobileCriteriaShown = !this.mobileCriteriaShown
                });

                this.$root.$on('mobile-criteria-close', () => {
                    this.mobileCriteriaShown = false
                });
            }
        },
        async created(): Promise<void> {
            const {currentRequest} = await DataMarketingService.create(this.request);
            this.currentRequest = currentRequest;

            this.listenForMobileMenu();
            this.listenForMobileCriteria();

            if(this.currentRequest.status !== RequestStatus.STARTED)
                this.progress.lockPreviousSteps();
        },
        mounted(): void {
            this.progress.findStepInUrl(window.location.href);
        }
    })
</script>

<style scoped lang="scss">
    @import "../../../../sass/_variables.scss";

    .dm-wrapper {
        display: grid;
        grid-template-columns: 4fr 10fr 5fr;
        align-items: start;
        column-gap: 25px;
      
        &.wide {
            grid-template-columns: 11fr 4fr;
            
            @media #{$responsive-992} {
                grid-template-columns: 1fr;
            }
        }

        @media #{$responsive-992} {
            grid-template-columns: 1fr;
        }
      
      main {
        min-height: 50vh;
        max-height: 74vh;
        overflow-y: auto;
        overflow-x: hidden; 
      }

        aside {
            @media #{$responsive-992} {
                position: fixed;
                background: #fff;
                border-right: 0;
                width: 80%;
                left: 0;
                padding: 25px;
                z-index: 55;
                bottom: 12vh;
                overflow-y: scroll;
                height: 82vh;
                box-shadow: 2px 2px 5px #0000002e;
            }
        }
    }
</style>