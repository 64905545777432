
<div>
    <a v-if="!isLeadCreated"
       href=""
       class="mt-3 btn" :class="btnClass"
       v-on:click.prevent="sendRequest(package, type)"
       :data-gtm-cat="dataGtmCat"
       :data-gtm-action="dataGtmAction">
        <slot></slot>
    </a>
    <div v-else class="alert alert-success">
        <b>{{message}}</b>
    </div>
</div>
