﻿import Page from '../lib/Page';
import {elementInViewport} from './OnScrollAnimations';

interface Address {
    name: string;
    street: string;
    number: string;
    box: string;
    postcode: string;
    city: string;
}

export default (function () {
    Page.registerBehavior('.map', function (element) {
        const address: Address = {
            name: element.getAttribute('data-name') ?? "",
            street: element.getAttribute('data-street') ?? "",
            number: element.getAttribute('data-number') ?? "",
            box: element.getAttribute('data-box') ?? "",
            postcode: element.getAttribute('data-postcode') ?? "",
            city: element.getAttribute('data-city') ?? ""
        };

        if (isVisible(element))
            initializeMap(element, address);

        function isVisible(el: Element) {
            return !isInModalTemplate(el) && elementInViewport(el as HTMLElement);
        }

        function isInModalTemplate(el: Element) {
            for (; el !== null; el = el.parentElement as Element) {
                if (el.nodeName.toLowerCase() === 'cw-modal')
                    return true;
            }
            return false;
        }
    });

    function initializeMap(element: Element, address: Address) {
        var map = new google.maps.Map(
            element,
            {
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            }
        );

        var geocoder = new google.maps.Geocoder();
        geocoder.geocode(
            {
                'address': [address.street.removeBrackets(), address.number, address.postcode, address.city.removeBrackets()].join(' ')
            },
            function (results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    map.setCenter(results[0].geometry.location);

                    var marker = new google.maps.Marker({
                        map: map,
                        position: results[0].geometry.location,
                        title: address.name
                    });

                    var infowindow = new google.maps.InfoWindow({
                        content:
                            '<p>' +
                            '  <br>' +
                            '  <b>' + address.name + '</b>' +
                            '  <br><br>' +
                            address.street + ' ' + address.number + ' ' + address.box + '<br> ' +
                            address.postcode + ' ' + address.city +
                            '</p>'
                    });

                    google.maps.event.addListener(marker, 'click', function () {
                        infowindow.open(map, marker);
                    });
                } else {
                    element.classList.add('disabled');
                }
            }
        );
        var center = map.getCenter();
        google.maps.event.trigger(map, 'resize');
        map.setCenter(center);
    }
}());
