﻿import Vue from 'vue';
import Page from '../../lib/Page';
import Overview from './components/overview.vue';
import ScoreGraph from './components/score-graph.vue';
import { createPinia, PiniaVuePlugin } from 'pinia';

Vue.use(PiniaVuePlugin);


Page.registerVue('[vue-radar]', function (rootSelector) {
    new Vue({
        el: rootSelector,
        name: 'radar',
        components: {
            Overview,
            ScoreGraph
        },
        pinia: createPinia()
    });
});

