
<aside>
    <a href="" class="d-block d-lg-none position-absolute right-0 top-0 p-3 text-grey" @click.prevent="closeMobileCriteria">
        <i class="fas fa-times fa-lg"></i>
    </a>
    
    <credits-counter :amount="total" :label="translate('credits_counter_label')"></credits-counter>
    
    <div class="box d-none d-lg-block p-3 pt-4 mt-3 mt-lg-5 " v-if="shouldSeeCriteriaCounter">
        <h3 class="box-title">{{boxTitle}} </h3>
        <div>
            <span class="selected">{{displayValue|formatNumber}}</span> {{translate("companies")}}
        </div>
        <div class="mt-4" v-if="!isLastStep">
            <button class="btn btn-info btn-block" @click.prevent="nextStep()" :disabled="isNextStepDisabled">{{translate("btn_next")}} <i class="fas fa-angle-right ml-2"></i></button>
        </div>
        <div class="mt-3 text-center" v-if="!isLastStep && !isNextStepDisabled">
            <a class="link text-grey" href="" @click.prevent="goToLastStep()"><i class="fas fa-check-double mr-1"></i> {{translate("btn_finish_request")}}</a>
        </div>
    </div>

    <template v-if="isProspection">
        <div class="mt-3 mt-lg-5 box bg-white" v-if="hasSelections || hasExcludedVats">
            <div class="box-title">{{translate("criteria")}}</div>
            <div class="mt-2"></div>
            <div v-if="hasExcludedVats">
                <h3>{{translate("sidebar_exclude")}}</h3>
                <ul class="mb-4">
                    <li v-if="hasExcludedViaFile">
                        <a href="" @click.prevent="removeExclusionViaFile()" class="delete-link" :class="{'pe-none': isLocked}">
                            <i class="fas fa-times" v-if="!isLocked"></i> {{translate("via_file")}}
                        </a>
                    </li>
                    <li v-for="(request, idx) in excludedRequests" :key="'exclusion_' + idx">
                        <a href="" @click.prevent="removeExclusionViaRequest(request)" class="delete-link" :class="{'pe-none': isLocked}">
                            <i class="fas fa-times" v-if="!isLocked"></i> {{request.requestDate}} {{request.reference}}
                        </a>
                    </li>
                </ul>
            </div>
            <div v-for="(group, name) in groupedCriteria" :key="'cat_' + name">
                <div v-if="group.length > 0">
                    <h3>{{translate(name)}}</h3>
                    <ul class="mb-4">
                        <li v-for="(criterium, idx) in group" :key="'node_crit_' + idx">
                            <a href="" @click.prevent="removeCriterium(criterium)" class="delete-link" :class="{'pe-none': isLocked}">
                                <i class="fas fa-times" v-if="!isLocked"></i> {{formatCriterium(criterium)}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <p class="my-5 text-center" v-else>{{translate("no_selected_criteria")}}</p>

        <form v-if="!isPaid" :action="currentRequest.hideRequestLink" method="POST" class="text-center">
          <button class="no-style-btn btn-link mt-3 hover-underline" type="submit">
              <span class="text-grey "><i class="fas fa-trash mr-1"></i> {{translate("delete_request")}}</span>
              
          </button>
        </form>
    </template>
</aside>
