
<div>
    <div v-if="isLoading">
        <div class="py-5 text-center">
            <i class="fas fa-spinner spinning text-blue fa-2x"></i>
        </div>
    </div>
    <div v-else>
        <table class="data-table table table-striped mt-5">
            <thead>
                <tr>
                    <th colspan="3">

                    </th>
                    <th>
                        <input type="search" class="form-control" placeholder="filter op naam" v-model="filter.name"/>
                    </th>
                    <th>
                        <input type="search" class="form-control" placeholder="filter op tussenpersoon" v-model="filter.tussenPersoon"/>
                    </th>
                    <th>

                    </th>
                    <th>
                    <select v-model="filter.type" class="form-control mt-auto">
                        <option value="">Alle</option>
                        <option value="generic">Generiek</option>
                        <option value="custom">Op maat</option>
                    </select>
                    </th>
                    <th>
                    <select v-model="filter.customerType" class="form-control mt-auto">
                        <option value="">Alle</option>
                        <option value="prospect">Prospect</option>
                        <option value="customer">Klant</option>
                    </select>
                    </th>
                </tr>
                <tr>
                    <th width="30"><i class="fa fa-globe" title="Published?"></i></th>
                    <th width="30"><i class="fa fa-archive" title="Legacy?"></i></th>
                    <th width="140">Logo</th>
                    <th width="265">Software</th>
                    <th>Softwarehuis/Integrator</th>
                    <th>Gebruikers</th>
                    <th wdith="120">Type pakket</th>
                    <th width="120">Type integrator</th>
                    <th width="120">&nbsp;</th>
                </tr>
            </thead>
            <tbody :list="softwares" group="softwares" @start="drag=true" @end="onDragged" tag="tbody" is="draggable">
                <tr v-for="software in filteredSoftwares" :key="software.sortIndex" class="move" :class="{'yellow-row': software.label === SoftwareLabel.COMINGSOON, 'green-row': software.status === SoftwareStatus.PUBLISHED}">
                    <td>
                        <i 
                        class="fas fa-globe" 
                        :class="{'text-teal': software.status === SoftwareStatus.PUBLISHED, 'text-yellow': software.label === SoftwareLabel.COMINGSOON}" 
                        v-if="software.status !== SoftwareStatus.DRAFT"></i>
                    </td>
                     <td>
                        <i class="fas fa-archive text-grey" v-if="software.isLegacy" title="Legacy software"></i>
                    </td>
                    <td>
                        <div class="d-flex align-items-center justify-content-center" style="width: 100px;">
                            <img :src="software.logo" v-if="software.logo" class="img-fluid" style="max-height: 45px;"/> 
                        </div>
                    </td>
                    <td>
                        <b>{{software.name}}</b> <span class="text-grey" v-if="software.distributor"> by {{software.distributor}}</span>
                    </td>
                    <td>
                        <span v-if="software.tussenPersoon">
                            {{software.tussenPersoon.companyName}}
                        </span>
                    </td>
                    <td>
                        {{software.amountOfUsers}}
                    </td>
                    <td>
                        <span class="badge badge-warning badge-inverted" v-if="software.isCustom">
                            <i class="fas fa-tools mr-1"></i> op maat
                        </span>
                        <span class="badge badge-blue badge-inverted" v-else>
                            <i class="fas fa-box mr-1"></i> generiek
                        </span>
                    </td>
                    <td>
                        <span class="badge badge-warning badge-inverted" v-if="software.isProspect">
                            <i class="fas fa-search mr-1"></i> prospect
                        </span>
                        <span class="badge badge-blue badge-inverted" v-else>
                            <i class="fas fa-user mr-1"></i> klant
                        </span>
                    </td>
                    <td class="text-right" slot="footer">
                        <button @click.stop="editSoftware(software)" class="btn btn-info btn-sm"><i class="fas fa-pencil-alt"></i> Edit</button>
                        <button @click.stop="tryDelete(software)" class="btn btn-default btn-sm"><i class="fas fa-trash-alt"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
