﻿<template>
    <div class="row">
        <div class="col-md-12">
            <p>
                Companyweb is dé eerste met de lancering van een mobile app waarmee je nu ook onderweg weet met wie je zaken doet.
                Waar je ook bent, je hebt altijd onze up-to-date informatie bij de hand.
            </p>
            <p>
                De mobiele applicatie is toegankelijk voor IOS en Android toestellen en beschikbaar op de App Store en Google Play Store.
            </p>
            <div>
                <img src="/images/mobile-app/nl/5.jpg" alt="Mobile app" class="img-fluid" width="180"/>
                <img src="/images/mobile-app/nl/2.jpg" alt="Mobile app" class="img-fluid" width="180"/>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'feature-mobile-app'
    }
</script>