﻿import Vue from 'vue';
import Ajax from '../../lib/Ajax';
import Page from '../../lib/Page';
/*@ts-ignore*/
import GoogleLogin from 'vue-google-login';
import CwModal from '../shared/modal.vue';

export default (function () {
    Page.registerVue('#loginFormWithGoogle', function (rootSelector) {
        new Vue({
            el: rootSelector,
            components: {
                GoogleLogin,
                CwModal
            },
            data: {
                username: window.cw.email || window.cw.username || "",
                returnUrl: window.cw.returnUrl || '',
                digitPassCode: '',
                showAccountExistsMessage: window.cw.showAccountExistsMessage || false,
                hasSubmittedUsernameOrEmail: false,
                isMailResent: false,
                isLoading: false,
                error: '',
                modal: 'no-mail-received-modal',
                googleParams: {
                    client_id: window.cw.clientId
                },
                googleRenderParams: {
                    width: 350,
                    height: 40,
                    longtitle: true,
                    theme: 'dark',
                    scope: 'profile email'
                }
            },
            computed: {
                isDigitPassCodeFilled(): boolean {
                    return this.digitPassCode.length === 6;
                },
                isDigitPassCodeNotSixDigits(): boolean {
                    return this.digitPassCode.length !== 0 && this.digitPassCode.length !== 6;
                },
                usernameIsEmail(): boolean {
                    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.username.trim());
                },
                formAction(): string {
                    return this.usernameIsEmail ? "/account/login/passcode" : "/account/login/password";
                },
                hasError(): boolean {
                    return this.error !== '';
                }
            },
            watch: {
                digitPassCode(val): void {
                    this.digitPassCode = val.replace(/\D/g, '');
                }
            },
            methods: {
                openNoMailModal(): void {
                    this.$root.$emit('show-modal', this.modal);
                },
                redirectWhenLoggedIn(): void {
                    let timeout = 5000;
                    const timeoutIncrease = 5000;
                    const maxTimeout = 60000;
                    const maxPollingDuration = 60000 * 5;

                    function doPoll() {
                        Ajax.get(
                            '/ajax/account/getloginstatus',
                            {
                                now: new Date().getTime()
                            },
                            (response) => {
                                timeout += timeoutIncrease;

                                if (response.Data.isLoggedInAsMultiUser || timeout > maxPollingDuration)
                                    window.location.href = "/";
                                else
                                    setTimeout(doPoll, Math.min(timeout, maxTimeout));
                            },
                            (err) => console.error(err)
                        );
                    }

                    doPoll();
                },
                onSubmit(): void {
                    this.error = '';
                    if (!this.username)
                        return;

                    if (!this.hasSubmittedUsernameOrEmail) {

                        if (this.usernameIsEmail) {
                            this.isLoading = true;
                            Ajax.post(
                                '/ajax/account/login',
                                {
                                    email: this.username,
                                    returnUrl: this.returnUrl
                                },
                                () => {
                                    this.error = '';
                                    this.isLoading = false;
                                    this.hasSubmittedUsernameOrEmail = true;
                                    this.showEmailSuccessHandler();
                                },
                                (error) => {
                                    this.isLoading = false;
                                    this.error = error;
                                }
                            );
                        } else {
                            this.hasSubmittedUsernameOrEmail = true;
                        }
                    } else {
                        (this.$refs.form as HTMLFormElement).submit();
                    }
                },
                showEmailSuccessHandler(): void {
                    this.redirectWhenLoggedIn();
                    this.$nextTick(function () {
                        (this.$refs.passcode as HTMLInputElement).focus();
                    });
                },
                resendMail(): void {
                    this.isMailResent = false;

                    if (this.isLoading)
                        return;

                    this.isLoading = true;
                    Ajax.post(
                        '/ajax/account/login',
                        {
                            email: this.username,
                            returnUrl: this.returnUrl,
                            isRetry: true
                        },
                        () => {
                            this.isMailResent = true;
                            this.isLoading = false;
                            this.$root.$emit('hide-modal', this.modal);
                        },
                        (error) => {
                            console.error(error);
                            this.isMailResent = true;
                            this.isLoading = false;
                            this.$root.$emit('hide-modal', this.modal);
                        }
                    );
                },
                onGoogleSuccess(googleUser: any): void {
                    const email = this.username;
                    const id_token = googleUser.getAuthResponse().id_token;

                    this.loginWithGoogle(email, id_token, this.returnUrl);
                },
                onGoogleFailure(): any {

                },
                loginWithGoogle(emailAddress: string, googleTokenId: string, returnUrl = "") {
                    this.isLoading = true;
                    Ajax.post(
                        '/ajax/account/login-with-google',
                        {
                            emailAddress,
                            googleTokenId,
                            returnUrl
                        },
                        (response: AjaxResponse<any>) => {
                            this.isLoading = false;
                            let redirectUrl = response.Data.returnUrl;

                            if (redirectUrl)
                                window.location.href = redirectUrl;
                            else
                                window.location.reload();
                        },
                        (error) => {
                            this.isLoading = false;
                            this.error = error;
                        }
                    );
                }
            },
            mounted() {
                (this.$refs.username as HTMLInputElement).focus();
            }
        });
    });
}());
