﻿<template>
    <g>
        <path fill="transparent"
              class="kyc-edge"
              :stroke-width="strokeWidth"
              stroke-opacity="1"
              :stroke="strokeColor"
              :d="drawline"
              :stroke-dasharray="dashArray" style="pointer-events: none">
        </path>
        <g :transform="sharesCircleTransform" v-if="info.linkType === 'shareholder' || info.linkType === 'mandateholder-shareholder'">
            <circle class="kyc-link-type" :r="circleRadius" cx="0" cy="0" :stroke="sharesStrokeColor" fill="white"></circle>

            <text :transform="'translate(5, '+mainSharesYOffset+')'" fill="#7F90A0" font-size="12" text-anchor="end" font-weight="bold">
                <tspan v-if="shares.isKnown">
                    {{shares.whole}}
                </tspan>
                <tspan v-else>
                    ?
                </tspan>
            </text>
            <text v-if="shares.decimals" :transform="'translate(5, '+decimalSharesYOffset+')'" fill="#7F90A0" font-size="9" text-anchor="end">,{{shares.decimals}}</text>
            <text v-if="shares.isKnown" :transform="'translate(6, '+mainSharesYOffset+')'" fill="#7F90A0" font-size="10" text-anchor="start" font-weight="bold">%</text>
        </g>
    </g>
</template>

<script lang="ts">
    import Vue from 'vue';
    import { Info, Position, Shares, Coordinate } from './types';


    export default Vue.extend({
        name: 'ubo_edge',
        props: {
            from: Object as () => Position,
            to: Object as () => Position,
            info: Object as () => Info,
            current: Number,
            max: Number,
            nodeHeight: Number,
            rowHeight: Number,
            colWidth: Number,
            circleRadius: Number,
            arrowHeight: Number,
            hoveredVat: Number as () => number | null
        },
        data() {
            return {
                doesNotGoDownward: false as boolean
            };
        },
        watch: {
           doesNotGoDownward(val): void {
               if(val) this.$root.$emit('circular');
           }
        },
        computed: {
            shouldGlow(): boolean {
                return this.hoveredVat === this.info?.fromVat;  
            },
            strokeColor(): string {
                return this.shouldGlow ? '#03a9f4' : (this.doesNotGoDownward ? 'red' : '#7F90A0');
            },
            sharesStrokeColor(): string {
                return this.shouldGlow ? '#03a9f4' : '#f1f1f1';
            },
            shares(): Shares {
                if (!this.info.shares)
                    return {
                        isKnown: false,
                        whole: 0,
                        decimals: 0
                    };

                const regex = /(\d+)[,]?(\d*)%/;

                return {
                    isKnown: true,
                    whole: +this.info.shares.replace(regex, '$1'),
                    decimals: +this.info.shares.replace(regex, '$2')
                };
            },
            mainSharesYOffset(): number {
                return this.shares.decimals ? 2 : 4;
            },
            decimalSharesYOffset(): number {
                return this.mainSharesYOffset + 8;
            },
            siblingXOffset(): number {
                const center = (this.max + 1) / 2;
                const delta = this.current - center;

                return delta * 40;
            },
            sharesCircleTransform(): string {
                const x = (this.from.column - 1) * this.colWidth + this.colWidth / 2 + this.siblingXOffset;
                let y = this.from.row * this.rowHeight;

                // prevents cutting circle in half
                if (y === 0)
                    y = this.circleRadius!;

                return 'translate(' + x + ', ' + y + ')';
            },
            strokeWidth(): number {
                switch (this.info.linkType) {
                    case "shareholder":
                        return 1;
                    case "mandateholder-shareholder":
                        return 1;
                    case "mandateholder":
                        return 2;
                    default:
                        return 1;
                }
            },
            dashArray(): string {
                switch (this.info.linkType) {
                    case "shareholder":
                        return "7 6";
                    case "mandateholder-shareholder":
                        return "250 0";
                    case "mandateholder":
                        return "2 5";
                    default:
                        return "";
                }
            },
            drawline(): string {
                const showSharesCircle = this.info.linkType === 'shareholder' || this.info.linkType === 'mandateholder-shareholder'
                const start = this.calcPosition(this.from.row, this.from.column, true, showSharesCircle);
                const end = this.calcPosition(this.to.row, this.to.column, false, showSharesCircle);

                const spansOnlyOneRow = this.to.row - this.from.row === 1;
                const spansMultipleColumns = Math.abs(this.to.column - this.from.column) > 0;
                const doesNotGoDownward = this.doesNotGoDownward;
                
                const xDelta = end.x - start.x;
                const yDelta = end.y - start.y;

                let cp1: Coordinate = {
                    x: start.x,
                    y: spansOnlyOneRow && spansMultipleColumns
                        ? end.y
                        : start.y + yDelta / 3
                };

                let cp2: Coordinate = {
                    x: end.x,
                    y: spansOnlyOneRow && spansMultipleColumns
                        ? start.y
                        : end.y - yDelta / 3
                };

                // if the edge is not going downward,
                // it is not legally correct and will be shown red
                // as well as its controlPoints get redefined to be more curvy.
                if(doesNotGoDownward) {
                    // to prevent a straight line upward.
                    const fixedXOffset = start.x === end.x ? -50 : 0;

                    cp1 = {
                        x: start.x + (xDelta / 3) + fixedXOffset,
                        y: start.y + Math.abs(yDelta / 1.5)
                    };

                    cp2 = {
                        x: end.x - (xDelta / 3) + fixedXOffset,
                        y: end.y - Math.abs(yDelta / 1.5)
                    };
                }


                const isRootEdge = this.from.row === 0;
                if (isRootEdge)
                    return `M ${start.x} ${start.y} ${end.x} ${end.y}`;
                
                return `M ${start.x} ${start.y} 
                        C ${cp1.x} ${cp1.y} ${cp2.x} ${cp2.y} 
                          ${end.x} ${end.y}`;
            },
            sharesTransform(): string {
                const startVector = this.calcPosition(this.from.row, this.from.column, true);

                return 'translate(' + (startVector.x + 5)  + ',' + startVector.y + ')';
            }
        },
        methods: {
            calcPosition(row: number, col: number, isFromVector: boolean, showSharesCircle?: boolean): Coordinate {
                const defaultX = (col -1) * this.colWidth + this.colWidth / 2;
                const defaultY = row * this.rowHeight - this.nodeHeight;
                const xOffset = isFromVector ? this.siblingXOffset : 0;
                const yOffset = isFromVector ? this.nodeHeight : -1 * this.arrowHeight;
                const circleYOffset: number = showSharesCircle && isFromVector ? this.circleRadius! : 0;

                const x = defaultX + xOffset;
                const y = defaultY + yOffset + circleYOffset;
                
                return {
                    x,
                    y
                };
            }
        },
        mounted(): void {
            this.doesNotGoDownward = this.from.row >= this.to.row;
        }
    });
</script>