
<div>
    <h2 class="mb-4"><b>{{translate("sidebar_legal_form")}}</b></h2>

    <div class="row">
         <div class="col-md-6">
            <select-tree 
                :isParentSelected="false"
                :criteria="treeData.juridischeVorm" 
                :type="ProspectieDataSelectionCriteriumType.JURIDISCHEVORM"></select-tree>
        </div>
    </div>
</div>
