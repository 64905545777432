﻿<template>
    <div class="row">
        <div class="col-md-12">
           <p>
               Met deze feature ziet in in alle lijstweergaves meteen de score van het respectievelijke bedrijf.<br />
               U krijgt onmiddellijk een idee van de algemene gezondheid van die lijst van bedrijven.
           </p>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'feature-linked-barometers'
    }
</script>