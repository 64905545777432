﻿import Page from '../lib/Page';

export default (function () {
    Page.registerBehavior('.tr-anchor', function (el) {
        const anchor = el as HTMLAnchorElement;

        const tr = anchor.closest('tr');
        const target = anchor.getAttribute('target');
        const href = anchor.getAttribute('href');
        const action = anchor.getAttribute('action');
        const isDisabled = !!anchor.getAttribute('disabled');
            
        if(!tr)
            return;

        if ((!href && !action) || isDisabled) {
            tr.classList.remove('pointer');
            return;
        } else {
            tr.classList.add('pointer');
        }
        

        tr
            .addEventListener('mouseup', function (ev: MouseEvent) {
                const isForwardButton = ev.which === 4;
                const isBackButton = ev.which === 5;
                const isRightClick = ev.which === 3;

                const currentTarget = ev.target as HTMLElement;

                const isNotLeftClick = isRightClick || isForwardButton || isBackButton;
                const rowTargetIsNotALink = anchor.classList.contains('no-link');
                const clickedElementHasItsOwnBehaviour = currentTarget?.classList.contains('excluded-from-click') || isAnchorOrContainedInAnchor(currentTarget);

                if (isNotLeftClick || rowTargetIsNotALink || clickedElementHasItsOwnBehaviour)
                    return;

                ev.preventDefault();
                const middleMouseClick = ev.type === 'mouseup' && ev.which === 2;

                if (middleMouseClick || target === "_blank")
                    return openInNewTab(anchor);

                const controlKeyIsDown = ev.ctrlKey || ev.metaKey;

                if (controlKeyIsDown || target === "_blank")
                    openInNewTab(anchor);
                else
                    openInSameTab(anchor);
            });
    });

    function openInNewTab(anchor: HTMLAnchorElement) {
        const href = anchor.getAttribute('href')!;
        const method = anchor.getAttribute('method');
    
        if (method === 'post')
            anchor.click();
        else
            window.open(href, '_blank');
    }

    function openInSameTab(anchor: HTMLAnchorElement) {
        const href = anchor.getAttribute('href')!;
        const method = anchor.getAttribute('method');
    
        if (method === 'post')
            anchor.click();
        else
            window.location.href = href;
    }

    function isAnchorOrContainedInAnchor(el: HTMLElement)
    {
        for (; el.parentElement != null; el = el.parentElement)
            if (el.tagName.toLowerCase() == 'a')
                return true;
        return false;
    }
}());
