import Enumeration from '../../types/global/enumeration';

export enum PricingRowType {
    NUMBER,
    TEXT,
    DROPDOWN,
    CHECKMARK,
    CHECKBOX
}

export class Package extends Enumeration<Package>() {
    public integrationPrice: number = 0;

    private constructor(
        public readonly name: string,
        public readonly price: number
    ) {
        super(name);
    }

    public get totalPrice(): number {
        return this.price + this.integrationPrice;
    }

    public static readonly Basic = new Package("Basic", 715);
    public static readonly Standard = new Package("Standard", 960);
    public static readonly Premium = new Package("Premium", 1205);
    public static readonly Premium500 = new Package("Premium 500", 1450);
    public static readonly PremiumInternational = new Package("Premium International", 1695);
    public static readonly PremiumCompliance = new Package("Premium Compliance", 1695);
    public static readonly PremiumData = new Package("Premium Data", 1450);
    
    public static get defaultPackages(): Package[] {
        return this.values;
    }

    public get urlName(): string {
        return this.name.toKebabCase();
    }
    
    public get propertyName(): string {
        return this.name.toCamelCase();
    }
};

export class PricingColumn extends Enumeration<PricingColumn>() {
    private constructor(
        public readonly name: string,
        public readonly selectablePackages: Package[]
    ) {
        super(name);
        const t = Package.enumerators
    }

    public selectedPackage: Package = this.getPackageFromUrl();
    
    private getPackageFromUrl(): Package {
        const urlSegements = window.location.pathname.split("/");
        const lastSegment = urlSegements[urlSegements.length - 1];

        return this.selectablePackages.find(pack => pack.urlName === lastSegment) ?? this.selectablePackages[0];
    }

    public static readonly One = new PricingColumn("Thematic", [Package.PremiumInternational, Package.PremiumCompliance, Package.PremiumData]);
    public static readonly Two = new PricingColumn("Premium", [Package.Premium, Package.Premium500]);
    public static readonly Three = new PricingColumn("Standard", [Package.Standard]);
    public static readonly Four = new PricingColumn("Basic", [Package.Basic]);
}

export type ValueType = number | string | boolean | number[] | boolean[];

export class PricingRow<TValue extends ValueType> extends Enumeration<PricingRow<ValueType>>() {
    private constructor(
        public readonly type: PricingRowType,
        public readonly name: string,
        public readonly hasModal: boolean,
        private readonly basic: TValue,
        private readonly standard: TValue,
        private readonly premium: TValue,
        private readonly premium500: TValue,
        private readonly premiumInternational: TValue,
        private readonly premiumCompliance: TValue,
        private readonly premiumData: TValue,
    ) {
        super(name);
    }

    
    public getColumnValue(pack: Package): ValueType {
        return (this as any)[pack.propertyName];
    }

    public isVisible(isSales: boolean): boolean {
        if (this === PricingRow.Integration)
            return isSales;
        else
            return true;
    }

    public static readonly Hits: PricingRow<number> = 
        new PricingRow(PricingRowType.NUMBER, "hits_per_week", false, 250, 250, 250, 250, 250, 250, 250);

    public static readonly BedrijvenInOpvolging: PricingRow<number[]> = 
        new PricingRow(PricingRowType.DROPDOWN,"bedrijven_in_opvolging", false, [50], [50], [250, 500], [250, 500], [250], [250], [250]);

    public static readonly Rapporten: PricingRow<string> = 
        new PricingRow(PricingRowType.TEXT,"rapporten", true, 'basisrapporten', 'standaardrapporten', 'premiumrapporten', 'premiumrapporten', 'premiumrapporten', 'premiumrapporten', 'premiumrapporten');

    public static readonly BedrijfsInformatie: PricingRow<boolean> = 
        new PricingRow(PricingRowType.CHECKMARK,"bedrijfsinformatie", true, true, true, true, true, true, true, true);

    public static readonly BetalingsErvaring: PricingRow<boolean> = 
        new PricingRow(PricingRowType.CHECKMARK,"betalingservaring", true, true, true, true, true, true, true, true);

    public static readonly Bedrijfsleiding: PricingRow<boolean> = 
        new PricingRow(PricingRowType.CHECKMARK,"bedrijfsleiding", true, true, true, true, true, true, true, true);

    public static readonly ZoekenOpBedrijf: PricingRow<boolean> = 
        new PricingRow(PricingRowType.CHECKMARK,"zoeken_op_bedrijf", false, true, true, true, true, true, true, true);

    public static readonly ZoekenOpBedrijfsleider: PricingRow<boolean> = 
        new PricingRow(PricingRowType.CHECKMARK,"zoeken_op_bedrijfsleider", false, false, true, true, true, true, true, true);

    public static readonly ZoekenOpAdres: PricingRow<boolean> = 
        new PricingRow(PricingRowType.CHECKMARK,"zoeken_op_adres", false, false, false, true, true, true, true, true);

    public static readonly Screening: PricingRow<boolean> = 
        new PricingRow(PricingRowType.CHECKMARK,"screening", false, false, false, true, true, true, true, true);

    public static readonly Timeline: PricingRow<boolean> = 
        new PricingRow(PricingRowType.CHECKMARK,"timeline", false, false, false, true, true, true, true, true);

    public static readonly MobileApp: PricingRow<boolean> = 
        new PricingRow(PricingRowType.CHECKMARK,"mobile_app", true, false, false, true, true, true, true, true);

    public static readonly InternationalReports: PricingRow<boolean> = 
        new PricingRow(PricingRowType.CHECKMARK,"internationale_rapporten", false, false, false, false, false, true, false, false);

    public static readonly KYC: PricingRow<boolean> = 
        new PricingRow(PricingRowType.CHECKMARK,"kyc_rapport", true, false, false, false, false, false, true, false);

    public static readonly Integration: PricingRow<boolean> =
        new PricingRow(PricingRowType.CHECKBOX,"integraties", false,  false, true, true, true, true, true, true);
    
    public static readonly DataMarketing: PricingRow<boolean> =
        new PricingRow(PricingRowType.CHECKMARK,"data_marketing", true,  false, false, false, false, false, false, true);

}