
<div class="pricing-cell" :class="{'d-none d-sm-inline-flex': !isSelected, 'selected': isSelected}">
    <div v-if="type === PricingRowType.TEXT">{{translate(value)}}</div>
    <div v-else-if="type === PricingRowType.CHECKMARK"><i v-if="value" class="fas fa-check text-teal"></i></div>
    <div v-else-if="type === PricingRowType.NUMBER">{{value}}</div>
    <div v-else-if="type === PricingRowType.DROPDOWN">
        <span v-if="value.length === 1">{{value[0].toString()}}</span>
        <select v-else v-model="selectedValue" class="form-control" style="width: 110px;">
            <option v-for="v in value" :key="v" :value="v">{{v}}</option>
        </select>
    </div>
    <div v-else-if="type === PricingRowType.CHECKBOX">
        <input :id="'integratie'+currentPackage.propertyName" type="checkbox" v-if="value" v-model="hasIntegratie" class="form-check-input"/>
        <label :for="'integratie'+currentPackage.propertyName">&nbsp;</label>
    </div>
</div>
