
<div>
    <div class="dm-wrapper" :class="{'wide': waitingOrFinished}">
        <sidebar :progress="progress" :title="translate('dm_option_prospecting_title')" v-if="!waitingOrFinished" :class="{'d-none d-lg-block': !mobileOpen, 'd-block': mobileOpen}"></sidebar>
        <main v-if="currentRequest" class="pb-5 pr-3 pb-dm-0">
            <warning-assistent></warning-assistent>
            <keep-alive>
                <component :is="activeStep.label"  :progress="progress"></component>
            </keep-alive>
        </main>
        <main v-else>
            <div class="text-center py-5">
                <i class="fas fa-spinner spinning text-blue fa-2x"></i>
            </div>
        </main>
        <result-counter 
            v-if="currentRequest" 
            :progress="progress" 
            :boxTitle="translate('uw_selectie')" 
            class="d-none d-lg-block"
            :class="{'d-none d-lg-block': !mobileCriteriaShown, 'd-block': mobileCriteriaShown}"></result-counter>
        <mobile-menu v-if="currentRequest && !waitingOrFinished"  :progress="progress"></mobile-menu>
    </div>

</div>
