﻿import _ from 'lodash';
import Ajax from '../lib/Ajax';
import Page from '../lib/Page';

export default (function () {
    Page.registerBehavior('#dashboardActivityLog li', function (link) {
        (link as HTMLLIElement).addEventListener('contextmenu', (ev: Event) => {
            ev.preventDefault();
            const ctxMenu: Element = link.getElementsByClassName("ctxMenu")[0];
            if (!ctxMenu)
                return;

            (link as HTMLLIElement).style.backgroundColor = "#f3f3f3";
            (ctxMenu as HTMLElement).style.display = "block";
            (ctxMenu as HTMLElement).style.left = 20 + "px";
            (ctxMenu as HTMLElement).style.top = 30 + "px";

            (ctxMenu as HTMLElement).addEventListener("click", function () {
                const vat = this.dataset.vat;
                const personId = this.dataset.personid;

                Ajax.post(
                    '/ajax/activity-log/remove-log',
                    {
                        vat: vat,
                        personId: personId
                    },
                    () => {
                        location.reload();
                    },
                    (error: string) => {
                        console.error(error);
                    }
                );
            });
        }, false);

        (link as HTMLLIElement).addEventListener('mouseleave', () => {
            const ctxMenu: Element = link.getElementsByClassName("ctxMenu")[0];
            if (!ctxMenu)
                return;

                (link as HTMLLIElement).style.backgroundColor = "#ffffff";
                (ctxMenu as HTMLElement).style.display = "";
                (ctxMenu as HTMLElement).style.left = "";
                (ctxMenu as HTMLElement).style.top = "";
        }, false);
    });
}());
