<template>
    <div class="barometer-wrap" :class="className">
        <template v-if="!noAnimate">
            <img class="d-print-none" src="/images/barometer-grijs.svg" v-if="isFailliet" />
            <img class="d-print-none" src="/images/barometer-zonder-pijl.svg" v-else/>
            <img src="/images/barometer-pijl.svg" class="arrow d-print-none" :class="{'keep-rotating': isDemo}" id="barometer-arrow" :style="transformArrow" v-if="this.hasScore || this.isDemo"/>
            <div class="count d-print-none" id="counter" v-html="label" :style="bgColor"></div>
        </template>
        <img v-if="!isDemo" :src="'/images/barometer/big/' + imageName" class="d-print-block" :class="{'d-none': !noAnimate}"/>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        name: 'barometer',
        props: {
            isDemo: Boolean,
            className: String,
            score: Number,
            noAnimate: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                transformArrow: 'transform: rotate(0deg)',
                bgColor: '',
                label : '',
                imageName: this.className + '.png'
            }
        },
        computed:{
            isFailliet(): boolean {
                return this.className === 'failliet';
            },
            hasScore(): boolean {
                return this.score !== undefined;
            }
        },
        methods: {
            animate(): void {
                const id = setInterval(frame, 12);
                let step = -5;
                const that = this;

                function frame() {
                    if (step >= that.score) {
                        clearInterval(id);
                        return;
                    }

                    that.updateArrowRotation(step);
                    that.updateCounterBackgroundColor(step);
                    that.updateCounterText(step);

                    step = step + 0.049;
                }
            },
            updateArrowRotation(step: number): void {
                const angle = (step + 5) * 18;
                this.transformArrow = 'transform: rotate(' + angle + 'deg)';
            },
            updateCounterBackgroundColor(step: number): void {
                let bgColor;

                if (step < -3) {
                    bgColor = '#F44336';
                } else if (step < -1) {
                    bgColor = '#F9821E';
                } else if (step > 3) {
                    bgColor = '#4CAF50';
                } else if (step > 1) {
                    bgColor = '#94B633';
                } else {
                    bgColor = '#f1d01c';
                }

                this.bgColor = 'background-color: ' + bgColor;
            },
            updateCounterText(step: number): void {
                this.label = step.toFixed(1).replace('.', ',');
            },
            setLabel(): void {
                if(this.hasScore)
                    this.label = this.score.toFixed(1).toString().replace('.', ',')
                else if (this.isFailliet)
                    this.label = '<i class=\"fas fa-gavel\"></i>';
                else if (this.isDemo)
                    this.label = '?';
                else
                    this.label = this.className;
            },
            setBGColor(): void {
                if(this.isDemo)
                    this.bgColor = 'background-color: #808080';
            }
        },
        mounted() {
            this.setLabel();
            this.setBGColor();

            if(this.hasScore && !this.isDemo)
                this.animate();
        }
    })
</script>

<style scoped lang="scss">
  @keyframes demoRotation {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .arrow.keep-rotating {
    animation-name: demoRotation;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

</style>