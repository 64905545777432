﻿import _ from 'lodash';
import Page from '../lib/Page';
import Chart from 'chart.js';

export default (function () {

    Page.registerBehavior('.barchart', function (element) {
        initializeBarChart(element);
    });
    type Data = {
        [key: string]: string
    }
    function initializeBarChart(el: Element) {
        const datasets: any[] = [];
        const unit = el.getAttribute('data-unit');
        const stacked = (el.getAttribute('data-stacked')||'').toLowerCase() === 'true';
        const sortLabelsDescending = el.getAttribute('data-x-sort') ===' desc';
        
        for (let i = 1; true; i++) {
            const rawdata = el.getAttribute("data-set-" + i + "-data");
            if (!rawdata) break;

            const label = el.getAttribute("data-set-" + i + "-label");

            const data = _.reduce(rawdata.split(';'), function (acc: Data, datum: string) {
                const pair = datum.split(':') as string[];
                const key = pair[0];
                const value = pair[1];

                acc[key] = value;

                return acc;
            }, {});
            datasets.push({ label: label, data: data });
        }

        let labels = _.flatMap(datasets, function (dataset) {
            return _.keys(dataset.data);
        });

        labels = _.sortedUniq(_.flatMap(datasets, function (dataset) {
            return _.keys(dataset.data);
        }).sort());

        if (sortLabelsDescending)
            labels = _.reverse(labels);

        const canvas = document.createElement("canvas");
        const height = window.outerWidth <= 575 ? 320 : 450;
        const width = window.outerWidth <= 575 ? 320 : 550;
        canvas.setAttribute('height', height.toString());
        canvas.setAttribute('width', width.toString());
        el.parentNode!.appendChild(canvas);

        let formatValue: Function = (v: number): string => v.toString()
        if (unit == 'euro')
            formatValue = formatEuro;
        if (unit == 'ratio')
            formatValue = formatRatio;

        const colors = getChartColorSeries();
        
        new Chart(canvas, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: _.map(datasets, function (dataset) {
                    return _.merge(
                        {
                            label: dataset.label,
                            data: labels.map(function (label) { return dataset.data[label]||undefined }),
                            borderWidth: 1,
                            maxBarThickness: 30
                        },
                        colors.next()
                    );
                })
            },
            options: {
                legend: {
                    display: datasets.length > 0,
                    position: 'bottom',
                    onHover: function (e: Event) {
                        (e.target as HTMLElement).style.cursor = 'pointer';
                    }
                },
                hover: {
                    onHover: function (e: Event) {
                        var point = this.getElementAtEvent(e);
                        if (point.length) (e.target as HTMLElement).style.cursor = 'pointer';
                        else (e.target as HTMLElement).style.cursor = 'default';
                    }
                },
                scales: {
                    xAxes: [{
                        stacked: stacked
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            callback: function (value, index, values) {
                                return formatValue(value);
                            }
                        },
                        stacked: stacked
                    }]
                },
                tooltips: {
                    callbacks: {
                        label: function (params) {
                            return datasets[params.datasetIndex!].label + ': ' + formatValue(params.yLabel);
                        }
                    }
                },
                responsiveAnimationDuration: 0,
                animation: { duration: 0 },
                maintainAspectRatio: true,
                responsive: false
            }
        });
    }


    function formatEuro(value: number): string {
        value = Math.round(value);
        const sign = value < 0 ? '-' : '';
        let chunks = [];
        value = Math.abs(value);

        while (value >= 1000) {
            chunks.unshift(value.toString().substr(-3))
            value = Math.floor(value / 1000);
        }
        chunks.unshift(value.toString())

        return '\u20AC ' + sign + chunks.join('.');
    }

    function formatRatio(value: number): string {
        const left = Math.round(value);
        const right = Math.round((value - Math.floor(value)) * 100);

        return left + '.' + (right < 10 ? right + "0" : right);
    }

    function getChartColorSeries() {
        var current = 0,
            colors = [
                {
                    backgroundColor: '#73a6d7',
                    borderColor: '#73a6d7'
                },
                {
                    backgroundColor: 'rgba(232, 49, 49, 0.6)',
                    borderColor: 'rgba(232, 49, 49, 1)'
                },
                {
                    backgroundColor: 'rgba(255, 183, 28, 0.6)',
                    borderColor: 'rgb(255, 183, 28)'
                },
                {
                    backgroundColor: 'rgba(22, 200, 110, 0.6)',
                    borderColor: 'rgb(22, 200, 110)'
                },
                {
                    backgroundColor: 'rgba(194, 21, 186, 0.6)',
                    borderColor: 'rgb(194, 21, 186)'
                },
                {
                    backgroundColor: 'rgba(255, 131, 28, 0.6)',
                    borderColor: 'rgb(255, 131, 28)'
                },
                {
                    backgroundColor: 'rgba(43, 54, 197, 0.6)',
                    borderColor: 'rgb(43, 54, 197)'
                }
            ],
            length = colors.length;
        return {
            next: function () {
                return colors[current++%length];
            }
        }
    }

}());
