﻿<template>
    <div class="barometer-icon" :class="className" :score="score">
        <span v-if="!!score">{{scoreFormatted}}</span>
    </div>
</template>

<script>
    export default {
        name: 'html-barometer-icon',
        props: {
            score: String | Number,
            className: String,
            scoreFormatted: String | Number
        }
    };
</script>