import _ from 'lodash';

const behaviorDefinitions: BehaviorDefinition[] = [];

class BehaviorDefinition {
    private selector: string;
    private behavior: (element: Element) => void

    constructor(
        selector: string, 
        behavior: (element:Element) => void
    ) {
        this.selector = selector;
        this.behavior = behavior;
    }

    apply = (element: Element | Document): void => {
        _.forEach(
            element.querySelectorAll(this.selector),
            el => {
                this.behavior(el);
            }
        );
    }
}

export default {
    register: (pageName: string, f: Function): void => {
        if (document.body.classList.contains(pageName))
            f();
    },
    registerVue: (
        selector: string, f: (rootSelecter: string | Element) => void): void => {
        if (document.querySelector(selector))
            f(selector);
    },
    registerBehavior: (selector: string, behavior: (element: Element) => void): void => {
        const definition:BehaviorDefinition = new BehaviorDefinition(selector, behavior);
        definition.apply(document);
        behaviorDefinitions.push(definition);
    },
    applyBehaviors: (selector: string | Element): void => {
        if(typeof  selector != 'string') {
            _.forEach(
                behaviorDefinitions,
                definition => definition.apply(selector)
            );
        } else{
            if(document.querySelector(selector) !== null) {
                _.forEach(
                    behaviorDefinitions,
                    definition => definition.apply(document.querySelector(selector)!)
                );
            }
        }
    }
};