
<div>
    <label>{{translate("ondergrens")}}</label>
    <datepicker v-model="criteria._value.lowerBound"
                :language="localeNl"
                :bootstrap-styling="true"
                :monday-first="true"
                initial-view="year"
                format="dd/MM/yyyy"
                calendar-button
                calendar-button-icon="fa fa-calendar"
                :disabled-dates="{from: criteria._value.upperBound}"
                :full-month-name="true"
                @selected="onSelectLowerBound">
    </datepicker>

    <label class="mt-4">{{translate("bovengrens")}}</label>
    <datepicker v-model="criteria._value.upperBound"
                :language="localeNl"
                :bootstrap-styling="true"
                :monday-first="true"
                initial-view="year"
                format="dd/MM/yyyy"
                calendar-button
                calendar-button-icon="fa fa-calendar"
                :disabled-dates="{to: criteria._value.lowerBound}"
                :full-month-name="true"
                @selected="onSelectUpperBound">
    </datepicker>
</div>
