<template>
    <div>
        <h2 class="mb-4"><b>{{translate("sidebar_exclude")}}</b></h2>
        <div class="row">
            <div class="col-md-12">
                <h3>{{translate("vats")}}</h3>
                <p>
                    {{translate("exclude_desc")}}
                </p>

            </div>
            <div class="col-md-6 text-center">
                <template v-if="isLoading"><i class="fas fa-spinner spinning text-blue"></i></template>
                <btw-file-input v-else :required="false" :ask-for-references="false" :max-number-of-vats="100000" @input="onInput"></btw-file-input>
            </div>
            <div class="col-md-6 mt-4 mt-md-0">
                <div class="form-group">
                    <label for="selecties">{{translate("former_requests")}}</label>
                    <checkbox-dropdown v-model="selectedFormerRequests"></checkbox-dropdown>
                </div>
            </div>
        </div>

         <div class="row mt-5">
            <div class="col-md-6">
                <h3>{{translate("phone")}}</h3>
                <div class="form-group">
                    <input type="checkbox" id="phone-only" class="form-check-input" @change="setOnlyTel" v-model="form.onlyTel">
                    <label for="phone-only">{{translate("only_phone")}}</label>
                </div>
            </div>
         </div>
    </div>
</template>

<script lang="ts">
    import Translate from '../../../mixins/translate';
    import mixins from 'vue-typed-mixins';
    import DataMarketingService, { Request } from '../../dataMarketingService';
    import Progress from '../../progress';
    import BtwFileInput from '../../../shared/btw-file-input.vue';
    import CheckboxDropdown, { CheckboxRow } from '../../../shared/checkbox-dropdown.vue';
    import { ProspectieDataSelectionCriteriumType } from '../../../../types/dto/ProspectieDataSelectionCriteriumType';
    import _ from 'lodash';

     export default mixins(Translate).extend({
        name: 'dm-exclude',
        components: {
            BtwFileInput,
            CheckboxDropdown
        },
        props: {
            progress: Object as () => Progress,
        },
         data() {
             return {
                 isLoading: false,
                 form: {
                     onlyTel: false,
                 }
             }
        },
        computed: {
            currentRequest(): Request{
                return DataMarketingService.getInstance().currentRequest;
            },
            selectedFormerRequests: {
                get(): CheckboxRow[] {
                    return this.currentRequest.formerRequests.sort((a,b) => b.requestDate - a.requestDate).map(request => ({
                        id: request.id,
                        label: request.requestDate + ': ' + request.reference,
                        isChecked: !!this.currentRequest.excludedRequestIds.find(id => id === request.id)
                    }));
                },
                async set(newSelections: CheckboxRow[]) {
                    const selectedIds = newSelections.filter(v => v.isChecked).map(v => v.id);

                    await this.currentRequest.setExcludesViaRequest(selectedIds);
                }
            }
         }, 
         methods: {
            async setOnlyTel(event: Event) {
                await this.currentRequest!.select(1, ProspectieDataSelectionCriteriumType.METTELEFOON, (event.target as HTMLInputElement).checked);
                await this.currentRequest.updateSummary();
            },
            async onInput(emitResult: VatInputEmit) {
                this.isLoading = true;
                await this.currentRequest.addExclusionViaFile(emitResult);
                this.isLoading = false;
            }
         },
         mounted() {
             this.form.onlyTel = _.some(this.currentRequest.selectedCriteria, criterium => criterium.type === ProspectieDataSelectionCriteriumType.METTELEFOON && criterium.id === 1);
         }
    });
</script>

<style scoped>

</style>