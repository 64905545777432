﻿<template>
    <div>
        <canvas ref="canvas" :height="height+'px'" :width="width + 'px'"></canvas>
    </div>
</template>

<script lang="ts">
    import Translate from '../../vue/mixins/translate';
    import mixins from 'vue-typed-mixins';
    import { ChartType } from '../../behaviors/Chart';
    import Chart from 'chart.js';
    import { Color, YearData } from './types';
    import Option from '../../types/global/option';
    
    interface ResulatetenRekeningData {
        winst: YearData,
        brutomarge: YearData,
        omzet: YearData
    }
    
    export default mixins(Translate).extend({
        name: 'resultatenrekening',
        data() {
            return {
                chartData: window.cw.resultatenrekeningData as ResulatetenRekeningData
            };
        },
        mounted() {
            this.drawChart();  
        },
        computed: {
            isMobile(): boolean {
                return window.outerWidth <= 500;
            },
            height(): number {
                return this.isMobile ? 320 : 450
            },
            width(): number {
                return this.isMobile ? 320 : 450
            }
        },
        methods: {
            formatNumber(input: string | number | undefined): string {
                return '€ ' + (input ?? '').toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            drawChart() {
                const canvas = this.$refs.canvas as HTMLCanvasElement;
                const rawData = this.chartData;
                const showOmzet: boolean = Object.values(rawData.omzet).reduce((total: number, yearOmzet: string) => {
                    return total + +yearOmzet
                },0) > 0;
                 const data = {
                     labels: Object.keys(rawData.winst),
                     datasets: [
                         {
                             label: this.translate('winst_verlies_van_het_boekjaar'),
                             data: Object.values(rawData.winst).map(v => v ?? 0),
                             backgroundColor: Color.YELLOW,
                             maxBarThickness: 30,
                             stack: '1'
                         },
                         {
                             label: this.translate('brutomarge'),
                             data: Object.values(rawData.brutomarge).map(v => v ?? 0),
                             backgroundColor: Color.INDIGO,
                             maxBarThickness: 30,
                             stack: '2'
                         }
                     ]
                 };
                 
                 if(showOmzet)
                     data.datasets.push({
                         label: this.translate('omzet'),
                         data: Object.values(rawData.omzet).map(v => v ?? 0),
                         backgroundColor: Color.BLUE,
                         maxBarThickness: 30,
                         stack: '3'
                     });
                 
                const instance = this;
                new Chart(canvas, {
                    type: ChartType.BAR,
                    data: data,
                    options: {
                        title: {
                            display: true,
                            text: this.translate('jaar_rekening_resultaten_rekening'),
                            fontSize: 15
                        },
                        animation: {
                            duration: 0
                        },
                        maintainAspectRatio: true,
                        responsive: false,
                        tooltips: {
                            callbacks: {
                                label(tooltipItem, data) {
                                    return instance.formatNumber(tooltipItem?.value);
                                }
                            }
                        },
                        scales: {
                            yAxes: [
                                {
                                    id: 'L',
                                    ticks: {
                                        beginAtZero: true,
                                        callback: (value: number) => {
                                            return instance.formatNumber(value);
                                        }
                                    }
                                },
                            ]
                        },
                        legend: {
                            labels: {
                                fontSize: 15
                            },
                            position: 'bottom'
                        }
                    }
                })
            },
            
        },
    });
</script>

<style lang="scss" scoped>
   
</style>