﻿<template>
    <div class="row">
        <div class="col-md-12">
            <div class="row mb-2">
                <div class="col-md-3 col-6">
                    <label>{{translate('address')}}</label>
                    <div class="filter-input">
                        <i class="fas fa-filter"></i>
                        <input type="text" :placeholder="translate('filter_on_address')" v-model="filter.location" />
                    </div>
                </div>
            </div>
            <template v-if="Addresses.length">
                <table class="table table-striped table-small data-table">
                    <thead>
                        <tr>
                            <th class="d-none d-lg-table-cell">{{translate('city')}}</th>
                            <th class="d-none d-lg-table-cell">{{translate('street')}}</th>
                            <th width="110px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr is="address-row" v-for="address in Addresses" :key="address.Id" v-bind="address" :cant-remove="currentList.IsReadOnly"></tr>
                    </tbody>
                </table>
                <div class="text-grey font-small py-5 text-center text-uppercase" v-if="!hasShownAllAddresses"><i class="fas fa-arrow-down mr-2"></i> {{translate('scroll_to_show_more')}}</div>
            </template>
            <template v-else>
                <p class="mt-5">
                    {{translate('no_results')}}
                </p>
            </template>
        </div>
    </div>
</template>

<script>
import Page from 'Lib/Page';
import AddressRow from './address-row.vue';
import _ from 'lodash';
import Translate from 'Mixins/translate';
import Vue from 'vue';

export default Vue.extend({
        name: 'list-management-address',
        components: {
            AddressRow
        },
        mixins: [Translate],
        props: {
            currentList: Object
        },
        data() {
            return {
                filter: {
                    location: ''
                },
                displayAmount: 50
            };
        },
        computed: {
            filteredAddresses() {
                return _.filter(this.currentList.Addresses, (address) => {
                    return address.Lijn1.toLowerCase().indexOf(this.filter.location.toLowerCase()) !== -1
                        || address.Lijn2.toLowerCase().indexOf(this.filter.location.toLowerCase()) !== -1;
                });
            },
            Addresses() {
                return _.take(this.filteredAddresses, this.displayAmount);
            },
            hasShownAllAddresses() {
                return this.displayAmount >= this.filteredAddresses.length
            }
        },
        methods: {
            infiniteScroll() {
                window.onscroll = () => {
                    const fullDocumentHeight = document.documentElement.scrollHeight;
                    const scrolledHeight = document.documentElement.scrollTop;
                    const screenHeight = window.innerHeight;

                    if (scrolledHeight + screenHeight + 200 >= fullDocumentHeight && !this.hasShownAllAddresses)
                        this.displayAmount += 50;
                }
            }
        },
        mounted() {
            Page.applyBehaviors('#opvolging');
            this.infiniteScroll();
        }
    });
</script>