﻿<template>
    <tr role="row" v-if="shouldShow" class="pointer">
        <td class="align-top">
            <div class="row">
                <div class="col-md-6">
                    <a :href="link" class="tr-anchor" :title="name">
                        <i class="fas small-icon" :class="iconClass"></i><b class="companyweb-lightblue" :inner-html.prop="highlightedName|truncate(38)"></b> <b class="companyweb-lightblue">({{vorm}})</b>
                    </a> 
                    <a :href="link" target="_blank" class="btn btn-transparent btn-sm ml-1"  :title="translate('open_in_new_tab')">
                        <i class="fas fa-external-link-alt"></i>
                    </a>
                </div>
                <div class="col-md-6">
                    {{vat}}
                </div>
            </div>
          
            <div class="row">
                <div class="col-md-6">
                    <b>{{matchAddressLabel}}</b> <span v-html="address"></span>
                </div>
                <div class="col-md-6" v-if="showMainSeatAddress">
                    <b>{{mainSeatAddressLabel}}:</b> <span v-html="mainSeatAddress"></span>
                </div>
            </div>
        </td>
        <td class="align-top" width="100px">
            <span v-html="barometer"></span>
            <span class="small-text" v-if="hasCompanyEndYear">({{companyEndYear}})</span>
        </td>
    </tr>
</template>
<script>
    import _ from 'lodash';
    import Truncate from 'Mixins/truncate';
    import Translate from 'Mixins/translate';

    export default {
        name: 'result-row',
        props: {
            vat: String,
            name: String,
            vorm: String,
            companyEndYear: Number,
            addressEndYear: Number,
            isActive: Boolean,
            barometer: String,
            huidigeMaatschappelijkeZetelStraat: String,
            huidigeMaatschappelijkeZetelGemeente: String,
            type: String,
            origin: String,
            addressStreet: String,
            addressNumber: String,
            addressBox: String,
            link: String
        },
        mixins: [Truncate, Translate],
        computed: {
            highlightedName() {
                let filterTerm = this.$parent.$parent.filterTerm.toLowerCase();

                return filterTerm === ''
                    ? this.name
                    : this.name.replace(new RegExp('(' + filterTerm + ')', "gi"), "<span class='highlight'>$1</span>");
            },
            address() {
                return this.addressStreet + " " + this.addressNumber + (this.addressBox ? '/' : '') + this.addressBox;
            },
            hasCompanyEndYear() {
                return this.companyEndYear && this.companyEndYear !== "";
            },
            hasAddressEndYear() {
                return this.addressEndYear && this.addressEndYear !== "";
            },
            matchAddressLabel() {
                return this.hasAddressEndYear
                    ? this.translate('tot_datum') + ' ' + this.addressEndYear + ': '
                    : (this.origin === 'vestiging'
                        ? this.translate('vestiging') + ': '
                        : this.translate('zetel') + ': ');
            },
            showMatchAddressLabel() {
                return this.hasAddressEndYear || this.origin === 'vestiging';
            },
            showMainSeatAddress() {
                return this.origin === 'vestiging' || this.origin === 'oude-maatschappelijke-zetel';
            },
            mainSeatAddressLabel() {
                let label = '';

                if (this.origin === 'oude-maatschappelijke-zetel')
                    label = this.translate('huidige_maatschappelijke_zetel');
                else if (this.origin === 'vestiging')
                    label = this.translate('hoofdzetel');

                return label;
            },
            mainSeatAddress() {
                return "<span class=\"no-wrap\">"+this.huidigeMaatschappelijkeZetelStraat + ", </span><span class=\"no-wrap\">" + this.huidigeMaatschappelijkeZetelGemeente + "</span>";
            },
            shouldShow() {
                return this.name.toString().toLowerCase().indexOf(this.$parent.$parent.filterTerm.toLowerCase()) !== -1;
            },
            iconClass() {
                return this.isActive
                    ? ''
                    : 'fa-minus-circle companyweb-red mr-2';
            },
            strippedVat() {
                return this.vat
                    ? this.vat.replace(/[^0-9]/g, '')
                    : '';
            }
        }
    }
</script>

<style>
    #addressSearchResults .highlight {
        background-color: #fce940;
    }
</style>
