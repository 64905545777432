<template>
    <nav class="row mobile-dm-menu">
        <div class="col-3">
            <button class="btn btn-default btn-block" @click.prevent="toggleMenu">
                <i class="fas fa-bars"></i>
            </button>
        </div>
        <div class="col-6">
            <a href="" @click.prevent="toggleCriteria">{{displayValue|formatNumber}} / {{total}}</a>
        </div>
        <div class="col-3" v-if="!isLastStep">
            <button class="btn btn-info btn-block" @click.prevent="nextStep()" :disabled="isNextStepDisabled">
                <i class="fas fa-angle-right"></i>
            </button>
        </div>
    </nav>
</template>

<script lang="ts">
    import Vue from 'vue';
    import { RequestStatus } from '../../types';
    import gsap from 'gsap';
    import DataMarketingService, {Request} from '../../dataMarketingService';
    import Progress from '../../progress';
    import { Language } from '../../../../types/dto/Language';


  
    export default Vue.extend({
        name: 'dm-result-counter',
        props: {
            progress: Object as () => Progress,
        },
        data() {
            return {
                displayValue: DataMarketingService.getInstance().currentRequest.summary?.countTotal ?? 0,
                tweenValue: DataMarketingService.getInstance().currentRequest.summary?.countTotal ?? 0,
                treeData: DataMarketingService.getInstance().treeData!,
                Language
            }
        },
        computed: {
            currentRequest(): Request{
                return DataMarketingService.getInstance().currentRequest;
            },
            isNextStepDisabled(): boolean {
                return this.progress.isNextStepDisabled;
            },
            total(): number {
                return this.currentRequest.numberOfAvailableCompanies;
            },
            status(): RequestStatus {
                return this.currentRequest.status;
            },
            selected(): number {
                return this.currentRequest.summary?.countTotal ?? 0
            },
            isLastStep(): boolean {
                return this.progress.activeStep === this.progress.allSteps[this.progress.allSteps.length - 1];
            }
        },
        methods: {
            toggleMenu(): void {
                this.$root.$emit("mobile-menu");
            },
            toggleCriteria(): void {
                this.$root.$emit('mobile-criteria')
            },
            nextStep(): void {
                this.progress?.activateNextStep();
            }
        },
        filters:{
            formatNumber: (num: number): string => num.formatThousandSeparator()
        },
        watch: {
            selected(): void {
                gsap.to(this, {
                    tweenValue: this.selected,
                    onUpdate: () => {
                        this.displayValue = Math.ceil(this.tweenValue);
                    }
                });
            }
        }
    })
</script>

<style scoped lang="scss">
    @import "../../../../../sass/variables";

   .selected {
       font-size: 23px;
       font-weight: bolder;
   }

   .pe-none{
       pointer-events: none;
   }

   .delete-link {
       color: $grey;
       font-size: 13px;

       &:hover {
           color: $red;
           text-decoration: none;
       }
   }

   .mobile-dm-menu {
        position: fixed;
        bottom: 0;
        z-index: 500;
        background: $bg-lightgrey;
        padding: 15px 0;
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #e5e5e5;
        display: none;
        height: 12vh;

        @media #{$responsive-992} {
            display: flex;
        }

        a {
            color: $body;
            text-decoration: underline;
            font-size: 16px;
        }
   }
</style>