import _ from 'lodash';

import Ajax from '../../lib/Ajax';
import SelectionPreset from './selectionPreset';
import {DataMarketingRequestResultSummary} from '../../types/dto/DataMarketingRequestResultSummary';
import {PrepaidDataMarketingRequest} from '../../types/dto/PrepaidDataMarketing';
import {SaveDataMarketingRequestResponse} from '../../types/dto/SaveDataMarketingRequestResponse';
import {Language} from '../../types/dto/Language';
import {DataMarketingRequestType} from '../../types/dto/DataMarketingRequestType';
import {
    TreeData,
    RequestStatus
} from './types';
import Option from '../../types/global/option';
import {ProspectieDataSelectionCriterium} from '../../types/dto/ProspectieDataSelectionCriterium';
import {ProspectieDataRange, ProspectieDataRangeOperator} from '../../types/dto/ProspectieDataRange';
import {ProspectieDataSelectionCriteriumType} from '../../types/dto/ProspectieDataSelectionCriteriumType';
import CriteriumRange from './criteriumRange';

export class Request implements PrepaidDataMarketingRequest {
    private static _instance: Request;
    public selectionUpperLimit: number = 10000;

    public id: number;
    public userId: number;
    public requestDate: number;
    public reference: string | null;
    public validationDate: number | null;
    public fileGenerationDate: number | null;
    public amountOfCompanies: number | null;
    public type: DataMarketingRequestType;
    public exportLanguage: Language;
    public isPaid: boolean;

    public detailLink: string;
    public saveLink: string;
    public isPaidLink: string;
    public numberOfCompaniesLink: string;
    public exportFileLink: string;
    public addExclusionViaFileLink: string;
    public removeExclusionViaFileLink: string;
    public setExclusionsViaRequestLink: string;
    public addRangeCriteriumLink: string;
    public removeRangeCriteriumLink: string;
    public addNodeCriteriumLink: string;
    public removeNodeCriteriumLink: string;
    public removeAllCriteriaLink: string;
    public removeCriteriumByTypeLink: string;
    public overrideFileLink: string;
    public getSummaryLink: string;
    public setIsPaidLink: string;
    public hideRequestLink: string;

    public selectedCriteria: ProspectieDataSelectionCriterium[] | null;
    public summary?: DataMarketingRequestResultSummary;
    public numberOfAvailableCompanies: number = 0;
    public presets: SelectionPreset[] = SelectionPreset.enumerators as SelectionPreset[];
    public formerRequests: PrepaidDataMarketingRequest[] = [];
    public hasExcludedVatsViaFile: boolean;
    public vatInputEmit: Option<VatInputEmit> = Option.none();
    public excludedRequestIds: number[];

    private constructor(initRequest: PrepaidDataMarketingRequest) {
        this.id = initRequest.id,
            this.userId = initRequest.userId,
            this.requestDate = initRequest.requestDate,
            this.validationDate = initRequest.validationDate,
            this.fileGenerationDate = initRequest.fileGenerationDate,
            this.selectedCriteria = initRequest.selectedCriteria,
            this.hasExcludedVatsViaFile = initRequest.hasExcludedVatsViaFile,
            this.excludedRequestIds = initRequest.excludedRequestIds,
            this.reference = initRequest.reference,
            this.type = initRequest.type,
            this.exportLanguage = initRequest.exportLanguage,
            this.isPaid = initRequest.isPaid,
            this.amountOfCompanies = initRequest.amountOfCompanies,

            this.detailLink = initRequest.detailLink,
            this.saveLink = initRequest.saveLink,
            this.isPaidLink = initRequest.isPaidLink,
            this.setIsPaidLink = initRequest.setIsPaidLink,
            this.numberOfCompaniesLink = initRequest.numberOfCompaniesLink,
            this.exportFileLink = initRequest.exportFileLink,
            this.addExclusionViaFileLink = initRequest.addExclusionViaFileLink,
            this.removeExclusionViaFileLink = initRequest.removeExclusionViaFileLink,
            this.setExclusionsViaRequestLink = initRequest.setExclusionsViaRequestLink,
            this.addRangeCriteriumLink = initRequest.addRangeCriteriumLink,
            this.removeRangeCriteriumLink = initRequest.removeRangeCriteriumLink,
            this.addNodeCriteriumLink = initRequest.addNodeCriteriumLink,
            this.removeNodeCriteriumLink = initRequest.removeNodeCriteriumLink,
            this.removeAllCriteriaLink = initRequest.removeAllCriteriaLink,
            this.removeCriteriumByTypeLink = initRequest.removeCriteriumByTypeLink,
            this.getSummaryLink = initRequest.getSummaryLink,
            this.hideRequestLink = initRequest.hideRequestLink,
            this.overrideFileLink = initRequest.overrideFileLink;
    }

    public static async getInstance(initRequest: PrepaidDataMarketingRequest): Promise<Request> {
        if (!Request._instance) {
            Request._instance = new Request(initRequest);

            if (initRequest.type === DataMarketingRequestType.PROSPECTIEDATA) {
                this._instance.formerRequests = await Request._getFormerRequests();
            }

            this._instance.numberOfAvailableCompanies = await this._instance._getNumberOfAvailableCompanies();

            await this._instance.updateSummary();
        }

        return Request._instance;
    }

    public get hasSelections(): boolean {
        return (this.selectedCriteria ?? []).length > 0;
    }

    public get status(): RequestStatus {
        if (!this.validationDate)
            return RequestStatus.STARTED;
        else if (!this.fileGenerationDate || !this.isPaid)
            return RequestStatus.PENDING;
        else
            return RequestStatus.FINISHED;
    }

    public get hasReference(): boolean {
        return this.reference !== null && this.reference !== '';
    }

    public async addExclusionViaFile(vatInput: VatInputEmit): Promise<void> {
        await Ajax.postAsync(this.addExclusionViaFileLink, {...vatInput});

        this.hasExcludedVatsViaFile = true;
        await this._checkIfShouldExclude();
    }

    public async getRequest(requestId: number): Promise<void> {
        try {
            Ajax.invalidateCache('/ajax/datamarketing/' + requestId, {});
            const req = await Ajax.getAsync<PrepaidDataMarketingRequest>('/ajax/datamarketing/' + requestId);
            this.validationDate = req.validationDate;
            this.fileGenerationDate = req.fileGenerationDate;
            this.isPaid = req.isPaid;
            this.amountOfCompanies = req.amountOfCompanies;
            
        } catch (e) {
            
        }
    }

    public async removeExclusionViaFile() {
        await Ajax.postAsync(this.removeExclusionViaFileLink);
        this.hasExcludedVatsViaFile = false;
        await this._checkIfShouldExclude();
    }

    public async setExcludesViaRequest(requestIds: number[]): Promise<void> {
        await Ajax.postAsync(
            this.setExclusionsViaRequestLink,
            {
                selectedRequestIds: requestIds
            }
        );

        this.excludedRequestIds = requestIds;
        await this._checkIfShouldExclude();
    }

    private async _checkIfShouldExclude() {
        // this method is adding a selection type 99 when we should exclude.
        // although this is not advised because we have two sources of truth now, Hans still requested me to do this.
        await this.removeCriteriumByType(ProspectieDataSelectionCriteriumType.EXCLUSION);

        if (this.excludedRequestIds.length > 0 || this.hasExcludedVatsViaFile)
            await this.select(1, ProspectieDataSelectionCriteriumType.EXCLUSION, true);
        else
            await this.select(1, ProspectieDataSelectionCriteriumType.EXCLUSION, false);

        await this.updateSummary();
    }

    public async usePreset(preset: SelectionPreset): Promise<void> {
        await this._resetCriteria();
        await Promise.all(preset.criteria.map(crit => this.addCriterium(crit)));
        await this.updateSummary();
    }
    
    public async useNoPreset(): Promise<void> {
        await this._resetCriteria();
        await this.updateSummary();
    }

    public async useFormerRequest(request: PrepaidDataMarketingRequest): Promise<void> {
        await this._resetCriteria();
        await Promise.all((request.selectedCriteria ?? []).map(crit => this.addCriterium(crit)));
        await this.updateSummary();
    }

    public async removeCriterium(criterium: ProspectieDataSelectionCriterium): Promise<void> {
        if (criterium.range !== null) {
            if (criterium.type === ProspectieDataSelectionCriteriumType.PERSONEEL)
                await this.removeCriteriumByType(ProspectieDataSelectionCriteriumType.PERSONEEL);
            else
                await this.removeRange(criterium.range);
        } else {
            await this.select(criterium.id!, criterium.type, false);
        }
    }

    public async addCriterium(criterium: ProspectieDataSelectionCriterium): Promise<void> {
        if (criterium.range !== null) {
            await this.addRange(criterium.range);
        } else {
            await this.select(criterium.id!, criterium.type, true);
        }
    }

    public async select(nodeId: number, type: ProspectieDataSelectionCriteriumType, isSelected: boolean): Promise<void> {
        return new Promise(async (resolve) => {
            await Ajax.postAsync(
                isSelected ? this.addNodeCriteriumLink : this.removeNodeCriteriumLink,
                {
                    requestId: this.id,
                    type: type,
                    nodeId: nodeId
                });

            if (isSelected) {
                const newCriterium: ProspectieDataSelectionCriterium = {
                    type: type,
                    id: nodeId,
                    range: null
                };

                this.selectedCriteria!.push(newCriterium);
            } else {
                var element = this.selectedCriteria!.find(
                    criterium => criterium.id === nodeId &&
                        criterium.type === type
                );

                if (element)
                    this.selectedCriteria?.remove(element);
            }
            resolve();
        });
    }

    public async addRange(range: CriteriumRange): Promise<void> {
        return new Promise(async (resolve) => {
            if (this._hasExactSameCriteriumRange(range))
                return resolve();

            await Ajax.postAsync(
                this.addRangeCriteriumLink,
                {
                    type: range.type,
                    lowerbound: range.lowerBound,
                    upperbound: range.upperBound,
                    operator_: range.operator
                }
            );

            const newCriterium: ProspectieDataSelectionCriterium = {
                type: range.type,
                id: null,
                range: range
            };

            this.selectedCriteria!.push(newCriterium);
            resolve();
        });
    }

    private _hasExactSameCriteriumRange(range: ProspectieDataRange): boolean {
        return !!this.selectedCriteria!.find(
            criterium => criterium.range?.lowerBound === range.lowerBound &&
                criterium.range?.upperBound === range.upperBound &&
                criterium.range?.type === range.type
        );
    }

    public async removeRange(emitData: ProspectieDataRange): Promise<void> {
        let operator = emitData.operator;
        let lowerBound = emitData.lowerBound;
        let upperBound = emitData.upperBound;

        await Ajax.postAsync<number>(
            this.removeRangeCriteriumLink,
            {
                type: emitData.type,
                lowerbound: lowerBound,
                upperbound: upperBound,
                operator_: operator
            });

        var element = this.selectedCriteria!.find(
            criterium => criterium.range?.lowerBound === emitData.lowerBound &&
                criterium.range?.upperBound === emitData.upperBound &&
                criterium.range?.type === emitData.type
        );

        if (element)
            this.selectedCriteria?.remove(element);
    }

    public async removeCriteriumByType(type: ProspectieDataSelectionCriteriumType): Promise<void> {
        await Ajax.postAsync(this.removeCriteriumByTypeLink, {
            type: type
        });

        this.selectedCriteria = this.selectedCriteria!.filter(criterium => criterium.type !== type);
    }

    public async updateSummary(): Promise<void> {
        this.summary = await Ajax.getAsync<DataMarketingRequestResultSummary>(this.getSummaryLink);
    }

    public async setIsPaid(): Promise<void> {
        await Ajax.getAsync(this.setIsPaidLink);
    }

    public async saveRequest(reference: string, exportLanguage: Language): Promise<SaveDataMarketingRequestResponse> {
        return new Promise(async (resolve, reject) => {
            this.reference = reference;
            this.exportLanguage = exportLanguage;
      
            try {
                const reponse = await Ajax.postAsync<SaveDataMarketingRequestResponse>(this.saveLink, {
                    fileIdentifier: this.vatInputEmit.map(v => v.fileIdentifier).valueOr(null!),
                    vatColumnIndex: this.vatInputEmit.map(v => v.vatColumnIndex).valueOr(null!),
                    referenceColumnIndex: this.vatInputEmit.map(v => v.referenceColumnIndex).valueOr(null!),
                    reference,
                    exportLanguage,
                    amountOfCompanies: this.summary?.countTotal
                }, 30000);
    
                this.validationDate = reponse.validationDate;
                this.fileGenerationDate = reponse.fileGenerationDate;
                this.isPaid = reponse.isPaid;
                this.amountOfCompanies = reponse.amountOfCompanies;
                
                this.numberOfAvailableCompanies = reponse.amountOfAvailableCompanies;
    
                resolve(reponse);
            } catch (e) {
                reject();
            }
        });
    }

    public async overrideFile(input: VatInputEmit): Promise<void> {
     
        await Ajax.postAsync<SaveDataMarketingRequestResponse>(this.overrideFileLink, {...input});
    }

    private _getNumberOfAvailableCompanies = async (): Promise<number> => await Ajax.getAsync<number>(this.numberOfCompaniesLink);

    private async _resetCriteria(): Promise<void> {
        return new Promise(async (resolve) => {
            this.selectedCriteria = _.cloneDeep([]);
            await Ajax.postAsync(this.removeAllCriteriaLink);

            resolve();
        });
    }

    private static _getFormerRequests = async () => await Ajax.getAsync<PrepaidDataMarketingRequest[]>(DataMarketingService._getRequestsUrl);
}

export default class DataMarketingService {
    private static instance: DataMarketingService;

    private constructor(
        public currentRequest: Request,
        public treeData: TreeData) {
    }

    public static getInstance(): DataMarketingService {
        return DataMarketingService.instance;
    }

    public static async create(request: PrepaidDataMarketingRequest): Promise<DataMarketingService> {
        const currentRequest = await Request.getInstance(request);
        const treeData = await DataMarketingService._getTreeData();

        const instance = new DataMarketingService(currentRequest, treeData);
        this.instance = instance;

        return instance;
    }

    public static uploadVats = async (vatInput: VatInputEmit): Promise<PrepaidDataMarketingRequest> => {
        return await Ajax.postAsync<PrepaidDataMarketingRequest>(DataMarketingService._getUploadVatsUrl, {...vatInput}, 15000);
    };

    public static readonly _getTreeDataUrl: string = '/ajax/datamarketing/prospecting/getCriteria';
    public static readonly _getRequestsUrl: string = '/ajax/datamarketing/prospecting/getRequests';
    public static readonly _getUploadVatsUrl: string = '/ajax/datamarketing/enrichment/uploadVats';

    private static _getTreeData = () => Ajax.getAsync<TreeData>(DataMarketingService._getTreeDataUrl);
}