
<div class="box">
    <div class="box-title">Opvolging</div>

    <div class="row">
        <div class="col-12">
            <template v-if="hasFollowup">
                <div>

                </div>
                <template v-if="amountOfFollowupCredits === 0">
                    <h2 class="text-uppercase font-medium"><b>Meer bedrijven opvolgen?</b></h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer iaculis magna vtiae congue tincidunt. 
                        Nulla velit mauris, altrices at vestibulum.
                    </p>
                    <a href="/contact/message" class="btn btn-success btn-200"> 
                        <i class="fas fa-comments mr-2"></i> Contacteer ons
                    </a>
                </template>
            </template>
            <template v-else>
                <h2 class="text-uppercase font-medium"><b>Internationale bedrijven opvolgen?</b></h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer iaculis magna vtiae congue tincidunt. 
                    Nulla velit mauris, altrices at vestibulum.
                </p>
                <a href="/contact/message" class="btn btn-success btn-200"> 
                    <i class="fas fa-comments mr-2"></i> Contacteer ons
                </a>
            </template>
        </div>
    </div>
</div>
