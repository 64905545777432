<template>
    <div class="pricing-table-row">
        <div class="pricing-cell">
            <a v-if="rowData.hasModal" href="" v-on:click.prevent="showModal(rowData.name)" class="link">
                {{translate(rowData.name)}}
            </a>
            <span v-else>
                {{translate(rowData.name)}}
            </span>
        </div>

        <pricing-cell v-for="column in columns" :key="column.name + '_row'" :currentPackage="column.selectedPackage" :selectedPackage="selectedPackage" :rowData="rowData"></pricing-cell>
    </div>
</template>

<script lang="ts">
    import { Package, PricingColumn, PricingRow, PricingRowType } from '../types';
    import Translate from '../../mixins/translate';
    import mixins from 'vue-typed-mixins';
    import PricingCell from './pricing-cell.vue';
    

    export default mixins(Translate).extend({
        name: 'pricing-row',
        components: {PricingCell},
        props: {
            rowData: Object as () => PricingRow<PricingRowType>,
            selectedPackage: Object as () => Package
        },
        data() {
            return {
                Package,
            }
        },
        methods: {
            showModal(name: string): void {
                this.$root.$emit("show-modal", name);
            }
        },
        computed: {
             columns(): PricingColumn[] {
                return PricingColumn.enumerators;
            }
        }
    })
</script>

<style scoped>

</style>