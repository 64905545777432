import Translate from '../../lib/Translate';
import { ProspectieDataRange, ProspectieDataRangeOperator } from '../../types/dto/ProspectieDataRange';
import { ProspectieDataSelectionCriteriumType } from '../../types/dto/ProspectieDataSelectionCriteriumType';

enum RangeFormatType {
    STRING,
    EURO,
    DATE,
    BAROMETER_SCORE,
    PERSONEEL
}

export default class CriteriumRange implements ProspectieDataRange{
    public type: ProspectieDataSelectionCriteriumType;
    public operator: ProspectieDataRangeOperator;
    public lowerBound: number;
    public upperBound: number;

    private get _formatType(): RangeFormatType {
        if ([ProspectieDataSelectionCriteriumType.BALANSTOTAAL, ProspectieDataSelectionCriteriumType.EIGENVERMOGEN,
        ProspectieDataSelectionCriteriumType.OMZET, ProspectieDataSelectionCriteriumType.BRUTOMARGE, ProspectieDataSelectionCriteriumType.WINSTVERLIES].includes(this.type))
            return RangeFormatType.EURO;
        else if ([ProspectieDataSelectionCriteriumType.OPRICHTINGSDATUM].includes(this.type))
            return RangeFormatType.DATE;
        else if ([ProspectieDataSelectionCriteriumType.RISICOPROFIEL].includes(this.type))
            return RangeFormatType.BAROMETER_SCORE;
        else if ([ProspectieDataSelectionCriteriumType.PERSONEEL].includes(this.type))
            return RangeFormatType.PERSONEEL;
        else
            return RangeFormatType.STRING;
    };

    public constructor(
        operator: ProspectieDataRangeOperator,
        type: ProspectieDataSelectionCriteriumType,
        lowerBound?: number,
        upperBound?: number
    ) {
        this.lowerBound = lowerBound ?? 0
        this.upperBound = upperBound ?? 0

        if (upperBound === undefined)
            this.operator = ProspectieDataRangeOperator.GREATEROREQUALTHAN;
        else if (lowerBound === undefined)
            this.operator = ProspectieDataRangeOperator.LESSTHAN;
        else if (lowerBound === upperBound)
            this.operator = ProspectieDataRangeOperator.EQUALTO;
        else 
            this.operator = operator;
        
        if(type === ProspectieDataSelectionCriteriumType.OPRICHTINGSDATUM && upperBound === undefined) {
            this.upperBound = new Date().toHansDate();
            this.operator = ProspectieDataRangeOperator.BETWEEN;
        }
        
        this.type = type;
    }

    public get translationKey(): string {
        return 'range_' + this.operator
    }

    public toString(): string {
        let lowerBound = this.lowerBound?.toString();
        let upperBound = this.upperBound?.toString();
    
        if (this._formatType === RangeFormatType.EURO)
        {
            lowerBound = this.lowerBound?.formatThousandSeparator();
            upperBound = this.upperBound?.formatThousandSeparator();
        } 
        else if (this._formatType === RangeFormatType.PERSONEEL)
        {
            upperBound = this.upperBound === 1000 ? '1000+' : this.upperBound?.toString();
        } 
        else if (this._formatType === RangeFormatType.DATE)
        {
            lowerBound = this.lowerBound !== 0 && !!this.lowerBound ? Date.fromHansDate(this.lowerBound).format() : '';
            upperBound = this.upperBound !== 0 && !!this.upperBound ? Date.fromHansDate(this.upperBound).format() : '';
        }
        else if (this._formatType === RangeFormatType.BAROMETER_SCORE)
        {
            lowerBound = (this.lowerBound ? this.lowerBound / 10 : this.lowerBound)?.toString().replace(".",",");
            upperBound = (this.upperBound ? this.upperBound / 10 : this.upperBound)?.toString().replace(".",",");
        }
        
        return Translate(`range_${this.operator}`, lowerBound, upperBound);
    }
}