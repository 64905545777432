<template>
    <div>
        <a v-if="link" :href="link" class="link" target="_blank">Link naar detailpagina<i class="ml-1 fas fa-external-link-alt fa-xs"></i></a>
        <upsert-form v-if="article" :populateWith="edittableArticle"></upsert-form>
        <div v-else-if="error !== ''" class="info-box warning mt-5">{{error}}</div>
        <div v-else class="py-5 text-center">
            <i class="fas fa-spinner spinning text-blue fa-2x"></i>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import Ajax from '../../../lib/Ajax';
    import { BlogArticle } from './types';
    import Option from '../../../types/global/option';
    import UpsertForm from './form.vue';
  
    export default Vue.extend({
        name: 'edit_article',
        components: {
            UpsertForm
        },
        data() {
            return {
                article: null as BlogArticle | null,
                articleId: +this.$route.params.articleId,
                error: ''
            };
        },
        computed: {
            edittableArticle(): Option<BlogArticle> {
                 return Option.someNotNull(this.article);
            },
            
            link(): string | undefined {
                return this.article?.detailLink;
            },
        },
        methods: {
            async getArticle(articleId: number): Promise<BlogArticle> {
                return new Promise((resolve, reject) => {
                    if (!articleId)
                        reject();

                    Ajax.get(
                        '/ajax/cms/blog',
                        {
                            articleId: articleId
                        },
                        response => {
                            resolve(response.data);
                        },
                        error => {
                            reject(error);
                        }
                    )
                });
            }
        },
        async created() {
            try {
                this.article = await this.getArticle(this.articleId);
                this.error = '';
            } catch(e) {
                this.error = 'Artikel met ID ' + this.articleId + " kan niet worden gevonden.";
            }
        }
    });
</script>

<style scoped>

</style>