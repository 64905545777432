
<div>
    <h2 class="mb-4"><b>{{translate("sidebar_establishment")}}</b></h2>
    <form v-on:submit.prevent="addYearRange()">
        <div class="row form-group">
            <div class="col-sm-4">
                <datepicker v-model="lowerBound" 
                            :language="locales[userLang]" 
                            :bootstrap-styling="true"  
                            :monday-first="true"
                            initial-view="year"
                            format="dd/MM/yyyy"
                            calendar-button
                            calendar-button-icon="fa fa-calendar"
                            :disabled-dates="{from: new Date()}"
                            :full-month-name="true">
                </datepicker>
            </div>
            <label class="col-sm-1 flex-h-center my-auto">{{translate("tot_en_met")}}</label>
            <div class="col-sm-4">
                <datepicker v-model="upperBound"
                            :language="locales[userLang]"
                            :bootstrap-styling="true"
                            :disabled-dates="{from: new Date()}"
                            :monday-first="true"
                            initial-view="year"
                            format="dd/MM/yyyy"
                            calendar-button
                            calendar-button-icon="fa fa-calendar"
                            :full-month-name="true">
                </datepicker>
            </div>
            <div class="col-sm-3">
                <button type="submit" class="btn btn-default btn-block">{{translate("btn_add")}}</button>
            </div>
        </div>
        <div class="card-box small warning mt-4" v-if="($v.lowerBound.$invalid && $v.lowerBound.$dirty) || ($v.upperBound.$invalid && $v.upperBound.$dirty)">
            {{translate("validation_error_date_collision")}}
        </div>
    </form>
</div>
