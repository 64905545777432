﻿function isHansDate(date: number | string | Date) {
    return /^\d{8}$/.test(date.toString());
}

import T from '../../lib/Translate';
import Vue from 'vue';

function translate(translateKey: string, ...args: any) {
    return T(translateKey, ...args);
}

export default Vue.extend({
    filters: {
        toTimeAgo: function (dateString: string) {
            let date = new Date(dateString);

            if (isHansDate(dateString)) {
                date = Date.fromHansDate(+dateString);
                
                return date.toTimeAgo(false);
            } 
            
            return date.toTimeAgo(true);
        },
        toLongDate(date: Date | string) {
            if (isHansDate(date))
                date = Date.fromHansDate(+date);
            
            if (typeof date === "string")
                return date;

            return date.format();
        }
    },
    methods: {
        formatDate(date: Date): string {
          return date.format();
        },
    }
});
