﻿import _ from 'lodash';
import Vue from 'vue';
import Page from '../lib/Page';

export default (function () {
    Page.registerVue('#cookieCheck', function (rootSelector) {
        
        new Vue({
            el: rootSelector,
            data() {
             return {
                cookieValue: ""
             }
            },
            computed: {
                cookiesAllowed(): boolean {
                    return this.cookieValue === "true";
                }
            },
            methods: {
                writeCookie(): void {
                    let d = new Date();
                    d.setTime(d.getTime() + 60000);

                    const expires = "expires=" + d.toUTCString();
                    document.cookie = "cookiecheck=true;" + expires + ";path=/";
                },
                getCookie(): string {
                    const name = "cookiecheck=";
                    const decodedCookie = decodeURIComponent(document.cookie);
                    const ca = decodedCookie.split(';');

                    for (var i = 0; i < ca.length; i++) {
                        var c = ca[i].trim();
                        while (c.charAt(0) === ' ') {
                            c = c.substring(1);
                        }
                        if (c.indexOf(name) === 0) {
                            return c.substring(name.length, c.length);
                        }
                    }

                    return "";
                }
            },
            created() {
                this.writeCookie();
            },
            mounted() {
                this.cookieValue = this.getCookie();
            }
        });
    });
}());
