import Vue from 'vue';
import Page from '../../lib/Page';
import VueRouter from 'vue-router';

import SoftwareIntegrations from './software-integrations/index.vue'
import SoftwareIntegrationsList from './software-integrations/list.vue';
import SoftwareIntegrationsCreate from './software-integrations/create.vue';
import SoftwareIntegrationsEdit from './software-integrations/edit.vue';

import BlogArticles from './blog/index.vue'
import BlogArticlesList from './blog/list.vue';
import BlogArticlesCreate from './blog/create.vue';
import BlogArticlesEdit from './blog/edit.vue';

import TermsAndConditions from './terms-and-conditions/form.vue'

Vue.use(VueRouter);

const routes = [
    {
        path: '/cms/software-integrations',
        component: SoftwareIntegrations,
        children: [
            {
                path: '',
                component: SoftwareIntegrationsList,
                name: 'list'
            },
            {
                path: 'create',
                component: SoftwareIntegrationsCreate,
                name: 'create'
            },
            {
                path: 'edit/:softwareId',
                component: SoftwareIntegrationsEdit,
                name: 'edit'
            }
        ]
    },
    {
        path: '/cms/blog',
        component: BlogArticles,
        children: [
            {
                path: '',
                component: BlogArticlesList,
                name: 'blog-list'
            },
            {
                path: 'create',
                component: BlogArticlesCreate,
                name: 'blog-create'
            },
            {
                path: 'edit/:articleId',
                component: BlogArticlesEdit,
                name: 'blog-edit'
            }
        ]
    }
];

const router = new VueRouter({
      routes, 
      mode: 'history'
});

export default (function () {
    Page.registerVue('#cmsWrapper', function (rootSelector) {
        new Vue({
            el: rootSelector,
            components: {
                SoftwareIntegrations,
                BlogArticles,
                TermsAndConditions
            },
            router: router
        });
    });
}());
