
<div>
    <div v-if="isLoading">
        <div class="py-5 text-center">
            <i class="fas fa-spinner spinning text-blue fa-2x"></i>
        </div>
    </div>
    <div v-else>
        <table class="data-table table table-striped mt-5">
            <thead>
                <tr>
                    <th colspan="3">

                    </th>
                    <th>
                        <input type="search" class="form-control" placeholder="filter op NL titel" v-model="filter.title"/>
                    </th>
                </tr>
                <tr>
                    <th width="30"><i class="fa fa-globe" title="Published?"></i></th>
                    <th width="30"><i class="fa fa-thumbtack" title="Pinned?"></i></th>
                    <th width="120"></th>
                    <th><i class="fa fa-label" title="Titel"></i></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="article in filteredArticles" :key="article.id" @click="editArticle(article)" class="pointer blog-row">
                    <td width="30">
                      <i class="fas fa-globe text-teal" v-if="article.isPublished"></i>
                    </td>
                    <td width="30">
                      <i class="fas fa-thumbtack text-lightblue" v-if="article.isPinned"></i>
                    </td>
                    <td class="text-center">
                        <img :alt="article.titleNl" v-if="article.banner" :src="article.banner" />
                    </td>
                    <td>
                        <b>{{article.titleNl}}</b>
                        <div v-if="article.excerptNl" v-html="truncate(article.excerptNl, 100)"></div>
                        <div><span v-for="tag in article.relatedTags" :key="tag" class="badge badge-info badge-pill badge-sm mr-1">{{tag}}</span></div>
                    </td>
                    <td class="text-right" slot="footer">
                        <button @click.stop="tryDelete(article)" class="btn btn-default btn-sm"><i class="fas fa-trash-alt"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
