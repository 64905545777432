﻿import Page from '../lib/Page';

export default (function () {
    Page.registerBehavior('#labsWidget', function (element) {
        element.addEventListener('mouseover', function (ev: Event) {
            ev.stopPropagation();
            ev.preventDefault();
            element.classList.add('open');
        });

        element.addEventListener('mouseleave', function (ev: Event) {
            ev.stopPropagation();
            ev.preventDefault();
            element.classList.remove('open');
        });
    });
}());
