﻿<template>
    <tr role="row">
        <td class="p-0 border-0">
            <table class="table table-small" >
                <tr>
                    <td colspan="2">
                        <div class="company">
                            <a :href="company.url" class="companyname">
                                <b>{{company.companyName}}</b>
                            </a>
                            <div class="vat text-lightgrey">
                                {{company.registrationNumber}}
                                <a :href="company.url" class="btn btn-transparent btn-sm d-inline-block ml-1" target="_blank">
                                    <i class="fas fa-external-link-alt ext-link-icon"></i>
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
                <alert-event v-for="(event, index) in events" :key="company.Vat+'-event-'+index" v-bind="event"></alert-event>
            </table>
        </td>
    </tr>
</template>

<script lang="ts">
    import DateHelpers from '../../mixins/date';
    import {AlertsCompany, ExtendedAlertsEvent} from './types';
    import Vue from 'vue';
    import AlertEvent from './alert-event.vue';
    
    export default Vue.extend({
        name: 'alert-company',
        components: {AlertEvent},
        props: {
            events: {
                type: Array as () => ExtendedAlertsEvent[],
                required:true
            }
        },
        mixins: [DateHelpers],
        computed: {
            company(): AlertsCompany {
                return this.events[0].company;
            }
        }
    });
</script>
