﻿<template>
    <div class="cw-dropdown"id="cw-dropdown">
        <div class="cw-dropdown__label" @click="toggleShowList()">{{label}}</div>
        <ul class="cw-dropdown__list" :class="{'active': showList}">
            <li v-for="user in value">
                <input class="form-check-input" :id="user.id" type="checkbox" v-model="user.isFollower" @change="emitUsers()" />
                <label :for="user.id">{{user.name}} &lt;{{user.email}}&gt;</label>
            </li>
        </ul>
    </div>
</template>

<script>
    import _ from 'lodash';
    import Translate from 'Mixins/translate';

    export default {
        name: 'user-dropdown',
        props: {
            value: Array,
            isPrivate: Boolean
        },
        mixins: [Translate],
        data() {
            return {
                showList: false
            }
        },
        computed: {
            followers() {
                return _.filter(this.value, user => user.isFollower);
            },
            label() {
                if (this.followers.length === 0)
                    return this.translate('select_users');
                else if (this.followers.length === 1)
                    return this.followers[0].name;
                else
                    return this.followers.length + " " + this.translate('users').toLowerCase();
            }
        },
        methods: {
            toggleShowList() {
                this.showList = !this.showList;
            },
            emitUsers() {
                this.$emit('input', this.value);
            }
        },
        mounted() {
            function isDescendant(parent, child) {
                var node = child.parentNode;
                while (node != null) {
                    if (node == parent) {
                        return true;
                    }
                    node = node.parentNode;
                }
                return false;
            }

            const containerEl = document.getElementById('cw-dropdown');

            const outsideClickListener = (event) => {
                if (!isDescendant(containerEl, event.target))
                    this.showList = false;
            }

            document.addEventListener('click', outsideClickListener)
        },
        destroy() {
            document.removeEventListener('click', outsideClickListener)
        }
    }
</script>