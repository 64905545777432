
<div style="min-width: 360px;" class="radar-cell">
    <div style="min-width:33px;">
        <barometer-icon-square :class-name="barometer" v-if="barometer"></barometer-icon-square>
    </div>
   <div class="w-full">
       <div class="d-flex justify-content-between">
        <span>
            <a :disabled="confidential" class="font-weight-bold underline" :href="'/company/'+maybeConfidentialVat" target="_blank" :title="companyName">
                <span v-confidential="confidential">{{companyName.truncate(29)}} </span>
            </a><span class="font-small ml-1">({{vorm}})</span>
        </span>
           <span class="flex space-x-1">
            <a :title="favouritesLabel" @click.prevent="addToOrRemoveFromFavourites" href="" class="icon-btn"><i class="far fa-star" :class="{'fas text-yellow': isInFavourites}"></i></a>
            <a :title="translate('add_to_alerts')" @click.prevent="addToAlerts" href="" class="icon-btn"><i class="far fa-bell"  :class="{'fas text-yellow': isInAlerts || store.vatsInAlerts.includes(vat)}"></i></a>
        </span>
       </div>
       <div class="font-small">
           <span v-confidential="confidential">{{vat.formatVat()}}</span>
       </div>
       <div class="text-grey font-small">
           <ul>
               <li v-if="address">
                   <i class="fas fa-chair-office mr-1" :title="translate('maatschappelijke_zetel')"></i>
                   <a :title="translate('search_on_address')" :disabled="confidential" target="_blank" :href="getSearchOnAddressLink(address)" v-confidential="confidential">{{address.straatNaam}} {{address.huisNummer}}{{address.busNummer}}, {{address.postcode}} {{address.gemeente}}</a>
               </li>
               <li v-for="adres in extraAddresses">
                   <i class="fas fa-store mr-1" :title="translate('vestiging')"></i>
                   <a :title="translate('search_on_address')" :disabled="confidential" target="_blank" :href="getSearchOnAddressLink(adres)" v-confidential="confidential">{{adres.straatNaam}} {{adres.huisNummer}}{{adres.busNummer}}, {{adres.postcode}} {{adres.gemeente}}</a>
               </li>
           </ul>
       </div>
   </div>
</div>
