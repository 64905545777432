<template>
    <div>
        <upsert-form :populateWith="emptyArticle"></upsert-form>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import Option from '../../../types/global/option';
    import UpsertForm from './form.vue';
    import { BlogArticle } from './types';

    export default Vue.extend({
        name: 'create_article',
        components: {
            UpsertForm
        },
        data() {
            return {
                emptyArticle: Option.none<BlogArticle>()
            }
        }
    });
</script>

<style scoped>

</style>