﻿<template>
    <ul class="pl-3" >
        <li v-for="node in nodes" :key="node.value">
            <span v-if="hasMatchingDescendants(node)" @click="onExpandClick(node)" class="expander">
                <i aria-hidden="true" class="fas fa-lg fa-angle-down" v-if="isExpanded(node)"></i>
                <i aria-hidden="true" class="fas fa-lg fa-angle-right text-lightgrey" v-else></i> 
            </span>

            <input type="checkbox" class="form-check-input azure" :id="node.value" :checked="isSelected(node)" @change="select(node)" :disabled="isSelectedAndDisabled"/>
            <label :for="node.value">{{node.name}}</label>

            <selection-branch v-if="node.children && isExpanded(node)" :isParentSelected="isSelected(node)" :criteria="criteria" :tree-data="node.children" :column="column" :filterTerm="filterTerm"></selection-branch>
        </li>
    </ul>
</template>

<script lang="ts">
import _ from 'lodash';
import Vue from 'vue';
import {Column, NamedId, SelectionTreeFilterCriteria, SelectionTreeNode} from "../../../types";

export default Vue.extend({
    name: 'selection-branch',
    props: {
        criteria: Object as () => SelectionTreeFilterCriteria,
        treeData: Array as () => SelectionTreeNode[],
        isParentSelected: Boolean,
        isForcedExpanded: Boolean,
        column: {
            type:Object as () => Column,
            required: true
        },
        filterTerm: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            expandedNodes:[] as  SelectionTreeNode[]
        }
    },
    computed:{
        nodes(): SelectionTreeNode[] {
            return this.treeData.filter(node => {
                return node.name.cw_contains(this.filterTerm) || this.hasMatchingDescendants(node);
            });
        },
        isSelectedAndDisabled(): boolean {
            return this.isParentSelected;
        }
    },
    methods: {
        hasMatchingDescendants(node: SelectionTreeNode): boolean {
            if(!this.filterTerm)
                return node.children.length > 0;

            return _.some(node.children, (child: SelectionTreeNode) => {
                return child.name.cw_contains(this.filterTerm) || this.hasMatchingDescendants(child);
            });
        },
        onExpandClick(node: SelectionTreeNode): void {
            this.expandedNodes.includes(node)
                ? this.expandedNodes.remove(node)
                : this.expandedNodes.push(node);
        },
        isExpanded(node: SelectionTreeNode): boolean {
            return this.isForcedExpanded || this.expandedNodes.includes(node) || this.hasSelectedChildren(node) || (this.hasMatchingDescendants(node) && !!this.filterTerm);
        },
        select(node: SelectionTreeNode): void {
            let current = ((this.column.filterCriteria as SelectionTreeFilterCriteria)._value);
            const val: NamedId = {
                id: node.value,
                name: node.name
            }

            if (current.map(c => c.id).includes(val.id)) 
                current.removeBy(c => c.id === val.id);
            else 
                current.push(val);
        },
        hasSelectedChildren(node: SelectionTreeNode): boolean {
            let anySelected = false;

            _.forEach(node.children, child => {
                this.foreachInTree(
                    child,
                    (n: SelectionTreeNode) => {
                        anySelected = anySelected || this.isSelected(n)|| this.isSelected(n);
                    });
            });

            return anySelected
        },
        foreachInTree (node: SelectionTreeNode, visitor: (n: SelectionTreeNode) => any) {
            visitor(node);
            if (node.children) {
                for (var i = 0, l = node.children.length; i < l; i++) {
                    this.foreachInTree(node.children[i], visitor);
                }
            }
        },
        isSelected(node: SelectionTreeNode): boolean {
            return (this.criteria?._value ?? []).map(c => c.id).includes(node.value) || this.isSelectedAndDisabled;
        }
    }
});
</script>

<style scoped lang="scss">
li {
    position: relative;
    > ul {
        padding-left: 15px;
    }
}
.expander {
    display: block;
    position: absolute;
    margin-top: -4px;
    margin-left: -20px;
    padding: 5px;
    cursor: pointer;
}
</style>