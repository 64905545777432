<template>
    <div>
        <!-- 1. loading -->
        <div v-if="isLoading" class="my-5 py-5 text-center">
            <i class="fas fa-2x fa-spinner text-lightblue spinning"></i>
        </div>

        <!-- 2. started -->
        <template v-else-if="currentRequest.status === RequestStatus.STARTED">
            <result-bar v-for="(bar) in resultBars" :key="bar.label" :amount="bar.count" :label="translate(bar.label)" :totalAmount="summary.countTotal"></result-bar>

            <form class="mt-3 mt-lg-5" @submit.prevent="saveRequest" v-if="!isLocked">
                <div class="form-group">
                    <div class="row">
                        <div class="col-3">
                            <label for="language">{{translate("language")}}</label>
                            <select id="language" v-model="selectedLanguage" class="form-control mr-1">
                                <option v-for="lang in Language" :value="lang" :key="lang">{{lang.toUpperCase()}}</option>
                            </select>
                        </div>
                        <div class="col-9">
                            <label for="reference">{{translate("reference_label")}}</label>
                            <input :readonly="hasReference" id="reference" type="text" class="form-control" v-model="$v.newReference.$model" />
                        </div>
                    </div>

                    <template v-if="$v.newReference.$invalid && $v.newReference.$dirty">
                        <div class="card-box small my-2 warning" v-if="!$v.newReference.required">
                            {{translate('validation_error_required', translate("dm_reference"))}}
                        </div>
                        <div class="card-box small my-2 warning" v-else-if="!$v.newReference.maxLength">
                            {{translate('validation_error_max_length', translate("dm_reference"), 50)}}
                        </div>
                        <div class="card-box small my-2 warning" v-else-if="!$v.newReference.minLength">
                            {{translate('validation_error_min_length', translate("dm_reference"), 3)}}
                        </div>
                        <div class="card-box small my-2 warning" v-else-if="!$v.newReference.alphaNumeric">
                            {{translate('validation_error_alphanumeric', translate("dm_reference"), 3)}}
                        </div>
                    </template>
                </div>

                <div class="form-group">
                    <input id="terms" type="checkbox" class="form-check-input" v-model="$v.acceptTerms.$model" />
                    <label for="terms">
                        <translation label="ik_ga_akkoord_met">
                            <a :href="termsOfUseLink" class="link">{{translate('de_gebruiksvoorwaarden').toLowerCase()}}</a>
                        </translation>
                    </label>
                </div>
                <template v-if="$v.acceptTerms.$invalid && $v.acceptTerms.$dirty">
                    <div class="card-box small my-2 warning">
                        <translation label="validation_error_required"><span>{{translate('gebruiksvoorwaarden')}}</span></translation>
                    </div>
                </template>

                <div class="tip warning mt-4" v-if="!hasFullAccess">
                    {{translate('datamarketing_no_user_rights')}}
                </div>
                
                <div class="form-group mt-4">
                    <button class="btn btn-info btn-200" type="submit" :disabled="!canFinishRequest">{{translate('btn_accept_request')}} <i class="fas ml-1 fan-angle-right"></i></button>
                </div>
            </form>
            <div v-if="isDeveloperOrManager">
                <hr>
                <br>
                <h2><b>Radar</b></h2>
                <p>
                    Maak hier een Radar dataset aan de hand van uw criteria.
                </p>
                <div>
                    <input type="search" placeholder="Filter op bedrijfsnaam" class="form-control" v-model="filterCompanyName">
                </div>
                <div class="overflow-auto my-3 pr-2" style="max-height: 450px;">
                    <table>
                        <thead>
                        <tr>
                            <th>Bedrijfsnaam</th>
                            <th width="75px">BOID</th>
                            <th>#sets </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="customer in filteredRadarCustomers" :key="customer.customerId">
                            <td>
                                <input type="radio" class=" mr-2" name="ts" :id="'customer_'+radarCustomerId" v-model="radarCustomerId" :value="customer.customerId">
                                <label :for="'customer_'+radarCustomerId" @click.prevent.stop="radarCustomerId = customer.customerId">
                                    {{customer.companyName}}
                                </label>
                            </td>
                            <td>
                                <a class="link" :href="'https://backoffice.companyweb.be/main.asp?ret=55&hbcid=' + customer.customerId" target="_blank">{{customer.customerId}}</a>
                            </td>
                            <td>
                                {{customer.numberOfActiveDatasets}}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
               <div class="d-flex" style="gap: 25px;" v-if="!sentToRadar"> 
                   <input type="text" class="form-control" v-model="datasetName" placeholder="Naam dataset" />
                   <button class="btn" :disabled="!radarCustomerId" @click="sendToRadar">Create</button>
               </div>
                <p v-else>
                    Verzonden.
                </p>
            </div>
        </template>

        <!-- 3. finished OR pending -->
        <div v-else>
            <div class="row text-center mt-3 mt-lg-5">
                <div class="col-md-8 offset-md-2">
                    <img src="/images/illustrations/dm-success.svg" class="img-fluid mb-4 success-image"/>
                    <h2><b>{{translate('results_success_title')}}!</b></h2>
                    <p> 
                        <translation label="download_text">
                            <a href="/datamarketing" class="link">{{translate('historiek').toLowerCase()}}</a>
                            <span>{{availableUntil.format()}}</span>
                        </translation>
                    </p>

                    <template v-if="!!currentRequest.fileGenerationDate">
                        <a 
                        v-if="canExport"
                        class="btn btn-success btn-xl mt-4"   
                        :href="currentRequest.exportFileLink">
                            {{translate('btn_download')}}<i class="fas ml-2 fa-download"></i>
                        </a>
                        
                        <a 
                        v-else
                        class="btn btn-success btn-xl mt-4"  
                        :disabled="!selectionFitsInPlan" 
                        @click.prevent="tryToComplete()">
                            {{translate('btn_download')}}<i class="fas ml-2 fa-download"></i>
                        </a>
                    </template>

                    <div class="font-weight-bold mt-2">{{currentRequest.reference}}</div>
                    <div v-if="!!currentRequest.fileGenerationDate" class="text-grey font-small mt-1" v-html="translate('download_btn_desc', currentRequest.amountOfCompanies, requestDate.format())"></div>
                    
                    <div class="tip warning mt-4" v-if="isTrial">
                        <p class="mb-0">
                            {{translate('trial_warning')}}
                        </p>
                    </div>
                    <div class="tip warning mt-4" v-else-if="currentRequest.status !== RequestStatus.FINISHED && selectionNeedsManualAction">
                        <p class="mb-0">
                            {{translate('warning_selection_bigger_than_limit', selectionUpperLimit)}}
                        </p>
                    </div>
                    <div class="tip warning mt-4 text-left" v-else-if="currentRequest.status !== RequestStatus.FINISHED && !selectionFitsInPlan">
                        <p class="mb-0">
                            {{translate('warning_selection_bigger_than_plan')}}
                        </p>

                        <form :action="'/order/microtransaction/data-marketing/' + upsellAmount" method="POST" ref="mtForm" class="mt-3" @submit.prevent="startMicroTransaction">
                            <div class="form-group">
                                <input id="terms" type="checkbox" class="form-check-input" v-model="$v.acceptMollieTerms.$model" />
                                <label for="terms">
                                    <translation label="ik_ga_akkoord_met">
                                        <a :href="termsAndConditionsLink" class="link" target="_blank">{{translate('algemene_voorwaarden').toLowerCase()}}</a>
                                    </translation>
                                </label>
                            </div>
                            <template v-if="$v.acceptMollieTerms.$invalid && $v.acceptMollieTerms.$dirty">
                                <div class="card-box small my-2 warning">
                                    <translation label="validation_error_required"><span>{{translate('algemene_voorwaarden')}}</span></translation>
                                </div>
                            </template>

                            <button type="submit" class="btn btn-success">{{translate('buy_credits_btn')}}</button>
                        </form>
                    </div>
                </div>
            </div>
            
            
            <!-- SUPERADMIN BOX -->
            <div v-if="isLoggedInAsCustomer || isSuperAdmin" class="row mt-5 pt-4">
                <div class="col-md-12">
                    <div class="box">
                        <span class="box-title"><i class="mr-2 fas fa-gem"></i>Super admin</span>
                        <div class="row">
                            <div class="col-md-4">
                                <b>Bestand</b>
                                <a v-if="!!currentRequest.fileGenerationDate" class="btn btn-default mt-3 d-block" :href="currentRequest.exportFileLink">
                                    Download <i class="fas ml-2 fa-download"></i>
                                </a>
                                <div v-else-if="isGenerating">
                                    Generating file...
                                </div>
                                <a v-else class="btn btn-default mt-3 d-block" href="#" @click.prevent="generateFileAsSuperadmin">
                                    Genereer <i class="fas ml-2 fa-sync"></i>
                                </a>
                            </div>
                            <div class="col-md-4">
                                <div>
                                    <btw-file-input @input="onNewFileInput"></btw-file-input>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="!isPaid">
                                <b>Markeren als betaald</b>
                                <button class="btn btn-default btn-block mt-3 "  @click.prevent="setIsPaid()">
                                    Betaald <i class="fas ml-2 fa-euro-sign"></i>
                                </button>
                            </div>
                        </div>
                        
                        <div class="row mt-4">
                            <div class="col-md-4">
                                <div class="font-weight-bold">Validatie datum</div>
                                {{currentRequest.validationDate}}
                            </div>
                            <div class="col-md-4">
                                <div class="font-weight-bold">Is betaald?</div>
                                {{currentRequest.isPaid}}
                            </div>
                            <div class="col-md-4">
                                <div class="font-weight-bold">Aanmaakdatum bestand</div>
                                {{currentRequest.fileGenerationDate}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import _ from 'lodash';
    import Translate from '../../../mixins/translate';
    import mixins from 'vue-typed-mixins';
    import Progress from '../../progress';
    import {DataMarketingRequestResultSummary} from '../../../../types/dto/DataMarketingRequestResultSummary';
    import ResultBar from './result-bar.vue';
    //@ts-ignore
    import {maxLength, minLength, required, sameAs} from 'vuelidate/dist/validators.min';
    import {DataMarketingRequestType} from '../../../../types/dto/DataMarketingRequestType';
    import {RadarCustomer, RequestStatus} from '../../types';
    import {Language} from '../../../../types/dto/Language';
    import DataMarketingService, {Request} from '../../dataMarketingService';
    import Toast from '../../../../lib/Toast';
    import BtwFileInput from '../../../shared/btw-file-input.vue';
    import Translation from '../../../shared/translation.vue';
    import Ajax from '../../../../lib/Ajax';
    import {SaveDataMarketingRequestResponse} from '../../../../types/dto/SaveDataMarketingRequestResponse';

    const alphaNumeric = (value: string) => /^([A-Za-z]|[0-9]|_| |-|[èéàáçêô])+$/.test(value);
    
    export default mixins(Translate).extend({
        name: 'dm-results',
        components: { ResultBar, BtwFileInput, Translation },
        props: {
            progress: Object as () => Progress,
        },
        data() {
            return {
                isLoggedInAsCustomer: window.cw.isLoggedInAsCustomer,
                termsOfUseLink: window.cw.termsOfUseLink,
                termsAndConditionsLink: window.cw.termsAndConditionsLink,
                isSuperAdmin: window.cw.isSuperAdmin,
                isDeveloperOrManager: window.cw.isDeveloperOrManager,
                newReference: DataMarketingService.getInstance().currentRequest.reference ?? '',
                acceptTerms: false,
                isLoading: false,
                isGenerating: false,
                selectedLanguage: DataMarketingService.getInstance().currentRequest.exportLanguage,
                Language,
                RequestStatus,
                isSetAsPaidByAdmin: false,
                polling: null as NodeJS.Timer | null,
                hasFullAccess: window.cw.hasFullAccess as boolean,
                acceptMollieTerms: false,
                isTrial: window.cw.isTrial,
                radarCustomers: window.cw.radarCustomers as RadarCustomer[],
                radarCustomerId: 299698 as number | null,
                sentToRadar: false,
                datasetName: "",
                filterCompanyName: ""
            }
        },
        validations: {
            newReference: {
                required: required,
                minLength: minLength(3),
                maxLength: maxLength(50),
                alphaNumeric: alphaNumeric
            },
            acceptTerms: {
                sameAs: sameAs(() => true)
            },
            acceptMollieTerms: {
                sameAs: sameAs(() => true)
            }

        },
        computed: {
            filteredRadarCustomers(): RadarCustomer[] {
                return this.radarCustomers.filter(c => !this.filterCompanyName || c.companyName.cw_contains(this.filterCompanyName));
            },
            upsellAmount(): number
            {
                const amountOverLimit = this.totalNumberOfCompanies - this.currentRequest.numberOfAvailableCompanies;    
                
                return amountOverLimit + (1000 - amountOverLimit%1000);
            },
            totalNumberOfCompanies(): number {
                return this.currentRequest.status === RequestStatus.STARTED 
                    ? this.summary.countTotal 
                    : this.currentRequest.amountOfCompanies!;
            },
            requestDate(): Date | null {
                return this.currentRequest.validationDate != null 
                    ? Date.fromHansDate(this.currentRequest.validationDate) 
                    : null;
            },
            availableUntil(): Date {
                return new Date(this.requestDate?.getTime() ?? new Date().getTime()).addYears(1);
            },
            currentRequest(): Request {
                return DataMarketingService.getInstance().currentRequest;
            },
            selectionUpperLimit(): number {
                return this.currentRequest.selectionUpperLimit;
            },
            summary(): DataMarketingRequestResultSummary {
                return this.currentRequest.summary as DataMarketingRequestResultSummary
            },
            resultBars(): Array<{ count: number, label: string }> {
                return _.map(this.summary, (count: number, label: string) => ({
                    count,
                    label
                })
                ).sort((a, b) => b.count - a.count);
            },
            hasReference: {
                cache: false,
                get(): boolean {
                    return this.currentRequest.hasReference;
                }
            },
            selectionFitsInPlan(): boolean {
                return this.totalNumberOfCompanies <= this.currentRequest.numberOfAvailableCompanies;
            },
            selectionNeedsManualAction(): boolean {
                return this.totalNumberOfCompanies > this.selectionUpperLimit;
            },
            canFinishRequest(): boolean {
                return (this.currentRequest.hasSelections || this.currentRequest.type === DataMarketingRequestType.DATAVERRIJKING) && this.hasFullAccess;
            },
            isLocked(): boolean {
                return this.status !== RequestStatus.STARTED;
            },
            isPaid(): boolean {
                return this.currentRequest.isPaid || this.isSetAsPaidByAdmin;
            },
            canExport(): boolean {
                return this.isPaid && !!this.currentRequest.validationDate && !!this.currentRequest.fileGenerationDate;
            },
            status(): RequestStatus {
                return this.currentRequest.status;
            },
            type(): DataMarketingRequestType {
                return this.currentRequest.type;
            }
        },
        methods: {
            async sendToRadar() {
                await Ajax.postAsync("/ajax/radar/create-dataset", {
                    requestId: this.currentRequest.id,
                    customerId: this.radarCustomerId,
                    datasetName: this.datasetName
                });
                this.sentToRadar = true;
            },
            startMicroTransaction(ev: Event) {
                this.$v.$touch();
                
                if (this.$v.acceptMollieTerms.$invalid)
                    return;

                this.$v.$reset();
                (this.$refs.mtForm! as HTMLFormElement).submit();
            },
            async setIsPaid(): Promise<void> {
                await this.currentRequest.setIsPaid();
                Toast.success("Gemarkeerd als betaald.");
                this.isSetAsPaidByAdmin = true;
            },
            async saveRequest(): Promise<void> {
                this.$v.$touch();
                
                if (this.$v.newReference.$invalid || this.$v.acceptTerms.$invalid)
                    return;
                try {
                    this.isLoading = true;
                    await this.currentRequest.saveRequest(this.newReference, this.selectedLanguage);
                    this.isLoading = false;
                    await this.pollForUpdates();
                }
                catch(e: unknown) {
                    this.isLoading = false;
                }
                this.$v.$reset();
                this.progress.lockPreviousSteps();

            },
            async tryToComplete(): Promise<void> {
                this.isLoading = true;
                try {
                    const response = await this.currentRequest.saveRequest(this.newReference, this.selectedLanguage);
                    if(response.isPaid)
                        window.location.href = this.currentRequest.exportFileLink
                    
                    this.isLoading = false;
                } catch {
                    this.isLoading = false;
                }
            },
            async onNewFileInput(emitResult: VatInputEmit) {
                try {
                    await this.currentRequest.overrideFile(emitResult);
                    window.location.reload();
                    
                } catch(e: unknown) {
                    Toast.error(e as string);
                }
                
            },
            async generateFileAsSuperadmin() {
                this.isGenerating = true;
                await Ajax.postAsync<SaveDataMarketingRequestResponse>('/ajax/datamarketing/' + this.currentRequest.id + '/generate', null, 60000);
                this.isGenerating = false;
                window.location.reload();
            },
            async pollForUpdates() {
                if(!this.currentRequest.fileGenerationDate && !!this.currentRequest.validationDate && !this.selectionNeedsManualAction) {
                    await this.getUpdate();
                    
                    this.polling = setInterval(async () => {
                        await this.getUpdate();
                    }, 2000)
                }
            },
            async getUpdate() {
                this.isLoading = true;
                await this.currentRequest.getRequest(this.currentRequest.id);
                
                if(!!this.currentRequest.fileGenerationDate) {
                    clearInterval(this.polling!);
                    this.isLoading = false;
                }            
            }
        },
        mounted() {
            this.pollForUpdates();
        },
        beforeDestroy() {
            clearInterval(this.polling!);
            this.isLoading = false;
        }
    });
</script>

<style scoped lang="scss">
    .success-image {
        max-width: 165px;
    }
</style>