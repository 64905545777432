﻿<template>
    <div>
        <div :style="{left: trackerPosition + 'px'}" class="tracker d-print-none"></div>
        <div class="years">
            <div v-for="year in years" class="year">
                <div class="label">{{year}}</div>
            </div>
        </div>

        <div class="category">
            <h3>{{translate('algemeen')}}</h3>
            <cw-track :label="translate('mijlpalen')" :events="mijlpalen" :timeline-start="timelineStart" :timeline-end="timelineEnd"></cw-track>

            <template v-if="!hasMultipleNameLanguages">
                <cw-track :label="translate('benaming')" :periods="names" :timeline-start="timelineStart" :timeline-end="timelineEnd"></cw-track>
            </template>
            <template v-else>
                <cw-track v-for="(namesByLanguage, lang) in languages" :key="'name'+lang" :label="translate('benaming') + ' ' + lang.toUpperCase()" :periods="namesByLanguage" :timeline-start="timelineStart" :timeline-end="timelineEnd"></cw-track>
            </template>

            <cw-track :label="translate('maatschappelijke_zetel')" :periods="addresses" :timeline-start="timelineStart" :timeline-end="timelineEnd"></cw-track>
        </div>
        <div class="category">
            <h3>{{translate('juridisch')}}</h3>
            <cw-track :label="translate('rsz-dagvaarding')" :events="dagvaardingen" :timeline-start="timelineStart" :timeline-end="timelineEnd"></cw-track>
            <cw-track :label="translate('inhoudingsplicht')" :periods="inhoudingsplicht" :timeline-start="timelineStart" :timeline-end="timelineEnd" forced-color="#F44336"></cw-track>
            <cw-track :label="translate('gerechtelijkakkoord')" :periods="gerechtelijkakkoord" :timeline-start="timelineStart" :timeline-end="timelineEnd" forced-color="#F44336"></cw-track>
        </div>
        <div class="category">
            <h3>{{translate('mandaathouders')}}</h3>
            <cw-track v-for="(mandateHolder, i) in mandateHolders"
                      :key="i"
                      :label="mandateHolder.name"
                      :type="mandateHolder.type"
                      :link="mandateHolder.link"
                      :periods="mandateHolder.mandates"
                      :timeline-start="timelineStart" 
                      :timeline-end="timelineEnd">
            </cw-track>
        </div>
    </div>
</template>

<script>
    import Track from './track.vue';
    import Translate from 'Mixins/translate';
    import _ from 'lodash';

    export default {
        name: 'timeline',
        components: {
            'cw-track': Track
        },
        props: {
            companyStartDate: Number,
            companyEndDate: Number,
            mijlpalen: Array,
            names: Array,
            addresses: Array,
            financialYears: Array,
            mandateHolders: Array,
            dagvaardingen: Array,
            inhoudingsplicht: Array,
            gerechtelijkakkoord: Array,
        },
        data() {
            return {
                trackerPosition: 0,
                maxYears: 20
            }
        },
        mixins:[Translate],
        provide() {
            return {
                hansDateToTimestamp: this.hansDateToTimestamp,
                hansDateToReadableDate: this.hansDateToReadableDate
            }
        },
        computed: {
            languages() {
                const namesAppearingInTimeline = this.names.filter(n => n.endDate === 0 || n.endDate > this.timelineStart);

                return _.groupBy(namesAppearingInTimeline, 'language');
            },
            hasMultipleNameLanguages() {
                return Object.keys(this.languages).map(e => this.languages[e]).length > 1;
            },
            offsetLeft: function () {
                return this.$parent.$el.offsetLeft;
            },
            timelineStart: function () {
                return Math.max(parseInt(this.companyStartYear.toString() + '0101'), parseInt((this.timelineEnd - ((this.maxYears - 1) * 10000)).toString().substring(0, 4) + '0101'));
            },
            timelineEnd: function () {
                return parseInt(this.companyEndYear.toString() + '1231');
            },
            companyStartYear: function () {
                return this.$root.startDate.toString().substring(0, 4);
            },
            companyEndYear: function () {
                return this.$root.endDate.toString().substring(0, 4);
            },
            years: function () {
                const startYear = parseInt(this.timelineStart.toString().substring(0, 4));
                const endYear = parseInt(this.timelineEnd.toString().substring(0, 4));

                return _.range(startYear, endYear + 1);
            },
            today: function () {
                var today = new Date();
                var dd = today.getDate();
                var mm = today.getMonth() + 1;

                var yyyy = today.getFullYear();
                if (dd < 10)
                    dd = '0' + dd;

                if (mm < 10)
                    mm = '0' + mm;

                return yyyy + mm + dd;
            }
        },
        methods: {
            mouseIsMoving: function (e) {
                this.trackerPosition = e.pageX - this.offsetLeft < 0 ? 0 : e.pageX - this.offsetLeft;
            },
            hansDateToReadableDate: function (hansDate) {
                var regEx = /(\d{4})(\d{2})(\d{2})/;
                return this.zeroDateToPresent(hansDate).toString().replace(regEx, '$3/$2/$1');
            },
            hansDateToTimestamp: function (hansDate) {
                var regEx = /(\d{4})(\d{2})(\d{2})/;
                var date = this.zeroDateToPresent(hansDate).toString().replace(regEx, '$2/$3/$1');

                return new Date(date).getTime();
            },
            zeroDateToPresent: function (date) {
                return date === 0 ? this.today : date;
            }
        },
        mounted: function () {
            window.addEventListener('mousemove', this.mouseIsMoving);
        },
        beforeDestroy() {
            window.removeEventListener('mousemove');
        }
    }
</script>