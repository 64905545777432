
<div class="radar-loader-wrapper">
    <div class="text-center" style="margin-top: -60px;font-weight: bold;font-size: 1.2em;">{{store.currentDataset.title}} </div>
    <div class="loading-bar-wrapper">
        <div class="loading-bar" :style="'width:' + store.loadingPercentage + '%'"></div>
        <span class="loading-percentage">{{store.loadingPercentage}}%</span>
    </div>
    <div class="radar-loader">
        <div class="scanner"></div>
        <div class="dot dot-1"></div>
        <div class="dot dot-2"></div>
        <div class="dot dot-3"></div>
        <div class="dot dot-4"></div>
    </div>
</div>
