﻿<template>
    <g v-if="show">
        <chart-segment v-if="type === 'segment'" v-for="(value, index) in lineValues" v-bind="value" :index="index" :key="'segment-' + index"></chart-segment>
        <chart-node v-if="type === 'node'" v-for="(value, year, index) in valuesPerYear" :value="value" :year="year" :index="index" :key="'node-'+index"></chart-node>
    </g>
</template>

<script>
    import ChartNode from './chart-category/chart-node.vue';
    import ChartSegment from './chart-category/chart-segment.vue';
    import _ from 'lodash';

    export default {
            name: 'chart-category',
            props: {
                isActive: Boolean,
                valuesPerYear: {},
                label: String,
                color: String,
                hasNoValues: Boolean,
                type: String
            },
            provide() {
                return {
                    color: this.color
                }
            },
            components: {
                'chart-node': ChartNode,
                'chart-segment': ChartSegment
            },
            computed: {
                show() {
                    return this.isActive && !this.hasNoValues;
                },
                lineValues() {
                    var valStart = 0;
                    var valEnd = 0;
                    var lines = [];

                    _.forEach(this.valuesPerYear, (val, year) => {
                        if (valStart === 0)
                            valStart = val;
                        else if (valEnd === 0)
                            valEnd = val;

                        if (valStart !== 0 && valEnd !== 0) {
                            var line = {
                                valStart: valStart,
                                valEnd: valEnd,
                                year: year
                            };

                            lines.push(line);
                            line = {};
                            valStart = val;
                            valEnd = 0;
                        }
                    });

                    return lines;
                }
            }
        }
</script>
