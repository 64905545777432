﻿import Page from '../lib/Page';

export default (function () {
    Page.registerBehavior('.collapsible.toggle-collapse, .collapsible .toggle-collapse', function (element: Element) {
        (<HTMLElement>element).addEventListener('click', (ev: Event) => {
            ev.stopPropagation();
            ev.preventDefault();

            const collapsible = element.closest('.collapsible')! as HTMLElement;
            
            if(collapsible.classList.contains('collapsed'))
                collapsible.classList.remove('collapsed');
            else
                collapsible.classList.add('collapsed');
        });
    });

    Page.registerBehavior('[data-toggle="collapse"]', function (element: Element) {
        (<HTMLElement>element).addEventListener('click', (ev: Event) => {
            ev.stopPropagation();
            ev.preventDefault();

            const collapsible = element.parentElement?.querySelector('.collapsible')! as HTMLElement;
            if(!collapsible)
                return;

            if(collapsible.classList.contains('collapsed'))
                collapsible.classList.remove('collapsed');
            else
                collapsible.classList.add('collapsed');
        });
    });
}());
