﻿import Dialog  from '../lib/Dialog';
import Page from '../lib/Page';
import { SweetAlertType } from 'sweetalert2';

export default (function () {
    Page.registerBehavior('[data-dialog]', function (element) {
        const type = element.getAttribute('data-dialog-type') as SweetAlertType;
        const title = element.getAttribute('data-dialog-title')!;
        const body = element.getAttribute('data-dialog-body')!;
    
        Dialog({
            type,
            title,
            body
        });
    });  
}());
