
<form @submit.prevent="onSubmit">
    <div class="row mb-4 mt-5">
        <div class="col-8">
            <ul class="nav nav-tabs border-bottom">
                <li class="nav-item" >
                    <a href="" class="nav-link" :class="{'active': currentTab === Tab.ALGEMEEN}" @click.prevent="changeTab(Tab.ALGEMEEN)">
                        <i class="fas fa-info-circle mr-1 text-lightgrey"></i> Algemeen</a>
                </li>
                 <li class="nav-item" >
                    <a href="" class="nav-link" :class="{'active': currentTab === Tab.NEDERLANDS}" @click.prevent="changeTab(Tab.NEDERLANDS)">
                        <img src="/images/flags/nl.svg" class="mr-1 height-auto" width="13" height="13"> Nederlands</a>
                </li>
                <li class="nav-item" >
                    <a href="" class="nav-link" :class="{'active': currentTab === Tab.FRANS}" @click.prevent="changeTab(Tab.FRANS)">
                        <img src="/images/flags/fr.svg" class="mr-1 height-auto" width="13" height="13"> Francais</a>
                </li>
                <li class="nav-item" >
                    <a href="" class="nav-link" :class="{'active': currentTab === Tab.ENGELS}" @click.prevent="changeTab(Tab.ENGELS)">
                        <img src="/images/flags/en.svg" class="mr-1 height-auto" width="13" height="13"> English</a>
                </li>
            </ul>
        </div>
        <div class="col-4 text-right">
            <button type="submit" class="btn btn-blue btn-200"><i class="fas fa-save mr-1"></i> Opslaan</button>
        </div>
    </div>

    <!-- TAB ALGEMEEN -->
    <div class="row"  v-if="currentTab === Tab.ALGEMEEN">
        <div class="col-6">
            <div class="form-group">
                <div class="row">
                    <div class="col-7">
                    <file-input 
                        v-on:input="onImageUpload" 
                        :multiple="false" 
                        :required="false" 
                        name="banner"
                        placeholder=".jpg" 
                        title="Upload image"
                        accept=".jpg" ></file-input>
                    </div>
                    <div class="col-5 mt-auto">
                        <img :src="image.path" v-if="image" class="img-fluid"/>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <input id="isPinned" type="checkbox" v-model="isPinned" class="form-check-input">
                <label for="isPinned">In de kijker?</label>
            </div>

             <div class="form-group">
                <input id="isPublished" type="checkbox" v-model="isPublished" class="form-check-input">
                <label for="isPublished">Published?</label>
            </div>

            <hr> 
            <h2>Tags</h2>
            <ul class="mt-3">
                <li v-for="tag in Tags" :key="tag">
                    <input class="form-check-input" :value="tag" :id="tag" type="checkbox" v-model="related_tags">
                    <label :for="tag">{{tag}}</label>
                </li>
            </ul>
        </div>
    </div>
    <div class="row" v-else> 
        <div class="col-12">
             <div class="form-group">
                <label>Titel {{currentTab.toUpperCase()}}</label>
                <input type="text" class="form-control" v-model="title[currentTab]">
            </div> 

            <div class="form-group">
                <label>Excerpt {{currentTab.toUpperCase()}}</label>
                <vue-editor v-model="excerpt[currentTab]" :editorToolbar="customToolbarExcerpt"></vue-editor>
            </div> 

            <div class="form-group big-editor">
                <label>Tekst {{currentTab.toUpperCase()}}</label>
                <vue-editor v-model="text[currentTab]" :editorToolbar="customToolbar"></vue-editor>
            </div> 
        </div>
    </div>


</form>
