﻿<template>
    <div>
        <div class="mb-4">
            <upsell-banner v-if="triedToExceedLimit && $root.amountOfFollowupsSuggested <= 500"
                           :title="translate('upsell_title_limit_reached_during_upload')"
                           :text="translate('upsell_text_limit_reached_during_upload', currentList.Naam)"
                           :href="increaseAlertsLink">
            </upsell-banner>
        </div>
        <div class="row mt-3 position-relative">
            <div class="col-md-6">
                <div>
                    <div class="mb-1"><b>{{translate('choose_selection_method_all_label')}}</b></div>
                    <input type="radio" id="all" value="all" v-model="uploadType" class="image-checkbox">
                    <label for="all">
                        <img src="/images/illustrations/upload-all.svg" />
                    </label>
                </div>
            </div>
            <div class="text-center font-italic text-grey middle-or">
                {{translate('or').toLowerCase()}}
            </div>
            <div class="col-md-6 pt-5 pt-md-0">
                <div>
                    <div class="mb-1">
                    <b>{{translate('choose_selection_method_screening_label')}}*</b>
                    <span class="ml-2" v-if="!canAccessScreening">({{translate('only_in_premium')}})</span></div>
                    <input type="radio" id="screening" value="screening" v-model="uploadType" class="image-checkbox" :disabled="!canAccessScreening">
                    <label for="screening" @click="triedToClickScreening()">
                        <img src="/images/illustrations/upload-screen.svg" />
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 offset-md-6">
                <p class="font-small mb-0">
                   * {{translate('choose_selection_method_screening_explanation')}}
                </p>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <button type="submit" class="btn btn-blue btn-200" @click="submitForm()">
                    {{translate('btn_complete')}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Ajax from 'Lib/Ajax';
    import Toast from 'Lib/Toast';
    import Translate from 'Mixins/translate';
    import UpsellBanner from '../../shared/upsell-banner.vue';
    import Vue from 'vue';

    export default Vue.extend({
        name: 'choose-upload-type',
        mixins: [Translate],
        components: { UpsellBanner },
        props: {
            vatsString: String,
            selectedColumnIndex: Number,
            currentList: Object
        },
        data() {
            return {
                uploadType: 'all',
                triedToExceedLimit: false,
                upgradeScreeningLink: '/upgrade/alerts-screening',
                increaseAlertsLink: '/upgrade/alerts'
            }
        },
        computed: {
            hasVats() { return this.vatsString !== undefined && this.vatsString !== ''; },
            canAccessScreening() { return this.$root.canAccessScreening; }
        },
        methods: {
            triedToClickScreening() {
                if (!this.canAccessScreening) {
                    Ajax.get(
                        "/ajax/followup/create-lead-screening",
                        {},
                        () => { },
                        err => console.error(err)
                    );
                }
            },
            submitForm() {
                if (this.uploadType === 'all') {
                    if (this.hasVats)
                        this.addCompaniesViaText();
                    else
                        this.addCompaniesViaFile();
                }
                else {
                    this.getScreenedCompanies();
                }

            },
            updateList() {
                return new Promise((resolve, reject) => {
                    Ajax.get(
                        '/ajax/followup',
                        {
                            listId: this.currentList.ListId
                        },
                        (response) => {
                            let companies = response.Data.Companies;
                            let events = response.Data.FollowUpEvents;
                            this.$root.$emit('updateList', {
                                listId: this.currentList.ListId,
                                companies,
                                events
                            });
                            resolve();
                        },
                        () => {
                            reject();
                        }
                    );
                });
            },
            addCompaniesViaText() {
                this.$root.$emit('setLoading', true);
                Ajax.post(
                    '/ajax/followup/add-companies',
                    {
                        listId: this.currentList.ListId,
                        vats: this.vatsString
                    },
                    () => {
                        this.updateList()
                            .then(() => {
                                this.$root.$emit('setLoading', false);
                                this.$root.$emit('change-used-companies');
                                this.$root.$emit('hide-modal', 'addCompanies');
                                this.$root.$emit('hide-modal', 'addList');

                                Toast.success(this.translate('list_updated', this.currentList.Naam));
                            });

                    },
                    err => {
                        Toast.error(err);
                        this.$root.$emit('setLoading', false);
                        this.triedToExceedLimit = true;
                    }
                )
            },
            addCompaniesViaFile() {
                this.$root.$emit('setLoading', true);
                Ajax.post(
                    '/ajax/followup/select-vat-index',
                    {
                        listId: this.currentList.ListId,
                        VatColumnIndex: this.selectedColumnIndex
                    },
                    () => {
                        this.updateList().then(() => {
                            this.$root.$emit('setLoading', false);
                            this.$root.$emit('change-used-companies');
                            this.$root.$emit('hide-modal', 'addCompanies');
                            this.$root.$emit('hide-modal', 'addList');

                            Toast.success(this.translate('list_updated', this.currentList.Naam));
                        })
                    },
                    err => {
                        this.$root.$emit('setLoading', false);
                        this.triedToExceedLimit = true;
                        Toast.error(err)
                    }
                )
            },
            getScreenedCompanies() {
                this.$root.$emit('setLoading', true);
                if (this.hasVats) {
                    Ajax.post(
                        '/ajax/followup/screen-companies/vats',
                        {
                            listId: this.currentList.ListId,
                            vats: this.vatsString
                        },
                        response => this.showScreening(response.Data),
                        err => {
                            this.$root.$emit('setLoading', false);
                            console.error(err);
                            Toast.error(err);
                        }
                    )
                } else {
                    Ajax.post(
                        '/ajax/followup/screen-companies/index',
                        {
                            listId: this.currentList.ListId,
                            selectedColumnIndex: this.selectedColumnIndex
                        },
                        response => this.showScreening(response.Data),
                        err => {
                            this.$root.$emit('setLoading', false);
                            console.error(err);
                            Toast.error(err);
                        }
                    )
                }

            },
            showScreening(barometerstatusses) {
                this.$root.$emit('setLoading', false);
                this.$root.$emit('change-view', {
                    viewName: 'screening',
                    viewData: {
                        barometerstatusses: barometerstatusses
                    },
                    currentList: this.currentList
                });
            }
        }
    });
</script>
