﻿<template>
    <div class="row mt-4">
        <div class="col-md-12">
            <div class="box">
                <div class="box-title">{{translate('settings')}}</div>
                <form @submit.prevent="updateList()">
                    <div class="row">
                        <div class="col-md-12 col-lg-4">
                            <label>{{translate('naam')}}</label>
                            <input type="text" class="form-control" v-model="form.listName" />

                            <template v-if="$v.form.listName.$invalid && $v.form.listName.$dirty">
                                <div class="card-box small my-2 warning" v-if="!$v.form.listName.required">
                                    {{translate('validation_error_required', translate('naam'))}}
                                </div>
                                <div class="card-box small my-2 warning" v-else-if="!$v.form.listName.maxLength">
                                    {{translate('validation_error_max_length', 50)}}
                                </div>
                            </template>

                        </div>
                        <div class="col-md-12 col-lg-4  my-2 my-lg-0" v-if="!form.isPrivate">
                            <label>{{translate('followers_label')}}</label>
                            <users-dropdown v-model="form.followers" :is-private="form.isPrivate"></users-dropdown>

                            <div class="card-box small my-2 warning" v-if="$v.form.followers.$invalid && $v.form.followers.$dirty">
                                {{translate('validation_error_followers_required')}}
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-4" v-if="canHaveSharedLists">
                            <label>{{translate('private_list_label')}}</label>
                            <div>
                                <input id="private" type="radio" class="form-check-input" v-model="form.isPrivate" :value="true" :disabled="hasOtherFollowers" />
                                <label for="private">{{translate('private_list_label_me')}}</label>
                            </div>
                            <div>
                                <input id="shared" type="radio" class="form-check-input" v-model="form.isPrivate" :value="false" />
                                <label for="shared">{{translate('private_list_label_all')}}</label>
                            </div>
                        </div>
                        <div class="col-md-6 mt-2">
                            <button type="submit" class="btn btn-default btn-200">
                                {{translate('btn_save')}}
                            </button>
                        </div>
                        <div class="col-md-6 text-right mt-auto" v-if="!currentList.IsReadOnly">
                            <a v-confirm="deleteConfirmConfig" class="link text-grey ml-2 pointer"><i class="fas fa-trash-alt mr-2"></i>{{translate('confirm_delete_list_confirm_button')}}</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-md-12 mt-3" v-if="currentList.IsReadOnly">
            <div class="info-box">{{translate('info_readonly_list')}}</div>
        </div>
     
        <div class="col-md-12 mt-5">
            <div class=" menu-items-wrapper align-left mb-4" v-if="showPersonTab || showAddressTab">
                <div class="menu-items">
                    <a href="" class="menu-item" :class="{'active': currentTab === 'company-list'}" @click.prevent="currentTab = 'company-list'">
                        <i class="fas fa-building mr-2"></i> {{translate('tab_companies')}}
                        <span class="badge badge-pill badge-secondary float-right badge-fixed ml-2" :class="{'badge-blue': currentTab === 'company-list'}">{{currentList.NumberOfCompanies}}</span>
                    </a>
                    <a v-if="showPersonTab" href="" class="menu-item" :class="{'active': currentTab === 'person-list'}" @click.prevent="currentTab = 'person-list'">
                        <i class="fas fa-user-tie mr-2"></i> {{translate('tab_persons')}}
                        <span class="badge badge-pill badge-secondary float-right badge-fixed ml-2" :class="{'badge-blue': currentTab === 'person-list'}">{{currentList.NumberOfPersons}}</span>
                    </a>
                    <a v-if="showAddressTab" href="" class="menu-item" :class="{'active': currentTab === 'address-list'}" @click.prevent="currentTab = 'address-list'">
                        <i class="fas fa-map-marker-alt mr-2"></i> {{translate('tab_addresses')}}
                        <span class="badge badge-pill badge-secondary float-right badge-fixed ml-2" :class="{'badge-blue': currentTab === 'address-list'}">{{currentList.NumberOfAddresses}}</span>
                    </a>
                </div>
            </div>

            <component :is="currentTab" :current-list="currentList"></component>
        </div>
    </div>
</template>

<script>
    import { required, maxLength } from 'vuelidate/dist/validators.min';
    import Ajax from 'Lib/Ajax';
    import Page from 'Lib/Page';
    import UsersDropdown from './users-dropdown.vue';
    import _ from 'lodash';
    import Translate from 'Mixins/translate';
    import Toast from 'Lib/Toast';
    import Confirm from 'Directives/confirm';
    import Vue from 'vue';
    import CompanyList from "./list-management/company-list.vue";
    import PersonList from "./list-management/person-list.vue";
    import AddressList from "./list-management/address-list.vue";

    const isPrivateOrHasFollowers = (followers, form) => form.isPrivate || _.filter(followers, f => f.IsFollower).length > 0;

    export default Vue.extend({
        name: 'list-management',
        components: {
            CompanyList,
            PersonList,
            AddressList,
            UsersDropdown
        },
        mixins: [Translate],
        props: {
            currentList: Object,
            users: Array
        },
        directives: {
            'confirm': Confirm
        },
        data() {
            return {
                currentTab: "company-list",
                form: {
                    listName: this.currentList.Naam,
                    followers: _.map(this.users, user => {
                        return {
                            ...user,
                            IsFollower: _.includes(_.map(this.currentList.Followers, 'UserId'), parseInt(user.UserId))
                        }
                    }),
                    isPrivate: this.currentList.IsPrivate
                },
                showPersonTab: window.cw.isPersonFollowupEnabled,
                showAddressTab: window.cw.isAddressFollowupEnabled,
                deleteConfirmConfig: {
                    handler: this.deleteList,
                    title: this.translate('confirm_delete_list_title', this.currentList.Naam),
                    body: this.translate('confirm_delete_list_body'),
                    type: 'error',
                    confirmButton: this.translate('confirm_delete_list_confirm_button'),
                    cancelButton: this.translate('confirm_delete_list_cancel_button')
                }
            };
        },
        validations: {
            form: {
                listName: {
                    required,
                    maxLength: maxLength(50)
                },
                followers: {
                    isPrivateOrHasFollowers
                }
            }
        },
        watch: {
            currentList: function (list) {
                this.form.listName = list.Naam;
                this.form.followers = _.map(this.users, user => {
                    return {
                        ...user,
                        IsFollower: _.includes(_.map(this.currentList.Followers, 'UserId'), parseInt(user.UserId))
                    }
                })
            }
        },
        computed: {
            canHaveSharedLists() {
                return this.$root.canHaveSharedLists;
            },
            hasOtherFollowers() {
                return _.some(this.form.followers, follower => {
                    return parseInt(follower.UserId) !== this.$root.userId && follower.IsFollower;
                });
            },
            increaseAlertsLink() {
                return '/upgrade/alerts';
            },
        },
        methods: {
            updateList() {
                this.$v.$touch();
                if (this.$v.form.$invalid)
                    return;

                const postData = {
                    ListId: this.currentList.ListId,
                    Naam: this.form.listName,
                    IsPrivate: this.form.isPrivate,
                    FollowerIds: this.form.followers
                        .filter(follower => follower.IsFollower)
                        .map(follower => follower.UserId)
                };

                Ajax.post(
                    '/ajax/followup/update',
                    postData,
                    (response) => {
                        //this.$parent.currentList.Naam = this.form.listName;
                        this.$parent.currentList.Followers = this.form.followers;
                        this.$parent.currentList.IsPrivate = this.form.isPrivate;

                        this.$root.$emit('updateList', {
                            listId: this.currentList.ListId,
                            name: this.form.listName
                        });
                        Toast.success(response.Data);
                    },
                    (e) => {
                        console.error(e)
                    }
                )
            },
            deleteList() {
                this.$root.$emit('setLoading', true);
                Ajax.post(
                    '/ajax/followup/delete',
                    {
                        listId: this.currentList.ListId
                    },
                    response => {
                        window.location.href = "/followup";
                    },
                    () => {
                        Toast.error(this.translate('error_message_removing_list'))
                    }
                )
            }
        },
        mounted() {
            if (!this.canHaveSharedLists) 
                this.form.isPrivate = true;
            
            Page.applyBehaviors('#opvolging');
        }
    });
</script>