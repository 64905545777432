
<ul class="pl-3">
    <li v-for="node in nodes" :key="node.id">
        <span v-if="node.children && node.children.length > 0 && !alwaysOpen" @click="onExpandClick(node)" class="expander">
            <i aria-hidden="true" class="fas fa-lg fa-angle-down" v-if="isExpanded(node)"></i>
            <i aria-hidden="true" class="fas fa-lg fa-angle-right text-lightgrey" v-else></i> 
        </span>

        <input type="checkbox" class="form-check-input" :id="type + node.id" :checked="isSelected(node, type)" @change="select(node, $event, type, subType)" :disabled="isSelectedAndDisabled"/>
        <label :for="type + node.id">{{node.name}}</label>

        <select-tree v-if="node.children && isExpanded(node)" :isParentSelected="isSelected(node, type)" :criteria="node.children" :type="subType || type" :filterTerm="filterTerm"></select-tree>
    </li>
</ul>
