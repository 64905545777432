
<div class="cw-dropdown" id="cw-dropdown">
    <div class="cw-dropdown__label" @click="toggleShowList()">{{label}}</div>
    <ul class="cw-dropdown__list" :class="{'active': showList}">
        <li v-for="record in value" :key="record.id">
            <input class="form-check-input azure" :id="record.id" type="checkbox" v-model="record.isChecked" @change="emitData()"/> 
            <label :for="record.id"><span>{{record.label}}</span></label>
        </li>
    </ul>
</div>
