<template>
    <aside>
        <a href="" class="d-block d-lg-none position-absolute right-0 top-0 p-3 text-grey" @click.prevent="closeMobileCriteria">
            <i class="fas fa-times fa-lg"></i>
        </a>
        
        <credits-counter :amount="total" :label="translate('credits_counter_label')"></credits-counter>
        
        <div class="box d-none d-lg-block p-3 pt-4 mt-3 mt-lg-5 " v-if="shouldSeeCriteriaCounter">
            <h3 class="box-title">{{boxTitle}} </h3>
            <div>
                <span class="selected">{{displayValue|formatNumber}}</span> {{translate("companies")}}
            </div>
            <div class="mt-4" v-if="!isLastStep">
                <button class="btn btn-info btn-block" @click.prevent="nextStep()" :disabled="isNextStepDisabled">{{translate("btn_next")}} <i class="fas fa-angle-right ml-2"></i></button>
            </div>
            <div class="mt-3 text-center" v-if="!isLastStep && !isNextStepDisabled">
                <a class="link text-grey" href="" @click.prevent="goToLastStep()"><i class="fas fa-check-double mr-1"></i> {{translate("btn_finish_request")}}</a>
            </div>
        </div>

        <template v-if="isProspection">
            <div class="mt-3 mt-lg-5 box bg-white" v-if="hasSelections || hasExcludedVats">
                <div class="box-title">{{translate("criteria")}}</div>
                <div class="mt-2"></div>
                <div v-if="hasExcludedVats">
                    <h3>{{translate("sidebar_exclude")}}</h3>
                    <ul class="mb-4">
                        <li v-if="hasExcludedViaFile">
                            <a href="" @click.prevent="removeExclusionViaFile()" class="delete-link" :class="{'pe-none': isLocked}">
                                <i class="fas fa-times" v-if="!isLocked"></i> {{translate("via_file")}}
                            </a>
                        </li>
                        <li v-for="(request, idx) in excludedRequests" :key="'exclusion_' + idx">
                            <a href="" @click.prevent="removeExclusionViaRequest(request)" class="delete-link" :class="{'pe-none': isLocked}">
                                <i class="fas fa-times" v-if="!isLocked"></i> {{request.requestDate}} {{request.reference}}
                            </a>
                        </li>
                    </ul>
                </div>
                <div v-for="(group, name) in groupedCriteria" :key="'cat_' + name">
                    <div v-if="group.length > 0">
                        <h3>{{translate(name)}}</h3>
                        <ul class="mb-4">
                            <li v-for="(criterium, idx) in group" :key="'node_crit_' + idx">
                                <a href="" @click.prevent="removeCriterium(criterium)" class="delete-link" :class="{'pe-none': isLocked}">
                                    <i class="fas fa-times" v-if="!isLocked"></i> {{formatCriterium(criterium)}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <p class="my-5 text-center" v-else>{{translate("no_selected_criteria")}}</p>

            <form v-if="!isPaid" :action="currentRequest.hideRequestLink" method="POST" class="text-center">
              <button class="no-style-btn btn-link mt-3 hover-underline" type="submit">
                  <span class="text-grey "><i class="fas fa-trash mr-1"></i> {{translate("delete_request")}}</span>
                  
              </button>
            </form>
        </template>
    </aside>
</template>

<script lang="ts">
    import { TreeData, RequestStatus } from '../../types';
    import gsap from 'gsap';
    import _, { Dictionary } from 'lodash';
    import DataMarketingService, { Request } from '../../dataMarketingService';
    import CriteriumRange from '../../criteriumRange';
    import Progress from '../../progress';
    import { Language } from '../../../../types/dto/Language';
    import { ProspectieDataSelectionCriterium } from '../../../../types/dto/ProspectieDataSelectionCriterium';
    import { ProspectieDataSelectionCriteriumType } from '../../../../types/dto/ProspectieDataSelectionCriteriumType';

    import mixins from 'vue-typed-mixins';
    import Translate from '../../../mixins/translate';
    import CreditsCounter from '../../../shared/credits-counter.vue';
    import { DataMarketingRequestType } from '../../../../types/dto/DataMarketingRequestType';
    import { IProspectieDataTreeNode } from '../../../../types/dto/IProspectieDataTreeNode';
    import { PrepaidDataMarketingRequest } from '../../../../types/dto/PrepaidDataMarketing';
  
    export default mixins(Translate).extend({
        name: 'dm-result-counter',
        props: {
            boxTitle: String,
            progress: Object as () => Progress
        },
        components: { CreditsCounter },
        data() {
            return {
                displayValue: DataMarketingService.getInstance().currentRequest.summary?.countTotal ?? 0,
                tweenValue: DataMarketingService.getInstance().currentRequest.summary?.countTotal ?? 0,
                treeData: DataMarketingService.getInstance().treeData!,
                Language
            }
        },
        computed: {
            isProspection(): boolean {
                return this.currentRequest.type === DataMarketingRequestType.PROSPECTIEDATA;
            },
            currentRequest(): Request {
                return DataMarketingService.getInstance().currentRequest;
            },
            isNextStepDisabled(): boolean {
                return this.progress.isNextStepDisabled;
            },
            total(): number {
                return this.currentRequest.numberOfAvailableCompanies;
            },
            shouldSeeCriteriaCounter(): boolean {
                return !this.hasFile;
            },
            status(): RequestStatus {
                return this.currentRequest.status;
            },
            isPaid(): boolean {
                return this.currentRequest.isPaid;
            },
            hasFile(): boolean {
                return !!this.currentRequest.fileGenerationDate;
            },
            isLocked(): boolean {
                return this.status !== RequestStatus.STARTED;
            },
            hasExcludedViaFile(): boolean {
                return this.currentRequest.hasExcludedVatsViaFile;
            },
            excludedRequests(): PrepaidDataMarketingRequest[] {
                return this.currentRequest.formerRequests.filter(req => this.currentRequest.excludedRequestIds.includes(req.id));
            },
            hasExcludedVats(): boolean {
                return this.hasExcludedViaFile || this.excludedRequests.length > 0;
            },
            groupedCriteria(): Dictionary<ProspectieDataSelectionCriterium[]> {
                const selectedCriteria = this.currentRequest.selectedCriteria!
                    .filter((c: ProspectieDataSelectionCriterium) => c.type !== ProspectieDataSelectionCriteriumType.METTELEFOON)
                    .filter((c: ProspectieDataSelectionCriterium) => c.type !== ProspectieDataSelectionCriteriumType.EXCLUSION);
                    
                return _.groupBy(selectedCriteria, c => c.type);
            },
            selected(): number {
                return this.currentRequest.summary?.countTotal ?? 0
            },
            hasSelections(): boolean {
                return this.currentRequest.hasSelections;
            },
            isLastStep(): boolean {
                return this.progress.activeStep === this.progress.allSteps[this.progress.allSteps.length - 1];
            },
            flattenedTreeData(): TreeData {
                function flatten(data: IProspectieDataTreeNode[]){
                    const clonedData = _.cloneDeep(data ?? []);
                    return clonedData.reduce((result: IProspectieDataTreeNode[], next: IProspectieDataTreeNode) => {
                        result.push(next);
                        if(next.children){
                            result = result.concat(flatten(next.children));  
                            next.children = [];
                        }
                        return result;
                    },[]);
                }
                
                return {
                    provincie: flatten(this.treeData.provincie),
                    gewest: this.treeData.gewest,
                    activiteit: flatten(this.treeData.activiteit),
                    beroep: flatten(this.treeData.beroep),
                    /*@ts-ignore*/
                    "juridische-vorm": flatten(this.treeData.juridischeVorm),
                    postcode: [],
                    personeel: [],
                    oprichtingsdatum: [],
                    risicoprofiel: [],
                    brutomarge: [],
                    omzet: [],
                    balanstotaal: [],
                    "eigen-vermogen": [],
                    nis: [],
                    "taal-vereiste": [],
                    "met-telefoon": [],
                    "winst-verlies": [],
                    exclusion: []
                }
            }
        },
        methods: {
            closeMobileCriteria() {
                this.$root.$emit('mobile-criteria-close');
            },
            async removeExclusionViaFile() {
                await this.currentRequest.removeExclusionViaFile();
                this.$root.$emit("reset-file-input");
            },
            async removeExclusionViaRequest(request: PrepaidDataMarketingRequest) {
                const requestIds = this.currentRequest.excludedRequestIds.filter(id => id !== request.id);

                await this.currentRequest.setExcludesViaRequest(requestIds);
            },
            formatCriterium(criterium: ProspectieDataSelectionCriterium): string {
                if([
                    ProspectieDataSelectionCriteriumType.JURIDISCHEVORM,
                    ProspectieDataSelectionCriteriumType.GEWEST,
                    ProspectieDataSelectionCriteriumType.PROVINCIE,
                    ProspectieDataSelectionCriteriumType.ACTIVITEIT,
                    ProspectieDataSelectionCriteriumType.BEROEP,
                    ProspectieDataSelectionCriteriumType.METTELEFOON
                ].includes(criterium.type)) {
                    return this.flattenedTreeData[criterium.type].find(n => n.id === criterium.id)?.name ?? 'not-found';
                } else if(criterium.type === ProspectieDataSelectionCriteriumType.TAALVEREISTE) {
                    if(criterium.id === 1)
                        return "Frans";
                    else
                        return "Nederlands";
                    
                } else {
                     return new CriteriumRange(
                        criterium.range!.operator, 
                        criterium.type, 
                        criterium.range?.lowerBound, 
                        criterium.range?.upperBound).toString(); 
                }
            },
            async removeCriterium(criterium: ProspectieDataSelectionCriterium): Promise<void> {
                await this.currentRequest.removeCriterium(criterium);
                await this.currentRequest.updateSummary();
                
                this.$root.$emit('delete-filter', criterium.type);
            },
            nextStep(): void {
                this.progress.activateNextStep();
            },
            goToLastStep(): void {
                this.progress.activateLastStep();
            }
        },
        filters:{
            formatNumber: (num: number): string => num?.formatThousandSeparator() ?? ''
        },
        watch: {
            selected(): void {
                gsap.to(this, {
                    tweenValue: this.selected,
                    onUpdate: () => {
                        this.displayValue = Math.ceil(this.tweenValue);
                    }
                });
            }
        }
    })
</script>

<style scoped lang="scss">
    @import "../../../../../sass/variables";
    
    .bordered {
        border: 1px solid #dee0e0;

        @media #{$tablet} {
            border: 0;
        }
    }

   .selected {
       font-size: 23px;
       font-weight: bolder;
   }

   .pe-none{
       pointer-events: none;
   }

   .delete-link {
       color: $grey;
       font-size: 13px;

       &:hover {
           color: $red;
           text-decoration: none;
       }
   }
</style>