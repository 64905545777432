
<div class="container ">
    <div class="row vert-offset-bottom-2">
        <div class="col-md-6 vert-offset-bottom-1">
            <h1>{{translate('page_title')}}</h1>
            <p>
                {{translate('page_intro')}}
            </p>
        </div>

        <div class="col-lg-5 offset-lg-1 col-md-6 mt-4" v-if="hasJoinLink && editablePermissions.seeShareLink">
            <b>{{translate('deelbare_link')}}</b>
            <p>
                {{translate('join_link_intro')}}
            </p>
            <div class="copy-input-wrapper">
                <input class="form-control"
                       :value="joinLink"
                       type="text"
                       ref="joinLink"
                       readonly v-on:click="selectJoinLink()" />
                <a href="" v-on:click.prevent="copyJoinLink()" class="btn btn-default">
                    <i class="fas fa-clipboard mr-2"></i> {{translate('copy')}}
                </a>
            </div>
        </div>
    </div>
    <div class="row vert-offset-bottom-2" v-if="userLimitReached">
        <div class="col-md-6">
            <div class="jumbotron jumbotron-cw">
                <div class="content">
                    <h3>
                        {{translate('upgrade_users_title')}}
                    </h3>
                    <p v-if="showExtendedUpsellMessage">
                        {{translate('upgrade_users_body', userLimit)}}
                    </p>
                    <a :href="upgradeUsersLink" class="btn btn-info" :class="{'mt-3': !showExtendedUpsellMessage}">{{translate('upgrade_users_btn')}} <i class="fas fa-angle-right ml-2"></i></a>
                </div>
                <img src="/images/upsell.svg" :alt="translate('upgrade_users_btn')">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12 mb-3" v-if="needReviewUsers.length > 0 && isLoggedInAsAdmin">
                    <div class="row">
                        <needs-review-user v-for="(user, index) in needReviewUsers"
                                           :key="index"
                                           :index="index"
                                           :user="user"></needs-review-user>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row filter-row mb-3">
                        <div class="col-md-4">
                            <div class="filter-input">
                                <i class="fas fa-filter"></i>
                                <input type="text" :placeholder="translate('filter_placeholder')" v-model="filterTerm" />
                            </div>
                        </div>
                        <div class="col-md-4 my-auto text-center">
                            {{users.filter(u => u.type === "active").length}} / {{userLimit}} {{translate('users').toLowerCase()}}
                        </div>
                        <div class="col-md-4 text-right" v-if="isLoggedInAsAdmin && editablePermissions.addUsers">
                            <a href="" class="btn btn-info" v-on:click.prevent="goToCreateMode">
                                <i class="fas fa-plus"></i> &nbsp;{{translate('gebruiker_toevoegen')}}
                            </a>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 users">
                            <table class="table phone-table data-table">
                                <thead>
                                    <tr>
                                        <th width="80">{{translate('beheerder')}}?</th>
                                        <th width="180">{{translate('naam')}}</th>
                                        <th width="120">{{translate('status')}}</th>
                                        <th width="280">{{translate('email')}}</th>
                                        <th width="230">{{translate('laatst_actief')}}</th>
                                        <th v-if="isLoggedInAsAdmin"><i :title="translate('aantal_mobile')" class="fas fa-mobile-alt"></i></th>
                                        <th><i :title="translate('aantal_persoonlijke_alerts_gebruikt')" class="fas fa-bell"></i></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="user in filteredUsers"
                                        :user="user"
                                        is="user-row"
                                        @edit="editUser"
                                        :key="user.userId"></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <modal :title="translate('gebruiker_toevoegen')" id="add-user">
        <template v-slot>
            <form v-on:submit.prevent="inviteUser" 
                  class="full-width" 
                  ref="inviteForm">
                <template v-if="newUser.isLoading">
                    <div class="text-center">
                        <i class="fas fa-spinner spinning fa-lg"></i>
                    </div>
                </template>
                <template v-else>
                    <div class="row mb-3">
                        <div class="col-8">
                            <label>{{translate('email')}}</label>
                            <input data-val-regex-pattern="^[-_.a-zA-Z0-9+]+@[-_.a-zA-Z0-9]+\.[a-zA-Z0-9]+$"
                                   class="form-control"
                                   type="text"
                                   v-model="newUser.email"
                                   :placeholder="translate('email_address')"
                                   ref="email"
                                   v-validate.required.email="translate('email')" />
                        </div>
                        <div class="col-4">
                            <label>{{translate('language')}}</label>
                            <select v-model="newUser.language" class="form-control" v-validate.required="translate('language')">
                                <option v-for="lang in Language" :value="lang" :key="lang">{{lang|uppercase}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <div v-if="!!newUser.errorMessage" class="card-box warning small mb-0 mt-1">
                                {{newUser.errorMessage}}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <button type="submit" class="btn btn-info btn-block">{{translate('uitnodigen')}}</button>
                        </div>
                    </div>
                </template>
            </form>
        </template>
    </modal>

    <modal :title="reviewModal.title" id="review">
        <template v-slot>
            <div v-html="translate('suspicious_domain_user_activate_confirmation_article', avHtml)"></div>

            <div class="mt-3" v-html="reviewModal.body"></div>

            <div class="mt-3">
                <a href="" class="btn btn-info" v-on:click.prevent="acceptUser">{{reviewModal.yesBtn}}</a>
                <a class="btn btn-default" v-on:click.prevent="rejectUser" v-if="reviewModal.type !== 'deactivated'">{{reviewModal.noBtn}}</a>
            </div>
        </template>
    </modal>
    
    <modal :title="translate('edit_permissions')" id="edit-user" size="lg">
        <template v-slot>
            <form v-on:submit.prevent="savePermissions" v-if="editableUser">
                <h2 class="mb-4">{{ editableUser.name }}</h2>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input :id="'userIsAdmin' + editableUser.userId"
                                   type="checkbox"
                                   class="form-check-input"
                                   :checked="editableUser.isAdmin"
                                   :disabled="!isLoggedInAsAdmin || cannotDemoteFromAdmin"
                                   @change.stop="editAdmin($event)"/>
                            <label :for="'userIsAdmin' + editableUser.userId"
                                   class="mb-0">{{ translate("beheerder") }}</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" v-if="isLoggedInAsAdmin && editablePermissions.vervaldagboek">
                            <div>
                                <input type="checkbox" class="form-check-input"
                                       :id="'vervaldagboek' + editableUser.userId"
                                       v-model="editableUser.permissions.hasVervaldagboek"/>
                                <label :for="'vervaldagboek' + editableUser.userId"
                                       class="mb-0">{{ translate('vervaldagboek') }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group" v-if="editablePermissions.alertsPlus">
                            <label>ALERTS+</label>
                            <select class="form-control" v-model="editableUser.permissions.alertsPlusAccessLevel">
                                <option v-for="apAccesslevel in apAccesslevels" :value="apAccesslevel"
                                        :key="'al'+apAccesslevel">
                                    {{ translate('alertsplus_accesslevel_' + apAccesslevel) }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" v-if="isLoggedInAsAdmin && editablePermissions.internationaal">
                            <label>{{ translate('internationaal_rapport') }}</label>
                            <select class="form-control"
                                    v-model="editableUser.permissions.internationaalAccessLevel">
                                <option v-for="irAccesslevel in irAccesslevels" :value="irAccesslevel"
                                        :key="'ir'+irAccesslevel">
                                    {{ translate('internationaal_accesslevel_' + irAccesslevel) }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group" v-if="isLoggedInAsAdmin && editablePermissions.dataMarketing">
                            <label>{{ translate('datamarketing') }}</label>
                            <select class="form-control"
                                    v-model="editableUser.permissions.dataMarketingAccessLevel">
                                <option v-for="dmAccesslevel in dmAccesslevels" :value="dmAccesslevel"
                                        :key="'dm'+dmAccesslevel">
                                    {{ translate('internationaal_accesslevel_' + dmAccesslevel) }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group"
                             v-if="isLoggedInAsAdmin && editablePermissions.radar">
                            <label>Radar</label>
                            <select class="form-control" v-model="editableUser.permissions.radarAccessLevel">
                                <option v-for="radarAccessLevel in radarAccesslevels" :value="radarAccessLevel"
                                        :key="'radar'+radarAccessLevel">
                                    {{ translate('internationaal_accesslevel_' + radarAccessLevel) }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group" v-if="isLoggedInAsAdmin && hasMobileDevices">
                    <hr>
                    <label>{{ translate('mobile_devices') }}</label>
                    <div class="col-md-12">
                        <div class="row" v-for="(device, index) in editableUser.mobileInstalls.filter(x => x.isActive && !x.isOldMobileAccount)">
                            {{ mobileDeviceName(device) }}
                            <button class="btn btn-sm ml-2 remove show-on-hover"
                                    v-if="!device.isOldMobileAccount"
                                    v-on:click.prevent.stop="changeDeviceStatus(device.id, false)"
                                    :title="translate('deactivate_device')">
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </div>
                        <div class="row grey" v-for="(device, index) in editableUser.mobileInstalls.filter(x => !x.isActive && !x.isOldMobileAccount)">
                            {{ mobileDeviceName(device) }}
                            <button class="btn btn-sm ml-2 show-on-hover"
                                    v-if="!device.isOldMobileAccount"
                                    v-on:click.prevent.stop="changeDeviceStatus(device.id, true)"
                                    :title="translate('activate_device')">
                                <i class="fas fa-check-circle"></i>
                            </button>
                        </div>
                        <div class="row grey" v-for="(device, index) in editableUser.mobileInstalls.filter(x => x.isOldMobileAccount)">
                            {{ mobileDeviceName(device) }}
                        </div>
                    </div>
                </div>

                <button type="submit" class="btn btn-info mt-3">{{ translate("btn_save") }}</button>
            </form>
        </template>
    </modal>
</div>
