import {Key} from 'readline';

export {};

import Option from "../types/global/option";

declare global {
    interface Array<T> {
        /**
        * Finds an element based on a predicate and returns it as an Option.
        * 
        * @param callback - The predicate to match the element on. The iteratee and index will be passed as arguments of this function.
        */
        firstOrNone(callback: (elem: T, index: number) => boolean): Option<T>;

        /**
        * Returns the first element of the array or an empty Option if the array is empty.
        */
        firstElement(): Option<T>;

        /**
        * Mutates the array by removing an element based on triple equality operator.
        *
        * @param elem - The element to remove from given array.
        */
        remove(elem: T): void;

        /**
         * Mutates the array by removing an element based on a predicate.
         *
         * @param elem - The element to remove from given array.
         */
        removeBy(callback: (elem: T) => boolean): void;
        
        /**
         * Returns a new array containing the values of the present Options
         */
        presentValues<T extends Option<U>, U>(): U[];

        /**
         * Returns a sorted array containing
         */
        sortAlpha<T extends string>(): T[];
        
        /**
         * Groups an array based on a predicate.
         *
         * @param predicate - The predicate to group the elements on. The iteratee will be passed as an argument of this function.
         */
        groupBy<U extends PropertyKey>(predicate: (elem: T) => U): Map<U, T[]>;
    }
}
if (!Array.prototype.firstOrNone)
    Array.prototype.firstOrNone = function <T>(callback: (elem: T, index: number) => boolean): Option<T> {
        return Option.someNotNull(this.find(callback));
    }

if (!Array.prototype.firstElement)
    Array.prototype.firstElement = function<T>(): Option<T> {
        return (!!this && this.length > 0)
            ? Option.some(this[0])
            : Option.none<T>();
    }

if (!Array.prototype.removeBy)
    Array.prototype.removeBy = function <T>(callback: (elem: T) => boolean): void {
        const item = this.find(callback);
        if(item)
            this.remove(item);
    }

if (!Array.prototype.remove)
    Array.prototype.remove = function <T>(elem: T): void {
        this.forEach((item, index) => {
            if (item === elem) this.splice(index, 1);
        });
    }

if (!Array.prototype.presentValues)
    Array.prototype.presentValues = function <T extends Option<U>, U>(): U[] {
        let result: U[] = [];
        
        this.forEach((item: Option<U>) => {
           item.matchSome(i => result.push(i));
        }); 
        
        return result;
    }

if (!Array.prototype.sortAlpha)
    Array.prototype.sortAlpha = function <T extends string>(): T[] {
        return this.sort((a, b) => {
            if(isNaN(a) && isNaN(b)) {
                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                return 0;
            } else {
                return a-b;
            }
        });
    }

if (!Array.prototype.groupBy) {
    Array.prototype.groupBy = function<T, U extends PropertyKey>(predicate: (elem: T) => U): Map<U, T[]> {
        let map = new Map<U, T[]>();

        this.forEach(item => {
            let itemKey = predicate(item);

            if (!map.has(itemKey)) {
                map.set(itemKey, this.filter(i => predicate(i) === predicate(item)));
            }
        });

        return map;
    }
}