﻿<template>
    <div>
        <b v-if="title !== ''" class="label-like">{{title}}</b>
        <input :multiple="multiple" :required="required" :id="name" :name="name" type="file" :ref="name" @change="previewFile($event)" class="cw-file-input" :class="{'valid': error == '' && hasUploaded, dragged}" :accept="accept"/>
        <label @dragover="dragover" @dragleave="dragleave" @drop="drop" :for="name">
            <span class="info text-grey font-small font-italic" v-if="!small">
                <template v-if="hasUploaded" class="">
                    {{filesizeLabel}}
                </template>
                <template v-else>
                    {{instructionsLabel}}
                </template>
            </span>
            <span class="inner-label">{{label|truncate(25)}}</span>
        </label>

        <div v-if="error !== ''" class="card-box warning mt-2 small">{{error}}</div>
    </div>
</template>

<script type="text/javascript" lang="ts">
    import Truncate from '../mixins/truncate';
    import Translate from '../mixins/translate';
    import mixins from 'vue-typed-mixins'; 

    export default mixins(Truncate, Translate).extend({
        name: 'file-input',
        props: {
            title: String,
            placeholder: String,
            name: {
                default: "file-input",
                type: String
            },
            accept: String,
            required: Boolean,
            multiple: Boolean,
            small: {
                type: Boolean,
                default: false
            },
            modelClosed: Boolean
        },
        data() {
            return {
                dragged: false,
                error: "",
                instructionsLabel: this.multiple ? this.translate('file_input_instructions_label_multiple') : this.translate('file_input_instructions_label'),
                validFiles: [] as File[]
            }
        },
        computed: {
            hasUploaded(): boolean {
                return this.validFiles.length > 0;
            },
            label(): string {
                if (!this.hasUploaded)
                    return this.placeholder;

                if (this.validFiles.length === 1)
                    return this.validFiles[0].name;

                return this.validFiles.length + " " + this.translate("files").toLowerCase();
            },  
            filesizeLabel(): string {
                if (!this.hasUploaded)
                    return "";

                if (this.combinedFilesize / (1024*1024) > 1)
                    return (this.combinedFilesize / (1024 * 1024)).toFixed(2) + " MB"
                else if (this.combinedFilesize / 1024 > 1)
                    return (this.combinedFilesize / 1024).toFixed(2) + " KB"
                else 
                    return this.combinedFilesize + " bytes"

            },
            combinedFilesize(): number {
                return this.validFiles.reduce((totalSize, file) => file.size + totalSize, 0);
            }
        },
        watch: {
            combinedFilesize(value: number): void {
                if (value > 5 * 1024 * 1024)
                    this.error = this.translate('validation_error_message_file_size_exceeded', '5 MB')
            },
            error(v: string): void {
                this.$root.$emit('status', v !== '');
            },
            modelClosed(v: boolean) {
                if(v) {
                    this.resetState();
                }
            }
        },
        methods: {
            previewFile(e: InputEvent): void {
                const input = e.target as HTMLInputElement;
                const files = Array.from(input.files ?? []);

                this.validFiles = files.filter(
                    f => this.isValidFile(f)
                );
                this.$emit('input', this.validFiles);
            },
            isValidFile(file: File): boolean {
                if (!file)
                    return false;

                const extensionRegex = /(\.[^.]+)?$/;
                const extension = extensionRegex.exec(file.name.toLowerCase())![1];
                let acceptedFileExtensions: string[] = [];
               
                if(this.accept)
                    acceptedFileExtensions = this.accept.split(",");

                if (acceptedFileExtensions.length > 0 && !acceptedFileExtensions.includes(extension)) {
                    this.error = this.translate('validation_error_message_wrong_extension');
                    return false;
                }
                
                this.error = "";

                return true;
            },
            onChange(): void {
                const input =  this.$refs[this.name] as HTMLInputElement;
                const files: FileList | null = input.files;

                this.validFiles = Array.from(files || []).filter(f => this.isValidFile(f));
                this.$emit('input', this.validFiles);
            },
            dragover(event: DragEvent): void {
                event.preventDefault();
                this.dragged = true;
            },
            dragleave(): void {
                this.dragged = false;
            },
            drop(event: DragEvent): void {
                event.preventDefault();
                this.dragged = false;
                const input = this.$refs[this.name] as HTMLInputElement;

                // assign the dropped files to the native input element.
                input.files = event.dataTransfer?.files || null;
                this.onChange();
            },
            resetState(): void {
                this.validFiles = [];
                this.error = "";
                const input =  this.$refs[this.name] as HTMLInputElement;
                
                if(!!input)
                    input.value = '';
            }
        },
        mounted() {
            this.$root.$on("reset-file-input", () => {
                this.resetState();
            });
        }
    });
</script>