
<div class="zoom-on-hover" 
     v-bind:class="{zoomed}" 
     @touchstart="touchzoom"
     @mousemove="move" 
     @mouseenter="zoom" 
     @mouseleave="unzoom">
    <img class="normal" ref="normal" :src="imgNormal" />
    <img class="zoom" ref="zoom" :src="imgZoom || imgNormal" />
</div>
