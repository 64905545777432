<template>
    <form :action="'/international/search/'+ form.countryCode" method="GET" @submit="isSearching = true">
        <input type="hidden" name="searchType" :value="usedSearchField.map(field => field.name)" />
        <input type="hidden" name="searchTerm" :value="usedSearchField.map(field => field.value)" />
        
        <div class="row">
            <div class="col-md-4">
                <h2>1. {{translate('select_a_country')}}</h2>
                <country-input :countries="countries" id="country" v-model="form.countryCode"></country-input>
            </div>
            <div class="col-md-7 offset-md-1 mt-2 mt-md-0" v-if="limitReached">
                <upsell-banner 
                        :title="translate('cta_upgrade_international_title')"
                        :text="translate('cta_upgrade_international_text')"
                        :btn="translate('cta_upgrade_international_btn')"
                        href="/upgrade/international-reports"
                ></upsell-banner>
            </div>
        </div>

        <h2 class="mt-5">2. {{translate('search_a_company')}}</h2>
        <div class="box">
            <div class="row searchfields">
                <div v-for="searchField in searchFields"
                        :data-or="translate('or').toLowerCase()"
                        class="searchfield col-md-4 mb-2 mb-md-0"
                        :key="searchField.label">
                    <label :for="searchField.name">{{searchField.label}}</label>
                    <input type="text"
                            :name="searchField.name"
                            :id="searchField.name"
                            v-model="searchField.value"
                            :placeholder="searchField.placeholder"
                            :disabled="usedSearchField.hasValue() && !usedSearchField.contains(searchField)"
                            class="form-control search-input" />
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <button type="submit" class="btn btn-blue btn-200" :disabled="!usedSearchField.hasValue() || isSearching">
                        <template v-if="!isSearching"><i class="fas fa-search mr-2"></i>{{translate('button_search')}}</template>
                        <i v-else class="fas fa-spinner spinning"></i>
                    </button>
                </div>
            </div>
        </div>

    </form>
</template>

<script lang="ts">
    import Option from '../../../types/global/option';
    import { Country, SearchField } from '../types';
    import CountryInput from './country-input.vue';
    import Translate from '../../mixins/translate';
    import UpsellBanner from '../../shared/upsell-banner.vue';

    export default Translate.extend({
        name: 'international-search',
        components: {UpsellBanner, CountryInput},
        props: {
            countries: Array as () => Country[]
        },
        data() {
            return {
                isSearching: false,
                prefilledField: window.cw.prefilledSearchField,
                prefilledValue: window.cw.prefilledValue,
                limitReached: window.cw.limitReached || false,
                form: {
                    countryCode: '',
                    companyName: ''
                }
            }
        },
        computed: {
            suggestedCountries(): Country[] {
                return this.countries?.filter(c => ["nl", "fr", "lu", "de", "uk"].includes(c.code))
            },
            showSearchBox(): boolean {
                return this.country.hasValue() && this.form.countryCode !== "";  
            },
            country(): Option<Country> {
                return Option.someNotNull(this.countries.find(c => c.code === this.form.countryCode));
            },
            searchFields(): SearchField[] {
                return this.country
                    .map(c => c.searchFields.filter(field => field.isAvailable))
                    .valueOr([]);
            },
            usedSearchField(): Option<SearchField> {
                return this.searchFields.firstOrNone(field => field.value !== '');
            }
        },
        methods: {
            acceptSuggestedCountry(suggestedCountry: Country): void {
                this.form.countryCode = suggestedCountry.code;
            },
            prefillSearchField(): void {
                this.searchFields.forEach(field => {
                        if(field.name === this.prefilledField)
                            field.value = this.prefilledValue
                    }
                );
            }
        },
        created() {
            const matches = document.location.pathname.match(/\/international\/(?:search\/)?([\w]{2})/);
            const countryCodeInUrl = Option.someNotNull(matches?.[1]);
            const defaultCountryCode = 'nl';
     
            this.form.countryCode = countryCodeInUrl.valueOr(defaultCountryCode);
        },
        mounted() {
            this.prefillSearchField();
        }
    });
</script>

<style scoped lang="scss">
    .searchfields > .searchfield:not(:last-child):after {
      content: attr(data-or);
      color: #878787;
      font-style: italic;
      position: absolute;
      bottom: 7px;
      right: -5px;
      font-size: 13px;
    }
</style>
