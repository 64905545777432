﻿<template>
    <div>
        <template v-if="isLoading">
            <div class="text-center p-5">
                <i class="fas fa-spinner spinning"></i>
            </div>
        </template>
        <template v-else>
            <div class="mb-4" v-if="$root.isLimitReached">
                <upsell-banner :title="translate('upsell_title_limit_reached')"
                               :text="translate('upsell_text_limit_reached')"
                               href="/upgrade/alerts">
                </upsell-banner>
            </div>
            <div class="mb-4" v-else-if="$root.isLimitAlmostReached">
                <upsell-banner :title="translate('upsell_title_limit_almost_reached')"
                               :text="translate('upsell_text_limit_almost_reached')"
                               href="/upgrade/alerts">
                </upsell-banner>
            </div>
            <form @submit.prevent="submitForm()">
                <div class="row">
                    <div class="col-md-12">
                        <p>
                            {{translate('add_companies_info_text')}}
                        </p>
                    </div>
                </div>
                <div class="row position-relative">
                    <div class="col-md-6 flex-v-container">
                        <b>{{translate('add_via_vats_label')}}</b>
                        <textarea class="form-control notes" rows="3" v-model="vatsString"></textarea>
                    </div>
                    <div class="text-center font-italic text-grey middle-or">
                        {{translate('or').toLowerCase()}}
                    </div>
                    <div class="col-md-6 pt-5 pt-md-0">
                        <file-input 
                        v-model="vatsFiles" 
                        :title="translate('add_via_file_label')" 
                        :placeholder="placeholder|truncate(25)" 
                        accept=".xlsx"></file-input>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-12">
                        <button type="submit" class="btn btn-blue btn-200">
                            {{translate('next')}}
                        </button>
                    </div>
                </div>
            </form>
        </template>
    </div>
</template>

<script>
    import Toast from 'Lib/Toast';
    import Truncate from 'Mixins/truncate';
    import Translate from 'Mixins/translate';
    import UpsellBanner from '../../shared/upsell-banner.vue';
    import Ajax from '../../../lib/Ajax';
    import FileInput from '../../shared/file-input.vue';
    import Vue from 'vue';

    export default Vue.extend({
        name: 'upload-form',
        components: { UpsellBanner, FileInput },
        props: {
            currentList: Object
        },
        data() {
            return {
                currentView: 'upload-form',
                hasDropSupport: false,
                vatsString: '',
                vatsFiles: [],
                isLoading: false
            }
        },
        mixins: [Truncate, Translate],
        computed: {
            vatsFile() {
                return this.vatsFiles.length > 0 ? this.vatsFiles[0] : null
            },
            hasUploaded() {
                return this.vatsFile !== null;
            },
            placeholder() {
                return this.hasUploaded
                    ? this.vatsFile.name
                    : 'Excel';
            }
        },
        methods: {
            resetForm() {
                this.vatsString = '';
            },
            submitForm() {
                if (this.vatsString !== '')
                    this.addCompaniesViaText();
                else if (this.hasUploaded)
                    this.addCompaniesViaFile();
                else
                    return;
            },
            addCompaniesViaText() {
                this.$root.$emit('change-view', {
                    viewName: 'choose-selection-method',
                    viewData: {
                        vatsString: this.vatsString
                    },
                    currentList: this.currentList
                });
            },
            addCompaniesViaFile() {
                this.isLoading = true;
                var formData = new FormData();
                formData.append('listId', this.currentList.ListId.toString());
                formData.append('file', this.vatsFile);

                Ajax.postWithBinary(
                    '/ajax/followup/import-companies',
                    formData,
                    (response) => {
                        this.$root.$emit('change-view', {
                            viewName: 'choose-column',
                            viewData: {
                                columns: response.Data,
                                error: response.Error
                            },
                            currentList: this.currentList
                        });
                        this.isLoading = false;
                    },
                    (response) => {
                        this.$root.$emit('hide-modal', 'addCompanies');
                        Toast.error(response);
                        this.isLoading = false;
                    }
                );
            }
        }
    });
</script>

<style scoped>
    .flex-v-container {
        display: flex;
        flex-direction: column;
    }

    .flex-v-container textarea {
        flex-grow: 1;
    }
</style>