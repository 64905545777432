﻿import Translate from 'Lib/Translate';
import Confirm from 'Lib/Confirmation';

export default {
    bind(el, binding, vnode) {
        const config = binding.value;

        el.handleClick = (e) => {
            Confirm(
                config.handler,
                {
                    title: config.title || "Are you sure?",
                    body: config.body || "This action cannot be undone.",
                    type: config.type || 'info',
                    cancelButtonText: config.cancelButton || Translate("annuleren"),
                    confirmButtonText: config.confirmButton || Translate("verdergaan")
                }
            );
        }
        el.addEventListener('click', el.handleClick);
    },
    unbind(el) {
        el.removeEventListener('click', el.handleClick);
    }
};

