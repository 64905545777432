
<div class="wrapper">
    <div class="top-view">
        <div class="mb-3">
            <a href="" class="btn btn-sm btn-default" @click.prevent="backToList">
                <i class="fas fa-arrow-left mr-"></i> {{translate('all_filters')}}
            </a>
        </div>
        <h2 class="font-weight-bold">{{label}}</h2>
        <p v-if="column && column.showDescription" v-html="translate(column.title+'_desc')"></p>
    </div>
    <div class="main-view">
        <template v-if="column">
            <div v-if="column.filterCriteria._type === FilterType.FREETEXT">
                <free-text :criteria="column.filterCriteria"></free-text>
            </div>
            <div v-if="column.filterCriteria._type === FilterType.BOOLEAN">
                <yes-no :criteria="column.filterCriteria"></yes-no>
            </div>
            <div v-else-if="column.filterCriteria._type === FilterType.SELECTIONLIST">
                <selection-list :column="column"></selection-list>
            </div>
            <div v-else-if="column.filterCriteria._type === FilterType.DATERANGE" class="mt-3">
                <date-range :criteria="column.filterCriteria"></date-range>
            </div>
            <div v-else-if="column.filterCriteria._type === FilterType.SELECTIONTREE">
                <selection-tree
                    :column="column"
                    :criteria="column.filterCriteria"
                    :treeData="getSelectionTree"
                    :is-forced-expanded="column.forceExpandTree"></selection-tree>
            </div>
            <div v-else-if="column.filterCriteria._type === FilterType.NUMERICRANGE">
                <div class="px-2 py-3">
                    <range-slider
                        :is-linear="isLinear"
                        :lower-bound="lowerBound"
                        :upper-bound="upperBound"
                        v-model="column.filterCriteria._value"></range-slider>
                </div>
            </div>
        </template>
        <template v-else-if="currentDrawerFilter === 'companyname'">
            <input v-model="store.companyNameFilter" class="form-control" :placeholder="translate('filter_on_company')" type="search"/>
        </template>
        <template v-else-if="currentDrawerFilter === 'street'">
            <div class="row">
                <div class="col-md-8">
                    <input v-model="streetSearchTerm" class="form-control" :placeholder="translate('filter_on_street')" type="search"/>
                    <div class="filter-overflow-box mt-4">
                        <ul>
                            <li v-for="street in shownFilteredStreets" :key="street">
                                <input :id="street"
                                       :checked="store.streetFilters.includes(street)"
                                       class="form-check-input azure"
                                       type="checkbox"
                                       @change="store.toggleFilterStreet(street)"/>
                                <label :for="street" class="wrap-normal d-block"><span>{{ street }}</span></label>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <input class="form-control" type="search" :placeholder="translate('house_number')" v-model="store.houseNumberFilter">
                </div>
            </div>
        </template>
        <template v-else-if="currentDrawerFilter === 'form'">
            <div class="filter-overflow-box">
                <ul>
                    <li v-for="form in uniqueForms" :key="form">
                        <input :id="form"
                               :checked="store.formFilters.includes(form)"
                               class="form-check-input azure"
                               type="checkbox"
                               @change="store.toggleFilterForm(form)"/>
                        <label :for="form" class="wrap-normal d-block"><span>{{ form }}</span></label>
                    </li>
                </ul>
            </div>
        </template>
        <template v-else>
            <div>
                <div v-if="field.type === 'boolean'">
                    <select v-model="store.customFilters[field.id]" class="form-control">
                        <option :value="null">{{translate('checkbox_all')}}</option>
                        <option :value="true">{{translate('checkbox_checked')}}</option>
                        <option :value="false">{{translate('checkbox_unchecked')}}</option>
                    </select>
                </div>
                <div v-else>
                    <div class="d-flex align-items-center">
                        <input v-model="store.customFilters[field.id]"
                               :placeholder="translate('filter_on') + ' ' + field.name" class="form-control" type="search">
                    </div>
                </div>
            </div>
        </template>
    </div>
    <div class="bottom-view">
        <a href="" class="btn btn-white" @click.prevent="close">{{translate('cancel')}}</a>
        <div class="font-italic text-grey text-center"><div class="font-weight-bold text-body">{{store.allFilteredCompanies.length}}</div> {{translate('x_selected_companies')}}</div>
        <button class="btn btn-default" @click.prevent="removeFilter">{{translate('clear_filter')}}</button>
    </div>
</div>
