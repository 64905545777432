﻿<template>
    <g transform="translate(25,25)" id="nodeBaro">
        <circle r="13" cx="0" cy="0" :fill="className|statusColor"></circle>


        <text text-anchor="middle" fill="white" dy=".35em" :font-size="size" >{{content}}</text>
    </g>
</template>

<script>
    import _ from 'lodash';
    
    export default {
        name: 'svg-barometer-icon',
        props: {
            score: String | Number,
            className: String,
            scoreFormatted: String | Number
        },
        computed: {
            content() {
                if (this.className === "new")
                    return "NEW";
                else if (this.className === "failliet")
                    return "F"; //might never happen?
                else
                    return this.scoreFormatted;
            },
            size() {
                if (!!this.score)
                    return '11';
                
                return '8';
            }
        },
        filters: {
            statusColor(status) {
                var colorMap = {
                    'new': '#03a9f4',
                    'stop': '#F44336',
                    'failliet': '#F44336',
                    'neg-5': '#F44336',
                    'neg-4': '#F44336',
                    'neg-3': '#F7632B',
                    'neg-2': '#F9821E',
                    'neg-1': '#FCA112',
                    'neg-0': '#FFC107',
                    'zero': '#f1d01c',
                    'pos-0': '#f1d01c',
                    'pos-1': '#B7BA24',
                    'pos-2': '#94B633',
                    'pos-3': '#70B342',
                    'pos-4': '#4CAF50',
                    'pos-5': '#4CAF50'
                };

                const mappedColor = _.find(colorMap, function (__, key) {
                    return status.indexOf(key) === 0;
                });

                
                return mappedColor || '#cccccc';
            }
        }
    };
</script>