﻿<template>
    <div class="usage-bar-wrapper" :style="'width:' + width + 'px;'">
        <div class="usage-bar">
            <div class="used" :style="'width:' + usedWidth + 'px;'">
                <span class="number">{{translate('used')}}: {{used}}</span>
            </div>
            <div class="selected" :class="{'red':isLimitExceeded}" :style="'left:' + usedWidth + 'px; width:' + selectedWidth + 'px;'">
                <span class="number">{{translate('used_plus_selection')}}: {{selected + used}}</span>
            </div>
        </div>
        <div class="maximum">{{max}} ({{translate('limit')}})</div>
    </div>
</template>

<script>
    import Translate from 'Mixins/translate';
    export default {
        name: 'alerts-usage',
        props: {
            max: Number,
            selected: Number,
            used: Number
        },
        mixins: [Translate],
        data() {
            return {
                width: screen.width < 500
                    ? 190
                    : screen.width < 800
                        ? 250
                        : 450
            }
        },
        computed: {
            usedWidthCoefficient() {
                return this.used / this.max;
            },
            usedWidth() {
                return this.width * this.usedWidthCoefficient;
            },
            selectedWidthCoefficientCap() {
                return 1 - this.usedWidthCoefficient;
            },
            selectedWidthCoefficient() {
                let result = this.selected / this.max;
                return result > this.selectedWidthCoefficientCap
                    ? this.selectedWidthCoefficientCap
                    : result;
            },
            selectedWidth() {
                return this.width * this.selectedWidthCoefficient;
            },
            isLimitExceeded() {
                return this.used + this.selected > this.max;
            }
        }
    }
</script>

<style>
    .usage-bar-wrapper {
        margin: 25px 0;
        height: 12px;
        position: relative;
        box-sizing: content-box;
    }

    .usage-bar-wrapper .usage-bar {
        background-color: #f1f1f1;
        height: 100%;
        border-radius: 4px;
    }
    .usage-bar-wrapper .used {
        position: absolute;
        top: 0;
        border-radius: 4px 0 0 4px;
        height: 100%;
        background: #777777;
    }

    .usage-bar-wrapper .used .number {
        position: absolute;
        left: 100%;
        bottom: -20px;
        font-size: 12px;
        color: #777777;
        border-left: 1px solid #777777;
        padding-left: 7px;
        padding-top: 2px;
        margin-left: -1px;
        white-space: nowrap;
    }

    .usage-bar-wrapper .selected {
        position: absolute;
        top: 0;
        height: 100%;
        background: var(--primary);
        transition: all 0.35s ease-in-out;
    }

    .usage-bar-wrapper .selected .number {
        position: absolute;
        left: 100%;
        top: -20px;
        font-size: 12px;
        font-weight: bold;
        color: var(--primary);
        border-left: 1px solid var(--primary);
        padding-left: 7px;
        padding-bottom: 2px;
        margin-left: -1px;
        white-space: nowrap;
    }

    .usage-bar-wrapper .selected.red {
        background: #F44336;
    }
    .usage-bar-wrapper .selected.red .number {
        color: #F44336;
        border-left: 1px solid #F44336;
    }


    .usage-bar-wrapper .maximum {
        position: absolute;
        left: 100%;
        top: 0;
        padding-left: 15px;
        font-size: 12px;
        white-space: nowrap;
    }
</style>