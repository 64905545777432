﻿import _, { isArray } from 'lodash';
import axios, { AxiosError, AxiosResponse } from 'axios';
import URLSearchParams from '@ungap/url-search-params'

enum Method {
    POST = "post",
    GET = "get"
}

enum Status {
    CASHED,
    PENDING,
    NOTCASHED
}


class CacheObject<T> {
    public response?: AjaxResponse<T>;
    public status: Status;

    constructor(status: Status, response?: AjaxResponse<T>) {
        this.response = response;
        this.status = status;
    }
}

class Cache {
    private static cacheRecords: Map<string, CacheObject<any>> = new Map<string, CacheObject<any>>();
    private static keyFor = (url: string, params: any): string => JSON.stringify(url) + JSON.stringify(params);

    public static set = (url: string, params: any, response: AjaxResponse<any>): void => {
        Cache.cacheRecords.set(Cache.keyFor(url, params), new CacheObject(Status.CASHED, response))
    };
    
    public static get = (url: string, params: any): CacheObject<any> => 
        Cache.cacheRecords.get(Cache.keyFor(url, params)) ?? new CacheObject(Status.NOTCASHED);
    
    public static setPending = (url: string, params: any): void => {
        Cache.cacheRecords.set(Cache.keyFor(url, params), new CacheObject(Status.PENDING))
    };

    public static setNotCached = (url: string, params: any): void => {
        Cache.cacheRecords.set(Cache.keyFor(url, params), new CacheObject(Status.NOTCASHED))
    };

    public static remove = (url: string, params: any): void => {
        Cache.cacheRecords.delete(Cache.keyFor(url, params));
    }
}

function ajaxJson<T>(
    method: Method, 
    url: string, 
    data: any, 
    axiosSuccessHandler: (response: AjaxResponse<T>, shouldCache: boolean) => void, 
    axiosErrorHandler: (error: string) => void,
    withBinaryData?: boolean,
    ajaxTimeOut?: number,
    asJson: boolean = false
) {
    const dataKey = method === Method.GET ? 'params' : 'data';

    let params = new URLSearchParams();
    let ajaxTimeout: number = ajaxTimeOut ?? 30000; //ms

    for(const key in data) {
        if(isArray(data[key])){
            for(const k in data[key]){
                if(typeof data[key][k] !== 'function')
                    params.append(key, data[key][k]);
            }
        } else
            params.append(key, data[key]);
    }
    
    const processedData: any = (withBinaryData || method === Method.GET || asJson) 
        ? data
        : params;
    
    /*
        By default, axios serializes JavaScript objects to JSON. 
        To send data in the application/x-www-form-urlencoded format instead, you can use qs.stringify
    */
    axios({
        method: method,
        url: url,
        [dataKey]: processedData,
        timeout: ajaxTimeout,
        headers: {
            'Content-Type': asJson ? 'application/json; charset=UTF-8' : 'application/x-www-form-urlencoded; charset=UTF-8',
        }
    }).then((response: AxiosResponse<AjaxResponse<T>>) => {
        const error = response.data.Error || response.data.error;
        const shouldCache = response.headers["cache-control"] !== "private, max-age=0, no-cache, no-store";

        if (error) {
            if (error === 'REQUIRESADMIN' || error === 'FEATUREDEPRECATED' || error === 'WEBHITSLIMITREACHED')
                document.location = document.location;
            else
                axiosErrorHandler(error);
        }
        else {
            axiosSuccessHandler(response.data, shouldCache);
        }
    }).catch((error: AxiosError) => {
        axiosErrorHandler(error.message)
    });
}

export default {
    get: (function () {
        let mostRecentCall = new Map<string, any>();

        return function (url: string, data: any, successHandler: (response: AjaxResponse<any>) => void, errorHandler: (error: string) => void){
            const cached = Cache.get(url, data);
            mostRecentCall.set(url, data);

            if (cached.status === Status.CASHED && cached.response)
                return successHandler(cached.response);

            if (cached.status === Status.PENDING)
                return;

            if (cached.status === Status.NOTCASHED) {
                Cache.setPending(url, data);

                ajaxJson(
                    Method.GET, 
                    url, 
                    data,
                    (json: AjaxResponse<any>, shouldCache: boolean) => {
                        if(shouldCache)
                            Cache.set(url, data, json);
                        else 
                            Cache.setNotCached(url, data);

                        if (_.isEqual(mostRecentCall.get(url), data))
                            successHandler(json);
                    },
                    (error: string) => {
                        Cache.remove(url, data);

                        if (_.isEqual(mostRecentCall.get(url), data))
                            errorHandler(error);
                    }
                );
            }
        };
    }()),
    getRawJson: (function () {
        let mostRecentCall = new Map<string, any>();

        return function (url: string, data: any, successHandler: (response: any) => void, errorHandler: (error: string) => void){
            const cached = Cache.get(url, data);
            mostRecentCall.set(url, data);

            if (cached.status === Status.CASHED && cached.response)
                return successHandler(cached.response);

            if (cached.status === Status.PENDING)
                return;

            if (cached.status === Status.NOTCASHED) {
                Cache.setPending(url, data);

                ajaxJson(
                    Method.GET,
                    url,
                    data,
                    (json: any, shouldCache: boolean) => {
                        if(shouldCache)
                            Cache.set(url, data, json);
                        else
                            Cache.setNotCached(url, data);

                        if (_.isEqual(mostRecentCall.get(url), data))
                            successHandler(json);
                    },
                    (error: string) => {
                        Cache.remove(url, data);

                        if (_.isEqual(mostRecentCall.get(url), data))
                            errorHandler(error);
                    }
                );
            }
        };
    }()),
    post(
        url: string, 
        data: any, 
        successHandler: (response: AjaxResponse<any>) => void, 
        errorHandler: (error: string) => void,
        ajaxTimeOut?: number
    ) {
        ajaxJson(Method.POST, url, data, successHandler, errorHandler, false, ajaxTimeOut);
    },
    postWithBinary(
        url: string, 
        data: FormData, 
        successHandler: (response: AjaxResponse<any>) => void, 
        errorHandler: (error: string) => void,
        ajaxTimeOut?: number
    ) {
        ajaxJson(Method.POST, url, data, successHandler, errorHandler, true, ajaxTimeOut);
    },
    postAsJson(
        url: string,
        data: any,
        successHandler: (response: AjaxResponse<any>) => void,
        errorHandler: (error: string) => void,
        ajaxTimeOut?: number
    ) {
        ajaxJson(Method.POST, url, data, successHandler, errorHandler, true, ajaxTimeOut, true);
    },
    invalidateCache(url: string, data: any): void {
        Cache.remove(url, data);
    },
    /* Methods below create an async Promise-based wrapper around our Ajax lib to be able to await the request. */
    async getAsync<T>(url: string, data?: any): Promise<T> {
        return new Promise((resolve, reject) => {
            this.get(
                url, 
                data ?? {},
                (response: AjaxResponse<T>) => {
                    resolve(response.data)
                },
                (error: string) => {
                    reject(error);
                }
            )
        });
    },
    async getJsonFileAsync<T>(url: string): Promise<T> {
        return new Promise((resolve, reject) => {
            this.getRawJson(
                url,
                {},
                (response: T) => {
                    resolve(response)
                },
                (error: string) => {
                    reject(error);
                }
            )
        });
    },
    async postAsync<T>(url: string, data?: any, ajaxTimeOut?: number): Promise<T> {
        return new Promise((resolve, reject) => {
            this.post(
                url, 
                data?? {},
                (response: AjaxResponse<T>) => {
                    resolve(response.data)
                },
                (error: string) => {
                    reject(error);
                },
                ajaxTimeOut
            )
        });
    },
    async postAsJsonAsync<T>(url: string, data?: any, ajaxTimeOut?: number): Promise<T> {
        return new Promise((resolve, reject) => {
            this.postAsJson(
                url,
                data?? {},
                (response: AjaxResponse<T>) => {
                    resolve(response.data)
                },
                (error: string) => {
                    reject(error);
                },
                ajaxTimeOut
            )
        });
    },
    async postWithBinaryAsync<T>(url: string, data: FormData, ajaxTimeOut?: number): Promise<T> {
        return new Promise((resolve, reject) => {
            this.postWithBinary(
                url, 
                data,
                (response: AjaxResponse<T>) => {
                    resolve(response.data)
                },
                (error: string) => {
                    reject(error);
                },
                ajaxTimeOut
            )
        });
    }
};