﻿import { DirectiveBinding } from 'vue/types/options';

export default {
    inserted: function (el: HTMLElement, binding: DirectiveBinding) {
        const isConfidential = binding.value;
     
        if(!isConfidential)
            return;
       
        el.innerText = el.innerText.replace(/\w/g, "x");
        el.classList.add("confidential");
    }
};

