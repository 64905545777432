
<div class="row">
    <div class="col-md-12">
        <p>
            Met de Alert Screening Service kan u uw klanten of leveranciers door een screening halen vooraleer u ze toevoegt aan een alert lijst. <br />
            De screening zal elke onderneming van de geüploade lijst quoteren en indelen. Op die manier kan u zelf nagaan of een bedrijf het volgen waard is.
        </p>
        <p>
            Door enkel de meest risicovolle ondernemingen op te volgen, maakt u optimaal gebruik van uw beschikbare Alerts.
        </p>
    </div>

    <div class="col-md-12">
        <img src="/images/campaign/alerts-upgrade/laptop-screening.png" class="img-fluid" width="350"/>
    </div>
</div>
