﻿<template>
    <g>
        <text text-anchor="end" :transform="transform" :fill="textColor" font-family="Roboto, sans-serif">{{value|formatEuro($root.hasMillions)}}</text>
        <line :x1="x1" :y1="tween.y" :x2="x2" :y2="tween.y" :stroke="strokeColor" stroke-width="1"></line>
    </g>
</template>

<script>
    import gsap from 'gsap';

    export default {
        name: 'chart-y-axis-separator',
        props: {
            value: Number,
            index: Number
        },
        data() {
            return {
                tween: {
                    y: 0
                }
            };
        },
        inject: ['valueToYPos'],
        watch: {
            y: function (val) {
                gsap.to(this.tween, 0.6, { y: val });
            }
        },
        computed: {
            x1() { return this.value === 0 ? -15 : 0 },
            x2() { return this.$root.chartWidth },
            y() { return this.valueToYPos(this.value) },
            transform() {
                return `translate(-30,${this.tween.y + 4})`;
            },
            textColor() {
                var color = 'grey';
                if (this.value < 0)
                    color = 'red';
                if (this.value === 0)
                    color = 'black';

                return color;
            },
            strokeColor() {
                return this.value === 0 ? '#868686' : '#dee2e6';
            },
        },
        created() {
            this.tween.y = this.y;
        }
    }
</script>