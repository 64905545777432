﻿<template>
    <g :transform="transform">
        <rect :width="$root.yearWidth" height="100%" :style="fillColor" v-if="!isLastYear"></rect>
        <text :transform="yearTransform" fill="grey" font-family="Roboto, sans-serif" font-weight="bold">{{label}}</text>
    </g>
</template>

<script>
    export default {
        name: 'chart-year',
        props: {
            label: String,
            index: Number
        },
        computed: {
            xPos() { return this.$root.yAxisWidth + this.index * this.$root.yearWidth },
            isGrey() { return this.index % 2 === 0 },
            transform() {
                return 'translate(' + this.xPos + ',0)';
            },
            yearTransform() {
                return 'translate(-16, ' + (this.$root.containerHeight + 5) + ')';
            },
            fillColor() {
                return this.isGrey ? 'fill: rgb(249,249,249)' : 'fill: rgb(255,255,255)';
            },
            isLastYear() {
                return this.index === (this.$root.years.length - 1);
            }
        }
    };
</script>