import Vue from 'vue';
import Page from '../lib/Page';
import CwModal from './shared/modal.vue';

export default (function () {
    Page.registerVue('#locaties-modals', function (rootSelector) {
        new Vue({
            el: rootSelector,
            components:{CwModal},
            methods: {
                openMapsModal(slug: string) {
                    this.$root.$emit('show-modal', 'maps' + slug);
                    this.$nextTick(() => {
                        Page.applyBehaviors(rootSelector);
                    });
                }
            }
        });
    });
}());