import Vue from "vue";
import Page from "../../lib/Page";
import CompanyInformationFeatures from './components/company-information-features.vue';

export default (function () {
    Page.registerVue('#possibilities', function (rootSelector) {
        new Vue({
            el: rootSelector,
            name: 'possibility_wrapper',
            components: {
                CompanyInformationFeatures
            },
            data() {
                return {
                
                }
            },
            mounted() {
                Page.applyBehaviors(rootSelector);
            }
        });
    });
}());
