﻿import * as Sentry from '@sentry/browser';

export default (function () {
    type Issues = {
        [key: string]: number;
    }
    let shownIssues: Issues = {};
    let shouldCheck = true;

    try { shouldCheck = document.cookie.indexOf("show_translation_keys=true") === -1; }
    catch (e) { }

    if (shouldCheck) {
        registerProblematicPattern('Untranslated Key', ['[['], /\[{2}[a-z][\w-]+\]{2}/g);
    }

    registerProblematicPattern('Untranslated Label', ['<label'], /\<label[^>]*>((?:[^_<@]|[\r\n])*_[^<@]*)</g);
    registerProblematicPattern('Untranslated ErrorMessage', ['data-valmsg-replace="true"'], /<span [^>]*data-valmsg-replace\s*=\s*"true"[^>]*>((?:[^_<]|[\r\n])*[_\{][^<]*)</g);

    function registerProblematicPattern(name: string, quickChecks: string[], pattern: RegExp) {
        function handler() {
            warnForProblematicPattern(name, quickChecks, pattern);
        }

        handler();
    }

    function warnForProblematicPattern(name: string, quickChecks: string[], pattern: RegExp) {
        const html = document.documentElement.innerHTML;
        let doThoroughCheck = false;

        for (let quickCheck in quickChecks)
            if (html.indexOf(quickCheck) !== -1)
                doThoroughCheck = true;

        if (!doThoroughCheck)
            return;

        const matches: RegExpExecArray[] = [];
        let match: RegExpExecArray | null;
        
        do {
            match = pattern.exec(html);
            if (match)
                matches.push(match);
        } while (match);


        if (matches.length && matches.length > (shownIssues[name] || 0)) {
            shownIssues[name] = matches.length;
            const pageUrl = window.location.pathname;

            for (let i in matches) {
                const match = matches[i] as RegExpExecArray;
                if(!match.input)
                    return;

                const errorMessage = name + ' found: ' + pageUrl;
                console.error(name + ' found on line ' + lineNr(match) + ' |=> ' + matchWithContext(15, match));
            
                Sentry.withScope(function (scope) {
                    scope.setExtra('lineNumber', lineNr(match));
                    scope.setExtra('context', matchWithContext(150, match));
                    scope.setExtra('pageUrl', window.location.pathname);
                    Sentry.captureMessage(errorMessage);
                });
            }
        }
    }

    function matchWithContext(numberOfCharacters: number, match: RegExpExecArray): string {
        return '...' +
            match.input.substring(
                Math.max(0, match.index - numberOfCharacters),
                Math.min(match.input.length, match.index + match[0].length + numberOfCharacters)
            ) +
            '...';
    }

    function lineNr(match: RegExpExecArray): number {
        const numberOfLinesBeforeHead = 2;

        return match.input.substring(0, match.index).split('\n').length + numberOfLinesBeforeHead;
    }

}());
