﻿<template>
    <th :class="{'open': isFilterBoxShown, 'vertical narrow': column.isVertical, 'filtered': column.filterCriteria.hasValue}"
        @click.stop="toggle" :style="'min-width:' + (column.minWidth ? (column.minWidth + 'px') : '25px')">
        <div class="filter-title-row">
            <div class="d-flex" :class="{'align-items-center': column.isVertical}" style="gap:8px">
                <div class="text">
                    {{ translate(column.title) }}
                </div>
                <div class="d-flex flex-column sort-wrapper">
                    <a class="d-inline-block sort-btn" :class="{'active': isSorted && !isDescending}" href="#"
                       @click.prevent.stop="setSort(false)" :title="translate('sort_asc')">
                        <i class="fa-caret-up"
                           :class="{'fas': isSorted && !isDescending, 'far': !isSorted || isDescending}"></i>
                    </a>
                    <a class="d-inline-block  sort-btn" :class="{'active': isSorted && isDescending}" href="#"
                       @click.prevent.stop="setSort(true)" :title="translate('sort_desc')">
                        <i class="fa-caret-down"
                           :class="{'fas': isSorted && isDescending, 'far': !isSorted || !isDescending}"></i>
                    </a>
                </div>
            </div>
        </div>
        <transition name="dropdown">
            <div v-if="isFilterBoxShown" v-click-outside="close">
                <div id="filter-box" class="filter-box" @click.stop>
                    <div class="row mb-2">
                        <h3 class="col-md-6">
                            {{ translate(column.title) }}
                        </h3>
                        <div class="col-md-6 text-right" v-if="column.filterCriteria.hasValue">
                            <a href="" class="text-grey link" @click.prevent="column.filterCriteria.reset()"><i
                                class="fas fa-times"></i> {{ translate('delete_filter') }}</a>
                        </div>
                    </div>
                    <div v-if="column.showDescription" class="mb-3">
                        <em>{{ translate(column.title + '_desc') }}</em>
                    </div>

                    <div v-if="column.filterCriteria._type === FilterType.FREETEXT">
                        <free-text :criteria="column.filterCriteria"></free-text>
                    </div>
                    <div v-if="column.filterCriteria._type === FilterType.BOOLEAN">
                        <yes-no :criteria="column.filterCriteria"></yes-no>
                    </div>
                    <div v-else-if="column.filterCriteria._type === FilterType.SELECTIONLIST">
                        <selection-list :column="column" limited-height></selection-list>
                    </div>
                    <div v-else-if="column.filterCriteria._type === FilterType.SELECTIONTREE">
                        <selection-tree
                            limited-height
                            :column="column"
                            :criteria="column.filterCriteria"
                            :treeData="getSelectionTree"></selection-tree>
                    </div>
                    <div v-else-if="column.filterCriteria._type === FilterType.DATERANGE" class="mt-3">
                        <date-range :criteria="column.filterCriteria"></date-range>
                    </div>
                    <div v-else-if="column.filterCriteria._type === FilterType.NUMERICRANGE">
                        <div class="px-2 py-3">
                            <range-slider 
                                :is-linear="isLinear"
                                :lower-bound="lowerBound" 
                                :upper-bound="upperBound" 
                                v-model="column.filterCriteria._value"></range-slider>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </th>
</template>

<script lang="ts">
import {Column, FilterType, NumericRangeFilterCriteria, SelectionTreeNode} from '../../types';
import {useStore} from '../../store';
import Translate from '../../../mixins/translate';
import RangeSlider from '../../../shared/range-slider/index.vue';
import FreeText from './filter-types/free-text.vue';
import YesNo from './filter-types/yes-no.vue';
import DateRange from './filter-types/date-range.vue';
import SelectionList from './filter-types/selection-list.vue';
import SelectionTree from './filter-types/selection-tree.vue';
//@ts-ignore
import ClickOutside from "vue-click-outside";

export default Translate.extend({
    name: 'filter-th',
    components: {SelectionTree, DateRange, RangeSlider, FreeText, YesNo, SelectionList},
    directives: {ClickOutside},
    props: {
        column: {
            required: true,
            type: Object as () => Column
        },
    },
    data() {
        return {
            store: useStore(),
            FilterType,
            dateLowerBound: undefined as Date | undefined,
            dateUpperBound: undefined as Date | undefined
        };
    },
    methods: {
        toggle(): void {
            this.store.toggleFilterBox(this.column);
        },
        close(): void {
            this.dateLowerBound = undefined;
            this.dateUpperBound = undefined;
            this.store.toggleFilterBox(null);
        },
        setSort(desc: boolean): void {
            this.store.setSorting(this.column.title, desc);
            this.close();
        }
    },
    computed: {
        isFilterBoxShown(): boolean {
            return this.store.openedFilterBox === this.column;
        },
        hasCheckedFilters(): boolean {
            return this.column.filterCriteria.hasValue;
        },
        isSorted(): boolean {
            return this.store.sorting.property === this.column.title;
        },
        isDescending(): boolean {
            return this.store.sorting.descending;
        },
        lowerBound(): number | undefined {
            return (this.column.filterCriteria as NumericRangeFilterCriteria)._min;
        },
        upperBound(): number | undefined {
            return (this.column.filterCriteria as NumericRangeFilterCriteria)._max;
        },
        isLinear(): boolean | undefined {
            return (this.column.filterCriteria as NumericRangeFilterCriteria)._isLinear;
        },
        getSelectionTree(): SelectionTreeNode[] {
            switch (this.column.title) {
                case 'nace':
                    return this.store.naceTree;
                case 'beroepenindex':
                    return this.store.beroepenIndexTree;
                case 'custom_categories' :
                    return this.store.customCategoriesTree;
                default: 
                    return [];
            }
        }
    }
});
</script>

<style lang="scss" scoped>
$primColor: #0368FF;

h3 {
    text-transform: none;
}

.link {
    text-transform: none;
    text-decoration: none !important;

    &:hover {
        text-decoration: underline !important;
    }
}

th {
    position: relative;
    cursor: pointer;
    user-select: none;
    color: #595959;
    font-weight: 500;

    &.filtered {
        color: black;
        font-weight: 600;
    }

    em {
        font-weight: normal;
        white-space: normal;
        word-break: normal;
    }

    &:hover, &.open {
        background-color: white;
    }

    &.vertical {
        transform-origin: bottom;
        transform: skewX(-10deg);

        .filter-box {
            transform: skewX(10deg);
        }

        .sort-wrapper {
            transform: skewX(10deg);
        }

        .filter-title-row {
            writing-mode: vertical-rl;
            vertical-align: bottom;

            .text {
                transform: rotateZ(-170deg) skewX(10deg);
            }

            .has-filtered {
                transform: skewX(10deg);
            }
        }

    }

    .filter-box {
        position: absolute;
        background: white;
        padding: 25px;
        min-width: 520px;
        width: 100%;
        right: 0;
        top: 100%;
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
        border-radius: 0 0 4px 4px;
        z-index: 20;
        border-top: 0;
        cursor: auto;
        white-space: break-spaces;
    }
}

.filter-title-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 1;
    position: relative;
    gap: 10px;

    .has-filtered {
        padding: 5px 9px;
        border-radius: 3px;
        display: inline-block;
        background-color: #d7d7d7;
        writing-mode: lr;
        margin-top: 10px;

        &:hover {
            background-color: #c4c4c4;
            color: black;
        }
    }
}

.sort-wrapper {
    writing-mode: initial;
    justify-content: flex-end;

    .sort-btn {
        line-height: 4px;
        padding: 2px;
        color: #c0c0c0;

        :hover {
            color: grey;
        }

        &.active {
            color: $primColor;
        }

        i {
            line-height: 0.4;
        }
    }
}
</style>