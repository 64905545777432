﻿import {Adres} from '../../types/dto/Adres';
import Translate from '../mixins/translate';
import translate from "../../lib/Translate";

interface RadarCompany extends RadarCompanyDto{
    juridischeVormTranslated: string;
    juridischeSituatieTranslated: string;
    beroepenIndexDescription: string | null;
    hoofdNace: string;
}

enum DrawerView {
    COLUMN_EDIT = "column-edit",
    COLUMN_ADD = "column-add",
    FILTER_LIST = "filter-list",
    FILTER_DETAIL = "filter-detail",
    FILTER_SAVE = "filter-save"
}

interface SavedFilter {
    id: number;
    version: number;
    title: string;
    criteria: Filter;
}
interface RadarCompanyDto {
    vat: number;
    companyName: string;
    barometer: string;
    isActive: boolean;
    vestigingen: null;
    zetelAddress: Adres;
    juridischeVorm: number;
    juridischeSituatie: number;
    beroepenIndex: string | null;
    beginDatum: number;
    eindDatum: number | null;
    btwPlichtig: boolean;
    numberOfEmployees: number;
    lastBestuursWissel: number | null;
    vestigingsAdressen: Adres[];
    hoofdNaceCode: ChangeableProperty<string>;
    postCode: ChangeableProperty<number>;
    lateFinancialstatements: ChangeableProperty<number>;
    fakeFinancialstatements: ChangeableProperty<number>;
    negativeEquity: ChangeableProperty<number>;
    creditLimitZero: ChangeableProperty<number>;
    warningLiquidity: ChangeableProperty<number>;
    vatDeleted: ChangeableProperty<number>;
    addressCrossedOut: ChangeableProperty<number>;
    administrativelyCanceled: ChangeableProperty<number>;
    hasDangerousPerson: ChangeableProperty<number>;
    hasDangerousAddress: ChangeableProperty<number>;
    hasBusyAddress: ChangeableProperty<number>;
    movedToBusinessCenter: ChangeableProperty<number>;
    hasInhoudingsplicht: ChangeableProperty<number>;
    rszDagvaardingCount: ChangeableProperty<number>;
    hasManyNaceCodes: ChangeableProperty<number>;
    hasCrimeLink: ChangeableProperty<number>;
    hasMandateHolderWithFailedAndNewCompanies: ChangeableProperty<number>;
    hasNewManagement: ChangeableProperty<number>;
    managementChangeCount: ChangeableProperty<number>;
    foodwebScore: ChangeableProperty<number>;
    zombie: ChangeableProperty<number>;
    ghost: ChangeableProperty<number>;
    multiMove: ChangeableProperty<number>;
    totalFraudScore: ChangeableProperty<number>;
    enterListDate: number | null;
    leaveListDate: number | null;
    customValues: CustomValue[];
    customCategories: string[];
    mandateHolders: MandateHolder[];
    isInAlerts: boolean;
    isInFavourites: boolean;
    lastChangeDate: number;
}

interface MandateHolder {
    name: string;
    link: string;
    vormId: number | null;
}

enum RadarProfile {
    POLICE = "police",
    MULTI_DATASET = "multi-dataset",
    COMPANYWEB = "companyweb",
}

interface CustomField {
    id: number;
    name: string;
    description: string;
    type: "string" | "boolean";
    minWidth: number;
}

interface Translation<T> {
    value: T;
    nl: string;
    fr: string;
    en: string;
}

interface TranslationWithParent<T> extends Translation<T>{
    parentValue: T;
}

interface GeneralFile {
    juridischeVorm: Translation<number>[];
    juridischeSituatie: Translation<number>[];
    nace: Translation<string>[];
    beroepenIndex: TranslationWithParent<string>[];
    customCategory: TranslationWithParent<string>[];
}

interface CustomValue {
    stringValue: string | null;
    boolValue: boolean | null;
    fieldId: number;
    fieldType: "string" | "boolean";
    value: string | boolean;
    vat: number;
}

enum Subset {
    ALL = "all",
    ALL_ACTIVE = "all-active-only",
    UPDATED = "changed",
    NEW = "new",
    DELETED = "removed"
}

interface Dataset {
    title: string;
    id: number;
}

interface IndexFile {
    maxTotalScore: number;
    maxEmployees: number;
    radarStartDate: number;
    currentPeriodStartDate: number | undefined;
    hasOnlyLocationCriteria: boolean;
    zipCodesFromCriteria: number[];
}
interface Column {
    title: string;
    hidden: boolean;
    isFilterOnly: boolean;
    forceExpandTree: boolean;
    isVertical: boolean;
    showDescription: boolean;
    toClass: (v: RadarCompany) => string;
    toHtml: (v: RadarCompany) => string;
    toString: (v: RadarCompany) => string;
    sortMapper: (v:  RadarCompany) => string | number;
    lastUpdated: (v: RadarCompany) => number | undefined;
    isBold: boolean;
    minWidth?: number;
    matchesCriteria: (v: RadarCompany, criteria: FilterCriteria) => boolean;
    filterCriteria: FilterCriteria
}

interface ColumnForStorage extends  Pick<Column, "filterCriteria" | "title">{}

interface ChangeableProperty<T> {
    value: T;
    updateDate: number;
}

enum FilterType{
    FREETEXT,
    SELECTIONLIST,
    DATERANGE,
    NUMERICRANGE,
    SELECTIONTREE,
    BOOLEAN
}

interface Filter {
    companyNameFilter: string;
    formFilters: string[];
    streetFilters: string[];
    genericFilters: ColumnForStorage[];
    customFilters: Record<number, string | boolean | null>
}

interface NamedId {
    id: string;
    name: string;
}

type NullableBoolean = boolean | null;

abstract class FilterCriteria {
    _type: FilterType;
    _value;
    
    protected constructor(type: FilterType, value: NullableBoolean | string[] | NumericRange | DateRange | NamedId[]) {
        this._type = type;
        this._value = value;
    }

    public abstract get hasValue(): boolean;
    public abstract reset(): void;
    public abstract setValue(value: NullableBoolean | string[] | NumericRange | DateRange | NamedId[]): void; 
    public abstract matchesCriteria(v: any): boolean;
    public abstract toHtml(): string;
}

class FreeTextFilterCriteria implements FilterCriteria {
    public _type: FilterType = FilterType.FREETEXT;
    public _value: string[];

    public constructor(val?: string[]) {
        this._value = val ?? ["", ""];
    }

    public matchesCriteria(possibleValues: string[]): boolean {
        return !this.hasValue || this._value.every(x => possibleValues.some(v => v.cw_contains(x))) ;
    }

    public get hasValue(): boolean {
        return this._value.length != 0 && !this._value.every(v => v === "");
    }

    public reset(): void {
        this._value = ["", ""];
    }

    public setValue(val: string[]): void {
        this._value = val;
    }
    
    public toHtml(): string {
        return this._value[0] + " " + this._value[1];
    }
}

class BooleanFilterCriteria implements FilterCriteria {
    public _type: FilterType = FilterType.BOOLEAN;
    public _value: NullableBoolean;

    public constructor(val?: boolean) {
        this._value = val ?? null;
    }

    public matchesCriteria(companyValue: boolean): boolean {
        return !this.hasValue || this._value === companyValue;
    }

    public get hasValue(): boolean {
        return this._value !== null;
    }

    public reset(): void {
        this._value = null;
    }
    
    public setValue(val: boolean): void {
        this._value = val;
    }

    toHtml(): string {
        return this._value ? translate('filter_yes') : translate('filter_no');
    }
}

class SelectionListFilterCriteria implements FilterCriteria {
    public _type: FilterType = FilterType.SELECTIONLIST;
    public _value: string[];

    public constructor(val?: string[]) {
        this._value = val ?? [];
    }

    public matchesCriteria(companyValue: string): boolean {
        return !this.hasValue || this._value.some(v => v === companyValue);
    }

    public get hasValue(): boolean {
        return this._value.length != 0;
    }

    public reset(): void {
        this._value = [];
    }
    
    public setValue(val: string[]): void {
        this._value = val;
    }

    toHtml(): string {
        return  this._value.length == 1 
            ? this._value[0].truncate(25)
            : this._value.length.toString();
    }
}

class SelectionTreeFilterCriteria implements FilterCriteria {
    public _type: FilterType = FilterType.SELECTIONTREE;
    public _value: NamedId[];

    public constructor(val?: NamedId[]) {
        this._value = val ?? [];
    }

    public matchesCriteria(companyValue: string): boolean {
        return !this.hasValue || this._value.some(v => companyValue.startsWith(v.id));
    }

    public matchesMultiCriteria(companyValue: string[]): boolean {
        return !this.hasValue || this._value.some(v => companyValue.some(cv => cv.startsWith(v.id)));
    }

    public get hasValue(): boolean {
        return this._value.length != 0;
    }

    public reset(): void {
        this._value = [];
    }

    public setValue(val: NamedId[]): void {
        this._value = val;
    }

    toHtml(): string {
        return  this._value.length == 1
            ? this._value[0].name.truncate(25)
            : this._value.length.toString();
    }
}

class DateRangeFilterCriteria implements FilterCriteria {
    public _type: FilterType = FilterType.DATERANGE;
    public _value: DateRange;

    public constructor(range?: DateRange) {
        this._value = {
            lowerBound: range?.lowerBound,
            upperBound: range?.upperBound,
        };
    }

    public matchesCriteria(companyValue: Date | undefined): boolean {
        if(!this.hasValue)
            return true;

        if(companyValue == undefined)
            return false;

        return (this._value.upperBound === undefined && companyValue >= this._value.lowerBound!) ||
            (this._value.lowerBound === undefined && companyValue <= this._value.upperBound!) ||
            (companyValue <= this._value.upperBound! && companyValue >= this._value.lowerBound!);
    }

    public get hasValue(): boolean {
        return this._value.lowerBound !== undefined || this._value.upperBound !== undefined;
    }

    public reset(): void {
        this._value = {
            lowerBound: undefined,
            upperBound: undefined,
        };
    }
    
    public setValue(range: DateRange): void {
        let l = range.lowerBound;
        let u = range.upperBound;
        
        this._value.lowerBound = typeof l === 'string' ? new Date(l) : l;
        this._value.upperBound = typeof u === 'string' ? new Date(u) : u;
    }

    toHtml(): string {
        return (this._value.lowerBound?.format() ?? "") + " - " + (this._value.upperBound?.format() ?? "");
    }
}

class NumericRangeFilterCriteria implements FilterCriteria {
    public _type: FilterType = FilterType.NUMERICRANGE;
    public _value: NumericRange;
    public _min: number;
    public _max: number;
    public _isLinear: boolean;
    
    public constructor(range?: NumericRange, min?: number, max?: number, isLinear?: boolean) {
        this._value = {
            lowerBound: range?.lowerBound,
            upperBound: range?.upperBound,
        };
        this._min = min ?? 0;
        this._max = max ?? 100;
        this._isLinear = isLinear ?? false;
    }

    public matchesCriteria(companyValue: number | null): boolean {
        if(!this.hasValue)
            return true;
        
        if(companyValue == null)
            return false;

        return (this._value.upperBound === undefined && companyValue >= this._value.lowerBound!) ||
            (this._value.lowerBound === undefined && companyValue <= this._value.upperBound!) ||
            (companyValue <= this._value.upperBound! && companyValue >= this._value.lowerBound!);
    }

    public get hasValue(): boolean {
        return this._value.lowerBound !== undefined || this._value.upperBound !== undefined;
    }

    public reset(): void {
        this._value = {
            lowerBound: undefined,
            upperBound: undefined,
        };
    }
    
    public setValue(val: NumericRange): void {
        this._value = val;
    }

    toHtml(): string {
        return (this._value.lowerBound?.toString() ?? "") + " - " + (this._value.upperBound?.toString() ?? "");
    }
}

interface SelectionTreeNode {
    value: string;
    name: string;
    children: SelectionTreeNode[];
}

export {
    Filter,
    RadarCompany,
    RadarCompanyDto,
    Column,
    ChangeableProperty,
    CustomField,
    CustomValue,
    ColumnForStorage,
    SavedFilter,
    GeneralFile,
    SelectionTreeNode,
    TranslationWithParent,
    NamedId,
    RadarProfile,
    FilterType,
    Subset,
    Dataset,
    FreeTextFilterCriteria,
    BooleanFilterCriteria,
    SelectionListFilterCriteria,
    SelectionTreeFilterCriteria,
    DateRangeFilterCriteria,
    NumericRangeFilterCriteria,
    FilterCriteria,
    DrawerView,
    IndexFile
}