<template>
    <form @submit.prevent="onSubmit">
        <div class="row mb-4 mt-5">
            <div class="col-8">
                <ul class="nav nav-tabs border-bottom">
                    <li class="nav-item" >
                        <a href="" class="nav-link" :class="{'active': currentTab === Tab.ALGEMEEN}" @click.prevent="changeTab(Tab.ALGEMEEN)"><i class="fas fa-info-circle mr-1 text-lightgrey"></i> Algemeen</a>
                    </li>
                     <li class="nav-item">
                        <a href="" class="nav-link" :class="{'active': currentTab === Tab.APP}" @click.prevent="changeTab(Tab.APP)"><i class="fas fa-code mr-1 text-lightgrey"></i>Applicatie</a>
                    </li>
                     <li class="nav-item">
                        <a href="" class="nav-link" :class="{'active': currentTab === Tab.DOWNLOADS}" @click.prevent="changeTab(Tab.DOWNLOADS)"><i class="fas fa-file-pdf mr-1 text-lightgrey"></i>Downloads</a>
                    </li>
                     <li class="nav-item">
                        <a href="" class="nav-link" :class="{'active': currentTab === Tab.SCREENSHOTS}" @click.prevent="changeTab(Tab.SCREENSHOTS)"><i class="fas fa-image mr-1 text-lightgrey"></i>Screenshots</a>
                    </li>
                </ul>
            </div>
            <div class="col-4 text-right">
                <button type="submit" class="btn btn-blue btn-200"><i class="fas fa-save mr-1"></i> Opslaan</button>
            </div>
        </div>

        <!-- TAB ALGEMEEN -->
        <div class="row"  v-if="currentTab === Tab.ALGEMEEN">
            <div class="col-6">
                <div class="row">
                    <div class="col-8">
                        <div class="form-group">
                            <label for="name">Naam</label>
                            <input id="name" type="text" v-model="name" class="form-control" required/>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="name">Price</label>
                            <input id="name" type="number" v-model="price" class="form-control" required/>
                        </div>
                    </div>
                </div>
                

               <div class="form-group">
                    <label for="distributor">Verdeler</label>
                    <input id="distributor" type="text" v-model="distributor" class="form-control" placeholder="Enkel indien meerdere verdelers"/>
                </div>

                <div class="form-group">
                    <label for="distributorRedesign">Verdeler Redesign</label>
                    <input id="distributorRedesign" type="text" v-model="distributorRedesign" class="form-control" placeholder="Enkel indien meerdere verdelers"/>
                </div>

                <div class="form-group">
                    <label for="name">Zoekwoorden</label>
                    <vue-tags-input
                        v-model="tag"
                        :tags="tags"
                        placeholder="Zoekwoord toevoegen"
                        @tags-changed="newTags => tags = newTags"
                        />
                </div>

                <div class="form-group">
                    <label>Omschrijving NL</label>
                    <vue-editor v-model="descriptionNL" :editorToolbar="customToolbar"></vue-editor>
                </div>     
                <div class="form-group">
                    <label>Omschrijving FR</label>
                    <vue-editor v-model="descriptionFR" :editorToolbar="customToolbar"></vue-editor>
                </div> 
                <div class="form-group">
                    <label>Omschrijving EN</label>
                    <vue-editor v-model="descriptionEN" :editorToolbar="customToolbar"></vue-editor>
                </div>  
            </div>
            <div class="col-6">
                <div class="form-group">
                    <div class="row">
                        <div class="col-7">
                        <file-input 
                            v-on:input="onLogoUpload" 
                            :multiple="false" 
                            :required="false" 
                            name="logo"
                            placeholder=".png" 
                            title="Upload logo"
                            accept=".png" ></file-input>
                        </div>
                        <div class="col-5 mt-auto">
                            <img :src="logo.path" v-if="logo" class="img-fluid"/>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="col-7">
                            <file-input
                                v-on:input="onLogoRedesignUpload"
                                :multiple="false"
                                :required="false"
                                name="logoRedesign"
                                placeholder=".png"
                                title="Upload logoRedesign"
                                accept=".png" ></file-input>
                        </div>
                        <div class="col-5 mt-auto">
                            <img :src="logoRedesign.path" v-if="logoRedesign" class="img-fluid"/>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="intro_nl">Intro NL</label>
                    <vue-editor id="intro_nl" v-model="introNL" :editorToolbar="customToolbar"/>
                </div> 
                <div class="form-group">
                    <label for="intro_fr">Intro FR</label>
                    <vue-editor id="intro_fr" v-model="introFR" :editorToolbar="customToolbar"/>
                </div> 
                <div class="form-group">
                    <label for="intro_en">Intro EN</label>
                    <vue-editor id="intro_en" v-model="introEN" :editorToolbar="customToolbar"/>
                </div>

                <div class="form-group">
                    <b class="label-like">CTA knop</b>
                    <div>
                        <input id="c" type="radio" v-model="ctaType" :value="CtaType.NOCTA">
                        <label for="c">Geen CTA</label>
                    </div>
                    <div class="mt-1">
                        <input id="b" type="radio" v-model="ctaType" :value="CtaType.CONTACTUS">
                        <label for="b">Contacteer ons</label>
                    </div>
                    <div class="mt-1">
                        <input id="a" type="radio" v-model="ctaType" :value="CtaType.ACTIVATABLE">
                        <label for="a">Activeerbaar</label>
                    </div>
                </div>

                <div class="form-group my-4">
                    <b class="label-like">Type software</b>
                    <div>
                        <input id="e" type="radio" v-model="isCustom" :value="false">
                        <label for="e">Generiek</label>
                    </div>
                    <div class="mt-1">
                        <input id="d" type="radio" v-model="isCustom" :value="true">
                        <label for="d">Op maat</label>
                    </div>
                </div>

                <div class="form-group">
                    <input id="betalingservaring" type="checkbox" v-model="hasBetalingservaring" class="form-check-input">
                    <label for="betalingservaring">Heeft Betalingservaring?</label>
                </div>

                <div class="form-group">
                    <input id="hasFollowUp" type="checkbox" v-model="hasFollowUp" class="form-check-input">
                    <label for="hasFollowUp">Heeft follow-up?</label>
                </div>

                <div class="form-group">
                    <input id="prospect" type="checkbox" v-model="isProspect" class="form-check-input" :disabled="status === SoftwareStatus.PUBLISHED && !isProspect">
                    <label for="prospect">Prospect?</label>
                </div>

                <div class="form-group">
                    <input id="legacy" type="checkbox" v-model="isLegacy" class="form-check-input">
                    <label for="legacy">Legacy?</label> <i class="fas fa-info-circle" title="Legacy software wordt enkel op beheer-pagina getoond, maar niet op de ontdek-pagina."></i>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="col-6">
                            <label for="label">Label</label>
                            <select v-model="label" class="form-control" id="label">
                                <option :value="null">Geen</option>
                                <option v-for="label in SoftwareLabel" :key="label" :value="label">{{label}}</option>
                            </select>
                        </div>
                        <div class="col-6">
                            <label for="status">Status <span class="status-circle" :class="{'available': status === SoftwareStatus.PUBLISHED}"></span></label>
                            <select v-model="status" class="form-control" id="status">
                                <option :disabled="(isProspect || hasEmptyCredentials) && status === SoftwareStatus.PUBLISHED" v-for="status in SoftwareStatus" :key="status" :value="status.valueOf()">{{status}}</option>
                            </select>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

        <!-- TAB APP -->
        <div class="row" v-if="currentTab === Tab.APP">
            <div class="col-6">
                <div class="form-group mt-4">
                    <div class="box">
                        <div class="box-title">
                            Integrator Credentials
                        </div>
                        <div class="form-group">
                            <label for="creds_id">ID</label>
                            <input id="creds_id" type="text" v-model="integratorCredentials.softwareId" :readonly="isEdit" class="form-control"/>
                        </div>
                        <div class="form-group" v-if="isEdit">
                            <label for="creds_secret">Secret</label>
                            <input id="creds_secret" type="text" v-model="integratorCredentials.secret" readonly class="form-control user-select-all"/>
                        </div>
                        <div class="form-group" v-if="isEdit && hasEmptyCredentials">
                            <button class="btn btn-info" @click.prevent="generateCredentials()"><i class="fas fa-sync mr-1"></i> Generate credentials</button>
                        </div>
                    </div>
               </div>

                <div class="form-group mt-5">
                    <div class="box">
                        <div class="box-title">
                            On-Premise App
                        </div>

                        <div v-if="!isEdit" class="tip">
                            On-Premise app toevoegen kan enkel na het aanmaken van de software.
                        </div>
                       
                        <file-input 
                            v-else
                            v-on:input="onPremiseAppUpload" 
                            :multiple="false" 
                            :required="false" 
                            name="app"
                            placeholder=".zip" 
                            title="Upload app"
                            accept=".zip"></file-input>


                        <template v-if="onPremiseApp.path !== ''">
                            <div class="my-3 "><b>Upload link:</b> <span class="font-italic" style="user-select:all">{{onPremiseApp.path}}</span></div>
                            <div class="form-group small-editor">
                                <label>On-Premise tekst NL</label>
                                <vue-editor v-model="onPremiseApp.textNL" :editorToolbar="customToolbar"></vue-editor>
                            </div>   
                            <div class="form-group small-editor">
                                <label>On-Premise tekst FR</label>
                                <vue-editor v-model="onPremiseApp.textFR" :editorToolbar="customToolbar"></vue-editor>
                            </div>   
                            <div class="form-group small-editor">
                                <label>On-Premise tekst EN</label>
                                <vue-editor v-model="onPremiseApp.textEN" :editorToolbar="customToolbar"></vue-editor>
                            </div> 

                            <a href="" @click.prevent="deleteApp()" class="text-danger"><i class="fas fa-times mr-2"></i>Verwijder app</a>
                        </template>    
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <div class="box">
                        <div class="box-title">
                            Externe link
                        </div>
                        <div class="form-group small-editor">
                            <label>Tekst NL</label>
                            <vue-editor v-model="externalLinkNL" :editorToolbar="customToolbar"></vue-editor>
                        </div>   
                        <div class="form-group small-editor">
                            <label>Tekst FR</label>
                            <vue-editor v-model="externalLinkFR" :editorToolbar="customToolbar"></vue-editor>
                        </div>   
                        <div class="form-group small-editor">
                            <label>Tekst EN</label>
                            <vue-editor v-model="externalLinkEN" :editorToolbar="customToolbar"></vue-editor>
                        </div> 
                    </div>
               </div>
            </div>
        </div>

        <!-- TAB DOWNLOADS -->
        <div class="row" v-if="currentTab === Tab.DOWNLOADS">
            <div class="col-6">
                <div class="form-group mt-3">
                    <div class="box">
                        <div class="box-title">
                            Contacteer Partner
                        </div>
                        <div class="form-group small-editor">
                            <label>Tekst over partner NL</label>
                            <vue-editor v-model="partnerTextNL" :editorToolbar="customToolbar"></vue-editor>
                        </div>   
                        <div class="form-group small-editor">
                            <label>Tekst over partner FR</label>
                            <vue-editor v-model="partnerTextFR" :editorToolbar="customToolbar"></vue-editor>
                        </div>   
                        <div class="form-group small-editor">
                            <label>Tekst over partner EN</label>
                            <vue-editor v-model="partnerTextEN" :editorToolbar="customToolbar"></vue-editor>
                        </div> 
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-6">
                        <h2>Downloads</h2>
                    </div>
                    <div class="col-6 text-right">
                        <button v-if="isEdit" class="btn btn-ghost btn-sm" @click.prevent="createEmptyDownload()">
                            <i class="fas fa-file-plus mr-2"></i> Download toevoegen
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div v-if="!isEdit" class="tip">
                            Downloads toevoegen kan enkel na het aanmaken van de software.
                        </div>
                    
                        <software-manual-component v-for="(download, index) in downloads" :manual.sync="downloads[index]" :key="index" :download="download" :index="index" @delete-download="deleteDownload"></software-manual-component>
                    </div>
                </div>
            </div>
        </div>


        <!-- TAB SCREENSHOTS -->
        <div class="row" v-if="currentTab === Tab.SCREENSHOTS">
             <div class="col-12">
                 <div class="row">
                     <div class="col-6">
                        <file-input 
                            v-on:input="onScreenshotUpload" 
                            :multiple="true" 
                            :required="false" 
                            name="logo"
                            placeholder=".png" 
                            title="Upload screenshots"
                            accept=".png,.jpg,.jpeg" ></file-input>
                     </div>

                     <div class="col-12 mt-4">
                         <div class="row">
                            <div class="col-2 my-auto relative" v-for="(screenshot, index) in allScreenshots" :key="'screen_' + index">
                                <button class="btn btn-danger delete-btn" @click.prevent="deleteScreenshot(screenshot)"><i class="fas fa-times"></i></button>
                                <img :src="screenshot.path" class="img-fluid"/>
                            </div>
                         </div>
                     </div>
                 </div>
            </div>
        </div>

        <hr>

        <!-- TUSSENPERSOON -->
        <div class="box mt-5" v-if="tussenPersoon">
            <div class="box-title">Tussenpersoon</div>
            <div class="row">
               <div class="col-md-2">
                    <b>Bedrijf</b>
               </div>
               <div class="col-md-4">
                    {{tussenPersoon.companyName}} <span v-if="tussenPersoon.companyVat">({{tussenPersoon.companyVat}})</span>
               </div>

                <div class="col-md-2">
                    <b>Contactpersoon</b>
               </div>
               <div class="col-md-4">
                    {{tussenPersoon.contactName}}
               </div>
            </div>

            <div class="row mt-3">
               <div class="col-md-2">
                    <b>Telefoon</b>
               </div>
               <div class="col-md-4">
                    {{tussenPersoon.phone}}
               </div>

               <div class="col-md-2">
                    <b>Gsm</b>
               </div>
               <div class="col-md-4">
                    {{tussenPersoon.mobile}}
               </div>
            </div>

            <div class="row mt-3">
               <div class="col-md-2">
                    <b>Adres</b>
               </div>
               <div class="col-md-4">
                    {{tussenPersoon.address.lijn1}}<br>
                    {{tussenPersoon.address.lijn2}}

               </div>

               <div class="col-md-2">
                    <b>E-mailadres</b>
               </div>
               <div class="col-md-4">
                    {{tussenPersoon.email}}
               </div>
            </div>

            
            <div class="row mt-3">
               <div class="col-md-2">
                    <b>Website</b>
               </div>
               <div class="col-md-4">
                    {{tussenPersoon.website}}
               </div>

               <div class="col-md-2">
                    <b>Commissie</b>
               </div>
               <div class="col-md-4">
                    {{(tussenPersoon.commission || 0) * 100}} %
               </div>
            </div>
        </div>
    </form>
</template>

<script lang="ts">
    import Vue from 'vue';
    import Option from '../../../types/global/option';
    /*@ts-ignore*/
    import VueTagsInput from '@johmun/vue-tags-input';
    import FileInput from '../../shared/file-input.vue';
    import SoftwareManualComponent from './software-manual.vue';
    import { VueEditor } from "vue2-editor";
    import Ajax from '../../../lib/Ajax';
    import Toast from '../../../lib/Toast';
    import {SoftwarePartner, SoftwareStatus, IntegratorCredentials, SoftwarePartnerManual, SoftwareLabel, CtaType} from '../../../types/dto/SoftwarePartner';

    interface Screenshot {
        file: File | null;
        path: string;
    }

    interface Tag {
        text: string;
    }

    enum Tab {
        ALGEMEEN,
        APP,
        DOWNLOADS,
        SCREENSHOTS
    }
    
    export default Vue.extend({
        name: 'upsert_form',
        components: {
            FileInput,
            VueEditor,
            SoftwareManualComponent,
            VueTagsInput
        },
        props: {
            populateWith: Object as () => Option<SoftwarePartner>
        },
        data() {
            return {
                Tab,
                currentTab: Tab.ALGEMEEN,
                SoftwareStatus,
                SoftwareLabel,
                CtaType,
                tag: '',
                customToolbar: [["bold", "italic", "underline"],  ["link"], [{ list: "ordered" }, { list: "bullet" }]],
                id: this.populateWith.map(v => v.id),
                name: this.populateWith.map((v: SoftwarePartner) => v.name).valueOr(""),
                descriptionNL: this.populateWith.map(v => v.description.nl).valueOr(""),
                descriptionFR: this.populateWith.map(v => v.description.fr).valueOr(""),
                descriptionEN: this.populateWith.map(v => v.description.en).valueOr(""),
                introNL: this.populateWith.map(v => v.introNl).valueOr(''),
                introFR: this.populateWith.map(v => v.introFr).valueOr(''),
                introEN: this.populateWith.map(v => v.introEn).valueOr(''),
                isLegacy: this.populateWith.map(v => v.isLegacy).valueOr(false),
                ctaType: this.populateWith.map(v => v.ctaType).valueOr(CtaType.ACTIVATABLE),
                isCustom: this.populateWith.map(v => v.isCustom).valueOr(false),
                isProspect: this.populateWith.map(v => v.isProspect).valueOr(false),
                status: this.populateWith.map(v => v.status).valueOr(SoftwareStatus.DRAFT),
                externalLinkNL: this.populateWith.map(v => v.externalLinkTextNl).valueOr(''),
                externalLinkFR: this.populateWith.map(v => v.externalLinkTextFr).valueOr(''),
                externalLinkEN: this.populateWith.map(v => v.externalLinkTextEn).valueOr(''),
                partnerTextNL: this.populateWith.map(v => v.partnerTextNl).valueOr(''),
                partnerTextFR: this.populateWith.map(v => v.partnerTextFr).valueOr(''),
                partnerTextEN: this.populateWith.map(v => v.partnerTextEn).valueOr(''),
                onPremiseApp: {
                    file: null as File | null,
                    path: this.populateWith.map(v => v.appPath ? v.appPath : '').valueOr(''),
                    textNL: this.populateWith.map(v => v.appTextNl).valueOr(''),
                    textFR: this.populateWith.map(v => v.appTextFr).valueOr(''),
                    textEN: this.populateWith.map(v => v.appTextEn).valueOr('')
                },
                logo: {
                    file: null as File | null,
                    path: this.populateWith.map(v => v.logo).valueOr('')
                },
                logoRedesign: {
                    file: null as File | null,
                    path: this.populateWith.map(v => v.logoRedesign).valueOr('')
                },
                downloads: [] as SoftwarePartnerManual[],
                integratorCredentials: {
                    softwareId: this.populateWith.map(v => v.integratorCredentials.softwareId).valueOr(''),
                    secret: this.populateWith.map(v => v.integratorCredentials.secret).valueOr('')
                },
                tags: this.populateWith.map(v => v.keyWords).valueOr([]).map(v => ({
                    text: v
                })) as Tag[],
                distributor: this.populateWith.map(v => v.distributor).valueOr(''),
                distributorRedesign: this.populateWith.map(v => v.distributorRedesign).valueOr(''),
                tussenPersoon: this.populateWith.map(v => v.tussenPersoon).valueOrNull(),
                screenshots: this.populateWith.map(v => v.screenshots).valueOr([]).map(path => ({path: path, file: null})) as Screenshot[],
                newScreenshots: [] as Screenshot[],
                label: this.populateWith.map(v => v.label).valueOrNull(),
                hasBetalingservaring: this.populateWith.map(v => v.hasBetalingservaring).valueOr(false),
                hasFollowUp: this.populateWith.map(v => v.hasFollowUp).valueOr(false),
                price: this.populateWith.map(v => v.price).valueOr(0)
            }
        },
        computed: {
            isEdit(): boolean {
                return this.populateWith.hasValue();
            },
            hasEmptyCredentials(): boolean {
              return this.integratorCredentials.softwareId === '';  
            },
            allScreenshots(): Screenshot[] {
                return this.screenshots.concat(this.newScreenshots);
            }
        },
        methods: {
            changeTab(tab: Tab): void {
                this.currentTab = tab;
            },
            deleteScreenshot(screenshot: Screenshot) {
                const isRecentlyUploaded = !!screenshot.file;

                if(isRecentlyUploaded)
                    this.newScreenshots = this.newScreenshots.filter(screen => screen !== screenshot);
                else 
                    this.ajaxDeleteScreenshot(screenshot);
            },
            ajaxDeleteScreenshot(screenshot: Screenshot): void {
                Ajax.post(
                    '/ajax/cms/software-integrations/delete-screenshot',
                    {
                        softwareId: this.id.valueOrThrow(() => "Id needs to be present here."),
                        path: screenshot.path
                    },
                    response => {
                        this.screenshots = this.screenshots.filter(screen => screen !== screenshot);
                        Toast.success('Screenshot verwijderd.')
                    },
                    error => {
                        console.error(error);
                    }
                )
            },
            generateCredentials(): void {
                Ajax.post(
                    '/ajax/cms/software-integrations/create-credentials',
                    {
                        softwareId: this.id.valueOrThrow(() => "Id must be defined here")
                    },
                    (response: AjaxResponse<IntegratorCredentials>) => {
                        this.integratorCredentials = response.data;
                    },
                    error => {
                        console.error(error);
                    }
                );
            },
            async onPremiseAppUpload(uploadedFiles: File[]): Promise<void> {
                if(uploadedFiles.length === 0)
                    return;

                const file = uploadedFiles[0];
                this.onPremiseApp.path = await this.ajaxUploadAppFile(file);
                this.onPremiseApp.file = file;
            },
            ajaxUploadAppFile(file: File): Promise<string> {
                return new Promise((resolve, reject) => {
                    const formData = new FormData();
                    formData.append("softwareId", this.id.valueOrThrow(() => "Id must be defined here").toString());
                    formData.append("file", file);

                    Ajax.postWithBinary(
                        '/ajax/cms/software-integrations/upload-app',
                        formData,
                        (response: AjaxResponse<string>) => {
                            const uploadUrl = response.data;
                            resolve(uploadUrl);
                        },
                        error => {
                            reject(error);
                        }
                    )
                });
            },
            onLogoUpload(uploadedFiles: File[]) {
                if(uploadedFiles.length === 0)
                    return;

                const file = uploadedFiles[0];
                this.logo.path = URL.createObjectURL(file); //sets preview image
                this.logo.file = file;
            },
            onLogoRedesignUpload(uploadedFiles: File[]) {
                if(uploadedFiles.length === 0)
                    return;

                const file = uploadedFiles[0];
                this.logoRedesign.path = URL.createObjectURL(file); //sets preview image
                this.logoRedesign.file = file;
            },
            onScreenshotUpload(uploadedFiles: File[]) {
                this.newScreenshots = uploadedFiles.map(file => {
                    return {
                        path: URL.createObjectURL(file),
                        file: file
                    }
                });
            },
            async onSubmit() {
                const formData = new FormData();
                
                formData.append('name', this.name);
                formData.append('desc_nl', this.sanitizeHtml(this.descriptionNL));
                formData.append('desc_fr', this.sanitizeHtml(this.descriptionFR));
                formData.append('desc_en', this.sanitizeHtml(this.descriptionEN));
                formData.append('intro_nl', this.introNL);
                formData.append('intro_fr', this.introFR);
                formData.append('intro_en', this.introEN);
                formData.append('is_legacy', this.isLegacy.toString());
                formData.append('cta_type', this.ctaType);
                formData.append('is_custom', this.isCustom.toString());
                formData.append('is_prospect', this.isProspect.toString());
                formData.append('status', this.status);
                formData.append('external_link_text_nl', this.sanitizeHtml(this.externalLinkNL));
                formData.append('external_link_text_fr', this.sanitizeHtml(this.externalLinkFR));
                formData.append('external_link_text_en', this.sanitizeHtml(this.externalLinkEN));
                formData.append('partner_text_nl', this.sanitizeHtml(this.partnerTextNL));
                formData.append('partner_text_fr', this.sanitizeHtml(this.partnerTextFR));
                formData.append('partner_text_en', this.sanitizeHtml(this.partnerTextEN));
                formData.append('distributor', this.distributor.toString());
                formData.append('distributor_redesign', this.distributorRedesign.toString());
                formData.append('label', this.label ?? "");
                formData.append('key_words', this.tags.map(t => t.text).join(";"));
                formData.append('has_betalingservaring', this.hasBetalingservaring.toString());
                formData.append('has_followup', this.hasFollowUp.toString());
                formData.append('price', this.price.toString());

                
                if(this.logo.file)
                    formData.append('logo', this.logo.file);

                if(this.logoRedesign.file)
                    formData.append('logo_redesign', this.logoRedesign.file);

                this.newScreenshots.forEach(screenshot => {
                    formData.append('screenshots', screenshot.file!);
                });

                this.id.match(id_ => {
                    formData.append('id', id_.toString());
                    formData.append('app_text_nl', this.sanitizeHtml(this.onPremiseApp.textNL));
                    formData.append('app_text_fr', this.sanitizeHtml(this.onPremiseApp.textFR));
                    formData.append('app_text_en', this.sanitizeHtml(this.onPremiseApp.textEN));
                },
                () => {
                    formData.append('integrator_id', this.integratorCredentials.softwareId);
                });

                if(this.isEdit) {
                    const updateAllDownloads: Promise<void>[] = this.downloads.map(download => this.updateDownload(download));

                    await Promise.all(updateAllDownloads);
                }
                    
                const url:string = `/ajax/cms/software-integrations/${this.isEdit ? 'update' : 'add'}`;
                
                Ajax.postWithBinary(
                    url,
                    formData,
                    async (response: AjaxResponse<SoftwarePartner>) => {
                        const software = response.data;

                        if(!this.isEdit) {
                            await this.$router.push({ 
                                name: 'edit', 
                                params: { softwareId: software.id+"" } 
                            });
                        }

                        Toast.success('Opslaan gelukt.')
                    },
                    (err) => {
                        Toast.error(err);
                    }
                );
            },
            sanitizeHtml(html: string): string {
                const inlineStyles = /style="[^"]+"/ig;
                const unicodeChars = /<span class="ql-cursor">[^<\/span>]*<\/span>/ig;

                return html
                    .replace(inlineStyles, "")
                    .replace(unicodeChars, "");
            },
            deleteApp(): void {
                Ajax.post(
                    '/ajax/cms/software-integrations/delete-app',
                    {
                        softwareId: this.id.valueOrThrow(() => "Id needs to be present here.")
                    },
                    response => {
                        this.onPremiseApp = {
                            file: null,
                            path: '',
                            textNL: '',
                            textFR: '',
                            textEN: ''
                        };
                    },
                    error => {
                        console.error(error);
                    }
                )
            },
            createEmptyDownload(): void {
                Ajax.post(
                    '/ajax/cms/software-integrations/manuals/create',
                    {
                          softwareId: this.id.valueOrThrow(() => "Id needs to be present here.")
                    },
                    response => {
                        const downloadId: number = response.data;
                        const newDownload: SoftwarePartnerManual = {
                            id: downloadId,
                            softwareId: this.id.valueOrThrow(() => "Id needs to be present here."),
                            nameNl: '',
                            nameFr: '',
                            nameEn: '',
                            hasFileNl: false,
                            hasFileFr: false,
                            hasFileEn: false,
                            pathNl: '',
                            pathFr: '',
                            pathEn: ''
                        };

                        this.downloads.push(newDownload);
                    },
                    error => {
                        console.error(error);
                    }
                )
            },
            async getDownloads(softwareId: number): Promise<SoftwarePartnerManual[]> {
                return new Promise((resolve, reject) => {
                    Ajax.get(
                        '/ajax/cms/software-integrations/manuals',
                        {
                            softwareId: softwareId
                        },
                        response => {
                            resolve(response.data);    
                        },
                        error => {
                            reject(error);
                        }
                    )
                });
            },
            updateDownload(download: SoftwarePartnerManual): Promise<void> {
                return new Promise((resolve, reject) => {
                    Ajax.post(
                        '/ajax/cms/software-integrations/manuals/update',
                        {
                            downloadId: download.id,
                            nameNl: download.nameNl,
                            nameFr: download.nameFr,
                            nameEn: download.nameEn
                        },
                        () => {
                            resolve()
                        },
                        error => {
                            reject(error);
                        }
                    )
                });
            },
            deleteDownload(downloadId: number) {
                this.downloads = this.downloads.filter(download => download.id !== downloadId);
            }
        },
        async mounted(): Promise<void> {
            await this.id.matchSomeAsync(async id => {
                this.downloads = await this.getDownloads(id);
            });
        }
    });
</script>

<style lang="scss" scoped>
    .relative{
        min-height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            .delete-btn {
                opacity: 1;
            }
        }
    }

    .delete-btn {
        opacity: 0;
        position: absolute;
        top: 15px;
        right: 15px;

    }

    .small-editor .ql-editor {
        min-height: 40px;
    }
    .ti-tag {
        background-color: var(--primary) !important;
    }

    .vue-tags-input {
        width: 100%;
        max-width: initial !important;

        .ti-input {
            display: block;
            height: calc(2.1rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: 0.9rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
    }
</style>