
<form class="wrapper" @submit.prevent="onSubmit">
    <div class="top-view">
        <h2 class="font-weight-bold">{{translate('save_filter')}}</h2>
        <p>{{translate('save_filter_desc')}}</p>
    </div>
    <div class="main-view">
        <div>
            <label for="nameFilter">{{translate('filter_name')}}</label>
            <input type="text" class="form-control" id="nameFilter" v-model="title" />
        </div>
        <template v-if="$v.title.$invalid && $v.title.$dirty">
            <div class="card-box small my-2 warning" v-if="!$v.title.required">
                {{translate('validation_error_required', translate("title"))}}
            </div>
            <div class="card-box small my-2 warning" v-else-if="!$v.title.maxLength">
                {{translate('validation_error_max_length', translate("title"), 50)}}
            </div>
            <div class="card-box small my-2 warning" v-else-if="!$v.title.minLength">
                {{translate('validation_error_min_length', translate("title"), 3)}}
            </div>
        </template>
    </div>
    <div class="bottom-view">
        <a href="" class="btn btn-white" @click.prevent="close">{{translate('cancel')}}</a>
        <button class="btn btn-primary" type="submit">{{translate('save_filter')}}</button>
    </div>
</form>
