﻿import _ from 'lodash';
import Vue from 'vue';
import Ajax from '../lib/Ajax';
import Toast from '../lib/Toast';
import Translate from '../lib/Translate';
import Page from '../lib/Page';
import Validate from './directives/validate';

export default (function () {

    Page.registerVue('#helpCorner', function (rootSelector) {
        new Vue({
            el: rootSelector,
            data: {
                name: '',
                email: '',
                isLogging: false,
                cookieValue: ''
            },
            directives: {
                'validate': Validate
            },
            computed: {
                buttonLabel(): string {
                    return this.isLogging ? Translate('stop') : Translate('start');
                },
                cookiesAllowed(): boolean {
                    return this.cookieValue === "true";
                },
                isDisabled(): boolean {
                    return this.name === '' || this.email === '';
                },
                wimbLink(): string {
                    return 'https://www.whatismybrowser.com/?yourname=' + this.name +
                        '&youremail=' + this.email + '&recipientemail=info@companyweb.be#send-to-tech';
                },
                icon(): string {
                    return this.isLogging ? 'fa-stop' : 'fa-play';
                }
            },
            created(): void {
                this.writeCookie();
            },
            mounted(): void {
                this.cookieValue = this.getCookie();
            },
            methods: {
                wimbSubmit(): void {
                    const win = window.open(this.wimbLink, '_blank');
                    win?.focus();
                },
                writeCookie(): void {
                    let d = new Date();
                    d.setTime(d.getTime() + 60000);
                    const expires = "expires=" + d.toUTCString();
                    document.cookie = "cookiecheck=true;" + expires + ";path=/";
                },
                getCookie(): string {
                    const name = "cookiecheck=";
                    const decodedCookie = decodeURIComponent(document.cookie);
                    let ca = decodedCookie.split(';');
                    for (let i = 0; i < ca.length; i++) {
                        let c = ca[i].trim();
                        while (c.charAt(0) === ' ') {
                            c = c.substring(1);
                        }
                        if (c.indexOf(name) === 0) {
                            return c.substring(name.length, c.length);
                        }
                    }
                    return "";
                },
                startLogging(): void {
                    this.isLogging = false;

                    Ajax.get(
                        '/ajax/help/startlogging',
                        {},
                        (r) => {
                            this.isLogging = true;
                            Toast.success(Translate('user_logging_started'));
                        },
                        (err) => {
                            console.error(err);
                            this.isLogging = false;
                        }
                    );
                },
                stopLogging(): void {
                    Ajax.get(
                        '/ajax/help/stoplogging',
                        {},
                        () => {
                            this.isLogging = false;
                        },
                        (err) => {
                            console.error(err);
                            this.isLogging = false;
                        }
                    );
                },
                onSubmit(): void {
                    if (this.isLogging)
                        this.stopLogging();
                    else
                        this.startLogging();
                }
            }
        });
    });
}());
