import { AlertsPlusAccessLevel } from "../../types/dto/AlertsPlusAccessLevel";
import { InternationaalAccessLevel } from '../../types/dto/InternationaalAccessLevel';
import { DataMarketingAccessLevel } from '../../types/dto/DataMarketingAccessLevel';
export enum RadarAccessLevel {
    NONE = "none",
    FULL = "full",
}
export interface User {
    userId: number;
    name: string;
    email: string;
    lastActive: number;
    mobileInstalls: MobileInstalls[];
    alertsUsage: number;
    type: "pending" | "review-pending" | "active" | "deactivated";
    hasSuspiciousDomain: boolean;
    isAdmin: boolean;
    permissions:{
        hasVervaldagboek: boolean;
        alertsPlusAccessLevel: AlertsPlusAccessLevel;
        internationaalAccessLevel: InternationaalAccessLevel;
        dataMarketingAccessLevel: DataMarketingAccessLevel;
        radarAccessLevel: RadarAccessLevel;
    }
}

export interface MobileInstalls {
    id: string;
    name: string;
    hits: number;
    isActive: boolean;
    canBeRemoved: boolean;
    isOldMobileAccount: boolean;
}

export interface EditablePermissions {
    vervaldagboek: boolean;
    alertsPlus: boolean;
    internationaal: boolean;
    addUsers: boolean;
    seeShareLink: boolean;
    dataMarketing: boolean;
    radar: boolean;
}

export interface InviteUserResult {
    userId: number | null;
    notInvitedReason: string | null;
    userInvited: boolean;
}

export interface Company {
    vat: number;
    name: string;
}