
<div class="select-menu-wrapper">
    <a class="label-wrapper" href="" @click.prevent="toggle">
        <span class="text">
            <div class="text-small text-grey">{{label}}</div>
             <span class="font-500">{{ activeOption.title }}</span>
        </span>
        <span class="chevron">
            <i class="far fa-chevron-down"></i>
        </span>
    </a>
    <template v-if="!linkToUrl">
        <ul v-if="isOpen" class="select-menu">
            <li v-for="option in options" :class="{'active': option === activeOption}" @click="select(option)">
                <div>
                    <div class="select-title">
                        <span>{{ option.title }}</span>
                        <i v-if="option === activeOption" class="far fa-check"></i>
                    </div>
                    <div class="select-desc">{{ option.description }}</div>
                </div>
            </li>
        </ul>
    </template>
    <template v-else>
        <div v-if="isOpen" class="select-menu">
            <a :href="option.url" v-for="option in options" :class="{'active': option === activeOption}">
                <div class="select-title">
                    <span>{{ option.title }}</span>
                    <i v-if="option.id == activeOption.id" class="far fa-check"></i>
                </div>
            </a>
        </div>
    </template>
</div>
