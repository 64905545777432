﻿import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css'

export enum MessageType {
    SUCCESS = "success",
    INFO = "info",
    WARNING = "warning",
    ERROR = "error"
}

function showMessage(message: string, type: MessageType, disappearAfterSeconds?: number): void {
    const disappearAfterMiliSeconds = disappearAfterSeconds ? disappearAfterSeconds * 1000 : 0;

    Swal.fire({
        title: message,
        toast: true,
        type: type,
        position: 'bottom-end',
        showConfirmButton: false,
        showCloseButton: true,
        timer: disappearAfterMiliSeconds,
        customClass: {
            container: 'd-print-none toast-' + type,
            popup: 'toast-popup',
            header: 'toast-header',
            title: 'toast-title',
            closeButton: 'toast-close',
            image: 'toast-image',
            content: 'toast-content',
            input: 'toast-input',
            actions: 'toast-actions',
            confirmButton: 'toast-confirm-button',
            cancelButton: 'toast-cancel-button',
            footer: 'toast-footer'
        },
        onOpen: (toast: HTMLElement): void => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
}

export default {
    success: (message: string) => {
        showMessage(message, MessageType.SUCCESS, 5);
    },
    info: (message: string) => {
        showMessage(message, MessageType.INFO, 5);
    },
    warning: (message: string) => {
        showMessage(message, MessageType.WARNING);
    },
    error: (message: string) => {
        showMessage(message, MessageType.ERROR);
    },
    message: (message: string, type: MessageType, disappearAfterSeconds?: number) => {
        showMessage(message, type, disappearAfterSeconds);
    }
};

