﻿import { Translation } from './Translation';
import { Adres } from './Adres';
import { Language } from './Language';

export interface SoftwarePartner { 
    id: number;
    name: string;
    description: Translation;
    introNl: string;
    introFr: string;
    introEn: string;
    ctaType: CtaType;
    isLegacy: boolean;
    isCustom: boolean;
    isProspect: boolean;
    status: SoftwareStatus;
    externalLinkTextNl: string;
    externalLinkTextFr: string;
    externalLinkTextEn: string;
    partnerTextNl: string;
    partnerTextFr: string;
    partnerTextEn: string;
    appName: string | null;
    appTextNl: string | null;
    appTextFr: string | null;
    appTextEn: string | null;
    integratorCredentials: IntegratorCredentials;
    distributor: string | null;
    distributorRedesign: string | null;
    keyWords: string[];
    amountOfUsers: number | null;
    tussenPersoon: TussenPersoon | null;
    sortIndex: number;
    screenshots: string[];
    logo: string | null;
    logoRedesign: string | null;
    label: SoftwareLabel | null;
    hasBetalingservaring: boolean;
    hasFollowUp: boolean;
    price: number;
    assetDirectory: string;
    appPath: string | null;
    screenshotsDirectory: string;
}

export interface SoftwarePartnerManual { 
    id: number;
    nameNl: string;
    nameFr: string;
    nameEn: string;
    softwareId: number;
    hasFileNl: boolean;
    hasFileFr: boolean;
    hasFileEn: boolean;
    pathNl: string;
    pathFr: string;
    pathEn: string;
}

export interface TussenPersoon { 
    id: number;
    address: Adres;
    email: string | null;
    contactName: string;
    companyVat: string;
    companyName: string;
    language: Language;
    phone: string | null;
    mobile: string | null;
    website: string | null;
    commission: number | null;
}

export interface IntegratorCredentials { 
    softwareId: string;
    secret: string;
    isDemo: boolean;
}

export enum SoftwareStatus { 
    DRAFT = "draft",
    PUBLISHED = "published",
}

export enum SoftwareLabel { 
    COMINGSOON = "coming-soon",
    RECENT = "recent",
    UPDATED = "updated",
}

export enum CtaType {
    CONTACTUS = "contact-us",
    ACTIVATABLE = "activatable",
    NOCTA = "no-cta",
}