﻿<template>
    <div class="suggestion-selector" :class="{'selected': isSelected}" v-on:click="accept" v-html="highlightedText"></div>
</template>

<script>
    export default {
        name: 'autocomplete-suggestion',
        props: {
            name: '',
            searchValue: '',
            index: Number,
            selectedIndex: Number
        },
        computed: {
            highlightedText: function () {
                var startOfMatch = normalizeSearchText(this.name).indexOf(normalizeSearchText(this.searchValue));

                if (startOfMatch === -1)
                    return this.name;

                var endOfMatch = startOfMatch + this.searchValue.length;

                return this.name.substring(0, startOfMatch) + '<span class="highlight">' + this.name.substring(startOfMatch, endOfMatch) + '</span>' + this.name.substring(endOfMatch);
            },
            isSelected: function () {
                return this.index === this.selectedIndex;
            }
        },
        methods: {
            accept: function () {
                this.$parent.$emit('acceptSuggestion', this.index);
            }
        }
    };

    function normalizeSearchText(text)
    {
        return (text||'').toLowerCase().replaceAll('é', 'e').replaceAll('è', 'e').replaceAll('â', 'a')
            .replaceAll('ê', 'e').replaceAll('ë', 'e').replaceAll('ç', 'c')
            .replaceAll('ï', 'i').replaceAll('ö', 'o').replaceAll('ô', 'o')
            .replaceAll('œ', 'oe').trim();
    }
</script>