﻿import _ from 'lodash';
import Vue from 'vue';
import Page from 'Lib/Page';
import SearchBox from './box.vue';

export default (function () {
    Page.registerVue('#search', function (rootSelector) {
        new Vue({
            el: rootSelector,
            components: {
                'search-box': SearchBox
            },
            data: {
                showAnnouncement: window.cw.showAnnouncement,
                canSearchOnManager: window.cw.canSearchOnManager,
                isInstituutUser: window.cw.isInstituutUser,
                canSearchOnAddress: window.cw.canSearchOnAddress,
                isStandardUser: window.cw.isStandardUser,
                isBasicUser: window.cw.isBasicUser,
                isTrialUser: window.cw.isTrialUser,
                upgradeSearchOnManagerLink: window.cw.upgradeSearchOnManagerLink,
                gtm: {
                    company: window.cw.gtmCompany,
                    person: window.cw.gtmPerson,
                    address: window.cw.gtmAddress
                }
            }
        });
    });
}());
