
<div class="cw-dropdown"id="cw-dropdown">
    <div class="cw-dropdown__label" @click="toggleShowList()">{{label}}</div>
    <ul class="cw-dropdown__list" :class="{'active': showList}">
        <li v-for="user in value">
            <input class="form-check-input" :id="user.id" type="checkbox" v-model="user.isFollower" @change="emitUsers()" />
            <label :for="user.id">{{user.name}} &lt;{{user.email}}&gt;</label>
        </li>
    </ul>
</div>
