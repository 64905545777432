import Vue from 'vue';
import Page from '../../lib/Page';
import Baro from './barometer.vue';

export default (function () {
    Page.registerVue('#barometerWrapper', function (rootSelector) {
        new Vue({
            name: 'barometer-wrapper',
            el: rootSelector,
            components: { Baro }
        });
    });
}());

