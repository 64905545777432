
<div class="cookie">
    <label class="switch d-block" :class="{'switch-locked': required}" :for="name">
        <input type="checkbox" :id="name" class="form-check-input" v-model="isChecked" v-if="!required">
        <span class="slider"></span>
        <div class="switch-label">
            <div class="row">
                <div class="col-10">
                    <h3 data-nosnippet>{{label}}</h3>
                </div>
                <div class="col-2 text-right">
                    <span class="d-md-none d-block"><i class="fas fa-info-circle" v-on:click.prevent.stop="toggleDescription()"></i></span>
                </div>
            </div>
            <p class="d-none d-md-block" :class="{'d-block': showDescMobile}" data-nosnippet>
                {{description}}
            </p>
        </div>
    </label>
</div>
