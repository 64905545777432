
<div class="wrapper">
    <div class="top-view">
        <div class="row title-row">
            <div class="col-lg-4"><h2 class="font-weight-bold mb-0">{{translate('filters')}}</h2></div>
            <div class="col-lg-8 my-auto">
                <div class="inline-form">
                    <dropdown>
                        <template v-slot:toggler>{{store.savedFilters.length}} {{translate('saved_filter_s')}}</template>
                        <template v-slot:content>
                            <li @click="applyFilter(filter.criteria)" v-for="filter in store.savedFilters" class="d-flex justify-content-between">
                                <span>{{filter.title}}</span>
                                <a class="btn btn-sm btn-default ml-2" href="" @click.prevent.stop="deleteFilter(filter)" :title="translate('delete_filter')">
                                    <i class="fas fa-trash-alt"></i>
                                </a>
                            </li>
                            <div v-if="store.savedFilters.length === 0"></div>
                        </template>
                    </dropdown>
                </div>
            </div>
        </div>
    </div>
    <div class="main-view">
        <div class="filter-list">
            <h3 style="margin-left: 30px;">{{translate('company_information')}}</h3>

            <a href="" @click.prevent="openFilterDetail('companyname')"  class="filter-list-item">
                <div>
                    <h3 class="font-weight-normal mb-0">{{ translate('companyname') }}</h3>
                </div>
                <div class="d-flex align-items-center" style="gap: 20px">
                    <span v-if="store.companyNameFilter !== ''" class="badge badge-pill ml-1">{{store.companyNameFilter}}</span>
                    <i class="fas fa-chevron-right text-grey"></i>
                </div>
            </a>

            <a href="" @click.prevent="openFilterDetail('street')"  class="filter-list-item">
                <div>
                    <h3 class="font-weight-normal mb-0">{{ translate('street') }}</h3>
                </div>
                <div class="d-flex align-items-center" style="gap: 20px">
                    <span v-if="store.streetFilters.length > 0" class="badge badge-pill ml-1">{{store.streetFilters.length}}</span>
                    <i class="fas fa-chevron-right text-grey"></i>
                </div>
            </a>

            <a href="" @click.prevent="openFilterDetail('form')"  class="filter-list-item">
                <div>
                    <h3 class="font-weight-normal mb-0">{{ translate('form') }}</h3>
                </div>
                <div class="d-flex align-items-center" style="gap: 20px">
                    <span v-if="store.formFilters.length > 0" class="badge badge-pill ml-1">{{store.formFilters.length}}</span>
                    <i class="fas fa-chevron-right text-grey"></i>
                </div>
            </a>
            
            <a href="" v-for="filter in companyFilters" class="filter-list-item" @click.prevent="openFilterDetail(filter.title)">
                <div>
                    <h3 class="font-weight-normal mb-0">{{ translate(filter.title) }}</h3>
                </div>
                <div class="d-flex align-items-center" style="gap: 20px">
                    <span v-if="filter.filterCriteria.hasValue" class="badge badge-pill ml-1"><span v-html="filter.filterCriteria.toHtml()"></span></span>
                    <i class="fas fa-chevron-right text-grey"></i>
                </div>
            </a>

            <h3 class="mt-5" style="margin-left: 30px;">{{translate('scores_and_indicators')}}</h3>
            <a href="" v-for="filter in scoreFilters" class="filter-list-item" @click.prevent="openFilterDetail(filter.title)">
                <div>
                    <h3 class="font-weight-normal mb-0">{{ translate(filter.title) }}</h3>
                </div>
                <div class="d-flex align-items-center" style="gap: 20px">
                    <span v-if="filter.filterCriteria.hasValue" class="badge badge-pill ml-1"><span v-html="filter.filterCriteria.toHtml()"></span></span>
                    <i class="fas fa-chevron-right text-grey"></i>
                </div>
            </a>

            <h3 class="mt-5" style="margin-left: 30px;" v-if="store.customFields.length > 0">{{translate('custom_fields')}}</h3>
            <a href="" v-for="customField in store.customFields" class="filter-list-item" @click.prevent="openFilterDetail(customField)">
                <div>
                    <h4 class="font-weight-normal mb-0">{{ customField.name }}</h4>
                </div>
                <div class="d-flex align-items-center" style="gap: 20px">
                    <span v-if="hasSpecificCustomFilter(customField)" class="badge badge-pill ml-1"><span v-html="store.customFilters[customField.id]"></span></span>
                    <i class="fas fa-chevron-right text-grey"></i>
                </div>
            </a>
        </div>
    </div>
    <div class="bottom-view">
        <a href="" class="btn btn-white" @click.prevent="close">{{translate('cancel')}}</a>
        <div class="font-italic text-grey text-center"><div class="font-weight-bold text-body">{{store.allFilteredCompanies.length}}</div> {{translate('x_selected_companies')}}</div>
        <button class="btn btn-default" :disabled="!store.hasAnyFilter" @click.prevent="store.openDrawer(DrawerView.FILTER_SAVE)">{{translate('save_filter')}}</button>
    </div>
</div>
