﻿import Vue from 'vue';
import Page from '../lib/Page';
import AjaxButton from './shared/ajax-button.vue';

export default (function () {
    Page.registerVue('[ajax-button-wrapper]', (rootSelector) => {
        new Vue({
            el: rootSelector,
            components: { 
                AjaxButton
            }
        });
    });
}());
