
<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>{{translate('international_search')}}</h1>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12">
                <international-search :countries="countries"></international-search>
            </div>
<!--            <div class="col-md-5" v-if="hasFollowup">-->
<!--                <followup-box :hasFollowup="hasFollowup"></followup-box>-->
<!--            </div>-->
        </div>

        <div class="row mt-5" v-if="companies.length > 0">
            <div class="col-12">
                <companies :countries="countries" :companies="companies" :hasFollowup="hasFollowup"></companies>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import InternationalSearch from '../components/search.vue';
    import FollowupBox from '../components/followup-box.vue';
    import Companies from '../components/companies.vue';
    import { Country, Company } from '../types';
    import Translate from '../../mixins/translate';


    export default Translate.extend({
        name: 'international-dashboard',
        components: { InternationalSearch, FollowupBox, Companies },
        data() {
            return {
                countries: window.cw.countries as Country[],
                companies: window.cw.companies as Company[],
                hasFollowup: window.cw.hasAccessToFollowup as boolean
            }
        }
    });
</script>

<style scoped lang="scss">

</style>