
<div>
    <div :style="{left: trackerPosition + 'px'}" class="tracker d-print-none"></div>
    <div class="years">
        <div v-for="year in years" class="year">
            <div class="label">{{year}}</div>
        </div>
    </div>

    <div class="category">
        <h3>{{translate('algemeen')}}</h3>
        <cw-track :label="translate('mijlpalen')" :events="mijlpalen" :timeline-start="timelineStart" :timeline-end="timelineEnd"></cw-track>

        <template v-if="!hasMultipleNameLanguages">
            <cw-track :label="translate('benaming')" :periods="names" :timeline-start="timelineStart" :timeline-end="timelineEnd"></cw-track>
        </template>
        <template v-else>
            <cw-track v-for="(namesByLanguage, lang) in languages" :key="'name'+lang" :label="translate('benaming') + ' ' + lang.toUpperCase()" :periods="namesByLanguage" :timeline-start="timelineStart" :timeline-end="timelineEnd"></cw-track>
        </template>

        <cw-track :label="translate('maatschappelijke_zetel')" :periods="addresses" :timeline-start="timelineStart" :timeline-end="timelineEnd"></cw-track>
    </div>
    <div class="category">
        <h3>{{translate('juridisch')}}</h3>
        <cw-track :label="translate('rsz-dagvaarding')" :events="dagvaardingen" :timeline-start="timelineStart" :timeline-end="timelineEnd"></cw-track>
        <cw-track :label="translate('inhoudingsplicht')" :periods="inhoudingsplicht" :timeline-start="timelineStart" :timeline-end="timelineEnd" forced-color="#F44336"></cw-track>
        <cw-track :label="translate('gerechtelijkakkoord')" :periods="gerechtelijkakkoord" :timeline-start="timelineStart" :timeline-end="timelineEnd" forced-color="#F44336"></cw-track>
    </div>
    <div class="category">
        <h3>{{translate('mandaathouders')}}</h3>
        <cw-track v-for="(mandateHolder, i) in mandateHolders"
                  :key="i"
                  :label="mandateHolder.name"
                  :type="mandateHolder.type"
                  :link="mandateHolder.link"
                  :periods="mandateHolder.mandates"
                  :timeline-start="timelineStart" 
                  :timeline-end="timelineEnd">
        </cw-track>
    </div>
</div>
