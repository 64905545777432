﻿<template>
    <div class="barometer-icon squared" :class="className" :score="score.valueOr(0)">
        <span v-if="!!score">{{scoreFormatted}}</span>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import {parseInt} from 'lodash';
    import Option from '../../types/global/option';

    export default Vue.extend({
        name: 'barometer-icon',
        props: {
            className: {
                type: String,
                required: true
            }
        },
        computed: {
            scoreFormatted(): string {
                return this.score
                    .map(s => s
                        .toFixed(1)
                        .replace('.', ','))
                    .valueOr("");
            },
            score(): Option<number> {
                if(this.className === "zero")
                    return Option.some(0);
                
                return Option
                    .someNotNull(this.className.match(/^([^-]+)-?(\d{2})$/))
                        .filter(c => c.length > 1)
                        .map(c => parseInt(c[2]) * (c[1] === "neg" ? -1 : 1))
                        .map(c => (c / 10));
            }
        }
    });
</script>

<style scoped lang="scss">
    .squared {
        border-radius: 3px;
        height: unset !important;
        width: auto !important;
        min-width: 32px;
        padding: 2px 6px;
        line-height: 1rem;
        font-size: 0.8rem;
      &:after {
        line-height: 1rem;
        font-size: 0.7rem;
      }
    }
</style>