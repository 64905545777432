
<form class="wrapper" @submit.prevent="onSubmit">
    <div class="top-view">
        <div class="mb-3">
            <a href="" class="btn btn-sm btn-default" @click.prevent="backToList">
                <i class="fas fa-arrow-left mr-"></i> {{translate('all_filters')}}
            </a>
        </div>
        <div class="row">
            <div class="col-md-12"><h1 class="font-weight-bold">{{translate('add_column')}}</h1></div>
        </div>

        <div class="mr-5 pr-5 mt-4">
            <div class="d-flex space-x-6 mb-4">
                <div>
                    <input class="azure" id="boolean" type="radio" v-model="type" value="string">
                    <label for="boolean">{{ translate('text') }}</label>
                </div>
                <div>
                    <input  class="azure" id="string" type="radio" v-model="type" value="boolean">
                    <label for="string">{{ translate('checkbox') }}</label>
                </div>
            </div>

            <div class="mb-4">
                <h4 class="mb-2">{{ translate('title') }}</h4>
                <input id="title" type="text" v-model="title" class="form-control">
                <template v-if="$v.title.$invalid && $v.title.$dirty">
                    <div class="card-box small my-2 warning" v-if="!$v.title.required">
                        {{ translate('validation_error_required', translate("title")) }}
                    </div>
                    <div class="card-box small my-2 warning" v-else-if="!$v.title.maxLength">
                        {{ translate('validation_error_max_length', translate("title"), 20) }}
                    </div>
                    <div class="card-box small my-2 warning" v-else-if="!$v.title.minLength">
                        {{ translate('validation_error_min_length', translate("title"), 3) }}
                    </div>
                </template>
            </div>
            <div class="mb-4">
                <h4 class="mb-2">{{ translate('description') }}</h4>
                <textarea v-model="description" class="form-control" rows="4"></textarea>
            </div>
            <div class="mb-4">
                <h4 class="mb-2">{{ translate('width') }}</h4>
                <input v-model="minWidth" class="form-control" type="number" min="50" max="250"/>
                <div class="mt-2 text-grey small">min 50px - max 250px</div>
            </div>
        </div>
    </div>
    <div class="bottom-view">
        <a href="" class="btn btn-white" @click.prevent="close">{{translate('cancel')}}</a>
        <button class="btn btn-primary" type="submit">{{translate('add_column')}}</button>
    </div>
</form>
