﻿﻿<template>
    <modal :title="translate('edit_column')" id="editCustomColumnModal" size="md">
        <form @submit.prevent="onSubmit">
            <div class="mb-4">
                <h4 class="mb-2">{{translate('type')}}</h4>
                <div class="d-flex space-x-6">
                    <div>
                        <input id="boolean" type="radio" class="azure" v-model="type" value="string">
                        <label for="boolean">{{translate('text')}}</label>
                    </div>
                    <div>
                        <input id="string" type="radio" class="azure" v-model="type" value="boolean">
                        <label for="string">{{translate('checkbox')}}</label>
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <h4 class="mb-2">{{translate('title')}}</h4>
                <input id="title" type="text" v-model="title" class="form-control">
                <template v-if="$v.title.$invalid && $v.title.$dirty">
                    <div class="card-box small my-2 warning" v-if="!$v.title.required">
                        {{translate('validation_error_required', translate("title"))}}
                    </div>
                    <div class="card-box small my-2 warning" v-else-if="!$v.title.maxLength">
                        {{translate('validation_error_max_length', translate("title"), 20)}}
                    </div>
                    <div class="card-box small my-2 warning" v-else-if="!$v.title.minLength">
                        {{translate('validation_error_min_length', translate("title"), 3)}}
                    </div>
                </template>
            </div>
            <div class="mb-4">
                <h4 class="mb-2">{{translate('description')}}</h4>
                <textarea v-model="description" class="form-control" rows="4"></textarea>
            </div>
            <div class="mb-4">
                <h4 class="mb-2">{{translate('width')}}</h4>
                <input v-model="minWidth" class="form-control" type="number" min="50" max="350" />
            </div>
            <div class="row">
                <div class="col-md-7">
                    <button type="submit" class="btn btn-primary btn-200">
                        <i class="fas fa-save mr-1"></i>
                        {{translate('btn_save')}}
                    </button>
                </div>
                <div class="col-md-5 mt-auto text-right">
                    <a href="" class="text-grey link" @click.stop.prevent="tryDeleteColumn">{{translate('delete_column')}}</a>
                </div>
            </div>
        </form>
    </modal>
</template>

<script lang="ts">
import Modal from '../../../shared/modal.vue';
import Ajax from '../../../../lib/Ajax'
import Translate from '../../../mixins/translate';
//@ts-ignore
import {maxLength, minLength, required} from 'vuelidate/dist/validators.min';
import Confirmation from '../../../../lib/Confirmation';
import Toast from '../../../../lib/Toast';
import {CustomField} from '../../types';
import {useStore} from '../../store';

export default Translate.extend({
    name: "edit-custom-column",
    components: {Modal},
    props: {
        field: {
            type: Object as () => CustomField | null
        }
    },
    data() {
        return {
            store: useStore(),
            fieldId: this.field?.id ?? 0,
            title: this.field?.name ?? '',
            description: this.field?.description ?? '',
            type: this.field?.type ?? 'string',
            minWidth: this.field?.minWidth ?? 50
        }
    },
    watch: {
        field(f: CustomField) {
            if(!f)
                return;

            this.fieldId = f.id;
            this.title = f.name;
            this.description = f.description;
            this.type = f.type;
            this.minWidth = f.minWidth;
        }
    },
    validations: {
        title: {
            required: required,
            minLength: minLength(3),
            maxLength: maxLength(20)
        },
        description:{
            minLength: minLength(3),
            maxLength: maxLength(250)
        }
    },
    methods: {
        async onSubmit(): Promise<void> {
            this.$v.$touch();
            if(this.$v.$anyError)
                return;

            await Ajax.postAsync("/ajax/radar/edit-column", {
                fieldId: this.fieldId,
                type: this.type,
                title: this.title,
                description: this.description,
                minWidth: this.minWidth < 50 ? 50 : this.minWidth
            });

            this.$root.$emit("hide-modal", "editCustomColumnModal");
            Toast.success(this.translate('column_edited'));

            const cf = this.store.customFields.find(cf => cf.id == this.fieldId)!;
            cf.name = this.title;
            cf.description = this.description;
            cf.minWidth = this.minWidth;
            cf.type = this.type;

            this.$v.$reset();
        },
        tryDeleteColumn(): void {
            Confirmation(
                async () => {
                    await Ajax.postAsync("/ajax/radar/delete-column", {
                        fieldId: this.fieldId
                    });

                    window.location.reload();
                },
                {
                    title: this.translate('delete_column_confirm', this.title),
                    body: this.translate('no_redo')
                }
            )
        }
    },
});
</script>

<style scoped>

</style>