﻿<template>
    <tr role="row" class="pointer">
        <td :class="{'faded': hasBeenRemoved}">
            <div class="flex-v-center">
                <a :href="Link" class="tr-anchor">
                    {{VolledigeNaam.Lastname}} {{VolledigeNaam.Firstname}}
                </a>
                <a :href="Link" target="_blank" class="btn btn-transparent btn-sm d-inline ml-1" :title="translate('open_in_new_tab')">
                    <i class="fas fa-external-link-alt"></i>
                </a>
            </div>
        </td>
        <td :class="{'faded': hasBeenRemoved}" class="d-none d-lg-table-cell">
            {{Adres.Lijn1}}, {{Adres.Lijn2}}
        </td>
        <template v-if="!cantRemove">
            <td class="remove-person-button excluded-from-click text-center"
                :title="translate('remove_person')"
                v-on:click="removePerson()"
                width="50"
                v-if="!hasBeenRemoved">
                <i class="fas fa-trash-alt excluded-from-click"></i>
            </td>
            <td class="excluded-from-click text-center"
                :title="translate('restore_person')"
                v-on:click="addPerson()"
                width="50"
                v-else>
                <i class="fas fa-undo excluded-from-click"></i>
            </td>
        </template>
    </tr>
</template>

<script>
    import Translate from 'Mixins/translate';
    import Truncate from 'Mixins/truncate';
    import Ajax from 'Lib/Ajax';
    import Toast from 'Lib/Toast';

    export default {
        name: 'person-row',
        props: {
            Adres: {
                Lijn1: String,
                Lijn2: String
            },
            Link: String,
            VolledigeNaam: Object,
            Id: Number,
            isRemoved: Boolean,
            cantRemove: Boolean
        },
        mixins: [Truncate, Translate],
        data() {
            return {
                hasBeenRemoved: this.isRemoved
            }
        },
        methods: {
            removePerson() {
                Ajax.post(
                    '/ajax/followup/remove-person',
                    {
                        listId: this.$parent.currentList.ListId,
                        personId: this.Id
                    },
                    () => {
                        Toast.success(this.translate('person_removed'));
                        this.$root.$emit('change-used-persons');
                        this.hasBeenRemoved = true;
                        this.$root.$emit('removePerson', { id: this.Id, isReAdded: false } );
                    },
                    (err) => {
                        console.error(err);
                    }
                )
            },
            addPerson() {
                Ajax.post(
                    '/ajax/followup/add-person',
                    {
                        listId: this.$parent.currentList.ListId,
                        personId: this.Id
                    },
                    () => {
                        this.$root.$emit('change-used-persons');
                        Toast.success(this.translate('person_restored'));
                        this.hasBeenRemoved = false;
                        this.$root.$emit('removePerson', { id: this.Id, isReAdded: true } );
                    },
                    (err) => {
                        console.error(err);
                    }
                );
            }
        }
    }
</script>

<style>
    td.faded {
        opacity: 0.2;
    }

    .remove-person-button:hover {
        background: #F44336;
        color: white !important;
    }
</style>