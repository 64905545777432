﻿<template>
    <div class="dashboard-grid">
        <div v-if="kpiData" class="tile tile-1">
            <h2>Recent Sales</h2>
            <div class="vert-grid">
                <div v-for="(sale, index) in kpiData.recentSales" :class="{'border-bottom': index < kpiData.recentSales.length - 1}" class="py-2 px-2 position-relative">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="font-small">{{ Date.fromISOString(sale.creationDate).formatDateTime() }}</div>
                            <b class="accent-color">{{ sale.companyName|truncate(30) }}</b>
                            <div class="font-small" v-if="sale.aanbrenger !== 'Website'">Sold by <b>{{ sale.aanbrenger }}</b></div>
                        </div>
                        <div class="text-right">
                            <div class="font-big font-weight-bold mb-1">€ {{sale.price.formatThousandSeparator()}}</div>
                            <div :class="getBadgeColor(sale.factuurType)" class="badge badge-small">{{ sale.factuurType }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="kpiData" class="tile tile-2">
            <h2>Upcoming Webmeetings</h2>
            <div class="vert-grid">
                <div v-for="(demo, index) in kpiData.upcomingWebmeetings" :class="{'border-bottom': index < kpiData.upcomingWebmeetings.length - 1}" class="py-2 px-2 position-relative">
                    <div class="font-small">{{ Date.fromISOString(demo.date).formatDateTime() }}</div>
                    <b class="accent-color">{{ demo.companyName|truncate(35) }}</b>
                    <div class="font-small">{{ demo.salesOperator }}</div>
                </div>
            </div>
        </div>
        
        <div class="tile tile-3">
            <h2>Sales revenue</h2>
            <div>
                <canvas id="salesCanvas" ref="salesCanvas" class="pb-3"></canvas>
            </div>
        </div>

      
        <div class="tile tile-4">
            <h2>Planner KPI's</h2>
            <div>
                <canvas id="plannerCanvas" ref="plannerCanvas"></canvas>
            </div>
        </div>

        <div v-if="kpiData" class="tile tile-5 tile-centered">
            <h2> 1.200.000 EUR revenue target</h2>
            <div class="big-text" :class="{'green': kpiData.annualTurnoverProgression >= 100, 'red': kpiData.annualTurnoverProgression < 100}">
                {{kpiData.annualTurnoverProgression}} %
            </div>

            <div v-if="isManager" class="progression-form">
                <form v-on:submit.prevent="saveProgression">
                    <input type="number" v-model.number="newProgression" class="form-control">
                </form>
            </div>
        </div>
        <div class="tile tile-6" v-if="kpiData">
            <h2>Datateam</h2>
            <div class="vert-grid">
                <div class="d-flex justify-content-between">
                    <div class="mandates-title">Active</div>
                    <div class="mandates-data green">{{kpiData.mandatesKpi.find(x => x.active === true).filledWithBirthDate.formatThousandSeparator()}} / {{kpiData.mandatesKpi.find(x => x.active == true).total.formatThousandSeparator()}}</div>
                    <div class="mandates-percent">
                        {{(kpiData.mandatesKpi.find(x => x.active == true).filledWithBirthDate/kpiData.mandatesKpi.find(x => x.active == true).total * 100).toFixed(2)}} %
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="mandates-title">Inactive</div>
                    <div class="mandates-data red">{{kpiData.mandatesKpi.find(x => x.active === false).filledWithBirthDate.formatThousandSeparator()}} / {{kpiData.mandatesKpi.find(x => x.active == false).total.formatThousandSeparator()}}</div>
                    <div class="mandates-percent">
                        {{(kpiData.mandatesKpi.find(x => x.active == false).filledWithBirthDate/kpiData.mandatesKpi.find(x => x.active == false).total * 100).toFixed(2)}} %
                    </div>
                </div>
            </div>
            <div class="mt-5">
                <canvas id="dagverwerkingCanvas" ref="dagverwerkingCanvas"></canvas>
            </div>
        </div>
        <div class="text-center font-small position-absolute bottom-0">Last update: {{lastUpdate.formatDateTime()}}</div>
    </div>
</template>

<script lang="ts">
import Ajax from '../../lib/Ajax';
import Truncate from '../../vue/mixins/truncate';
import mixins from 'vue-typed-mixins';
import {ChartType, CWChartData} from '../../behaviors/Chart';
import {KpiData, SalesKpi, CallTime, PaymentExperienceSummary, Operator} from './types';
import Chart from 'chart.js';
import _, { unset } from 'lodash';

enum Color {
    GREEN = '#7de18c',
    BLUE = '#73a6d7',
    INDIGO = '#6c2f86',
    ORANGE = '#ff8b62',
    LIGHTGREY = '#cdcdcd',
    YELLOW = '#ffe162',
    WHITE = '#fff'
}

export default mixins(Truncate).extend({
    name: 'wrapper',
    data() {
        return {
            kpiData: null as KpiData | null,
            lastUpdate: new Date(),
            callTimes: [] as CallTime[],
            isManager: window.cw.isManager,
            newProgression: 15
        };
    },
    computed: {
        salesOperators(): Operator[] {
            return (this.kpiData?.operators ?? [])
                .filter(p => p.group === "sales" || p.login === "anissa") //anissa is group kpi maar verkoopt blijkbaar ook nu.
                .filter(p => p.firstName !== "Saleh")
                .sort((a,b) => b.id - a.id);
        },
        planners(): Operator[] {
            return (this.kpiData?.operators ?? [])
                .filter(p => p.group === "planner")
        }
    },
    methods: {
        async saveProgression(): Promise<void> {
            await Ajax.postAsync('/ajax/kpi/setProgression', {
                progression:this.newProgression
            });

            if(this.kpiData)
                this.kpiData.annualTurnoverProgression = this.newProgression;
        },
        async fetchData(): Promise<void> {
            this.kpiData = await Ajax.getAsync<KpiData>('/ajax/kpi');

            this.newProgression = this.kpiData.annualTurnoverProgression;


            await this.fetchCallTimes();
            await this.drawSalesChart();
            this.drawPlannerChart();
            this.drawDagverwerkingChart();
            this.lastUpdate = new Date();
        },
        async fetchCallTimes() {
            const now = new Date();

            const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
            const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

            const startDate = firstDay.toHansDate();
            const endDate = lastDay.toHansDate();

            const extensions: number[] = (this.kpiData?.operators ?? []).map(o => o.extension);

            const tasks = extensions.map(ext => Ajax.getAsync<CallTime>('/ajax/kpi/getCalltimes?ext='+ext, {
                extension: ext,
                fromDate: startDate,
                toDate: endDate
            }));
            
            this.callTimes = await Promise.all(tasks);
        },
        getBadgeColor(str: string): string {
            if (str === 'nieuw')
                return ' badge-1';
            if (str === 'dm')
                return ' badge-2';
            else
                return ' badge-3';
        },
        drawDagverwerkingChart() {
            const canvas = this.$refs.dagverwerkingCanvas as HTMLCanvasElement;
            const pubsProcessed = this.kpiData?.pubsProcessed ?? [];
            const mandateHoldersProcessed = this.kpiData?.mandateHoldersProcessed ?? [];

            const data = {
                labels: pubsProcessed.map(p => "Week " + p.weekNumber),
                datasets: [
                    {
                        label: 'Pubs',
                        data: pubsProcessed.map(p => p.amount),
                        backgroundColor: Color.INDIGO,
                        borderColor: Color.INDIGO,
                        maxBarThickness: 25,
                        yAxisID: 'L'
                    },
                    {
                        label: 'Mandateholders',
                        data: mandateHoldersProcessed.map(p => p.amount),
                        backgroundColor: Color.BLUE,
                        borderColor: Color.BLUE,
                        maxBarThickness: 25,
                        yAxisID: 'L'
                    }
                ]
            };

            new Chart(canvas, {
                type: ChartType.BAR,
                data: data,
                options: {
                    animation: {
                        duration: 0
                    },
                    maintainAspectRatio: true,
                    scales: {
                        yAxes: [
                            {
                                id: 'L',
                                ticks: {
                                    beginAtZero: true
                                },
                                position: 'left',
                                scaleLabel: {
                                    labelString: "Amount processed",
                                    display: false
                                }
                            }
                        ],
                        xAxes: [{
                            ticks: {
                                fontSize: 15,
                                fontColor: '#000',
                                fontStyle: 'bold'
                            }
                        }]
                    },
                    legend: {
                        labels: {
                            padding: 10,
                            fontSize: 14
                        },
                        position: 'bottom'
                    }
                }
            })
        },
        async drawSalesChart() {
            const canvas = this.$refs.salesCanvas as HTMLCanvasElement;

            interface GroupedSalesKpi {
                label: string;
                basic: number;
                standard: number;
                premium: number;
                premiumInternational: number;
                recup: number;
                upsell: number;
                callTime: number;
            }

            const data: GroupedSalesKpi[] = this.salesOperators.map(operator => {
                const kpis = (this.kpiData?.salesKpi ?? []).filter(kpi => kpi.operatorName.toLowerCase() === operator.login.toLowerCase());
                const totalSold = kpis.reduce((acc: number, current: SalesKpi) => {
                    return  acc + current.amount
                }, 0);
                const name = operator.login;

                return {
                    label: `${name} (€ ${totalSold.formatThousandSeparator()})`,
                    basic:  kpis.reduce((acc: number, current: SalesKpi) => {return acc + (current.package == 'basic' ? current.amount : 0) }, 0),
                    standard: kpis.reduce((acc: number, current: SalesKpi) => {return acc + (current.package == 'standard' ? current.amount : 0) }, 0),
                    premium: kpis.reduce((acc: number, current: SalesKpi) => {return acc + (current.package == 'premium' ? current.amount : 0) }, 0),
                    premiumInternational: kpis.reduce((acc: number, current: SalesKpi) => {return acc + (current.package == 'premium-international' ? current.amount : 0) }, 0),
                    recup: kpis.reduce((acc: number, current: SalesKpi) => {return acc + (current.package == 'recup' ? current.amount : 0) }, 0),
                    upsell: kpis.reduce((acc: number, current: SalesKpi) => {return acc + (current.package == 'upsell' ? current.amount : 0) }, 0),
                    callTime: this.callTimes
                        .firstOrNone(c => c.extension === operator.extension)
                        .map(c => c.numbCalls)
                        .valueOr(0)
                } as GroupedSalesKpi;
            });

            new Chart(canvas, {
                type: ChartType.BAR,
                data: {
                    labels: data.map(t => t.label),
                    datasets: [
                        {
                            label: 'Basic',
                            data: data.map(t => t.basic),
                            backgroundColor: Color.GREEN,
                            borderColor: Color.GREEN,
                            maxBarThickness: 45,
                            stack: 'Stack 0',
                            yAxisID: 'L'
                        },
                        {
                            label: 'Standard',
                            data: data.map(t => t.standard),
                            backgroundColor: Color.BLUE,
                            borderColor: Color.BLUE,
                            maxBarThickness: 45,
                            stack: 'Stack 0',
                            yAxisID: 'L'
                        },
                        {
                            label: 'Premium',
                            data: data.map(t => t.premium),
                            backgroundColor: Color.ORANGE,
                            borderColor: Color.ORANGE,
                            maxBarThickness: 45,
                            stack: 'Stack 0',
                            yAxisID: 'L'
                        },
                        {
                            label: 'Premium International',
                            data: data.map(t => t.premiumInternational),
                            backgroundColor: Color.INDIGO,
                            borderColor: Color.INDIGO,
                            maxBarThickness: 45,
                            stack: 'Stack 0',
                            yAxisID: 'L'
                        },
                        {
                            label: 'Upsell',
                            data: data.map(t => t.upsell),
                            backgroundColor: Color.YELLOW,
                            borderColor: Color.YELLOW,
                            maxBarThickness: 45,
                            stack: 'Stack 0',
                            yAxisID: 'L'
                        },
                        {
                            label: 'Recup',
                            data: data.map(t => t.recup),
                            backgroundColor: Color.WHITE,
                            borderColor: Color.WHITE,
                            maxBarThickness: 45,
                            stack: 'Stack 0',
                            yAxisID: 'L'
                        },
                        {
                            label: 'Calls',
                            data: data.map(t => t.callTime),
                            backgroundColor: Color.LIGHTGREY,
                            maxBarThickness: 30,
                            stack: 'Stack 1',
                            yAxisID: 'R'
                        }
                    ]
                },
                options: {
                    maintainAspectRatio: true,
                    animation: {
                        duration: 0
                    },
                    elements: {
                        arc: {
                            borderWidth: 0
                        }
                    },
                    legend: {
                        labels: {
                            padding: 25,
                            fontSize: 15
                        },
                        display: true,
                        position: 'bottom'
                    },
                    tooltips: {
                        enabled: true,
                    },
                    scales: {
                        yAxes: [{
                            id: 'L',
                            stacked: true,
                            ticks: {
                                beginAtZero: true
                            },
                            position: 'left',
                            scaleLabel: {
                                labelString: "Sold",
                                display: true
                            }
                        }, {
                            id: 'R',
                            stacked: true,
                            ticks: {
                                beginAtZero: true
                            },
                            position: 'right',
                            gridLines: {
                                display: false
                            },
                            scaleLabel: {
                                labelString: "Calls",
                                display: true
                            }
                        }],
                        xAxes: [{
                            stacked: true,
                            ticks: {
                                fontSize: 15,
                                fontColor: '#000',
                                fontStyle: 'bold'
                            }
                        }]
                    }
                }
            });
        },
        drawPlannerChart() {
            const canvas = this.$refs.plannerCanvas as HTMLCanvasElement;
            const rawData = this.kpiData?.plannerKpi ?? [];

            const callTimes = this.planners
                .map(d => d.extension)
                .map(ext => this.callTimes
                    .firstOrNone(c => c.extension === ext)
                    .map(c => c.numbCalls)
                    .valueOr(0)
                );

            const labels = this.planners.map((planner: Operator) => {
                const user = rawData.find(r => r.extension == planner.extension);

                const name = planner.login
                const valids = user?.validMeetings ?? 0;

                return `${name} (${valids})`;
            });

            const data = {
                labels: labels,
                datasets: [
                    {
                        label: 'Invalid',
                        data: this.planners.map(operator => {
                            const u = rawData.find(r => r.extension == operator.extension);

                            return u?.invalidMeetings ?? 0;
                        }),
                        backgroundColor: Color.ORANGE,
                        maxBarThickness: 45,
                        stack: 'stack 0',
                        yAxisID: 'L'
                    },
                    {
                        label: 'Valid',
                        data: this.planners.map(operator => {
                            const u = rawData.find(r => r.extension == operator.extension);

                            return u?.validMeetings ?? 0;
                        }),
                        backgroundColor: Color.GREEN,
                        maxBarThickness: 45,
                        stack: 'stack 0',
                        yAxisID: 'L'
                    },
                    {
                        label: 'Calls',
                        data: callTimes,
                        backgroundColor: Color.LIGHTGREY,
                        maxBarThickness: 30,
                        stack: 'Stack 1',
                        yAxisID: 'R'
                    }
                ]
            };

            new Chart(canvas, {
                type: ChartType.BAR,
                data: data,
                options: {
                    animation: {
                        duration: 0
                    },
                    maintainAspectRatio: true,
                    scales: {
                        yAxes: [
                            {
                                id: 'L',
                                ticks: {
                                    beginAtZero: true
                                },
                                stacked: true,
                                position: 'left',
                                scaleLabel: {
                                    labelString: "Executed web meetings",
                                    display: true
                                }
                            },
                            {
                                id: 'R',
                                ticks: {
                                    beginAtZero: true,
                                },
                                stacked: true,
                                position: 'right',
                                scaleLabel: {
                                    labelString: "Calls",
                                    display: true
                                },
                                gridLines: {
                                    display: false
                                }
                            }
                        ],
                        xAxes: [{
                            ticks: {
                                fontSize: 15,
                                fontColor: '#000',
                                fontStyle: 'bold'
                            }
                        }]
                    },
                    legend: {
                        labels: {
                            padding: 25,
                            fontSize: 15
                        },
                        position: 'bottom'
                    }
                }
            })
        },
        drawPEChart() {
            const canvas = this.$refs.peCanvas as HTMLCanvasElement;
            const canvas2 = this.$refs.peCanvas2 as HTMLCanvasElement;

            // const summaries = [
            //     {"period":202207,"amountOfSuppliers":384,"amountOfCompanies":92513},
            //     {"period":202206,"amountOfSuppliers":379,"amountOfCompanies":86689},
            //     {"period":202205,"amountOfSuppliers":364,"amountOfCompanies":84289},
            //     {"period":202204,"amountOfSuppliers":354,"amountOfCompanies":83289},
            //     {"period":202203,"amountOfSuppliers":344,"amountOfCompanies":81289},
            //     {"period":202202,"amountOfSuppliers":333,"amountOfCompanies":80289},
            // ];

            const months = [
                "Jan",
                "Feb",
                "Maa",
                "Apr",
                "Mei",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Okt",
                "Nov",
                "Dec"
            ];

            const monthlyData = _.map(this.kpiData!.paymentExperienceSummaries, (summary: PaymentExperienceSummary) => {
                return {
                    period: summary.period,
                    amountOfCompanies: summary.amountOfCompanies,
                    amountOfSuppliers: summary.amountOfSuppliers
                }
            }).sort((a,b) => a.period - b.period);

            const chartDataSuppliers: CWChartData = {
                colors: [Color.GREEN],
                labels: monthlyData.map(c => months[Number(c.period.toString().slice(-2)) - 1]),
                bckColors: monthlyData.map(c => '#5ed5cf1f'),
                percentages: monthlyData.map(c => c.amountOfSuppliers)
            };

            const chartDataCompanies: CWChartData = {
                colors: [Color.BLUE],
                labels: monthlyData.map(c => months[Number(c.period.toString().slice(-2)) - 1]),
                bckColors: monthlyData.map(c => Color.BLUE),
                percentages: monthlyData.map(c => c.amountOfCompanies)
            };

            new Chart(canvas, {
                type: ChartType.LINE,
                data: {
                    labels: chartDataSuppliers.labels,
                    datasets: [
                        {
                            data: chartDataSuppliers.percentages,
                            hoverBackgroundColor: chartDataSuppliers.colors,
                            borderColor: chartDataSuppliers.colors,
                            pointRadius: 5,
                            pointBorderColor: Color.GREEN,
                            pointHoverRadius: 8,
                            pointHitRadius: 8,
                            lineTension: 0
                        }
                    ]
                },
                options: {
                    animation: {
                        duration: 0
                    },
                    maintainAspectRatio: false,
                    aspectRatio: 11/2,
                    elements: {
                        arc: {
                            borderWidth: 0
                        }
                    },
                    legend: {
                        display: false,
                    },
                    tooltips: {
                        enabled: true
                    },
                    scales: {
                        yAxes: [
                            {
                                scaleLabel: {
                                    labelString: "Suppliers",
                                    display: true
                                }
                            }
                        ]
                    },
                }
            });

            new Chart(canvas2, {
                type: ChartType.LINE,
                data: {
                    labels: chartDataCompanies.labels,
                    datasets: [
                        {
                            data: chartDataCompanies.percentages,
                            hoverBackgroundColor: chartDataCompanies.colors,
                            borderColor: chartDataCompanies.colors,
                            pointRadius: 5,
                            pointBorderColor: Color.BLUE,
                            pointHoverRadius: 8,
                            pointHitRadius: 8,
                            lineTension: 0
                        }
                    ]
                },
                options: {
                    animation: {
                        duration: 0
                    },
                    maintainAspectRatio: false,
                    aspectRatio: 11/2,
                    elements: {
                        arc: {
                            borderWidth: 0
                        }
                    },
                    legend: {
                        display: false,
                    },
                    tooltips: {
                        enabled: true
                    },
                    scales: {
                        yAxes: [
                            {
                                scaleLabel: {
                                    labelString: "Companies",
                                    display: true
                                }
                            }
                        ]
                    },
                }
            });
        },
        drawAboPieChart() {
            const canvas = this.$refs.aboCanvas as HTMLCanvasElement;
            const data = (this.kpiData?.abosSold ?? []);
            unset(data, 'samia');

            new Chart(canvas, {
                type: 'doughnut',
                data: {
                    labels: Object.keys(data),
                    datasets: [{
                        data: Object.values(data),
                        backgroundColor: [
                            Color.INDIGO,
                            Color.ORANGE,
                            Color.BLUE,
                            Color.GREEN,
                            Color.LIGHTGREY
                        ]
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                    legend: {
                        labels: {
                            padding: 25,
                            fontSize: 15
                        },
                        position: 'bottom'
                    },
                    animation: {
                        duration: 0
                    }
                }
            })
        },
    },
    async mounted() {
        await this.fetchData();
        
        setInterval(async () => {
            await this.fetchData();
        }, 60_000);

        setTimeout(async () => {
            window.location.reload();
        }, 600_000);
    }
});
</script>

<style lang="scss" scoped>
.accent-color {
  color: #03a9f4;
}

.text-grey {
  color: #cdcdcd !important;
}

.font-small {
  line-height: 1.1rem;
}
.badge {
  color: #000;
}
.badge-small {
  padding: 0.15rem 0.4rem;
}
.badge-1 {
  background-color: #7de18c;
}
.badge-2 {
  background-color: #73a6d7;
}
.badge-3 {
  background-color: #ffe162;
}

.dashboard-grid {
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: 20px;
  background: #fbfbfb;
  min-width: 0;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
  .tile {
    border-radius: 6px;
    background: #f3f3f3;
    border: 1px solid #5e89d51f;
    padding: 0 15px;
    min-width: 0;
    overflow: hidden;
    position: relative;

    .border-bottom {
      border-color: #5ed5cf29 !important;
    }

    h2 {
      text-transform: uppercase;
      text-align: center;
      margin: 8px;
      font-size: 20px;
      line-height: 40px;
      font-weight: bold;
      letter-spacing: 1px;
    }

    &.tile-1 {
      grid-area: 1 / 1 / 3 / 3;
    }

    &.tile-2 {
      grid-area: 3 / 1 / 5 / 3;
    }

    &.tile-3 {
      grid-area: 1 / 3 / 3 / 7;
    }

    &.tile-4 {
      grid-area: 3 / 3 / 5 / 7;
    }

    &.tile-5 {
      grid-area: 1 / 7 / 2 / 10;
    }

    &.tile-6 {
      grid-area: 2 / 7 / 5 / 10;
    }

   
    @media only screen and (max-width: 800px) {
      grid-area: auto !important;
    }
  }
}
.vert-grid {
  display: grid;
  grid-template-columns: 1fr;
}

.tile-centered {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.big-text{
  text-align: center;
    font-weight: bold;
  font-size: 7em;
  flex-basis: 100%;
}

.green {
    color: #7de18c;
}
.red {
    color: #ff8b62;
}

.progression-form {
  position: absolute;
  bottom: 10px;
}
.mandates-title {
    font-size: 15px;
    font-weight: 300;
    margin-top: 11px;
    color: #938f8f;
    width: 7%;
}
.mandates-data {
    font-size: 30px;
    font-weight: bold;
}
.mandates-percent {
    font-size: 24px;
    font-weight: 200;
    margin-top: 5px;
}
@media only screen and (min-width: 2000px) {
    .mandates-title {
        margin-top: 25px;
        width: 7%;
    }
    .mandates-data {
        font-size: 50px;
    }
    .mandates-percent {
        font-size: 30px;
        margin-top: 13px;
    }
}
</style>