﻿<template>
    <div class="track">
        <transition name="fade">
            <div class="active-element"
                 v-bind:class="{'reflected': isReflected}"
                 v-bind:style="labelPosition"
                 v-if="shouldShowInfo"
                 v-html="activeElement.content"></div>
        </transition>
        <div class="track-label">
            <template v-if="link">
                <a v-if="icon" :href="link" target="_blank" class="text-blue">
                    {{label}}
                </a>
            </template>
            <template v-else>
                {{label}}
            </template>
            <i v-if="icon" class="fas" v-bind:class="'fa-' + icon"></i>
        </div>
        <div class="track-content">
            <cw-period v-for="(period, index) in periods"
                       v-bind="period"
                       :key="index"
                       :index="index"
                       :timeline-start="timelineStart"
                       :timeline-end="timelineEnd">
            </cw-period>
            <cw-event v-for="(event, index) in events"
                      v-bind="event"
                      :key="index"
                      :index="index"
                      :timeline-start="timelineStart"
                      :timeline-end="timelineEnd">
            </cw-event>
        </div>
    </div>
</template>

<script>
    import Period from './period.vue';
    import Event from './event.vue';

    export default {
        name: 'cw-track',
        components: {
            'cw-period': Period,
            'cw-event': Event
        },
        props: {
            label: String,
            type: String,
            link: String,
            periods: Array,
            events: Array,
            forcedColor: String,
            timelineStart: Number,
            timelineEnd: Number
        },
        inject: ['hansDateToReadableDate', 'hansDateToTimestamp'],
        provide() {
            return {
                hansDateToTimestamp: this.hansDateToTimestamp,
                hansDateToReadableDate: this.hansDateToReadableDate
            }
        },
        data: function () {
            return {
                activeElement: {
                    index: -1,
                    content: ''
                }
            };
        },
        computed: {
            trackerPosition: function () {
                return this.$parent.trackerPosition;
            },
            cursorCloseToEdge: function () {
                return this.trackerPosition >= this.$parent.$el.clientWidth - 320;
            },
            labelPosition: function () {
                var styleObject = this.cursorCloseToEdge ?
                    { right: this.$parent.$el.clientWidth - this.trackerPosition + 'px' } :
                    { left: this.trackerPosition + 'px' };

                return styleObject;
            },
            isReflected: function () {
                return this.cursorCloseToEdge;
            },
            shouldShowInfo: function () {
                return this.activeElement.content !== undefined && this.activeElement.content !== '';
            },
            icon: function () {
                if (this.type === 'person')
                    return 'user';
                else if (this.type === 'company')
                    return 'building';
                else
                    return '';
            }
        }
    };
</script>