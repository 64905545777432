<template>
    <div class="box my-4">
        <div class="box-title">Download {{index + 1}}</div>

        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label for="nl">Titel NL</label>
                    <input id="nl" type="text" v-model="form.nameNl" class="form-control" @change="change"/>
                </div>
                <div class="form-group">
                    <label for="fr">Titel FR</label>
                    <input id="fr" type="text" v-model="form.nameFr" class="form-control" @change="change"/>
                </div>
                <div class="form-group">
                    <label for="en">Titel EN</label>
                    <input id="en" type="text" v-model="form.nameEn" class="form-control" @change="change"/>
                </div>

                <div class="mt-2">
                    <a href="" class="text-danger" @click.prevent="tryDelete()"><i class="fas fa-times mr-2"></i> download verwijderen</a>
                </div>
            </div>

            <div class="col-6">
                <div class="form-group">
                     <file-input 
                            small
                            v-on:input="onManualNlUpload" 
                            :multiple="false" 
                            :required="false" 
                            :name="'pdf_nl_' + index"
                            placeholder=".pdf" 
                            title="Upload NL pdf"
                            accept=".pdf"></file-input>

                            <a v-if="manualNl.path !== ''" :href="manualNl.path" download class="text-blue mt-2 d-inline-block">
                                <i class="fas fa-download mr-2"></i> download PDF
                            </a>
                </div>
                 <div class="form-group">
                     <file-input 
                            small
                            v-on:input="onManualFrUpload" 
                            :multiple="false" 
                            :required="false" 
                            :name="'pdf_fr_' + index"
                            placeholder=".pdf" 
                            title="Upload FR pdf"
                            accept=".pdf"></file-input>

                        <a v-if="manualFr.path !== ''" :href="manualFr.path" download class="text-blue mt-2 d-inline-block">
                            <i class="fas fa-download mr-2"></i> download PDF
                        </a>
                </div>
                 <div class="form-group mb-0">
                     <file-input 
                            small
                            v-on:input="onManualEnUpload" 
                            :multiple="false" 
                            :required="false" 
                            :name="'pdf_en_' + index"
                            placeholder=".pdf" 
                            title="Upload EN pdf"
                            accept=".pdf"></file-input>

                        <a v-if="manualEn.path !== ''" :href="manualEn.path" download class="text-blue mt-2 d-inline-block">
                            <i class="fas fa-download mr-2"></i> download PDF
                        </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue'
    import FileInput from '../../shared/file-input.vue';
    import Ajax from '../../../lib/Ajax';
    import Toast from '../../../lib/Toast';
    import Confirm from '../../../lib/Confirmation';
    import { SoftwarePartnerManual } from '../../../types/dto/SoftwarePartner';
    import { Exception } from '@sentry/types';

    export default Vue.extend({
       name: 'software-manual',
       components: { FileInput },
       props: {
           download: Object as () => SoftwarePartnerManual,
           index: Number
       },
       data() {
           return {
               form: {
                   nameNl: this.download.nameNl,
                   nameFr: this.download.nameFr,
                   nameEn: this.download.nameEn
               },
               manualNl: {
                   file: null as File | null,
                   path: this.download.hasFileNl ? this.download.pathNl : ""
               },
               manualFr: {
                   file: null as File | null,
                   path: this.download.hasFileFr ? this.download.pathFr : ""
               },
               manualEn: {
                   file: null as File | null,
                   path: this.download.hasFileEn ? this.download.pathEn : ""
               }
           }
       },
       methods: {
           change() {
               const updatedDownload: SoftwarePartnerManual = {
                    nameNl: this.form.nameNl,
                    nameFr: this.form.nameFr,
                    nameEn: this.form.nameEn,
                    id: this.download.id,
                    softwareId: this.download.softwareId,
                    hasFileNl: this.download.hasFileNl,
                    hasFileFr: this.download.hasFileFr,
                    hasFileEn: this.download.hasFileEn,
                    pathNl: this.download.pathNl,
                    pathFr: this.download.pathFr,
                    pathEn: this.download.pathEn
                };

                this.$emit('update:manual', updatedDownload);
           },
           onManualNlUpload(uploadedFiles: File[]): void {
               this.uploadManual(uploadedFiles, 'nl');
           },
           onManualFrUpload(uploadedFiles: File[]): void {
               this.uploadManual(uploadedFiles, 'fr');
           },
           onManualEnUpload(uploadedFiles: File[]): void {
               this.uploadManual(uploadedFiles, 'en');
           },
           async uploadManual(files: File[], language: string): Promise<void> {
                if(files.length === 0)
                    return;

                const file = files[0];
                try {
                    const filePath = await this.ajaxUploadManual(file, language);

                    if(language === 'nl') {
                        this.manualNl.path = filePath;
                        this.manualNl.file = file;
                    } else if(language === 'fr') {
                        this.manualFr.path = filePath;
                        this.manualFr.file = file;
                    } else {
                        this.manualEn.path = filePath;
                        this.manualEn.file = file;
                    }
                } catch(e: unknown) {
                    /* catch clause cannot be typed but we know that if it errors out it will be a string since that's what ajaxUploadManual rejects. */
                    Toast.error(e as string);
                }
            },
            ajaxUploadManual(file: File, language: string): Promise<string> {
                const formData = new FormData();
                formData.append("downloadId", this.download.id.toString());
                formData.append("softwareId", this.download.softwareId.toString());
                formData.append("language", language);
                formData.append("file", file);

                return new Promise((resolve, reject) => {
                    Ajax.postWithBinary(
                       '/ajax/cms/software-integrations/manuals/upload',
                       formData,
                       (response: AjaxResponse<string>) => {
                            const uploadUrl = response.data;
                            resolve(uploadUrl);
                        },
                        error => {
                            reject(error);
                        }
                    );
                });
           },
           tryDelete() {
               Confirm(this.deleteDownload, {
                   title: 'Wilt u deze download/handleiding verwijderen?',
                   body: 'Zowel de tekstgegevens als de PDF\'s zullen permanent worden verwijderd.'
               });
           },
           deleteDownload() {
               Ajax.post(
                    '/ajax/cms/software-integrations/manuals/delete',
                    {
                        downloadId: this.download.id,
                        softwareId: this.download.softwareId
                    },
                    response => {
                        this.$emit('delete-download', this.download.id);
                        Toast.success('Download verwijderd.');
                    },
                    error => {
                        console.error(error);
                    }
               )
           }
       }
    });
</script>

<style scoped>

</style>