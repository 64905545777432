<template>
    <div>
        <h2>{{translate('integrate_title')}}</h2>
        <p>{{translate('no_account_text')}}</p>
        <a :href="link" class="btn btn-200 btn-success">{{translate('probeer_gratis')}}</a>
    </div>
</template>

<script lang="ts">
    import mixins from 'vue-typed-mixins';
    import Translate from '../../mixins/translate';
    
    export default mixins(Translate).extend({
        name: 'integration_no_account',
        props: {
            integratorId: String
        },
        data() {
            return {
                link: '/trial/' + this.integratorId
            }
        }
    })
</script>

<style scoped>

</style>