
<div>
    <div class="mb-4">
        <upsell-banner v-if="triedToExceedLimit && $root.amountOfFollowupsSuggested <= 500"
                       :title="translate('upsell_title_limit_reached_during_upload')"
                       :text="translate('upsell_text_limit_reached_during_upload', currentList.Naam)"
                       :href="increaseAlertsLink">
        </upsell-banner>
    </div>
    <div class="row mt-3 position-relative">
        <div class="col-md-6">
            <div>
                <div class="mb-1"><b>{{translate('choose_selection_method_all_label')}}</b></div>
                <input type="radio" id="all" value="all" v-model="uploadType" class="image-checkbox">
                <label for="all">
                    <img src="/images/illustrations/upload-all.svg" />
                </label>
            </div>
        </div>
        <div class="text-center font-italic text-grey middle-or">
            {{translate('or').toLowerCase()}}
        </div>
        <div class="col-md-6 pt-5 pt-md-0">
            <div>
                <div class="mb-1">
                <b>{{translate('choose_selection_method_screening_label')}}*</b>
                <span class="ml-2" v-if="!canAccessScreening">({{translate('only_in_premium')}})</span></div>
                <input type="radio" id="screening" value="screening" v-model="uploadType" class="image-checkbox" :disabled="!canAccessScreening">
                <label for="screening" @click="triedToClickScreening()">
                    <img src="/images/illustrations/upload-screen.svg" />
                </label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 offset-md-6">
            <p class="font-small mb-0">
               * {{translate('choose_selection_method_screening_explanation')}}
            </p>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <button type="submit" class="btn btn-blue btn-200" @click="submitForm()">
                {{translate('btn_complete')}}
            </button>
        </div>
    </div>
</div>
