
<div class="mandate-card">
    <div class="box" :class="{'sharp-bottom-corners': allMandatesShown}">
        <div class="inner-card">
            <div>
                <div class="mb-2 flex-v-center">
                    <a :href="detailsUrl" target="_blank">
                        <h3 class="font-weight-bold mr-3 mb-0">{{ name }}</h3>
                    </a>
                    <barometer-icon v-if="scoreClass && canSeeScores" :className="scoreClass" :score="score"></barometer-icon>
                </div>
                <div class="space-y-3">
                    <div v-if="vat">
                        <i class="faded fas fa-building mr-2"></i> {{ vat }}
                    </div>
                    <div v-if="isCompany">
                        <i class="faded fas fa-user-tie mr-2"></i> {{translate('vertegenwoordigd_door')}}: <b>{{ personName }}</b>
                    </div>
                    <div v-if="address">
                        <i class="faded fas fa-map-marker-alt mr-2"></i> {{ address }}
                    </div>
                </div>
            </div>
            <div>
                <h4>{{ title }}</h4>

                <div v-if="isMobile" class="mandate-label mt-4"><i class="fas fa-book mr-2"></i> <span v-html="mandateString"></span></div>
                <div :class="{'company-started-at-timeline-start': companyStartedAtTimelineStart}" class="timeline__company">
                    <div :class="mandateStatus" :style="'left:' + mandateOffset + '%; width:' + mandateWidth + '%;'" class="timeline__mandate">
                        <div v-if="!isMobile" class="mandate-label" :class="{'left': tooFewSpaceRight}"><i class="fas fa-book mx-2"></i> <span v-html="mandateString"></span></div>
                    </div>
                </div>
                
                <div class="d-flex justify-content-between align-items-center">
                    <h4 class="mb-0">{{translate('overige_mandaten')}}</h4>
                    <template v-if="canSeeOtherMandates">
                        <a href="" class="link font-small no-wrap d-print-none" @click.prevent="showOtherMandates" v-if="!allMandatesShown && hasOtherMandates">{{translate('bekijk_alle_mandaten')}} <i class="fas fa-angle-down ml-2"></i></a>
                        <a href="" class="link font-small no-wrap d-print-none" @click.prevent="hideOtherMandates" v-if="allMandatesShown">{{translate('verberg_alle_mandaten')}} <i class="fas fa-angle-up ml-2"></i></a>
                    </template>
                </div>
                <div class="space-x-9">
                    <span :title="translate('mandaat_status_' + MandateStatus.MandateIsActive)" :class="{'very-faded': summary.green === 0}">
                        <i class="fas fa-book mr-2" :class="MandateStatus.MandateIsActive"></i>{{summary.green}}
                    </span>
                    <span :title="translate('mandaat_status_' + MandateStatus.MandateEnded)" :class="{'very-faded': summary.blue === 0}">
                        <i class="fas fa-book mr-2" :class="MandateStatus.MandateEnded"></i>{{summary.blue}}
                    </span>
                    <span :title="translate('mandaat_status_' + MandateStatus.CompanyEnded)" :class="{'very-faded': summary.yellow === 0}">
                        <i class="fas fa-book mr-2" :class="MandateStatus.CompanyEnded"></i>{{summary.yellow}}
                    </span>
                    <span :title="translate('mandaat_status_' + MandateStatus.MandateEndedLongBeforeCompanyFailure)" :class="{'very-faded': summary.purple === 0}">
                        <i class="fas fa-book mr-2" :class="MandateStatus.MandateEndedLongBeforeCompanyFailure"></i>{{summary.purple}}
                    </span>
                    <span :title="translate('mandaat_status_' + MandateStatus.MandateEndedShortlyBeforeCompanyFailure)" :class="{'very-faded': summary.red === 0}">
                        <i class="fas fa-book mr-2" :class="MandateStatus.MandateEndedShortlyBeforeCompanyFailure"></i>{{summary.red}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="all-mandates" v-if="loadingMandates || allMandatesShown">
        <div v-if="loadingMandates" class="text-center">
            <i class="fas fa-spinner spinning text-lightblue fa-lg"></i>
        </div>
        <table class="table data-table mb-0" v-if="allMandatesShown">
            <thead>
                <tr>
                    <th>{{translate('mandate')}}</th>
                    <th v-if="!isMobile" width="150px">{{translate('mandate_end')}}</th>
                    <th v-if="!isMobile" width="150px">{{translate('company_end')}}</th>
                    <th width="60px">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <template  v-for="(mandate, index) in otherMandates">
                    <mandate-row :mandate="mandate" :can-see-scores="canSeeScores" :should-see-upsell="shouldSeeUpsell" :is-mobile="isMobile"></mandate-row>
                    <template v-if="mandate.subMandaten.length > 0">
                        <mandate-row  v-for="(submandate, idx) in mandate.subMandaten" :mandate="submandate" :key="'mandate_'+index + '_' + idx"  is-submandate :can-see-scores="canSeeScores" :should-see-upsell="shouldSeeUpsell" :is-mobile="isMobile"></mandate-row>
                    </template>
                </template>
            </tbody>
        </table>
    </div>
</div>
